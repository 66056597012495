<!-- tax-configuration.component.html -->
<div style="height:64px;" class="page-empty-header"></div>

<div class="container-fluid">
  <div class="row">
    <div style="background:#215DAB; height: calc(100vh - 64px); overflow: scroll;" class="col-2">
      <app-configuration-dashboard-menu></app-configuration-dashboard-menu>
    </div>

    <div class="col-10" style="padding-left: 2.5rem;padding-right: 2.5rem;padding-top:2.5rem;overflow:scroll;max-height: calc(100vh - 64px);">
      <div class="container-fluid">
        <h1 class>Tax Configuration</h1>

        <mat-card class="configuration-card">
          <mat-card-header>
            <mat-card-title>Tax Configurations</mat-card-title>
          </mat-card-header>

          <mat-card-content>
            <div>
              <mat-progress-spinner class="matProgressSpinnerCentered" *ngIf="showProgressSpinner" mode="indeterminate"></mat-progress-spinner>

              <form class="col-12" [formGroup]="taxConfigurationForm">
                <app-success-message [message]="this.successMessage"></app-success-message>
                <app-error-message [message]="this.errorMessage"></app-error-message>

                <div formArrayName="taxConfigurations">
                  <mat-accordion>
                    <mat-expansion-panel *ngFor="let taxConfig of taxConfigurations.controls; let i = index" [formGroupName]="i" class="tax-config-panel">
                      <mat-expansion-panel-header>
                        <mat-panel-title>
                          {{ taxConfig.get('title').value || 'Untitled Configuration' }}
                        </mat-panel-title>
                        <mat-panel-description>
                          <span [innerHTML]="getHelpfulInfo(i)"></span>
                        </mat-panel-description>
                      </mat-expansion-panel-header>

                      <div class="tax-config-content">
                        <div class="row">
                          <div class="col-12">
                            <mat-form-field class="full-width-field" appearance="outline">
                              <mat-label>Configuration Title</mat-label>
                              <input matInput formControlName="title" placeholder="Enter a title for this tax configuration">
                            </mat-form-field>
                          </div>
                        </div>

                        <div class="row">
                          <div class="col-6">
                            <mat-form-field class="full-width-field" appearance="outline">
                              <mat-label>Calculation Method</mat-label>
                              <mat-select formControlName="field_calculation_method" (selectionChange)="onCalculationMethodChange(i)">
                                <mat-option *ngFor="let method of calculationMethods" [value]="method">
                                  {{ method | titlecase }}
                                </mat-option>
                              </mat-select>
                            </mat-form-field>
                          </div>
                          <div class="col-6">
                            <mat-form-field class="full-width-field" appearance="outline">
                              <mat-label>Rounding Rule</mat-label>
                              <mat-select formControlName="field_rounding_rule">
                                <mat-option *ngFor="let rule of roundingRules" [value]="rule">
                                  {{ rule | titlecase }}
                                </mat-option>
                              </mat-select>
                            </mat-form-field>
                          </div>
                        </div>

                        <div class="row">
                          <div class="col-6">
                            <mat-form-field class="full-width-field" appearance="outline">
                              <mat-label>Tax Type</mat-label>
                              <mat-select formControlName="field_tax_type">
                                <mat-option *ngFor="let type of taxTypes" [value]="type">
                                  {{ type }}
                                </mat-option>
                              </mat-select>
                            </mat-form-field>
                          </div>
                          <div class="col-6">
                            <mat-slide-toggle formControlName="field_tax_status">Tax Status</mat-slide-toggle>
                          </div>
                        </div>

                        <div class="row">
                          <div class="col-6">
                            <mat-form-field class="full-width-field" appearance="outline">
                              <mat-label>{{ getTaxValueLabel(i) }}</mat-label>
                              <input matInput formControlName="field_tax_value" [placeholder]="getTaxValuePlaceholder(i)">
                            </mat-form-field>
                          </div>
                        </div>

                        <div class="row">
                          <div class="col-6">
                            <mat-form-field class="full-width-field" appearance="outline">
                              <mat-label>Effective Tax Start Date</mat-label>
                              <input matInput [matDatepicker]="startDatePicker" formControlName="field_effective_tax_start_date" placeholder="Select start date">
                              <mat-datepicker-toggle matSuffix [for]="startDatePicker"></mat-datepicker-toggle>
                              <mat-datepicker #startDatePicker></mat-datepicker>
                            </mat-form-field>
                          </div>
                          <div class="col-6">
                            <mat-form-field class="full-width-field" appearance="outline">
                              <mat-label>Effective Tax End Date</mat-label>
                              <input matInput [matDatepicker]="endDatePicker" formControlName="field_effective_tax_end_date" placeholder="Select end date">
                              <mat-datepicker-toggle matSuffix [for]="endDatePicker"></mat-datepicker-toggle>
                              <mat-datepicker #endDatePicker></mat-datepicker>
                            </mat-form-field>
                          </div>
                        </div>

                        <div class="row">
                          <div class="col-12 text-right">
                            <button mat-raised-button color="warn" (click)="removeTaxConfiguration(i)">Remove Configuration</button>
                          </div>
                        </div>
                      </div>
                    </mat-expansion-panel>
                  </mat-accordion>
                </div>

                <button mat-raised-button color="primary" (click)="addTaxConfiguration()" class="mt-3">
                  Add New Tax Configuration
                </button>
              </form>
            </div>
          </mat-card-content>

          <mat-card-actions align="end">
            <button type="button" [disabled]="!taxConfigurationForm.valid" (click)="actionType === 'create' ? onSubmit() : onUpdate()" mat-raised-button color="accent">
              {{ actionType === 'create' ? 'Save' : 'Update' }} All Configurations
            </button>
          </mat-card-actions>
        </mat-card>
      </div>
    </div>
  </div>
</div>
