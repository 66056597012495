import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-lesson-list',
  templateUrl: './lesson-list.component.html',
  styleUrls: ['./lesson-list.component.css']
})
export class LessonListComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  getLessonList() {
    
  }

}
