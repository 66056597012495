import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import moment from 'moment';
import { BaseDialogComponent } from '../base-dialog/base-dialog.component';

@Component({
  selector: 'app-cancel-future-appointment',
  templateUrl: './cancel-future-appointment.component.html',
  styleUrls: ['./cancel-future-appointment.component.css']
})
export class CancelFutureAppointmentComponent extends BaseDialogComponent implements OnInit {
  override entityId = this._injectedDialogData['data']['EntityID'];
  lessonsToDelete: any[] = [];

  override ngOnInit(): void {
    console.log('CancelFutureAppointmentComponent - ngOnInit');
    console.log('injectedDialogData', this._injectedDialogData['data']);
    console.log(this._injectedDialogData['data']);

    this.getLessonsToDelete();
  }

  getLessonsToDelete() {
    console.log('CancelFutureAppointmentComponent - getLessonsToDelete');
    this.displayProgressSpinner(true);

    let params: any = {
      eventID: this._injectedDialogData?.['data']?.['entityID'],
      field_studio_reference: this._injectedDialogData?.['data']?.['field_studio_reference'],
      bundle: this._injectedDialogData?.['data']?.['bundle']
    };

    if (this.bundle !== 'services') {
      params.parentID = this._injectedDialogData?.['data']?.['parentID'];
    }

    console.log('API request params:', params);

    this._drupalRESTService.httpPOST('/api_rest/v1/cancelFutureStandingAppts', params)
      .subscribe(data => {
        this.displayProgressSpinner(false);
        console.log('API response data:', data);
        if (data?.['lesson_to_delete']) {
          this.lessonsToDelete = data['lesson_to_delete'];
          console.log('Lessons to delete:', this.lessonsToDelete);
        } else if (data?.['services_to_delete']) {
          this.lessonsToDelete = data['services_to_delete'];
          console.log('Services to delete:', this.lessonsToDelete);
        } else if (data?.['schedules_to_delete']) {
          this.lessonsToDelete = data['schedules_to_delete'];
          console.log('Schedules to delete:', this.lessonsToDelete);
        }
        if (data?.['message']) {
          this.errorMessage = data['message'];
          console.log('Error message:', this.errorMessage);
        }
      },
        error => {
          this.displayProgressSpinner(false);
          this.errorMessage = "There was a problem retrieving future appointments.";
          console.error('API error:', error);
          this.handleError(error);
        });
  }

  override onSubmit(form: NgForm) {
    console.log('CancelFutureAppointmentComponent - onSubmit');
    this.displayProgressSpinner(true);

    let params: any = {
      parentID: this._injectedDialogData?.['data']?.['parentID'],
      eventID: this._injectedDialogData?.['data']?.['entityID'],
      bundle: this._injectedDialogData?.['data']?.['bundle'],
      confirm: true
    };
    console.log('API request params:', params);

    this._drupalRESTService.httpPOST('/api_rest/v1/cancelFutureStandingAppts', params)
      .subscribe(data => {
        this.displayProgressSpinner(false);
        console.log('API response data:', data);
        if (data?.['success']) {
          console.log('Success message:', data['success']);
          this._dialog.closeAll();
        }
        if (data?.['message']) {
          this.errorMessage = data['message'];
          console.log('Error message:', this.errorMessage);
        }
      },
        error => {
          this.displayProgressSpinner(false);
          this.errorMessage = "There was a problem canceling future lessons.";
          console.error('API error:', error);
          this.handleError(error);
        });
  }

  onCancel() {
    console.log('CancelFutureAppointmentComponent - onCancel');
    this._dialog.closeAll();
  }
}
