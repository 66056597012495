<!-- simple-import-packages.component.html -->
<mat-card>
  <form [formGroup]="packageForm" (ngSubmit)="onSubmitPackages()">

    <div class="row">
      <h3>Packages</h3>

      <div class="col-1">
        <button (click)="addPackage()" type="button" class="btn btn-primary">
          <span class="material-icons large-icon">add</span>
        </button>
      </div>

      <div class="col-11">
        <div formArrayName="packages">
          <div *ngFor="let package of packageArray.controls; let i = index" [formGroupName]="i">
            <!-- [ngClass]="{'error-border': packageErrorMessages[i]}" -->

            <div class="row">
              <div class="col-2">
                <mat-form-field class="full-width-field" appearance="outline">
                  <mat-label>Package Name</mat-label>
                  <input matInput formControlName="field_package_name">
                </mat-form-field>
              </div>

              <div class="col-2">
								<mat-form-field class="full-width-field" appearance="outline">
									<mat-label>SPS Code</mat-label>
									<mat-select matInput formControlName="field_sps_code">
											<mat-option>None</mat-option>
											<mat-option *ngFor="let element of this._taxonomyService?.sps_code"
													[value]="element.id">{{ element.name }}</mat-option>
									</mat-select>
							  </mat-form-field>
              </div>

              <div class="col-2 d-none">
								<mat-form-field class="full-width-field" appearance="outline">
									<mat-label>Lesson Type</mat-label>
									<mat-select matInput formControlName="field_lesson_type">
											<mat-option>None</mat-option>
											<mat-option *ngFor="let element of this._taxonomyService?.lesson_type"
													[value]="element.id">{{ element.name }}</mat-option>
									</mat-select>
							  </mat-form-field>
              </div>

              <div class="col-2">
                <mat-form-field class="full-width-field" appearance="outline">
                  <mat-label>Lesson Price</mat-label>
                  <input matInput formControlName="field_lesson_price">
                </mat-form-field>
              </div>

              <div class="col-1">
                <mat-form-field class="full-width-field" appearance="outline">
                  <mat-label># Lessons</mat-label>
                  <input matInput formControlName="field_lesson_count">
                </mat-form-field>
              </div>

							<div class="col-1">
								<mat-form-field class="full-width-field" appearance="outline">
									<mat-label>Discount</mat-label>
									<input matInput formControlName="field_discount">
								</mat-form-field>
							</div>

							<div class="col-2 d-none">
								<mat-form-field class="full-width-field" appearance="outline">
									<mat-label>Total Price</mat-label>
									<input matInput formControlName="field_total_price" readonly>
								</mat-form-field>
							</div>

              <div class="col-2">
                <mat-form-field class="full-width-field" appearance="outline">
                  <mat-label>Notes</mat-label>
                  <input matInput formControlName="field_additional_notes">
                </mat-form-field>
              </div>

              <div class="col-1">
                <button type="button" (click)="openDeleteDialog(i)" class="btn btn-danger">
                  <mat-icon>delete</mat-icon>
                </button>
              </div>

              <div *ngIf="packageErrorMessages[i]" class="col-1 error-icon">
                <mat-icon matTooltip="{{ packageErrorMessages[i] }}" aria-label="Error icon">error_outline</mat-icon>
              </div>
              <div *ngIf="packageSuccessStates[i]" class="col-1 success-icon">
                <mat-icon>check_circle</mat-icon>
              </div>
            </div>

          </div>
        </div>
      </div>

    </div>

    <button mat-raised-button color="primary">Submit</button>
  </form>
</mat-card>
