import { ComponentType } from '@angular/cdk/portal';
import { Component, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { LiveAnnouncer } from '@angular/cdk/a11y';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { UtilityService } from 'src/app/services/utility.service';
import { MatPaginator, PageEvent } from "@angular/material/paginator";
import { NgForm } from "@angular/forms";
import { DrupalRESTService } from "src/app/services/drupal-rest.service";
import { ActivatedRoute, Event, Params, Router } from "@angular/router";
import { saveAs } from 'file-saver';
import moment from 'moment';
import { DialogService } from 'src/app/services/dialog.service';
import { FieldsService } from 'src/app/services/fields.service';
import { TaxonomyService } from 'src/app/services/taxonomy.service';
import { PaymentsEntityComponent } from '../../forms/payments-entity/payments-entity.component';
import { EventLessonEntityComponent } from '../../forms/event-lesson-entity/event-lesson-entity.component';
import { EnrollmentEntityComponent } from '../../forms/enrollment-entity/enrollment-entity.component';
import { SendUpdateAPIComponent } from '../../forms/send-update-api/send-update-api.component';
import { BaseComponent } from '../../forms/base-form/base-form.component';
import { EventServicesEntityComponent } from '../../forms/event-services-entity/event-services-entity.component';
import { EventGroupLessonEntityComponent } from '../../forms/event-group-lesson-entity/event-group-lesson-entity.component';
import { EventSchedulesEntityComponent } from '../../forms/event-schedules-entity/event-schedules-entity.component';
import { EnrollmentDropComponent } from '../../forms/enrollment-drop/enrollment-drop.component';
import { StudentInquiryDetailsComponent } from '../../forms/student-inquiry-details/student-inquiry-details.component';
import { EnrollmentViewLessonComponent } from '../../forms/enrollment-view-lesson/enrollment-view-lesson.component';
import { MiscServiceDeductionsEntityComponent } from '../../forms/misc-service-deductions-entity/misc-service-deductions-entity.component';
import { HttpParameter } from 'src/app/types/http-parameter';
import { SignatureEnrollmentComponent } from '../../enrollment/signature-enrollment/signature-enrollment.component';
import { Subscription } from 'rxjs';

@Component({ template: '' })

/**
 * Base class to be extended.
 */
export class ViewTableComponent extends BaseComponent implements OnInit {
  private subscription: Subscription;

  departmentData = this._fieldsService.studentDepartmentData();
  instructorData = this._fieldsService.instructorData();
  executiveData = this._fieldsService.instructorsExecutive();
  juniorExecutiveData = this._fieldsService.instructorsJuniorExecutive();
  standingTypeData = this._fieldsService.standingTypeData();
  WeeksForYearData = this._fieldsService.WeeksForYearData();
  LastFourYearsData = this._fieldsService.LastFourYearsData();

  // Other component refs.
  EventLessonEntityComponent = EventLessonEntityComponent
  EventServicesEntityComponent = EventServicesEntityComponent;
  EventSchedulesEntityComponent = EventSchedulesEntityComponent;
  EventGroupLessonEntityComponent = EventGroupLessonEntityComponent;
  PaymentsEntityComponent = PaymentsEntityComponent;
  EnrollmentEntityComponent = EnrollmentEntityComponent;
  EnrollmentDropComponent = EnrollmentDropComponent;
  EnrollmentViewLessonComponent = EnrollmentViewLessonComponent;
  SendUpdateAPIComponent = SendUpdateAPIComponent;
  StudentInquiryDetailsComponent = StudentInquiryDetailsComponent;
  MiscServiceDeductionsEntityComponent = MiscServiceDeductionsEntityComponent;
  SignatureEnrollmentComponent = SignatureEnrollmentComponent;

  @ViewChild('f') form: NgForm;
  @ViewChild('displayTable') displayTable;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  displayedColumns: string[] = [
    'example',
  ];

  dataSource: MatTableDataSource<unknown>;
  ShowProgressBar: boolean = true;
  autoCompleteOptions: any;
  queryParams: { parameter: string, value: string }[] = [];
  routeParams: any;
  sortParams: any;
  paginationTotalPages: number = 0;
  paginationIndex: number = 0;
  pageSize: number = 0;
  pageEvent: PageEvent;

  autoCompleteCategoryOptions: any = [];
  autoCompleteEnrollmentOptions: any = [];
  autoCompleteStudentAccountOptions: any = [];
  autocompleteInstructorOptions: any = [];
  autocompleteCustomerNameOptions: any = [];
  autoCompleteFirstNameOptions: any = [];
  autoCompleteLastNameOptions: any = [];
  autoCompleteFirstLastNameOptions: any = [];
  autoCompletePaymentIDOptions: any = [];

  isLoading: boolean = false;

  override onSubmit(form: NgForm): void {
    // fix for form submitting on (blur) and (input)
    if (this.ShowProgressBar) { return; }

    // if (this.isLocked) {return}
    // this.isLocked = true;

    console.log('form values', form.form.value)

    // Convert date value(s) to what Drupal expects. YYYY/MM/DD
    for (const property in form.form.value) {
      if (moment.isMoment(form.form.value[property]) == true) {
        form.form.value[property] = (form.form.value[property]) ? moment(form.form.value[property]).format('YYYY-MM-DD') : '';
      }
    }

    // Build query params.
    this.queryParams = [];
    for (const property in form.form.value) {
      if (form.form.value[property] && form.form.value[property] != "") {
        this.queryParams.push({ parameter: property, value: form.form.value[property] })
      }
    }

    // Build the route params.
    this.routeParams = new Object;
    for (const property in form.form.value) {
      if (form.form.value[property] && form.form.value[property] != "") {
        // let append = { {property}: form.form.value[property] }
        this.routeParams = {
          ...this.routeParams,
          [property]: form.form.value[property]
        }
      }
    }

    // Update the query parameters, so the link can be copied.
    // this.updateRouterParams(form.form.value);
    this.updateRouterParams(this.routeParams);
  }

  getData(params: { parameter: string; value: string; }[]) {
  }

  /**
   * Sets the query parameters in the URL.
   *
   * @param queryParams
   */
  private isUpdatingParams = false;

  updateRouterParams(routeParams?: any) {
    console.log('updateRouterParams called with:', routeParams);

    if (this.isUpdatingParams) {
      console.log('updateRouterParams already in progress, skipping update');
      return;
    }

    if (routeParams) {
      if (!routeParams.hasOwnProperty('page')) {
        console.log('Adding default page parameter');
        routeParams = {
          ...routeParams,
          page: '0',
        };
      }

      this.isUpdatingParams = true;

      console.log('Navigating with query params:', routeParams);
      this._router.navigate([], {
        relativeTo: this._activatedRoute,
        queryParams: routeParams,
        queryParamsHandling: 'merge'
      }).finally(() => {
        this.isUpdatingParams = false;
        console.log('Navigation complete, isUpdatingParams reset');
      });

      // Convert route params to queryParams
      this.queryParams = [];
      for (const property in routeParams) {
        if (property !== 'timestamp') {
          this.queryParams.push({
            parameter: property, value: routeParams[property]
          });
        }
      }
      console.log('Updated queryParams:', this.queryParams);
    } else {
      console.log('No route params provided');
    }
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  ngOnInit(): void {
    // Check for query params.
    this.subscription = this._activatedRoute.queryParams
      .subscribe((routeParams: Params) => {
        if (routeParams) {
          // Ignore route params when inside student dashboard.
          if (this.constructor.name == 'LessonStudentReportComponent' || 'NonUnitLessonsComponent' || 'PaymentReportsComponent') {
          } else {
            this.updateRouterParams(routeParams);
          }
        }

        // Load the data.
        this.getData(this.queryParams)
      });
  }

  updatePaginationConfig(dataRows) {
    // Update pageSize
    if (dataRows == undefined) {
      this.pageSize = 0;
    } else {
      this.pageSize = dataRows.length
    }

    if (dataRows && this?.paginator) {
      this.paginator.length = dataRows?.length ?? '';
      this.paginator.pageSize = this.pageSize;
      this.paginator.pageIndex = this.paginationIndex;
    }
  }

  /**
   * Handle sort parameters here. Rely on API to sort.
   *
   * @param sort
   */
  sortData(sort: Sort) {
    this.sortParams =
    {
      // toUppercase() is needed here.
      stype: sort.direction.toUpperCase(),
      sort: sort.active
    }

    // Don't forget the existing parameters.
    // console.log('this.sortParams')
    // console.log(this.sortParams)
    // if (this.sortParams) {
    //   this.routeParams = {
    //     ...this.routeParams,
    //     ...
    //   }
    // }

    this.updateRouterParams(this.sortParams)
  }

  /**
   * Handle sort parameters here for Drupal Views.
   *
   * @param sort
   */
  sortDataView(sort: Sort) {
    if (sort.direction == '' || sort.active == '') {
      this.sortParams = {};
      delete this.routeParams.sort_order;
      delete this.routeParams.sort_by;
    } else {
      this.sortParams =
      {
        // toUppercase() is needed here.
        sort_order: sort.direction.toUpperCase(),
        sort_by: sort.active
      }
    }

    // Don't forget the existing parameters.
    if (this.routeParams) {
      this.routeParams = {
        ...this.routeParams,
        ...this.sortParams
      }
    } else {
      this.routeParams = {
        ...this.sortParams
      }
    }

    this.updateRouterParams(this.routeParams)
  }

  onPageSizeOptions() {
    return [25];
  }

  /**
   * Handle pagination.
   *
   * @param event
   */
  pageChanged(event: PageEvent) {
    console.log('pageChanged called...');

    let paginationParams = {
      page: event.pageIndex.toString(),
      timestamp: new Date().getTime().toString()
    };

    this.updateRouterParams({ ...this.routeParams, ...paginationParams });
  }

  /**
   * Handle when a user wants to print the page. Shortcut for CTRL + P.
   */
  onPrint() {
    window.print();
  }

  /**
   * Handle onDownloadCSV click.
   */
  onDownloadCSV() {
    this.downloadFile(this.data['#events']);
  }

  /**
   * Download a CSV using a Drupal View export.
   */
  downloadCSVDrupalViews(route: string, filename: string, params: any) {
    this.isLoading = true; // Start loading
    console.log('param', params);

    // Process the params for start and end dates.
    const processedParams = this.processParams(params);
    // Append _format=csv to the route correctly
    const csvRoute = route.includes('?') ? `${route}&_format=csv` : `${route}?_format=csv`;

    this._drupalRESTService.downloadFile(csvRoute, params)
      .subscribe((response: any) => {
        let dataType = response.type;
        let binaryData = [];
        binaryData.push(response.body);
        let downloadLink = document.createElement('a');
        downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, { type: dataType }));
        if (filename) {
          downloadLink.setAttribute('download', filename + '.csv');
        }
        document.body.appendChild(downloadLink);
        downloadLink.click();
        this.isLoading = false;
      }, error => {
        console.error(error);
        this.isLoading = false;
      });
  }

  /**
   * Quick & dirty way to get CSV's working.
   */
  downloadFile(data: any) {
    const replacer = (key: any, value: any) => value === null ? '' : value; // specify how you want to handle null values here
    const header = Object.keys(data[0]);
    let csv = data.map((row: { [x: string]: any; }) => header.map(fieldName => JSON.stringify(row[fieldName], replacer)).join(','));
    csv.unshift(header.join(','));
    let csvArray = csv.join('\r\n');

    var blob = new Blob([csvArray], { type: 'text/csv' })
    saveAs(blob, "data.csv");
  }

  /**
   * Reset any parameters and clear form and state data.
   */
  resetForm() {
    // Navigate to the current location, to clear any parameters that may be set.
    // this._router.navigate([window.location.pathname.split('/')[2]]);

    // Clear Params
    this.queryParams = [];

    this.updateRouterParams(this.queryParams);

    // Clear the form.
    this.form.resetForm();

    // Clear the sort direction in the table. See https://github.com/angular/components/issues/10242
    // this.sort.sort({ id: '', start: 'asc', disableClear: false });

    // Reset pageSize.
    this.pageSize = 0;
  }

  /**
   * Nuke the results in a mat data table.
   */
  clearFormTableData() {
    this.dataSource = null;
    this.data = null;
  }

  onAutocomplete(term: string, eckType: string, bundle: string, field: string) {
    return this._utilityService.getAMTAbstractAutocomplete(term, eckType, bundle, field);
  }

  onViewsAutocompleteFiltersCategory($event: { target: { value: string; }; }) {
    this._utilityService.getViewsAutocompleteFilters()
      .subscribe(data => {
        this.autoCompleteCategoryOptions = data;
      })
  }

  onViewsAutocompleteEmail($event: { target: { value: string; }; }) {
    this._utilityService.getViewsAutocompleteFilter("inquiry", "page_1", "field_email_value", $event.target.value)
      .subscribe(data => {
        console.log(data);
        this.autoCompleteOptions = data;
      })
  }

  onViewsAutocompleteFiltersID($event: { target: { value: string; }; }) {
    this._utilityService.getViewsAutocompleteFilter("payment_reports", "page_1", "field_payment_id_value", $event.target.value)
      .subscribe(data => {
        console.log(data);
        this.autoCompletePaymentIDOptions = data;
      })
  }

  // Handle the autocomplete for package field.
  onViewsAutocompletePackages($event: { target: { value: string; }; }) {
    this._utilityService.getViewsAutocompleteFilter("enrollment", "page_1", "field_package_name_value", $event.target.value)
      .subscribe(data => {
        this.autoCompletePackageOptions = data;
      })
  }

  // Handle the autcomplete for customer name field.
  onViewsAutocompleteCustomerName($event: { target: { value: string; }; }) {
    this._utilityService.getViewsAutocompleteFilter("payment_reports", "page_1", "field_customer_name_value", $event.target.value)
      .subscribe(data => {
        this.autocompleteCustomerNameOptions = data;
      })
  }

  // Handle the autcomplete for student field.
  onFirstNameInput($event: { target: { value: string; }; }) {
    this._utilityService.getViewsAutocompleteFilter("inquiry", "page_1", "field_first_name_value", $event.target.value)
      .subscribe(data => {
        this.autoCompleteFirstNameOptions = data;
      })
  }

  onLastNameInput($event: { target: { value: string; }; }) {
    this._utilityService.getViewsAutocompleteFilter("inquiry", "page_1", "field_last_name_value", $event.target.value)
      .subscribe(data => {
        console.log(data);
        this.autoCompleteLastNameOptions = data;
      })
  }

  onFirstLastNameInput($event: { target: { value: string; }; }) {
    let term: string = $event.target.value;
    let eckType: string = 'user';
    let bundle: string = '';
    let field: string = 'filterBasedOnNameAndLastName';

    this.onAutocomplete(term, eckType, bundle, field)
      .subscribe(data => {
        this.autoCompleteFirstLastNameOptions = data;
      });
  }

  onEnrollmentAutocomplete($event: { target: { value: string; }; }) {
    this._utilityService.getAutocompleteEnrollment($event)
      .subscribe(data => {
        this.autoCompleteEnrollmentOptions = data;
      });
  }

  // Handle the autocomplete for instructor field.
  onAutocompleteInstructor($event: { target: { value: string; }; }) {
    let term: string = $event.target.value;
    let eckType: string = 'user';
    let bundle: string = 'instructor';
    let field: string = 'filterBasedOnNameAndLastName';

    this.onAutocomplete(term, eckType, bundle, field)
      .subscribe(data => {
        this.autocompleteInstructorOptions = data;
      });
  }


  // Handle the autocomplete for student filter field.
  onAutocompleteStudentAccount($event: { target: { value: string; }; }) {
    let term: string = $event.target.value;
    let eckType: string = "student_accounts"; // student_accounts
    let bundle: string = "student_account"; // student_account
    let field: string = "title";

    this.onAutocomplete(term, eckType, bundle, field)
      .subscribe(data => {
        this.autoCompleteStudentAccountOptions = data;
      })
  }

  openEntityComponent(component: ComponentType<unknown>, eckType: any, bundle: any, action: any, EntityID?: any, fieldsData?: {}) {
    this._dialogService.openDialog(component, "defaultWithData", {
      data: {
        EntityID: EntityID,
        eckType: eckType,
        bundle: bundle,
        action: action,
        fieldsData: fieldsData ?? '',
      },
    }).afterClosed().subscribe(data => {
      // this.refreshCalendar();
      this.getData(this.queryParams);
    });
  }

  openEntityComponentPayment(component: ComponentType<unknown>, eckType: any, bundle: any, action: any, EntityID?: any, fieldsData?: {}) {
    console.log('openEntityComponentPayment called...')
    this._dialogService.openDialog(component, "paymentFormWithData", {
      data: {
        EntityID: EntityID,
        eckType: eckType,
        bundle: bundle,
        action: action,
        fieldsData: fieldsData ?? '',
      },
    }).afterClosed().subscribe(data => {
      // this.refreshCalendar();
      this.getData(this.queryParams);
    });
  }

  openEntityComponentXL(component: ComponentType<unknown>, eckType: any, bundle: any, action: any, EntityID?: any, fieldsData?: {}) {
    this._dialogService.openDialog(component, "defaultXL", {
      data: {
        EntityID: EntityID,
        eckType: eckType,
        bundle: bundle,
        action: action,
        fieldsData: fieldsData ?? '',
      },
    }).afterClosed().subscribe(data => {
      // this.refreshCalendar();
      this.getData(this.queryParams);
    });
  }

  /**
   * Utility to open dialogs with a default configuration.
   *
   * @param formName
   * @param dialogConfig
   */
  openDialog(component: ComponentType<unknown>, dialogConfig = 'defaultWithData', data?) {
    let dialogRef = this._dialogService.openDialog(component, dialogConfig ?? "defaultWithData", {
      data,
    }).afterClosed().subscribe(data => {
      // this.refreshCalendar();
    });
  }

  /**
   * Handle common parameters for Drupal Views.
   *
   * @param params
   * @returns
   */
  processParams = (params: any): HttpParameter[] => {
    let start_date = "";
    let end_date = "";
    let field_next_scheduled_payment;

    if (params) {
      if (params['start_date']) {
        start_date = moment(params['start_date']).format('YYYY-MM-DD').toString();
      }

      if (params['end_date']) {
        end_date = moment(params['end_date']).format('YYYY-MM-DD').toString();
      }

      field_next_scheduled_payment = {
        parameter: 'field_payment_date',
        value: start_date + "--" + end_date,
      };
    }

    const processedParams: HttpParameter[] = [];

    if (field_next_scheduled_payment && field_next_scheduled_payment['value'] !== 'undefined--undefined') {
      processedParams.push(field_next_scheduled_payment);
    }

    return processedParams;
  };

  setupPagination(data: any, params: { parameter: string; value: string; }[]) {
    const pageParam = params.find(param => param.parameter === 'page');
    this.paginator.length = data['count'];
    this.paginator.pageSize = this.pageSize;
    this.paginator.pageIndex = pageParam ? parseInt(pageParam.value, 10) : 0;

    // Convert params to router params format and update URL
    const routerParams = params.reduce((acc, param) => ({
      ...acc,
      [param.parameter]: param.value
    }), {});

    if (routerParams) {
      // Check if the current route parameters are the same as the new ones
      const currentParams = this._activatedRoute.snapshot.queryParams;
      const isSameParams = Object.keys(routerParams).every(key => currentParams[key] === routerParams[key]);

      if (isSameParams) {
        console.log('Route parameters unchanged, skipping navigation');
        return;
      }
      this.updateRouterParams(routerParams);
    }
  }


  // override handleError(error: any) {
  //   console.log("handleError called...");
  //   console.log(error);
  //   this.ShowProgressBar = false;

  //   this.errorMessage = "There was an error.";
  // }

  getPaymentIcon(paymentType: string): string {
    switch (paymentType) {
      case 'visa':
        return 'fab fa-cc-visa';
      case 'mastercard':
        return 'fab fa-cc-mastercard';
      case 'amex':
        return 'fab fa-cc-amex';
      case 'discover':
        return 'fab fa-cc-discover';
      default:
        return '';
    }
  }

}
