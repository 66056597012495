<div class="full-screen-payment-container">
  <div class="payment-content">
    <h3 class="text-center" *ngIf="!paymentStatus">
      Please initiate the payment of {{ amount | currency:currency }} on the Square Terminal.
    </h3>
    <h3 class="text-center" *ngIf="paymentStatus === 'PENDING'">
      Payment in progress. Please complete the transaction on the Square Terminal.
    </h3>
    <h3 class="text-center" *ngIf="paymentStatus === 'SUCCESS'">
      Payment completed successfully!
    </h3>
    <h3 class="text-center" *ngIf="paymentStatus === 'FAILURE'">
      Payment failed. Please try again.
    </h3>
    <h3 class="text-center" *ngIf="paymentStatus === 'CANCELED'">
      Payment was canceled.
    </h3>

    <mat-progress-bar mode="indeterminate" *ngIf="isProcessing || paymentStatus === 'PENDING'"></mat-progress-bar>

    <div *ngIf="paymentStatus" class="payment-status mt-3"
         [ngClass]="{
           'text-success': paymentStatus === 'SUCCESS',
           'text-warning': paymentStatus === 'PENDING',
           'text-danger': paymentStatus === 'FAILURE',
           'text-info': paymentStatus === 'CANCELED'
         }">
      <mat-icon>
        {{ paymentStatus === 'SUCCESS' ? 'check_circle' :
           paymentStatus === 'PENDING' ? 'hourglass_empty' :
           paymentStatus === 'CANCELED' ? 'cancel' : 'error' }}
      </mat-icon>
      {{ paymentStatusMessage }}
    </div>

    <div *ngIf="checkoutId" class="checkout-id mt-2">
      Checkout ID: {{ checkoutId }}
    </div>

    <div *ngIf="amount" class="amount-display mt-2">
      Amount: {{ amount | currency:currency:'symbol':'1.2-2' }}
    </div>

    <button mat-raised-button
            color="primary"
            (click)="onCapturePaymentClick()"
            [disabled]="isProcessing || paymentStatus === 'PENDING'"
            *ngIf="!paymentStatus || paymentStatus === 'FAILURE'"
            type="button">
      <mat-icon>point_of_sale</mat-icon>
      Create Terminal Checkout
    </button>

    <button mat-raised-button
            color="warn"
            (click)="onCancelPaymentClick()"
            [disabled]="isProcessing || paymentStatus !== 'PENDING'"
            *ngIf="paymentStatus === 'PENDING'"
            type="button">
      <mat-icon>cancel</mat-icon>
      Cancel Checkout
    </button>

    <button mat-raised-button
            color="primary"
            (click)="onClose()"
            *ngIf="paymentStatus === 'SUCCESS' || paymentStatus === 'CANCELED'"
            type="button">
      <mat-icon>check</mat-icon>
      Close
    </button>

  </div>

</div>
