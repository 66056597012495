<!-- <mat-expansion-panel>
	<mat-expansion-panel-header>
			<mat-panel-title>
					Filter
			</mat-panel-title>
			<mat-panel-description>
					<app-filter-results-panel [parameters]=queryParams>
					</app-filter-results-panel>
			</mat-panel-description>
	</mat-expansion-panel-header>
	<form (ngSubmit)="onSubmit(f)" #f=ngForm class="form">
			<div class="row">

					<div class="col-2">
							<mat-form-field class="full-width-field">
									<mat-label>Payment ID</mat-label>
									<input
											matInput
											ngModel
											name="field_payment_id_value"
											(blur)="onSubmit(f)"
											(selectionChange)="onSubmit(f)">
							</mat-form-field>
					</div>

					<div class="col-2">
							<mat-form-field class="full-width-field">
									<mat-label>Customer Name</mat-label>
									<input
											matInput
											ngModel
											name="field_customer_name_value"
											(blur)="onSubmit(f)"
											(selectionChange)="onSubmit(f)">
							</mat-form-field>
					</div>

					<div class="col-2">
							<mat-form-field class="full-width-field">
									<mat-label>Choose a date</mat-label>
									<input
											matInput
											[matDatepicker]="pickerMin"
											name="field_date_and_time_value[min]"
											ngModel
											(dateChange)="onSubmit(f)">
									<mat-datepicker-toggle matSuffix [for]="pickerMin"></mat-datepicker-toggle>
									<mat-datepicker #pickerMin></mat-datepicker>
							</mat-form-field>
					</div>

					<div class="col-2">
							<mat-form-field class="full-width-field">
									<mat-label>Through</mat-label>
									<input
											matInput
											[matDatepicker]="pickerMax"
											name="field_date_and_time_value[max]"
											ngModel
											(dateChange)="onSubmit(f)">
									<mat-datepicker-toggle matSuffix [for]="pickerMax"></mat-datepicker-toggle>
									<mat-datepicker #pickerMax></mat-datepicker>
							</mat-form-field>
					</div>


					<div class="col-2">
							<mat-form-field class="full-width-field">
									<mat-label>Status</mat-label>
									<mat-select
											matInput
											ngModel
											name="field_status_value"
											(blur)="onSubmit(f)"
											(selectionChange)="onSubmit(f)">
											<mat-option value="All" selected="selected">- Any -</mat-option>
											<mat-option value="Paid">Paid</mat-option>
									</mat-select>
							</mat-form-field>
					</div>

					<section class="section">

							<div class="float-end bring-inline">
									<button (click)="resetForm()" color="primary"
											mat-flat-button
											class="me-1" mat-button>Clear
									</button>
							</div>

					</section>


			</div>


			<section class="section">
			</section>

	</form>

</mat-expansion-panel> -->

<mat-progress-bar *ngIf="ShowProgressBar" mode="indeterminate"></mat-progress-bar>
<div class="row">
	<div class="col-md-12 mt-3">
			<div class="float-end">
					<!-- <button (click)="onPrint()" color="primary" mat-flat-button class="me-1" mat-button>Print</button> -->
					<!-- <button (click)="onDownloadCSV()" color="primary" mat-flat-button class="me-1 warning" mat-button>CSV</button> -->
			</div>
	</div>
</div>

<!-- <mat-form-field appearance="standard">
<mat-label>Filter</mat-label>
<input matInput (keyup)="applyFilter($event)" placeholder="" #input>
</mat-form-field> -->

<!-- <div class="example-container mat-elevation-z8">
<div class="loading-shade"
*ngIf="ShowProgressBar">
<mat-spinner *ngIf="ShowProgressBar"></mat-spinner>
<div class="example-rate-limit-reached" *ngIf="isRateLimitReached">
	No limit
</div>
</div>
</div> -->

<div class="table-container">

	<div class="lessons-dropdown" style="width:150px; margin-bottom:-27px;padding-bottom: 0px;">
		<!-- <label>Show the next</label> -->
		<mat-form-field appearance="outline" style="margin-bottom:0px;padding-bottom: 0px;">
				<mat-label>Show the next</mat-label>
				<mat-select [(value)]="pageSize" (selectionChange)="getData()" style="margin-bottom:0px;">
						<mat-option value="5">5</mat-option>
						<mat-option value="10">10</mat-option>
						<mat-option value="15">15</mat-option>
				</mat-select>
		</mat-form-field>
</div>

	<table mat-table #displayTable [dataSource]="dataSource"
			matSort
			(matSortChange)="sortData($event)"
			class="mt-4">

			<!-- field_date_and_time Column -->
			<ng-container matColumnDef="date">
					<th mat-header-cell *matHeaderCellDef
							sortActionDescription="Sort by Student Name">
							Date
					</th>
					<td mat-cell *matCellDef="let element"> {{ element.field_date_and_time | date }} </td>
			</ng-container>

			<!-- time Column -->
			<ng-container matColumnDef="time">
					<th mat-header-cell *matHeaderCellDef
							sortActionDescription="Sort by Payment ID">
							Time
					</th>
					<td mat-cell *matCellDef="let element"> {{element.field_date_and_time_1}}
					</td>
			</ng-container>

			<!-- instructorName Column -->
			<ng-container matColumnDef="instructorName">
					<th mat-header-cell *matHeaderCellDef
							sortActionDescription="Sort by Customer Name">
							Instructor Name
					</th>
					<td mat-cell *matCellDef="let element"> {{ element.instructor }} </td>
			</ng-container>

			<!-- lessonCategory Column -->
			<ng-container matColumnDef="lessonCategory">
					<th mat-header-cell *matHeaderCellDef
							sortActionDescription="Sort by Lesson Category">
							Lesson Category
					</th>
					<td mat-cell *matCellDef="let element"> {{ element.field_type }} </td>
			</ng-container>

			<!-- enrollmentCategory Column -->
			<ng-container matColumnDef="enrollmentCategory">
					<th mat-header-cell *matHeaderCellDef
							sortActionDescription="Sort by Payment Amount">
							Enrollment Category
					</th>
					<td mat-cell *matCellDef="let element"> {{
							element.field_category }}
					</td>
			</ng-container>


			<!-- Edit  -->
			<!-- <ng-container matColumnDef="edit">
					<th mat-header-cell *matHeaderCellDef>Edit</th>
					<td mat-cell *matCellDef="let element">
							<button mat-stroked-button
									(click)="openEntityComponent(PaymentsEntityComponent, 'payments', 'payment', 'edit', element.id)"
									mat-button>
									Edit
							</button>
					</td>
			</ng-container> -->

			<!-- Row shown when there is no matching data. -->
			<tr class="mat-row" *matNoDataRow>
				<td class="mat-cell" colspan="4">No upcoming lessons.</td>
			</tr>

			<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
			<tr mat-row matRipple *matRowDef="let row; columns: displayedColumns;"></tr>
	</table>

	<!-- <mat-paginator
	 	  [disabled]="ShowProgressBar"
			(page)="pageChanged($event)"
			[pageSizeOptions]="onPageSizeOptions()"
			[length]="30"
			[pageSize]="paginationTotalPages"
			[pageIndex]="paginationIndex"
			aria-label="Select page of view"></mat-paginator> -->

</div>
