<!-- license-agreement-dialog.component.html -->
<div
	class="container d-flex justify-content-center align-items-center min-vh-100">
	<div class="card w-75">
		<div class="card-header bg-primary text-white text-center">
			<h2>License Agreement</h2>
		</div>
		<div class="card-body">
			<div class="scrollable-box" (scroll)="onScroll()" #scrollableBox style="height: 300px; overflow-y: scroll;">
				<p>

					<div class="WordSection1">

						<p class="DocumentTitle"><a name="a000008"><u><span
										style="font-size:12.0pt">AGENDA
										MASTER – TERMS OF USE</span></u></a><u><span
									style="font-size:12.0pt"></span></u></p>

						<p class="Para" style="text-align:justify">Please read this Agenda Master
							– Terms
							of Use (this “<b>Agreement</b>”) before installing the Agenda Master
							software (<span style="mso-font-kerning:14.0pt">the
								“<b>Software</b>”)</span>. All references
							in this Agreement to (i) “<b>Licensee</b>” means the franchisee who is
							hereby
							acquiring the license to the Software in connection with the operation of
							its
							Arthur Murray studio (the “<b>Studio</b>”); (ii) “<b>Licensor</b>” means
							<span style="color:windowtext">Arthur Murray International, Inc</span><u
								style="text-underline:blue double"><span
									style="color:blue">.,</span></u><span style="color:windowtext"> a
								Delaware corporation; and (iii) </span>“<b>Effective
								Date</b>” means the date on which Licensee installed the Software on its
							computer or other similar device pursuant to this Agreement<span
								style="color:windowtext">. </span>Licensor and Licensee may be referred
							to
							herein collectively as the “<b
								style="mso-bidi-font-weight:normal">Parties</b>”
							or individually as a “<b
								style="mso-bidi-font-weight:normal">Party</b>.”</p>

						<p class="SFPara-Clause" style="margin-left:0in;page-break-after:avoid;
						mso-list:l35 level1 lfo46"><a
								name="a000133"><!--[if !supportLists]--><span
									style="mso-list:Ignore">1.<span
										style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
									</span></span><!--[endif]--><span
									class="SFParasubclause1Char"><u>License</u></span>.
							</a></p>

						<p class="SFParasubclause1"
							style="text-align:justify;page-break-after:avoid;
						mso-list:l35 level2 lfo2"><a name="a000134"><!--[if !supportLists]--><span
									style="mso-list:Ignore">(a)<span
										style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
									</span></span><!--[endif]--><u>License Grant</u>. Subject to terms and
								conditions
								of this Agreement, Licensor hereby grants Licensee a location specific,
								non-exclusive,
								non-sublicensable, and non-transferable license during the Term to use
								the
								Software solely <span style="mso-font-kerning:14.0pt">in connection with
									the
									operation of the Studio.</span></a><span
								style="mso-font-kerning:14.0pt"> </span>Pursuant
							to the terms of this Agreement, Licensor hereby grants to Licensee a
							non-exclusive, non-sublicensable, non-transferable license during the
							Term to
							use user manuals, handbooks, and installation guides relating to the
							Software
							that are provided by Licensor to Licensee (collectively, the
							“<b>Documentation</b>”
							and together with the Software, as the “<b>Licensor Property</b>”).</p>

						<p class="SFParasubclause1"
							style="text-align:justify;mso-list:l35 level2 lfo2"><a
								name="a000013"><!--[if !supportLists]--><span
									style="mso-list:Ignore">(b)<span
										style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
									</span></span><!--[endif]--><u>Use Restrictions</u>. Licensee shall not
								use the Licensor
								Property for any purposes beyond the scope of the license granted in
								this
								Agreement. Without limiting the foregoing and except as otherwise
								expressly set
								forth in this Agreement, Licensee shall not at any time, directly or
								indirectly: (i) copy, modify, or create derivative works of the Licensor
								Property, in whole or in part; (ii) rent, lease, lend, sell, sublicense,
								assign, distribute, publish, transfer, or otherwise make available the
								Licensor
								Property; (iii) reverse engineer, disassemble, decompile, decode, adapt,
								or
								otherwise attempt to derive or gain access to the source code of the
								Software,
								in whole or in part; (iv) remove any proprietary notices from the
								Licensor
								Property; or (v) use the Software in any manner or for any purpose that
								infringes, misappropriates, or otherwise violates any intellectual
								property
								right or other right of any person, or that violates any applicable
								law.</a>
							The Software may only be installed or used on the computer hardware that
							is
							approved by Licensor from time to time for use in connection with the
							operation
							of the Studio. </p>

						<p class="SFParasubclause1"
							style="text-align:justify;mso-list:l35 level2 lfo2"><a
								name="a000018"><!--[if !supportLists]--><span
									style="mso-list:Ignore">(c)<span
										style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
									</span></span><!--[endif]--><u>Reservation of Rights</u>. Licensor
								reserves all
								rights not expressly granted to Licensee in this Agreement. Except for
								the
								limited rights and licenses expressly granted under this Agreement,
								nothing in
								this Agreement grants, by implication, waiver, estoppel, or otherwise,
								to Licensee
								or any third party any intellectual property rights or other right,
								title, or
								interest in or to Licensor Property.</a></p>

						<p class="SFParasubclause1"
							style="margin-top:0in;text-align:justify;mso-list:
						l35 level2 lfo2"><a name="a000022"></a><a name="a000136"><span
									style="mso-bookmark:
						a000022"><!--[if !supportLists]--><span style="mso-list:Ignore">(d)<span
											style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
										</span></span><!--[endif]--><u>Suspension</u>. Notwithstanding
									anything to the
									contrary in this Agreement, Licensor reserves the right to suspend
									Licensee’s
									access to any portion or </span></a><span class="GramE"><span
									style="mso-bookmark:
						a000136"><span style="mso-bookmark:a000022">all
										of</span></span></span><span style="mso-bookmark:a000136"><span
									style="mso-bookmark:a000022"> the Licensor
									Property if suspension is advisable in its reasonable judgement.<span
										style="mso-spacerun:yes">&nbsp; </span>Without limitation and by way
									of example
									only, Licensor may suspend access to any portion or all of the Licensor
									Property, if: (i) Licensor reasonably determines that (I) there is a
									threat of
									harm to or actual harm to any of the components comprising Licensor
									Property; (II)
									Licensee’s or its users’ use of the Licensor Property disrupts or poses
									a risk
									to any of the components comprising the Licensor Property or to third
									parties;
									(III) Licensee or its user are using the Licensor Property for
									unauthorized, fraudulent
									or illegal activities; (IV) Licensee has ceased to continue its
									business in the
									ordinary course, made an assignment for the benefit of creditors or
									similar
									disposition of its assets, or become the subject of any bankruptcy,
									reorganization, liquidation, dissolution, or similar proceeding; (V)
									suspension
									is advisable for purposes of conducting either routine or emergency
									maintenance; or (VI) Licensor’s provision of the Licensor Property is
									prohibited by applicable law or should be suspended to accommodate
									superior
									third-party rights or any governmental investigation; (ii) any vendor
									of Licensor
									has suspended or terminated Licensor’ access to or use of any
									third-party services
									or products required to enable Licensee to access the Licensor
									Property; or
									(iii) in response to a Licensee breach or default of the terms of this
									Agreement (any such suspension described in subclause (i), (ii), or
									(iii), a “<b style="mso-bidi-font-weight:normal">Service
										Suspension</b>”). Licensor shall
									use commercially reasonable efforts to provide Licensee with updates
									regarding
									resumption of access following any Service Suspension. Licensor shall
									use
									commercially reasonable efforts to resume providing access as soon as
									reasonably possible after the event giving rise to the Service
									Suspension is
									cured. Licensor will have no liability for any cost, expense, damage,
									liabilities, losses (including any loss of data or profits), or any
									other
									consequences that Licensee may incur <span class="GramE">as a result
										of</span> a
									Service Suspension. </span></span><span
								style="mso-bookmark:a000022"></span></p>

						<p class="SFPara-Clause"
							style="margin-left:0in;mso-list:l35 level1 lfo2"><span
								style="mso-bookmark:a000022"><!--[if !supportLists]--><span
									style="mso-list:Ignore">2.<span
										style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
									</span></span><!--[endif]--><u>Licensee
									Responsibilities</u>.</span></p>

						<p class="SFParasubclause1"
							style="text-align:justify;mso-list:l35 level2 lfo2"><a
								name="a000137"><!--[if !supportLists]--><span
									style="mso-list:Ignore">(a)<span
										style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
									</span></span><!--[endif]--><u>General</u>. Licensee is responsible and
								liable for
								all uses of the Licensor Property resulting from access provided by
								Licensee,
								directly or indirectly, whether such access or use is permitted by or in
								violation of this Agreement. Without limiting the generality of the
								foregoing,
								Licensee is responsible for all acts and omissions of its users, and any
								act or
								omission by Licensee’s users that would constitute a breach of this
								Agreement
								if taken by Licensee will be deemed a breach of this Agreement by
								Licensee.
								Licensee shall make all its users aware of this Agreement's provisions
								and take
								reasonable efforts to cause it users to comply with such
								provisions.</a></p>

						<p class="SFParasubclause1"
							style="margin-top:0in;text-align:justify;mso-list:
						l35 level2 lfo2"><a name="a000139"><!--[if !supportLists]--><span
									style="mso-list:
						Ignore">(b)<span
										style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
									</span></span><!--[endif]--><u>Passwords; Access Controls</u>. Licensee
								shall be
								responsible for the safekeeping, proper use and management of all
								passwords or
								other access controls to the Software to be used by Licensee and its
								users.<span style="mso-spacerun:yes">&nbsp; </span>Licensee shall
								implement adequate security
								controls to ensure that all passwords and access controls are made
								available
								only to authorized users for the uses permitted under this
								Agreement.<span style="mso-spacerun:yes">&nbsp; </span>If Licensee
								learns of any loss or
								unauthorized use of such passwords or access controls, Licensee shall
								immediately notify Licensor of the same and reasonably cooperate in the
								investigation of the incident and take such steps as Licensor may
								require.<span style="mso-spacerun:yes">&nbsp;&nbsp;&nbsp;
								</span></a></p>

						<p class="SFParasubclause1"
							style="text-align:justify;mso-list:l35 level2 lfo2"><span
								style="mso-bookmark:a000139"><!--[if !supportLists]--><span
									style="mso-list:Ignore">(c)<span
										style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
									</span></span><!--[endif]--><u>Third-Party Products</u>.
							</span>Licensor may from
							time to time make third-party products, software, or data that are
							provided
							with, incorporated <span class="GramE">into</span> or integrated with the
							Software (collectively, the “<b>Third-Party Products</b>”) available to
							Licensee
							as part of the Software. Such Third-Party Products may be subject to
							their own
							terms and conditions, which will be made available to Licensee in
							reasonable
							form. If Licensee uses the Third-Party Products, which may be required by
							Licensor,
							Licensee must abide by the applicable terms and conditions for such
							Third-Party
							Products.</p>

						<p class="SFPara-Clause"
							style="margin-left:0in;text-align:justify;mso-list:l35 level1 lfo2"><a
								name="a000030"></a><a name="a000023"><span
									style="mso-bookmark:a000030"><!--[if !supportLists]--><span
										style="mso-list:Ignore">3.<span
											style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
										</span></span><!--[endif]--><u>Availability; Support;
										Replacement</u>.</span></a><span
								style="mso-bookmark:a000030"></span></p>

						<p class="SFParasubclause1"
							style="margin-top:0in;text-align:justify;mso-list:
						l35 level2 lfo2"><span style="mso-bookmark:a000030"><a
									name="a000211"><!--[if !supportLists]--><span
										style="mso-list:Ignore">(a)<span
											style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
										</span></span><!--[endif]--><u>Availability</u>. Subject to the terms
									and
									conditions of this Agreement, Licensor shall use reasonable efforts as
									it
									determines from time to time in its discretion to make the Software
									available
									to Licensee. Licensee acknowledges, however, that the Software may
									experience
									periods of downtime or unavailability, and that Licensor makes no
									assurances,
									guarantees, representations or warranties regarding their uptime or
									availability. The Software are provided on an “AS-IS, AS AVAILABLE”
									basis
									only.<span style="mso-spacerun:yes">&nbsp; </span></a></span></p>

						<p class="SFParasubclause1"
							style="margin-top:0in;text-align:justify;mso-list:
						l35 level2 lfo2"><span style="mso-bookmark:a000030"><a
									name="a000212"><!--[if !supportLists]--><span
										style="mso-list:Ignore">(b)<span
											style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
										</span></span><!--[endif]--><u>Maintenance &amp; Support</u>. Licensor
									shall use
									reasonable efforts as it determines from time to time in its discretion
									to
									provide maintenance and support for the Software. Maintenance may
									include such
									updates, patches, </a><span class="GramE"><span
										style="mso-bookmark:a000212">fixes</span></span><span
									style="mso-bookmark:a000212"> and releases for the Software as Licensor
									elects
									to make available for the Software from time to time.<span
										style="mso-spacerun:yes">&nbsp; </span>Support may include help desk
									support
									available through remote means (either electronically or
									telephonically)
									through Licensor or its designated support agent.<span
										style="mso-spacerun:yes">&nbsp; </span>Licensor reserves the right to
									suspend,
									modify, <span class="GramE">terminate</span> or otherwise revise its
									maintenance
									and support program as it deems fit and will use commercially
									reasonable means
									to communicate any material changes to Licensee as they occur. Licensor
									makes
									no assurances, guarantees, representations or warranties regarding the
									scope or
									availability of maintenance and support services and provides the same
									to Licensee
									on an “AS-IS, AS AVAILABLE” basis only. </span><span
									style="background:yellow;
						mso-highlight:yellow"><span
										style="mso-spacerun:yes">&nbsp;&nbsp;</span></span><a
									name="a000193"><span
										style="mso-spacerun:yes">&nbsp;</span></a></span></p>

						<p class="SFParasubclause1"
							style="margin-top:0in;text-align:justify;mso-list:
						l35 level2 lfo2"><span
								style="mso-bookmark:a000030"><!--[if !supportLists]--><span
									style="mso-list:Ignore">(c)<span
										style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
									</span></span><!--[endif]--><u>Modifications</u>. Licensor may in the
								future elect
								to modify, enhance, redesign, discontinue or offer a substitute
								replacement
								service for the Software in whole or in part.<span
									style="mso-spacerun:yes">&nbsp;
								</span>Licensor may require Licensee to license, purchase, implement and
								utilize any modifications to the Software.<span
									style="mso-spacerun:yes">&nbsp;
								</span>Licensee shall adopt and implement such replacement service upon
								Licensor’s
								request. Licensor reserves the right to charge fees for the foregoing
								and to
								change the fees from time to time.<span style="mso-spacerun:yes">&nbsp;
								</span>Further, Licensor may cease to make the Software available to
								Licensee
								and require Licensee to obtain substitute point of sale systems or
								services
								from a third-party vendor at Licensee’s cost.<span
									style="mso-spacerun:yes">&nbsp;&nbsp;&nbsp;
								</span></span></p>

						<p class="SFPara-Clause"
							style="margin-left:0in;mso-list:l35 level1 lfo2"><span
								style="mso-bookmark:a000030"><!--[if !supportLists]--><span
									style="mso-list:Ignore">4.<span
										style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
									</span></span><!--[endif]--><span class="SFParasubclause1Char"><u>Fees
										and Payment</u></span>.
							</span></p>

						<p class="SFParasubclause1"
							style="text-align:justify;mso-list:l35 level2 lfo2"><a
								name="a000031"><!--[if !supportLists]--><span
									style="mso-list:Ignore">(a)<span
										style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
									</span></span><!--[endif]--><u>Fees</u>. Licensee agrees to pay
								Licensor a monthly
								software license fees (“<b
									style="mso-bidi-font-weight:normal">Fees</b>”) on
								such dates that Licensor specifies from time to time. Licensee shall
								make all
								payments hereunder in US dollars on or before the due date specified by
								Licensor. If Licensee fails to make any payment when due, in addition to
								all
								other remedies that may be available: (i) Licensor may charge interest
								on the
								past due amount at the rate of 1.5% per month <span
									style="mso-spacerun:yes">&nbsp;</span>calculated daily and compounded
								monthly or, if
								lower, the highest rate permitted under applicable law; and (ii)
								Licensee shall
								reimburse Licensor for all reasonable costs incurred by Licensor in
								collecting
								any late payments or interest, including attorneys' fees, court costs,
								and
								collection agency fees; and (iii) in addition to Licensor’s other
								rights, Licensor
								may suspend Licensee’s access to the Software until such amounts are
								paid in
								full.</a> <span style="mso-font-width:0%">Licensor may periodically
								specify and
								change <span class="GramE">the means by which</span> Licensee must pay
								the Fees.
								Licensee hereby </span><span
								style="mso-bidi-font-size:11.0pt;mso-font-width:
						0%">authorizes Licensor to debit Licensee’s designated bank account and
								credit
								card account to collect the Fees (the “<b
									style="mso-bidi-font-weight:normal">EFT
									Authorization</b>”)</span><span style="mso-font-width:0%"> and agrees
								to
								execute and deliver to Licensor all documents that Licensor may <span
									class="GramE">reasonable</span> request for such EFT
								Authorization.<span style="mso-spacerun:yes">&nbsp; </span>Such EFT
								Authorization shall remain in full
								force and effect at all times this Agreement is in effect and for 30
								days
								following its expiration <span class="GramE">or <span
										style="mso-spacerun:yes">&nbsp;</span>termination</span>.<span
									style="mso-spacerun:yes">&nbsp; </span></span></p>

						<p class="SFParasubclause1"
							style="text-align:justify;mso-list:l35 level2 lfo2"><a
								name="a000034"><!--[if !supportLists]--><span
									style="mso-list:Ignore">(b)<span
										style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
									</span></span><!--[endif]--><u>Taxes</u>. All Fees and other amounts
								payable by
								Licensee under this Agreement are exclusive of taxes and similar
								assessments.
								Licensee is responsible for all sales, use, and excise taxes, and any
								other
								similar taxes, duties, and charges of any kind imposed by any federal,
								state,
								or local governmental or regulatory authority on any amounts payable by
								Licensee hereunder, other than any taxes imposed on Licensor's
								income.</a></p>

						<p class="SFPara-Clause"
							style="margin-left:0in;text-align:justify;mso-list:l35 level1 lfo2"><a
								name="a000035"><!--[if !supportLists]--><span
									style="mso-list:Ignore">5.<span
										style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
									</span></span><!--[endif]--><span
									class="SFParasubclause1Char"><u>Confidential
										Information</u></span>. </a>From time to time during the Term,
							Licensor may
							disclose or make available to Licensee certain non-public information
							about or
							related to the Licensor Property, whether orally or in written,
							electronic, or
							other form or media.<span style="mso-spacerun:yes">&nbsp; </span><span
								class="GramE">All
								of</span> the foregoing information constitutes the confidential and
							proprietary information of Licensor (collectively, “<b
								style="mso-bidi-font-weight:
						normal">Confidential Information</b>”) regardless of whether it has been
							marked
							or stamped as confidential. Licensee shall not disclose the Confidential
							Information to any person or entity, except to Licensee’s users who need
							to
							know the Confidential Information to exercise its rights or perform its
							obligations hereunder.<span style="mso-spacerun:yes">&nbsp;
							</span>Furthermore,
							Licensee, on behalf of itself and its users, agrees to maintain the
							confidentiality of all Confidential Information by, among other things,
							establishing reasonable security and access measures and restricting its
							disclosure.</p>

						<p class="SFPara-Clause"
							style="margin-left:0in;text-align:justify;mso-list:l35 level1 lfo2"><a
								name="a000040"><!--[if !supportLists]--><span
									style="mso-list:Ignore">6.<span
										style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
									</span></span><!--[endif]--><span
									class="SFParasubclause1Char"><u>Intellectual
										Property Ownership; Feedback</u></span>.</a></p>

						<p class="SFParasubclause1"
							style="margin-top:0in;text-align:justify;mso-list:
						l35 level2 lfo2"><a name="a000041"><!--[if !supportLists]--><span
									style="mso-list:
						Ignore">(a)<span
										style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
									</span></span><!--[endif]-->Licensee acknowledges that, as between
								Licensee and
								Licensor, Licensor owns all right, title, and interest, including all
								intellectual property rights, in and to the Licensor Property</a>.
							Licensee has
							no rights in or to the foregoing except for the limited rights expressly
							granted in Section 1 hereto.</p>

						<p class="SFParasubclause1"
							style="text-align:justify;mso-list:l35 level2 lfo2"><a
								name="a000042"><!--[if !supportLists]--><span
									style="mso-list:Ignore">(b)<span
										style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
									</span></span><!--[endif]--><u>Feedback</u>. If Licensee or any of its
								representatives
								sends or transmits any communications or materials to Licensor by mail,
								email,
								telephone, or otherwise, suggesting or recommending changes to the
								Licensor
								Property, including without limitation, new features or functionality
								relating
								thereto, or any comments, questions, suggestions, or the like ("<b
									style="mso-bidi-font-weight:normal">Feedback</b>"), Licensor is free to
								use such Feedback irrespective of any other obligation or limitation
								between
								the Parties governing such Feedback. Licensee hereby assigns to Licensor
								on
								Licensee’s behalf, and on behalf of its employees, contractors and/or
								agents,
								all right, title, and interest in, and Licensor is free to use, without
								any
								attribution or compensation to any party, any ideas, know-how, concepts,
								techniques, or other intellectual property rights contained in the
								Feedback,
								for any purpose whatsoever, although Licensor is not required to use any
								Feedback.</a></p>

						<p class="SFPara-Clause"
							style="margin-left:0in;mso-list:l35 level1 lfo2"><a
								name="a000043"><!--[if !supportLists]--><span
									style="mso-list:Ignore">7.<span
										style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
									</span></span><!--[endif]--><u>Limited Warranties and Warranty
									Disclaimer</u>. </a></p>

						<p class="SFParasubclause1"
							style="margin-top:0in;text-align:justify;mso-list:
						l35 level2 lfo2"><a name="a000149"></a><a name="a000207"><span
									style="mso-bookmark:
						a000149"><!--[if !supportLists]--><span style="mso-list:Ignore">(a)<span
											style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
										</span></span><!--[endif]-->Except for the express assurances set
									forth in this
									Agreement, Licensor does not make any assurances, guarantees,
									representations
									or warranties regarding uptime, </span></a><span class="GramE"><span
									style="mso-bookmark:a000207"><span
										style="mso-bookmark:a000149">availability</span></span></span><span
								style="mso-bookmark:a000207"><span style="mso-bookmark:a000149"> or
									quality of
									the Licensor Property. In the event of a defect or failure in the
									Licensor
									Property that does not conform to the assurances set forth in this
									Agreement, Licensor
									shall at its option either: (i) use commercially reasonable efforts to
									remedy
									the defect or failure within a reasonable <span class="GramE">period of
										time</span>
									or (ii) replace the defective or failing software components with
									alternate
									software components of reasonably similar functionality. The foregoing
									constitutes
									Licensee’s sole and exclusive remedies and Licensor sole and exclusive
									obligations in response to a defect or failure in the Licensor
									Property.<span style="mso-spacerun:yes">&nbsp; </span></span></span><a
								name="a000208"><span style="mso-bookmark:a000149"></span></a></p>

						<p class="SFParasubclause1"
							style="text-align:justify;mso-list:l35 level2 lfo2"><span
								style="mso-bookmark:a000208"><span
									style="mso-bookmark:a000149"><!--[if !supportLists]--><span
										style="mso-list:Ignore">(b)<span
											style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
										</span></span><!--[endif]-->EXCEPT AS SET FORTH IN SECTION 7. (A)
									ABOVE, THE
									LICENSOR PROPERTY IS PROVIDED “AS-IS, AS-AVAILABLE” AND LICENSOR HEREBY
									DISCLAIMS ALL REPRESENTATIONS AND WARRANTIES, WHETHER EXPRESS, IMPLIED,
									STATUTORY, OR OTHERWISE. LICENSOR SPECIFICALLY DISCLAIMS ALL IMPLIED
									REPRESENTATIONS
									AND WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE,
									TITLE, INTEGRATION,
									AND NON-INFRINGEMENT, AND ALL REPRESENTATIONS AND WARRANTIES ARISING
									FROM
									COURSE OF DEALING, USAGE, OR TRADE PRACTICE. LICENSOR MAKES NO WARRANTY
									OF ANY
									KIND THAT THE LICENSOR PROPERTY, OR ANY RESULTS OF THE USE THEREOF,
									WILL MEET LICENSEE’S
									OR ANY OTHER PERSON’S REQUIREMENTS, OPERATE WITHOUT INTERRUPTION,
									ACHIEVE ANY
									INTENDED RESULT, BE COMPATIBLE OR WORK WITH ANY SOFTWARE, SYSTEM OR
									OTHER SERVICES,
									OR BE SECURE, ACCURATE, AVAILABLE, COMPLETE, FREE OF HARMFUL CODE, OR
									ERROR
									FREE. </span></span><span style="mso-bookmark:a000149"><span
									style="mso-spacerun:yes">&nbsp;</span>LICENSOR STRICTLY DISCLAIMS ALL
								WARRANTIES
								WITH RESPECT TO ANY THIRD-PARTY PRODUCTS. </span></p>

						<p class="SFPara-Clause"
							style="margin-left:0in;text-align:justify;mso-list:l35 level1 lfo2"><a
								name="a000046"><!--[if !supportLists]--><span
									style="mso-list:Ignore">8.<span
										style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
									</span></span><!--[endif]--><span
									class="SFParasubclause1Char"><u>Indemnification</u></span>.
							</a><a name="a000052">Licensee shall indemnify, hold harmless, and, at
								Licensor’s
								option, defend Licensor and its affiliates from and against any
								liabilities,
								damages, claims, losses, costs and expenses resulting or arising from
								(i)
								breach of this Agreement by Licensee; (ii) negligence or willful
								misconduct of Licensee,
								its employees, contractors, users, or agents; (iii) use of the Licensor
								Property in a manner not authorized by this Agreement; (iv) use of the
								Licensor
								Property in combination with data, software, hardware, equipment or
								technology
								not provided by Licensor or authorized by Licensor in writing; or (v)
								infringement
								or misappropriation of third party intellectual property, publicity,
								privacy or
								other rights as a result of data, content or other information input
								into the Software
								by Licensee or its users. Notwithstanding the foregoing, Licensee may
								not
								settle any such claims against Licensor or its affiliates unless
								Licensor
								consents to such settlement, and Licensor and its affiliates will have
								the
								right to defend themselves against any such claims or to participate in
								the
								defense thereof by counsel of its own choice.<span
									style="mso-spacerun:yes">&nbsp;
								</span>This indemnity will continue in full force and effect </a><span
								class="GramE"><span style="mso-bookmark:a000052">subsequent
									to</span></span><span style="mso-bookmark:a000052"> and notwithstanding
								this Agreement’s expiration
								or termination.<span style="mso-spacerun:yes">&nbsp; </span>Licensor and
								its
								affiliates need not seek recovery from any insurer or other third-party,
								or
								otherwise mitigate its losses and expenses, to maintain and recover
								fully a
								claim against Licensee under this Section. Any failure by Licensor or
								its
								affiliates to pursue a recovery or mitigate a loss will not reduce or
								alter the
								amounts that person may recover from Licensee under this Section.
							</span></p>

						<span style="mso-bookmark:a000052"></span>

						<p class="SFPara-Clause"
							style="margin-left:0in;text-align:justify;mso-list:l35 level1 lfo2"><a
								name="a000070"><!--[if !supportLists]--><span
									style="mso-list:Ignore">9.<span
										style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
									</span></span><!--[endif]--><span
									class="SFParasubclause1Char"><u>Limitations of
										Liability</u></span>. IN NO EVENT WILL LICENSOR BE LIABLE UNDER OR IN
								CONNECTION WITH THIS AGREEMENT UNDER ANY LEGAL OR EQUITABLE THEORY,
								INCLUDING
								BREACH OF CONTRACT, TORT (INCLUDING NEGLIGENCE), STRICT LIABILITY, AND
								OTHERWISE, FOR ANY: (a) CONSEQUENTIAL, INCIDENTAL, INDIRECT, EXEMPLARY,
								SPECIAL, ENHANCED, OR PUNITIVE DAMAGES; (b) INCREASED COSTS, DIMINUTION
								IN
								VALUE OR LOST BUSINESS, PRODUCTION, REVENUES, OR PROFITS; (c) LOSS OF
								GOODWILL
								OR REPUTATION; (d) USE, INABILITY TO USE, LOSS, INTERRUPTION, DELAY OR
								RECOVERY
								OF ANY DATA, OR BREACH OF DATA OR SYSTEM SECURITY; OR (e) COST OF
								REPLACEMENT
								GOODS OR SERVICES, IN EACH CASE REGARDLESS OF WHETHER LICENSOR WAS
								ADVISED OF
								THE POSSIBILITY OF SUCH LOSSES OR DAMAGES OR SUCH LOSSES OR DAMAGES WERE
								OTHERWISE FORESEEABLE. IN NO EVENT WILL LICENSOR'S AGGREGATE LIABILITY
								ARISING
								OUT OF OR RELATED TO THIS AGREEMENT UNDER ANY LEGAL OR EQUITABLE THEORY,
								INCLUDING BREACH OF CONTRACT, TORT (INCLUDING NEGLIGENCE), STRICT
								LIABILITY,
								AND OTHERWISE EXCEED </a>THE TOTAL FEES PAID TO LICENSOR UNDER THIS
							AGREEMENT
							DURING THE TWELVE (12) MONTH PERIOD PRECEDING THE EVENT GIVING RISE TO
							THE
							CLAIM. </p>

						<p class="SFPara-Clause"
							style="margin-left:0in;mso-list:l35 level1 lfo2"><a
								name="a000079"><!--[if !supportLists]--><span
									style="mso-list:Ignore">10.<span
										style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
									</span></span><!--[endif]--><span class="SFParasubclause1Char"><u>Term
										and
										Termination</u></span>. </a></p>

						<p class="SFParasubclause1"
							style="margin-top:0in;text-align:justify;mso-list:
						l35 level2 lfo2"><a name="a000080"><!--[if !supportLists]--><span
									style="mso-list:
						Ignore">(a)<span
										style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
									</span></span><!--[endif]--><u>Term</u>. </a><a name="a000088">Unless
								terminated
								sooner as provided in Section 10(b)(i) – (iii), the term of this
							</a><span class="GramE"><span style="mso-bookmark:a000088">Agreement
									<span style="mso-spacerun:yes">&nbsp;</span>(</span></span><span
								style="mso-bookmark:a000088">“<b
									style="mso-bidi-font-weight:normal">Term</b>”) begins on the Effective
								Date and
								shall expire upon the termination or expiration of the franchise
								agreement (the
								“<b>Franchise Agreement</b>”) which governs the operations of the
								Studio.</span></p>

						<p class="SFParasubclause1"
							style="margin-top:0in;text-align:justify;mso-list:
						l35 level2 lfo2"><span style="mso-bookmark:a000088"><a
									name="a000083"><!--[if !supportLists]--><span
										style="mso-list:Ignore">(b)<span
											style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
										</span></span><!--[endif]--><u>Termination</u>. In addition to any
									other express
									termination right set forth in this Agreement:</a></span></p>

						<p class="SFParasubclause2"
							style="margin-top:0in;text-align:justify;mso-list:
						l35 level3 lfo2"><span style="mso-bookmark:a000088"><a
									name="a000084"><!--[if !supportLists]--><span
										style="mso-list:Ignore">(i)<span
											style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
										</span></span><!--[endif]-->Licensor reserves the right to terminate
									this Agreement
									for convenience upon written notice to Licensee.<span
										style="mso-spacerun:yes">&nbsp; </span>In such a circumstance,
									Licensor will
									endeavor to provide at least thirty (30) days advance written notice of
								</a><span class="GramE"><span style="mso-bookmark:a000084">termination,
										but</span></span><span style="mso-bookmark:a000084"> may provide
									lesser notice if there is a valid
									business reasons for doing so in Licensor’ reasonable judgment.
								</span></span></p>

						<p class="SFParasubclause2"
							style="margin-top:0in;text-align:justify;mso-list:
						l35 level3 lfo2"><span style="mso-bookmark:a000088"><span
									style="mso-bookmark:
						a000084"><!--[if !supportLists]--><span style="mso-list:Ignore">(ii)<span
											style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
										</span></span><!--[endif]-->Licensor may terminate this Agreement,
									effective on
									written notice to Licensee, if Licensee: (A) fails to pay any amount
									when due
									hereunder, and such failure continues more than ten (10) days after
									Licensor’
									delivery of written notice thereof; or (B) breaches any of its other
									obligations
									under this Agreement and such failure continues for more than thirty
									(30) days
									after Licensor’ delivery of written notice <span
										class="GramE">thereof;</span></span></span></p>

						<p class="SFParasubclause2"
							style="margin-top:0in;text-align:justify;mso-list:
						l35 level3 lfo2"><span style="mso-bookmark:a000088"><a
									name="a000087"><!--[if !supportLists]--><span
										style="mso-list:Ignore">(iii)<span
											style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
										</span></span><!--[endif]-->Licensor may terminate this Agreement,
									effective
									immediately upon written notice to Licensee if Licensee: (A) becomes
									insolvent
									or is generally unable to pay, or fails to pay, its debts as they
									become due; (B)
									files or has filed against it, a petition for voluntary or involuntary
									bankruptcy or otherwise becomes subject, voluntarily or involuntarily,
									to any
									proceeding under any domestic or foreign bankruptcy or insolvency law;
									(C)
									makes or seeks to make a general assignment for the benefit of its
									creditors;
									or (D) applies for or has appointed a receiver, trustee, custodian, or
									similar
									agent appointed by order of any court of competent jurisdiction to take
									charge
									of or sell any material portion of its property or
									business.</a></span></p>

						<p class="SFParasubclause2"
							style="margin-top:0in;text-align:justify;mso-list:
						l35 level3 lfo2"><span
								style="mso-bookmark:a000088"><!--[if !supportLists]--><span
									style="mso-list:Ignore">(iv)<span
										style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
									</span></span><!--[endif]-->Upon the termination or expiration of the
								Franchise
								Agreement, this Agreement shall automatically terminate.</span></p>

						<p class="SFParasubclause1"
							style="margin-top:0in;text-align:justify;mso-list:
						l35 level2 lfo2"><span
								style="mso-bookmark:a000088"><!--[if !supportLists]--><span
									style="mso-list:Ignore">(c)<span
										style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
									</span></span><!--[endif]--><u>Effect of Expiration or Termination</u>.
								Upon
								expiration or earlier termination of this Agreement, Licensee shall
								immediately
								discontinue use of <span class="GramE">the<span
										style="mso-spacerun:yes">&nbsp;
									</span>Licensor</span> Property and, without limiting Licensee’s
								obligations
								under Section 5, Licensee shall delete, destroy, or return all copies of
								the
								Documentation and other Licensor Property in its possession and certify
								in
								writing to the Licensor that the Documentation and other Licensor
								Property has
								been deleted or destroyed. No expiration or termination will affect
								Licensee’s
								obligation to pay all Fees that may have become due before such
								expiration or <span class="GramE">termination, or</span> entitle
								Licensee to any refund.</span></p>

						<p class="SFParasubclause1"
							style="text-align:justify;mso-list:l35 level2 lfo2"><a
								name="a000156"><!--[if !supportLists]--><span
									style="mso-list:Ignore">(d)<span
										style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
									</span></span><!--[endif]--><u>Survival</u>. </a>This Section 10(d) and
							Sections 4,
							5, 6, 7, 8, 9, 10(c), and 11 survive any termination or expiration of
							this
							Agreement<span class="ParaChar">. No other provisions of this Agreement
								survive
								the expiration or earlier termination of this Agreement.</span></p>

						<p class="SFPara-Clause"
							style="margin-left:0in;text-align:justify;mso-list:l35 level1 lfo2"><a
								name="a000089"><!--[if !supportLists]--><span
									style="mso-list:Ignore">11.<span
										style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
									</span></span><!--[endif]--><u>Miscellaneous</u>. </a></p>

						<p class="SFParasubclause1"
							style="margin-top:0in;text-align:justify;mso-list:
						l35 level2 lfo2"><a name="a000159"><!--[if !supportLists]--><span
									style="mso-list:
						Ignore">(a)<span
										style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
									</span></span><!--[endif]--><u>Data</u>. The Parties agree that
								Licensee owns all
								data that it or its owners </a><span class="GramE"><span
									style="mso-bookmark:
						a000159">enter into</span></span><span style="mso-bookmark:a000159"> the
								Software. Licensee shall be responsible for the privacy and security of
								all
								personally identifiable information of individuals within its possession
								or
								control including, without limitation, personal financial information,
								personal
								health information, credit card or related payment account information,
								social
								security numbers or any other such personally identifiable information
								of its
								employees, contractors, customers or third parties.<span
									style="mso-spacerun:yes">&nbsp; </span>Licensee shall transmit, <span
									class="GramE">process</span>
								and store all such information in accordance with applicable laws and
								applicable regulations, including without limitation the Payment Card
								Industry
								Data Security Standards applicable to Licensee as a merchant and any
								similar
								credit card association requirements pertaining to confidentiality,
								security
								and integrity of cardholder and card transaction data. <span
									style="mso-spacerun:yes">&nbsp;</span>Licensee shall indemnify, <span
									class="GramE">defend</span>
								and hold Licensor harmless from and against all liabilities, damages,
								claims,
								losses, costs and expenses (including reasonable attorneys’ fees)
								resulting or
								arising from (i) the violation of these obligations by Licensee, its
								employees,
								contractors or agents or (ii) any data security incidents or breaches
								caused by
								the acts, omissions, negligence or willful misconduct of Licensee, its
								employees, contractors or agents. <span
									style="mso-spacerun:yes">&nbsp;</span>Licensee
								shall notify Licensor immediately of any suspected data security
								incident or
								breach (whether the incident or breach has been confirmed or not) and
								cooperate
								in all reasonable ways with Licensor in investigating the matter and in
								taking
								appropriate steps to minimize any resulting harm.<span
									style="mso-spacerun:yes">&nbsp;&nbsp;&nbsp; </span></span></p>

						<p class="SFParasubclause1"
							style="margin-top:0in;text-align:justify;mso-list:
						l35 level2 lfo2"><span
								style="mso-bookmark:a000159"><!--[if !supportLists]--><span
									style="mso-list:Ignore">(b)<span
										style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
									</span></span><!--[endif]--><u>Equipment &amp; Internet</u>.<span
									style="mso-spacerun:yes">&nbsp; </span>Licensee will, at its cost and
								expense,
								acquire, install, and maintain throughout the Term, such equipment and
								<span style="mso-font-width:0%">high-speed Internet connection</span>
								that are
								designated by Licensor from time to time in connection with the
								operation of
								the Software. <u></u></span></p>

						<p class="SFParasubclause1"
							style="text-align:justify;mso-list:l35 level2 lfo2"><span
								style="mso-bookmark:a000159"><!--[if !supportLists]--><span
									style="mso-list:Ignore">(c)<span
										style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
									</span></span><!--[endif]--><u>Entire Agreement</u>. This Agreement,
								together with the
								Exhibits, constitutes the sole and entire agreement of the Parties with
								respect
								to the subject matter of this Agreement and supersedes all prior and
								contemporaneous understandings, agreements, and representations and
								warranties,
								both written and oral, with respect to such subject matter.
							</span><u></u></p>

						<p class="SFParasubclause1"
							style="text-align:justify;mso-list:l35 level2 lfo2"><a
								name="a000101"><!--[if !supportLists]--><span
									style="mso-list:Ignore">(d)<span
										style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
									</span></span><!--[endif]--><u>Notices</u>. All notices, requests,
								consents,
								claims, demands, waivers, and other communications hereunder (each, a
								“<b style="mso-bidi-font-weight:normal">Notice</b>”) must be in writing
								and
								addressed to the Parties at the addresses set forth on the first page of
								this
								Agreement (or to such other address that may be designated by the Party
								giving
								Notice from time to time in accordance with this Section). All Notices
								must be
								delivered by personal delivery, nationally recognized overnight courier
								(with
								all fees pre-paid), or email (with confirmation of transmission), or
								certified
								or registered mail (in each case, return receipt requested, postage
								pre-paid).
								Except as otherwise provided in this Agreement, a Notice is effective
								only: (i)
								upon receipt by the receiving Party, and (ii) if the Party giving the
								Notice
								has complied with the requirements of this Section.</a></p>

						<p class="SFParasubclause1"
							style="text-align:justify;mso-list:l35 level2 lfo2"><a
								name="a000180"><!--[if !supportLists]--><span
									style="mso-list:Ignore">(e)<span
										style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
									</span></span><!--[endif]--><u>Force Majeure</u>. In no event shall
								Licensor be
								liable to Licensee, or be deemed to have breached this Agreement, for
								any
								failure or delay in performing its obligations under this Agreement,
								(except
								for any obligations to make payments), if and to the extent such failure
								or
								delay is caused by any circumstances beyond Licensor’s reasonable
								control,
								including but not limited to: (i) acts of God; (ii) flood, fire,
								earthquake, other
								potential disaster(s) or catastrophe(s), such as epidemics, or
								explosion; (iii)
								war, invasion, hostilities (whether war is declared or not), terrorist
								threats
								or acts, riot or other civil unrest; (iv) government order, law, or
								actions;
								(v) embargoes or blockades in effect on or after the date of this
								Agreement;
								(vi) national or regional emergency; (vii) strikes, labor stoppages or
								slowdowns, or other industrial disturbances; and (viii) shortage of
								adequate
								power or transportation facilities.</a></p>

						<p class="SFParasubclause1"
							style="text-align:justify;mso-list:l35 level2 lfo2"><a
								name="a000112"><!--[if !supportLists]--><span
									style="mso-list:Ignore">(f)<span
										style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
									</span></span><!--[endif]--><u>Amendment and Modification; Waiver</u>.
								No amendment
								to or modification of this Agreement is effective unless it is in
								writing and
								signed by an authorized representative of each Party. No waiver by any
								Party of
								any of the provisions hereof will be effective unless explicitly set
								forth in
								writing and signed by the Party so waiving.</a></p>

						<p class="SFParasubclause1"
							style="text-align:justify;mso-list:l35 level2 lfo2"><a
								name="a000116"><!--[if !supportLists]--><span
									style="mso-list:Ignore">(g)<span
										style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
									</span></span><!--[endif]--><u>Severability</u>. If any provision of
								this Agreement
								is invalid, illegal, or unenforceable in any jurisdiction, such
								invalidity,
								illegality, or unenforceability will not <span class="ParaChar">affect
									any other
									term or provision of this Agreement or invalidate or render
									unenforceable such
									term or provision in any other jurisdiction. Upon such
									determination</span>
								that any term or other provision is invalid, illegal, or unenforceable,
								the
								Parties hereto shall negotiate in good faith to modify this Agreement so
								as to </a><span class="GramE"><span
									style="mso-bookmark:a000116">effect</span></span><span
								style="mso-bookmark:a000116"> the original intent of the Parties as
								closely as
								possible in a mutually acceptable manner in order that the transactions
								contemplated hereby be consummated as originally contemplated to the
								greatest
								extent possible.</span><u></u></p>

						<p class="SFParasubclause1"
							style="text-align:justify;mso-list:l35 level2 lfo2"><a
								name="a000118"><!--[if !supportLists]--><span
									style="mso-list:Ignore">(h)<span
										style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
									</span></span><!--[endif]--><u>Governing Law; Submission to
									Jurisdiction; Jury
									Trial Waiver; Class Action Waiver</u>. This Agreement shall be governed
								by and
								construed in accordance with the choice of law and dispute resolution
								provisions set forth in the Franchise Agreement, which provisions are
								incorporated herein.</a><a name="a000108"> NOTWITHSTANDING ANYTHING TO
								THE
								CONTRARY, THE PARTIES IRREVOCABLY WAIVE TRIAL BY JURY IN ANY ACTION,
								PROCEEDING, OR COUNTERCLAIM ARISING FROM OR RELATED TO THIS AGREEMENT.
								LICENSEE
								ALSO WAIVES ITS RIGHT TO PARTICIPATE IN ANY CLASS ACTION WITH RESPECT TO
								CLAIMS
								ARISING OUT OF THIS AGREEMENT, AND LICENSEE AGREES THAT CLAIMS OF ANY
								THIRD
								PARTY SHALL NOT BE JOINED WITH ANY CLAIMS ASSERTED IN ANY ACTION OR
								PROCEEDING
								BETWEEN LICENSOR AND LICENSEE.<u></u></a></p>

						<p class="SFParasubclause1"
							style="text-align:justify;mso-list:l35 level2 lfo2"><span
								style="mso-bookmark:a000108"><!--[if !supportLists]--><span
									style="mso-list:Ignore">(i)<span
										style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
									</span></span><!--[endif]--><u>Assignment</u>. Licensee may not assign
								or transfer
								any of its rights or delegate any of its obligations hereunder, in each
								case
								whether voluntarily, involuntarily, by operation of law or otherwise,
								without
								the prior written consent of Licensor. Any purported assignment,
								transfer, or
								delegation in violation of this Section is null and void. No assignment,
								transfer, or delegation will relieve the assigning or delegating Party
								of any
								of its obligations hereunder. This Agreement is binding upon and inures
								to the
								benefit of the Parties hereto and their respective permitted successors
								and
								assigns. </span></p>

						<p class="SFParasubclause1"
							style="text-align:justify;mso-list:l35 level2 lfo2"><a
								name="a000124"><!--[if !supportLists]--><span
									style="mso-list:Ignore">(j)<span
										style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
									</span></span><!--[endif]--><span class="SFPara-ClauseChar"><u>Export
										Regulation</u></span>.
								The Software may be subject to US export control laws, including the
								Export
								Control Reform Act and its associated regulations. Licensee shall not,
							</a><span class="GramE"><span
									style="mso-bookmark:a000124">directly</span></span><span
								style="mso-bookmark:a000124"> or indirectly, export, re-export, or
								release the
								Software to, or make the Software accessible from, any jurisdiction or
								country
								to which export, re-export, or release is prohibited by law, rule, or
								regulation. Licensee shall comply with all applicable federal laws,
								regulations, and rules, and complete all required undertakings
								(including
								obtaining any necessary export license or other governmental approval),
								prior
								to exporting, re-exporting, releasing, or otherwise making the Software
								available outside the US. </span><a name="a000091"><span
									style="mso-spacerun:yes">&nbsp;</span></a></p>

						<p class="SFParasubclause1"
							style="text-align:justify;mso-list:l35 level2 lfo2"><a
								name="a000122"><!--[if !supportLists]--><span
									style="mso-list:Ignore">(k)<span
										style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
									</span></span><!--[endif]--><u>Equitable Relief</u>. </a>Each Party
							acknowledges
							and agrees that a breach or threatened breach by such Party of any of its
							obligations could cause the other Party irreparable harm for which
							monetary
							damages would not be an adequate remedy and agrees that, in the event of
							such
							breach or threatened breach, the other Party will be entitled to
							equitable
							relief, including a restraining order, an injunction, specific
							performance and
							any other relief that may be available from any court, without any
							requirement
							to post a bond or other security, or to prove actual damages or that
							monetary
							damages are not an adequate remedy. Such remedies are not exclusive and
							are in
							addition to all other remedies that may be available at law, in equity or
							otherwise.</p>

						<p class="SFParasubclause1"
							style="margin-left:0in;text-align:justify;text-indent:
						0in;mso-pagination:widow-orphan lines-together;page-break-after:avoid;
						mso-list:none;tab-stops:.5in"><u><span
										style="text-decoration:none">&nbsp;</span></u></p>

						<p class="Paragraph">&nbsp;</p>

						<p class="Paragraph">&nbsp;</p>


					</div>

			</div>
			<div class="form-check mt-3">
				<input class="form-check-input" type="checkbox" id="agreeCheckbox" [(ngModel)]="isChecked" [disabled]="!canCheck">
				<label class="form-check-label" for="agreeCheckbox">
					By checking this box, Licensee
							acknowledges
							that it has read this Agreement and agrees to abide by this Agreement.
				</label>
			</div>
		</div>
		<div class="card-footer text-center">
			<button class="btn btn-primary me-1" (click)="onAgree()" [disabled]="!isChecked">Agree</button>
			<button class="btn btn-secondary" (click)="onCancel()">Cancel</button>
		</div>
	</div>
</div>
