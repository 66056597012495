import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators, NgForm, FormArray, FormControl, AbstractControl } from '@angular/forms';
import { MatTabGroup } from '@angular/material/tabs';
import _ from 'lodash';
import moment from 'moment';
import { BaseDialogComponent } from '../base-dialog/base-dialog.component';
import { BaseComponent } from '../base-form/base-form.component';
import { CreditCardReadInputComponent } from '../credit-card-read-input/credit-card-read-input.component';

@Component({
  selector: 'app-payments-entity',
  templateUrl: './payments-entity.component.html',
  styleUrls: ['./payments-entity.component.css']
})
export class PaymentsEntityComponent {

}
