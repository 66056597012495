import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AuthService } from 'src/app/services/auth.service';
import { DrupalRESTService } from 'src/app/services/drupal-rest.service';
import { v4 as uuidv4 } from 'uuid';

@Component({
  selector: 'app-square-terminal-payment',
  templateUrl: './square-terminal-payment.component.html',
  styleUrls: ['./square-terminal-payment.component.css']
})

export class SquareTerminalPaymentComponent implements OnInit {
  amount: number;
  currency: string;
  studioId: string;
  paymentStatus: 'SUCCESS' | 'PENDING' | 'FAILURE' | 'CANCELED' | null = null;
  paymentStatusMessage: string = '';
  isProcessing: boolean = false;
  checkoutId: string | null = null;

  constructor(
    private drupalRESTService: DrupalRESTService,
    private snackBar: MatSnackBar,
    public dialogRef: MatDialogRef<SquareTerminalPaymentComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    console.log('SquareTerminalPaymentComponent received data:', data);
    this.amount = data.amount;
    this.currency = data.currency || 'USD';
    this.studioId = data.studioId;
  }

  ngOnInit() {
    if (!this.amount || this.amount <= 0 || !this.studioId) {
      console.error('Invalid amount or studio ID provided:', { amount: this.amount, studioId: this.studioId });
      this.snackBar.open('Invalid amount or studio ID provided', 'Close', { duration: 5000 });
      this.dialogRef.close({ status: 'ERROR', message: 'Invalid amount or studio ID' });
    } else {
      console.log('SquareTerminalPaymentComponent initialized with:', { amount: this.amount, currency: this.currency, studioId: this.studioId });

      // Automatically start the payment process
      this.capturePayment();
    }
  }

  onCapturePaymentClick() {
    console.log('Capture payment clicked');
    this.capturePayment();
  }

  onCancelPaymentClick() {
    console.log('Cancel payment clicked');
    this.cancelPayment();
  }

  private capturePayment() {
    if (this.isProcessing) {
      return;
    }

    this.isProcessing = true;
    this.paymentStatus = null;
    this.paymentStatusMessage = '';
    this.checkoutId = null;

    const amountInCents = Math.round(this.amount * 100);

    if (!this.studioId) {
      this.handlePaymentError(new Error('No studio ID available'));
      return;
    }

    const endpoint = `/api/square/terminal/checkout/${this.studioId}/${amountInCents}/${this.currency}`;

    this.drupalRESTService.httpPOST(endpoint, {}).subscribe({
      next: (response) => this.handlePaymentSuccess(response),
      error: (error) => this.handlePaymentError(error)
    });
  }

  private cancelPayment() {
    if (!this.checkoutId || this.paymentStatus !== 'PENDING') {
      return;
    }

    this.isProcessing = true;

    if (!this.studioId) {
      this.handleCancellationError(new Error('No studio ID available'));
      return;
    }

    const endpoint = `/api/square/terminal/cancel/${this.studioId}/${this.checkoutId}`;

    // Remove query parameters from the URL
    this.drupalRESTService.httpPOST(endpoint, {}).subscribe({
      next: (response) => this.handleCancellationSuccess(response),
      error: (error) => this.handleCancellationError(error)
    });
  }

  private handlePaymentSuccess(response: any) {
    this.isProcessing = false;
    if (response.checkout && response.checkout.id) {
      this.checkoutId = response.checkout.id;
      this.paymentStatus = response.checkout.status === 'PENDING' ? 'PENDING' : 'SUCCESS';
      this.paymentStatusMessage = `Terminal checkout created. ID: ${this.checkoutId}`;
      this.snackBar.open('Terminal checkout created successfully!', 'Close', { duration: 5000 });
    } else {
      this.handlePaymentError(new Error('Invalid response from server'));
    }
  }

  private handlePaymentError(error: any) {
    this.isProcessing = false;
    this.paymentStatus = 'FAILURE';

    if (error.error && error.error.errorCode === 'NO_DEVICE_ID') {
      this.paymentStatusMessage = 'No Square Terminal device is configured for this studio. Please contact support.';
    } else {
      this.paymentStatusMessage = 'Failed to create terminal checkout. Please try again.';
    }

    console.error('Error creating terminal checkout:', error);
    this.snackBar.open(this.paymentStatusMessage, 'Close', { duration: 5000 });
  }

  private handleCancellationSuccess(response: any) {
    this.isProcessing = false;
    this.paymentStatus = 'CANCELED';
    this.paymentStatusMessage = 'Terminal checkout cancelled successfully.';
    this.snackBar.open(this.paymentStatusMessage, 'Close', { duration: 5000 });
  }

  private handleCancellationError(error: any) {
    this.isProcessing = false;
    this.paymentStatusMessage = 'Failed to cancel terminal checkout. Please try again.';
    console.error('Error cancelling terminal checkout:', error);
    this.snackBar.open(this.paymentStatusMessage, 'Close', { duration: 5000 });
  }

  onClose() {
    this.dialogRef.close({
      status: this.paymentStatus,
      checkoutId: this.checkoutId,
      error: this.paymentStatus === 'FAILURE' ? this.paymentStatusMessage : null
    });
  }
}
