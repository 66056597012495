import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { BaseComponent } from '../base-form/base-form.component';
import { BaseEnrollmentComponent } from '../../enrollment/base-enrollment/base-enrollment.component';

@Component({
  selector: 'app-enrollment-payment-display',
  templateUrl: './enrollment-payment-display.component.html',
  styleUrls: ['./enrollment-payment-display.component.css']
})
export class EnrollmentPaymentDisplayComponent extends BaseEnrollmentComponent implements OnInit {

  @Input() override entityData: any;
  @Input() override enrollmentForm: any;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['enrollmentForm']) {
      console.log('enrollmentForm:', this.enrollmentForm);
    }
  }

}
