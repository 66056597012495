<!-- us-sales-tax-report.component.html -->
<div class="container-fluid">
  <h1 class="page-header">US Sales Tax Report</h1>

  <nav mat-tab-nav-bar #tabPanel>
    <a mat-tab-link *ngFor="let link of links"
      (click)="activeLink = link"
      routerLink="/{{link.linkRoute}}"
      [active]="activeLink == link.linkTitle"> {{link.linkTitle}} </a>
  </nav>

  <mat-expansion-panel>
    <mat-expansion-panel-header>
      <mat-panel-title>
        Filter
      </mat-panel-title>
      <mat-panel-description>
        <app-filter-results-panel [parameters]=queryParams></app-filter-results-panel>
      </mat-panel-description>
    </mat-expansion-panel-header>
    <form (ngSubmit)="onSubmit(f)" #f=ngForm class="form">
      <div class="row">

        <div class="col-2">
          <mat-form-field class="full-width-field">
              <mat-label>Select Payment Type...</mat-label>
              <mat-select
                name="payment_type"
                ngModel
                (selectionChange)="onSubmit(f)">
                <mat-option value="All">All</mat-option>
                <mat-option value="Credit Card">Credit Card</mat-option>
                <mat-option value="Cash Payment">Cash Payment</mat-option>
              </mat-select>
          </mat-form-field>
        </div>

        <section class="section">
          <mat-checkbox
              name="Recent"
              class="margin"
              ngModel
              (change)="onSubmit(f)">Recent</mat-checkbox>
        </section>

        <section class="section">
          <div class="float-end">
            <button (click)="resetForm()" color="primary" mat-flat-button class="me-1" mat-button>Clear</button>
          </div>
        </section>
      </div>
    </form>
  </mat-expansion-panel>

  <div class="row">
    <div class="col-md-12 mt-3">
      <div class="float-end">
        <button (click)="downloadCSVDrupalViews('/api_rest/v1/us-sales-tax-report/export?', 'us_sales_tax_report.csv', {})" color="primary" mat-flat-button class="me-1 warning" mat-button>

          <mat-icon *ngIf="isLoading" class="spinner-icon">hourglass_empty</mat-icon>
          <span *ngIf="!isLoading">Export CSV</span>
          <span *ngIf="isLoading">Exporting...</span>
        </button>
      </div>
    </div>
  </div>

  <mat-progress-bar *ngIf="ShowProgressBar" mode="indeterminate"></mat-progress-bar>

  <div class="table-responsive">
    <table mat-table #displayTable [dataSource]="dataSource"
      matSort
      (matSortChange)="sortDataView($event)"
      class="mt-4">

      <ng-container matColumnDef="title">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Title</th>
        <td mat-cell *matCellDef="let element">{{element.title}}</td>
      </ng-container>

      <ng-container matColumnDef="field_date_and_time">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Date and Time</th>
        <td mat-cell *matCellDef="let element">{{element.field_date_and_time | date}}</td>
      </ng-container>

      <ng-container matColumnDef="field_gross_tuition">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Gross Tuition</th>
        <td mat-cell *matCellDef="let element">{{element.field_gross_tuition | currency}}</td>
      </ng-container>

      <ng-container matColumnDef="field_payment_id">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Payment ID</th>
        <td mat-cell *matCellDef="let element">{{element.field_payment_id}}</td>
      </ng-container>

      <ng-container matColumnDef="field_payment_type">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Payment Type</th>
        <td mat-cell *matCellDef="let element">{{element.field_payment_type}}</td>
      </ng-container>

      <ng-container matColumnDef="field_status">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Status</th>
        <td mat-cell *matCellDef="let element">{{element.field_status}}</td>
      </ng-container>

      <ng-container matColumnDef="field_student_name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Student Name</th>
        <td mat-cell *matCellDef="let element">{{element.field_student_name}}</td>
      </ng-container>

      <ng-container matColumnDef="field_tax_collected">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Tax Collected</th>
        <td mat-cell *matCellDef="let element">{{element.field_tax_collected | currency}}</td>
      </ng-container>

      <ng-container matColumnDef="field_total_payment_amount">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Total Payment Amount</th>
        <td mat-cell *matCellDef="let element">{{element.field_total_payment_amount | currency}}</td>
      </ng-container>

      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef>Actions</th>
        <td mat-cell *matCellDef="let element">
          <button mat-icon-button [matMenuTriggerFor]="reportMenu" [matMenuTriggerData]="{ payment_id: element.field_payment_id }">
            <mat-icon>more_vert</mat-icon>
          </button>
        </td>
      </ng-container>

      <mat-menu #reportMenu="matMenu">
        <ng-template matMenuContent let-payment_id="payment_id">
            <button mat-menu-item
                (click)="setReportStatus(payment_id, 'Paid')">
                Mark as Paid
            </button>
            <button mat-menu-item
                (click)="setReportStatus(payment_id, 'Pending')">
                Mark as Pending
            </button>
        </ng-template>
      </mat-menu>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
  </div>

	<!-- <mat-paginator (page)="pageChanged($event)"
		[disabled]="ShowProgressBar"
		[pageSizeOptions]="onPageSizeOptions()"
		[length]="30"
		[pageSize]="paginationTotalPages"
		aria-label="Select page of view">
	</mat-paginator> -->

</div>
