import { Component, OnInit } from '@angular/core';
import { Validators, FormArray, NgForm } from '@angular/forms';
import _ from 'lodash';
import moment from 'moment';
import { BaseDialogComponent } from '../base-dialog/base-dialog.component';
import { StudentRecordEntityComponent } from '../student-record-entity/student-record-entity.component';

@Component({
  selector: 'app-student-inquiry-details',
  templateUrl: './student-inquiry-details.component.html',
  styleUrls: ['./student-inquiry-details.component.css']
})
export class StudentInquiryDetailsComponent extends BaseDialogComponent implements OnInit {

  override eckType = "student_accounts";
  override bundle = "student_account";
  override entityId = this._injectedDialogData['data']['EntityID'];

  override ngOnInit() {
    this.initActionType = this._injectedDialogData['data']['action'];
    this.actionType = this._injectedDialogData['data']['action'];

    let formGroup = {
      id: [this.entityData?.id, Validators.required],
      field_inquiry_taker: [this.entityData?.field_inquiry_taker, null],
      field_inquiry_method: [this.entityData?.field_inquiry_method, null],
      field_inquired: [this.entityData?.field_inquired, null],
      field_teacher: [this.entityData?.field_teacher, null],
      field_executive: [this.entityData?.field_executive, null],
      field_booked_on: [this.entityData?.field_booked_on, null],
      field_showed: [this.entityData?.field_showed, null],
      field_notes: [this.entityData?.field_notes, null],
      field_inquiry_studen: [this.entityData?.field_inquiry_studen, null],
      // field_contacts: new FormArray([]),
      field_contacts: new FormArray([]),
    }

    let formGroupEdit;
    formGroupEdit = {
          ...formGroup,
    }

    this.studentAccountDetailsForm = this._formBuilder.group(formGroupEdit);
  }

  onSubmitInquiryDetails(f: NgForm) {
    this.displayProgressSpinner(true);
    console.log('onSubmitInquiryDetails called...');

    console.log('studentAccountDetailsForm', this.studentAccountDetailsForm.value);

    // Fix for package creation not working...
    if (this.actionType == 'create') {
    }

    // Set default value for field_retired if nothing is set.
    if (this.studentAccountDetailsForm.value?.['field_retired'] == "") {
      this.studentAccountDetailsForm.value.field_retired = false;
    }

    // Handle date fields
    this.formatDateField('field_inquired');
    this.formatDateField('field_showed');
    this.formatDateField('field_booked_on');

    // If SPS code is sundry (558), Misc (554), or Non Unit (657) don't send lesson_type.
    if ((this.studentAccountDetailsForm.value?.field_lesson_type == '') && (this.studentAccountDetailsForm.value.field_sps_code == '558' || this.studentAccountDetailsForm.value.field_sps_code == '554' || this.studentAccountDetailsForm.value.field_sps_code == '657')) {
      delete (this.studentAccountDetailsForm.value.field_lesson_type);
    }

    // if target_id is null, assume we are creating a new student.
    for (let property in this.studentAccountDetailsForm.value?.field_contacts) {
      if (this.studentAccountDetailsForm.value?.field_contacts[property]?.id == null) {
        // delete this.studentAccountDetailsForm.value?.field_contacts[property]?.id;
        // this.studentAccountDetailsForm.value.field_contacts[property].id = null;
      }
    }

    this.onSubmit(this.studentAccountDetailsForm)
  }

  contactEntityIncrement() {
    let newContactID;

    // Create the contact enitity.
    let body = {
      'field_first_name': '',
      'field_last_name': '',
      'field_home_phone': '',
      'field_cell_phone': '',
      'field_email': '',
    }
    this._entityRESTService.createEntity('contacts', 'student_record', body)
    .subscribe(data => {
      console.log('contact data', data)
      newContactID = data?.['id']?.[0]?.value;
      // Append new contact to the student inquiry details form.
      let control = this._formBuilder.group({
        'target_id': [data?.['id']?.[0]?.value, Validators.required],
        'field_first_name': [null, Validators.required],
        'field_last_name': [null, Validators.required],
        'field_home_phone': [null, Validators.required],
        'field_cell_phone': [null, Validators.required],
        'field_email': [null, Validators.required],
      });

      (<FormArray>this.studentAccountDetailsForm.get('field_contacts')).push(control)

      // Link contact to student account.
      let body = {
        'field_contacts': this.studentAccountDetailsForm.value?.field_contacts
      }
      this._entityRESTService.patchEntity('student_accounts', 'student_account', this.entityId, body)
      .subscribe(data => {
        console.log('student account data', data)
        console.log('newContactID', newContactID)

        this._dialogService.openDialog(StudentRecordEntityComponent, "defaultWithData", {
          data: {
            EntityID: newContactID,
            eckType: 'contacts',
            bundle: 'student_record',
            action: 'edit',
            fieldsData: '',
          },
        }).afterClosed().subscribe(data => {
          // Reload this component.
          this.ngOnInit();
          this.ngAfterContentInit();
        });
      });
    });
  }

  contactEntityRemove(index: number) {
    // Maybe display a warning?

    // Remove the contact from Drupal.
    let studentContactID = (<FormArray>this.studentAccountDetailsForm.get('field_contacts')).value?.[index]?.['target_id'];
    this._entityRESTService.deleteEntity('contacts', 'student_record', studentContactID)
    .subscribe(data => {
      // Remove the contact from the form.
      (<FormArray>this.studentAccountDetailsForm.get('field_contacts')).removeAt(index);

      // Sync contact(s) to student account.
      let body = {
        'field_contacts': this.studentAccountDetailsForm.value?.field_contacts
      }
      this._entityRESTService.patchEntity('student_accounts', 'student_account', this.entityId, body)
      .subscribe(data => {
        // Reload this component.
        this.ngOnInit();
        this.ngAfterContentInit();
      });

    });

  }

  studentUpdateLoad(index: number) {
    console.log(index, (<FormArray>this.studentAccountDetailsForm.get('field_contacts')).value?.[index]);
    let studentContactID = (<FormArray>this.studentAccountDetailsForm.get('field_contacts')).value?.[index]?.['target_id'];
    this._dialogService.openDialog(StudentRecordEntityComponent, "defaultWithData", {
      data: {
        EntityID: studentContactID,
        eckType: 'contacts',
        bundle: 'student_record',
        action: 'edit',
        fieldsData: '',
      },
    }).afterClosed().subscribe(data => {
      // Reload this component.
      this.ngOnInit();
      this.ngAfterContentInit();
    });
  }

  formatDateField(fieldName: string) {
    const fieldValue = this.studentAccountDetailsForm.value?.[fieldName];
    if (fieldValue && moment(fieldValue, 'YYYY-MM-DD', true).isValid()) {
      this.studentAccountDetailsForm.value[fieldName] = moment(fieldValue).format('YYYY-MM-DD');
    } else {
      this.studentAccountDetailsForm.value[fieldName] = null;
    }
  }


}
