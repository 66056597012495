<!-- General Condition (includes by_the_lesson and others) -->
<ng-container>

	<!-- Test for single payment -->
	<ng-container *ngIf="!is_array(this.entityData?.field_scheduled_payments)">
			<!-- TODO: Calculate "Initial Payment" by adding up all down payments. -->
			<!-- <p>Initial Payment: {{ this.entityData?.field_scheduled_payments?.title }}</p> -->

			<p><strong>Payment Arrangement(s): {{ getPaymentStructureTypeLabel() }}</strong></p>
			<div class="mb-2">
					<li>
							Amount: ${{ this.entityData?.field_scheduled_payments?.[0]?.field_payment_amount }} + (${{ (this.entityData?.field_scheduled_payments?.[0]?.field_payment_amount * (entityData?.field_tax_percentage / 100)) | number:'1.2-2' }})
							- Payment Date: {{ this.entityData?.field_scheduled_payments?.[0]?.field_payment_date }}
					</li>
			</div>
	</ng-container>

	<!-- Test for multiple payments -->
	<ng-container *ngIf="entityData?.field_scheduled_payments && is_array(entityData?.field_scheduled_payments) && objLength(this.entityData?.field_scheduled_payments) > 0">
			<p><strong>Payment Arrangement(s): {{ getPaymentStructureTypeLabel() }}</strong></p>
			<div *ngFor="let payment of this.entityData.field_scheduled_payments" class="mb-2">
					<li>
							Amount: ${{ payment?.['0']?.field_payment_amount }} + (${{ (payment?.['0']?.field_payment_amount * (entityData?.field_tax_percentage / 100)) | number:'1.2-2' }})
							<!-- Special condition for by_the_lesson -->
							<ng-container *ngIf="enrollmentForm.get('field_payments_structure_type').value == 'by_the_lesson'">
									- Due at {{ payment?.['0']?.field_number_lesson_remaining | number:'1.0-0' }} lessons
							</ng-container>
							- Payment Date: {{ (payment?.['0']?.field_payment_date) ? (payment?.['0']?.field_payment_date | date:'shortDate') : 'TBD' }}
					</li>
			</div>
	</ng-container>

</ng-container>
