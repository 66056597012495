import { Component } from '@angular/core';
import { map, tap } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { DrupalRESTService } from './services/drupal-rest.service';
import { UtilityService } from 'src/app/services/utility.service';
import { FieldsService } from './services/fields.service';
import { TaxonomyService } from './services/taxonomy.service';
import { AuthRESTService } from './services/auth-rest.service';
import { AuthService } from './services/auth.service';
import { Router } from '@angular/router';
import { MenuRESTService } from './services/menu-rest.service';
import { BaseComponent } from './components/forms/base-form/base-form.component';
import { SupportRequestComponent } from './components/forms/support-request/support-request.component';
import { ComponentType } from '@angular/cdk/overlay';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  // styleUrls: ['./app.component.css'],
})
export class AppComponent extends BaseComponent {

  supportRequestForm = SupportRequestComponent;

  ngOnInit() {
  }

}
