import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import moment from 'moment';
import { Subscription } from 'rxjs';
import { DrupalRESTService } from 'src/app/services/drupal-rest.service';

@Component({
  selector: 'app-status-badge-update',
  templateUrl: './status-badge-update.component.html',
  styleUrls: ['./status-badge-update.component.css']
})
export class StatusBadgeUpdateComponent {
  @Input() currentStatus: string;
  @Input() lessonId: number;
  @Input() statuses: { id: string, name: string }[] = [];
  @Input() attendeeId: number;
  @Output() statusUpdated = new EventEmitter<{ lessonId: number, statusId: string }>();

  isUpdating = false;
  showDebugInfo = false; // Toggle this to show/hide debug info

  constructor(private _snackBar: MatSnackBar, private _drupalRESTService: DrupalRESTService) {}

  getStatusClass(statusId: string): string {
    switch (statusId) {
      case '63':
        return 'amt-bg-gray badge';
      case '61':
        return 'amt-bg-gray badge';
      case '60':
        return 'amt-bg-orange badge';
      case '64':
        return 'amt-bg-red badge';
      case '62':
        return 'amt-bg-purple badge';
      case '59':
        return 'amt-bg-green badge';
      case '':
        return 'amt-bg-red badge';
      default:
        return 'amt-bg-red badge';
    }
  }

  getStatusName(statusId: string): string {
    const status = this.statuses.find(s => s.id === statusId);
    return status ? status.name : 'Pending Status';
  }

  updateStatus(statusId: string): void {
    this.isUpdating = true;

    const params = [
      { parameter: `selectedStatus[${this.attendeeId}]`, value: statusId },
      { parameter: 'dateTime', value: moment().format('YYYY-MM-DD[T]HH:mm:ss') },
      { parameter: 'eventId', value: this.lessonId.toString() }
    ];

    this._drupalRESTService.httpGET('/api_rest/v1/updateStatus', params).subscribe(
      (response: any) => {
        this.isUpdating = false;
        if (response.success) {
          this.currentStatus = statusId;
          this.statusUpdated.emit({ lessonId: this.lessonId, statusId });
          this._snackBar.open('Status updated successfully', 'Close', {
            duration: 3000,
            panelClass: 'success-snackbar'
          });
        } else {
          this.handleErrorResponse(response);
        }
      },
      error => {
        this.isUpdating = false;
        this.handleErrorResponse(error.error);
      }
    );
  }

  private handleErrorResponse(errorResponse: any): void {
    let errorMessage = 'An error occurred while updating the status';

    if (errorResponse && errorResponse.message) {
      // Display the specific error message from the response
      errorMessage = errorResponse.message;
    } else if (errorResponse && typeof errorResponse === 'string') {
      // In case the error is a plain string
      errorMessage = errorResponse;
    }

    this._snackBar.open(errorMessage, 'Close', {
      duration: 5000,
      panelClass: 'error-snackbar'
    });
  }
}
