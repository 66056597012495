import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DrupalRESTService } from 'src/app/services/drupal-rest.service';

@Component({
  selector: 'app-form-studio-working-hours',
  templateUrl: './form-studio-working-hours.component.html',
  styleUrls: ['./form-studio-working-hours.component.css']
})
export class FormStudioWorkingHoursComponent implements OnInit {
  studioWorkingHoursForm: FormGroup;
  configFieldName = 'field_day_view_reference';
  days = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
  hours = Array.from({ length: 96 }, (_, i) => {
    const hour = Math.floor(i / 4);
    const minute = (i % 4) * 15;
    return `${hour.toString().padStart(2, '0')}${minute.toString().padStart(2, '0')}`;
  });

  actionType: 'create' | 'edit' = 'create';
  showProgressSpinner = false;
  successMessage = '';
  errorMessage = '';

  constructor(
    private fb: FormBuilder,
    private drupalRESTService: DrupalRESTService
  ) {}

  ngOnInit(): void {
    this.studioWorkingHoursForm = this.fb.group({
      field_studio_working_hours: this.fb.array([])
    });

    this.loadStudioWorkingHours();
  }

  get workingHours(): FormArray {
    return this.studioWorkingHoursForm.get('field_studio_working_hours') as FormArray;
  }

  addWorkingHour(day: number, dayDelta: number, allDay: boolean, startHours: string, endHours: string, comment: string) {
    this.workingHours.push(this.fb.group({
      day: [day],
      day_delta: [dayDelta],
      all_day: [allDay],
      starthours: [startHours, Validators.required],
      endhours: [endHours, Validators.required],
      comment: [comment]
    }));
  }

  loadStudioWorkingHours() {
    this.showProgressSpinner = true;

    this.drupalRESTService
      .httpGET(`/api_rest/v1/loadStudioConfig?config_field_name=${this.configFieldName}`)
      .subscribe(
        (response) => {
          const workingHours = response?.['field_studio_working_hours'];
          if (workingHours) {
            // Clear the existing form array
            this.workingHours.clear();

            // Add form groups based on the loaded data
            workingHours.forEach(workingHour => {
              this.workingHours.push(this.fb.group({
                day: [workingHour.day],
                day_delta: [workingHour.day_delta],
                all_day: [workingHour.all_day],
                starthours: [workingHour.starthours.toString().padStart(4, '0'), Validators.required],
                endhours: [workingHour.endhours.toString().padStart(4, '0'), Validators.required],
                comment: [workingHour.comment]
              }));
            });

            this.actionType = 'edit';
          } else {
            // Add default working hours if no data is loaded
            this.days.forEach((day, index) => {
              this.addWorkingHour(index, 0, false, '0900', '1800', '');
            });
          }
          this.showProgressSpinner = false;
        },
        (error) => {
          console.error('Error loading studio working hours:', error);
          this.errorMessage = 'Failed to load studio working hours.';
          this.showProgressSpinner = false;
        }
      );
  }

  saveStudioWorkingHours() {
    this.showProgressSpinner = true;
    this.successMessage = '';
    this.errorMessage = '';

    const postData = {
      config_field_name: this.configFieldName,
      config_data: this.studioWorkingHoursForm.value
    };

    this.drupalRESTService
      .httpPOST('/api_rest/v1/saveStudioConfig', postData)
      .subscribe(
        (response) => {
          console.log('Studio working hours saved successfully:', response);
          this.successMessage = 'Studio working hours saved successfully.';
          this.showProgressSpinner = false;
        },
        (error) => {
          console.error('Error saving studio working hours:', error);
          this.errorMessage = 'Failed to save studio working hours.';
          this.showProgressSpinner = false;
        }
      );
  }

  updateStudioWorkingHours() {
    this.showProgressSpinner = true;
    this.successMessage = '';
    this.errorMessage = '';

    const postData = {
      config_field_name: this.configFieldName,
      config_data: this.studioWorkingHoursForm.value
    };

    this.drupalRESTService
      .httpPOST('/api_rest/v1/saveStudioConfig', postData)
      .subscribe(
        (response) => {
          console.log('Studio working hours updated successfully:', response);
          this.successMessage = 'Studio working hours updated successfully.';
          this.showProgressSpinner = false;
        },
        (error) => {
          console.error('Error updating studio working hours:', error);
          this.errorMessage = 'Failed to update studio working hours.';
          this.showProgressSpinner = false;
        }
      );
  }

  convertTo12HourFormat(time: string): string {
    if (typeof time !== 'string') {
      return '';
    }

    const [hours, minutes] = [time.slice(0, 2), time.slice(2)];
    const period = +hours >= 12 ? 'PM' : 'AM';
    const formattedHours = +hours % 12 || 12;
    return `${formattedHours.toString().padStart(2, '0')}:${minutes} ${period}`;
  }

  resetRow(index: number) {
    const workingHour = this.workingHours.at(index);
    workingHour.patchValue({
      starthours: '0000',
      endhours: '0000',
      all_day: false,
      comment: ''
    });
  }

  copyLastDay(index: number) {
    if (index > 0) {
      const lastDay = this.workingHours.at(index - 1).value;
      const currentDay = this.workingHours.at(index);
      currentDay.patchValue({
        starthours: lastDay.starthours,
        endhours: lastDay.endhours,
        all_day: lastDay.all_day,
        comment: lastDay.comment
      });
    }
  }

  onAllDayChange(index: number) {
    const workingHour = this.workingHours.at(index);
    const allDay = workingHour.get('all_day').value;

    if (allDay) {
      workingHour.patchValue({
        starthours: '0000',
        endhours: '2345'
      });
    } else {
      workingHour.patchValue({
        starthours: '0900',
        endhours: '1800'
      });
    }
  }
}
