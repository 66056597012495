import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'phone'
})
export class PhonePipe implements PipeTransform {
  transform(value: string | number | null | undefined): string {
    if (value == null) return '';

    const cleaned = ('' + value).replace(/\D/g, '');

    if (cleaned.length === 10) {
      const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
      if (match) {
        return '(' + match[1] + ') ' + match[2] + '-' + match[3];
      }
    } else if (cleaned.length === 11 && cleaned.startsWith('1')) {
      const match = cleaned.match(/^1(\d{3})(\d{3})(\d{4})$/);
      if (match) {
        return '+1 (' + match[1] + ') ' + match[2] + '-' + match[3];
      }
    }

    return cleaned; // Return cleaned number if it doesn't match the patterns
  }
}
