import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-standing-appointment',
  templateUrl: './standing-appointment.component.html',
  styleUrls: [
    './standing-appointment.component.css',
    '../../../../../app.component.css'
  ]
})
export class StandingAppointmentComponent implements OnInit {

  // Default value for Title.
  @Input() Title: string = "Standing Appointment";

  constructor() { }

  ngOnInit(): void {
  }
}
