import { Component, Input, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { ViewTableComponent } from 'src/app/components/base/view-table/view-table.component';

@Component({
  selector: 'app-misc-student-report',
  templateUrl: './misc-student-report.component.html',
  styleUrls: ['./misc-student-report.component.css']
})
export class MiscStudentReportComponent extends ViewTableComponent implements OnInit {

  @Input() drupal_studentAccount_id: number;

  override displayedColumns: string[] = [
    // 'enrollmentID',
    // 'field_contacts',
    'field_sale_date',
    'field_instructor',
    'field_category',
    'field_package_name',
    'field_enrollment_lesson_count',
    'field_enrollment_total_price',
    'field_total_paid',
    'field_additional_notes',
    'field_lesson_available',
    // 'isDropped',
    // 'nothing',
    'nothing_1',
  ];

  // override ngOnInit(): void {
    // this.openEntityComponent(EnrollmentEntityComponent, 'packages', 'enrollment', 'create', null)
  // }

  override getData(params: { parameter: string; value: string; }[]) {
    // Show Progress Bar (Loading indicator)
    this.ShowProgressBar = true;
    this.queryParams = params;

    let override_params = [
      { parameter: 'field_category_target_id', value: 'Miscellaneous' },
      { parameter: 'id', value: this.drupal_studentAccount_id+'' }
    ]

    // Add sorting paramaters, if available.
    if (this.sortParams !== undefined) {
      this.queryParams.push(this.sortParams)
    }

    // Load the new data with the filtering parameters.
    this._utilityService.getEnrollmentyView(
      this.queryParams.concat(override_params)
    )
      .subscribe((data) => {
        // ShowProgressBar
        this.ShowProgressBar = false;

        this.data = data;
        this.dataSource = new MatTableDataSource(data['results']);

        // Pagination
        if (data['results']) {
          this.paginator.length = data['count'];
          this.paginator.pageSize = this.pageSize;
          this.paginator.pageIndex = this.paginationIndex;
        }
      },
        error => this.handleError(error)
      )
  }



}
