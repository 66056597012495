<app-entity-mat-dialog-title cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle title="Audit"></app-entity-mat-dialog-title>


<form>

<h2></h2>

<app-success-message [message]=this.successMessage></app-success-message>
<app-error-message [message]=this.errorMessage></app-error-message>

<pre>
</pre>

<!-- <p> -->
	<!-- {{auditData | json}} -->
<!-- </p> -->

<p *ngIf="auditData && !auditData?.adjustmentsMade[0]">
There are no adjustments needed at this time.
</p>

<p *ngFor="let audit of auditData?.adjustmentsMade">
	{{audit}}
</p>

<div style="min-height:150px" *ngIf="showProgressSpinner">
	<mat-progress-spinner class="matProgressSpinnerCentered"
			*ngIf="showProgressSpinner" mode="indeterminate">
	</mat-progress-spinner>
</div>

<form (ngSubmit)="onSubmit(f)" #f="ngForm" class="form"></form>
