import { Component } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DrupalRESTService } from 'src/app/services/drupal-rest.service';

interface ImportResult {
  message: string;
  result: {
    processed: number;
    created: {
      student_accounts: number;
      enrollments: number;
      payments: number;
      lessons: number;
    };
    updated: {
      student_accounts: number;
      enrollments: number;
      payments: number;
      lessons: number;
    };
    deleted: {
      student_accounts: number;
    };
    errors: string[];
  };
  timestamp?: string;
}

@Component({
  selector: 'app-simple-import-spreadsheet',
  templateUrl: './simple-import-spreadsheet.component.html',
  styleUrls: ['./simple-import-spreadsheet.component.css']
})
export class SimpleImportSpreadsheetComponent {
  private apiEndpoint = '/api/ami-simple-import-spreadsheet';
  selectedFile: File | null = null;
  isUploading = false;
  lastImportResult: ImportResult | null = null;

  constructor(
    private drupalRESTService: DrupalRESTService,
    private snackBar: MatSnackBar
  ) {}

  downloadSpreadsheet() {
    this.drupalRESTService.downloadFile(this.apiEndpoint, [])
      .subscribe(
        (response: any) => {
          const blob = new Blob([response.body], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
          const url = window.URL.createObjectURL(blob);
          const link = document.createElement('a');
          link.href = url;
          link.download = 'ami_import_spreadsheet.xlsx';
          link.click();
          window.URL.revokeObjectURL(url);
          this.showMessage('Spreadsheet downloaded successfully');
        },
        (error: any) => {
          console.error('Error downloading the file:', error);
          this.showMessage('Error downloading the spreadsheet');
        }
      );
  }

  onFileSelected(event: Event) {
    const element = event.target as HTMLInputElement;
    let fileList: FileList | null = element.files;
    if (fileList) {
      this.selectedFile = fileList[0];
    }
  }

  uploadSpreadsheet() {
    if (!this.selectedFile) {
      this.showMessage('Please select a file to upload');
      return;
    }

    this.isUploading = true;
    this.lastImportResult = null;

    const formData = new FormData();
    formData.append('file', this.selectedFile, this.selectedFile.name);

    this.drupalRESTService.httpPOST(`${this.apiEndpoint}/upload`, formData)
      .subscribe(
        (response: ImportResult) => {
          console.log('Upload successful', response);
          this.showMessage('Spreadsheet processed successfully');
          this.isUploading = false;
          this.lastImportResult = response;
          this.lastImportResult.timestamp = new Date().toLocaleString();
        },
        error => {
          console.error('Error processing the file:', error);
          this.showMessage('Error processing the spreadsheet: ' + (error.error?.error || error.message));
          this.isUploading = false;
        }
      );
  }

  private showMessage(message: string) {
    this.snackBar.open(message, 'Close', {
      duration: 5000,
    });
  }
}
