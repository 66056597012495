import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import moment from 'moment';
import { ViewTableComponent } from 'src/app/components/base/view-table/view-table.component';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-cash-report',
  templateUrl: './cash-report.component.html',
  styleUrls: ['./cash-report.component.css']
})
export class CashReportComponent extends ViewTableComponent implements OnInit {

  currentPage: number;
  storage: any = {};
  Object: any;
  JSObject: Object = Object;
  environment = environment;

  // PRE ORI	EXT	REN	MISC/NON UNIT SUNDRY REFUNDS
  override displayedColumns: string[] = [
    'nothing',
  ];

  CashReportData = {
    DE: 0,
    PRE: 0,
    ORI: 0,
    EXT: 0,
    MISC: 0,
    NONUNIT: 0,
    PORI: 0,
    SUNDRY: 0,
    REN: 0,
    REFUND: 0,
  };

  CashReportDataWEEK = {
    DE: 0,
    PRE: 0,
    ORI: 0,
    EXT: 0,
    MISC: 0,
    NONUNIT: 0,
    PORI: 0,
    SUNDRY: 0,
    REN: 0,
    REFUND: 0,
  };

  week;
  year;

  override ngAfterContentInit(): void {
    this._activatedRoute.queryParams.subscribe(data => {
      this.ShowProgressBar = false;

      if (data['year']) {
        this.year = data['year']
      } else {
        this.year = moment().format("YYYY");
      }
      if (data['week']) {
        this.week = data['week']
      } else {
        this.week = moment().add(0, 'week').format("w");
      }

      this.getData([
        { parameter: 'week', value: this.week},
        { parameter: 'year', value: this.year},
      ]);
    })
  }

  override getData(params: { parameter: string; value: string; }[]) {
    this.ShowProgressBar = false;
    this.queryParams = params;

    // Add sorting paramaters, if available.
    if (this.sortParams !== undefined) {
      this.queryParams.push(this.sortParams)
    }

    params = [
      ...params,
    ];

    // Default values
    let YTD = moment().year() + "-01-01--today";
    let thisWeek = moment().startOf('week').format('YYYY-MM-DD').toString() + "--" + moment().endOf('week').format('YYYY-MM-DD').toString();

    // Handle parameters for week and year.
    if (params) {
      for (const property in params) {
        if (params[property]?.['parameter'] == 'week') {
          thisWeek = moment().startOf('week').week(+params[property]?.['value']).format('YYYY-MM-DD').toString() + "--" + moment().endOf('week').week(+params[property]?.['value']).format('YYYY-MM-DD').toString();
        }
        if (params[property]?.['parameter'] == 'year') {
          YTD = params[property]?.['value'] + "-01-01--today";
        }
      }
    }

    this.dataSource = new MatTableDataSource([0]);

    this._fieldsService.getInstructorDataPromise().subscribe(data =>{
      data['instructors'].forEach(element => {
        this.storage[element.uid] = {
          'name': element.value
        }

        this.getCashReportTeacher(element.uid, params, YTD, thisWeek, false)
        this.getCashReportRefund(element.uid, params, YTD, thisWeek, true);
      });
    })

    // Get overall summary
    this.getCashReportTeacher(null, params, YTD, thisWeek, false);
    this.getCashReportRefund(null, params, YTD, thisWeek, true);
  }

  getCashReportRefund(teacher_id, params, YTD, thisWeek, refund = true) {
    let teacher_filter = '';
    let refund_filter = '';

    if (teacher_id != null) {
      teacher_filter = (teacher_id != null ? '&field_instructor=' + teacher_id : '');
    }

    refund_filter = '&field_gross_tuition_value_op=<&field_gross_tuition_value[value]=0';

    // Load the new data with the filtering parameters.
    this._drupalRESTService.httpGET("/api/v1/reports/cash-sums?" + teacher_filter + refund_filter + "&field_date_and_time=" + YTD, params)
      .subscribe((data) => {
        if (teacher_id == null) {
          this.CashReportData.REFUND = parseFloat(data['results'][0]['payments_sum']);
        } else {
          this.storage = {
            ...this.storage,
            [teacher_id]: {
              ...this.storage[teacher_id],
              ['REFUND']: parseFloat(data['results'][0]['payments_sum'])
            }
          }
        }
      }, error => this.handleError(error))

    // Load the new data with the filtering parameters.
    this._drupalRESTService.httpGET("/api/v1/reports/cash-sums?" + teacher_filter + refund_filter + "&field_date_and_time=" + thisWeek, params)
    .subscribe((data) => {
      if (teacher_id == null) {
        this.CashReportDataWEEK.REFUND = parseFloat(data['results'][0]['payments_sum']);
      } else {
        this.storage = {
          ...this.storage,
          [teacher_id]: {
            ...this.storage[teacher_id],
            ['WEEK_REFUND']: parseFloat(data['results'][0]['payments_sum'])
          }
        }
      }
    }, error => this.handleError(error))
  }

  getCashReportTeacher(teacher_id, params, YTD, thisWeek, refund = false) {
    let teacher_filter = '';
    let refund_filter = '';

    if (teacher_id != null) {
      teacher_filter = (teacher_id != null ? '&field_instructor=' + teacher_id : '');
    }

    refund_filter = '&field_gross_tuition_value_op=>&field_gross_tuition_value[value]=0';
    if (refund == true) {
      // refund_filter = '&field_gross_tuition_value_op=<&field_gross_tuition_value[value]=0';
    } else {
    }

    // Load the new data with the filtering parameters.
    this._drupalRESTService.httpGET("/api/v1/reports/cash-sums?field_sps_code=547" + teacher_filter + refund_filter + "&field_date_and_time=" + YTD, params)
      .subscribe((data) => {
        if (teacher_id == null) {
          this.CashReportData.PRE = parseFloat(data['results'][0]['payments_sum']);
        } else {
          this.storage = {
            ...this.storage,
            [teacher_id]: {
              ...this.storage[teacher_id],
              ['PRE']: parseFloat(data['results'][0]['payments_sum'])
            }
          }
        }
      }, error => this.handleError(error))

    // EXT
    this._drupalRESTService.httpGET("/api/v1/reports/cash-sums?field_sps_code=550" + teacher_filter + refund_filter + "&field_date_and_time=" + YTD, params)
      .subscribe((data) => {
        if (teacher_id == null) {
          this.CashReportData.EXT = parseFloat(data['results'][0]['payments_sum']);
        } else {
          this.storage = {
            ...this.storage,
            [teacher_id]: {
              ...this.storage[teacher_id],
              ['EXT']: parseFloat(data['results'][0]['payments_sum'])
            }
          }
        }
      }, error => this.handleError(error))

    // MISC
    this._drupalRESTService.httpGET("/api/v1/reports/cash-sums?field_sps_code=554" + teacher_filter + refund_filter + "&field_date_and_time=" + YTD, params)
      .subscribe((data) => {
        if (teacher_id == null) {
          this.CashReportData.MISC = parseFloat(data['results'][0]['payments_sum']);
        } else {
          this.storage = {
            ...this.storage,
            [teacher_id]: {
              ...this.storage[teacher_id],
              ['MISC']: parseFloat(data['results'][0]['payments_sum'])
            }
          }
        }
      }, error => this.handleError(error))

    // NON UNIT
    this._drupalRESTService.httpGET("/api/v1/reports/cash-sums?field_sps_code=657" + teacher_filter + refund_filter + "&field_date_and_time=" + YTD, params)
      .subscribe((data) => {
        if (teacher_id == null) {
          this.CashReportData.NONUNIT = parseFloat(data['results'][0]['payments_sum']);
        } else {
          this.storage = {
            ...this.storage,
            [teacher_id]: {
              ...this.storage[teacher_id],
              ['NONUNIT']: parseFloat(data['results'][0]['payments_sum'])
            }
          }
        }
      }, error => this.handleError(error))

    // ORI
    this._drupalRESTService.httpGET("/api/v1/reports/cash-sums?field_sps_code=549" + teacher_filter + refund_filter + "&field_date_and_time=" + YTD, params)
      .subscribe((data) => {
        if (teacher_id == null) {
          this.CashReportData.ORI = parseFloat(data['results'][0]['payments_sum']);
        } else {
          this.storage = {
            ...this.storage,
            [teacher_id]: {
              ...this.storage[teacher_id],
              ['ORI']: parseFloat(data['results'][0]['payments_sum'])
            }
          }
        }
      }, error => this.handleError(error))

    // PORI
    this._drupalRESTService.httpGET("/api/v1/reports/cash-sums?field_sps_code=547" + teacher_filter + refund_filter + "&field_date_and_time=" + YTD, params)
      .subscribe((data) => {
        if (teacher_id == null) {
          this.CashReportData.PORI = parseFloat(data['results'][0]['payments_sum']);
        } else {
          this.storage = {
            ...this.storage,
            [teacher_id]: {
              ...this.storage[teacher_id],
              ['PORI']: parseFloat(data['results'][0]['payments_sum'])
            }
          }
        }
      }, error => this.handleError(error))

    // REN
    this._drupalRESTService.httpGET("/api/v1/reports/cash-sums?field_sps_code=552" + teacher_filter + refund_filter + "&field_date_and_time=" + YTD, params)
      .subscribe((data) => {
        if (teacher_id == null) {
          this.CashReportData.REN = parseFloat(data['results'][0]['payments_sum']);
        } else {
          this.storage = {
            ...this.storage,
            [teacher_id]: {
              ...this.storage[teacher_id],
              ['REN']: parseFloat(data['results'][0]['payments_sum'])
            }
          }
        }
      }, error => this.handleError(error))

    // SUNDRY
    this._drupalRESTService.httpGET("/api/v1/reports/cash-sums?field_sps_code=558" + teacher_filter + refund_filter + "&field_date_and_time=" + YTD, params)
      .subscribe((data) => {
        if (teacher_id == null) {
          this.CashReportData.SUNDRY = parseFloat(data['results'][0]['payments_sum']);
        } else {
          this.storage = {
            ...this.storage,
            [teacher_id]: {
              ...this.storage[teacher_id],
              ['SUNDRY']: parseFloat(data['results'][0]['payments_sum'])
            }
          }
        }
      }, error => this.handleError(error))

    // WEEK //

    // Load the new data with the filtering parameters.
    this._drupalRESTService.httpGET("/api/v1/reports/cash-sums?field_sps_code=547" + teacher_filter + refund_filter + "&field_date_and_time=" + thisWeek, params)
      .subscribe((data) => {
        if (teacher_id == null) {
          this.CashReportDataWEEK.PRE = parseFloat(data['results'][0]['payments_sum']);
        } else {
          this.storage = {
            ...this.storage,
            [teacher_id]: {
              ...this.storage[teacher_id],
              ['WEEK_PRE']: parseFloat(data['results'][0]['payments_sum'])
            }
          }
        }
        // this.CashReportDataWEEK.PRE = parseFloat(data['results'][0]['payments_sum']);
      }, error => this.handleError(error))

    // Load the new data with the filtering parameters.
    this._drupalRESTService.httpGET("/api/v1/reports/cash-sums?field_sps_code=550" + teacher_filter + refund_filter + "&field_date_and_time=" + thisWeek, params)
      .subscribe((data) => {
        if (teacher_id == null) {
          this.CashReportDataWEEK.EXT = parseFloat(data['results'][0]['payments_sum']);
        } else {
          this.storage = {
            ...this.storage,
            [teacher_id]: {
              ...this.storage[teacher_id],
              ['WEEK_EXT']: parseFloat(data['results'][0]['payments_sum'])
            }
          }
        }
      }, error => this.handleError(error))

    // MISC
    this._drupalRESTService.httpGET("/api/v1/reports/cash-sums?field_sps_code=554" + teacher_filter + refund_filter + "&field_date_and_time=" + thisWeek, params)
      .subscribe((data) => {
        if (teacher_id == null) {
          this.CashReportDataWEEK.MISC = parseFloat(data['results'][0]['payments_sum']);
        } else {
          this.storage = {
            ...this.storage,
            [teacher_id]: {
              ...this.storage[teacher_id],
              ['WEEK_MISC']: parseFloat(data['results'][0]['payments_sum'])
            }
          }
        }
      }, error => this.handleError(error))

    // NON UNIT
    this._drupalRESTService.httpGET("/api/v1/reports/cash-sums?field_sps_code=657" + teacher_filter + refund_filter + "&field_date_and_time=" + thisWeek, params)
      .subscribe((data) => {
        if (teacher_id == null) {
          this.CashReportDataWEEK.NONUNIT = parseFloat(data['results'][0]['payments_sum']);
        } else {
          this.storage = {
            ...this.storage,
            [teacher_id]: {
              ...this.storage[teacher_id],
              ['WEEK_NONUNIT']: parseFloat(data['results'][0]['payments_sum'])
            }
          }
        }
      }, error => this.handleError(error))

    // ORI
    this._drupalRESTService.httpGET("/api/v1/reports/cash-sums?field_sps_code=549" + teacher_filter + refund_filter + "&field_date_and_time=" + thisWeek, params)
      .subscribe((data) => {
        if (teacher_id == null) {
          this.CashReportDataWEEK.ORI = parseFloat(data['results'][0]['payments_sum']);
        } else {
          this.storage = {
            ...this.storage,
            [teacher_id]: {
              ...this.storage[teacher_id],
              ['WEEK_ORI']: parseFloat(data['results'][0]['payments_sum'])
            }
          }
        }
      }, error => this.handleError(error))

    // PORI
    this._drupalRESTService.httpGET("/api/v1/reports/cash-sums?field_sps_code=547" + teacher_filter + refund_filter + "&field_date_and_time=" + thisWeek, params)
      .subscribe((data) => {
        if (teacher_id == null) {
          this.CashReportDataWEEK.PORI = parseFloat(data['results'][0]['payments_sum']);
        } else {
          this.storage = {
            ...this.storage,
            [teacher_id]: {
              ...this.storage[teacher_id],
              ['WEEK_PORI']: parseFloat(data['results'][0]['payments_sum'])
            }
          }
        }
      }, error => this.handleError(error))

    // REN
    this._drupalRESTService.httpGET("/api/v1/reports/cash-sums?field_sps_code=552" + teacher_filter + refund_filter + "&field_date_and_time=" + thisWeek, params)
      .subscribe((data) => {
        if (teacher_id == null) {
          this.CashReportDataWEEK.REN = parseFloat(data['results'][0]['payments_sum']);
        } else {
          this.storage = {
            ...this.storage,
            [teacher_id]: {
              ...this.storage[teacher_id],
              ['WEEK_REN']: parseFloat(data['results'][0]['payments_sum'])
            }
          }
        }
      }, error => this.handleError(error))

    // SUNDRY
    this._drupalRESTService.httpGET("/api/v1/reports/cash-sums?field_sps_code=558" + teacher_filter + refund_filter + "&field_date_and_time=" + thisWeek, params)
      .subscribe((data) => {
        if (teacher_id == null) {
          this.CashReportDataWEEK.SUNDRY = parseFloat(data['results'][0]['payments_sum']);
        } else {
          this.storage = {
            ...this.storage,
            [teacher_id]: {
              ...this.storage[teacher_id],
              ['WEEK_SUNDRY']: parseFloat(data['results'][0]['payments_sum'])
            }
          }
        }
      }, error => this.handleError(error))
  }

}
