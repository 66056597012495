
import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import moment from 'moment';
import { ViewTableComponent } from '../../base/view-table/view-table.component';

@Component({
  selector: 'app-lessons-list-report',
  templateUrl: './lessons-list-report.component.html',
  styleUrls: ['./lessons-list-report.component.css']
})
export class LessonsListReportComponent extends ViewTableComponent implements OnInit {
  enrollmentFilter: boolean | null = null;
  override displayedColumns: string[] = [
    'id',
    // 'title',
    'field_status',
    'nothing_1',
    // 'field_instructor',
    'view_user',
    'views_conditional_field',
    'title_1',
    'field_email',
    'field_date_and_time_value',
    'views_conditional_field_1',
    'field_date_and_time_1',
    'field_expiration_date_1',
    'field_duration',
    'created',
    'field_type',
    // 'field_repetition_frequency',
    // 'field_type_1',
    // 'field_category',
    // 'edit_events',
    // 'views_conditional_field_2',
    'event_actions',
  ];

  exportLessonListReport() {
    let exportParams = this.prepareExportParams(this.queryParams);
    console.log('Export params:', exportParams); // For debugging
    this.downloadCSVDrupalViews('/api/export/appointment-list', 'appointment_list', exportParams);
  }

  private prepareExportParams(params: { parameter: string; value: string; }[]): { parameter: string; value: string; }[] {
    let exportParams = [...params]; // Create a copy of the params

    // Ensure type_event_entity_type is set to 'All'
    this.addOrUpdateParam(exportParams, 'type_event_entity_type', 'All');

    // Ensure instructor is set to an empty string if not already set
    if (!exportParams.find(p => p.parameter === 'instructor')) {
      this.addOrUpdateParam(exportParams, 'instructor', '');
    }

    // Handle enrollment filter
    const enrollmentParamIndex = exportParams.findIndex(p => p.parameter === 'id_op_enrollment');
    if (this.enrollmentFilter === true) {
      this.updateQueryParam(enrollmentParamIndex, 'empty', exportParams);
    } else if (this.enrollmentFilter === false) {
      this.updateQueryParam(enrollmentParamIndex, 'not+empty', exportParams);
    } else {
      // Remove the parameter for the undefined state
      if (enrollmentParamIndex !== -1) {
        exportParams.splice(enrollmentParamIndex, 1);
      }
    }

    return exportParams;
  }

  private addOrUpdateParam(params: { parameter: string; value: string; }[], parameter: string, value: string) {
    const index = params.findIndex(p => p.parameter === parameter);
    if (index !== -1) {
      params[index].value = value;
    } else {
      params.push({ parameter, value });
    }
  }

  private updateQueryParam(index: number, value: string, params: { parameter: string; value: string; }[]) {
    const param = { parameter: 'id_op_enrollment', value: value };
    if (index === -1) {
      params.push(param);
    } else {
      params[index] = param;
    }
  }

  override getData(params: { parameter: string; value: string; }[]) {
    this.ShowProgressBar = true;
    this.queryParams = params;

    // Fix for instructor not set.
    let instructor = params.find(obj => obj.parameter === 'instructor');
    let type_event_entity_type = params.find(obj => obj.parameter === 'type_event_entity_type');

    if (!instructor) {
      this.queryParams.push({ parameter: 'instructor', value: '' });
    }

    if (!type_event_entity_type) {
      this.queryParams.push({ parameter: 'type_event_entity_type', value: 'All' });
    }

    // Update the id_op_enrollment parameter based on enrollmentFilter state
    const enrollmentParamIndex = this.queryParams.findIndex(p => p.parameter === 'id_op_enrollment');
    if (this.enrollmentFilter === true) {
      this.updateQueryParam(enrollmentParamIndex, 'empty', this.queryParams);
    } else if (this.enrollmentFilter === false) {
      this.updateQueryParam(enrollmentParamIndex, 'not+empty', this.queryParams);
    } else {
      // Remove the parameter for the undefined state
      if (enrollmentParamIndex !== -1) {
        this.queryParams.splice(enrollmentParamIndex, 1);
      }
    }

    // Load the new data with the filtering parameters.
    this._utilityService.getLessonListReport(
      this.queryParams
    )
      .subscribe((data) => {
        // ShowProgressBar
        this.ShowProgressBar = false;

        this.data = data;
        this.dataSource = new MatTableDataSource(data['results']);

        // Pagination
        if (data['results']) {
          this.setupPagination(data, params);
        }

        // Post process the data.
        this.data['results'].forEach(element => {
          if (element?.field_date_and_time && element?.field_duration) {
            // Calculate the start time + duration to get the end time of the event.
            element.field_expiration_date_1 = moment(element.field_date_and_time).add(moment.duration(element.field_duration)).format('hh:mm A');
            element.views_conditional_field_1 = moment(element.field_date_and_time).add(moment.duration(element.field_duration)).format('M/D/Y hh:mm A')
            // Pretty the date and time field.
            element.field_date_and_time = moment(element.field_date_and_time).format('M/D/Y hh:mm A')
          }

        });
      },
        error => this.handleError(error)
      )
  }

  onEnrollmentFilterChange() {
    if (this.enrollmentFilter === null) {
      this.enrollmentFilter = true;
    } else if (this.enrollmentFilter === true) {
      this.enrollmentFilter = false;
    } else {
      this.enrollmentFilter = null;
    }
    this.getData(this.queryParams);
  }

}
