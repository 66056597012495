import { CommonModule } from "@angular/common";
import { Component, OnInit, AfterViewInit, ViewChild, VERSION } from '@angular/core';
import { LiveAnnouncer } from '@angular/cdk/a11y';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { UtilityService } from 'src/app/services/utility.service';
import { MatPaginator, PageEvent } from "@angular/material/paginator";
import { FormControl, FormGroup, NgForm } from "@angular/forms";
import { DrupalRESTService } from "src/app/services/drupal-rest.service";
import { ActivatedRoute, Event, Params, Router } from "@angular/router";
import { saveAs } from 'file-saver';
import moment from "moment";
import { ViewTableComponent } from "../../base/view-table/view-table.component";

@Component({
  selector: 'app-royalty-report',
  templateUrl: './royalty-report.component.html',
  styleUrls: ['./royalty-report.component.css']
})
export class RoyaltyReportComponent extends ViewTableComponent implements OnInit {

  override displayedColumns: string[] = [
    'receipt_number',
    'date_paid',
    'student_name',
    'executive',
    'instructor',
    'sale_code',
    'package_name',
    'lesson_count',
    'total_price',
    'cash',
    'field_tax_amount',
    'miscellaneous_services',
    'subject_to_royalty',
    'sundry',
    'total_receipts'
  ];

  currentReport: string = 'studioBusiness';
  week: string;
  year: string;
  royaltyPercentage;
  royaltyDataRefunds;

  onYearChange(event: any) {
    this.year = event.value;
    console.log('year', this.year)
    this.WeeksForYearData = this._fieldsService.WeeksForYearData(Number.parseInt(this.year));
  }

  override ngOnInit(): void {
    // Pull royalty percentage.
    this._fieldsService.getRoyaltyPercentageConfig().subscribe(data => {
      this.setRoyaltyPercentageBasedOnDate(data);
    });
  }

  override ngAfterContentInit(): void {
    this._activatedRoute.queryParams.subscribe(data => {
      // console.log('subscribed data', data)
      this.ShowProgressBar = false;

      if (data['year']) {
        this.year = data['year']
      } else {
        this.year = moment().format("YYYY");
      }
      if (data['week']) {
        this.week = data['week']
      } else {
        this.week = moment().add(0, 'week').format("w");
      }

      this.getData([
        { parameter: 'week', value: this.week},
        { parameter: 'year', value: this.year},
      ]);
    })
  }

  override getData(params: { parameter: string; value: string; }[]) {
    // Disable buttons if it's the current week.
    let paramWeek = moment().day("Sunday").year(+this.year).week(+this.week).add(1, 'weeks');
    this.isDisabled = paramWeek.isAfter();

    this.clearFormTableData();

    // Show Progress Bar (Loading indicator)
    this.ShowProgressBar = true;
    this.queryParams = params;

    // Add sorting paramaters, if available.
    if (this.sortParams !== undefined) {
      this.queryParams.push(this.sortParams)
    }

    // Load the new student list with the filtering parameters.
    this._utilityService.getRoyaltyReport(
      this.queryParams
    )
      .subscribe((data) => {
        // ShowProgressBar
        this.ShowProgressBar = false;
        // console.log(data['#royalty_content_table']?.staff_data_4_system['#rows']);

        let rows = data['#royalty_content_table']?.staff_data_4_system['#rows'];

        // No rows? Don't continue...
        if (!rows) { return; }

        // Check if any of the data has a non-zero or non-empty field_tax_amount
        const hasTaxData = rows.some(
          (item: any) =>
            item.field_tax_amount !== '' &&
            item.field_tax_amount !== '0' &&
            item.field_tax_amount !== '0.00'
        );

        if (hasTaxData) {
          // If tax data exists, include the "Tax" column
          if (!this.displayedColumns.includes('field_tax_amount')) {
            this.displayedColumns.push('field_tax_amount');
          }
        } else {
          // If no tax data exists, remove the "Tax" column
          const taxColumnIndex = this.displayedColumns.indexOf('field_tax_amount');
          if (taxColumnIndex !== -1) {
            this.displayedColumns.splice(taxColumnIndex, 1);
          }
        }

        // Modify data to work in Angular.
        rows.forEach((element, index) => {
          if (element.student_name) {
            rows[index]['student_name'] = element.student_name.replace(/<[^>]*>?/gm, '');
          }
        });

        // Extract the refunds from the rows to be displayed seperately.
        this.filterRefunds(rows);

        this.data = data;
        this.dataSource = new MatTableDataSource(rows);

        // Recalculate royalty percentage based on the new data
        this._fieldsService.getRoyaltyPercentageConfig().subscribe(royaltyData => {
          this.setRoyaltyPercentageBasedOnDate(royaltyData);
        });

        // Pagination configuration.
        this.updatePaginationConfig(rows);
      },
        error => this.handleError(error)
      )
  }

  filterRefunds(rows) {
    this.royaltyDataRefunds = rows.filter(object => {
      // let isRefund = (+object.total_receipts.replace('$', '') < 0);
      let isRefund = (+object.total_receipts < 0);

      return (isRefund);
    });

    // console.log('refunds', this.royaltyDataRefunds);
  }

  calcRoyaltyPercentage(amount) {
    amount = this._filterDigits(amount+'');
    return (amount * (this.royaltyPercentage / 100));
  }

  setRoyaltyPercentageBasedOnDate(data) {
    // Extract start and end dates of the specified week.
    const startDate = moment().year(+this.year).isoWeek(+this.week).startOf('isoWeek').utc().startOf('day').toDate();
    const endDate = moment().year(+this.year).isoWeek(+this.week).endOf('isoWeek').utc().endOf('day').toDate();

    let foundMatchingPeriod = false;
    let lowestPercentage = Infinity;

    // Check if 'royalty_periods' exists and has data.
    if (data.royalty_periods && data.royalty_periods.length > 0) {
      for (const period of data.royalty_periods) {
        // Extract period start and end dates and check if they exist.
        const periodStartDate = moment(period.start_date).utc().startOf('day').toDate();
        const periodEndDate = moment(period.end_date).utc().endOf('day').toDate();

        // If start and end dates exist, check for matching period.
        if (periodStartDate && periodEndDate && startDate >= periodStartDate && endDate <= periodEndDate) {
          let percentage = parseFloat(period.royalty_percentage);
          if (percentage < lowestPercentage) {
            lowestPercentage = percentage;
            this.royaltyPercentage = percentage;
            foundMatchingPeriod = true;
          }
        }
      }
    }
    // If no matching period is found, set the default royalty percentage
    if (!foundMatchingPeriod && data.default_royalty_percentage) {
      console.log('no matching period found, using default royalty percentage', data.default_royalty_percentage)
      this.royaltyPercentage = parseFloat(data.default_royalty_percentage);
    }
  }

  combineData(firstData: any[], secondData: any[]): any[] {
    const transformedSecondData = this.transformSecondDataset(secondData);
    return [...firstData, ...transformedSecondData];
  }
  transformSecondDataset(secondData: any[]): any[] {
    return secondData.map(item => {
      return {
        receipt_number: '',  // Placeholder
        date_paid: '',       // Placeholder
        student_name: '',    // Placeholder
        executive: '',       // Placeholder
        field_executive_display: '',  // Placeholder
        field_instructor_display: '', // Placeholder
        sale_code: '',       // Placeholder
        package_name: '',    // Placeholder
        lesson_count: '',    // Placeholder
        total_price: '',     // Placeholder
        cash: item.total?.cash || item.total_refunded?.cash || item.total_include_refunded?.cash || item.royalty_percentage?.cash,
        miscellaneous_services: item.total?.miscellaneous_services || item.total_refunded?.miscellaneous_services || item.total_include_refunded?.miscellaneous_services || item.royalty_percentage?.miscellaneous_services,
        subject_to_royalty: item.total?.subject_to_royalty || item.total_refunded?.subject_to_royalty || item.total_include_refunded?.subject_to_royalty || item.royalty_percentage?.subject_to_royalty,
        sundry: item.total?.sundry || item.total_refunded?.sundry || item.total_include_refunded?.sundry,
        total_receipts: item.total?.total_receipts || item.total_refunded?.total_receipts || item.total_include_refunded?.total_receipts || item.royalty_percentage?.total_receipts
      };
    });
  }

}
