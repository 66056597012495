<h1 class="page-header">Package List</h1>

<nav mat-tab-nav-bar #tabPanel>
  <a mat-tab-link *ngFor="let link of links"
     (click)="activeLink = link"
     routerLink="/{{link.linkRoute}}"
     [active]="activeLink == link.linkTitle"> {{link.linkTitle}} </a>
</nav>

<mat-tab-nav-panel #tabPanel>

	<mat-expansion-panel>
		<mat-expansion-panel-header>
			<mat-panel-title>
				Filter
			</mat-panel-title>
			<mat-panel-description>
				<app-filter-results-panel [parameters]=queryParams></app-filter-results-panel>
			</mat-panel-description>
		</mat-expansion-panel-header>
		<form (ngSubmit)="onSubmit(f)" #f=ngForm class="form">
			<div class="row">

				<div class="col-2">
					<mat-form-field class="full-width-field">
						<mat-label>Name</mat-label>
						<input
							matInput
							ngModel
							name="field_package_name_value"
							(blur)="onSubmit(f)"
							(selectionChange)="onSubmit(f)">
					</mat-form-field>
				</div>


				<div class="col-2">
					<mat-form-field class="full-width-field">
						<mat-label>SPS Code</mat-label>
						<input
							matInput
							ngModel
							name="field_sps_code_target_id"
							(blur)="onSubmit(f)"
							(selectionChange)="onSubmit(f)">
					</mat-form-field>
				</div>


				<div class="col-2">
					<mat-form-field class="full-width-field">
						<mat-label>Lesson Type</mat-label>
						<input
							matInput
							ngModel
							name="field_lesson_type_target_id"
							(blur)="onSubmit(f)"
							(selectionChange)="onSubmit(f)">
					</mat-form-field>
				</div>


				<div class="col-2">
					<mat-form-field class="full-width-field">
						<mat-label>Lesson Price</mat-label>
						<input
							matInput
							ngModel
							name="field_lesson_price_value"
							(blur)="onSubmit(f)"
							(selectionChange)="onSubmit(f)">
					</mat-form-field>
				</div>


				<div class="col-2">
					<mat-form-field class="full-width-field">
						<mat-label>Lesson Count</mat-label>
						<input
							matInput
							ngModel
							name="field_lesson_count_value"
							(blur)="onSubmit(f)"
							(selectionChange)="onSubmit(f)">
					</mat-form-field>
				</div>


				<div class="col-2">
					<mat-form-field class="full-width-field">
						<mat-label>Total Price</mat-label>
						<input
							matInput
							ngModel
							name="field_total_price_value"
							(blur)="onSubmit(f)"
							(selectionChange)="onSubmit(f)">
					</mat-form-field>
				</div>

				<div class="col-2">
					<mat-form-field class="full-width-field">
						<mat-label>Sort by</mat-label>
						<mat-select
							matInput
							ngModel
							name="sort_by"
							(blur)="onSubmit(f)"
							(selectionChange)="onSubmit(f)">
								<mat-option value="field_total_price_value" selected="selected">Total Price</mat-option>
								<mat-option value="field_lesson_count_value_1">Lesson Count</mat-option>
								<mat-option value="field_lesson_price_value">Lesson Price</mat-option>
						</mat-select>
					</mat-form-field>
				</div>

				<div class="col-2">
					<mat-form-field class="full-width-field">
						<mat-label>Order by</mat-label>
						<mat-select
							matInput
							ngModel
							name="sort_order"
							(blur)="onSubmit(f)"
							(selectionChange)="onSubmit(f)">
								<mat-option value="ASC" selected="selected">Asc</mat-option>
								<mat-option value="DESC">Desc</mat-option>
						</mat-select>
					</mat-form-field>
				</div>


				<section class="section">

					<div class="float-end bring-inline">
						<button (click)="resetForm()" color="primary" mat-flat-button
							class="me-1" mat-button>Clear
							<!-- <mat-icon (click)="resetForm()" aria-hidden="false" aria-label="Menu dropdown" class=""></mat-icon> -->
						</button>
					</div>

				</section>


			</div>


			<section class="section">
			</section>

		</form>

	</mat-expansion-panel>

	<mat-progress-bar *ngIf="ShowProgressBar" mode="indeterminate"></mat-progress-bar>

	<div class="row">
			<div class="col-md-12 mt-3">
					<div class="float-end">
							<button mat-stroked-button color="primary"
									(click)="openEntityComponent(PackageEntityComponent, 'packages', 'package', 'create', null)" mat-button>
									Add Package
							</button>
					</div>
			</div>
	</div>

	<div class="table-container">

		<table mat-table #displayTable [dataSource]="dataSource"
			matSort
			(matSortChange)="sortData($event)"
			class="mt-4">

			<!-- field_abbreviation Column -->
			<ng-container matColumnDef="field_abbreviation">
				<th mat-header-cell *matHeaderCellDef
					sortActionDescription="Sort by field_abbreviation">
					Abbreviation
				</th>
				<td mat-cell *matCellDef="let element"> {{element.field_abbreviation}} </td>
			</ng-container>

			<!-- field_lesson_count Column -->
			<ng-container matColumnDef="field_lesson_count">
				<th mat-header-cell *matHeaderCellDef
					sortActionDescription="Sort by field_lesson_count">
					Lesson Count
				</th>
				<td mat-cell *matCellDef="let element"> {{ element.field_lesson_count }} </td>
			</ng-container>

			<!-- field_lesson_price Column -->
			<ng-container matColumnDef="field_lesson_price">
					<th mat-header-cell *matHeaderCellDef
						sortActionDescription="Sort by field_lesson_price">
						Lesson Price
					</th>
					<td mat-cell *matCellDef="let element"> {{ element.field_lesson_price }} </td>
				</ng-container>

				<!-- field_lesson_type Column -->
				<ng-container matColumnDef="field_lesson_type">
					<th mat-header-cell *matHeaderCellDef
						sortActionDescription="Sort by field_lesson_type">
						Lesson Type
					</th>
					<td mat-cell *matCellDef="let element"> {{ element.field_lesson_type }} </td>
				</ng-container>

				<!-- field_package_name Column -->
				<ng-container matColumnDef="field_package_name">
					<th mat-header-cell *matHeaderCellDef
						sortActionDescription="Sort by field_package_name">
						Name
					</th>
					<td mat-cell *matCellDef="let element"> {{ element.field_package_name }} </td>
				</ng-container>

				<!-- field_sps_code Column -->
				<ng-container matColumnDef="field_sps_code">
					<th mat-header-cell *matHeaderCellDef
						sortActionDescription="Sort by field_sps_code">
						SPS Code
					</th>
					<td mat-cell *matCellDef="let element"> {{ element.field_sps_code }} </td>
				</ng-container>

				<!-- field_total_price Column -->
				<ng-container matColumnDef="field_total_price">
					<th mat-header-cell *matHeaderCellDef
						sortActionDescription="Sort by field_total_price">
						Total Price
					</th>
					<td mat-cell *matCellDef="let element"> {{ element.field_total_price }} </td>
				</ng-container>

				<!-- field_custom_text Column -->
				<ng-container matColumnDef="field_custom_text">
					<th mat-header-cell *matHeaderCellDef
						sortActionDescription="Sort by field_custom_text">
						<!-- Custom text -->
					</th>

					<td mat-cell *matCellDef="let element">
					<button
						mat-stroked-button
						PackageEntityComponent
						(click)="openEntityComponent(PackageEntityComponent, 'packages', 'package', 'edit', element.id)"
						mat-button>
							Edit
					</button>
						<!-- {{ element.id }} -->
						 <!-- {{ element.field_custom_text }}  -->
						</td>
				</ng-container>


			<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
			<tr mat-row matRipple *matRowDef="let row; columns: displayedColumns;"></tr>
		</table>

		<mat-paginator
		  [disabled]="ShowProgressBar"
			(page)="pageChanged($event)"
			[pageSizeOptions]="onPageSizeOptions()"
			[length]="30"
			[pageSize]="paginationTotalPages"
			[pageIndex]="paginationIndex"
			aria-label="Select page of view"></mat-paginator>

	</div>


</mat-tab-nav-panel>
