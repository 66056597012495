<app-entity-mat-dialog-title title="Set Opening Balance"></app-entity-mat-dialog-title>

<!-- <app-success-message [message]=this.successMessage></app-error-message> -->
<app-error-message [message]=this.errorMessage></app-error-message>

<div style="min-height:150px" *ngIf="showProgressSpinner">
    <mat-progress-spinner class="matProgressSpinnerCentered"
        *ngIf="showProgressSpinner" mode="indeterminate">
    </mat-progress-spinner>
</div>

<form (ngSubmit)="onSubmit(f)" #f="ngForm" class="form">
  <div class="container">
      <div class="row">

          <div class="col-12">
              <mat-form-field class="full-width-field">
                  <mat-label>Starting Balance Value</mat-label>
                  <input
                    name="balance"
                    matInput
                    type="number"
                    ngModel>
              </mat-form-field>
          </div>

        </div>
    </div>
</form>


<mat-dialog-actions align="end">
        <button type="button" (click)="onSubmit(f);" mat-raised-button
            color="accent">Save</button>
      <button mat-dialog-close type="submit" mat-raised-button
            color="accent">Cancel</button>
</mat-dialog-actions>
