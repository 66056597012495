import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormArray, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { index } from 'handsontable/helpers/dom';
import { data, error } from 'jquery';
import { ConfirmDialogComponent } from 'src/app/components/utility/confirm-dialog/confirm-dialog.component';
import { DrupalRESTService } from 'src/app/services/drupal-rest.service';
import { EntityRESTService } from 'src/app/services/entity-rest.service';
import { PackagesService } from 'src/app/services/packages.service';
import { TaxonomyService } from 'src/app/services/taxonomy.service';

@Component({
  selector: 'app-simple-import-packages',
  templateUrl: './simple-import-packages.component.html',
  styleUrls: ['./simple-import-packages.component.css']
})
export class SimpleImportPackagesComponent implements OnInit {

  packageForm: FormGroup;
  packageErrorMessages: string[] = [];
  packageSuccessStates: boolean[] = [];

  constructor(
    private fb: FormBuilder,
    private entityRESTService: EntityRESTService,
    private dialog: MatDialog,
    private drupalRESTService: DrupalRESTService,
    private _taxonomyService: TaxonomyService,
    private packagesService: PackagesService,
    private snackBar: MatSnackBar
  ) { }

  ngOnInit() {
    this.packageForm = this.fb.group({
      packages: this.fb.array([])
    });

    this.packagesService.packages$.subscribe(data => {
      this.populatePackages(data);
    });

    this.packagesService.fetchPackages();
  }

  get packageArray(): FormArray {
    return this.packageForm.get('packages') as FormArray;
  }

  fetchPackages() {
    this.drupalRESTService.httpGET('/api/v1/packages').subscribe(data => {
      this.populatePackages(data?.['results'] || []);
    }, error => {
      console.error('Error fetching packages:', error);
    });
  }

  addPackage(packageData: any = {}) {
    const packageGroup = this.fb.group({
      id: [packageData.id || ''],
      field_package_name: [packageData.field_package_name || '', Validators.required],
      field_abbreviation: [packageData.field_abbreviation],
      field_sps_code: [packageData.field_sps_code_id || ''],
      field_lesson_type: [packageData.field_lesson_type_id || ''],
      field_lesson_count: [packageData.field_lesson_count || ''],
      field_lesson_price: [packageData.field_lesson_price || ''],
      // field_lesson_type: [packageData.field_lesson_type_id || ''],
      field_additional_notes: [packageData.field_additional_notes || ''],
      field_discount: [packageData.field_discount || ''],
      field_discount_type: [packageData.field_discount_type || ''],
      field_total_price: [packageData.field_total_price || ''],
    });

    // Subscribe to changes in field_sps_code and update field_lesson_type accordingly
    packageGroup.get('field_sps_code').valueChanges.subscribe(spsCode => {
      const lessonTypeId = this.mapLessonType(spsCode);
      packageGroup.get('field_lesson_type').setValue(lessonTypeId, { emitEvent: false });
    });

    this.packageArray.insert(0, packageGroup);

    // Clear error messages and success states.
    this.packageErrorMessages.unshift('');
    this.packageSuccessStates.unshift(false);
  }

  deletePackage(index: number) {
    const packageId = this.packageArray.at(index).value.id;
    if (packageId) {
      this.packagesService.deletePackage(packageId).subscribe({
        next: () => {
          // Package deleted successfully
          this.packageArray.removeAt(index);
          this.packageErrorMessages.splice(index, 1);
        },
        error: (error) => {
          // Error handling
          this.snackBar.open('Failed to delete package. Please try again.', 'Close', { duration: 3000 });
          console.error('Error deleting package:', error);
        }
      });
    } else {
      // Handle case when packageId is not available
      this.packageArray.removeAt(index);
      this.packageErrorMessages.splice(index, 1);
    }
  }

  onSubmitPackages() {
    this.packageErrorMessages = [];
    this.packageSuccessStates = [];

    this.packageArray.controls.forEach((packageFormGroup, index) => {
      const packageData = packageFormGroup.value;

      // Sanitize and transform the field_lesson_price value
      if (packageData.field_lesson_price) {
        packageData.field_lesson_price = this.sanitizePrice(packageData.field_lesson_price);
      }

      // If abbreviation is not provided, generate one from the package name.
      if (!packageData.field_abbreviation || packageData.field_abbreviation === '') {
        packageData.field_abbreviation = packageData.field_package_name.replace(/\s/g, '').substring(0, 3).toUpperCase();
      }

      // Determine discount type and calculate total price
      if (packageData.field_discount !== undefined && packageData.field_discount !== null) {
        const discountStr = packageData.field_discount.toString();
        packageData.field_discount_type = discountStr.includes('%') ? 'percentage' : 'flat';
        packageData.field_discount = this.sanitizePrice(discountStr.replace('%', ''));
      }

      // Calculate total price
      let lessonPrice = this.sanitizePrice(packageData.field_lesson_price);
      let lessonCount = packageData.field_lesson_count == 0 ? 1 : packageData.field_lesson_count;
      let discount = packageData.field_discount_type === 'percentage' ?
        (lessonPrice * lessonCount) * (packageData.field_discount / 100) :
        packageData.field_discount;
      packageData.field_total_price = (lessonPrice * lessonCount) - discount;


      const requestObservable = packageData.id ?
        this.entityRESTService.patchEntity('packages', 'package', packageData.id, packageData) :
        this.entityRESTService.createEntity('packages', 'package', packageData);

      requestObservable.subscribe(response => {
        console.log('Package processed:', response);
        this.packageSuccessStates[index] = true;

        // Set the package ID if it's a new package
        if (!packageData.id) {
          packageFormGroup.patchValue({ id: response.id[0].value });
        }
      }, error => {
        console.error('Error processing package:', error);
        this.packageErrorMessages[index] = error.error.message ?? 'Error processing package.';
        this.packageSuccessStates[index] = false;
      });
    });
  }

  populatePackages(data: any[]) {
    this.packageArray.clear();
    data.forEach(packageData => {
      // Format discount as a percentage string if discount type is 'percentage'
      if (packageData.field_discount_type === 'percentage') {
        packageData.field_discount = `${packageData.field_discount}%`;
      }
      if (packageData.field_discount === '') {
        packageData.field_discount = '0';
      }
      if (packageData.field_lesson_count === '') {
        packageData.field_lesson_count = '0';
      }

      this.addPackage(packageData);
    });

    if (data.length === 0) {
      this.addPackage();
    }
  }

  sanitizePrice(price: any): number {
    // Convert price to string if it's not already
    const priceStr = (price === null || price === undefined) ? '' : price.toString();

    // Remove non-numeric characters and convert to a float
    return parseFloat(priceStr.replace(/[^0-9.-]+/g, ""));
  }

  openDeleteDialog(index: number) {
    const packageData = this.packageArray.at(index).value;
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '600px',
      height: 'auto',
      data: {
        title: 'Confirm Deletion',
        message: `Are you sure you want to delete package ${packageData.field_package_name}?`
      }
    });

    dialogRef.afterClosed().subscribe(confirmed => {
      if (confirmed) {
        this.deletePackage(index);
      }
    });
  }

  mapLessonType(spsCode) {
    console.log('mapLessonType spsCode', spsCode)
    switch (spsCode) {
      case '1394': // DE
        return '560'; // DE
      case '550': // EXT
      case '549': // ORI
        return '548'; // Front Department
      case '552': // REN
        return '600'; // Back Department
      case '547': // PORI
        return '1393'; // PORI
      default:
        return null;
    }
  }
}
