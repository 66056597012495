import { Component, Input, OnInit } from '@angular/core';
import { BaseDialogComponent } from '../base-dialog/base-dialog.component';

@Component({
  selector: 'app-view-payments-from-enrollment',
  templateUrl: './view-payments-from-enrollment.component.html',
  styleUrls: ['./view-payments-from-enrollment.component.css']
})
export class ViewPaymentsFromEnrollmentComponent extends BaseDialogComponent implements OnInit {
  @Input() override entityId;
}
