import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DrupalRESTService } from 'src/app/services/drupal-rest.service';
import { PermissionsService } from 'src/app/services/permissions.service';

@Component({
  selector: 'app-ami-reporting',
  templateUrl: './ami-reporting.component.html',
  styleUrls: ['./ami-reporting.component.css']
})
export class AmiReportingComponent implements OnInit {
  amiReportingForm: FormGroup;
  actionType: 'create' | 'edit' = 'create';
  showProgressSpinner = false;
  successMessage: string;
  errorMessage: string;

  constructor(
    private fb: FormBuilder,
    private drupalRESTService: DrupalRESTService,
    private _permissionsService: PermissionsService
  ) {}

  ngOnInit(): void {
    this.amiReportingForm = this.fb.group({
      amiAddress: ['', Validators.required],
      clientId: ['', Validators.required],
      grantType: ['', Validators.required],
      clientSecret: ['', Validators.required],
      username: ['', Validators.required],
      password: ['', Validators.required]
    });

    this.loadAMIReportingConfig();
  }

  loadAMIReportingConfig() {
    const configFieldName = 'field_ami_reporting';
    this.drupalRESTService
      .httpGET(`/api_rest/v1/loadStudioConfig?config_field_name=${configFieldName}`)
      .subscribe(
        (response) => {
          // Transform the response to match the form structure
          const formData = {
            amiAddress: response?.['field_ami_address']?.[0]?.value,
            clientId: response?.['field_client_id']?.[0]?.value,
            grantType: response?.['field_grant_type']?.[0]?.value,
            clientSecret: response?.['field_client_secret']?.[0].value,
            username: response?.['field_user_name']?.[0]?.value, // Note the field name here
            password: response?.['field_password']?.[0]?.value,
          };

          this.amiReportingForm.patchValue(formData);
        },
        (error) => {
          console.error('Error loading AMI Reporting config:', error);
          this.errorMessage = 'Error loading AMI Reporting configuration.';
        }
      );
  }

  saveAMIReportingConfig() {
    const configFieldName = 'field_ami_reporting';
    const formValues = this.amiReportingForm.value;

    // Transform the form values to match the Drupal expected structure
    const postData = {
      config_field_name: configFieldName,
      config_data: {
        field_ami_address: [{value: formValues.amiAddress}],
        field_client_id: [{value: formValues.clientId}],
        field_grant_type: [{value: formValues.grantType}],
        field_client_secret: [{value: formValues.clientSecret}],
        field_user_name: [{value: formValues.username}], // Note the corrected field name
        field_password: [{value: formValues.password}],
      }
    };

    this.showProgressSpinner = true;
    this.successMessage = '';
    this.errorMessage = '';

    this.drupalRESTService
      .httpPOST('/api_rest/v1/saveStudioConfig', postData)
      .subscribe(
        (response) => {
          console.log('AMI Reporting config saved successfully:', response);
          this.successMessage = 'AMI Reporting configuration saved successfully.';
          this.showProgressSpinner = false;
        },
        (error) => {
          console.error('Error saving AMI Reporting config:', error);
          this.errorMessage = 'Error saving AMI Reporting configuration.';
          this.showProgressSpinner = false;
        }
      );
  }

  onSubmit() {
    this.saveAMIReportingConfig();
  }

  onUpdate() {
    this.saveAMIReportingConfig();
  }

  hasAdminAccess(): boolean {
    return this._permissionsService.has_access('view_admin_links');
  }
}
