import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatTabGroup } from '@angular/material/tabs';
// import { faSearch } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import { ViewTableComponent } from '../../base/view-table/view-table.component';
import { BaseDialogComponent } from '../base-dialog/base-dialog.component';
import { BaseComponent } from '../base-form/base-form.component';
import { PackagesService } from 'src/app/services/packages.service';

@Component({
  selector: 'app-student-account-entity',
  templateUrl: './student-account-entity.component.html',
  styleUrls: ['./student-account-entity.component.css']
})
export class StudentAccountEntityComponent extends BaseComponent {
  eckType = "student_accounts";
  bundle = "student_account";
  entityId = '';

  contactList = [];
  field_contacts = [];
  inquiry = false;

  override onSubmit(f: NgForm) {
    let values = f.form.value;

    // Alter certain date fields to match what Drupal is looking for.
    if (values.field_inquired) { values.field_inquired = moment(values.field_inquired).format('YYYY-MM-DD'); }
    if (values.field_showed) { values.field_showed = moment(values.field_showed).format('YYYY-MM-DD'); }
    if (values.field_booked_on) { values.field_booked_on = moment(values.field_booked_on).format('YYYY-MM-DD'); }

    // Prep the data to be send.
    let body = {
      ...values,
      field_contacts: {
        ...values.field_contacts,
      },
      field_archive: "0",
      title: "Default Title"
    };

    this.displayProgressSpinner(true);

    this._entityRESTService.createEntity('student_accounts', 'student_account', body)
      .subscribe(data => {
        console.log('data', data);
        // TODO: Update this to a student account view, not a student dashboard.
        this._router.navigate(['/students/' + data['field_contacts'][0].target_id]);

        this.displayProgressSpinner(false);
      },
        error => this.handleError(error)
      )

  }

  studentUpdateLoad = (index: number): void => {
    console.log('UpdateLoad called...')
    this.editIndex = index;
    this.editMode = true;
    this.f.controls['__field_contact_inline_form'].patchValue(this.field_contacts[index])
  }

  studentRemove = (index: number): void => {
    // Reset the field contacts group control.
    this.f.form.controls['__field_contact_inline_form'].reset();
    this.editMode = false;
    this.field_contacts.splice(index, 1);
  }

  studentUpdateSubmit(number, contact: any) {
    this.field_contacts[this.editIndex] = contact;
    this.f.form.controls['__field_contact_inline_form'].reset();
    this.editMode = false;
  }

  studentIncrement(contact: any) {
    // Append type & bundle to model.
    contact = {
      ...contact,
      'type': "contacts",
      'bundle': "student_record",
    }

    // If our data binding fails us (API needs to give an array), set as an empty array.
    if (!Array.isArray(this.field_contacts)) { this.field_contacts = [] }

    this.field_contacts.push(contact);

    // Reset the field contact group control.
    this.f.form.controls['__field_contact_inline_form'].reset();
  }

  ngOnInit(): void {
    this.initActionType = "create";
    this.setFormMode("create");
  }

}
