import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-display-instructors',
  templateUrl: './display-instructors.component.html',
  styleUrls: ['./display-instructors.component.css']
})
export class DisplayInstructorsComponent implements OnInit {

  @Input() instructor_list;
  @Input() instructorUpdateLoad: (index: number) => void;
  @Input() instructorRemove: (index: number) => void;

  constructor() { }

  ngOnInit(): void {
  }

}
