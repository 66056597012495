import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import moment from 'moment';
import { ViewTableComponent } from '../../base/view-table/view-table.component';

@Component({
  selector: 'app-student-list-archived',
  templateUrl: './student-list-archived.component.html',
  styleUrls: ['./student-list-archived.component.css']
})
export class StudentListArchivedComponent extends ViewTableComponent implements OnInit {

  currentPage: number;

  activeLink = 'Retired Students';
  links = [
    {linkTitle: 'Active Students', linkRoute: '/students-list' },
    {linkTitle: 'Retired Students', linkRoute: '/students-list-retired' }
  ]

  override displayedColumns: string[] = [
    'title',
    'field_student_department_target_id',
    // 'field_last_lesson_date_day_value',
    'field_last_lesson_taken_value',
    'field_upcoming_lesson_value',
    'field_total_lessons_enrolled',
    'field_total_lessons_remaining_value',
    'field_total_lessons_paid_ahead_value',
    'instructor',
    'field_email_value',
    'field_cell_phone_value',
    'dashboard',
    'edit'
  ];

  override getData(params: { parameter: string; value: string; }[]) {
    this.ShowProgressBar = true;
    this.queryParams = params;

    // Handle different checkbox based filters.
    let filters = '?';
    let nfa;
    let today = moment().format('YYYY-MM-DD');
    if (params) {
      for (const property in params) {
        // console.log(params[property])
        if (params[property]?.['parameter'] == 'Active') {
          // filters = filters + "status[]=active&";
          filters = filters + "&field_last_lesson_date=" + moment().subtract(30, 'days').format('YYYY-MM-DD') + "--";
        }
        if (params[property]?.['parameter'] == 'Inactive') {
          filters = filters + "&field_last_lesson_date=" + "--" + moment().subtract(30, 'days').format('YYYY-MM-DD');
        }
        if (params[property]?.['parameter'] == 'NFA') {
          filters = filters + "&field_nfa=true";
        }
        if (params[property]?.['parameter'] == 'paid_finished') {
          filters = filters + "&field_paid_finished=true";
        }
      }
    }

    // Load the new data with the filtering parameters.
    let endpoint: string = "/api/v1/student-list-retired" + filters;

    this._drupalRESTService.httpGET(endpoint, params)
      .subscribe((data) => {
        // ShowProgressBar
        this.ShowProgressBar = false;

        this.dataSource = new MatTableDataSource(data['results']);

        // Pagination
        if (data['results']) {
          this.setupPagination(data, params);
        }
      },
        error => this.handleError(error)
      )
  }

  setStudentArchive(student_account_id: number, value: boolean) {
    let body = {
      field_archive: '' + value,
    };
    let setStudentArchive = this._entityRESTService.patchEntity('student_accounts', 'student_account', student_account_id, body);
    setStudentArchive.subscribe(data => {
      console.log('data', data);
      this.getData(this.queryParams);
    })
  }

}
