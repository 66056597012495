import { Injectable } from '@angular/core';
import { DrupalRESTService } from './drupal-rest.service';

@Injectable({
  providedIn: 'root'
})
export class EntityRESTService {

  constructor(
    private drupalRESTService: DrupalRESTService
    ) { }

  /**
   * Get a single entity from the system.
   *
   * @param params
   * @returns Observable
   */
   getEntity(eckType: string, bundle: string, id: number | string, params?: { parameter: string, value: string }[]) {
    let endpoint:string = "/api_entity/eck/" + eckType + "/" + bundle + "/" + id;

    if (!params) {
      params = []
    }

    params = [
      ...params,
      { parameter: "_format", value: "json" }
    ];

     return this.drupalRESTService.httpGET(endpoint, params);
  }

  /**
   * Update a single entity.
   *
   * @param params
   * @returns Observable
   */
  patchEntity(eckType: string, bundle: string, id: number, body: any, params?: { parameter: string, value: string }[]) {
    // let endpoint: string = "/packages/" + id;
    let endpoint: string = "/api_entity/eck/" + eckType + "/" + bundle + "/" + id;
    return this.drupalRESTService.httpPATCH(endpoint, body);
  }

  /**
   * Delete a single entity.
   *
   * @param params
   * @returns Observable
   */
  deleteEntity(eckType: string, bundle: string, id: number) {
    let endpoint: string = "/api_entity/eck/" + eckType + "/" + bundle + "/" + id + '?_format=json';
    return this.drupalRESTService.httpDELETE(endpoint);
  }

  /**
   * Create a single entity.
   *
   * @param params
   * @returns Observable
   */
   createEntity(eckType: string, bundle: string, body: any, params?: { parameter: string, value: string }[]) {
    let endpoint:string = "/api_entity/eck/" + eckType + "/" + bundle + "?_format=json";

    return this.drupalRESTService.httpPOST(endpoint, body);
  }


}
