import { Injectable } from '@angular/core';

import { DrupalRESTService } from 'src/app/services/drupal-rest.service';
import { InquiryComponent } from 'src/app/components/forms/inquiry/inquiry.component';
import { MatDialog } from '@angular/material/dialog';

@Injectable({
  providedIn: 'root'
})
export class UtilityService {
  AMTConfiguration: Object;
  AMTDayView: Object;
  LoadStudentsList: Object;

  constructor(
    private _drupalRESTService: DrupalRESTService,
  ) { }

  /**
   * Get the Lessons list report.
   *
   * @param params
   * @returns Observable
   */
  getLessonListReport(params?: { parameter: string, value: string }[]) {
    let endpoint: string = "/api/v1/lessons_list";

    params = [
      ...params,
      { parameter: "_format", value: "json" }
    ]

    return this._drupalRESTService.httpGET(endpoint, params);
  }

  /**
   * Get the payment report list for an individual student.
   *
   * @param params
   * @returns Observable
   */
  getPaymentReportStudent(id: number, params?: { parameter: string, value: string }[]) {
    let endpoint: string = "/api/v1/payment_report_student";
    params = [
      ...params,
      { parameter: 'id', value: String(id) }
    ];

    return this._drupalRESTService.httpGET(endpoint, params);
  }

  /**
   * Get the chat success rate report.
   *
   * @param params
   * @returns Observable
   */
  getChatSuccessRateReport(params?: { parameter: string, value: string }[]) {
    let endpoint: string = "/api_rest/v1/chat_success_rate";
    params = [
      ...params,
      { parameter: "_format", value: "json" }
    ]

    return this._drupalRESTService.httpGET(endpoint, params);
  }

  /**
   * Load the royalty report.
   *
   * @param params
   * @returns Observable
   */
  getRoyaltyReport(params?: { parameter: string, value: string }[]) {
    let endpoint: string = "/api_rest/v1/loadRoyaltyReport";
    params = [
      ...params,
      { parameter: "_format", value: "json" }
    ]

    return this._drupalRESTService.httpGET(endpoint, params);
  }

  /**
   * Get the staff performance report.
   *
   * @param params
   * @returns Observable
   */
  getStaffPerformanceReport(params?: { parameter: string, value: string }[]) {
    let endpoint: string = "/api_rest/v1/loadStaffPerformanceReport";
    params = [
      ...params,
      // { parameter: "_format", value: "json" }
    ]

    return this._drupalRESTService.httpGET(endpoint, params);
  }

  /**
   * Get the studio business report.
   *
   * @param params
   * @returns Observable
   */
  getStudioBusinessReport(params?: { parameter: string, value: string }[]) {
    let endpoint: string = "/api_rest/v1/loadStudioBusinessReport";
    params = [
      ...params,
      { parameter: "_format", value: "json" }
    ]

    return this._drupalRESTService.httpGET(endpoint, params);
  }

  /**
   * Get the student inventory report.
   *
   * @param params
   * @returns Observable
   */
  getStudentInventoryReport(params?: { parameter: string, value: string }[]) {
    let endpoint: string = "/api/v1/students_inventory";
    params = [
      ...params,
      { parameter: "_format", value: "json" }
    ]

    return this._drupalRESTService.httpGET(endpoint, params);
  }

  /**
   * Get the payment list report.
   *
   * @param params
   * @returns Observable
   */
  getPaymentReport(params?: { parameter: string, value: string }[]) {
    let endpoint: string = "/api/v1/payment_report";

    params = [
      ...params,
      { parameter: "_format", value: "json" }
    ]

    return this._drupalRESTService.httpGET(endpoint, params);
  }


  /**
   * Get the inquiry report view.
   *
   * @param params
   * @returns Observable
   */
  getInquiryView(params?: { parameter: string, value: string }[]) {
    let endpoint: string = "/api/v1/view/inquiry_report";

    params = [
      ...params,
      { parameter: "_format", value: "json" }
    ]

    return this._drupalRESTService.httpGET(endpoint, params);
  }

  /**
   * Get the Enrollment view.
   *
   * @param params
   * @returns Observable
   */
  getEnrollmentyView(params?: { parameter: string, value: string }[]) {
    let endpoint: string = "/api/v1/enrollment_list";

    params = [
      ...params,
      { parameter: "_format", value: "json" }
    ]

    return this._drupalRESTService.httpGET(endpoint, params);
  }

  /**
   * Get the Packages List view.
   *
   * @param params
   * @returns Observable
   */
  getPackagesListView(params?: { parameter: string, value: string }[]) {
    let endpoint: string = "/api/v1/packages";

    params = [
      ...params,
      { parameter: "_format", value: "json" }
    ]

    return this._drupalRESTService.httpGET(endpoint, params);
  }

  /**
   * Get the canceled events report view.
   *
   * @param params
   * @returns Observable
   */
  getCanceledEventsView(params?: { parameter: string, value: string }[]) {
    let endpoint: string = "/api/v1/view/canceled_events_report";

    params = [
      ...params,
      { parameter: "_format", value: "json" }
    ]

    return this._drupalRESTService.httpGET(endpoint, params);
  }

  /**
   * Get the projections report data.
   *
   * @param params
   * @returns Observable
   */
  getProjectionsReport(params?: { parameter: string, value: string }[]) {
    let endpoint: string = "/api_rest/v1/projections_report";

    params = [
      ...params,
      { parameter: "_format", value: "json" }
    ]

    return this._drupalRESTService.httpGET(endpoint, params);
  }

  getLoadStudentDashboard(id: number) {
    let endpoint: string = "/api_rest/v1/loadStudentDashboard";
    let params = [
      { parameter: 'id', value: String(id) }
    ];

    return this._drupalRESTService.httpGET(endpoint, params);
  }

  // Charts
  loadCharts() {
    let endpoint: string = "/api_rest/v1/charts";
    return this._drupalRESTService.httpGET(endpoint);
  }

  getDashboardPage() {
    let endpoint: string = "/api_rest/v1/dashboard";
    return this._drupalRESTService.httpGET(endpoint);
  }

  /**
   * Use to load the list of students.
   *
   * @param params
   * @returns Observable
   */
  getLoadStudentsList(params?: { parameter: string, value: string }[]) {
    let endpoint: string = "/api/v1/loadStudentsList";
    return this._drupalRESTService.httpGET(endpoint, params);
  }

  getInstructorList() {
    let endpoint = "/api/v1/loadInstructors";

    return this._drupalRESTService.httpGET(endpoint);
  }

  getAutocompleteEnrollment($event: { target: { value: string; }; }) {
    let endpoint = "/api/v1/autocomplete/"
    let params = [
      { parameter: "eckType", value: 'packages' },
      { parameter: "bundle", value: "enrollment" },
      { parameter: "field", value: 'title' },
      { parameter: "term", value: $event.target.value },
    ]

    return this._drupalRESTService.httpGET(endpoint, params);
  }

  /**
   * Request a first name list, used for autocomplete forms.
   *
   * @param $event The
   * @returns Observable
   */
  getAutocompleteFirstName($event: { target: { value: string; }; }) {
    let endpoint = "/views-autocomplete-filters/inquiry/page_1/field_first_name_value/0";
    let params = [
      { parameter: 'q', value: $event.target.value }
    ];


    // http://amt.local/views-autocomplete-filters/inquiry/page_1/field_first_name_value/0?q=a
    return this._drupalRESTService.httpGET(endpoint, params);
  }

  /**
   * Request a last name list, used for autocomplete forms.
   *
   * @param $event The
   * @returns Observable
   */
  getAutocompleteLastName($event: { target: { value: string; }; }) {
    let endpoint = "/views-autocomplete-filters/inquiry/page_1/field_last_name_value/0";
    let params = [
      { parameter: 'q', value: $event.target.value }
    ];

    return this._drupalRESTService.httpGET(endpoint, params);
  }

  /**
   * Get autocomplete
   */
  getViewsAutocompleteFilters() {
    let endpoint: string = "/views-autocomplete-filters/enrollment/page_1/field_package_name_value/0?q=coac";

    // let params = [
    //   { parameter: "term", value: term },
    //   { parameter: "eckType", value: eckType },
    //   { parameter: "bundle", value: bundle },
    //   { parameter: "field", value: field }
    // ];

    return this._drupalRESTService.httpGET(endpoint);
  }

  /**
   * Utlize this endpoint for autocomplete forms.
   *
   * @param $event The
   * @returns Observable
   */
  getAMTAbstractAutocomplete(term: string, eckType: string, bundle: string, field: string) {
    // Build the endpoint.
    let endpoint: string = "/api/dayview/autocomplete";

    let params = [
      { parameter: "term", value: term },
      { parameter: "eckType", value: eckType },
      { parameter: "bundle", value: bundle },
      { parameter: "field", value: field }
    ];

    // Do the request.
    return this._drupalRESTService.httpGET(endpoint, params);
  }

  getViewsAutocompleteFilter(view_name, view_display, filter_name, term) {
    let endpoint: string = "/api_rest/v1/views-autocomplete-filters/";
    let params = [
      { parameter: "view_name", value: view_name },
      { parameter: "view_display", value: view_display },
      { parameter: "filter_name", value: filter_name },
      { parameter: "q", value: term },
      { parameter: "_format", value: "json" }
    ];
    return this._drupalRESTService.httpGET(endpoint, params);
  }


  /**
   * Request a student account list, used for autocomplete forms.
   *
   * @param $event The
   * @returns Observable
   */
  getAMTAutocomplete($event: { target: { value: string; }; }) {
    let term: string = $event.target.value;
    let eckType: string = "student_accounts"; // student_accounts
    let bundle: string = "student_account"; // student_account
    let field: string = "title";

    // Build the endpoint.
    let endpoint: string = "/api/dayview/autocomplete";

    let params = [
      { parameter: "term", value: term },
      { parameter: "eckType", value: eckType },
      { parameter: "bundle", value: bundle },
      { parameter: "field", value: field }
    ];

    // Do the request.
    return this._drupalRESTService.httpGET(endpoint, params);
  }


  getAMTDayView() {
    let endpoint = "/api/dayview/resource?instructor_category=78&instructor=&lessonType=&student=&calendar_date=&start=2021-12-01T00%3A00%3A00&end=2021-12-02T00%3A00%3A00&_=1638394993251";

    this._drupalRESTService.httpGET(endpoint)
      .subscribe(data => {
        this.AMTDayView = data;
      });
  }

  getUniquePaymentID() {
    let endpoint = "/api/v1/getUniquePaymentID";
    return this._drupalRESTService.httpGET(endpoint);
  }

}
