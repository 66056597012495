<!-- lessons-list-report.component.html -->
<div class="container-fluid">
    <h1 class="page-header">Appointment List</h1>

    <mat-expansion-panel>
        <mat-expansion-panel-header>
            <mat-panel-title>
                Filter
            </mat-panel-title>
            <mat-panel-description>
                <app-filter-results-panel [parameters]=queryParams></app-filter-results-panel>
            </mat-panel-description>
        </mat-expansion-panel-header>
        <form (ngSubmit)="onSubmit(f)" #f=ngForm class="form">
            <div class="row">

                <div class="col-2">
                    <mat-form-field class="full-width-field">
                        <mat-label>Instructor</mat-label>
                        <mat-select
                            matInput
                            ngModel
                            name="instructor"
                            (selectionChange)="onSubmit(f)">
                            <mat-option>None</mat-option>
                            <mat-option
                                *ngFor="let element of this._fieldsService.instructorData()"
                                [value]="element.uid">
                                {{ element.value }}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>

                <!-- Student Contact Autocomplete -->
                <div class="col-2">
                    <mat-form-field class="full-width-field">
                        <mat-label>Student (Contact)</mat-label>
                        <input
                            #studentInput
                            name="field_contacts_target_id"
                            (input)="onAutocompleteStudentContact($event)"
                            matInput
                            ngModel
                            [matAutocomplete]="autoCompleteStudentContact">
                        <button (click)="redirectUserProfileID(studentInput)"
                            type="button" matSuffix mat-icon-button
                            aria-label="manage_accounts">
                            <mat-icon>manage_accounts</mat-icon>
                        </button>
                    </mat-form-field>
                    <mat-autocomplete (optionSelected)="onSubmit(f)"
                        #autoCompleteStudentContact="matAutocomplete">
                        <mat-option
                            *ngFor="let option of autoCompleteStudentContactOptions"
                            [value]="option.value">
                            {{option.label}} ({{option.value}})
                        </mat-option>
                    </mat-autocomplete>
                </div>

                <!-- <div class="col-2">
                <mat-form-field class="full-width-field">
                    <mat-label>Student Name</mat-label>
                    <input
                        name="title"
                        (input)="onAutocompleteStudent($event)"
                        matInput
                        ngModel
                        [matAutocomplete]="auto"
                        (blur)="onSubmit(f)">
                    <button type="button" matSuffix mat-icon-button
                        aria-label="manage_accounts">
                        <mat-icon>manage_accounts</mat-icon>
                    </button>
                </mat-form-field>
                <mat-autocomplete
                    #auto="matAutocomplete"
                    (optionSelected)="onSubmit(f)">
                    <mat-option *ngFor="let option of autoCompleteOptions"
                        [value]="option.label">
                        {{option.label}} ({{option.value}})
                    </mat-option>
                </mat-autocomplete>
            </div> -->

                <!-- Date Range Filter -->
                <div class="col-2">
                    <mat-form-field>
                        <mat-label>Enter a date range</mat-label>
                        <mat-date-range-input [rangePicker]="picker">
                            <input name="field_date_and_time_value"
                                (dateChange)="onSubmit(f)" ngModel matStartDate
                                placeholder="Start date">
                            <input name="field_date_and_time_value_1"
                                (dateChange)="onSubmit(f)" ngModel matEndDate
                                placeholder="End date">
                        </mat-date-range-input>
                        <!-- <mat-hint>MM/DD/YYYY – MM/DD/YYYY</mat-hint> -->
                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                        <mat-date-range-picker #picker></mat-date-range-picker>
                    </mat-form-field>
                </div>

                <!-- <div class="col-2">
                <mat-form-field class="full-width-field">
                    <mat-label>Start Date</mat-label>
                    <input
                        matInput
                        [matDatepicker]="pickerStart"
                        name="field_date_and_time_value"
                        ngModel
                        (dateChange)="onSubmit(f)">
                    <mat-datepicker-toggle matSuffix [for]="pickerStart"></mat-datepicker-toggle>
                    <mat-datepicker #pickerStart></mat-datepicker>
                </mat-form-field>
            </div> -->

                <!-- <div class="col-2">
                <mat-form-field class="full-width-field">
                    <mat-label>End Date</mat-label>
                    <input
                        matInput
                        [matDatepicker]="pickerEnd"
                        name="field_date_and_time_value_1"
                        ngModel
                        (dateChange)="onSubmit(f)">
                    <mat-datepicker-toggle matSuffix [for]="pickerEnd"></mat-datepicker-toggle>
                    <mat-datepicker #pickerEnd></mat-datepicker>
                </mat-form-field>
            </div> -->

                <!-- <div class="col-2">
                <mat-form-field class="full-width-field">
                    <mat-label>Category</mat-label>
                    <input
                        matInput
                        ngModel
                        name="field_category_target_id"
                        (blur)="onSubmit(f)"
                        (selectionChange)="onSubmit(f)">
                </mat-form-field>
            </div> -->

                <div class="col-2">
                    <mat-form-field class="full-width-field">
                        <mat-label>Event Type</mat-label>
                        <mat-select
                            name="type_event_entity_type"
                            ngModel
                            (selectionChange)="onSubmit(f)">
                            <mat-option value="All">All</mat-option>
                            <mat-option
                                *ngFor="let department of this._fieldsService.lessonTypeData"
                                [value]="department.key">{{ department.value }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>

                <div class="col-2">
                    <mat-form-field class="full-width-field">
                        <mat-label>Select Department...</mat-label>
                        <mat-select
                            name="field_student_department_target_id"
                            ngModel
                            (selectionChange)="onSubmit(f)">
                            <mat-option value="All">All</mat-option>
                            <mat-option
                                *ngFor="let department of departmentData"
                                [value]="department.key">{{ department.value }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>

                <div class="col-2">
                    <mat-form-field class="full-width-field">
                        <mat-label>Status</mat-label>
                        <mat-select
                            name="field_status_target_id[]"
                            ngModel
                            (selectionChange)="onSubmit(f)">
                            <mat-option value>None</mat-option>
                            <mat-option
                                *ngFor="let element of this._taxonomyService.lesson_status"
                                [value]="element.id">{{ element.name }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>

                <div class="col-2">
                    <mat-form-field class="full-width-field">
                        <mat-label>
                            Standings
                        </mat-label>
                        <mat-select
                            name="field_standings"
                            ngModel
                            (selectionChange)="onSubmit(f)">
                            <mat-option value>None</mat-option>
                            <mat-option
                                *ngFor="let element of standingTypeData"
                                [value]="element.key">{{ element.value }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>

                <mat-checkbox
                    [checked]="enrollmentFilter === true"
                    [indeterminate]="enrollmentFilter === null"
                    (change)="onEnrollmentFilterChange()">
                    Filter lessons without Enrollment
                </mat-checkbox>

                <section class="section">

                    <div class="float-end bring-inline">
                        <button (click)="resetForm()" color="primary"
                            mat-flat-button
                            class="me-1" mat-button>Clear
                            <!-- <mat-icon (click)="resetForm()" aria-hidden="false" aria-label="Menu dropdown" class=""></mat-icon> -->
                        </button>
                    </div>

                </section>

            </div>

            <section class="section">
            </section>

        </form>

    </mat-expansion-panel>

    <mat-progress-bar *ngIf="ShowProgressBar" mode="indeterminate"></mat-progress-bar>
    <div class="row">
        <div class="col-md-12 mt-3">
            <div class="float-end">
                <button (click)="onPrint()" color="primary" mat-flat-button
                    class="me-1" mat-button>Print</button>
                <button
                    (click)="exportLessonListReport()"
                    [disabled]="isLoading"
                    color="primary"
                    mat-flat-button
                    class="me-1 warning mat-icon-animate-spin"
                    mat-button>
                    <mat-icon *ngIf="isLoading">autorenew</mat-icon>
                    {{ isLoading ? 'Exporting...' : 'Export' }}
                </button>
            </div>
        </div>
    </div>

    <!-- <mat-form-field appearance="standard">
  <mat-label>Filter</mat-label>
  <input matInput (keyup)="applyFilter($event)" placeholder="" #input>
</mat-form-field> -->

    <!-- <div class="example-container mat-elevation-z8">
<div class="loading-shade"
  *ngIf="ShowProgressBar">
  <mat-spinner *ngIf="ShowProgressBar"></mat-spinner>
  <div class="example-rate-limit-reached" *ngIf="isRateLimitReached">
    No limit
  </div>
</div>
</div> -->

    <div class="table-container">

        <div class="table-responsive">
            <table mat-table #displayTable [dataSource]="dataSource"
                matSort
                (matSortChange)="sortDataView($event)"
                class="mt-4 table-responsive">

                <!-- id Column -->
                <ng-container matColumnDef="id">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header
                        sortActionDescription="Sort by id">
                        ID
                    </th>
                    <td mat-cell *matCellDef="let element">{{element.id}}</td>
                </ng-container>

                <!-- title Column -->
                <!-- <ng-container matColumnDef="title">
                <th mat-header-cell *matHeaderCellDef
                    sortActionDescription="Sort by Title">
                    Title
                </th>
                <td mat-cell *matCellDef="let element"> {{element.title}} </td>
            </ng-container> -->

                <!-- nothing_1 Column -->
                <ng-container matColumnDef="nothing_1">
                    <th mat-header-cell *matHeaderCellDef
                        sortActionDescription="Sort by nothing_1">
                        Enrollment ID
                    </th>
                    <td mat-cell *matCellDef="let element"> {{element.nothing_1}}
                    </td>
                </ng-container>

                <!-- field_instructor Column -->
                <!-- <ng-container matColumnDef="field_instructor">
                <th mat-header-cell *matHeaderCellDef
                    sortActionDescription="Sort by field_instructor">
                    Instructor ID
                </th>
                <td mat-cell *matCellDef="let element"> {{element.field_instructor}} </td>
            </ng-container> -->

                <!-- view_user Column -->
                <ng-container matColumnDef="view_user">
                    <th mat-header-cell *matHeaderCellDef
                        sortActionDescription="Sort by view_user">
                        Instructor
                    </th>
                    <td mat-cell *matCellDef="let element"> {{
                        element.field_first_name }} {{ element.field_last_name }}
                    </td>
                </ng-container>

                <!-- views_conditional_field Column -->
                <ng-container matColumnDef="views_conditional_field">
                    <th mat-header-cell *matHeaderCellDef
                        sortActionDescription="Sort by views_conditional_field">
                        Event Type
                    </th>
                    <td mat-cell *matCellDef="let element">
                        {{element.views_conditional_field}} </td>
                </ng-container>

                <!-- title_1 Column -->
                <ng-container matColumnDef="title_1">
                    <th mat-header-cell *matHeaderCellDef
                        sortActionDescription="Sort by title_1">
                        Student Name
                    </th>
                    <td mat-cell *matCellDef="let element"> {{element.title_1}}
                    </td>
                </ng-container>

                <!-- field_email Column -->
                <ng-container matColumnDef="field_email">
                    <th mat-header-cell *matHeaderCellDef
                        sortActionDescription="Sort by field_email">
                        Student Email
                    </th>
                    <td mat-cell *matCellDef="let element"> {{element.field_email}}
                    </td>
                </ng-container>

                <!-- field_date_and_time Column -->
                <ng-container matColumnDef="field_date_and_time_value">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header
                        sortActionDescription="Sort by field_date_and_time_value">
                        Start Date
                    </th>
                    <td mat-cell *matCellDef="let element">
                        {{element.field_date_and_time | date}} </td>
                </ng-container>

                <!-- views_conditional_field_1 Column -->
                <ng-container matColumnDef="views_conditional_field_1">
                    <th mat-header-cell *matHeaderCellDef
                        sortActionDescription="Sort by views_conditional_field_1">
                        End Date
                    </th>
                    <td mat-cell *matCellDef="let element">
                        {{element.views_conditional_field_1 | date}} </td>
                </ng-container>

                <!-- field_date_and_time_1 Column -->
                <ng-container matColumnDef="field_date_and_time_1">
                    <th mat-header-cell *matHeaderCellDef
                        sortActionDescription="Sort by field_date_and_time_1">
                        Start Time
                    </th>
                    <td mat-cell *matCellDef="let element"> {{
                        element.field_date_and_time_1 }} </td>
                </ng-container>

                <!-- field_expiration_date_1 Column -->
                <ng-container matColumnDef="field_expiration_date_1">
                    <th mat-header-cell *matHeaderCellDef
                        sortActionDescription="Sort by field_expiration_date_1">
                        End Time
                    </th>
                    <td mat-cell *matCellDef="let element">
                        {{element.field_expiration_date_1 }} </td>
                </ng-container>

                <!-- field_duration Column -->
                <ng-container matColumnDef="field_duration">
                    <th mat-header-cell *matHeaderCellDef
                        sortActionDescription="Sort by field_duration">
                        Duration
                    </th>
                    <td mat-cell *matCellDef="let element"> {{
                        element.field_duration }} </td>
                </ng-container>

                <!-- created Column -->
                <ng-container matColumnDef="created">
                    <th mat-header-cell *matHeaderCellDef
                        sortActionDescription="Sort by created">
                        Created
                    </th>
                    <td mat-cell *matCellDef="let element"> {{element.created}}
                    </td>
                </ng-container>

                <!-- field_type Column -->
                <ng-container matColumnDef="field_type">
                    <th mat-header-cell *matHeaderCellDef
                        sortActionDescription="Sort by field_type">
                        Lesson Type
                    </th>
                    <td mat-cell *matCellDef="let element"> {{element.field_type}}
                    </td>
                </ng-container>

                <!-- field_repetition_frequency Column -->
                <ng-container matColumnDef="field_repetition_frequency">
                    <th mat-header-cell *matHeaderCellDef
                        sortActionDescription="Sort by field_repetition_frequency">
                        Repetition
                    </th>
                    <td mat-cell *matCellDef="let element">
                        {{element.field_repetition_frequency}} </td>
                </ng-container>

                <!-- field_type_1 Column -->
                <!-- <ng-container matColumnDef="field_type_1">
                <th mat-header-cell *matHeaderCellDef
                    sortActionDescription="Sort by field_type_1">
                    Service ID
                </th>
                <td mat-cell *matCellDef="let element"> {{element.event_type}} </td>
            </ng-container> -->

                <!-- field_type_1 Column -->
                <ng-container matColumnDef="field_status">
                    <th mat-header-cell *matHeaderCellDef
                        sortActionDescription="Sort by field_status">
                        Status
                    </th>
                    <td mat-cell *matCellDef="let element">
                        <ng-container *ngIf="!this._permissionsService.has_access('view_admin_links')">
                            {{element.field_status ? element.field_status : 'Pending Status'}}
                        </ng-container>

                        <ng-container *ngIf="this._permissionsService.has_access('view_admin_links') && element.event_type == 'Lesson'">
                            <app-status-badge-update
                            [currentStatus]="element.field_status_id"
                            [lessonId]="element.id"
                            [attendeeId]="element.attendee_id"
                            [statuses]="_taxonomyService.lesson_status"
                            (statusUpdated)="onStatusUpdated($event)">
                            </app-status-badge-update>
                        </ng-container>
                      </td>
                </ng-container>

                <!-- field_type_1 Column -->
                <ng-container matColumnDef="event_actions">
                    <th mat-header-cell *matHeaderCellDef
                        sortActionDescription="Sort by event_actions">

                    </th>
                    <td mat-cell *matCellDef="let element">
                        <button *ngIf="element.event_type == 'Lesson'"
                            mat-flat-button color="accent"
                            (click)="openEntityComponent(EventLessonEntityComponent, 'events', 'lesson', 'edit', element.id)">
                            Edit Lesson
                        </button>
                        <button *ngIf="element.event_type == 'Group Lesson'"
                            mat-flat-button color="accent"
                            (click)="openEntityComponent(EventGroupLessonEntityComponent, 'events', 'group_lesson', 'edit', element.id)">
                            Edit Group Lesson
                        </button>
                        <button *ngIf="element.event_type == 'Services'"
                            mat-flat-button color="accent"
                            (click)="openEntityComponent(EventServicesEntityComponent, 'events', 'services', 'edit', element.id)">
                            Edit Service
                        </button>
                    </td>
                </ng-container>

                <!-- field_category Column -->
                <!-- <ng-container matColumnDef="field_category">
                <th mat-header-cell *matHeaderCellDef
                    sortActionDescription="Sort by field_category">
                    Type
                </th>
                <td mat-cell *matCellDef="let element"> {{element.field_category}} </td>
            </ng-container> -->

                <!-- edit_events Column -->
                <!-- <ng-container matColumnDef="edit_events">
                <th mat-header-cell *matHeaderCellDef
                    sortActionDescription="Sort by edit_events">
                    edit_events
                </th>
                <td mat-cell *matCellDef="let element"> {{element.edit_events}} </td>
            </ng-container> -->

                <!-- delete_events Column -->
                <!-- <ng-container matColumnDef="delete_events">
                <th mat-header-cell *matHeaderCellDef
                    sortActionDescription="Sort by delete_events">
                    delete_events
                </th>
                <td mat-cell *matCellDef="let element"> {{element.delete_events}} </td>
            </ng-container> -->

                <!-- views_conditional_field_2 Column -->
                <!-- <ng-container matColumnDef="views_conditional_field_2">
                <th mat-header-cell *matHeaderCellDef
                    sortActionDescription="Sort by views_conditional_field_2">
                    Conflict Report
                </th>
                <td mat-cell *matCellDef="let element"> {{element.views_conditional_field_2}} </td>
            </ng-container> -->

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row matRipple
                    *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>

            <ng-container *ngIf="this.queryParams.length == 0">
                <div>
                    Please select criteria to view a report.
                </div>
            </ng-container>

            <mat-paginator
                [disabled]="ShowProgressBar"
                (page)="pageChanged($event)"
                [pageSizeOptions]="onPageSizeOptions()"
                [length]="30"
                [pageSize]="paginationTotalPages"
                [pageIndex]="paginationIndex"
                aria-label="Select page of view"></mat-paginator>

        </div>
    </div>
</div>
