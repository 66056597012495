import { trigger, transition, style, animate } from '@angular/animations';
import { Component, OnInit } from '@angular/core';
import { MatChipInputEvent } from '@angular/material/chips';
import { DrupalRESTService } from 'src/app/services/drupal-rest.service';
@Component({
  selector: 'app-session-start-times',
  templateUrl: './session-start-times.component.html',
  styleUrls: ['./session-start-times.component.css'],
  animations: [
    trigger('chipAnimation', [
      transition(':enter', [
        style({ opacity: 0, transform: 'translateY(-20px)' }),
        animate('300ms ease-out', style({ opacity: 1, transform: 'translateY(0)' }))
      ]),
      transition(':leave', [
        animate('300ms ease-in', style({ opacity: 0, transform: 'translateY(-20px)' }))
      ])
    ])
  ]
})
export class SessionStartTimesComponent implements OnInit {

  constructor(private _drupalRESTService: DrupalRESTService) { }

  ngOnInit(): void {
    this.generateAvailableTimes();
    this.loadSessionStartTimes();
  }

  weekDays = [
    { name: 'Monday', times: [], newTime: '' },
    { name: 'Tuesday', times: [], newTime: '' },
    { name: 'Wednesday', times: [], newTime: '' },
    { name: 'Thursday', times: [], newTime: '' },
    { name: 'Friday', times: [], newTime: '' },
    { name: 'Saturday', times: [], newTime: '' },
    { name: 'Sunday', times: [], newTime: '' },
  ];

  availableTimes: string[] = [];
  newSessionTime: string;

  generateAvailableTimes() {
    for (let hour = 8; hour <= 23; hour++) {
      for (let minutes = 0; minutes < 60; minutes += 15) {
        let timeString = `${hour < 10 ? '0' + hour : hour}:${minutes === 0 ? '00' : minutes}`;
        this.availableTimes.push(timeString);
      }
    }
  }

  getFilteredAvailableTimes(day) {
    return this.availableTimes.filter(time => !day.times.includes(time));
  }

  loadSessionStartTimes() {
    this._drupalRESTService.httpGET('/api_rest/v1/loadStudioConfig?config_field_name=field_session_start_times_ref').subscribe(response => {
      console.log(response)
      this.weekDays.forEach(day => {
        const fieldName = 'field_' + day.name.toLowerCase();
        if (response[fieldName] && response[fieldName].length > 0) {
          day.times = response[fieldName][0].value.split(', ');
        }
      });
    });
  }

  clearTimes(day): void {
    day.times = [];
  }

  saveChanges() {
    let saveData = this.prepareSaveData();

    let configFieldName = 'field_session_start_times_ref';

    let postData = {
      config_field_name: configFieldName,
      config_data: saveData
    };

    this._drupalRESTService.httpPOST('/api_rest/v1/saveStudioConfig', postData).subscribe(
      response => {
        console.log('Save response:', response);
      },
      error => {
        console.error('Save error:', error);
      }
    );
  }

  prepareSaveData() {
    let data = {};
    this.weekDays.forEach(day => {
      data['field_' + day.name.toLowerCase()] = day.times.join(', ');
    });
    return data;
  }

  addTime(day, newTime): void {
    const time24Format = this.convertTo24HourFormat(newTime);
    if (!day.times.includes(time24Format)) {
      day.times.push(time24Format);
      day.times.sort();

      this.saveChanges();
    }
  }

  removeTime(day, time: string): void {
    const time24Format = this.convertTo24HourFormat(time);
    const index = day.times.indexOf(time24Format);
    if (index >= 0) {
      day.times.splice(index, 1);

      this.saveChanges();
    }
  }

  convertTo12HourFormat(time: string): string {
    let [hours, minutes] = time.split(':').map(Number);
    let period = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12 || 12;
    return `${hours}:${minutes < 10 ? '0' + minutes : minutes} ${period}`;
  }

  convertTo24HourFormat(time: string): string {
    let [hourPart, minutePart] = time.split(':');
    let minutes = minutePart.substr(0, 2);
    let period = minutePart.substr(3, 2);
    let hours = parseInt(hourPart, 10);
    if (period === 'PM' && hours < 12) hours += 12;
    if (period === 'AM' && hours === 12) hours = 0;
    return `${hours < 10 ? '0' + hours : hours}:${minutes}`;
  }

  copyToNextDays(dayIndex: number) {
    const timesToCopy = this.weekDays[dayIndex].times;
    for (let i = dayIndex + 1; i < this.weekDays.length; i++) {
      this.weekDays[i].times = [...timesToCopy];
    }
    this.saveChanges();
  }

  copyToAllWeekdays(dayIndex: number) {
    const timesToCopy = this.weekDays[dayIndex].times;
    for (let i = 0; i < this.weekDays.length; i++) {
      if (i !== dayIndex && i < 5) { // Copy to weekdays only (Monday to Friday)
        this.weekDays[i].times = [...timesToCopy];
      }
    }
    this.saveChanges();
  }

  copyLastDay(dayIndex: number) {
    if (dayIndex > 0) {
      this.weekDays[dayIndex].times = [...this.weekDays[dayIndex - 1].times];
      this.saveChanges();
    }
  }
}
