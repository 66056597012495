import { Component, Input, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { ViewTableComponent } from 'src/app/components/base/view-table/view-table.component';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import moment from 'moment';
import { MY_DATE_FORMATS } from 'src/app/components/reports/payments-report-view/payments-report-view.component';

@Component({
  selector: 'app-payment-reports',
  templateUrl: './payment-reports.component.html',
  styleUrls: ['./payment-reports.component.css'],
  providers: [
    { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: MY_DATE_FORMATS }
  ]
})

export class PaymentReportsComponent extends ViewTableComponent implements OnInit {

  @Input() drupal_student_id: number;
  @Input() drupal_student_name: string;
  @Input() drupal_account_id: string;

  hasTaxData: boolean = false;

  override displayedColumns: string[] = [
    'field_student_name',
    'field_payment_id',
    'field_date_and_time',
    'field_gross_tuition',
    'field_enrollment_name',
    'field_payment_type',
    'field_teacher',
    'edit',
  ];

  override getData(params: { parameter: string; value: string; }[]) {
    this.ShowProgressBar = true;
    this.queryParams = params;

    if (this.sortParams !== undefined) {
      this.queryParams.push(this.sortParams)
    }

    this._utilityService.getPaymentReportStudent(
      this.drupal_student_id,
      this.queryParams
    )
      .subscribe((data) => {
        this.ShowProgressBar = false;
        this.data = data;
        this.dataSource = new MatTableDataSource(data['results']);

        // Check if any of the data has a non-zero or non-empty field_tax_amount
        this.hasTaxData = data && data['results'] && data['results'].some(
          (item: any) =>
            item.field_tax_collected !== '' &&
            item.field_tax_collected !== '0' &&
            item.field_tax_collected !== '0.00'
        );

        // If tax data exists, insert the "Tax Amount" column after "Gross Tuition"
        const taxColumnIndex = this.displayedColumns.indexOf('field_gross_tuition') + 1;
        if (this.hasTaxData && !this.displayedColumns.includes('field_tax_collected')) {
          this.displayedColumns.splice(taxColumnIndex, 0, 'field_tax_collected');
        }


        // Pagination
        if (data['results']) {
          this.setupPagination(data, params);
        }
      },
        error => this.handleError(error)
      )
  }
}
