import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-duration-dropdown',
  templateUrl: './duration-dropdown.component.html',
  styleUrls: [
    './duration-dropdown.component.css',
    '../../../../../app.component.css'
  ]
})
export class DurationDropdownComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  durations: {value: string}[] = [
    {value: "00:15"},
    {value: "00:30"},
    {value: "00:45"},
    {value: "01:00"},
    {value: "01:15"},
    {value: "01:30"},
    {value: "01:45"},
    {value: "02:00"},
    {value: "02:15"},
    {value: "02:30"},
    {value: "02:45"},
    {value: "03:00"},
    {value: "03:15"},
    {value: "03:30"},
    {value: "03:45"},
    {value: "04:00"},
    {value: "04:15"},
    {value: "04:30"},
    {value: "04:45"},
    {value: "05:00"},
    {value: "05:15"},
    {value: "05:30"},
    {value: "05:45"},
    {value: "06:00"},
    {value: "06:15"},
    {value: "06:30"},
    {value: "06:45"},
    {value: "07:00"},
    {value: "07:15"},
    {value: "07:30"},
    {value: "07:45"},
    {value: "08:00"},
    {value: "08:15"},
    {value: "08:30"},
    {value: "08:45"},
    {value: "09:00"},
  ]

}
