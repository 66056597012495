<h1 class="page-header">Login</h1>
<div>
  <mat-progress-spinner class="matProgressSpinnerCentered" *ngIf="showProgressSpinner" mode="indeterminate">
  </mat-progress-spinner>

  <div>
    <form (ngSubmit)="onSubmit(f)" #f="ngForm" class="form">
      <div class="container">
        <div class="row justify-content-md-center">
          <mat-card class="col-md-6 col-12">
            <app-success-message [message]="this.successMessage"></app-success-message>
            <app-error-message [message]="this.errorMessage"></app-error-message>

            <div class="col-12">
              <mat-form-field class="full-width-field">
                <mat-label>Username</mat-label>
                <input
                  type="text"
                  name="name"
                  required
                  matInput
                  ngModel>
              </mat-form-field>
            </div>
            <div class="col-12">
              <mat-form-field class="full-width-field">
                <mat-label>Password</mat-label>
                <input
                  type="password"
                  name="pass"
                  required
                  matInput
                  ngModel>
              </mat-form-field>
            </div>

            <div class="col-12">
              <button
                [disabled]="!f.valid"
                type="button"
                (click)="onSubmit(f);"
                mat-raised-button
                color="primary">Login</button>

              <a class="float-end" mat-button routerLink="/auth/forgot-password">Forgot Password?</a>
            </div>
          </mat-card>
        </div>
      </div>
    </form>
  </div>
</div>
