import { Injectable } from '@angular/core';
import LogRocket from 'logrocket';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LogRocketService {

  private logRocketInitialized: boolean = false;

  constructor() {
    // LogRocket is initialized only when needed (in the identifyUser method)
  }

  // Identify a user in LogRocket, but skip if it's an admin (uid = 1)
  identifyUser(userId: string, userData: { uid: number; name?: string; email?: string; [key: string]: any }) {
    // If the user is an admin (uid = 1), skip LogRocket initialization
    if (userData.uid === 1) {
      console.log('Admin user detected. LogRocket will not be initialized.');
      return;
    }

    // Initialize LogRocket if it has not been initialized already
    if (!this.logRocketInitialized) {
      LogRocket.init(environment.logRocketKey);
      this.logRocketInitialized = true;
    }

    // Identify the user in LogRocket
    LogRocket.identify(userId, {
      name: userData.name,
      email: userData.email,
      ...userData // Pass any additional custom data fields
    });
  }

}
