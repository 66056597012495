<app-entity-mat-dialog-title [title]="this.title"></app-entity-mat-dialog-title>

<form>

<h2></h2>

<app-success-message [message]=this.successMessage></app-success-message>
<app-error-message [message]=this.errorMessage></app-error-message>

<pre>
    {{ this.apiMessage }}
</pre>

<mat-accordion *ngIf="this?.['api_data']">
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          API Data
        </mat-panel-title>
        <mat-panel-description>

        </mat-panel-description>
      </mat-expansion-panel-header>
        <pre style="border: 1px solid; height: 10pc; overflow-y: scroll;">
        <h5>Data Sent:</h5>
        {{ this.api_data | json }}
        </pre>
    </mat-expansion-panel>
</mat-accordion>

<div style="min-height:150px" *ngIf="showProgressSpinner">
    <mat-progress-spinner class="matProgressSpinnerCentered"
        *ngIf="showProgressSpinner" mode="indeterminate">
    </mat-progress-spinner>
</div>

<form (ngSubmit)="onSubmit(f)" #f="ngForm" class="form"></form>
