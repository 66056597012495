<!-- Test for single error -->
<ng-container *ngIf="(!is_array(message))">
    <mat-list class="alert alert-danger mt-3" *ngIf="message && message != ''">
        <!-- <mat-list class="alert alert-danger mt-3"> -->
        <div class="mt-3 mb-1"><strong>Something went wrong.</strong></div>
        <span [innerHTML]="message"></span>
        <!-- <p [innerHTML]="message | safeHtml"></p>
        {{ message }} -->
    </mat-list>
</ng-container>

<!-- Test for multiple errors -->
<ng-container *ngIf="this.message && is_array(this.message) && objLength(this.message) > 0">
    <mat-list class="alert alert-danger mt-3" *ngIf="message && message != ''">
    <!-- <mat-list class="alert alert-danger mt-3"> -->
        <div class="mt-3 mb-1"><strong>Something went wrong.</strong></div>
        <li class="mt-1" *ngFor="let m of message">
            <span [innerHTML]="m | safeHtml"></span>
        </li>
    </mat-list>
</ng-container>
