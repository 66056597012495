import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class SquareEventService {
  private deauthorizeSquareSource = new Subject<void>();

  // Observable that components can subscribe to
  deauthorizeSquare$ = this.deauthorizeSquareSource.asObservable();

  // Method to call when deauthorizeSquare is triggered
  triggerDeauthorizeSquare() {
    this.deauthorizeSquareSource.next();
  }
}
