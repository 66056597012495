import { Component, OnInit } from '@angular/core';
import { BaseDialogComponent } from '../../forms/base-dialog/base-dialog.component';
import { BaseEnrollmentComponent } from '../base-enrollment/base-enrollment.component';

@Component({
  selector: 'app-signature-enrollment',
  templateUrl: './signature-enrollment.component.html',
  styleUrls: ['./signature-enrollment.component.css']
})
export class SignatureEnrollmentComponent extends BaseEnrollmentComponent implements OnInit {

}
