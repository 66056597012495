<mat-form-field class="full-width-field">
    <mat-label>Instructor</mat-label>
    <mat-select
        ngModel
        name="field_teacher">
        <mat-option>None</mat-option>
        <mat-option 
            *ngFor="let instructor of instructorList" 
            [value]="instructor">{{
            instructor.field_first_name }} {{
            instructor.field_last_name }}</mat-option>
    </mat-select>
</mat-form-field>