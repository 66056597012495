import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DrupalRESTService } from 'src/app/services/drupal-rest.service';

@Component({
  selector: 'app-square-oauth-callback-component',
  templateUrl: './square-oauth-callback-component.component.html',
  styleUrls: ['./square-oauth-callback-component.component.css']
})
export class SquareOAuthCallbackComponent implements OnInit {
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private drupalRESTService: DrupalRESTService
  ) {}

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      const code = params['code'];
      const state = params['state'];
      const storedState = localStorage.getItem('squareOAuthState');

      if (state !== storedState) {
        console.error('State mismatch. Possible CSRF attack.');
        this.router.navigate(['/configuration/square-integration'], { queryParams: { error: 'state_mismatch' } });
        return;
      }

      localStorage.removeItem('squareOAuthState');

      if (code) {
        // Exchange the code for an access token
        this.drupalRESTService.httpPOST('/api_rest/v1/square/exchange-token', { code }).subscribe(
          (response: any) => {
            // Token exchange successful
            this.router.navigate(['/configuration/square-integration'], { queryParams: { authorized: 'true' } });
          },
          error => {
            console.error('Error exchanging Square authorization code:', error);
            this.router.navigate(['/configuration/square-integration'], { queryParams: { error: 'token_exchange_failed' } });
          }
        );
      } else {
        // No code received, authorization failed
        this.router.navigate(['/configuration/square-integration'], { queryParams: { error: 'no_code_received' } });
      }
    });
  }
}
