import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { DrupalRESTService } from 'src/app/services/drupal-rest.service';
import { Editor, TinyMCE } from 'tinymce';

@Component({
  selector: 'app-smsconfiguration-settings',
  templateUrl: './smsconfiguration-settings.component.html',
  styleUrls: ['./smsconfiguration-settings.component.css']
})
export class SMSConfigurationSettingsComponent implements OnInit {
  smsConfigForm: FormGroup;
  configFieldName = 'field_sms_template_settings_ref';
  showProgressSpinner = false;
  successMessage = '';
  errorMessage = '';
  tinyMceConfig = {
    base_url: '/tinymce/',
    height: 200,
    menubar: false,
    plugins: [
      'lists link charmap preview',
      'searchreplace visualblocks code fullscreen',
      'insertdatetime media table paste code help wordcount'
    ],
    toolbar:
      'undo redo | bold italic underline | \
      alignleft aligncenter alignright alignjustify | \
      bullist numlist outdent indent | removeformat | help | placeholders',
    setup: (editor: Editor) => {
      editor.ui.registry.addMenuButton('placeholders', {
        text: 'Placeholders',
        fetch: (callback) => {
          const items: Array<{ type: 'menuitem'; text: string; onAction: () => void }> = [
            { type: 'menuitem', text: 'Student Name', onAction: () => editor.insertContent('{$studentName}') },
            { type: 'menuitem', text: 'Date Time', onAction: () => editor.insertContent('{$dateTime}') },
            { type: 'menuitem', text: 'Instructor Name', onAction: () => editor.insertContent('{$instructorName}') },
            { type: 'menuitem', text: 'Studio Phone Number', onAction: () => editor.insertContent('{$studioPhoneNumber}') }
          ];
          callback(items);
        }
      });
    }
  };

  constructor(
    private formBuilder: FormBuilder,
    private drupalRESTService: DrupalRESTService
  ) { }

  ngOnInit() {
    this.initializeForm();
    this.loadSMSConfiguration();
  }

  initializeForm() {
    this.smsConfigForm = this.formBuilder.group({
      enableNewEnrollment: [false],
      newEnrollmentTemplate: [''],
      enableLessonConfirmation: [false],
      lessonConfirmationTemplate: [''],
      enableLessonRescheduled: [false],
      lessonRescheduledTemplate: [''],
      enableLessonReminder: [false],
      lessonReminderSMSTemplate: [''],
      enable24HourReminder: [false],
      enable48HourReminder: [false],
      enable72HourReminder: [false],
      enableFirstTimeLessonThankYou: [false],
      firstTimeLessonThankYouTemplate: ['']
    });
  }

  loadSMSConfiguration() {
    this.drupalRESTService
      .httpGET(`/api_rest/v1/loadStudioConfig?config_field_name=${this.configFieldName}`)
      .subscribe(
        (response) => {
          const intervals = response?.['field_lesson_reminder_intervals'] || [];
          const enable24HourReminder = intervals.some(interval => interval.value === '24_hour');
          const enable48HourReminder = intervals.some(interval => interval.value === '48_hour');
          const enable72HourReminder = intervals.some(interval => interval.value === '72_hour');

          this.smsConfigForm.patchValue({
            enableNewEnrollment: response?.['field_enable_new_enrollment']?.length > 0 ? response?.['field_enable_new_enrollment']?.[0]?.value : false,
            newEnrollmentTemplate: response?.['field_new_enrollment_template']?.length > 0 ? response?.['field_new_enrollment_template']?.[0]?.value : this.getDefaultNewEnrollmentTemplate(),
            enableLessonConfirmation: response?.['field_enable_lesson_confirmation']?.length > 0 ? response?.['field_enable_lesson_confirmation']?.[0]?.value : false,
            lessonConfirmationTemplate: response?.['field_lesson_confirmation_templa']?.length > 0 ? response?.['field_lesson_confirmation_templa']?.[0]?.value : '',
            enableLessonRescheduled: response?.['field_enable_lesson_rescheduled']?.length > 0 ? response?.['field_enable_lesson_rescheduled']?.[0]?.value : false,
            lessonRescheduledTemplate: response?.['field_lesson_rescheduled_templat']?.length > 0 ? response?.['field_lesson_rescheduled_templat']?.[0]?.value : '',
            enableLessonReminder: response?.['field_enable_lesson_reminder']?.length > 0 ? response?.['field_enable_lesson_reminder']?.[0]?.value : false,
            lessonReminderSMSTemplate: response?.['field_lesson_reminder_template']?.length > 0 ? response?.['field_lesson_reminder_template']?.[0]?.value : this.getDefaultLessonReminderTemplate(),
            enable24HourReminder,
            enable48HourReminder,
            enable72HourReminder,
            enableFirstTimeLessonThankYou: response?.['field_enable_first_time_lesson']?.length > 0 ? response?.['field_enable_first_time_lesson']?.[0]?.value : false,
            firstTimeLessonThankYouTemplate: response?.['field_first_time_lesson_template']?.length > 0 ? response?.['field_first_time_lesson_template']?.[0]?.value : this.getDefaultFirstTimeLessonThankYouTemplate()
          });
        },
        (error) => {
          console.error('Error loading SMS configuration:', error);
        }
      );
  }

  onSubmit() {
    if (this.smsConfigForm.valid) {
      const postData = {
        config_field_name: this.configFieldName,
        config_data: this.prepareConfigData()
      };

      this.showProgressSpinner = true;
      this.successMessage = '';
      this.errorMessage = '';

      this.drupalRESTService
        .httpPOST('/api_rest/v1/saveStudioConfig', postData)
        .subscribe(
          (response: any) => {
            console.log('SMS configuration saved successfully:', response);
            if (response && response.success) {
              this.successMessage = 'SMS configuration saved successfully.';
            } else {
              this.errorMessage = 'Error saving SMS configuration. Please try again.';
            }
            this.showProgressSpinner = false;
          },
          (error) => {
            console.error('Error saving SMS configuration:', error);
            this.errorMessage = 'Error saving SMS configuration. Please try again.';
            if (error.error && error.error.message) {
              this.errorMessage += ' ' + error.error.message;
            }
            this.showProgressSpinner = false;
          }
        );
    }
  }

  prepareConfigData() {
    const configData = this.smsConfigForm.value;

    const lessonReminderIntervals = [];

    if (configData.enable24HourReminder) {
      lessonReminderIntervals.push({ value: '24_hour' });
    }
    if (configData.enable48HourReminder) {
      lessonReminderIntervals.push({ value: '48_hour' });
    }
    if (configData.enable72HourReminder) {
      lessonReminderIntervals.push({ value: '72_hour' });
    }

    return {
      field_enable_new_enrollment: configData.enableNewEnrollment ? [{ value: true }] : [],
      field_new_enrollment_template: configData.newEnrollmentTemplate ? [{ value: configData.newEnrollmentTemplate }] : [],
      field_enable_lesson_confirmation: configData.enableLessonConfirmation ? [{ value: true }] : [],
      field_lesson_confirmation_templa: configData.lessonConfirmationTemplate ? [{ value: configData.lessonConfirmationTemplate }] : [],
      field_enable_lesson_rescheduled: configData.enableLessonRescheduled ? [{ value: true }] : [],
      field_lesson_rescheduled_templat: configData.lessonRescheduledTemplate ? [{ value: configData.lessonRescheduledTemplate }] : [],
      field_enable_lesson_reminder: configData.enableLessonReminder ? [{ value: true }] : [],
      field_lesson_reminder_template: configData.lessonReminderSMSTemplate ? [{ value: configData.lessonReminderSMSTemplate }] : [],
      field_lesson_reminder_intervals: lessonReminderIntervals,
      field_enable_first_time_lesson: configData.enableFirstTimeLessonThankYou ? [{ value: true }] : [],
      field_first_time_lesson_template: configData.firstTimeLessonThankYouTemplate ? [{ value: configData.firstTimeLessonThankYouTemplate }] : []
    };
  }

  getDefaultLessonReminderTemplate(): string {
    return `Hello {$studentName}, just a reminder of your upcoming lesson at with {$instructorName} on {$dateTime}. Feel free to call us at {$studioPhoneNumber}. Please reply "CONFIRM" or "C" to confirm your attendance.\n\nTo opt-out of future reminders, reply "STOP".\n\n`;
  }

  getDefaultNewEnrollmentTemplate(): string {
    return `Welcome to __studioName__! Your enrollment is confirmed.`;
  }

  getDefaultFirstTimeLessonThankYouTemplate(): string {
    return `Thank you for your first lesson at __studioName__! We hope you enjoyed it.`;
  }
}
