<form (ngSubmit)="onSubmit(f)" #f="ngForm" class="form" >
    <div mat-dialog-title>
        <h1>Student Account</h1>
        <button mat-button mat-dialog-close><mat-icon aria-hidden="false" aria-label="Example home icon">close</mat-icon></button>
    </div>
    <div mat-dialog-content>
            <div class="container">
                <div class="row">
    
                    <div class="col-6">
                        <mat-form-field class="full-width-field">
                            <mat-label>First Name</mat-label>
                            <input
                                name="firstName"
                                matInput
                                ngModel>
                        </mat-form-field>
                    </div>
    
                    <div class="col-6">
                        <mat-form-field class="full-width-field">
                            <mat-label>Last Name</mat-label>
                            <input
                                name="lastName"
                                matInput
                                ngModel>
                        </mat-form-field>
                    </div>
    
                </div>
    
                <div class="row">
                    <div class="col-6">
                        <div class="row">
                            <mat-label>Attending Lessons</mat-label>
                        </div>
                        <mat-radio-group name="attending_lessons" ngModel aria-label="Attending Lessons">
                            <mat-radio-button value="solo">Solo</mat-radio-button>
                            <mat-radio-button value="partner">With Partner</mat-radio-button>
                        </mat-radio-group>
                    </div>
                    <div class="col-6">
                        <div class="row">
                            <mat-label>Gender</mat-label>
                        </div>
                        <mat-radio-group name="gender" ngModel aria-label="Gender">
                            <mat-radio-button value="male">Male</mat-radio-button>
                            <mat-radio-button value="female">Female</mat-radio-button>
                        </mat-radio-group>
                    </div>
                </div>
    
    
    
                <div class="row">
                    <div class="col-12">
                        <mat-form-field class="full-width-field">
                            <mat-label>Street Address</mat-label>
                            <input ngModel name="address" matInput>
                        </mat-form-field>
                    </div>
                    <div class="col-5">
                        <mat-form-field class="full-width-field">
                            <mat-label>City</mat-label>
                            <input ngModel name="city" matInput>
                        </mat-form-field>
                    </div>
                    <div class="col-5">
                        <mat-form-field class="full-width-field">
                            <mat-label>State</mat-label>
                            <input ngModel name="state" matInput>
                        </mat-form-field>
                    </div>
                    <div class="col-2">
                        <mat-form-field class="full-width-field">
                            <mat-label>Zip</mat-label>
                            <input ngModel name="postal_code" matInput>
                        </mat-form-field>
                    </div>
    
                    <div class="col-5">
                        <mat-form-field class="full-width-field">
                            <mat-label>Phone</mat-label>
                            <input ngModel name="phone" matInput>
                        </mat-form-field>
                    </div>
    
                    <div class="col-7">
                        <mat-form-field class="full-width-field">
                            <mat-label>Email Address</mat-label>
                            <input ngModel name="email" matInput>
                        </mat-form-field>
                    </div>
                </div>
    
                <div class="row">
                    <div class="col-12">
                        <mat-label>Lesson Reminders:</mat-label>
                        <mat-checkbox
                            ngModel
                            name="field_notification_preference[phone_call]"
                            class="">Phone Call</mat-checkbox>
                        <mat-checkbox 
                            ngModel
                            name="field_notification_preference[sms]"
                            class="">Text</mat-checkbox>
                        <mat-checkbox 
                            ngModel    
                            name="field_notification_preference[email]"
                            class="">Email</mat-checkbox>
                    </div>
                </div>
    
                <div class="row">
    
                    <div class="col-6">
                        <mat-form-field class="full-width-field">
                            <mat-label>What type of Dances?</mat-label>
                            <mat-select
                                ngModel
                                multiple
                                name="field_dances_type[]">
                                <mat-option value="_none">None</mat-option>
                                <mat-option value="waltz">Waltz</mat-option>
                                <mat-option value="tango">Tango</mat-option>
                                <mat-option value="foxtrot">Foxtrot</mat-option>
                                <mat-option value="chacha">Cha Cha</mat-option>
                                <mat-option value="rumba">Rumba</mat-option>
                                <mat-option value="swing">Swing</mat-option>
                                <mat-option value="salsa">Salsa</mat-option>
                                <mat-option value="bachata">Bachata</mat-option>
                                <mat-option value="merengue">Merengue</mat-option>
                                <mat-option value="country_western">Country Western</mat-option>
                                <mat-option value="mc_swing">WC Swing</mat-option>
                                <mat-option value="other">Other</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
    
                    <div class="col-6">
                        <mat-form-field class="full-width-field">
                            <mat-label>How did you hear about us?</mat-label>
                            <mat-select 
                                ngModel
                                name="field_inquiry_method">
                                <mat-option value="_none">None</mat-option>
                                <mat-option value="internet">Internet</mat-option>
                                <mat-option value="phone">Phone</mat-option>
                                <mat-option value="walk-in">Walk-in</mat-option>
                                <mat-option value="other">Other</mat-option>
                                <mat-option value="guest">Guest</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
    
                    <div class="col-12">
                        <mat-form-field class="full-width-field">
                            <mat-label>Notes</mat-label>
                            <textarea ngModel name="field_notes" matInput placeholder=""></textarea>
                        </mat-form-field>
                    </div>
    
                    <div *ngIf="data.InstructorList" class="col-6">
                        <mat-form-field class="full-width-field">
                            <mat-label>Instructors??</mat-label>
                            <mat-select
                                ngModel
                                name="field_teacher">
                                <mat-option>None</mat-option>
                                <mat-option *ngFor="let instructor of data.InstructorList" [value]="instructor">{{ instructor.field_first_name }} {{ instructor.field_last_name }}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
    
                    <div class="col-6">
                        <mat-form-field class="full-width-field">
                            <mat-label>Lesson Type</mat-label>
                            <mat-select
                                ngModel
                                name="lesson_type">
                                <mat-option>None</mat-option>
                                <mat-option *ngFor="let lesson of data.lesson_types" [value]="lesson.id">{{ lesson.value }}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
    
                    <div class="col-6">
                        <!-- Date  -->
                        <mat-form-field class="full-width-field">
                            <mat-label>Requested Date and Time</mat-label>
                            <input
                                ngModel
                                matInput
                                [matDatepicker]="picker"
                                name="lesson_date[date]">
                            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                            <mat-datepicker #picker></mat-datepicker>
                        </mat-form-field>
                    </div>
    
                    <div class="col-6">
                        <!-- Time  -->
                        <mat-form-field class="full-width-field">
                            <mat-label>Time Slot</mat-label>
                            <mat-select
                                ngModel
                                name="lesson_date[time]">
                                <mat-option value="12:00">12:00 PM</mat-option>
                                <mat-option value="12:15">12:15 PM</mat-option>
                                <mat-option value="12:30">12:30 PM</mat-option>
                                <mat-option value="12:45">12:45 PM</mat-option>
                                <mat-option value="1:00">1:00 PM</mat-option>
                                <mat-option value="1:00">1:15 PM</mat-option>
                                <mat-option value="1:00">1:30 PM</mat-option>
                                <mat-option value="1:00">1:45 PM</mat-option>
                                <mat-option value="2:00">2:00 PM</mat-option>
                                <mat-option value="2:00">2:15 PM</mat-option>
                                <mat-option value="2:00">2:30 PM</mat-option>
                                <mat-option value="2:00">2:45 PM</mat-option>
                                <mat-option value="3:00">3:00 PM</mat-option>
                                <mat-option value="3:00">3:15 PM</mat-option>
                                <mat-option value="3:00">3:30 PM</mat-option>
                                <mat-option value="3:00">3:45 PM</mat-option>
                                <mat-option value="4:00">4:00 PM</mat-option>
                                <mat-option value="4:00">4:15 PM</mat-option>
                                <mat-option value="4:00">4:30 PM</mat-option>
                                <mat-option value="12:00">4:45 PM</mat-option>
                                <mat-option value="12:00">5:00 PM</mat-option>
                                <mat-option value="12:00">5:15 PM</mat-option>
                                <mat-option value="12:00">5:30 PM</mat-option>
                                <mat-option value="12:00">5:45 PM</mat-option>
                                <mat-option value="12:00">6:00 PM</mat-option>
                                <mat-option value="12:00">6:15 PM</mat-option>
                                <mat-option value="12:00">6:30 PM</mat-option>
                                <mat-option value="12:00">6:45 PM</mat-option>
                                <mat-option value="12:00">7:00 PM</mat-option>
                                <mat-option value="12:00">7:15 PM</mat-option>
                                <mat-option value="12:00">7:30 PM</mat-option>
                                <mat-option value="12:00">7:45 PM</mat-option>
                                <mat-option value="12:00">8:00 PM</mat-option>
                                <mat-option value="12:00">8:15 PM</mat-option>
                                <mat-option value="12:00">8:30 PM</mat-option>
                                <mat-option value="12:00">8:45 PM</mat-option>
                                <mat-option value="12:00">9:00 PM</mat-option>
                                <mat-option value="12:00">9:15 PM</mat-option>
                                <mat-option value="12:00">9:30 PM</mat-option>
                                <mat-option value="12:00">9:45 PM</mat-option>
                                <mat-option value="12:00">10:00 PM</mat-option>
                                <mat-option value="12:00">10:15 PM</mat-option>
                                <mat-option value="12:00">10:30 PM</mat-option>
                                <mat-option value="12:00">10:45 PM</mat-option>
                                <mat-option value="12:00">11:00 PM</mat-option>
                                <mat-option value="12:00">11:15 PM</mat-option>
                                <mat-option value="12:00">11:30 PM</mat-option>
                                <mat-option value="12:00">11:45 PM</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
    
            </div>
        </div>
        <mat-dialog-actions align="end">
            <button onclick="onSubmit(f)" type="submit" mat-raised-button color="accent">Save and Schedule Service</button>
        </mat-dialog-actions>
    </form>