<!-- simple-import-history.component.html -->
<mat-card>
	<form [formGroup]="studentAccountsForm" (ngSubmit)="onSubmit()">
		<div class="row">
			<h3>Student Accounts</h3>


			<div class="col-1">
				<button (click)="addStudentAccount()" type="button" class="btn btn-primary">
					<span class="material-icons large-icon">add</span>
				</button>
			</div>

			<div class="col-11">
				<div formArrayName="studentAccounts">
					<div
						class="student-account"
						*ngFor="let studentAccount of studentAccountsArray.controls; let i = index"
						[formGroupName]="i">

						<div class="row student-account-container">
							<div *ngIf="studentAccountSuccessStates[i]" class="success-icon">
								<mat-icon style="float:right;">check_circle</mat-icon>
							</div>

							<div class="row">
								<!-- Contact ID field -->
								<div class="col-2 d-none">
									<mat-form-field class="full-width-field" appearance="outline">
										<mat-label>ID</mat-label>
										<input matInput formControlName="id">
									</mat-form-field>
								</div>

								<!-- Contact 1 Fields -->
								<div class="d-none">
									<mat-form-field class="full-width-field" appearance="outline">
										<mat-label>Contact 1 - ID</mat-label>
										<input matInput formControlName="contact1ID">
									</mat-form-field>
								</div>
								<div class="col-3">
									<mat-form-field class="full-width-field" appearance="outline">
										<mat-label>Contact 1 - First Name</mat-label>
										<input matInput formControlName="contact1FirstName">
									</mat-form-field>
								</div>

								<div class="col-3">
									<mat-form-field class="full-width-field" appearance="outline">
										<mat-label>Contact 1 - Last Name</mat-label>
										<input matInput formControlName="contact1LastName">
									</mat-form-field>
								</div>

								<div class="col-2">
									<mat-form-field class="full-width-field" appearance="outline">
										<mat-label>Contact 1 - Email</mat-label>
										<input matInput type="email" formControlName="contact1Email">
									</mat-form-field>
								</div>

								<div class="col-2">
									<mat-form-field class="full-width-field" appearance="outline">
										<mat-label>Contact 1 - Phone</mat-label>
										<input matInput formControlName="contact1Phone">
									</mat-form-field>
								</div>

								<div class="col-2 d-none">
									<mat-form-field class="full-width-field" appearance="outline">
										<mat-label>Contact 1 - Gender</mat-label>
										<input matInput formControlName="contact1Gender">
									</mat-form-field>
								</div>

								<!-- Student account delete button -->
								<div class="col-1">
									<button type="button" (click)="deleteStudentAccount(i)" class="btn btn-danger" [disabled]="loadingStudentAccounts[i]">
										<mat-icon *ngIf="!loadingStudentAccounts[i]">delete</mat-icon>
										<mat-spinner *ngIf="loadingStudentAccounts[i]" diameter="20"></mat-spinner>
									</button>
								</div>

							</div>
							<div class="row">

								<!-- Contact 2 Fields -->
								<div class="d-none">
									<mat-form-field class="full-width-field" appearance="outline">
										<mat-label>Contact 2 - ID</mat-label>
										<input matInput formControlName="contact2ID">
									</mat-form-field>
								</div>
								<div class="col-3">
									<mat-form-field class="full-width-field" appearance="outline">
										<mat-label>Contact 2 - First Name</mat-label>
										<input matInput formControlName="contact2FirstName">
									</mat-form-field>
								</div>

								<div class="col-3">
									<mat-form-field class="full-width-field" appearance="outline">
										<mat-label>Contact 2 - Last Name</mat-label>
										<input matInput formControlName="contact2LastName">
									</mat-form-field>
								</div>

								<div class="col-2">
									<mat-form-field class="full-width-field" appearance="outline">
										<mat-label>Contact 2 - Email</mat-label>
										<input matInput type="email" formControlName="contact2Email">
									</mat-form-field>
								</div>

								<div class="col-2">
									<mat-form-field class="full-width-field" appearance="outline">
										<mat-label>Contact 2 - Phone</mat-label>
										<input matInput formControlName="contact2Phone">
									</mat-form-field>
								</div>

								<div class="d-none">
									<mat-form-field class="full-width-field" appearance="outline">
										<mat-label>Contact 2 - Gender</mat-label>
										<input matInput formControlName="contact2Gender">
									</mat-form-field>
								</div>

								<div class="col-2">
									<!-- Success Icon -->
									<div *ngIf="studentSuccessStates[i]" class="success-icon">
										<!-- <mat-icon>check_circle</mat-icon> -->
									</div>

									<!-- Error Icon -->
									<div *ngIf="studentErrorMessages[i]" class="error-icon">
										<mat-icon matTooltip="{{studentErrorMessages[i]}}">error_outline</mat-icon>
									</div>
								</div>

							</div>

							<!-- Enrollment Component Placeholder -->
							<div class="enrollment-container">
								<mat-card>
									<div formArrayName="enrollments">

										<h3>Enrollment(s)</h3>

										<div class="row">
											<div class="col-1">
												<button (click)="addEnrollment(i)" type="button"
													class="btn btn-primary">
													<span class="material-icons large-icon">add</span>
												</button>
											</div>

											<div class="col-11">
												<div class="mat-card-enrollment"
													*ngFor="let enrollment of getEnrollmentsFormGroup(studentAccountsArray.at(i)).controls; let j = index"
													[formGroupName]="j">

													<div class="row">
														<!-- Enrollment ID field -->
														<div class="col-2 d-none">
															<mat-form-field class="full-width-field" appearance="outline">
																<mat-label>ID</mat-label>
																<input matInput formControlName="id">
															</mat-form-field>
														</div>

														<!-- Sale Date Field -->
														<div class="col-3">
															<mat-form-field appearance="outline">
																<input formControlName="field_sale_date"
																	matInput
																	[ngxMatDatetimePicker]="picker_field_sale_date"
																	placeholder="Enrollment Date">
																<mat-datepicker-toggle matSuffix [for]="picker_field_sale_date"></mat-datepicker-toggle>
																<ngx-mat-datetime-picker #picker_field_sale_date
																	[hideTime]="false"
																	[enableMeridian]="true"
																	[showSpinners]="true"
																	[showSeconds]="false"
																	[stepHour]="1" [stepMinute]="15"
																	[touchUi]="false">
																</ngx-mat-datetime-picker>
															</mat-form-field>
														</div>

														<!-- Packages Field -->
														<div class="col-2">
															<mat-form-field class="full-width-field" appearance="outline">
																<mat-label>Package</mat-label>
																<mat-select
																	(selectionChange)="onPackageChange($event.value, i, j)"
																	formControlName="__field_packages">
																	<mat-option *ngFor="let package of packages"
																		[value]="package.id">
																		{{ package.field_package_name }}
																	</mat-option>
																</mat-select>
															</mat-form-field>
														</div>

														<!-- Total Lessons Enrolled Field -->
														<div class="col-2">
															<mat-form-field class="full-width-field" appearance="outline">
																<mat-label>Total Lessons Enrolled</mat-label>
																<input matInput formControlName="__total_lessons_enrolled"
																	type="number">
															</mat-form-field>
														</div>

														<!-- Total Lessons Available Field -->
														<div class="col-2">
															<mat-form-field class="full-width-field" appearance="outline">
																<mat-label>Total Lessons Available</mat-label>
																<input matInput formControlName="__total_lessons_available" type="number">
																<mat-hint *ngIf="enrollment.errors?.totalLessonsAvailableInvalid">
																	Total Lessons Available cannot be less than Total Lessons Taken and cannot be more than Total Lessons Enrolled.
																</mat-hint>
															</mat-form-field>
														</div>

														<!-- Total Lessons Taken Field -->
														<div class="col-2">
															<mat-form-field class="full-width-field" appearance="outline">
																<mat-label>Total Lessons Taken</mat-label>
																<input matInput formControlName="__total_lessons_taken" type="number">
																<mat-hint *ngIf="enrollment.errors?.totalLessonsTakenInvalid">
																	Total Lessons Taken cannot be more than Total Lessons Enrolled.
																</mat-hint>
															</mat-form-field>
														</div>

														<!-- Total Enrollment Price Field -->
														<div class="col-2">
															<mat-form-field class="full-width-field" appearance="outline">
																<mat-label>Total Tuition Paid</mat-label>
																<input matInput formControlName="__total_enrollment_price"
																	type="number">
															</mat-form-field>
														</div>

														<!-- Discount Field -->
														<div class="col-2">
															<mat-form-field class="full-width-field" appearance="outline">
																<mat-label>Discount</mat-label>
																<input matInput formControlName="field_discount">
															</mat-form-field>
														</div>

														<!-- Student Field -->
														<div class="col-2 d-none">
															<mat-form-field class="full-width-field" appearance="outline">
																<mat-label>Student</mat-label>
																<input matInput formControlName="field_student">
															</mat-form-field>
														</div>

														<!-- Category Field -->
														<div class="col-2 d-none">
															<mat-form-field class="full-width-field" appearance="outline">
																<mat-label>Category</mat-label>
																<mat-select matInput formControlName="field_category">
																	<mat-option>None</mat-option>
																	<mat-option
																		*ngFor="let element of this._taxonomyService.enrollment_type"
																		[value]="element.id">
																		{{ element.name }}</mat-option>
																</mat-select>
															</mat-form-field>

														</div>

														<!-- Enrollment Package Name Field -->
														<div class="col-2 d-none">
															<mat-form-field class="full-width-field" appearance="outline">
																<mat-label>Enrollment Package Name</mat-label>
																<input matInput formControlName="field_enrollment_package_name">
															</mat-form-field>
														</div>

														<!-- Enrollment Lesson Price Field -->
														<div class="col-2 d-none">
															<mat-form-field class="full-width-field" appearance="outline">
																<mat-label>Lesson Price</mat-label>
																<input matInput formControlName="field_enrollment_lesson_price"
																	type="number">
															</mat-form-field>
														</div>

														<!-- Enrollment Lesson Count Field -->
														<div class="col-2 d-none">
															<mat-form-field class="full-width-field" appearance="outline">
																<mat-label>Lesson Count</mat-label>
																<input matInput formControlName="field_enrollment_lesson_count"
																	type="number">
															</mat-form-field>
														</div>

														<!-- Enrollment Total Price Field -->
														<div class="col-2 d-none">
															<mat-form-field class="full-width-field" appearance="outline">
																<mat-label>Total Price</mat-label>
																<input matInput formControlName="field_enrollment_total_price"
																	type="number">
															</mat-form-field>
														</div>

														<!-- Discount Type Field -->
														<div class="col-2 d-none">
															<mat-form-field class="full-width-field" appearance="outline">
																<mat-label>Discount Type</mat-label>
																<mat-select required matInput
																	formControlName="field_discount_type">
																	<mat-option>None</mat-option>
																	<mat-option
																		*ngFor="let element of this._fieldsService.discountType"
																		[value]="element.key">
																		{{ element.value }}</mat-option>
																</mat-select>
															</mat-form-field>
														</div>

														<!-- Tax Percentage Field -->
														<div class="col-2 d-none">
															<mat-form-field class="full-width-field" appearance="outline">
																<mat-label>Tax Percentage</mat-label>
																<input matInput formControlName="field_tax_percentage"
																	type="number">
															</mat-form-field>
														</div>

														<!-- Expiration Date Field -->
														<div class="col-2 d-none">
															<mat-form-field appearance="outline">
																<input formControlName="field_expiration_date"
																	matInput
																	[ngxMatDatetimePicker]="picker_field_expiration_date"
																	placeholder="Expiration Date">
																<mat-datepicker-toggle matSuffix
																	[for]="picker_field_expiration_date"></mat-datepicker-toggle>
																<ngx-mat-datetime-picker #picker_field_expiration_date
																	[hideTime]="false"
																	[enableMeridian]="true"
																	[showSpinners]="true"
																	[showSeconds]="false"
																	[stepHour]="1" [stepMinute]="15"
																	[touchUi]="false">
																</ngx-mat-datetime-picker>
															</mat-form-field>
														</div>

														<!-- Executive Field -->
														<div class="col-2">
															<mat-form-field class="full-width-field" appearance="outline">
																<mat-label>Executive</mat-label>
																<mat-select required matInput
																	formControlName="field_executive">
																	<mat-option>None</mat-option>
																	<mat-option
																		*ngFor="let element of this._fieldsService.instructorsExecutive();"
																		[value]="element.uid">
																		{{ element.value }}</mat-option>
																</mat-select>
															</mat-form-field>
														</div>

														<!-- Instructor Field -->
														<div class="col-2">
															<mat-form-field class="full-width-field" appearance="outline">
																<mat-label>Instructor</mat-label>
																<mat-select required matInput
																	formControlName="field_instructor">
																	<mat-option>None</mat-option>
																	<mat-option
																		*ngFor="let element of this._fieldsService.instructorsExecutive();"
																		[value]="element.uid">
																		{{ element.value }}</mat-option>
																</mat-select>
															</mat-form-field>
														</div>

														<!-- Notes Field -->
														<div class="col-2">
															<mat-form-field class="full-width-field" appearance="outline">
																<mat-label>Notes</mat-label>
																<input matInput formControlName="field_notes">
															</mat-form-field>
														</div>

														<!-- Enrollment delete button -->
														<div class="col-1">
															<button type="button" (click)="deleteEnrollment(i, j)" class="btn btn-danger" [disabled]="loadingEnrollments[i + '_' + j]">
																<mat-icon *ngIf="!loadingEnrollments[i + '_' + j]">delete</mat-icon>
																<mat-spinner *ngIf="loadingEnrollments[i + '_' + j]" diameter="20"></mat-spinner>
															</button>
														</div>

														<div class="col-4">
															<div class="enrollment-summary">
																<p><strong>Original Enrollment Price:</strong> {{ calculateOriginalEnrollmentPrice(enrollment) | currency }}</p>
																<p><strong>Discount:</strong> {{ enrollment.get('field_discount').value }}{{ enrollment.get('field_discount_type').value === 'percentage' ? '%' : '' }}</p>
																<p><strong>Discounted Enrollment Price:</strong> {{ calculateDiscountedEnrollmentPrice(enrollment) | currency }}</p>
																<p><strong>Price Per Lesson:</strong> {{ calculateDiscountedPricePerLesson(enrollment) | currency }}</p>
																<p><strong>Total Paid:</strong> {{ calculateTotalPaid(enrollment) | currency }}</p>
															</div>
														</div>

														<div *ngIf="enrollmentErrorMessages[j]" class="col-1 error-icon">
															<div class="col-1 error-icon">
																<mat-icon matTooltip="{{enrollmentErrorMessages[j]}}">error_outline</mat-icon>
															</div>
														</div>
														<div *ngIf="enrollmentSuccessStates[j]" class="col-1 success-icon">
															<div class="col-1 success-icon">
																<mat-icon>check_circle</mat-icon>
															</div>
														</div>
													</div>

													<!-- Looping through each Payment -->
													<div formArrayName="payments" class="d-none">
														<div class="row">
															<h3>Payment</h3>
															<div class="col-1">
																<button (click)="addPayment(i, j)" type="button"
																	class="btn btn-primary">
																	<span class="material-icons large-icon">add</span>
																</button>
															</div>

															<div class="col-11">
																<ng-container
																	*ngFor="let payment of getPayments(i, j).controls; let paymentIndex = index"
																	[formGroupName]="paymentIndex">
																	<div class="row">
																		<!-- Payment ID field -->
																		<div class="col-2 d-none">
																			<mat-form-field class="full-width-field" appearance="outline">
																				<mat-label>ID</mat-label>
																				<input matInput formControlName="id">
																			</mat-form-field>
																		</div>
																		<!-- Date and Time Field -->
																		<div class="col-3">
																			<mat-form-field appearance="outline">
																				<input formControlName="field_date_and_time"
																					matInput
																					[ngxMatDatetimePicker]="picker_field_date_and_time"
																					placeholder="Date and Time">
																				<mat-datepicker-toggle matSuffix
																					[for]="picker_field_date_and_time"></mat-datepicker-toggle>
																				<ngx-mat-datetime-picker #picker_field_date_and_time
																					[hideTime]="false"
																					[enableMeridian]="true"
																					[showSpinners]="true"
																					[showSeconds]="false"
																					[stepHour]="1" [stepMinute]="15"
																					[touchUi]="false">
																				</ngx-mat-datetime-picker>
																			</mat-form-field>
																		</div>
																		<div class="col-2">
																			<mat-form-field class="full-width-field" appearance="outline">
																				<mat-label>Tuition Paid</mat-label>
																				<input matInput formControlName="field_gross_tuition"
																					type="number">
																			</mat-form-field>
																		</div>
																		<div class="col-2">
																			<mat-form-field class="full-width-field" appearance="outline">
																				<mat-label>Tax Collected</mat-label>
																				<input matInput type="number" formControlName="field_tax_collected" required>
																			</mat-form-field>
																		</div>
																		<div class="col-2 d-none">
																			<mat-form-field class="full-width-field" appearance="outline">
																				<mat-label>Student Name</mat-label>
																				<input matInput formControlName="field_student_name">
																			</mat-form-field>
																		</div>
																		<div class="col-2 d-none">
																			<mat-form-field class="full-width-field" appearance="outline">
																				<mat-label>Enrollment Name</mat-label>
																				<input matInput formControlName="field_enrollment_name">
																			</mat-form-field>
																		</div>
																		<div class="col-2">
																			<mat-form-field appearance="outline">
																				<mat-label>Select Payment Type</mat-label>
																				<mat-select formControlName="field_payment_type">
																					<mat-option
																						*ngFor="let element of this._taxonomyService.payment_type"
																						[value]="element.id">
																						{{ element.name }}
																					</mat-option>
																				</mat-select>
																			</mat-form-field>
																		</div>
																		<div
																			*ngIf="paymentErrorMessages[i + '_' + j + '_' + paymentIndex]"
																			class="col-1 error-icon">
																			<mat-icon
																				matTooltip="{{paymentErrorMessages[i + '_' + j + '_' + paymentIndex]}}">error_outline</mat-icon>
																		</div>
																		<!-- <div
																			*ngIf="paymentSuccessStates[i + '_' + j + '_' + paymentIndex]"
																			class="col-1 success-icon">
																			<mat-icon>check_circle</mat-icon>
																		</div> -->

																		<div class="col-1">
																			<button type="button"
																				(click)="removePayment(i, j, paymentIndex)"
																				class="btn btn-danger">
																				<mat-icon>delete</mat-icon>
																			</button>
																		</div>

																		<div *ngIf="paymentSuccessStates[generatePaymentKey(i, j, paymentIndex)]" class="col-1 success-icon">
																			<!-- <mat-icon>check_circle</mat-icon> -->
																		</div>
																	</div>
																</ng-container>
															</div>
														</div>
													</div>

													<!-- Looping through each Lesson -->
													<div formArrayName="lessons" class="d-none">
														<div class="row">
															<h3>Lesson(s)</h3>
															<div class="col-1">
																<button (click)="addLesson(i, j)" type="button"
																	class="btn btn-primary">
																	<span class="material-icons large-icon">add</span>
																</button>
															</div>

															<div class="col-11">
																<ng-container
																	*ngFor="let lesson of getLessons(i, j).controls; let lessonIndex = index"
																	[formGroupName]="lessonIndex">
																	<div class="row">

																		<!-- Event ID field -->
																		<div class="col-2 d-none">
																			<mat-form-field class="full-width-field" appearance="outline">
																				<mat-label>ID</mat-label>
																				<input matInput formControlName="id">
																			</mat-form-field>
																		</div>

																		<!-- Date and Time Field -->
																		<div class="col-3">
																			<mat-form-field appearance="outline">
																				<input formControlName="field_date_and_time"
																					matInput
																					[ngxMatDatetimePicker]="picker_field_date_and_time"
																					placeholder="Date and Time">
																				<mat-datepicker-toggle matSuffix
																					[for]="picker_field_date_and_time"></mat-datepicker-toggle>
																				<ngx-mat-datetime-picker #picker_field_date_and_time
																					[hideTime]="false"
																					[enableMeridian]="true"
																					[showSpinners]="true"
																					[showSeconds]="false"
																					[stepHour]="1" [stepMinute]="15"
																					[touchUi]="false">
																				</ngx-mat-datetime-picker>
																			</mat-form-field>
																		</div>
																		<div class="col-3">
																			<mat-form-field class="full-width-field" appearance="outline">
																				<mat-label>Instructor</mat-label>
																				<mat-select required matInput
																					formControlName="field_instructor">
																					<mat-option>None</mat-option>
																					<mat-option
																						*ngFor="let element of this._fieldsService.instructorData();"
																						[value]="element.uid">
																						{{ element.value }}</mat-option>
																				</mat-select>
																			</mat-form-field>
																		</div>

																		<!-- <div *ngIf="lessonErrorMessages[i + '_' + j + '_' + lessonIndex]" class="col-1 error-icon">
																			<mat-icon matTooltip="{{lessonErrorMessages[i + '_' + j + '_' + lessonIndex]}}">error_outline</mat-icon>
																		</div>
																		<div *ngIf="lessonSuccessStates[i + '_' + j + '_' + lessonIndex]" class="col-1 success-icon">
																			<mat-icon>check_circle</mat-icon>
																		</div> -->

																		<div class="col-1">
																			<button type="button"
																				(click)="removeLesson(i, j, lessonIndex)"
																				class="btn btn-danger">
																				<mat-icon>delete</mat-icon>
																			</button>
																		</div>

																		<div *ngIf="lessonSuccessStates[i + '_' + lessonIndex]"
																			class="col-1 success-icon">
																			<mat-icon>check_circle</mat-icon>
																		</div>

																		<div *ngIf="lessonErrorMessages[i + '_' + lessonIndex]"
																			class="col-1 error-icon">
																			<mat-icon
																				matTooltip="{{lessonErrorMessages[i + '_' + lessonIndex]}}">error_outline</mat-icon>
																		</div>

																	</div>
																</ng-container>
															</div>
														</div>
													</div>

												</div>
											</div>
										</div>

									</div>
								</mat-card>
							</div>

						</div>
					</div>
				</div>
			</div>
		</div>

		<div class="submission-progress" *ngIf="isSubmitting">
      <mat-progress-bar mode="determinate" [value]="submissionProgress"></mat-progress-bar>
      <p>Submission Progress: {{submissionProgress.toFixed(2)}}%</p>
    </div>

		<button mat-raised-button color="primary" type="submit" [disabled]="isSubmitting">
			<div class="button-content">
				<span *ngIf="!isSubmitting">Submit</span>
				<span *ngIf="isSubmitting" class="spinner-container">
					Submitting... <mat-spinner *ngIf="isSubmitting" diameter="20"></mat-spinner>
				</span>
			</div>
		</button>

    <button mat-raised-button color="secondary" type="button" (click)="clearStudentForm()" class="ms-1">
      Clear Form / Add New Student
    </button>
	</form>
</mat-card>
