import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { UtilityService } from 'src/app/services/utility.service';
import { ChartConfiguration, ChartData, ChartEvent, ChartType } from 'chart.js';
import { BaseChartDirective } from 'ng2-charts';
import DataLabelsPlugin from 'chartjs-plugin-datalabels';

@Component({
  selector: 'app-payments-chart',
  templateUrl: './payments-chart.component.html',
  styleUrls: ['./payments-chart.component.css']
})
export class PaymentsChartComponent implements OnInit {

  @ViewChild(BaseChartDirective) chart: BaseChartDirective | undefined;
  @Input() set chart_data(chart_data) {
    this._chart_data = chart_data;
    this.update();
  };

  get chart_data(): string {

    return this.chart_data;

  }

  _chart_data;
  dashboardData;

  constructor() { }

  ngOnInit(): void {
    this.update();
  }

  barChartOptions: ChartConfiguration['options'] = {
    responsive: true,

    scales: {
      y: {
        title: {
          display: true,
          text: 'Total Payments'
        }
      },
      x: {
        title: {
          display: true,
          text: 'Months'
        }
      }
    },
    plugins: {
      title: {
        display: true,
        text: 'Payments'
      },
      legend: {
        display: true,
      },
      datalabels: {
        anchor: "end",
        align: 'end'
      },
    }
  };
  barChartType: ChartType = 'bar';
  barChartPlugins = [
    DataLabelsPlugin
  ];

  barChartData: ChartData<'bar'> = {
    labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
    datasets: [
      { data: [0, 0, 0, 0, 0, 0, 0], label: '2022' },
      { data: [0, 0, 0, 0, 0, 0, 0], label: '2021' }
    ]
  };

  // events
  chartClicked({ event, active }: { event?: ChartEvent, active?: {}[] }): void {
    // console.log(event, active);
  }

  chartHovered({ event, active }: { event?: ChartEvent, active?: {}[] }): void {
    // console.log(event, active);
  }

  update(): void {
    if (this._chart_data == null) { return };
    let chart_data = this._chart_data;
    let newLabel = [];
    let newDataCol1 = [];
    let newDataCol2 = [];

    // Transform data to fit into Charts.js.
    for (let i = 1; i <= 12; i++) {
      newLabel.push(chart_data[i][0]);
      newDataCol1.push(chart_data[i][1]);
      newDataCol2.push(chart_data[i][2]);
    }

    this.barChartData.datasets[0].label = chart_data[0][1];
    this.barChartData.datasets[1].label = chart_data[0][2];

    this.barChartData.labels = newLabel;
    this.barChartData.datasets[0].data = newDataCol1;
    this.barChartData.datasets[1].data = newDataCol2;

    this.chart?.update();
  }

}
