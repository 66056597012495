<mat-card>
	<form [formGroup]="instructorForm" (ngSubmit)="onSubmitInstructors()">

		<div class="row">

			<h3>Instructors</h3>

			<div class="col-1">
				<button (click)="addInstructor()" type="button" class="btn btn-primary">
					<span class="material-icons large-icon">add</span>
				</button>
			</div>

			<div class="col-11">

				<div formArrayName="instructors">
					<div *ngFor="let instructor of instructorArray.controls; let i = index"
						[formGroupName]="i"
						>
						<!-- [ngClass]="{'error-border': instructorErrorMessages[i]}" -->

						<div class="row">

							<div class="d-none">
								<mat-form-field class="full-width-field" appearance="outline">
									<mat-label>Email</mat-label>
									<input matInput formControlName="uid">
								</mat-form-field>
							</div>

							<div class="col-2">
								<mat-form-field class="full-width-field" appearance="outline">
									<mat-label>Email</mat-label>
									<input matInput formControlName="email">
								</mat-form-field>
							</div>

							<div class="col-2">
								<mat-form-field class="full-width-field" appearance="outline">
									<mat-label>Last Name</mat-label>
									<input matInput formControlName="field_last_name">
								</mat-form-field>
							</div>

							<div class="col-2">
								<mat-form-field class="full-width-field" appearance="outline">
									<mat-label>First Name</mat-label>
									<input matInput formControlName="field_first_name">
								</mat-form-field>
							</div>

							<div class="col-2">
								<mat-form-field class="full-width-field" appearance="outline">
									<mat-label>User Name</mat-label>
									<input matInput formControlName="field_username">
								</mat-form-field>
							</div>

							<div class="col-2">
								<mat-form-field class="full-width-field" appearance="outline">
									<mat-label>AMI ID</mat-label>
									<input matInput formControlName="field_ami_id">
								</mat-form-field>
							</div>

							<!-- Delete Button -->
							<div class="col-1">
								<button type="button" (click)="openDeleteDialog(i)"
									class="btn btn-danger">
									<mat-icon>delete</mat-icon>
								</button>
							</div>

							<!-- Error Icon with Tooltip -->
							<div *ngIf="instructorErrorMessages[i]" class="col-1 error-icon">
								<mat-icon matTooltip="{{ instructorErrorMessages[i] }}"
									aria-label="Error icon">error_outline</mat-icon>
							</div>
							<!-- Success Checkmark Icon -->
							<div *ngIf="instructorSuccessStates[i]" class="col-1 success-icon">
								<mat-icon>check_circle</mat-icon>
							</div>
						</div>



					</div>
				</div>
			</div>

		</div>

		<!-- Additional form fields for lastName, firstName, userName, and amiId -->
		<button mat-raised-button color="primary">Submit</button>
	</form>
</mat-card>
