<div class="container-fluid">
    <h1 class="page-header">Student Inventory</h1>

    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          Filter
        </mat-panel-title>
        <mat-panel-description>
          <app-filter-results-panel [parameters]="queryParams"></app-filter-results-panel>
        </mat-panel-description>
      </mat-expansion-panel-header>
      <form (ngSubmit)="onSubmit(f)" #f="ngForm" class="form">
        <div class="row">

          <div class="col-3">
            <mat-form-field class="full-width-field">
              <mat-label>Select Department...</mat-label>
              <mat-select name="field_student_department_target_id" ngModel (selectionChange)="onSubmit(f)">
                <mat-option value="All">All</mat-option>
                <mat-option *ngFor="let department of departmentData" [value]="department.key">{{ department.value }}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="col-3">
            <mat-form-field class="full-width-field">
              <mat-label>Last Lesson Taken Start</mat-label>
              <input matInput (click)="pickerMin.open()" [ngxMatDatetimePicker]="pickerMin" name="last_lesson_taken_start" [(ngModel)]="lastLessonTakenStart" (dateChange)="updatePickerMax($event)" [disabled]="isActiveChecked || isInactiveChecked">
              <mat-datepicker-toggle matSuffix [for]="pickerMin"></mat-datepicker-toggle>
              <ngx-mat-datetime-picker #pickerMin [hideTime]="true" [enableMeridian]="true" [showSpinners]="true" [showSeconds]="false" [stepHour]="1" [stepMinute]="15" [touchUi]="false"></ngx-mat-datetime-picker>
            </mat-form-field>
          </div>

          <div class="col-3">
            <mat-form-field class="full-width-field">
              <mat-label>Last Lesson Taken End</mat-label>
              <input matInput (click)="pickerMax.open()" [ngxMatDatetimePicker]="pickerMax" name="last_lesson_taken_end" [(ngModel)]="lastLessonTakenEnd" (dateChange)="onSubmit(f)" [disabled]="isActiveChecked || isInactiveChecked">
              <mat-datepicker-toggle matSuffix [for]="pickerMax"></mat-datepicker-toggle>
              <ngx-mat-datetime-picker #pickerMax [hideTime]="true" [enableMeridian]="true" [showSpinners]="true" [showSeconds]="false" [stepHour]="1" [stepMinute]="15" [touchUi]="false"></ngx-mat-datetime-picker>
            </mat-form-field>
          </div>

          <section class="section">
            <mat-checkbox name="Active" class="margin" [(ngModel)]="isActiveChecked" (change)="onActiveChange()">Active</mat-checkbox>
            <mat-checkbox name="Inactive" class="margin" [(ngModel)]="isInactiveChecked" (change)="onInactiveChange()">Inactive</mat-checkbox>
          </section>

          <section class="section">
            <div class="float-end bring-inline">
              <button (click)="resetForm()" color="primary" mat-flat-button class="me-1" mat-button>Clear</button>
            </div>
          </section>

        </div>
      </form>
    </mat-expansion-panel>

    <mat-progress-bar *ngIf="ShowProgressBar" mode="indeterminate"></mat-progress-bar>
    <div class="row">
      <div class="col-md-12 mt-3">
        <div class="float-end">
          <button (click)="downloadCSVDrupalViews('/api/v2/students_inventory/export?' + filters, 'student_inventory.csv', this.form.value)" color="primary" mat-flat-button class="me-1 warning" mat-button>
            <mat-icon *ngIf="isLoading" class="spinner-icon">hourglass_empty</mat-icon>
            <span *ngIf="!isLoading">Export CSV</span>
            <span *ngIf="isLoading">Exporting...</span>
          </button>
        </div>
      </div>
    </div>

    <div class="table-container">

        <table mat-table #displayTable [dataSource]="dataSource"
            matSort
            (matSortChange)="sortData($event)"
            class="mt-4">

            <!-- name Column -->
            <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef
                    sortActionDescription="Sort by name">
                    Full Name of Client
                </th>
                <td mat-cell *matCellDef="let element"> {{element.field_last_name}}, {{element.field_first_name}} </td>
            </ng-container>

            <!-- field_student_department -->
            <ng-container matColumnDef="field_student_department">
                <th mat-header-cell *matHeaderCellDef
                    sortActionDescription="Sort by field_student_department">
                    Dept.
                </th>
                <td mat-cell *matCellDef="let element"> {{element.field_student_department}} </td>
            </ng-container>


            <!-- address Column -->
            <ng-container matColumnDef="address">
                <th mat-header-cell *matHeaderCellDef
                    sortActionDescription="Sort by address">
                    Complete Address
                </th>
                <td mat-cell *matCellDef="let element">
                    {{ element.field_address_address_line1 }}
                    {{ element.field_address_address_line2 }}
                    {{ element.field_address_administrative_area }}
                    {{ element.field_address_postal_code }}
                </td>
            </ng-container>

            <!-- totalEnrolled Column -->
            <ng-container matColumnDef="totalEnrolled">
                <th mat-header-cell *matHeaderCellDef
                    sortActionDescription="Sort by totalEnrolled">
                    Total PER Lessons Enrolled (45-Minute)
                </th>
                <td mat-cell *matCellDef="let element"> {{element.field_total_lessons_enrolled}} </td>
            </ng-container>

            <!-- lessonUsed Column -->
            <ng-container matColumnDef="lessonUsed">
                <th mat-header-cell *matHeaderCellDef
                    sortActionDescription="Sort by lessonUsed">
                    Total PRI Lessons Used
                </th>
                <td mat-cell *matCellDef="let element"> {{element.field_total_lessons_taken_all}} </td>
            </ng-container>

            <!-- remaining Column -->
            <ng-container matColumnDef="remaining">
                <th mat-header-cell *matHeaderCellDef
                    sortActionDescription="Sort by remaining">
                    No. Of Untaught PRI Lessons
                </th>
                <td mat-cell *matCellDef="let element"> {{element.field_total_lessons_remaining}} </td>
            </ng-container>

            <!-- Misc. Serv. Type & Value Column -->
            <ng-container matColumnDef="miscService">
                <th mat-header-cell *matHeaderCellDef
                    sortActionDescription="Sort by remaining">
                    Misc. Serv. Type & Value
                </th>
                <td mat-cell *matCellDef="let element">
                    <div [innerHTML]="element.misc_services_field"></div>
                </td>
            </ng-container>

            <!-- totalDue Column -->
            <!-- <ng-container matColumnDef="totalDue">
                <th mat-header-cell *matHeaderCellDef
                    sortActionDescription="Sort by remaining">
                    Total $ Due
                </th>
                <td mat-cell *matCellDef="let element"> ${{ element.field_balance_due }} </td>
            </ng-container> -->

            <!-- total_due_misc_field Column -->
            <ng-container matColumnDef="total_due_misc_field">
                <th mat-header-cell *matHeaderCellDef
                    sortActionDescription="Sort by remaining">
                    Total Misc $ Due
                </th>
                <td mat-cell *matCellDef="let element"> ${{ element.total_due_misc_field }} </td>
            </ng-container>

            <!-- totalDueLessons Column -->
            <ng-container matColumnDef="totalDueLessons">
                <th mat-header-cell *matHeaderCellDef
                    sortActionDescription="Sort by remaining">
                    Total Lesson $ Due
                </th>
                <td mat-cell *matCellDef="let element"> ${{ element.total_due_lessons_field }} </td>
            </ng-container>

            <!-- lastLesson Column -->
            <ng-container matColumnDef="lastLesson">
                <th mat-header-cell *matHeaderCellDef
                    sortActionDescription="Sort by lastLesson">
                    Date of Last Lesson Taught
                </th>
                <td mat-cell *matCellDef="let element"> {{ element.field_last_lesson_taken | date }} </td>
            </ng-container>

            <!-- lastLesson Column -->
            <ng-container matColumnDef="futureLesson">
                <th mat-header-cell *matHeaderCellDef
                    sortActionDescription="Sort by futureLesson">
                    Date of Future Lesson
                </th>
                <td mat-cell *matCellDef="let element"> {{ element.field_upcoming_lesson | date }} </td>
            </ng-container>


            <!-- test Column -->
            <!-- <ng-container matColumnDef="test">
                <th mat-header-cell *matHeaderCellDef
                    sortActionDescription="Sort by test">
                    Total $ Due Studio & Budget
                </th>
                <td mat-cell *matCellDef="let element"> {{element.test}} </td>
            </ng-container> -->

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row matRipple *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>

        <mat-paginator
            [disabled]="ShowProgressBar"
            (page)="pageChanged($event)"
            [pageSizeOptions]="onPageSizeOptions()"
            [length]="30"
            [pageSize]="paginationTotalPages"
            [pageIndex]="paginationIndex"
            aria-label="Select page of view"></mat-paginator>

    </div>
</div>
