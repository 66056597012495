import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Observable } from 'rxjs';
import { ConfirmDialogComponent } from 'src/app/components/utility/confirm-dialog/confirm-dialog.component';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-simple-import-instructors',
  templateUrl: './simple-import-instructors.component.html',
  styleUrls: ['./simple-import-instructors.component.css']
})
export class SimpleImportInstructorsComponent implements OnInit {

  instructorForm: FormGroup;
  instructorErrorMessages: string[] = []; // Array to hold error messages per instructor
  instructorSuccessStates: boolean[] = []; // Array to hold success state per instructor

  constructor(
    private fb: FormBuilder,
    private userService: UserService,
    private dialog: MatDialog, // Inject MatDialog,
    private snackBar: MatSnackBar // Inject MatSnackBar
  ) { }

  ngOnInit() {
    this.instructorForm = this.fb.group({
      instructors: this.fb.array([])
    });

    this.userService.getUsers([]).subscribe(data => {
      this.populateInstructors(data);
    }, error => {
      console.error('Error fetching instructors:', error);
    });

    // Add initial instructor form group
    // this.addInstructor();
  }

  get instructorArray(): FormArray {
    return this.instructorForm.get('instructors') as FormArray;
  }

  deleteInstructor(index: number) {
    const userId = this.instructorArray.at(index).value.uid;
    console.log('Deleting user with ID:', userId);
    if (userId) {
      this.userService.deleteUser(userId).subscribe({
        next: () => {
          // User deleted successfully
          this.instructorArray.removeAt(index);
          this.instructorErrorMessages.splice(index, 1);
        },
        error: () => {
          // Error handling is already done in UserService
        }
      });
    } else {
      this.instructorArray.removeAt(index);
      this.instructorErrorMessages.splice(index, 1);
    }
  }

  addInstructor(instructorData: any = {}) {
    const instructorGroup = this.fb.group({
      uid: [instructorData.uid || ''], // Include UID, default to empty if not provided
      email: [instructorData.mail || '', Validators.email],
      field_first_name: [instructorData.first_first_name || ''],
      field_last_name: [instructorData.field_last_name || ''],
      field_username: [instructorData.username || ''],
      field_ami_id: [instructorData.field_ami_id || ''],
    });
    this.instructorArray.push(instructorGroup);
  }

  onSubmitInstructors() {
    this.instructorErrorMessages = []; // Reset error messages
    this.instructorSuccessStates = []; // Reset success states
    // Loop through each instructor and create a user for them
    this.instructorArray.controls.forEach((instructorFormGroup, index) => {
      const instructorData = instructorFormGroup.value;
      if (instructorData.uid) {
        // Update existing user
        this.userService.updateUser(instructorData.uid, {
          uid: instructorData.uid,
          username: instructorData.field_username,
          field_username: instructorData.field_username,
          mail: instructorData.email,
          field_first_name: instructorData.field_first_name,
          field_last_name: instructorData.field_last_name,
          field_ami_id: instructorData.field_ami_id,
        }).subscribe(response => {
          console.log('User updated:', response);
          this.instructorErrorMessages[index] = ''; // Clear error message for this index
          this.instructorSuccessStates[index] = true; // Set success state for this index
        }, error => {
          console.error('Error updating user:', error);
          this.instructorErrorMessages[index] = error.error.message ?? 'Error updating user.'; // Set error message for this index
        });
      } else {
        this.userService.createUser({
          username: instructorData.field_username,
          field_username: instructorData.field_username,
          mail: instructorData.email,
          field_first_name: instructorData.field_first_name,
          field_last_name: instructorData.field_last_name,
          field_ami_id: instructorData.field_ami_id,
        }).subscribe(response => {
          console.log('User created:', response);
          this.instructorErrorMessages[index] = ''; // Clear error message for this index
          this.instructorSuccessStates[index] = true; // Set success state for this index
          // Set the uid if it's a new instructor
          if (response.uid) {
            instructorFormGroup.patchValue({ uid: response.uid });
          }
        }, error => {
          console.error('Error creating user:', error);
          this.instructorErrorMessages[index] = error.error.message ?? 'Error creating user.'; // Set error message for this index
        });
      }
    });
  }

  openDeleteDialog(index: number) {
    const instructor = this.instructorArray.at(index).value;
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '600px',
      height: 'auto',
      data: {
        title: 'Confirm Deletion',
        message: `Are you sure you want to delete instructor ${instructor.field_first_name} ${instructor.field_last_name}?`
      }
    });

    dialogRef.afterClosed().subscribe(confirmed => {
      if (confirmed) {
        this.deleteInstructor(index);
      }
    });
  }

  populateInstructors(data: any[]) {
    data.forEach(instructor => {
      this.addInstructor(instructor);
    });

    // If there are no instructors, add one.
    if (data.length === 0) {
      this.addInstructor();
    }
  }

  updateUser(uid: string, userData: any): Observable<any> {
    return this.userService.updateUser(uid, userData);
  }
}
