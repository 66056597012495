import { Component, Input, OnInit } from '@angular/core';
import { UtilityService } from 'src/app/services/utility.service';

@Component({
  selector: 'app-instructor-list-dropdown',
  templateUrl: './instructor-list-dropdown.component.html',
  styleUrls: [
    './instructor-list-dropdown.component.css',
    '../../../../../app.component.css'
  ]
})
export class InstructorListDropdownComponent implements OnInit {

  // @Input() instructorList;
  instructorList;

  constructor(private _utilityService: UtilityService ) {}

  ngOnInit(): void {
    this._utilityService.getInstructorList().subscribe(data => {
      this.instructorList = data;
    })
  }

}
