import { Component, Input, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { ViewTableComponent } from 'src/app/components/base/view-table/view-table.component';

@Component({
  selector: 'app-non-unit-lessons',
  templateUrl: './non-unit-lessons.component.html',
  styleUrls: ['./non-unit-lessons.component.css']
})
export class NonUnitLessonsComponent extends ViewTableComponent implements OnInit {

  @Input() drupal_studentAccount_id: number;

  override displayedColumns: string[] = [
    // 'enrollmentID',
    // 'field_contacts',
    'field_date_and_time',
    'field_date_and_time_1',
    'field_type',
    'field_duration',
    'field_instructor',
    'field_status_1',
    'edit',
  ];

  // override ngOnInit(): void {
    // this.openEntityComponent(EnrollmentEntityComponent, 'packages', 'enrollment', 'create', null)
  // }

  override getData(params: { parameter: string; value: string; }[]) {
    // Show Progress Bar (Loading indicator)
    this.ShowProgressBar = true;
    this.queryParams = params;

    let override_params = [
      { parameter: 'field_category_target_id', value: 'Non-Unit' },
      { parameter: 'field_student_account_target_id', value: this.drupal_studentAccount_id+'' },
      { parameter: 'items_per_page', value: 'All' },
    ]

    // Add sorting paramaters, if available.
    if (this.sortParams !== undefined) {
      this.queryParams.push(this.sortParams)
    }

    // Load the new data with the filtering parameters.
    this._drupalRESTService.httpGET("/api_rest/v1/lesson-filter-view", this.queryParams.concat(override_params) )
      .subscribe((data) => {
        // ShowProgressBar
        this.ShowProgressBar = false;

        this.data = data;
        this.dataSource = new MatTableDataSource(data['results']);

        // Pagination
        if (data['results']) {
          this.paginator.length = data['count'];
          this.paginator.pageSize = this.pageSize;
          this.paginator.pageIndex = this.paginationIndex;
        }
      },
        error => this.handleError(error)
      )
  }



}
