import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, catchError, map, tap } from 'rxjs';
import { DrupalRESTService } from './drupal-rest.service';
import { EntityRESTService } from './entity-rest.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root'
})
export class PackagesService {
  private packagesSubject = new BehaviorSubject<any[]>([]);
  packages$ = this.packagesSubject.asObservable();

  constructor(
    private drupalRESTService: DrupalRESTService,
    private entityRESTService: EntityRESTService,
    private snackBar: MatSnackBar
  ) {
    this.fetchPackages();
  }

  fetchPackages() {
    this.drupalRESTService.httpGET('/api/v1/packages').subscribe(
      data => {
        const packages = data?.['results'] || [];
        this.packagesSubject.next(packages);
      },
      error => console.error('Error fetching packages:', error)
    );
  }

  addOrUpdatePackage(packageData: any) {
    if (packageData.id) {
      this.entityRESTService.patchEntity('packages', 'package', packageData.id, packageData).subscribe(
        () => this.fetchPackages(), // re-fetch packages to update the state
        error => console.error('Error updating package:', error)
      );
    } else {
      this.entityRESTService.createEntity('packages', 'package', packageData).subscribe(
        () => this.fetchPackages(), // re-fetch packages to update the state
        error => console.error('Error adding package:', error)
      );
    }
  }

  deletePackage(id: number): Observable<any> { // Notice the return type Observable<any>
    return this.entityRESTService.deleteEntity('packages', 'package', id).pipe(
      tap({
        next: () => this.fetchPackages(),
        error: error => {
          console.error('Error deleting package:', error);
          this.snackBar.open('Error deleting package.', 'Close', { duration: 3000 });
        }
      })
    );
  }

  manualUpdatePackages(newPackages: any[]) {
    this.packagesSubject.next(newPackages);
  }
}
