<h1 class="page-header">AMI Reports</h1>
<div class="container mt-5">
  <div class="row gx-3 gy-3 justify-content-center">
    <div class="col-3">
      <a [routerLink]="['/ami-staff-performance']" class="report-tile d-flex justify-content-center align-items-center">
        <div class="tile-content">
          <span class="material-icons">assessment</span>
          <span>Staff Performance Report</span>
        </div>
      </a>
    </div>
    <div class="col-3">
      <a [routerLink]="['/studio-business-summary']" class="report-tile d-flex flex-column justify-content-center align-items-center">
        <div class="tile-content">
          <span class="material-icons">business_center</span>
          <span>Studio Business Report</span>
        </div>
      </a>
    </div>
    <div class="col-3">
      <a [routerLink]="['/royalty-report']" class="report-tile d-flex flex-column justify-content-center align-items-center">
        <div class="tile-content">
          <span class="material-icons">account_balance</span>
          <span>Royalty Report</span>
        </div>
      </a>
    </div>
  </div>
  <div class="row gx-3 gy-3 mt-3 justify-content-center">
    <div class="col-3">
      <a [routerLink]="['/miscellaneous-report']" class="report-tile d-flex flex-column justify-content-center align-items-center">
        <div class="tile-content">
          <span class="material-icons">more_vert</span>
          <span>Miscellaneous Report</span>
        </div>
      </a>
    </div>
    <div class="col-3 mt-3">
      <a [routerLink]="['/students-inventory']" class="report-tile d-flex flex-column justify-content-center align-items-center">
        <div class="tile-content">
          <span class="material-icons">people</span>
          <span>Student Inventory</span>
        </div>
      </a>
    </div>
  </div>
</div>
