import { Component, OnInit } from '@angular/core';
import { UtilityService } from 'src/app/services/utility.service';

@Component({
  selector: 'app-schedules',
  templateUrl: './schedules.component.html',
  styleUrls: ['./schedules.component.css',
    '../../../../app.component.css'
  ]
})
export class SchedulesComponent implements OnInit {

  constructor() {}

  ngOnInit(): void {
  }

  onSubmit(f) {
  }

  standingAppointmentTitle: string = "Standing Schedule";

  schedule_type: {id: number, value: string}[] = [
    { id: 1374, value: "Break" },
    { id: 1375, value: "Calls" },
    { id: 1389, value: "Certification Prep" },
    { id: 1387, value: "Choreography" },
    { id: 1388, value: "Class Prep" },
    { id: 1376, value: "Dance Session" },
    { id: 1377, value: "Desk" },
    { id: 1378, value: "Meal Break" },
    { id: 1379, value: "Meeting" },
    { id: 1390, value: "One on One" },
    { id: 1380, value: "Out by Time off Request" },
    { id: 1381, value: "Out Sick" },
    { id: 1391, value: "Outside Event" },
    { id: 1382, value: "Regularly Schedule Out" },
    { id: 1392, value: "Schedule" },
    { id: 1383, value: "Training Class" },  
  ]

  

}
