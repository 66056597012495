import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormControl, FormArray, FormBuilder } from '@angular/forms';
import { HotTableComponent } from '@handsontable/angular';
import Handsontable from 'handsontable/base';
import { BaseComponent } from 'src/app/components/forms/base-form/base-form.component';
import { SimpleImportStudentAccountsComponent } from './simple-import-student-accounts/simple-import-student-accounts.component';

@Component({
  selector: 'app-simple-import',
  templateUrl: './simple-import.component.html',
  styleUrls: ['./simple-import.component.css']
})
export class SimpleImportComponent extends BaseComponent implements OnInit {
  @ViewChild(SimpleImportStudentAccountsComponent) studentAccountsComponent: SimpleImportStudentAccountsComponent;

  fb = new FormBuilder();

  instructorForm = this.fb.group({
    instructors: this.fb.array([])
  });

  ngOnInit(): void {
    this.addInstructor();
  }

  get instructorArray() {
    return this.instructorForm.get('instructors') as FormArray;
  }

  addInstructor() {
    const instructorGroup = this.fb.group({
      email: '',
      lastName: '',
      firstName: '',
      userName: '',
      amiId: ''
    });

    this.instructorArray.push(instructorGroup);
  }

  // Implement the onSubmit method to handle form submission
  override onSubmit() {
    console.log(this.instructorForm.value);
  }

}
