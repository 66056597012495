import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { BaseComponent } from 'src/app/components/forms/base-form/base-form.component';
import { LicenseAgreementDialogComponent } from 'src/app/components/utility/license-agreement-dialog/license-agreement-dialog.component';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent extends BaseComponent implements OnInit {

  override onSubmit(f: NgForm) {
    let values = f.form.value;

    this.displayProgressSpinner(true);

    this._authRESTService.login(values.name, values.pass)
      .subscribe(data => {
        console.log('data', data);
        if (data.success === false) {
          this.errorMessage = data.message || 'Authentication failed, please try again.';
        } else {
          this._authService.current_user = data?.['current_user'];

          // Store the CSRF token in localStorage
          this._authService.csrf_token = data['csrf_token'];

          this._authService.logout_token = data['logout_token'];
          this._authService.studios = data['studios'];

          this._menuRESTService.getMenu();

          // Check if the user has agreed to the license agreement
          console.log('field_license_agreed', data['field_license_agreed']);
          if (!data['field_license_agreed'] || data['field_license_agreed'] === '0') {
            this.openLicenseAgreementDialog();
          } else {
            this.navigateToDashboard();
          }
        }
        this.displayProgressSpinner(false);
      },
        error => this.handleError(error)
      );
  }

  openLicenseAgreementDialog(): void {
    const dialogRef = this._dialogService.openDialog(LicenseAgreementDialogComponent, 'fullScreenCreditCardInput');

    dialogRef.afterClosed().subscribe(result => {
      if (result === true) {
        // User agreed to the license
        const userId = this._authService.current_user?.['uid'];
        const updateData = {
          uid: userId,
          field_license_agreed: true,
          field_license_agreed_at: this.formatDateForMySQL(new Date()),
        };

        this._userRESTService.updateUser(userId, updateData).subscribe(
          response => {
            this.navigateToDashboard();
          },
          error => {
            this.errorMessage = "Failed to update user license agreement: " + error.message;
          }
        );
      } else {
        localStorage.removeItem('csrf_token');
        this._authService.set_user_authenticated(false);
        this._router.navigate(['/auth/login'], {queryParams: {logged_out: true}})
        this._authService.current_user = null;
        this.successMessage = null;
        this.errorMessage = "You must agree to the license agreement to proceed.";
      }
    });
  }

  navigateToDashboard(): void {
    this._authService.set_user_authenticated(true);
    this._fieldsService.getInstructorData();
    this._taxonomyService.initTaxonomyTerms();
    this._router.navigate(['/app/splash']);
  }

  ngOnInit(): void {
    this._activatedRoute.queryParams
      .subscribe(params => {
        console.log(params);
        if (params['logged_out']) {
          this.successMessage = "You have been logged out.";
        }
        if (params['logged_out_inactive']) {
          this.successMessage = "You have been logged out due to inactivity.";
        }
      });
  }

  formatDateForMySQL(date: Date): string {
    return date.getFullYear() + '-' +
           ('00' + (date.getMonth() + 1)).slice(-2) + '-' +
           ('00' + date.getDate()).slice(-2) + 'T' +
           ('00' + date.getHours()).slice(-2) + ':' +
           ('00' + date.getMinutes()).slice(-2) + ':' +
           ('00' + date.getSeconds()).slice(-2);
  }
}
