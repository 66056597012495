<div #conflictsContainer class="conflicting-event-card">
  <section *ngIf="conflictingEvent" class="conflicting-event-info" role="alert">
    <div class="row">
      <div class="col-5 conflicting-event-section">
        <h3 class="conflicting-event-title">
          <mat-icon color="warn" aria-label="Warning">warning</mat-icon>
          Conflicting Event:
        </h3>
        <ul>
          <li>Date: {{ conflictingEvent.field_date_and_time | date:'short' }}</li>
          <li>Instructor: {{ conflictingEvent.instructor }}</li>
          <li>Duration: {{ conflictingEvent.duration }}</li>
          <li>Type:
            <ng-container [ngSwitch]="conflictingEvent.bundle">
              <span *ngSwitchCase="'lesson'">Private Lesson</span>
              <span *ngSwitchCase="'group_lesson'">Group Lesson</span>
              <span *ngSwitchCase="'service'">Service</span>
              <span *ngSwitchCase="'schedule'">Schedule</span>
              <span *ngSwitchDefault>{{ conflictingEvent.bundle }}</span>
            </ng-container>
          </li>
        </ul>
      </div>
      <div class="col-7 alternative-time-section" *ngIf="alternativeTimes.length > 0">
        <h3>Alternative Times:</h3>
        <ul>
          <li *ngFor="let time of alternativeTimes; trackBy: trackByTime">
            <a (click)="updateFormWithAlternativeTime(time)" class="alternative-time" tabindex="0">
              {{ time.date | date:'short' }} ({{ time.duration }})
            </a>
          </li>
        </ul>
      </div>
    </div>
  </section>
</div>
