<div class="container-fluid">
    <h1 class="page-header">Royalty Report</h1>

    <app-report-nav-menu [week]="this.week" [year]="this.year" [currentReport]="this.currentReport"></app-report-nav-menu>

    <mat-expansion-panel>
        <mat-expansion-panel-header>
            <mat-panel-title>
                Filter
            </mat-panel-title>
            <mat-panel-description>
                <app-filter-results-panel [parameters]=queryParams></app-filter-results-panel>
            </mat-panel-description>
        </mat-expansion-panel-header>
        <form (ngSubmit)="onSubmit(f)" #f=ngForm class="form">
            <div class="row">

                <!-- Student Autocomplete -->
                <div class="col-2 d-none">
                    <mat-form-field class="full-width-field">
                        <mat-label>Student</mat-label>
                        <input #studentInput name="field_student_target_id" (input)="onAutocompleteStudent($event)" matInput ngModel
                            [matAutocomplete]="autoCompleteStudent">
                        <button (click)="redirectUserProfile(studentInput)" type="button" matSuffix mat-icon-button aria-label="manage_accounts">
                            <mat-icon>manage_accounts</mat-icon>
                        </button>
                    </mat-form-field>
                    <mat-autocomplete #autoCompleteStudent="matAutocomplete" (optionSelected)="onSubmit(f)">
                        <mat-option *ngFor="let option of autoCompleteStudentOptions"
                            [value]="option.label + ' ' + '(' + option.value + ')'">
                            {{option.label}} ({{option.value}})
                        </mat-option>
                    </mat-autocomplete>
                </div>

                <!-- Packages Autocomplete -->
                <div class="col-2 d-none">
                    <mat-form-field class="full-width-field">
                        <mat-label>Sale Package</mat-label>
                        <input name="field_enrollment_package_name_target_id" (input)="onAutocompletePackages($event)"
                            matInput ngModel [matAutocomplete]="autoCompletePackages">
                        <button type="button" matSuffix mat-icon-button aria-label="manage_accounts">
                            <mat-icon>manage_accounts</mat-icon>
                        </button>
                    </mat-form-field>
                    <mat-autocomplete #autoCompletePackages="matAutocomplete" (optionSelected)="onSubmit(f)">
                        <mat-option *ngFor="let option of autoCompletePackageOptions"
                            [value]="option.label + ' ' + '(' + option.value + ')'">
                            {{option.label}} ({{option.value}})
                        </mat-option>
                    </mat-autocomplete>
                </div>

                <div class="col-2 d-none">
                    <mat-form-field class="full-width-field">
                        <mat-label>Date Paid</mat-label>
                        <input matInput [matDatepicker]="picker" name="field_date_and_time_value" ngModel
                            (dateChange)="onSubmit(f)">
                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                        <mat-datepicker #picker></mat-datepicker>
                    </mat-form-field>
                </div>

                <div class="col-3">
                    <mat-form-field class="full-width-field">
                        <mat-label>Week</mat-label>
                        <mat-select [ngModel]=week matInput name="week" (selectionChange)="onSubmit(f)">
                            <!-- <mat-option>None</mat-option> -->
                            <mat-option *ngFor="let element of WeeksForYearData" [value]="element.key">
                                {{ element.value }}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>

                <div class="col-3">
                    <mat-form-field class="full-width-field">
                        <mat-label>Year</mat-label>
                        <mat-select
                          matInput
                          [ngModel]="year"
                          name="year"
                          (selectionChange)="onYearChange($event); onSubmit(f)">
                          <mat-option>None</mat-option>
                          <mat-option *ngFor="let element of LastFourYearsData" [value]="element.key">
                            {{ element.value }}
                          </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>

                <section class="section">

                    <div class="float-end bring-inline">
                        <button (click)="resetForm()" color="primary" mat-flat-button class="me-1" mat-button>Clear
                            <!-- <mat-icon (click)="resetForm()" aria-hidden="false" aria-label="Menu dropdown" class=""></mat-icon> -->
                        </button>
                    </div>

                </section>
            </div>


            <section class="section">
            </section>

        </form>

    </mat-expansion-panel>

    <mat-progress-bar *ngIf="ShowProgressBar" mode="indeterminate"></mat-progress-bar>
    <div class="row">
        <div class="col-md-12 mt-3">
            <div class="float-end">
                <button
                [disabled]="this.isDisabled"
                class="me-1"
                mat-raised-button color="primary"
                (click)="openDialog(SendUpdateAPIComponent, null, {
                    endpoint: 'royalty_report_send_api',
                    operation: 'Send API',
                    form: this.f.form.value
                });">Send API</button>
                <button
                [disabled]="this.isDisabled"
                class="me-1"
                mat-raised-button color="primary"
                (click)="openDialog(SendUpdateAPIComponent, null, {
                    endpoint: 'royalty_report_send_api',
                    operation: 'Update API',
                    form: this.f.form.value
                });">Update API</button>

                <button
                    [routerLink]="['/ami-reports']"
                    class="me-1"
                    mat-raised-button color="secondary"
                    >Back to list</button>
            </div>
        </div>
    </div>

    <table class="mat-table cdk-table mat-sort mt-4" role="table"
        *ngIf="data != undefined && data['#royalty_header_table'] != undefined">
        <thead role="rowgroup">
            <tr>
                <th class="mat-header-cell">Report Type</th>
                <th class="mat-header-cell">Prepared By</th>
                <th class="mat-header-cell">Year</th>
                <th class="mat-header-cell">Week Number</th>
                <th class="mat-header-cell">Date Range</th>
            </tr>
        </thead>
        <tr>
            <td class="mat-row">{{ data['#royalty_header_table']['royalty_data_header']['#rows'][0][0] }}</td>
            <td class="mat-row">{{ data['#royalty_header_table']['royalty_data_header']['#rows'][0][1] }}</td>
            <td class="mat-row">{{ data['#royalty_header_table']['royalty_data_header']['#rows'][0][2] }}</td>
            <td class="mat-row">{{ data['#royalty_header_table']['royalty_data_header']['#rows'][0][3] }}</td>
            <td class="mat-row">{{ data['#royalty_header_table']['royalty_data_header']['#rows'][0][5] || '' | date }} - {{ data['#royalty_header_table']['royalty_data_header']['#rows'][0][6] | date }}</td>
        </tr>
    </table>


    <div class="table-container">
        <mat-card *ngIf="this.royaltyDataRefunds?.[0]">
            <mat-card-title>
            Refunds
            </mat-card-title>

            <mat-card-content>

            <table class="mat-table cdk-table mat-sort mt-4" role="table"
                *ngIf="data != undefined && this.royaltyDataRefunds?.[0] != undefined">
                <thead role="rowgroup">
                    <tr>
                        <th class="mat-header-cell">Receipt Number</th>
                        <th class="mat-header-cell">Date Refunded</th>
                        <th class="mat-header-cell">Student Name</th>
                        <!-- <th class="mat-header-cell">Executive Member</th> -->
                        <!-- <th class="mat-header-cell">Staff Member</th> -->
                        <!-- <th class="mat-header-cell">Sale Code</th> -->
                        <!-- <th class="mat-header-cell">Sale Package</th> -->
                        <!-- <th class="mat-header-cell">Lesson Count</th> -->
                        <!-- <th class="mat-header-cell">Total Price</th> -->
                        <!-- <th class="mat-header-cell">Cash</th> -->
                        <!-- <th class="mat-header-cell">Misc Services</th> -->
                        <!-- <th class="mat-header-cell">Subject to Royalty</th> -->
                        <!-- <th class="mat-header-cell">Sundry</th> -->
                        <th class="mat-header-cell">Total</th>
                    </tr>
                </thead>
                <tr *ngFor="let refund of this.royaltyDataRefunds ; index as i;">
                    <td class="mat-row">{{ this.royaltyDataRefunds[i]['receipt_number'] }}</td>
                    <td class="mat-row">{{ this.royaltyDataRefunds[i]['date_paid'] || '' | date }}</td>
                    <td class="mat-row">{{ this.royaltyDataRefunds[i]['student_name'] }}</td>
                    <!-- <td class="mat-row">{{ this.royaltyDataRefunds[i]['field_executive_display'] }}</td> -->
                    <!-- <td class="mat-row">{{ this.royaltyDataRefunds[i]['field_instructor_display'] }}</td> -->
                    <!-- <td class="mat-row">{{ this.royaltyDataRefunds[i]['sale_code'] }}</td> -->
                    <!-- <td class="mat-row">{{ this.royaltyDataRefunds[i]['package_name'] }}</td> -->
                    <!-- <td class="mat-row">{{ this.royaltyDataRefunds[i]['lesson_count'] }}</td> -->
                    <!-- <td class="mat-row">{{ this.royaltyDataRefunds[i]['total_price'] }}</td> -->
                    <!-- <td class="mat-row">${{ this.royaltyDataRefunds[i]['cash'] }}</td> -->
                    <!-- <td class="mat-row">${{ this.royaltyDataRefunds[i]['miscellaneous_services'] }}</td> -->
                    <!-- <td class="mat-row">${{ this.royaltyDataRefunds[i]['subject_to_royalty'] }}</td> -->
                    <!-- <td class="mat-row">${{ this.royaltyDataRefunds[i]['sundry'] }}</td> -->
                    <td class="mat-row">${{ this.royaltyDataRefunds[i]['total_receipts'] }}</td>
                </tr>
            </table>

            </mat-card-content>
        </mat-card>

        <!-- All Payments Table  -->
        <mat-card>
            <mat-card-title>
                All Receipts
            </mat-card-title>

            <mat-card-content>
                <table mat-table #displayTable [dataSource]="dataSource" matSort (matSortChange)="sortData($event)" class="mt-4">

                    <!-- cash Column -->
                    <ng-container matColumnDef="cash">
                        <th mat-header-cell *matHeaderCellDef sortActionDescription="Sort by cash">
                            Cash
                        </th>
                        <td mat-cell *matCellDef="let element">
                            <ng-container>${{ element.cash }}</ng-container>
                        </td>
                    </ng-container>

                    <!-- date_paid Column -->
                    <ng-container matColumnDef="date_paid">
                        <th mat-header-cell *matHeaderCellDef sortActionDescription="Sort by date_paid">
                            Date Paid
                        </th>
                        <td mat-cell *matCellDef="let element">
                            <ng-container>{{ element.date_paid || '' | date  }}</ng-container>
                        </td>
                    </ng-container>

                    <!-- executive Column -->
                    <ng-container matColumnDef="executive">
                        <th mat-header-cell *matHeaderCellDef sortActionDescription="Sort by executive">
                            Executive Member
                        </th>
                        <td mat-cell *matCellDef="let element">
                            <ng-container>{{ element.field_executive_display }}</ng-container>
                        </td>
                    </ng-container>

                    <!-- instructor Column -->
                    <ng-container matColumnDef="instructor">
                        <th mat-header-cell *matHeaderCellDef sortActionDescription="Sort by executive">
                            Staff Member
                        </th>
                        <td mat-cell *matCellDef="let element">
                            <ng-container>{{ element.field_instructor_display }}</ng-container>
                        </td>
                    </ng-container>

                    <!-- lesson_count Column -->
                    <ng-container matColumnDef="lesson_count">
                        <th mat-header-cell *matHeaderCellDef sortActionDescription="Sort by lesson_count">
                            Lesson Count
                        </th>
                        <td mat-cell *matCellDef="let element">
                            <ng-container>{{ element.lesson_count }}</ng-container>
                        </td>
                    </ng-container>

                    <!-- miscellaneous_services Column -->
                    <ng-container matColumnDef="miscellaneous_services">
                        <th mat-header-cell *matHeaderCellDef sortActionDescription="Sort by miscellaneous_services">
                            <span style="white-space:nowrap">Misc/Non Unit</span>
                        </th>
                        <td mat-cell *matCellDef="let element">
                            <ng-container>${{ element.miscellaneous_services }}</ng-container>
                        </td>
                    </ng-container>

                    <!-- package_name Column -->
                    <ng-container matColumnDef="package_name">
                        <th mat-header-cell *matHeaderCellDef sortActionDescription="Sort by package_name">
                            Sale Package
                        </th>
                        <td mat-cell *matCellDef="let element">
                            <ng-container>{{ element.package_name }}</ng-container>
                        </td>
                    </ng-container>

                    <!-- receipt_number Column -->
                    <ng-container matColumnDef="receipt_number">
                        <th mat-header-cell *matHeaderCellDef sortActionDescription="Sort by receipt_number">
                            Receipt Number
                        </th>
                        <td mat-cell *matCellDef="let element">
                            <ng-container>{{ element.receipt_number }}</ng-container>
                        </td>
                    </ng-container>

                    <!-- sale_code Column -->
                    <ng-container matColumnDef="sale_code">
                        <th mat-header-cell *matHeaderCellDef sortActionDescription="Sort by sale_code">
                            Sale Code
                        </th>
                        <td mat-cell *matCellDef="let element">
                            <ng-container>{{ element.sale_code }}</ng-container>
                        </td>
                    </ng-container>

                    <!-- student_name Column -->
                    <ng-container matColumnDef="student_name">
                        <th mat-header-cell *matHeaderCellDef sortActionDescription="Sort by student_name">
                            Student Name
                        </th>
                        <td mat-cell *matCellDef="let element">
                            <ng-container>{{ element.student_name }}</ng-container>
                        </td>
                    </ng-container>

                    <!-- field_tax_amount Column -->
                    <ng-container matColumnDef="field_tax_amount">
                        <th mat-header-cell *matHeaderCellDef sortActionDescription="Sort by Tax">
                        Tax
                        </th>
                        <td mat-cell *matCellDef="let element">
                        <ng-container>${{ element.field_tax_collected }}</ng-container>
                        </td>
                    </ng-container>

                    <!-- subject_to_royalty Column -->
                    <ng-container matColumnDef="subject_to_royalty">
                        <th mat-header-cell *matHeaderCellDef sortActionDescription="Sort by subject_to_royalty">
                            Subject to Royalty
                        </th>
                        <td mat-cell *matCellDef="let element">
                            <ng-container>${{ element.subject_to_royalty }}</ng-container>
                        </td>
                    </ng-container>

                    <!-- sundry Column -->
                    <ng-container matColumnDef="sundry">
                        <th mat-header-cell *matHeaderCellDef sortActionDescription="Sort by sundry">
                            Sundry
                        </th>
                        <td mat-cell *matCellDef="let element">
                            <ng-container>${{ element.sundry }}</ng-container>
                        </td>
                    </ng-container>

                    <!-- total_price Column -->
                    <ng-container matColumnDef="total_price">
                        <th mat-header-cell *matHeaderCellDef sortActionDescription="Sort by total_price">
                            Total Price
                        </th>
                        <td mat-cell *matCellDef="let element">
                            <ng-container>${{ element.total_price }}</ng-container>
                        </td>
                    </ng-container>

                    <!-- total_receipts Column -->
                    <ng-container matColumnDef="total_receipts">
                        <th mat-header-cell *matHeaderCellDef sortActionDescription="Sort by total_receipts">
                            Total Receipts
                        </th>
                        <td mat-cell *matCellDef="let element">
                            <ng-container>${{ element.total_receipts }}</ng-container>
                        </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row matRipple *matRowDef="let row; columns: displayedColumns;"></tr>


                </table>
            </mat-card-content>
        </mat-card>

        <!-- Totals Summary Table  -->
        <mat-card>
            <mat-card-title>
            Summary
            </mat-card-title>

            <mat-card-content>
                <table class="mat-table cdk-table mat-sort mt-4" role="table"
                    *ngIf="data != undefined && data['#royalty_header_table'] != undefined">
                    <thead role="rowgroup">
                        <tr>
                            <th class="mat-header-cell col-9"></th>
                            <th class="mat-header-cell col-1">Cash</th>
                            <th class="mat-header-cell col-1">Misc Services</th>
                            <th class="mat-header-cell col-1">Subject to Royalty</th>
                            <th class="mat-header-cell col-1">Sundry</th>
                            <th class="mat-header-cell col-1">Total Reciepts</th>
                        </tr>
                    </thead>
                    <!-- Total Subject To Percentage -->
                    <tr>
                        <td class="mat-row">Totals</td>
                        <td class="mat-row">${{ data['#royalty_content_table']['staff_data_4_system']['#footer'][0].data.total['cash'] }}</td>
                        <td class="mat-row">${{ data['#royalty_content_table']['staff_data_4_system']['#footer'][0].data.total['miscellaneous_services'] }}</td>
                        <td class="mat-row">${{ data['#royalty_content_table']['staff_data_4_system']['#footer'][0].data.total['subject_to_royalty'] }}</td>
                        <td class="mat-row">${{ data['#royalty_content_table']['staff_data_4_system']['#footer'][0].data.total['sundry'] }}</td>
                        <td class="mat-row">${{ data['#royalty_content_table']['staff_data_4_system']['#footer'][0].data.total['total_receipts'] }}</td>
                    </tr>
                    <!-- Deduct Refunds/Credits -->
                    <tr>
                        <td class="mat-row">Deduct Refunds/Credits</td>
                        <!-- <td class="mat-row">${{ data['#royalty_content_table']['staff_data_4_system']['#footer'][0].data.total_refunded['total_price'] }}</td> -->
                        <td class="mat-row">${{ data['#royalty_content_table']['staff_data_4_system']['#footer'][0].data.total_refunded['cash'] }}</td>
                        <td class="mat-row">${{ data['#royalty_content_table']['staff_data_4_system']['#footer'][0].data.total_refunded['miscellaneous_services'] }}</td>
                        <td class="mat-row">${{ data['#royalty_content_table']['staff_data_4_system']['#footer'][0].data.total_refunded['subject_to_royalty'] }}</td>
                        <td class="mat-row">${{ data['#royalty_content_table']['staff_data_4_system']['#footer'][0].data.total_refunded['sundry'] }}</td>
                        <td class="mat-row">${{ data['#royalty_content_table']['staff_data_4_system']['#footer'][0].data.total_refunded['total_receipts'] }}</td>
                    </tr>
                    <!-- Total Subject To Percentage -->
                    <tr>
                        <td class="mat-row">Total Subject To Percentage</td>
                        <td class="mat-row">${{ data['#royalty_content_table']['staff_data_4_system']['#footer'][0].data.total_include_refunded['cash'] }}</td>
                        <td class="mat-row">${{ data['#royalty_content_table']['staff_data_4_system']['#footer'][0].data.total_include_refunded['miscellaneous_services'] }}</td>
                        <td class="mat-row">${{ data['#royalty_content_table']['staff_data_4_system']['#footer'][0].data.total_include_refunded['subject_to_royalty'] }}</td>
                        <td class="mat-row">$0</td>
                        <td class="mat-row">${{ data['#royalty_content_table']['staff_data_4_system']['#footer'][0].data.total_include_refunded['total_receipts'] }}</td>
                    </tr>
                    <!-- Royalty Percentage -->
                    <tr>
                        <td class="mat-row">Weekly Net Due ({{(this.royaltyPercentage)}}%)</td>
                        <td class="mat-row">${{ calcRoyaltyPercentage(data['#royalty_content_table']['staff_data_4_system']['#footer'][0].data.total_include_refunded['cash']) | number: '1.0-2' }}</td>
                        <td class="mat-row">${{ calcRoyaltyPercentage(data['#royalty_content_table']['staff_data_4_system']['#footer'][0].data.total_include_refunded['miscellaneous_services']) | number: '1.0-2' }}</td>
                        <td class="mat-row">${{ calcRoyaltyPercentage(data['#royalty_content_table']['staff_data_4_system']['#footer'][0].data.total_include_refunded['subject_to_royalty']) | number: '1.0-2' }}</td>
                        <td class="mat-row">$0</td>
                        <td class="mat-row">${{ calcRoyaltyPercentage(data['#royalty_content_table']['staff_data_4_system']['#footer'][0].data.total_include_refunded['total_receipts']) | number: '1.0-2' }}</td>
                    </tr>
                </table>
            </mat-card-content>

            <ng-container *ngIf="this.pageSize == 0">
                <div>
                    Please select criteria to generate a report.
                </div>
            </ng-container>
        </mat-card>

        <p style="margin-bottom:100px; height:100px;">&nbsp;</p>

        <!-- <mat-paginator (page)="pageChanged($event)" [pageSizeOptions]="onPageSizeOptions()" [length]="10"
        [disabled]="ShowProgressBar"
        [pageSize]="paginationTotalPages" [pageIndex]="paginationIndex" aria-label="Select page of view"></mat-paginator> -->

    </div>
</div>
