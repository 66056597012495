import { Component, Input, Output, EventEmitter, ElementRef, ViewChild } from '@angular/core';
import { debounceTime, Subject } from 'rxjs';
import { DrupalRESTService } from 'src/app/services/drupal-rest.service';

@Component({
  selector: 'app-schedule-conflicts',
  templateUrl: './schedule-conflicts.component.html',
  styleUrls: ['./schedule-conflicts.component.css']
})
export class ScheduleConflictsComponent {
  @ViewChild('conflictsContainer') conflictsContainer: ElementRef;
  @Input() dateTime: string;
  @Input() instructor: string;
  @Input() lessonType: string;
  @Input() duration: string;
  @Input() entityId: string;
  @Output() dateTimeChange = new EventEmitter<string>();
  @Output() conflictFound = new EventEmitter<void>();

  conflictingEvent: any = null;
  alternativeTimes: any[] = [];

  private conflictsSubject = new Subject<void>();

  constructor(private _drupalRESTService: DrupalRESTService) {
    this.conflictsSubject.pipe(
      debounceTime(300) // Adjust the debounce time as needed
    ).subscribe(() => {
      this.fetchScheduleConflicts();
    });
  }

  checkScheduleConflicts() {
    this.conflictsSubject.next();
  }

  private async fetchScheduleConflicts() {
    // If field_date_and_time is empty, don't make the request
    if (!this.dateTime) {
      return;
    }
    const response = await this._drupalRESTService.httpGET(`/api_rest/v1/checkScheduleConflicts?field_duration=${this.duration}&field_date_and_time=${this.dateTime}&field_instructor=${this.instructor}`).toPromise();
    if (response?.['success']) {
      this.conflictingEvent = null;
      this.alternativeTimes = [];
    } else {
      const conflicts = response?.['conflicts']?.filter(conflict => conflict.id !== this.entityId);
      console.log('Conflicts:', conflicts);
      console.log('Entity ID:', this.entityId);
      this.conflictingEvent = conflicts.length > 0 ? conflicts[0] : null;
      this.alternativeTimes = response?.['alternativeTimes'] || [];
      this.conflictFound.emit();
    }
  }

  updateFormWithAlternativeTime(time: any) {
    this.dateTimeChange.emit(time.date);
    this.conflictingEvent = null;
  }

  trackByTime(index: number, time: any): string {
    return time.date;
  }
}
