import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import moment from 'moment';
import { ViewTableComponent } from '../../base/view-table/view-table.component';
import { HttpParameter } from 'src/app/types/http-parameter';

@Component({
  selector: 'app-payments-due',
  templateUrl: './payments-due.component.html',
  styleUrls: ['./payments-due.component.css']
})
export class PaymentsDueComponent extends ViewTableComponent implements OnInit {

  currentPage: number;

  override displayedColumns: string[] = [
    'field_payment_date_value',
    // 'id',
    'field_student_target_id',
    'title',
    'field_balance_due_value',
    'field_payment_amount_value',
    // 'field_payment_interval',
    'field_lesson_available_value',
    // 'field_enrollment_lesson_count',
    // 'field_enrollment_lesson_price',
    // 'field_lesson_used',
    // 'field_lessons_paid',
    'field_instructor_target_id',
    // 'field_lesson_type_label',
    // 'field_sps_code_label',
  ];

  override getData(params: HttpParameter[]) {
    this.ShowProgressBar = true;
    this.queryParams = params;

    // Add sorting paramaters, if available.
    if (this.sortParams !== undefined) {
      this.queryParams.push(this.sortParams)
    }

    params = [
      ...params,
    ];

    // Alter start_date and end_date params for Drupal.
    let start_date;
    let end_date;
    let field_next_scheduled_payment;

    // Process the params for start and end dates.
    params = this.processParams(params);

    // Load the new data with the filtering parameters.
    let endpoint: string = "/api/v1/reports/enrollments-payments-due";

    this._drupalRESTService.httpGET(endpoint, params)
      .subscribe((data) => {
        // ShowProgressBar
        this.ShowProgressBar = false;

        this.dataSource = new MatTableDataSource(data['results']);

        // // Pagination
        if (data['results']) {
          this.paginator.length = data['count'];
          this.paginator.pageSize = this.pageSize;
          this.paginator.pageIndex = this.paginationIndex;
        }
      },
        error => this.handleError(error)
      )
  }
}
