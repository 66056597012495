import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import moment from 'moment';
import { BaseDialogComponent } from '../base-dialog/base-dialog.component';

@Component({
  selector: 'app-cancel-reschedule',
  templateUrl: './cancel-reschedule.component.html',
  styleUrls: ['./cancel-reschedule.component.css']
})
export class CancelRescheduleComponent extends BaseDialogComponent implements OnInit {

  override entityId = this._injectedDialogData['data']['entityID'];
  appointmentType = this._injectedDialogData['data']['appointmentType'];
  field_date_and_time_default_time = [new Date().getHours(), 0 , 0]

  override onSubmit(form: NgForm) {
    let values = form.form.value;

    console.log('values.field_date_and_time', values.field_date_and_time);

    // Build request...
    let params: { parameter: any, value: any }[] = [];
    let rsDate = (values.field_date_and_time != '' ? moment(values.field_date_and_time).format("YYYY-MM-DD") : null)
    let rsTime = (values.field_date_and_time != '' ? moment(values.field_date_and_time).format("HH:mm") : null)

    // console.log('params');
    // console.log(params);

    console.log('rsDate', rsDate)
    console.log('rsTime', rsTime)

    // Reschedule the appointment.
    if (rsDate && rsTime && rsDate != 'Invalid date' && rsTime != 'Invalid date') {
      console.log('Rescheduling event...')

      // Add params the endpoint is expecting.
      params = [
        { parameter: 'rsDate', value: rsDate ?? '' }, // '2022-02-21' },
        { parameter: 'rsTime', value: rsTime ?? '' }, // '14:00' },
        { parameter: 'eventId', value: this.entityId },
      ];

      this._drupalRESTService.httpGET('/api_rest/v1/updateEvent', params)
        .subscribe(data => {
          console.log(data)
          if (data['success']) {

            if (this.appointmentType == 'services') {
              let body = {
                field_status: 111, // this is the ID for rescheduled.
              }
              this._entityRESTService.patchEntity('events', this.appointmentType, this.entityId, body).subscribe(data => {
                this._dialogService.dialog.closeAll();
              })
            }

            if (this.appointmentType == 'lesson') {
              params = [
                { parameter: 'selectedStatus[' + this._injectedDialogData['data']['eventData']['studentsDashboardLinks'][0]['attendees_id'] + ']', value: 62 },
                { parameter: 'dateTime', value: moment().format('YYYY-MM-DD[T]HH:mm:ss') },
                { parameter: 'eventId', value: this.entityId },
              ];

              // Add the cancellation reason to the params
              if (values.field_reason_for_canceling) {
                params = [
                  ...params,
                  { parameter: 'field_reason_for_canceling', value: values.field_reason_for_canceling },
                ];
              }

              this._drupalRESTService.httpGET('/api_rest/v1/updateStatus', params)
              .subscribe(data => {
                console.log(data)
                if (data['success']) {
                  this.successMessage = 'Status updated for the selected event.';

                  this._dialogService.dialog.closeAll();
                }
                if (data['message']) {
                  this.errorMessage = data['message'];
                }
              });
            }


            this.closeDialog();
          }
          if (data['message']) {
            this.errorMessage = data['message'];
          }
        });
    }

    // Cancel the appointment.
    if (!rsDate && !rsTime) {
      console.log('Canceling event...')

      // Handle different cancel status for different event types.
      let eventCancelledStatus:number;
      switch (this.appointmentType) {
        case 'services':
          eventCancelledStatus = 110;
          break;

        case 'lesson':
          eventCancelledStatus = 63;
          break;
      }

      // Add params the endpoint is expecting.
      params = [
        { parameter: 'dateTime', value: moment().format('YYYY-MM-DD[T]HH:mm:ss') },
        { parameter: 'eventId', value: this.entityId },
        { parameter: 'eventStatus', value: eventCancelledStatus },
      ];

      // Add the cancellation reason to the params
      if (values.field_reason_for_canceling) {
        params = [
          ...params,
          { parameter: 'field_reason_for_canceling', value: values.field_reason_for_canceling },
        ];
      }

      if (this._injectedDialogData['data']['eventData']['studentsDashboardLinks']?.[0]?.attendees_id) {
        params = [
          ...params,
          { parameter: 'selectedStatus[' + this._injectedDialogData['data']['eventData']['studentsDashboardLinks'][0].attendees_id + ']', value: 63 },
          // { parameter: 'selectedStatus[' + '' + ']', value: '' },
        ]
      }

      this._drupalRESTService.httpGET('/api_rest/v1/updateStatus', params)
        .subscribe(data => {
          console.log(data)
          if (data['success']) {
            this._dialogService.dialog.closeAll();
          }
          if (data['message']) {
            this.errorMessage = data['message'];
          }
        });
    }

  }

}
