<div class="container-fluid">
    <h1 class="page-header">Chat Success Rate</h1>

    <mat-expansion-panel>
        <mat-expansion-panel-header>
            <mat-panel-title>
                Filter
            </mat-panel-title>
            <mat-panel-description>
                <app-filter-results-panel [parameters]=queryParams></app-filter-results-panel>
            </mat-panel-description>
        </mat-expansion-panel-header>
        <form (ngSubmit)="onSubmit(f)" #f=ngForm class="form">
            <div class="row">

                <div class="col-3">
                <mat-form-field class="full-width-field">
                    <mat-label>Service Type</mat-label>
                    <input
                    matInput
                    ngModel
                    name="service_type"
                    (blur)="onSubmit(f)"
                    (selectionChange)="onSubmit(f)">
                </mat-form-field>
                </div>

                <div class="col-3">
                    <mat-form-field class="full-width-field">
                        <mat-label>From</mat-label>
                        <input matInput [matDatepicker]="pickerStart" name="start_date" ngModel (dateChange)="onSubmit(f)">
                        <mat-datepicker-toggle matSuffix [for]="pickerStart"></mat-datepicker-toggle>
                        <mat-datepicker #pickerStart></mat-datepicker>
                    </mat-form-field>
                </div>

                <div class="col-3">
                    <mat-form-field class="full-width-field">
                        <mat-label>To</mat-label>
                        <input matInput [matDatepicker]="pickerEnd" name="end_date" ngModel (dateChange)="onSubmit(f)">
                        <mat-datepicker-toggle matSuffix [for]="pickerEnd"></mat-datepicker-toggle>
                        <mat-datepicker #pickerEnd></mat-datepicker>
                    </mat-form-field>
                </div>

                <div class="col-3">
                    <mat-form-field class="full-width-field">
                        <mat-label>Role:</mat-label>
                        <mat-select name="type" ngModel (selectionChange)="onSubmit(f)">
                            <mat-option value="">All</mat-option>
                            <mat-option value="active">Active</mat-option>
                            <mat-option value="inactive">Inactive</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>


                <section class="section">

                    <div class="float-end bring-inline">
                        <button (click)="resetForm()" color="primary" mat-flat-button class="me-1" mat-button>Clear
                            <!-- <mat-icon (click)="resetForm()" aria-hidden="false" aria-label="Menu dropdown" class=""></mat-icon> -->
                        </button>
                    </div>

                </section>


            </div>


            <section class="section">
            </section>

        </form>

    </mat-expansion-panel>

    <mat-progress-bar *ngIf="ShowProgressBar" mode="indeterminate"></mat-progress-bar>

    <div class="table-container">


        <ng-container *ngIf="this.queryParams.length == 0 && !this.data">
            <div>
            Please select criteria to view a report.
            </div>
        </ng-container>


        <table mat-table #displayTable [dataSource]="dataSource" matSort (matSortChange)="sortData($event)" class="mt-4">

            <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef sortActionDescription="Sort by name">
                    Name
                </th>
                <td mat-cell *matCellDef="let element"> {{element[0]?.data ? element[0].data : ''}} </td>
            </ng-container>

            <ng-container matColumnDef="role">
                <th mat-header-cell *matHeaderCellDef sortActionDescription="Sort by role">
                    Role
                </th>
                <td mat-cell *matCellDef="let element"> {{element[1]?.data ? element[1].data : ''}} </td>
            </ng-container>

            <ng-container matColumnDef="service_type">
                <th mat-header-cell *matHeaderCellDef sortActionDescription="Sort by service_type">
                    Service Type
                </th>
                <td mat-cell *matCellDef="let element"> {{element[2]?.data ? element[2].data : '' }} </td>
            </ng-container>

            <ng-container matColumnDef="success_rate">
                <th mat-header-cell *matHeaderCellDef sortActionDescription="Sort by success_rate">
                    Success Rate
                </th>
                <td mat-cell *matCellDef="let element"> {{ element[3]?.data ? element[3].data : ''}} </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row matRipple *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>

        <!-- <mat-paginator (page)="pageChanged($event)" [pageSizeOptions]="onPageSizeOptions()" [length]="30"
            [disabled]="ShowProgressBar"
            [pageSize]="paginationTotalPages" [pageIndex]="paginationIndex" aria-label="Select page of view">
        </mat-paginator> -->

    </div>
</div>
