import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import moment from 'moment';
import { ViewTableComponent } from '../../base/view-table/view-table.component';
import { StudentDashboardWrapperComponent } from '../../student/student-dashboard-wrapper/student-dashboard-wrapper.component';
import { FrmManageStaffComponent } from '../../forms/frm-manage-staff/frm-manage-staff.component';
import { ComponentType } from '@angular/cdk/overlay';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';

@Component({
  selector: 'app-staff-management',
  templateUrl: './staff-management.component.html',
  styleUrls: ['./staff-management.component.css']
})
export class StaffManagementComponent extends ViewTableComponent implements OnInit {

  currentPage: number;
  // StudentDashboardWrapperComponent = StudentDashboardWrapperComponent;
  frmManageStaffComponent = FrmManageStaffComponent


  links = [
    { label: 'Active', path: '/configuration/staff-management', queryParams: { status: '1' } },
    { label: 'Inactive', path: '/configuration/staff-management', queryParams: { status: '0' } }
  ];

  activeLink = this.links[0];

  override displayedColumns: string[] = [
    'field_first_name',
    'field_email',
    'field_cell_number',
    'field_status',
    'field_access',
    'created',
    'roles_target_id',
    'edit',
  ];

  override ngOnInit() {
    this.activeLink = this.links[0]; // Set the default active link

    this._activatedRoute.queryParams.subscribe(params => {
      const status = params['status'];
      if (status === '1') {
        this.activeLink = this.links[0];
      } else if (status === '0') {
        this.activeLink = this.links[1];
      }

      const queryParams = status ? [{ parameter: 'status', value: status }] : [];
      this.getData(queryParams);
    });
  }

  override getData(params: { parameter: string; value: string; }[]) {
    this.ShowProgressBar = true;
    this.queryParams = params;

    let filters = '?sort_order=ASC&sort_by=field_last_name_value';
    if (params) {
      for (const param of params) {
        if (param.parameter === 'status') {
          filters += `&status=${param.value}`;
        }
      }
    }

    // Load the data with the filtering parameters
    const endpoint: string = "/api/v1/instructor_list" + filters;

    this._drupalRESTService.httpGET(endpoint, params)
      .subscribe(
        (data) => {
          this.ShowProgressBar = false;
          this.dataSource = new MatTableDataSource(data['results']);
          if (data['results']) {
            this.setupPagination(data, params);
          }
        },
        error => this.handleError(error)
      );
  }

  setStudentArchive(student_account_id: number, value: boolean) {
    let body = {
      field_archive: '' + value,
    };
    let setStudentArchive = this._entityRESTService.patchEntity('student_accounts', 'student_account', student_account_id, body);
    setStudentArchive.subscribe(data => {
      console.log('data', data);
      this.getData(this.queryParams);
    })
  }



  /**
   * Utility to open dialogs with a default configuration.
   *
   * @param component Component to be opened in the dialog.
   * @param uid Unique identifier for the staff member.
   * @param dialogConfig Configuration for the dialog.
   */
  openStaffDialog(component: ComponentType<unknown>, uid: number, action: string, dialogConfig: { height: string, width: string, closeOnNavigation?: boolean, data?: {} } = { height: "auto", width: "600px", closeOnNavigation: true }) {
    // Include the UID and action in the dialog's data
    dialogConfig.data = { ...dialogConfig.data, uid, action };

    let dialogRef = this._dialogService.openDialog(component, "defaultWithData", dialogConfig).afterClosed().subscribe(data => {
      // Handle actions after the dialog is closed, if necessary
      this.getData(this.queryParams);
    });
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.dataSource.data, event.previousIndex, event.currentIndex);
    this.dataSource = new MatTableDataSource(this.dataSource.data);

    // Prepare data to send
    const updatedOrder = this.dataSource.data.map(item => item?.['uid']);
    console.log("updatedOrder",updatedOrder)
    // Send POST request
    this._drupalRESTService.httpPOST('/api_rest/v1/updateInstructorSortOrder', { instructors: updatedOrder })
      .subscribe(response => {
        console.log(response);
        // Handle response here
      }, error => {
        console.error('Error updating instructor order', error);
      });
  }

}

