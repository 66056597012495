<!-- student-inquiry-entity.component.html -->
<app-entity-mat-dialog-title
    [title]="(_inquiry ? 'Student Inquiry' : 'Create Student Account')"></app-entity-mat-dialog-title>

<div [ngClass]="{
        'loading-shade': showProgressSpinner
     }">

    <div mat-dialog-content>

        <mat-progress-spinner class="matProgressSpinnerCentered"
            *ngIf="showProgressSpinner"
            mode="indeterminate"></mat-progress-spinner>

        <mat-tab-group [(selectedIndex)]="this.selectedIndex"
            (selectedTabChange)="setFormMode($event.index)"
            animationDuration="0ms" #tabs>

            <mat-tab label="View" [disabled]="this.initActionType == 'create'">
                <app-entity-mat-tab-view
                    [entityData]="entityData"></app-entity-mat-tab-view>
            </mat-tab>

            <mat-tab
                label="{{(this.initActionType == 'create' ? 'Create' : 'Edit')}}">
                <app-error-message
                    [message]=this.errorMessage></app-error-message>

                <!-- Form -->
                <form (ngSubmit)="onSubmit(f)" #f="ngForm" class="form">
                    <!-- Contacts -->
                    <input
                        class="d-none"
                        type="text"
                        [(ngModel)]="field_contacts"
                        name="field_contacts"
                        value="{{contactList}}">

                    <div class="container">

                        <div class="row">
                            <!-- <mat-card> -->

                            <!-- Display Contacts  -->
                            <ng-container *ngIf="field_contacts">
                                <div
                                    *ngFor="let contact of field_contacts; let i = index;"
                                    class="mb-2 mt-2">
                                    <div class="p-3 display-edit-field">
                                        <span>{{ contact.field_first_name }} {{
                                            contact.field_last_name }}</span>

                                        <!-- Remove contact -->
                                        <mat-icon
                                            class="me-1 float-end"
                                            (click)="studentRemove(i)"
                                            aria-hidden="false"
                                            aria-label="trash">cancel</mat-icon>

                                        <!-- Edit contact -->
                                        <mat-icon
                                            class="me-1 float-end"
                                            (click)="studentUpdateLoad(i, contact)"
                                            aria-hidden="false"
                                            aria-label="edit">edit</mat-icon>
                                    </div>
                                </div>
                            </ng-container>

                            <!-- field_contacts -->
                            <!-- <div class ngModelGroup="__field_contact_inline_form" #contact="ngModelGroup"> -->
                            <div #firstContact="ngModelGroup" ngModelGroup="__field_contact_inline_form">
                                <input class="d-none" ngModel name="type"
                                    matInput>
                                <input class="d-none" ngModel name="bundle"
                                    matInput>

                                <mat-expansion-panel expanded="true"
                                style="border: 1px solid; margin-top: 7px;">
                                    <mat-expansion-panel-header>
                                        <mat-panel-title>
                                            Contact Information
                                        </mat-panel-title>
                                        <mat-panel-description>
                                            <!-- This is a summary of the content -->
                                        </mat-panel-description>
                                    </mat-expansion-panel-header>
                                    <div class="row">
                                        <div class="col-6">
                                            <mat-form-field
                                                class="full-width-field">
                                                <mat-label>First Name</mat-label>
                                                <input name="field_first_name" required matInput ngModel (ngModelChange)="checkDuplicateStudent('main')">
                                            </mat-form-field>
                                        </div>

                                        <div class="col-6">
                                            <mat-form-field
                                                class="full-width-field">
                                                <mat-label>Last Name</mat-label>
                                                <input name="field_last_name" required matInput ngModel (ngModelChange)="checkDuplicateStudent('main')">
                                            </mat-form-field>
                                        </div>

                                        <div class="alert alert-warning" *ngIf="duplicateWarning">
                                            <mat-icon class="warning-icon">warning</mat-icon>
                                            <span class="warning-text">{{ duplicateWarning }}</span>
                                        </div>

                                        <div class="col-3">
                                            <mat-form-field
                                                class="full-width-field">
                                                <mat-label>Home
                                                    Phone</mat-label>
                                                <input appPhoneNumberFormatter ngModel
                                                    name="field_home_phone"
                                                    matInput>
                                            </mat-form-field>
                                        </div>

                                        <div class="col-3">
                                            <mat-form-field
                                                class="full-width-field">
                                                <mat-label>Cell
                                                    Phone</mat-label>
                                                <input appPhoneNumberFormatter ngModel
                                                    name="field_cell_phone"
                                                    matInput>
                                            </mat-form-field>
                                        </div>

                                        <div class="col-6">
                                            <mat-form-field
                                                class="full-width-field">
                                                <mat-label>Email
                                                    Address</mat-label>
                                                <input required type="email"
                                                    ngModel name="field_email"
                                                    matInput>
                                            </mat-form-field>
                                        </div>

                                        <!-- Main Student's Gender -->
                                        <div class="row">
                                            <div class="col-md-6">
                                                <div class="row">
                                                    <mat-label>Gender
                                                        *</mat-label>
                                                </div>
                                                <mat-radio-group
                                                    name="field_gender_main"
                                                    required ngModel
                                                    aria-label="Gender">
                                                    <mat-radio-button
                                                        value="1">Male</mat-radio-button>
                                                    <mat-radio-button
                                                        value="0">Female</mat-radio-button>
                                                </mat-radio-group>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- Address  -->
                                    <div
                                        class="mt-3"
                                        ngModelGroup="field_address"
                                        #contact="ngModelGroup">

                                        <input
                                            class="d-none"
                                            name="multiple_properties"
                                            matInput
                                            ngModel="true">

                                        <mat-expansion-panel class="mt-1"
                                            expanded="false">
                                            <mat-expansion-panel-header>
                                                <mat-panel-title>
                                                    Address
                                                </mat-panel-title>
                                                <mat-panel-description>
                                                    Optional
                                                </mat-panel-description>
                                            </mat-expansion-panel-header>
                                            <div class="row">
                                                <!-- <div class="col-6">
                                                    <mat-form-field class="full-width-field">
                                                        <mat-label>Company</mat-label>
                                                        <input
                                                            name="organization"
                                                            matInput
                                                            ngModel>
                                                    </mat-form-field>
                                                </div> -->

                                                <div class="col-12">
                                                    <mat-form-field
                                                        class="full-width-field">
                                                        <mat-label>Street
                                                            Address</mat-label>
                                                        <input ngModel
                                                            name="address_line1"
                                                            matInput>
                                                    </mat-form-field>
                                                </div>

                                                <!-- <div class="col-12">
                                                    <mat-form-field class="full-width-field">
                                                        <mat-label>Street Address Line 2</mat-label>
                                                        <input ngModel name="address_line2" matInput>
                                                    </mat-form-field>
                                                </div> -->

                                                <div class="col-5">
                                                    <mat-form-field
                                                        class="full-width-field">
                                                        <mat-label>City</mat-label>
                                                        <input ngModel
                                                            name="locality"
                                                            matInput>
                                                    </mat-form-field>
                                                </div>
                                                <!-- <div class="col-5">
                                                    <mat-form-field class="full-width-field">
                                                        <mat-label>State</mat-label>
                                                        <input ngModel name="administrative_area" matInput>
                                                    </mat-form-field>
                                                </div> -->

                                                <div class="col-5">
                                                    <mat-form-field
                                                        class="full-width-field">
                                                        <mat-label>State</mat-label>
                                                        <mat-select matInput ngModel
                                                            name="administrative_area">
                                                            <mat-option>None</mat-option>
                                                            <mat-option
                                                                *ngFor="let element of this._fieldsService.StatesAbbreviations"
                                                                [value]="element.abbreviation">
                                                                {{ element.name
                                                                }}</mat-option>
                                                        </mat-select>
                                                    </mat-form-field>
                                                </div>

                                                <div class="col-2">
                                                    <mat-form-field
                                                        class="full-width-field">
                                                        <mat-label>Zip</mat-label>
                                                        <input ngModel
                                                            name="postal_code"
                                                            matInput>
                                                    </mat-form-field>
                                                </div>
                                                <div class="col-2 d-none">
                                                    <mat-form-field
                                                        class="full-width-field">
                                                        <mat-label>Country</mat-label>
                                                        <input ngModel="US"
                                                            name="country_code"
                                                            matInput>
                                                    </mat-form-field>
                                                </div>
                                            </div>
                                        </mat-expansion-panel>

                                        <!-- Notification Preference -->
                                        <!-- <mat-form-field class="full-width-field mt-2">
                                          <mat-label>Preferred Communication Method</mat-label>
                                          <mat-select ngModel name="field_notification_preference"
                                            name="field_notification_preference" (selectionChange)="onNotificationPreferenceChangePartner($event.value)" required>
                                            <mat-option *ngFor="let preference of this._fieldsService.communicationPreferences" [value]="preference.value">
                                              {{preference.label}}
                                            </mat-option>
                                          </mat-select>
                                        </mat-form-field> -->

                                        <div class="row">
                                            <div class="col-md-12" style="word-wrap: break-word;">
                                                <p style="font-size:12px; margin-bottom: 0.5rem;">Consenting below permits Arthur Murray International, Inc. to send you text messages about appointments, updates, and promotional offers. No purchase is necessary, and you can opt out anytime by texting STOP. Message and data rates may apply.</p>
                                                <mat-checkbox [ngModel]="field_communication_opt_in" (ngModelChange)="onCommunicationOptInChange($event)" name="field_communication_opt_in" class="mt-1" style="font-size:12px; line-height:1.4;">
                                                    Consent Given
                                                </mat-checkbox>
                                            </div>
                                        </div>

                                    </div>
                                </mat-expansion-panel>

                                <div class>
                                    <!-- TODO: Add support for this field though the API -->
                                    <!-- <div class="col-12 mt-3">
                                                <mat-label>Lesson Reminders:</mat-label>
                                                <mat-checkbox
                                                    ngModel
                                                    name="field_notification_preference[call]"
                                                    value="call"
                                                    class="">Phone Call</mat-checkbox>
                                                <mat-checkbox
                                                    ngModel
                                                    name="field_notification_preference[SMS]"
                                                    value="SMS"
                                                    class="">Text</mat-checkbox>
                                                <mat-checkbox
                                                    ngModel
                                                    name="field_notification_preference[Email]"
                                                    value="Email"
                                                    class="">Email</mat-checkbox>
                                                <mat-checkbox
                                                    ngModel
                                                    name="field_notification_preference[Both]"
                                                    value="Both"
                                                    class="">Both</mat-checkbox>
                                            </div> -->

                                    <div class="mt-3">
                                        <!-- Attending Lessons Section -->
                                        <div class="col-md-6">
                                            <div class="row">
                                            <mat-label>Attending Lessons</mat-label>
                                            </div>
                                            <mat-button-toggle-group name="field_attending_lessons" [(ngModel)]="field_attending_lessons" (change)="onAttendingLessonsChange($event)" aria-label="Attending Lessons">
                                            <mat-button-toggle value="solo">
                                                <mat-icon>person</mat-icon>
                                                Solo
                                            </mat-button-toggle>
                                            <mat-button-toggle value="partner">
                                                <mat-icon>people</mat-icon>
                                                With Partner
                                            </mat-button-toggle>
                                            </mat-button-toggle-group>
                                        </div>
                                    </div>
                                </div>

                                <ng-template [ngIf]="field_attending_lessons === 'partner'">
                                <div class>
                                    <div class="col-12">
                                        <h3 class="mt-3">Partner Information</h3>
                                    </div>
                                    <div class="col-12">
                                        <div
                                            ngModelGroup="__partner_contact_inline_form"
                                            #partnerContact="ngModelGroup">
                                            <mat-expansion-panel
                                                style="border: 1px solid; margin-top:7px;"
                                                expanded="true">
                                                <mat-expansion-panel-header>
                                                    <mat-panel-title>Partner Contact Information</mat-panel-title>
                                                    <mat-panel-description></mat-panel-description>
                                                </mat-expansion-panel-header>
                                                <div class="row">
                                                    <input
                                                        class="d-none"
                                                        ngModel
                                                        name="type"
                                                        matInput>
                                                    <input
                                                        class="d-none"
                                                        ngModel
                                                        name="bundle"
                                                        matInput>
                                                    <div
                                                        class="col-6">
                                                        <mat-form-field
                                                            class="full-width-field">
                                                            <mat-label>First Name</mat-label>
                                                            <input name="field_first_name" required matInput ngModel (ngModelChange)="checkDuplicateStudent('partner')">
                                                        </mat-form-field>
                                                    </div>
                                                    <div
                                                        class="col-6">
                                                        <mat-form-field
                                                            class="full-width-field">
                                                            <mat-label>Last Name</mat-label>
                                                            <input name="field_last_name" required matInput ngModel (ngModelChange)="checkDuplicateStudent('partner')">
                                                        </mat-form-field>
                                                    </div>

                                                    <div class="alert alert-warning" *ngIf="duplicateWarningPartner">
                                                        <mat-icon class="warning-icon">warning</mat-icon>
                                                        <span class="warning-text">{{ duplicateWarningPartner }}</span>
                                                    </div>

                                                    <div
                                                        class="col-3">
                                                        <mat-form-field
                                                            class="full-width-field">
                                                            <mat-label>Home
                                                                Phone</mat-label>
                                                            <input
                                                                ngModel
                                                                name="field_home_phone"
                                                                matInput>
                                                        </mat-form-field>
                                                    </div>
                                                    <div
                                                        class="col-3">
                                                        <mat-form-field
                                                            class="full-width-field">
                                                            <mat-label>Cell
                                                                Phone</mat-label>
                                                            <input
                                                                ngModel
                                                                name="field_cell_phone"
                                                                matInput>
                                                        </mat-form-field>
                                                    </div>
                                                    <div
                                                        class="col-6">
                                                        <mat-form-field
                                                            class="full-width-field">
                                                            <mat-label>Email
                                                                Address</mat-label>
                                                            <input
                                                                type="email"
                                                                ngModel
                                                                name="field_email"
                                                                matInput>
                                                        </mat-form-field>
                                                    </div>
                                                    <div
                                                        class="col-12">
                                                    </div>
                                                    <!-- Partner's Gender -->
                                                    <div
                                                        class="row">
                                                        <div
                                                            class="col-md-6">
                                                            <div
                                                                class="row">
                                                                <mat-label>Gender
                                                                    </mat-label>
                                                            </div>
                                                            <mat-radio-group
                                                                name="field_gender_partner"
                                                                ngModel
                                                                aria-label="Gender">
                                                                <mat-radio-button
                                                                    value="1">Male</mat-radio-button>
                                                                <mat-radio-button
                                                                    value="0">Female</mat-radio-button>
                                                            </mat-radio-group>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div
                                                    ngModelGroup="field_address"
                                                    #partnerAddress="ngModelGroup">
                                                    <input
                                                        class="d-none"
                                                        name="multiple_properties"
                                                        matInput
                                                        ngModel="true">
                                                    <mat-expansion-panel
                                                        class="mt-3"
                                                        expanded="false">
                                                        <mat-expansion-panel-header>
                                                            <mat-panel-title>Partner Address</mat-panel-title>
                                                            <mat-panel-description>Optional</mat-panel-description>
                                                        </mat-expansion-panel-header>
                                                        <div class="row">
                                                            <!-- Add the "Copy Address" button -->
                                                            <div class="col-12 mb-1">
                                                              <button type="button" mat-raised-button color="primary" (click)="copyAddress()">
                                                                Copy Address
                                                              </button>
                                                            </div>
                                                            <div
                                                                class="col-12">
                                                                <mat-form-field
                                                                    class="full-width-field">
                                                                    <mat-label>Street
                                                                        Address</mat-label>
                                                                    <input
                                                                        ngModel
                                                                        name="address_line1"
                                                                        matInput>
                                                                </mat-form-field>
                                                            </div>
                                                            <div
                                                                class="col-5">
                                                                <mat-form-field
                                                                    class="full-width-field">
                                                                    <mat-label>City</mat-label>
                                                                    <input
                                                                        ngModel
                                                                        name="locality"
                                                                        matInput>
                                                                </mat-form-field>
                                                            </div>
                                                            <div
                                                                class="col-5">
                                                                <mat-form-field
                                                                    class="full-width-field">
                                                                    <mat-label>State</mat-label>
                                                                    <mat-select
                                                                        matInput
                                                                        ngModel
                                                                        name="administrative_area">
                                                                        <mat-option>None</mat-option>
                                                                        <mat-option
                                                                            *ngFor="let element of this._fieldsService.StatesAbbreviations"
                                                                            [value]="element.abbreviation">
                                                                            {{
                                                                            element.name
                                                                            }}</mat-option>
                                                                    </mat-select>
                                                                </mat-form-field>
                                                            </div>
                                                            <div
                                                                class="col-2">
                                                                <mat-form-field
                                                                    class="full-width-field">
                                                                    <mat-label>Zip</mat-label>
                                                                    <input
                                                                        ngModel
                                                                        name="postal_code"
                                                                        matInput>
                                                                </mat-form-field>
                                                            </div>
                                                            <div
                                                                class="col-2 d-none">
                                                                <mat-form-field
                                                                    class="full-width-field">
                                                                    <mat-label>Country</mat-label>
                                                                    <input
                                                                        ngModel="US"
                                                                        name="country_code"
                                                                        matInput>
                                                                </mat-form-field>
                                                            </div>
                                                        </div>
                                                    </mat-expansion-panel>
                                                </div>

                                                <!-- Notification Preference -->
                                                <!-- <mat-form-field class="full-width-field mt-2">
                                                  <mat-label>Preferred Communication Method</mat-label>
                                                  <mat-select ngModel name="field_notification_preference"
                                                    name="field_notification_preference" (selectionChange)="onNotificationPreferenceChangePartner($event.value)" required>
                                                    <mat-option *ngFor="let preference of this._fieldsService.communicationPreferences" [value]="preference.value">
                                                      {{preference.label}}
                                                    </mat-option>
                                                  </mat-select>
                                                </mat-form-field> -->

                                                <div class="row">
                                                    <div class="col-md-12" style="word-wrap: break-word;">
                                                        <p style="font-size:12px; margin-bottom: 0.5rem;">Consenting below permits Arthur Murray International, Inc. to send you text messages and other about appointments, updates, and promotional offers. No purchase is necessary, and you can opt out anytime by texting STOP. Message and data rates may apply.</p>
                                                        <mat-checkbox [ngModel]="field_communication_opt_in_partner" (ngModelChange)="onCommunicationOptInPartnerChange($event)" name="field_communication_opt_in_partner" class="mt-1" style="font-size:12px; line-height:1.4;">
                                                            Consent Given
                                                        </mat-checkbox>
                                                    </div>
                                                </div>

                                            </mat-expansion-panel>

                                        </div>
                                    </div>
                                </div>
                            </ng-template>

                                <div class="col-12 mt-3">
                                    <!-- <button *ngIf="!editMode" type="button"
                                                mat-raised-button
                                                [disabled]="!contact.valid"
                                                class="me-1"
                                                color="primary" (click)="studentIncrement(contact.value)">
                                                Add New Student
                                            </button> -->
                                    <button *ngIf="editMode" type="button"
                                        mat-raised-button
                                        [disabled]="!contact.valid"
                                        class="me-1"
                                        color="primary"
                                        (click)="studentUpdateSubmit(i, contact.value)">
                                        Edit student
                                    </button>
                                </div>
                            </div>

                            <div class="col-12">
                                <mat-form-field class="full-width-field">
                                    <mat-label>Account Notes</mat-label>
                                    <textarea ngModel name="field_notes"
                                        matInput placeholder></textarea>
                                </mat-form-field>
                            </div>
                            <!-- </mat-card> -->
                        </div>


                        <div class="row">

                            <div class="col-12">
                                <h3 class="mt-3">Additional Details</h3>
                            </div>

                            <div class="col-6">
                                <mat-form-field class="full-width-field">
                                    <mat-label>Inquiry Taker</mat-label>
                                    <mat-select matInput required ngModel name="field_inquiry_taker">
                                        <mat-option>None</mat-option>
                                        <ng-container *ngFor="let element of _fieldsService.instructorsExecutive()">
                                            <mat-option *ngIf="element.status !== '0' || (element.status === '0' && element.uid === field_inquiry_taker)"
                                                        [value]="element.uid">
                                                {{ element.value }}
                                                <ng-container *ngIf="element.status === '0' && element.uid === field_inquiry_taker"></ng-container>
                                            </mat-option>
                                        </ng-container>
                                    </mat-select>
                                </mat-form-field>
                            </div>

                            <div class="col-6">
                                <mat-form-field class="full-width-field">
                                    <mat-label>Type of Dances</mat-label>
                                    <mat-select multiple
                                        name="field_dances_type"
                                        ngModel>
                                        <mat-option>None</mat-option>
                                        <mat-option
                                            *ngFor="let element of this._fieldsService.dance_types"
                                            [value]="element.key">{{
                                            element.value }}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>

                            <div class="col-6">
                                <mat-form-field class="full-width-field">
                                    <mat-label>How did you hear about
                                        us?</mat-label>
                                    <mat-select
                                        ngModel
                                        name="field_inquiry_method">
                                        <mat-option>None</mat-option>
                                        <mat-option
                                            *ngFor="let element of this._fieldsService.field_inquiry_method"
                                            [value]="element.key">{{
                                            element.value }}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>

                            <div class="col-6"
                                *ngIf="f.value.field_inquiry_method === 'Guest'">
                                <mat-form-field class="full-width-field">
                                    <mat-label>Instructor Credit</mat-label>
                                    <mat-select matInput ngModel
                                        name="field_instructor_credit">
                                        <mat-option>None</mat-option>
                                        <mat-option
                                            *ngFor="let element of this._fieldsService.instructorData()"
                                            [value]="element.uid">
                                            {{ element.value }}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>

                            <div class="mt-1 mb-1">
                                <!-- This should be hidden and selected in the Student Inquiry Form  -->
                                <mat-checkbox
                                    matTooltip="Inquired students are new students to the studio."
                                    [matTooltipPosition]="'right'"
                                    [ngModel]="_inquiry"
                                    name="field_inquiry_studen"
                                    (click)="toggleInquiry()"
                                    [ngClass]="{'d-none' : _inquiry}">Inquiry</mat-checkbox>
                            </div>

                            <div class="col-12">
                                <div class="toggle-container mt-3">
                                    <span>Schedule First Lesson</span>
                                    <mat-slide-toggle name="scheduleToggle"
                                        [(ngModel)]="scheduleFirstLessonEnabled"></mat-slide-toggle>
                                </div>
                            </div>

                            <div class="col-6">
                                <mat-form-field class="full-width-field">
                                    <mat-label>Executive</mat-label>
                                    <mat-select matInput ngModel
                                        name="schedule_first_lesson_field_executive"
                                        [disabled]="!scheduleFirstLessonEnabled"
                                        required>
                                        <mat-option>None</mat-option>
                                        <ng-container *ngFor="let element of _fieldsService.instructorsExecutive()">
                                            <mat-option *ngIf="element.status !== '0' || (element.status === '0' && element.uid === field_inquiry_taker)"
                                                        [value]="element.uid">
                                                {{ element.value }}
                                                <ng-container *ngIf="element.status === '0' && element.uid === field_inquiry_taker"></ng-container>
                                            </mat-option>
                                        </ng-container>
                                    </mat-select>
                                </mat-form-field>
                            </div>

                            <div class="col-6">
                                <mat-form-field class="full-width-field">
                                    <mat-label>Teacher</mat-label>
                                    <mat-select matInput ngModel
                                        name="field_teacher"
                                        (ngModelChange)="checkScheduleConflicts()"
                                        [disabled]="!scheduleFirstLessonEnabled"
                                        required>
                                        <mat-option>None</mat-option>
                                        <ng-container *ngFor="let element of _fieldsService.instructorData(); let i = index">
                                            <mat-option *ngIf="element.status !== '0' || (element.status === '0' && element.uid === field_teacher)"
                                                        [value]="element.uid">
                                                {{ element.value }}
                                                <ng-container *ngIf="element.status === '0' && element.uid === field_teacher"></ng-container>
                                            </mat-option>
                                        </ng-container>
                                    </mat-select>
                                </mat-form-field>
                            </div>

                            <div class="col-6">
                                <mat-form-field class="full-width-field">
                                    <mat-label>Lesson Type</mat-label>
                                    <mat-select ngModel name="lesson_type"
                                        [disabled]="!scheduleFirstLessonEnabled"
                                        required>
                                        <mat-option>None</mat-option>
                                        <mat-option
                                            *ngFor="let element of getFilteredLessonTypes()"
                                            [value]="element.id">
                                            {{ element.name }}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>

                            <div class="col-6">
                                <mat-form-field>
                                    <input
                                        [disabled]="!scheduleFirstLessonEnabled"
                                        [ngModel]="field_date_and_time"
                                        (ngModelChange)="uiUpdateDate($event); checkScheduleConflicts()"
                                        name="requested_date_time"
                                        matInput
                                        [ngxMatDatetimePicker]="picker_field_date_time"
                                        placeholder="Requested Date and Time"
                                        required>
                                    <mat-datepicker-toggle matSuffix
                                        [for]="picker_field_date_time">
                                    </mat-datepicker-toggle>
                                    <ngx-mat-datetime-picker
                                        #picker_field_date_time
                                        [hideTime]="false"
                                        [enableMeridian]="true"
                                        [showSpinners]="true"
                                        [showSeconds]="false"
                                        [stepHour]="1" [stepMinute]="15"
                                        [touchUi]="false">
                                    </ngx-mat-datetime-picker>
                                </mat-form-field>
                            </div>

                            <app-schedule-conflicts
                                [dateTime]="field_date_and_time"
                                [instructor]="f.value.field_teacher"
                                [lessonType]="f.value.lesson_type"
                                [duration]="updateLessonDefaultTimes(f.value.lesson_type)"
                                (dateTimeChange)="field_date_and_time = $event"
                                (conflictFound)="scrollToConflicts()">
                            </app-schedule-conflicts>

                            <!-- <div class="col-6">
                                    <mat-form-field class="full-width-field">
                                        <mat-label>Low Lesson Threshold</mat-label>
                                        <input
                                            name="field_low_lesson_threshold"
                                            matInput
                                            type="number"
                                            ngModel>
                                    </mat-form-field>
                                </div> -->

                        </div>
                    </div>
                </form>
            </mat-tab>

            <mat-tab label="Delete" [disabled]="this.initActionType == 'create'"
                *ngIf="this.actionType != 'create'">
                <app-entity-confirm-delete></app-entity-confirm-delete>
            </mat-tab>

        </mat-tab-group>

    </div>

</div>

<!-- <p>
    {{ getInvalidControls(f) | json }}
</p> -->

<mat-dialog-actions align="end">
    <ng-container *ngIf="this.actionType === 'create'">
        <!-- <button type="button" (click)="onSubmit(f);" mat-raised-button color="accent">Save</button> -->
        <!-- <button
            [disabled]="field_contacts.length == 0"
            type="button"
            (click)="onSubmit(f, true);"
            mat-raised-button
            color="accent">Save and Schedule Service</button> -->
        <button
            [disabled]="disableSubmitButton || !isFormValid()"
            type="button"
            (click)="onSubmit(f);"
            mat-raised-button
            color="accent">
            Create Student Account
        </button>
    </ng-container>
    <ng-container *ngIf="this.actionType === 'edit'">
        <ng-container *ngIf="this.initActionType == 'edit'">
            <button type="button" (click)="setFormMode('delete')"
                mat-raised-button color="accent">Delete</button>
            <button type="button" (click)="onSubmit(f);" mat-raised-button
                color="accent">Save</button>
        </ng-container>
        <ng-container *ngIf="this.initActionType == 'create'">
            <button type="button" (click)="closeDialog()" mat-raised-button
                color="accent">Cancel</button>
            <button type="button" (click)="onSubmit(f);" mat-raised-button
                color="accent">Save</button>
        </ng-container>
    </ng-container>
    <ng-container *ngIf="this.actionType === 'delete'">
        <button (click)="setFormMode('edit')" type="submit" mat-raised-button
            color="accent">Cancel</button>
        <button
            (click)="onDeleteSubmit(this._injectedDialogData['data']['EntityID'])"
            type="submit" mat-raised-button
            color="accent">Delete</button>
    </ng-container>
</mat-dialog-actions>
