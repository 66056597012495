<div style="height:64px;" class="page-empty-header"></div>

<div class="container-fluid">
	<div class="row">
		<div style="background:#215DAB; height: calc(100vh - 64px); overflow: scroll;" class="col-2">
			<app-configuration-dashboard-menu></app-configuration-dashboard-menu>
		</div>

		<div class="col-10" style="padding-left: 2.5rem;padding-right: 2.5rem;padding-top:2.5rem;overflow:scroll;max-height: calc(100vh - 64px);">

			<div class="container-fluid">
				<h1 class="">Studio Information</h1>

				<div class="">
					<div class="row">

						<div class="col-12">

							<div id="studio-information"></div>
							<app-form-studio-information></app-form-studio-information>

							<app-form-studio-working-hours></app-form-studio-working-hours>

							<app-session-start-times id="session-start-times"></app-session-start-times>

							<app-ami-reporting id="ami-reporting"></app-ami-reporting>
						</div>
					</div>
				</div>
		  </div>

		</div>

	</div>
</div>
