<h1 class="page-header">Student List</h1>

<nav mat-tab-nav-bar #tabPanel>
  <a mat-tab-link *ngFor="let link of links"
     (click)="activeLink = link"
     routerLink="/{{link.linkRoute}}"
     [active]="activeLink == link.linkTitle"> {{link.linkTitle}} </a>
</nav>

<mat-expansion-panel>
  <mat-expansion-panel-header>
    <mat-panel-title>
      Filter
    </mat-panel-title>
    <mat-panel-description>
      <app-filter-results-panel [parameters]=queryParams></app-filter-results-panel>
    </mat-panel-description>
  </mat-expansion-panel-header>
  <form (ngSubmit)="onSubmit(f)" #f=ngForm class="form">
    <div class="row">

      <!-- Student Contact Autocomplete -->
      <div class="col-2">
          <mat-form-field class="full-width-field">
              <mat-label>Student (Contact)</mat-label>
              <input
                  #studentInput
                  name="id"
                  (input)="onAutocompleteStudentContact($event)"
                  matInput
                  ngModel
                  [matAutocomplete]="autoCompleteStudentContact">
              <button (click)="redirectUserProfileID(studentInput)" type="button" matSuffix mat-icon-button aria-label="manage_accounts">
                <mat-icon>manage_accounts</mat-icon>
            </button>
          </mat-form-field>
          <mat-autocomplete (optionSelected)="onSubmit(f)" #autoCompleteStudentContact="matAutocomplete">
              <mat-option
                  *ngFor="let option of autoCompleteStudentContactOptions"
                  [value]="option.value">
                  {{option.label}} ({{option.value}})
              </mat-option>
          </mat-autocomplete>
      </div>

      <div class="col-2">
        <mat-form-field class="full-width-field">
          <mat-label>Email</mat-label>
          <input
            matInput
            ngModel
            name="email"
            (blur)="onSubmit(f)"
            (selectionChange)="onSubmit(f)">
        </mat-form-field>
      </div>

      <div class="col-2">
        <mat-form-field class="full-width-field">
          <mat-label>Phone</mat-label>
          <input
            matInput
            ngModel
            (blur)="onSubmit(f)"
            name="phone"
            (selectionChange)="onSubmit(f)">
        </mat-form-field>
      </div>

      <div class="col-2">
        <mat-form-field class="full-width-field">
          <mat-label>Choose a date</mat-label>
          <input
            matInput
            ngModel
            [matDatepicker]="picker"
            name="date"
            (dateChange)="onSubmit(f)">
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>
      </div>

      <div class="col-2">
          <mat-form-field class="full-width-field">
              <mat-label>Select Instructor...</mat-label>
              <mat-select
              matInput
              ngModel
              name="instructor"
              (selectionChange)="onSubmit(f)">
                  <mat-option>None</mat-option>
                  <mat-option
                      *ngFor="let element of this._fieldsService.instructorData()"
                      [value]="element.uid">
                      {{ element.value }}</mat-option>
              </mat-select>
          </mat-form-field>
      </div>

      <div class="col-2">
        <mat-form-field class="full-width-field">
            <mat-label>Select Department...</mat-label>
            <mat-select
              name="student_department_id"
              ngModel
              (selectionChange)="onSubmit(f)">
              <mat-option value="All">All</mat-option>
              <mat-option
              *ngFor="let department of departmentData"
              [value]="department.key">{{ department.value }}
              </mat-option>
            </mat-select>
        </mat-form-field>
      </div>


      <section class="section">
        <mat-checkbox
            name="Active"
            class="margin"
            ngModel
            (change)="onSubmit(f)">Active</mat-checkbox>
        <mat-checkbox
            name="Inactive"
            class="margin"
            ngModel
            (change)="onSubmit(f)">Inactive</mat-checkbox>

        <mat-checkbox
            name="NFA"
            class="margin"
            ngModel
            (change)="onSubmit(f)">NFA</mat-checkbox>

        <mat-checkbox
            name="paid_finished"
            class="margin"
            ngModel
            (change)="onSubmit(f)">Paid Finished</mat-checkbox>
      </section>

      <section class="section">

        <div class="float-end">
          <button (click)="resetForm()" color="primary" mat-flat-button class="me-1" mat-button>Clear
            <!-- <mat-icon (click)="resetForm()" aria-hidden="false" aria-label="Menu dropdown" class=""></mat-icon> -->
          </button>
        </div>

        </section>


      </div>


      <section class="section">
      </section>

    </form>

  </mat-expansion-panel>

  <mat-progress-bar *ngIf="ShowProgressBar" mode="indeterminate"></mat-progress-bar>

  <div class="row">
    <div class="col-md-12 mt-3">
      <div class="float-end">
        <!-- <button (click)="onPrint()" color="primary" mat-flat-button class="me-1" mat-button>Print</button>
          <button (click)="onDownloadCSV()" color="primary" mat-flat-button class="me-1 warning" mat-button>CSV
        </button> -->
      </div>
    </div>
  </div>

<!-- <mat-form-field appearance="standard">
  <mat-label>Filter</mat-label>
  <input matInput (keyup)="applyFilter($event)" placeholder="" #input>
</mat-form-field> -->

<table mat-table #displayTable [dataSource]="dataSource"
  matSort
  (matSortChange)="sortDataView($event)"
  class="mt-4">

  <!-- Student Column -->
  <ng-container matColumnDef="title">
    <th mat-header-cell *matHeaderCellDef mat-sort-header
      sortActionDescription="Sort by number">
      Student
    </th>
    <td mat-cell *matCellDef="let element">{{element.student}}</td>
  </ng-container>

  <!-- Email Column -->
  <ng-container matColumnDef="field_email_value">
    <th mat-header-cell *matHeaderCellDef mat-sort-header
      sortActionDescription="Sort by name">
      Email
    </th>
    <td mat-cell *matCellDef="let element">{{element.email}}</td>
  </ng-container>

  <!-- Phone Column -->
  <ng-container matColumnDef="field_cell_phone_value">
    <th mat-header-cell *matHeaderCellDef mat-sort-header
      sortActionDescription="Sort by phone">
      Phone
    </th>
    <td mat-cell *matCellDef="let element">{{element.phone | phone}}</td>
  </ng-container>

  <!-- "Student Department" Column -->
  <ng-container matColumnDef="field_student_department_target_id">
    <th mat-header-cell *matHeaderCellDef mat-sort-header
      sortActionDescription="Sort by department">
      Student Department
    </th>
    <td mat-cell *matCellDef="let element">{{ element.department }}</td>
  </ng-container>

  <!-- "Last Lesson" Column -->
  <!-- <ng-container matColumnDef="field_last_lesson_date_day_value">
    <th mat-header-cell *matHeaderCellDef mat-sort-header
      sortActionDescription="Sort by field_last_lesson_date_day_value">
      Last Lesson Taken
    </th>
    <td mat-cell *matCellDef="let element" class="text-nowrap">{{element.lastlessondate | date}}</td>
  </ng-container> -->

  <!-- "Lesson Enrolled" Column -->
  <ng-container matColumnDef="field_total_lessons_enrolled">
    <th mat-header-cell *matHeaderCellDef mat-sort-header
      sortActionDescription="Sort by field_total_lessons_enrolled">
      Lesson(s) Enrolled
    </th>
    <td mat-cell *matCellDef="let element">{{element.field_total_lessons_enrolled}}</td>
  </ng-container>

  <!-- "Last Lesson Taken" Column -->
  <ng-container matColumnDef="field_last_lesson_taken_value">
    <th mat-header-cell *matHeaderCellDef mat-sort-header
      sortActionDescription="Sort by field_last_lesson_taken_value">
      Last Lesson Taken
    </th>
    <td mat-cell *matCellDef="let element" class="text-nowrap">{{element.field_last_lesson_taken | date}}</td>
  </ng-container>

  <!-- "Next Lesson Scheduled" Column -->
  <ng-container matColumnDef="field_upcoming_lesson_value">
    <th mat-header-cell *matHeaderCellDef mat-sort-header
      sortActionDescription="Sort by next_lesson_scheduled">
      Next Lesson Scheduled
    </th>
    <td mat-cell *matCellDef="let element">{{element.field_upcoming_lesson | date}}</td>
  </ng-container>

  <!-- "Lesson Remaining" Column -->
  <ng-container matColumnDef="field_total_lessons_remaining_value">
    <th mat-header-cell *matHeaderCellDef mat-sort-header
      sortActionDescription="Sort by field_total_lessons_remaining_value">
      Lesson(s) Remaining
    </th>
    <td mat-cell *matCellDef="let element">{{element.field_total_lessons_remaining}}</td>
  </ng-container>

  <!-- "Paid Ahead" Column -->
  <ng-container matColumnDef="field_total_lessons_paid_ahead_value">
    <th mat-header-cell *matHeaderCellDef mat-sort-header
      sortActionDescription="Sort by field_total_lessons_paid_ahead_value">
      Paid Ahead
    </th>
    <td mat-cell *matCellDef="let element">{{element.field_total_lessons_paid_ahead}}</td>
  </ng-container>

  <!-- Instructor Column -->
  <ng-container matColumnDef="instructor">
    <th mat-header-cell *matHeaderCellDef
      sortActionDescription="Sort by instructor">
      Instructor
    </th>
    <td mat-cell *matCellDef="let element">{{element.instructor}}</td>
  </ng-container>

  <!-- View Column -->
  <!-- <ng-container matColumnDef="view">
    <th mat-header-cell *matHeaderCellDef sortActionDescription="Sort by view">
      View
    </th>
    <td mat-cell *matCellDef="let element"> </td>
  </ng-container> -->
  <!-- Dashboard Column -->
  <ng-container matColumnDef="dashboard">
    <th mat-header-cell *matHeaderCellDef sortActionDescription="Sort by
      dashboard">
      Dashboard
    </th>
    <td mat-cell *matCellDef="let element">
      <a [routerLink]="'/students/' + element.link">
        <mat-icon aria-hidden="false" aria-label="Person" class="menu-dropdown">person</mat-icon>
      </a>
    </td>
  </ng-container>

  <!-- Edit Column -->
  <ng-container matColumnDef="edit">
    <th mat-header-cell *matHeaderCellDef sortActionDescription="Sort by
      dashboard">
      Actions
    </th>
    <td mat-cell *matCellDef="let element">
          <button mat-icon-button [matMenuTriggerFor]="studentListMenu" [matMenuTriggerData]="{ student_account_id: element.student_account_id }">
            <mat-icon>more_vert</mat-icon>
          </button>
    </td>
  </ng-container>



  <mat-menu #studentListMenu="matMenu">
    <ng-template matMenuContent let-student_account_id="student_account_id">
        <button mat-menu-item
            (click)="openEntityComponent(StudentInquiryDetailsComponent, 'student_accounts', 'student_account', 'edit', student_account_id)"
            >
            Edit Inquiry Information
        </button>
        <button mat-menu-item
            (click)="setStudentArchive(student_account_id, 0)"
            >
            Unarchive
        </button>
    </ng-template>
  </mat-menu>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>

  <mat-paginator (page)="pageChanged($event)"
    [disabled]="ShowProgressBar"
    [pageSizeOptions]="onPageSizeOptions()" [length]="30"
    [pageSize]="paginationTotalPages" [pageIndex]="paginationIndex"
    aria-label="Select page of view"></mat-paginator>
