import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '../base-form/base-form.component';

@Component({
  selector: 'student-account',
  templateUrl: './student-account.component.html',
  styleUrls: ['./student-account.component.css',
    '../../../../app.component.css'
  ]
})
export class StudentAccountComponent extends BaseComponent {
}
