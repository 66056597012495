<mat-card class="col-12 configuration-card">
  <mat-card-header>
    <mat-card-title>Session Start Times</mat-card-title>
  </mat-card-header>

  <mat-card-content>
    <mat-accordion multi>
      <mat-expansion-panel *ngFor="let day of weekDays; let i = index" [expanded]="false">
        <mat-expansion-panel-header>
          {{ day.name }}
        </mat-expansion-panel-header>

        <h4>Selected Times:</h4>
        <mat-chip-list>
          <mat-chip *ngFor="let time of day.times"
                    [removable]="true"
                    (removed)="removeTime(day, convertTo12HourFormat(time))"
                    class="selected-time-chip"
                    [@chipAnimation]>
            {{ convertTo12HourFormat(time) }}
            <mat-icon matChipRemove>cancel</mat-icon>
          </mat-chip>
        </mat-chip-list>

        <h4 class="mt-3">Available Times:</h4>
        <mat-chip-list>
          <mat-chip *ngFor="let time of getFilteredAvailableTimes(day)"
                    (click)="addTime(day, time)"
                    class="available-time-chip"
                    [@chipAnimation]>
            {{ convertTo12HourFormat(time) }}
          </mat-chip>
        </mat-chip-list>

        <div class="button-group mt-3">
          <button class="me-2" mat-button (click)="clearTimes(day); saveChanges()">
            <mat-icon>delete</mat-icon> Clear All
          </button>
          <button class="me-2" mat-button (click)="copyToNextDays(i)">
            <mat-icon>next_week</mat-icon> Copy to Next Days
          </button>
          <button class="me-2" mat-button (click)="copyToAllWeekdays(i)">
            <mat-icon>calendar_today</mat-icon> Copy to All Weekdays
          </button>
          <button class="me-2" mat-button (click)="copyLastDay(i)" [disabled]="i === 0">
            <mat-icon>history</mat-icon> Copy Last Day
          </button>
        </div>
      </mat-expansion-panel>
    </mat-accordion>
  </mat-card-content>
</mat-card>
