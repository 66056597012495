<mat-form-field class="full-width-field">
    <mat-label>Lesson Type</mat-label>
    <mat-select
        matInput
        ngModel
        name="field_type_target_id"
        >
        <!-- (blur)="onSubmit(f)" -->
        <!-- (selectionChange)="onSubmit(f)" -->
        <mat-option value="All" selected="selected">- Any -</mat-option>
        <mat-option value="616">Assist Lesson</mat-option>
        <mat-option value="600">Back Department Lesson (BD)</mat-option>
        <mat-option value="602">BDYLSN</mat-option>
        <mat-option value="555">Bonus/Comp Lesson</mat-option>
        <mat-option value="561">Buddy Lesson</mat-option>
        <mat-option value="615">CALL</mat-option>
        <mat-option value="620">CHAT</mat-option>
        <mat-option value="601">COACH</mat-option>
        <mat-option value="559">Coach Lesson (C)</mat-option>
        <mat-option value="614">Coaching</mat-option>
        <mat-option value="599">Coaching Lesson</mat-option>
        <mat-option value="560">Dance Evaluation (DE)</mat-option>
        <mat-option value="622">DOR</mat-option><mat-option value="623">Drive</mat-option>
        <mat-option value="618">EM</mat-option>
        <mat-option value="621">EXT</mat-option>
        <mat-option value="612">FESTPRI</mat-option>
        <mat-option value="548">Front Department Lesson (FD)</mat-option>
        <mat-option value="625">GAP</mat-option>
        <mat-option value="644">Gift Certificate</mat-option>
        <mat-option value="617">GRAD</mat-option>
        <mat-option value="564">Group</mat-option>
        <mat-option value="607">Hold</mat-option>
        <mat-option value="626">Lesson pkg</mat-option>
        <mat-option value="609">LSNPKG</mat-option>
        <mat-option value="556">Master Class</mat-option>
        <mat-option value="624">MBCHT</mat-option>
        <mat-option value="551">Middle Dept Lesson</mat-option>
        <mat-option value="563">Not Found!</mat-option>
        <mat-option value="627">ORI</mat-option>
        <mat-option value="606">Party Session</mat-option>
        <mat-option value="557">Practice Party</mat-option>
        <mat-option value="1393">Pre Original</mat-option>
        <mat-option value="608">PRG</mat-option>
        <mat-option value="613">PRI</mat-option>
        <mat-option value="605">REN</mat-option>
        <mat-option value="604">SHOW</mat-option>
        <mat-option value="619">SOLO</mat-option>
        <mat-option value="611">SPNSR</mat-option>
        <mat-option value="603">ST-CMP</mat-option>
        <mat-option value="642">Transfer In</mat-option>
        <mat-option value="643">Transfer Out</mat-option>
        <mat-option value="610">Tuition</mat-option>
        <mat-option value="562">Wedding Lesson</mat-option>
        <mat-option value="553">BPRI</mat-option>
    </mat-select>
</mat-form-field>