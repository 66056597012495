<div class="container-fluid">

	<mat-progress-bar *ngIf="ShowProgressBar" mode="indeterminate"></mat-progress-bar>

	<div class="table-container">

			<table mat-table #displayTable [dataSource]="dataSource"
					matSort
					(matSortChange)="sortData($event)"
					class="mt-4">

					<ng-container matColumnDef="field_payment_id">
							<th mat-header-cell *matHeaderCellDef
									sortActionDescription="Sort by Payment ID">
									Payment ID
							</th>
							<td mat-cell *matCellDef="let element"> {{element.field_payment_id}}
							</td>
					</ng-container>

					<ng-container matColumnDef="field_customer_name">
							<th mat-header-cell *matHeaderCellDef
									sortActionDescription="Sort by Customer Name">
									Customer Name
							</th>
							<td mat-cell *matCellDef="let element"> {{element.field_customer_name}}
							</td>
					</ng-container>

					<ng-container matColumnDef="field_date_and_time">
							<th mat-header-cell *matHeaderCellDef
									sortActionDescription="Sort by field_date_and_time">
									Sale Date
							</th>
							<td mat-cell *matCellDef="let element"> {{ element.field_date_and_time | date: 'MM/dd/yyyy' }} </td>
					</ng-container>

					<!-- AllScheduledPaymentInstructors Column -->
					<ng-container matColumnDef="AllScheduledPaymentInstructors">
							<th mat-header-cell *matHeaderCellDef
									sortActionDescription="Sort by AllScheduledPaymentInstructors">
									Instructor
							</th>
							<td mat-cell *matCellDef="let element">
									{{ element.AllScheduledPaymentInstructors }}
							</td>
					</ng-container>

					<!-- AllScheduledPaymentInstructors Column -->
					<ng-container matColumnDef="field_gross_tuition">
							<th mat-header-cell *matHeaderCellDef
									sortActionDescription="Sort by field_gross_tuition">
									Tuition
							</th>
							<td mat-cell *matCellDef="let element">
									{{ element.field_gross_tuition }}
							</td>
					</ng-container>

					<!-- edit Column -->
					<ng-container matColumnDef="edit">
						<th mat-header-cell *matHeaderCellDef
								sortActionDescription="Sort by edit">
								Actions
						</th>
						<td mat-cell *matCellDef="let element">
								<button mat-icon-button [matMenuTriggerFor]="paymentMenu" [matMenuTriggerData]="{paymentID: element.id}">
										<mat-icon>more_vert</mat-icon>
								</button>
						</td>
				</ng-container>

				<mat-menu #paymentMenu="matMenu">
					<ng-template matMenuContent let-paymentID="paymentID" let-payment="element">
							<!-- <button mat-menu-item
									(click)="openEntityComponent(PaymentsEntityComponent, 'payments', 'payment', '0', paymentID)"
									>
									View
							</button> -->
							<button mat-menu-item
									(click)="openEntityComponent(PaymentsEntityComponent, 'payments', 'payment', 'edit', paymentID)"
									>
									Edit
							</button>
							<button mat-menu-item
									(click)="openEntityComponent(PaymentsEntityComponent, 'payments', 'payment', 'delete', paymentID)"
									>
									Delete
							</button>
							<button mat-menu-item
									(click)="openEntityComponent(PaymentsEntityComponent, 'payments', 'payment', 'refund', paymentID)"
									>
									Refund
							</button>
					</ng-template>
			</mat-menu>

					<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
					<tr mat-row matRipple *matRowDef="let row; columns: displayedColumns;"></tr>
			</table>

			<mat-paginator
					[disabled]="ShowProgressBar"
					(page)="pageChanged($event)"
					[pageSizeOptions]="onPageSizeOptions()"
					[length]="30"
					[pageSize]="paginationTotalPages"
					[pageIndex]="paginationIndex"
					aria-label="Select page of view"></mat-paginator>

	</div>
</div>
