<div class="container-fluid">
    <h1 class="page-header">Week View</h1>

    <!-- Filter Form  -->
    <mat-expansion-panel>
    <mat-expansion-panel-header>
        <mat-panel-title>
        Filter
        </mat-panel-title>
        <mat-panel-description>
        Showing only events&nbsp;
        <span *ngIf="this.f?.form?.get('filterInstructor')?.value"> for {{ this.getNameFromId(this.f.form.get('filterInstructor').value, this._fieldsService?.instructorData(), 'value') }} Instructors&nbsp;</span>
        <!-- <span *ngIf="filterInstructorCategory"> for {{ this.getNameFromId(filterInstructorCategory, filterInstructorCategory, 'name') }} Instructors  &nbsp; </span> -->
        <!-- <span *ngIf="filterLessonType"> for {{ this.getNameFromId(filterLessonType, filterLessonType) }} Lessons &nbsp; </span> -->
        <span *ngIf="filterStudent"> for {{ filterStudent }}&nbsp; </span>
        <span *ngIf="filterGroupLesson || filterLesson || filterServices">
            that are
            <span *ngIf="filterGroupLesson"> Group Lessons </span>
            <span *ngIf="filterLesson"> Personal Lessons </span>
            <span *ngIf="filterServices"> Services </span>
            &nbsp;
        </span>
        <span *ngIf="filterDate">on {{ filterDateDisplay }}</span>
        </mat-panel-description>
    </mat-expansion-panel-header>

    <form (ngSubmit)="updateCalendarEvents(f)" #f=ngForm class="form">
        <div class="row">
            <!-- <div class="col-3">
                <mat-form-field class="full-width-field">
                    <mat-label>Instructor Category</mat-label>
                    <mat-select
                        name="filterInstructorCategory"
                        ngModel
                        (selectionChange)="onSubmit(f)"
                        >
                        <mat-option>None</mat-option>
                        <mat-option *ngFor="let value of filterInstructorCategory" [value]="value.id">{{value.name}}</mat-option> -->
                        <!-- <mat-option *ngFor="let element of this._taxonomyService.teacher_category" [value]="element.id">{{ element.name }}</mat-option> -->
                    <!-- </mat-select>
                </mat-form-field>
            </div> -->


            <div class="col-3">
                <mat-form-field class="full-width-field">
                    <mat-label>Select Instructor</mat-label>
                    <mat-select
                        matInput
                        [(ngModel)]="field_instructor"
                        name="filterInstructor"
                        (selectionChange)="onSubmit(f)">
                        <mat-option>None</mat-option>
                        <ng-container *ngFor="let element of this._fieldsService.instructorData()">
                            <mat-option *ngIf="element.status !== '0' || (element.status === '0' && element.uid === field_instructor)"
                                        [value]="element.uid">
                                {{ element.value }}
                                <ng-container *ngIf="element.status === '0' && element.uid === field_instructor"></ng-container>
                            </mat-option>
                        </ng-container>
                    </mat-select>
                </mat-form-field>
            </div>

            <div class="col-3">
                <!-- <mat-form-field appearance="standard">
                    <mat-label>Select Instructor</mat-label>
                    <mat-select
                        name="filterInstructor"
                        [(ngModel)]="filterInstructor">
                        <mat-option *ngFor="let value of filterInstructor" [value]="value.name">{{value.name}}</mat-option>
                    </mat-select>
                </mat-form-field> -->

                <mat-form-field class="full-width-field">
                    <mat-label>Choose Lesson Type...</mat-label>
                    <mat-select
                        name="filterLessonType"
                        ngModel
                        (selectionChange)="onSubmit(f)">
                        <mat-option>None</mat-option>
                        <mat-option *ngFor="let lesson of filterLessonType" [value]="lesson.id">{{lesson.value}}</mat-option>
                        <!-- <mat-option *ngFor="let element of this._taxonomyService.lesson_type" [value]="element.id">{{ element.name }}</mat-option> -->
                    </mat-select>
                </mat-form-field>
            </div>

            <div class="col-3">
                <mat-form-field class="full-width-field">
                    <mat-label>Student</mat-label>
                    <input
                        name="filterStudent"
                        (input)="onStudentInput($event)"
                        matInput
                        ngModel
                        (blur)="onSubmit(f)"
                        [matAutocomplete]="auto"
                        >
                        <button type="button" matSuffix mat-icon-button aria-label="manage_accounts">
                            <mat-icon>manage_accounts</mat-icon>
                        </button>
                </mat-form-field>
                <mat-autocomplete #auto="matAutocomplete"
                        (optionSelected)="onSubmit(f)">
                    <mat-option *ngFor="let option of autoCompleteOptions"
                        [value]="option.label + ' ' + '(' + option.value + ')'">
                        {{option.label}} ({{option.value}})
                    </mat-option>
                </mat-autocomplete>
            </div>

            <div class="col-3">
                <mat-form-field class="full-width-field">
                    <mat-label>Choose a date</mat-label>
                    <mat-select matInput [(ngModel)]="filterDate" name="calendar_date" (selectionChange)="onSubmit(f)">
                        <mat-option *ngFor="let element of CalendarWeekViewForYearData" [value]="element.key">
                            {{ element.value }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>

        </div>

        <section class="section">
            <mat-checkbox
                name="filterGroupLesson"
                class="margin"
                [(ngModel)]="filterGroupLesson"
                (change)="onSubmit(f)">Group Lesson</mat-checkbox>
            <mat-checkbox
                name="filterLesson"
                class="margin"
                [(ngModel)]="filterLesson"
                (change)="onSubmit(f)">Lesson</mat-checkbox>

            <mat-checkbox
                name="filterServices"
                class="margin"
                [(ngModel)]="filterServices"
                (change)="onSubmit(f)">Services</mat-checkbox>
        </section>
    <!--
        <mat-divider></mat-divider>
        <button type="submit"
            mat-raised-button
            color="primary">Filter</button> -->

    </form>

    </mat-expansion-panel>

    <div class="row">
        <div class="col-12">
            <!-- Options  -->
            <div class="float-end mt-3">
                <!-- Summary Details -->
                <div class="d-inline me-1">
                    <span>Overall Summary: </span>
                    <span title="Booked Daily">{{ summaryCount.privateLessonDaily
                        }}</span>
                    <sup title="Taken Daily">{{ summaryCount.privateTakenLessonDaily
                        }}</sup>
                    <b> / </b>
                    <span title="Booked Weekly">{{ summaryCount.privateLessonWeekly
                        }}</span>
                    <sup title="Taken Weekly">{{
                        summaryCount.privateTakenLessonWeekly }}</sup>
                </div>

                <button style="color:#000; background-color: #82CFFD !important; border-color: #82CFFD !important;" mat-flat-button class="btn-color-status-update me-1" mat-button
                    [matMenuTriggerFor]="updateStatusMenu">
                    Status Update
                    <mat-icon aria-hidden="false" aria-label="Menu dropdown"
                        class="menu-dropdown">arrow_drop_down
                    </mat-icon>
                </button>
                <mat-menu #updateStatusMenu="matMenu">
                    <button mat-menu-item (click)="openDialog(UpdateLessonComponent, {
                                        height: '600px',
                                        width: '700px',
                                        data: {
                                            eventData: this.Events
                                        }
                                    })">Update Lesson</button>
                    <button mat-menu-item (click)="openDialog(UpdateGroupLessonComponent, {
                        height: '600px',
                        width: '700px',
                        data: {
                            eventData: this.Events
                        }
                    })">Update Group Lesson</button>
                    <button mat-menu-item (click)="openDialog(UpdateServiceComponent, {
                        height: '600px',
                        width: '700px',
                        data: {
                            eventData: this.Events
                        }
                    })">Update Services</button>
                </mat-menu>

                <button style="color:#fff; background-color: #1C3F8B !important; border-color: #1C3F8B !important;" mat-flat-button class="me-1" mat-button
                    [matMenuTriggerFor]="newEventMenu">
                    New Event
                    <mat-icon aria-hidden="false" aria-label="Menu dropdown"
                        class="menu-dropdown">arrow_drop_down
                    </mat-icon>
                </button>
                <mat-menu #newEventMenu="matMenu">
                    <button mat-menu-item
                        (click)="openEntityComponent(EventLessonEntityComponent, 'events', 'lessons', 'create')">
                        Add Lesson
                    </button>
                    <button mat-menu-item
                        (click)="openEntityComponent(EventGroupLessonEntityComponent, 'events', 'group_lesson', 'create')">
                        Add Group Lesson
                    </button>
                    <button mat-menu-item
                        (click)="openEntityComponent(EventServicesEntityComponent, 'events', 'services', 'create')">
                        Add Service
                    </button>
                    <button mat-menu-item
                        (click)="openEntityComponent(EventScheduleEntityComponent, 'events', 'schedules', 'create')">
                        Add Schedule
                    </button>
                </mat-menu>

                <button style="color:#fff; background-color: #0AC92B !important; border-color: #0AC92B !important;"
                    (click)="openEntityComponent(StudentInquiryEntityComponent, 'student_accounts', 'student_account', 'create')"
                    mat-button>
                    Add Inquiry
                </button>
            </div>
        </div>
    </div>
    <!--
    <mat-grid-list class="" cols="1" rowHeight="75px">
        <mat-grid-tile class="float-end">

        </mat-grid-tile>
    </mat-grid-list> -->


    <full-calendar #calendar id="calendar" [options]="calendarOptions"></full-calendar>


    <!-- TODO: make this a component -->
    <div class="popover bottom in" role="tooltip" id="custom-popover" style="display: none;">
        <div class="arrow" style="left: 50%;"></div>
        <div class="popover-content">
            <div style=" display: grid;">
                <button (click)="openEntityComponent(EventLessonEntityComponent, 'events', 'lessons', 'create', '', {
                    'field_instructor': this.currentSelectedInstructorID,
                    'field_duration': this.currentSelectedDuration,
                    'field_date_and_time': this.currentSelectedStartTime
                })" type="button"
                    class="btn btn-default" target_element="lesson"
                    title="Book Lesson">
                    Lesson
                </button>
                <button (click)="openEntityComponent(EventServicesEntityComponent, 'events', 'services', 'create', '', {
                    'field_executive': this.currentSelectedInstructorID,
                    'field_duration': this.currentSelectedDuration,
                    'field_date_and_time': this.currentSelectedStartTime
                })" type="button"
                    class="btn btn-default" target_element="service"
                    title="Book Service">
                    Service
                </button>
                <button (click)="openEntityComponent(EventScheduleEntityComponent, 'events', 'schedules', 'create', '', {
                    'field_schedule_instructor': this.currentSelectedInstructorID,
                    'field_duration': this.currentSelectedDuration,
                    'field_date_and_time': this.currentSelectedStartTime
                })" type="button"
                    class="btn btn-default" target_element="schedule"
                    title="Book Schedule">
                    Schedule
                </button>
                <button (click)="openEntityComponent(EventGroupLessonEntityComponent, 'events', 'group_lesson', 'create', '', {
                    'field_instructor': this.currentSelectedInstructorID,
                    'field_duration': this.currentSelectedDuration,
                    'field_date_and_time': this.currentSelectedStartTime
                })" type="button"
                    class="btn btn-default" target_element="group_lesson"
                    title="Book Group Lesson">
                    Group Lesson
                </button>
            </div>
        </div>
    </div>
</div>
