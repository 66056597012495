import { Component, Input, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { ViewTableComponent } from '../../base/view-table/view-table.component';
import { ViewPaymentsFromEnrollmentComponent } from '../../forms/view-payments-from-enrollment/view-payments-from-enrollment.component';
import { StudentDashboardWrapperComponent } from '../../student/student-dashboard-wrapper/student-dashboard-wrapper.component';
import { ComponentType } from '@angular/cdk/overlay';

@Component({
  selector: 'app-enrollment-view-payment-view',
  templateUrl: './enrollment-view-payment-view.component.html',
  styleUrls: ['./enrollment-view-payment-view.component.css']
})
export class EnrollmentViewPaymentViewComponent extends ViewTableComponent implements OnInit {

  @Input() override enrollmentID?: any;

  override displayedColumns: string[] = [
    'field_payment_id',
    'field_customer_name',
    'field_date_and_time',
    'field_gross_tuition',
    'AllScheduledPaymentInstructors',
    'edit',
  ];

  StudentDashboardWrapperComponent = StudentDashboardWrapperComponent;
  ViewPaymentsFromEnrollmentComponent = ViewPaymentsFromEnrollmentComponent;

  override getData(params: { parameter: string; value: string; }[]) {
    this.ShowProgressBar = true;
    this.queryParams = params;

    // Add sorting parameters, if available
    if (this.sortParams !== undefined) {
      this.queryParams.push(this.sortParams);
    }

    this._drupalRESTService.httpGET("/api_rest/v1/rest_api_view_payments_on_enrollment/" + this.enrollmentID, params)
      .subscribe((data) => {
        this.ShowProgressBar = false;
        this.data = data;
        this.dataSource = new MatTableDataSource(data['results']);

        // Pagination
        if (data['results']) {
          this.setupPagination(data, params);
        }

      }, error => this.handleError(error))
  }

  isInteger(value: string): boolean {
    if (value && !isNaN(+value)) {
      return Number(value) % 1 === 0;
    }
    return false;
  }

  override openEntityComponent(component: ComponentType<unknown>, eckType: any, bundle: any, action: any, EntityID?: any, fieldsData?: {}) {
    this._dialogService.openDialog(component, "defaultWithData", {
      data: {
        EntityID: EntityID,
        eckType: eckType,
        bundle: bundle,
        action: action,
        fieldsData: fieldsData ?? '',
      },
    }).afterClosed().subscribe(data => {
      // this.refreshCalendar();
      this.getData(this.queryParams);
    });
  }

}
