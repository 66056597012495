import { SelectionModel } from '@angular/cdk/collections';
import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Inject, OnInit, Output, Renderer2, ViewChild } from '@angular/core';
import { FormBuilder, NgForm } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTabGroup } from '@angular/material/tabs';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthRESTService } from 'src/app/services/auth-rest.service';
import { AuthService } from 'src/app/services/auth.service';
import { DialogService } from 'src/app/services/dialog.service';
import { DrupalRESTService } from 'src/app/services/drupal-rest.service';
import { EntityRESTService } from 'src/app/services/entity-rest.service';
import { FieldsService } from 'src/app/services/fields.service';
import { MenuRESTService } from 'src/app/services/menu-rest.service';
import { TaxonomyService } from 'src/app/services/taxonomy.service';
import { UtilityService } from 'src/app/services/utility.service';
import { BaseComponent } from '../base-form/base-form.component';
import { RouteEventsService } from 'src/app/route-events.service';
import { PermissionsService } from 'src/app/services/permissions.service';
import { ConfigPagesService } from 'src/app/services/config-pages.service';
import { PackagesService } from 'src/app/services/packages.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { UserService } from 'src/app/services/user.service';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-base-simple-dialog',
  template: '',
})
export class BaseSimpleDialogComponent extends BaseComponent{

  dataSource;
  selection = new SelectionModel(true, []);

  constructor(
    private _dialogRef: MatDialogRef<unknown>,
    @Inject(MAT_DIALOG_DATA) public _injectedDialogData: string,
    public override _dialogService: DialogService,
    public override _entityRESTService: EntityRESTService,
    public override _utilityService: UtilityService,
    public override _fieldsService: FieldsService,
    public override _drupalRESTService: DrupalRESTService,
    public override _taxonomyService: TaxonomyService,
    public override _router: Router,
    public override _activatedRoute: ActivatedRoute,
    public override _authRESTService: AuthRESTService,
    public override _authService: AuthService,
    public override _menuRESTService: MenuRESTService,
    public override _formBuilder: FormBuilder,
    public override _routeEventsService: RouteEventsService,
    public override _permissionsService: PermissionsService,
    public override _renderer2: Renderer2,
    public override _configPages: ConfigPagesService,
    public override _packagesService: PackagesService,
    public override _snackBar: MatSnackBar,
    public override _userRESTService: UserService,
    public override _el: ElementRef,
    public override _changeDetectorRef: ChangeDetectorRef,
    public override _sanitizer: DomSanitizer,
  ) {
    super(_dialogService, _entityRESTService, _utilityService, _fieldsService, _drupalRESTService, _taxonomyService, _router, _activatedRoute, _authRESTService, _authService, _menuRESTService, _formBuilder, _routeEventsService, _permissionsService, _renderer2, _configPages, _packagesService, _snackBar, _userRESTService, _el, _changeDetectorRef, _sanitizer);
  }


  filterEventLessonType(events: { [x: string]: any; }, filter: string) {
    let result = [], key;

    for (key in events) {
      if (events[key].appointmentType == filter) {
        console.log(events[key]);
        result.push(events[key]);
      }
    }

    return result;
  }

  closeDialog(data?) {
    this._dialogRef.close(data);
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource?.data?.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    if (this.isAllSelected()) {
      this.selection.clear();
      return;
    }

    this.selection.select(...this.dataSource.data);
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row): string {
    if (!row) {
      return `${this.isAllSelected() ? 'deselect' : 'select'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.position + 1}`;
  }

}
