<div *ngIf="actionType === 'edit'; else createTitle">
	<app-entity-mat-dialog-title title="Edit Gift Certificate"></app-entity-mat-dialog-title>
</div>
<ng-template #createTitle>
	<app-entity-mat-dialog-title title="Gift Certificate Issuance"></app-entity-mat-dialog-title>
</ng-template>

<div class="container">
  <mat-stepper linear #stepper>
    <mat-step [stepControl]="giftCertificateForm" [editable]="!paymentSubmitted">
      <form [formGroup]="giftCertificateForm">
        <ng-template matStepLabel>Gift Certificate Details</ng-template>
        <div class="form-group">
          <mat-form-field>
            <input matInput formControlName="giftCertificateCode" placeholder="Gift Certificate Code" readonly="true" required>
          </mat-form-field>
          <mat-form-field>
            <input matInput formControlName="giftCertificateAmount" placeholder="Gift Certificate Amount" required>
          </mat-form-field>
          <mat-form-field>
            <input matInput [matDatepicker]="expirationDatePicker" formControlName="expirationDate" placeholder="Expiration Date" required>
            <mat-datepicker-toggle matSuffix [for]="expirationDatePicker"></mat-datepicker-toggle>
            <mat-datepicker #expirationDatePicker></mat-datepicker>
          </mat-form-field>
          <mat-form-field>
            <textarea matInput formControlName="giftCertificateNotes" placeholder="Gift Certificate Notes"></textarea>
          </mat-form-field>

          <div class="row">
            <mat-label>Checkout Type<sup>*</sup></mat-label>
          </div>
          <mat-button-toggle-group formControlName="checkoutType" (change)="onCheckoutTypeChange($event)" aria-label="Checkout Type">
            <mat-button-toggle value="guest">Guest Checkout</mat-button-toggle>
            <mat-button-toggle value="existing">Existing Account</mat-button-toggle>
          </mat-button-toggle-group>

          <div class="mt-2" *ngIf="giftCertificateForm.value.checkoutType === 'guest'">
            <mat-form-field>
              <input matInput formControlName="purchaserName" placeholder="Purchaser's Name" required>
            </mat-form-field>
            <mat-form-field>
              <input matInput formControlName="purchaserEmail" placeholder="Purchaser's Email" required>
            </mat-form-field>
            <mat-form-field>
              <input matInput formControlName="purchaserPhone" placeholder="Purchaser's Phone" required>
            </mat-form-field>
          </div>

					<div class="mt-2" *ngIf="giftCertificateForm.value.checkoutType === 'existing'">
						<mat-form-field class="full-width-field">
							<mat-label>Gift Certificate Purchaser</mat-label>
							<input required name="giftCertificatePurchaser" (input)="onAutocompletePurchaser($event)" matInput formControlName="giftCertificatePurchaser" required #giftCertificatePurchaserInput [matAutocomplete]="autoCompletePurchaser">
							<button type="button" matSuffix mat-icon-button aria-label="manage_accounts">
								<mat-icon>manage_accounts</mat-icon>
							</button>
						</mat-form-field>
						<mat-autocomplete #autoCompletePurchaser="matAutocomplete">
							<mat-option (onSelectionChange)="lookupStudentAccount($event, true);" *ngFor="let option of autoCompletePurchaserOptions" [value]="option.label + ' (' + option.value + ')'">
								{{option.label}} ({{option.value}})
							</mat-option>
						</mat-autocomplete>
					</div>

          <mat-form-field>
						<input matInput formControlName="field_recipient_name" placeholder="Recipient's Name" required>
					</mat-form-field>
          <mat-form-field>
            <input matInput formControlName="recipientEmail" placeholder="Recipient's Email" required>
          </mat-form-field>
          <mat-form-field>
            <input matInput formControlName="recipientPhone" placeholder="Recipient's Phone" required>
          </mat-form-field>
					{{findInvalidControlsRecursive(giftCertificateForm)}}
        </div>
        <div>
					<button mat-button matStepperNext [disabled]="!isGiftCertificateFormValid()">Next</button>
        </div>
      </form>
    </mat-step>

		<mat-step [stepControl]="paymentForm" [editable]="!paymentSubmitted">
			<form [formGroup]="paymentForm">
				<ng-template matStepLabel>Payment Information</ng-template>
				<div class="form-group">
					<mat-radio-group class="row" name="field_payment_type" formControlName="field_payment_type" aria-label="Select an option">
						<ng-container *ngFor="let element of this.taxonomyService.payment_type">
							<div class="col-4">
								<mat-radio-button [value]="element.id">{{ element.name }}</mat-radio-button>
							</div>
						</ng-container>
					</mat-radio-group>

					<ng-container *ngIf="paymentForm.get('field_payment_type').value === 'Stripe'">
						<!-- Credit card fields -->
						<mat-form-field>
							<mat-label>Credit Card Number</mat-label>
							<input matInput formControlName="cc_number" required>
						</mat-form-field>

						<mat-form-field>
							<mat-label>Expiration Date</mat-label>
							<input matInput formControlName="cc_expiration" required>
						</mat-form-field>

						<mat-form-field>
							<mat-label>CVV</mat-label>
							<input matInput formControlName="cc_cvv" required>
						</mat-form-field>
					</ng-container>

					{{findInvalidControlsRecursive(paymentForm)}}
				</div>

				<div class="mt-3">
					<button class="me-1" mat-button matStepperPrevious>Back</button>
					<button mat-button color="primary" (click)="onSubmit()">Run Payment</button>
				</div>
			</form>
		</mat-step>

		<mat-step>
			<ng-template matStepLabel>Review and Confirmation</ng-template>
			<div class="container">
				<div class="row">
					<div class="col-md-6">
						<section class="gift-certificate-details">
							<h2>Gift Certificate Details</h2>
							<div class="row mb-3">
								<div class="col-sm-4"><strong>Gift Certificate Code:</strong></div>
								<div class="col-sm-8">{{giftCertificateForm.value.giftCertificateCode}}</div>
							</div>
							<div class="row mb-3">
								<div class="col-sm-4"><strong>Gift Certificate Amount:</strong></div>
								<div class="col-sm-8">{{giftCertificateForm.value.giftCertificateAmount | currency}}</div>
							</div>
							<div class="row mb-3">
								<div class="col-sm-4"><strong>Expiration Date:</strong></div>
								<div class="col-sm-8">{{giftCertificateForm.value.expirationDate | date}}</div>
							</div>
							<div class="row mb-3">
								<div class="col-sm-4"><strong>Gift Certificate Notes:</strong></div>
								<div class="col-sm-8">{{giftCertificateForm.value.giftCertificateNotes}}</div>
							</div>
						</section>
					</div>
					<div class="col-md-6">
						<section class="purchaser-information">
							<h2>Purchaser Information</h2>
								<div class="row mb-3">
									<div class="col-sm-4"><strong>Purchaser's Name:</strong></div>
									<div class="col-sm-8">{{giftCertificateForm.value.purchaserName}}</div>
								</div>
								<div class="row mb-3">
									<div class="col-sm-4"><strong>Purchaser's Email:</strong></div>
									<div class="col-sm-8">{{giftCertificateForm.value.purchaserEmail}}</div>
								</div>
								<div class="row mb-3">
									<div class="col-sm-4"><strong>Purchaser's Phone:</strong></div>
									<div class="col-sm-8">{{giftCertificateForm.value.purchaserPhone}}</div>
								</div>
						</section>
					</div>
				</div>
				<div class="row">
					<div class="col-md-6">
						<section class="recipient-information">
							<h2>Recipient Information</h2>
							<div class="row mb-3">
								<div class="col-sm-4"><strong>Recipient's Name:</strong></div>
								<div class="col-sm-8">{{giftCertificateForm.value.field_recipient_name}}</div>
							</div>
							<div class="row mb-3">
								<div class="col-sm-4"><strong>Recipient's Email:</strong></div>
								<div class="col-sm-8">{{giftCertificateForm.value.recipientEmail}}</div>
							</div>
							<div class="row mb-3">
								<div class="col-sm-4"><strong>Recipient's Phone:</strong></div>
								<div class="col-sm-8">{{giftCertificateForm.value.recipientPhone}}</div>
							</div>
						</section>
					</div>
					<div class="col-md-6">
						<section class="payment-information">
							<h2>Payment Information</h2>
							<div class="row mb-3">
								<div class="col-sm-4"><strong>Payment Type:</strong></div>
								<div class="col-sm-8">{{getPaymentTypeName(paymentForm.value.field_payment_type)}}</div>
							</div>
						</section>
					</div>
				</div>
				<div class="row">
					<div class="col-12">
						<section class="total-amount text-center">
							<h2>Total Amount</h2>
							<p class="lead">{{giftCertificateForm.value.giftCertificateAmount | currency}}</p>
						</section>
					</div>
				</div>
				<div class="row mt-4">
					<div class="col-12">
						<button class="me-1" mat-raised-button color="primary" (click)="this.sendGiftCertificateEmail(this.giftCertificateID)" [disabled]="!giftCertificateForm.valid">Send Gift Certificate Email</button>
						<button mat-raised-button color="primary" (click)="this.DialogRef.close();" [disabled]="!giftCertificateForm.valid">Done</button>
					</div>
				</div>
			</div>
		</mat-step>
  </mat-stepper>
</div>
