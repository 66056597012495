import { Component, Input, OnInit } from '@angular/core';
import { FormArray, FormBuilder } from '@angular/forms';

@Component({
  selector: 'app-simple-import-payments',
  templateUrl: './simple-import-payments.component.html',
  styleUrls: ['./simple-import-payments.component.css']
})
export class SimpleImportPaymentsComponent {
  @Input() paymentsArray: FormArray;

  constructor(private fb: FormBuilder) {}

  addPayment() {
    const paymentGroup = this.fb.group({
      // ... payment details ...
    });

    this.paymentsArray.push(paymentGroup);
  }
}
