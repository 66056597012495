<div mat-dialog-title>
	<h1>View Payments For Enrollment #{{this.entityId}}</h1>
	<button mat-button mat-dialog-close>
			<mat-icon aria-hidden="false" aria-label="Example home icon">close</mat-icon>
	</button>
</div>

<div [ngClass]="{
			'loading-shade': showProgressSpinner
	 }">

	<div mat-dialog-content>
		<!-- <app-enrollment-lesson-list-view [enrollmentID]="this.entityId"></app-enrollment-lesson-list-view> -->
		<!-- <app-payments-report-view [enrollmentID]="this.entityId"></app-payments-report-view> -->
		<app-enrollment-view-payment-view [enrollmentID]="this.entityId"></app-enrollment-view-payment-view>
	</div>
</div>
