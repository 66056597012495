import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { ViewTableComponent } from '../../base/view-table/view-table.component';

@Component({
  selector: 'app-payments-made-misc',
  templateUrl: './payments-made-misc.component.html',
  styleUrls: ['./payments-made-misc.component.css']
})
export class PaymentsMadeMiscComponent extends ViewTableComponent implements OnInit {

  currentPage: number;

  override displayedColumns: string[] = [
    'id',
    'field_date_and_time',
    'field_student_name',
    'field_student_department',
    'field_enrollment_package_name_label',
    // 'AMOUNTPAID',
    'field_payment_type_label',
    'field_instructor_label',
    'closer',
  ];

  override getData(params: { parameter: string; value: string; }[]) {
    this.ShowProgressBar = true;
    this.queryParams = params;

    // Add sorting paramaters, if available.
    if (this.sortParams !== undefined) {
      this.queryParams.push(this.sortParams)
    }

    params = [
      ...params,
    ];

    // Load the new data with the filtering parameters.
    let endpoint: string = "/api/v1/reports/cash?field_sps_code=554";

    this._drupalRESTService.httpGET(endpoint, params)
      .subscribe((data) => {
        // ShowProgressBar
        this.ShowProgressBar = false;

        this.dataSource = new MatTableDataSource(data['results']);

        // // Pagination
        if (data['results']) {
          this.paginator.length = data['count'];
          this.paginator.pageSize = this.pageSize;
          this.paginator.pageIndex = this.paginationIndex;
        }
      },
        error => this.handleError(error)
      )
  }
}
