import { Component, Input, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-simple-import-enrollment',
  templateUrl: './simple-import-enrollment.component.html',
  styleUrls: ['./simple-import-enrollment.component.css']
})
export class SimpleImportEnrollmentComponent implements OnInit {
  @Input() enrollments: FormArray;
  @Input() parentFormGroup: FormGroup; // Add this line

  constructor(private fb: FormBuilder) {}

  ngOnInit(): void {
    // Log for debugging purposes
    console.log('Enrollments FormArray:', this.enrollments);

    // Initialize with one enrollment if the array is empty
    if (this.enrollments.length === 0) {
      this.addEnrollment();
    }
  }

  addEnrollment() {
    const enrollmentFormGroup = this.fb.group({
      courseName: ['', Validators.required],
      startDate: [''],
      endDate: [''],
      // Add more fields as required
    });
    this.enrollments.push(enrollmentFormGroup);
  }

  deleteEnrollment(index: number) {
    this.enrollments.removeAt(index);
  }
}
