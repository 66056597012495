import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { DrupalRESTService } from 'src/app/services/drupal-rest.service';
import { environment } from 'src/environments/environment';
import { v4 as uuidv4 } from 'uuid';

declare var Square: any;
@Component({
  selector: 'app-square-web-payment',
  templateUrl: './square-web-payment.component.html',
  styleUrls: ['./square-web-payment.component.css']
})
export class SquareWebPaymentComponent implements OnInit, AfterViewInit {
  @Input() set amount(value: number | string) {
    this._amount = this.parseAmount(value);
  }
  get amount(): number {
    return this._amount;
  }
  private _amount: number = 0;
  @Output() paymentComplete = new EventEmitter<any>();
  @Output() paymentError = new EventEmitter<any>();
  @Input() customerInfo: any;

  private squarePayments: any;
  private squareCard: any;
  private squareConfig: any;

  constructor(private drupalRESTService: DrupalRESTService) {}

  ngOnInit() {
    this.loadSquareConfig();
  }

  ngAfterViewInit() {
    // Card will be initialized after config is loaded
  }

  private parseAmount(value: number | string): number {
    if (typeof value === 'string') {
      return parseFloat(value) || 0;
    }
    return value || 0;
  }

  async processPayment() {
    if (!this.squareCard) {
      console.error('Square card not initialized');
      this.paymentError.emit('Square card not initialized');
      return;
    }

    try {
      const result = await this.squareCard.tokenize();
      if (result.status === 'OK') {
        let verificationToken = null;
        if (false /*this.verifyBuyer()*/) {
          // verificationToken = await this.verifyBuyer(this.squarePayments, result.token);
        }
        const paymentResults = await this.createPayment(result.token, verificationToken);
        this.paymentComplete.emit(paymentResults);
      } else {
        throw new Error(result.errors[0].message);
      }
    } catch (e) {
      this.paymentError.emit(e.message);
    }
  }

  private loadSquareConfig() {
    this.drupalRESTService.httpGET('/api_rest/v1/loadStudioConfig?config_field_name=field_square_ref')
      .subscribe(
        (response) => {
          this.squareConfig = {
            applicationId: response?.['field_square_application_id']?.[0]?.value,
            locationId: response?.['field_square_location_id']?.[0]?.value || '',
            environment: response?.['field_square_environment']?.[0]?.value
          };
          this.initializeSquarePayments();
        },
        (error) => {
          console.error('Error loading Square configuration:', error);
          this.paymentError.emit('Error loading Square configuration');
        }
      );
  }

  private async initializeSquarePayments() {
    if (!Square) {
      console.error('Square.js failed to load properly');
      return;
    }

    try {
      this.squarePayments = Square.payments(this.squareConfig.applicationId, this.squareConfig.locationId);
      this.squareCard = await this.initializeCard(this.squarePayments);
    } catch (e) {
      console.error('Initializing Square payments failed', e);
      this.paymentError.emit('Error initializing Square payments');
    }
  }

  private async initializeCard(payments: any) {
    const card = await payments.card();
    await card.attach('#card-container');
    return card;
  }

  async handlePaymentMethodSubmission() {
    if (!this.squareCard) {
      console.error('Square card not initialized');
      this.paymentError.emit('Square card not initialized');
      return;
    }

    try {
      const token = await this.tokenize(this.squareCard);
      const verificationToken = await this.verifyBuyer(this.squarePayments, token);
      const paymentResults = await this.createPayment(token, verificationToken);
      this.displayPaymentResults('SUCCESS');
      this.paymentComplete.emit(paymentResults);
    } catch (e) {
      this.displayPaymentResults('FAILURE');
      this.paymentError.emit(e.message);
    }
  }

  private async tokenize(paymentMethod: any) {
    const tokenResult = await paymentMethod.tokenize();
    if (tokenResult.status === 'OK') {
      return tokenResult.token;
    } else {
      let errorMessage = `Tokenization failed with status: ${tokenResult.status}`;
      if (tokenResult.errors) {
        errorMessage += ` and errors: ${JSON.stringify(tokenResult.errors)}`;
      }
      throw new Error(errorMessage);
    }
  }

  getFormattedAmount(): string {
    return this.amount.toFixed(2);
  }

  private async verifyBuyer(payments: any, token: string) {
    const verificationDetails = {
      amount: this.getFormattedAmount(),
      currencyCode: 'USD', // Adjust as needed
      intent: 'STORE', // Changed from 'CHARGE' to 'STORE' as per the example
      billingContact: {
        addressLines: [
          this.customerInfo?.address1 || '123 Main Street',
          this.customerInfo?.address2 || 'Apartment 1'
        ],
        familyName: this.customerInfo?.lastName || 'Doe',
        givenName: this.customerInfo?.firstName || 'John',
        email: this.customerInfo?.email || 'jondoe@gmail.com',
        country: this.customerInfo?.country || 'US',
        phone: this.customerInfo?.phone || '3214563987',
        region: this.customerInfo?.state || 'CA',
        city: this.customerInfo?.city || 'San Francisco'
      }
    };

    try {
      console.log('Verifying buyer with:', verificationDetails);
      const verificationResults = await payments.verifyBuyer(token, verificationDetails);
      return verificationResults.token;
    } catch (error) {
      console.error('Error verifying buyer:', error);
      throw error;
    }
  }

  private async createPayment(token: string, verificationToken: string) {
    const paymentData = {
      locationId: this.squareConfig.locationId,
      sourceId: token,
      verificationToken,
      amount: this.amount,
      idempotencyKey: uuidv4(),
    };

    return this.drupalRESTService.httpPOST('/api_rest/v1/createSquarePayment', paymentData).toPromise();
  }

  private displayPaymentResults(status: 'SUCCESS' | 'FAILURE') {
    const statusContainer = document.getElementById('payment-status-container');
    if (statusContainer) {
      statusContainer.className = status === 'SUCCESS' ? 'is-success' : 'is-failure';
      statusContainer.textContent = status === 'SUCCESS' ? 'Payment successful!' : 'Payment failed. Please try again.';
      statusContainer.style.visibility = 'visible';
    }
  }
}
