<table class="mat-table table" *ngIf="dataSource">
    <tr class="mat-header-row">
        <th class="mat-header-cell" *ngFor="let i of
            dataSource['#enrollment']['#header']">
            {{ i }}
        </th>
    </tr>

    <tr class="mat-row" *ngFor="let i of
    dataSource['#enrollment']['#rows']">
        <td class="mat-cell">
            {{ i['1']['saleDate'] }}
        </td>
        <td class="mat-cell">
            {{ i['1']['instructor'] }}
        </td>
        <td class="mat-cell">
            {{ i['1']['category'] }}
        </td>
        <td class="mat-cell">
            {{ i['1']['packageName'] }}
        </td>
        <td class="mat-cell">
            {{ i['1']['#ofLessons'] }}
        </td>
        <td class="mat-cell">
            {{ i['1']['totalPrice'] }}
        </td>
        <td class="mat-cell">
            {{ i['1']['totalPaid'] }}
        </td>
        <td class="mat-cell">
            {{ i['1']['last_payment'] }}
        </td>
        <td class="mat-cell">
            {{ i['1']['lessonAvailable'] }}
        </td>
        <td class="mat-cell">
            <!-- TODO -->
            Hide
        </td>
        <td class="mat-cell">
            <!-- TODO -->
            Edit
        </td>
    </tr>
    <!-- {{ studentDashboardData['#enrollment']['#header'] }} -->
</table>
