import { Component, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatStepper } from '@angular/material/stepper';
import { DrupalRESTService } from 'src/app/services/drupal-rest.service';
import { EntityRESTService } from 'src/app/services/entity-rest.service';
import { TaxonomyService } from 'src/app/services/taxonomy.service';
import { UtilityService } from 'src/app/services/utility.service';

@Component({
  selector: 'app-frm-manage-gift-certificate',
  templateUrl: './frm-manage-gift-certificate.component.html',
  styleUrls: ['./frm-manage-gift-certificate.component.css']
})
export class FrmManageGiftCertificateComponent implements OnInit {
  actionType: string;
  giftCertificateForm: FormGroup;
  purchaserForm: FormGroup;
  recipientForm: FormGroup;
  paymentForm: FormGroup;
  autoCompletePurchaserOptions: any;
  paymentSubmitted = false;
  giftCertificateID: string;

  @ViewChild('stepper') stepper: MatStepper;

  constructor(
    private formBuilder: FormBuilder,
    private entityRestService: EntityRESTService,
    public DialogRef: MatDialogRef<FrmManageGiftCertificateComponent>,
    private utilityService: UtilityService,
    private drupalRESTService: DrupalRESTService,
    public taxonomyService: TaxonomyService,
    private snackBar: MatSnackBar,
  ) { }

  ngOnInit() {
    const currentDate = new Date();
    const expirationDate = new Date(currentDate.setMonth(currentDate.getMonth() + 6));

    this.giftCertificateForm = this.formBuilder.group({
      giftCertificateCode: ['', Validators.required],
      giftCertificateAmount: ['', [Validators.required, Validators.pattern(/^\d+(\.\d{1,2})?$/)]],
      expirationDate: [expirationDate, Validators.required],
      giftCertificateNotes: [''],
      checkoutType: ['', Validators.required],
      purchaserName: [''],
      purchaserEmail: ['', [Validators.required, Validators.email]],
      purchaserPhone: ['', Validators.required],
      giftCertificatePurchaser: [''],
      field_recipient_name: ['', Validators.required],
      recipientEmail: ['', [Validators.required, Validators.email]],
      recipientPhone: ['', Validators.required],
    });

    this.paymentForm = this.formBuilder.group({
      field_payment_type: ['', Validators.required],
      cc_number: [''],
      cc_expiration: [''],
      cc_cvv: [''],
    });

    this.loadUniqueGCCode();
  }

  private loadUniqueGCCode() {
    this.drupalRESTService.httpGET('/api_rest/v1/uniqueGCCode').subscribe(
      (response: any) => {
        if (response.status === 'success') {
          this.giftCertificateForm.patchValue({
            giftCertificateCode: response.code
          });
        }
      },
      (error) => {
        console.error('Error loading unique gift certificate code:', error);
      }
    );
  }

  onSubmit() {
    if (this.giftCertificateForm.valid && this.paymentForm.valid) {
      const expirationDateString = this.giftCertificateForm.value.expirationDate;
      const expirationDate = new Date(expirationDateString);
      const formattedExpirationDate = this.formatDate(expirationDate);

      // Create the payment entity
      const paymentBody = {
        title: 'Payment towards GC',
        field_student_name: this.regexStudentName(this.giftCertificateForm.value.giftCertificatePurchaser),
        field_payment_type: this.paymentForm.value.field_payment_type,
        field_gross_tuition: this.giftCertificateForm.value.giftCertificateAmount,
        field_reference_number: this.paymentForm.value.referenceNumber
      };

      this.entityRestService.createEntity('payments', 'payment', paymentBody)
        .subscribe(
          (paymentResponse) => {
            console.log('Payment created successfully:', paymentResponse);
            this.paymentSubmitted = true;

            // Create the request body for the gift certificate
            const body = {
              title: 'Gift Certificate',
              field_gift_certificate_status: 'active',
              field_gift_certificate_purchaser: this.regexStudentName(this.giftCertificateForm.value.giftCertificatePurchaser),
              field_checkout_type: this.giftCertificateForm.value.checkoutType,
              field_expiration_date: formattedExpirationDate,
              field_purchase_date: this.formatDate(new Date()),
              field_gift_certificate_amount: this.giftCertificateForm.value.giftCertificateAmount,
              field_remaining_balance: this.giftCertificateForm.value.giftCertificateAmount,
              field_gift_certificate_code: this.giftCertificateForm.value.giftCertificateCode,
              field_gift_certificate_notes: this.giftCertificateForm.value.giftCertificateNotes,
              field_recipient_email: this.giftCertificateForm.value.recipientEmail,
              field_recipient_phone: this.giftCertificateForm.value.recipientPhone,
              field_recipient_name: this.giftCertificateForm.value.field_recipient_name,
              field_purchaser_email: this.giftCertificateForm.value.checkoutType === 'existing' ? this.giftCertificateForm.value.purchaserEmail : '',
              field_purchaser_name: this.giftCertificateForm.value.checkoutType === 'existing' ? this.giftCertificateForm.value.purchaserName : '',
              field_purchaser_phone: this.giftCertificateForm.value.checkoutType === 'existing' ? this.giftCertificateForm.value.purchaserPhone : '',
            };

            // Call the createEntity method from EntityRESTService
            this.entityRestService.createEntity('gift_certificates', 'gift_certificate', body)
              .subscribe(
                (response) => {
                  console.log('Gift certificate created successfully:', response);
                  const giftCertificateId = response?.id?.[0]?.value;

                  // Update the payment entity with the gift certificate reference
                  const updatedPaymentBody = {
                    ...paymentBody,
                    field_gift_certificate: [
                      {
                        target_id: giftCertificateId,
                        target_type: 'gift_certificate',
                      },
                    ],
                  };

                  // Update the payment entity with the gift certificate reference
                  this.entityRestService.patchEntity('payments', 'payment', paymentResponse.id[0].value, updatedPaymentBody)
                    .subscribe(
                      (updateResponse) => {
                        console.log('Payment updated successfully with gift certificate reference:', updateResponse);
                        // Go to step 3 after successful update of payment
                        this.goToNextStep(this.DialogRef.componentInstance.stepper);
                      },
                      (updateError) => {
                        console.error('Error updating payment with gift certificate reference:', updateError);
                        // Handle error scenario
                      }
                    );
                },
                (error) => {
                  console.error('Error creating gift certificate:', error);
                  // Handle error scenario
                }
              );
          },
          (error) => {
            console.error('Error creating payment:', error);
            // Handle error scenario
          }
        );
    }
  }

  onCheckoutTypeChange(event: any) {
    const checkoutType = event.value;
    const purchaserNameControl = this.giftCertificateForm.get('purchaserName');
    const purchaserEmailControl = this.giftCertificateForm.get('purchaserEmail');
    const purchaserPhoneControl = this.giftCertificateForm.get('purchaserPhone');
    const giftCertificatePurchaserControl = this.giftCertificateForm.get('giftCertificatePurchaser');

    if (checkoutType === 'guest') {
      purchaserNameControl.setValidators([Validators.required]);
      purchaserEmailControl.setValidators([Validators.required, Validators.email]);
      purchaserPhoneControl.setValidators([Validators.required]);
      giftCertificatePurchaserControl.clearValidators();
      this.giftCertificateForm.patchValue({
        purchaserName: '',
        purchaserEmail: '',
        purchaserPhone: ''
      });
    } else if (checkoutType === 'existing') {
      purchaserNameControl.clearValidators();
      purchaserEmailControl.clearValidators();
      purchaserPhoneControl.clearValidators();
      giftCertificatePurchaserControl.setValidators([Validators.required]);
    }

    purchaserNameControl.updateValueAndValidity();
    purchaserEmailControl.updateValueAndValidity();
    purchaserPhoneControl.updateValueAndValidity();
    giftCertificatePurchaserControl.updateValueAndValidity();
  }

  formatDate(date: Date): string {
    const year = date.getFullYear();
    const month = ('0' + (date.getMonth() + 1)).slice(-2);
    const day = ('0' + date.getDate()).slice(-2);
    const hours = ('0' + date.getHours()).slice(-2);
    const minutes = ('0' + date.getMinutes()).slice(-2);
    const seconds = ('0' + date.getSeconds()).slice(-2);

    return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`;
  }

  isGiftCertificateFormValid(): boolean {
    const form = this.giftCertificateForm;
    const requiredControls = ['giftCertificateCode', 'giftCertificateAmount', 'expirationDate', 'checkoutType'];

    if (form.get('checkoutType').value === 'guest') {
      requiredControls.push('purchaserName', 'purchaserEmail', 'purchaserPhone');
    } else if (form.get('checkoutType').value === 'existing') {
      requiredControls.push('giftCertificatePurchaser');
    }

    requiredControls.push('field_recipient_name', 'recipientEmail', 'recipientPhone');

    return requiredControls.every(controlName => {
      const control = form.get(controlName);
      return control && control.valid;
    });
  }

  onAutocompletePurchaser(event: { target: { value: string } }) {
    let term: string = event.target.value;
    let eckType: string = "student_accounts"; // student_accounts
    let bundle: string = "student_account"; // student_account
    let field: string = "title";
    this.utilityService.getAMTAbstractAutocomplete(term, eckType, bundle, field)
      .subscribe(data => {
        this.autoCompletePurchaserOptions = data;
      })
  }

  /**
 * This function is to validate form controls recursively.
 *
 * @param formToInvestigate
 * @returns
 */
  findInvalidControlsRecursive(formToInvestigate: FormGroup | FormArray): string[] {
    var invalidControls: string[] = [];
    let recursiveFunc = (form: FormGroup | FormArray) => {
      Object.keys(form.controls).forEach(field => {
        const control = form.get(field);
        if (control.invalid) invalidControls.push(field);
        if (control instanceof FormGroup) {
          recursiveFunc(control);
        } else if (control instanceof FormArray) {
          recursiveFunc(control);
        }
      });
    }
    recursiveFunc(formToInvestigate);
    return invalidControls;
  }

  goToNextStep(stepper: MatStepper) {
    if (this.isGiftCertificateFormValid()) {
      stepper.next();
    }
  }

  regexStudentName(field_student_name: string) {
    if (!field_student_name) { return; }
    if (!isNaN(+field_student_name)) { return; }

    // Adjusted regex to match the content of the last set of parentheses
    let regExp = /\(([^)]+)\)(?!.*\()/;
    let matches = regExp.exec(field_student_name);

    if (!matches) { return; }

    return matches[1];
  }

  getPaymentTypeName(paymentTypeId: string): string {
    const paymentType = this.taxonomyService.payment_type.find(type => type.id === paymentTypeId);
    return paymentType ? paymentType.name : '';
  }

  lookupStudentAccount(event: any, populateFields: boolean = false) {
    console.log('Event:', event.source.value);

    if (event && event.source && event.source.value) {
      const selectedOption = event.source.value;
      const matches = /\(([^)]+)\)(?!.*\()/.exec(selectedOption);

      if (matches) {
        const studentAccountId = matches[1];

        this.entityRestService.getEntity('student_accounts', 'student_account', studentAccountId)
          .subscribe(
            (response: any) => {
              if (populateFields) {
                console.log('Student account data:', response);

                if (Array.isArray(response.field_contacts)) {
                  const primaryContact = response.field_contacts.find((contact: any) => contact.field_first_name && contact.field_last_name);

                  if (primaryContact) {
                    this.giftCertificateForm.patchValue({
                      purchaserName: primaryContact.field_first_name + ' ' + primaryContact.field_last_name,
                      purchaserEmail: primaryContact.field_email,
                      purchaserPhone: primaryContact.field_cell_phone ? primaryContact.field_cell_phone : primaryContact.field_home_phone,
                    });
                  } else {
                    this.giftCertificateForm.patchValue({
                      purchaserName: '',
                      purchaserEmail: '',
                      purchaserPhone: '',
                    });
                  }
                } else {
                  this.giftCertificateForm.patchValue({
                    purchaserName: response.field_contacts?.field_first_name + ' ' + response.field_contacts?.field_last_name,
                    purchaserEmail: response.field_contacts?.field_email,
                    purchaserPhone: response.field_contacts?.field_cell_phone ? response.field_contacts?.field_cell_phone : response.field_contacts?.field_home_phone,
                  });
                }

                console.log('Purchase name:', this.giftCertificateForm.get('purchaserName').value);
                console.log('Purchase email:', this.giftCertificateForm.get('purchaserEmail').value);
                console.log('Purchase phone:', this.giftCertificateForm.get('purchaserPhone').value);
              }
            },
            (error) => {
              console.error('Error retrieving student account data:', error);
            }
          );
      }
    }
  }

  sendGiftCertificateEmail(giftCertificateId: string) {
    this.drupalRESTService.httpGET('/api_rest/v1/sendGiftCertificateEmail?giftCertificateId=' + giftCertificateId)
      .subscribe(
        data => {
          console.log('API response:', data);
          if (data['status'] === 'error') {
            const errorMessage = data['message'] || 'An error occurred while sending the email.';
            // Snackbar message that is centered.
            this.snackBar.open(errorMessage, 'Close', {
              duration: 3000,
              horizontalPosition: 'center',
            });
          } else {
            // Snackbar message that is centered.
            this.snackBar.open('Gift certificate email sent successfully.', 'Close', {
              duration: 3000,
              horizontalPosition: 'center',
            });
          }
        },
        error => {
          console.error('Error sending email:', error);
          // Snackbar message that is centered.
          this.snackBar.open('An error occurred while sending the email.', 'Close', {
            duration: 3000,
            horizontalPosition: 'center',
          });
        }
      );
  }
}
