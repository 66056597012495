import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

interface GenderizeResponse {
  name: string;
  gender: string;
  probability: number;
  count: number;
}

@Injectable({
  providedIn: 'root'
})
export class GenderDetectionService {

  private apiUrl: string = 'https://api.genderize.io/';

  constructor(private http: HttpClient) { }

  getGender(name: string, countryId?: string): Observable<GenderizeResponse> {
    let url = `${this.apiUrl}?name=${encodeURIComponent(name)}`;
    if (countryId) {
      url += `&country_id=${encodeURIComponent(countryId)}`;
    }
    return this.http.get<GenderizeResponse>(url);
  }

  getGenderForMultipleNames(names: string[], countryId?: string): Observable<GenderizeResponse[]> {
    let url = `${this.apiUrl}?`;
    names.forEach((name, index) => {
      url += `name[]=${encodeURIComponent(name)}`;
      if (index < names.length - 1) {
        url += '&';
      }
    });
    if (countryId) {
      url += `&country_id=${encodeURIComponent(countryId)}`;
    }
    return this.http.get<GenderizeResponse[]>(url);
  }
}
