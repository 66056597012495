import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormArray } from '@angular/forms';
import { BaseComponent } from 'src/app/components/forms/base-form/base-form.component';

@Component({
  selector: 'app-simple-import-instructor-wrapper',
  templateUrl: './simple-import-instructor-wrapper.component.html',
  styleUrls: ['./simple-import-instructor-wrapper.component.css']
})
export class SimpleImportInstructorWrapperComponent extends BaseComponent implements OnInit {

  fb = new FormBuilder();

  instructorForm = this.fb.group({
    instructors: this.fb.array([])
  });

  ngOnInit(): void {
    this.addInstructor();
  }

  get instructorArray() {
    return this.instructorForm.get('instructors') as FormArray;
  }

  addInstructor() {
    const instructorGroup = this.fb.group({
      email: '',
      lastName: '',
      firstName: '',
      userName: '',
      amiId: ''
    });

    this.instructorArray.push(instructorGroup);
  }

  // Implement the onSubmit method to handle form submission
  override onSubmit() {
    console.log(this.instructorForm.value);
  }

}
