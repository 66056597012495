import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import moment from 'moment';
import { ViewTableComponent } from '../../base/view-table/view-table.component';

@Component({
  selector: 'app-payments-past-due',
  templateUrl: './payments-past-due.component.html',
  styleUrls: ['./payments-past-due.component.css']
})
export class PaymentsPastDueComponent extends ViewTableComponent implements OnInit {

  currentPage: number;

  override displayedColumns: string[] = [
    'field_payment_date_value',
    'field_student_target_id',
    'title',
    // 'field_balance_due_value',
    'field_balance_due',
    'field_total_payments_made',
    // 'field_total_paid_value',
    'field_lesson_available_value',
    'field_instructor_target_id',
    // 'instructor_last_name',
  ];

  override getData(params: { parameter: string; value: string; }[]) {
    this.ShowProgressBar = true;
    this.queryParams = params;

    // Add sorting paramaters, if available.
    if (this.sortParams !== undefined) {
      this.queryParams.push(this.sortParams)
    }

    params = [
      ...params,
    ];

    // Alter start_date and end_date params for Drupal.
    let start_date;
    let end_date;
    let field_payment_date;

    if (params) {
      for (const property in params) {
        if (params[property]?.['parameter'] == 'start_date') {
          start_date = moment(params[property]?.['value']).format('YYYY-MM-DD').toString();
        }
        if (params[property]?.['parameter'] == 'end_date') {
          end_date = moment(params[property]?.['value']).format('YYYY-MM-DD').toString();
        }
      }

      field_payment_date = {
        parameter: 'field_payment_date',
        value: start_date + "--" + end_date,
      }
    }

    if (field_payment_date && field_payment_date?.['value'] != 'undefined--undefined')  {
      params = [
        ...params,
        field_payment_date
      ];
    }

    // Load the new data with the filtering parameters.
    // let endpoint: string = "/api/v1/reports/enrollments-payments-overdue/730";
    let endpoint: string = "/api/v1/reports/enrollments-payments-overdue";

    this._drupalRESTService.httpGET(endpoint, params)
      .subscribe((data) => {
        // ShowProgressBar
        this.ShowProgressBar = false;

        this.dataSource = new MatTableDataSource(data['results']);

        // // Pagination
        if (data['results']) {
          this.paginator.length = data['count'];
          this.paginator.pageSize = this.pageSize;
          this.paginator.pageIndex = this.paginationIndex;
        }
      },
        error => this.handleError(error)
      )
  }
}
