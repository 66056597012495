import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { ViewTableComponent } from '../../base/view-table/view-table.component';
import { FrmManageGiftCertificateComponent } from '../../forms/frm-manage-gift-certificate/frm-manage-gift-certificate.component';
import { trigger, state, style, transition, animate } from '@angular/animations';

@Component({
  selector: 'app-gift-certificates',
  templateUrl: './gift-certificates.component.html',
  styleUrls: ['./gift-certificates.component.css'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', overflow: 'hidden', })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class GiftCertificatesComponent extends ViewTableComponent implements OnInit {
  currentPage: number;
  frmManageGiftCertificateComponent = FrmManageGiftCertificateComponent;

  links = [
    { label: 'Active', path: '/configuration/gift-certificates', queryParams: { status: 'Active' } },
    { label: 'Inactive', path: '/configuration/gift-certificates', queryParams: { status: 'Inactive' } }
  ];

  activeLink = this.links[0];

  isExpansionDetailRow = (index: number, row: any) => row.hasOwnProperty('expanded');
  expandedElement: any | null;
  expandedElements: { [key: string]: boolean } = {};

  override displayedColumns: string[] = [
    'expand',
    'field_gift_certificate_code',
    'field_gift_certificate_amount',
    'field_remaining_balance',
    'field_gift_certificate_status',
    'field_purchase_date',
    'field_expiration_date',
    'edit',
  ];

  override ngOnInit() {
    this.activeLink = this.links[0];

    this._activatedRoute.queryParams.subscribe(params => {
      const status = params['status'];
      if (status === 'Active') {
        this.activeLink = this.links[0];
      } else if (status === 'Inactive') {
        this.activeLink = this.links[1];
      }

      const queryParams = status ? [{ parameter: 'status', value: status }] : [];
      this.getData(queryParams);
    });
  }

  override getData(params: { parameter: string; value: string; }[]) {
    this.ShowProgressBar = true;
    this.queryParams = params;

    let filters = '?sort_order=ASC&sort_by=field_purchase_date_value';
    if (params) {
      for (const param of params) {
        if (param.parameter === 'status') {
          filters += `&field_gift_certificate_status=${param.value}`;
        }
      }
    }

    const endpoint: string = "/api_rest/v1/gift_certificates" + filters;

    this._drupalRESTService.httpGET(endpoint, params)
      .subscribe(
        (data) => {
          console.log('Received data:', data); // Log the received data
          this.ShowProgressBar = false;
          // Add the 'expanded' property to each element
          data['results'].forEach((element: any) => element.expanded = false);
          this.dataSource = new MatTableDataSource(data['results']);
          if (data['results']) {
            // this.setupPagination(data, params);
          }
        },
        error => this.handleError(error)
      );
  }

  openGiftCertificateDialog(id: string, action: string, dialogConfig: any = { height: "auto", width: "600px", closeOnNavigation: true }) {
    dialogConfig.data = { ...dialogConfig.data, id, action };

    let dialogRef = this._dialogService.openDialog(this.frmManageGiftCertificateComponent, "defaultXL+", dialogConfig).afterClosed().subscribe(data => {
      this.getData(this.queryParams);
    });
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.dataSource.data, event.previousIndex, event.currentIndex);
    this.dataSource = new MatTableDataSource(this.dataSource.data);

    const updatedOrder = this.dataSource.data.map(item => item?.['id']);
    console.log("updatedOrder", updatedOrder);

    this._drupalRESTService.httpPOST('/api_rest/v1/updateGiftCertificateSortOrder', { giftCertificates: updatedOrder })
      .subscribe(response => {
        console.log(response);
      }, error => {
        console.error('Error updating gift certificate order', error);
      });
  }

  toggleExpansion(element: any): void {
    console.log('Element Expanded State:', element.expanded);
  }

  toggleRow(element: any) {
    this.expandedElements[element.id] = !this.expandedElements[element.id];
  }
}
