import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DrupalRESTService } from 'src/app/services/drupal-rest.service';
import { Editor, TinyMCE } from 'tinymce';

@Component({
  selector: 'app-email-configuration-settings',
  templateUrl: './email-configuration-settings.component.html',
  styleUrls: ['./email-configuration-settings.component.css']
})
export class EmailConfigurationSettingsComponent implements OnInit {
  emailConfigForm: FormGroup;
  configFieldName = 'field_email_template_reference';
  showProgressSpinner = false;
  successMessage = '';
  errorMessage = '';
  tinyMceConfig = {
    base_url: '/tinymce/',
    height: 500,
    menubar: false,
    plugins: [
      'lists link image charmap preview anchor',
      'searchreplace visualblocks code fullscreen',
      'insertdatetime media table paste code help wordcount'
    ],
    toolbar:
      'undo redo | bold italic underline strikethrough | \
      alignleft aligncenter alignright alignjustify | \
      bullist numlist outdent indent | removeformat | help | placeholders',
    setup: (editor: Editor) => {
      editor.ui.registry.addMenuButton('placeholders', {
        text: 'Placeholders',
        fetch: (callback) => {
          const items: Array<{ type: 'menuitem'; text: string; onAction: () => void }> = [
            { type: 'menuitem', text: 'Student Name', onAction: () => editor.insertContent('__studentName__') },
            { type: 'menuitem', text: 'Student First Name', onAction: () => editor.insertContent('__studentFirstName__') },
            { type: 'menuitem', text: 'Student Last Name', onAction: () => editor.insertContent('__studentLastName__') },
            { type: 'menuitem', text: 'Date Time', onAction: () => editor.insertContent('__dateTime__') },
            { type: 'menuitem', text: 'Instructor Name', onAction: () => editor.insertContent('__instructorName__') },
            { type: 'menuitem', text: 'Studio Name', onAction: () => editor.insertContent('__studioName__') },
            { type: 'menuitem', text: 'Studio Address', onAction: () => editor.insertContent('__studioAddress__') },
            { type: 'menuitem', text: 'Studio Email', onAction: () => editor.insertContent('__studioEmail__') },
            { type: 'menuitem', text: 'Add To Calendar Links', onAction: () => editor.insertContent('__AddToCalendar__') }
          ];
          callback(items);
        }
      });
    }
  };

  constructor(
    private formBuilder: FormBuilder,
    private drupalRESTService: DrupalRESTService
  ) { }

  ngOnInit() {
    this.initializeForm();
    this.loadEmailConfiguration();
  }

  initializeForm() {
    this.emailConfigForm = this.formBuilder.group({
      enableNewEnrollment: [false],
      newEnrollmentSubject: [''],
      newEnrollmentTemplate: [''],
      enableLessonConfirmation: [false],
      lessonConfirmationSubject: [''],
      lessonConfirmationTemplate: [''],
      enableLessonRescheduled: [false],
      lessonRescheduledSubject: [''],
      lessonRescheduledTemplate: [''],
      enableLessonReminder: [false],
      lessonReminderSubject: [''],
      lessonReminderEmailTemplate: [''],
      enable24HourReminder: [false],
      enable48HourReminder: [false],
      enable72HourReminder: [false],
      enableFirstTimeLessonThankYou: [false],
      firstTimeLessonThankYouSubject: [''],
      firstTimeLessonThankYouTemplate: ['']
    });
  }

  loadEmailConfiguration() {
    this.drupalRESTService
      .httpGET(`/api_rest/v1/loadStudioConfig?config_field_name=${this.configFieldName}`)
      .subscribe(
        (response) => {
          const intervals = response?.['field_lesson_reminder_intervals'] || [];
          const enable24HourReminder = intervals.some(interval => interval.value === '24_hour');
          const enable48HourReminder = intervals.some(interval => interval.value === '48_hour');
          const enable72HourReminder = intervals.some(interval => interval.value === '72_hour');

          this.emailConfigForm.patchValue({
            enableNewEnrollment: response?.['field_enable_new_enrollment']?.length > 0 ? response?.['field_enable_new_enrollment']?.[0]?.value : false,
            newEnrollmentSubject: response?.['field_new_enrollment_subject']?.length > 0 ? response?.['field_new_enrollment_subject']?.[0]?.value : this.getDefaultNewEnrollmentSubject(),
            newEnrollmentTemplate: response?.['field_new_enrollment_template']?.length > 0 ? response?.['field_new_enrollment_template']?.[0]?.value : this.getDefaultNewEnrollmentTemplate(),
            enableLessonConfirmation: response?.['field_enable_lesson_confirmation']?.length > 0 ? response?.['field_enable_lesson_confirmation']?.[0]?.value : false,
            lessonConfirmationSubject: response?.['field_lesson_confirmation_subjec']?.length > 0 ? response?.['field_lesson_confirmation_subjec']?.[0]?.value : '',
            lessonConfirmationTemplate: response?.['field_lesson_confirmation_templa']?.length > 0 ? response?.['field_lesson_confirmation_templa']?.[0]?.value : '',
            enableLessonRescheduled: response?.['field_enable_lesson_rescheduled']?.length > 0 ? response?.['field_enable_lesson_rescheduled']?.[0]?.value : false,
            lessonRescheduledSubject: response?.['field_lesson_rescheduled_subject']?.length > 0 ? response?.['field_lesson_rescheduled_subject']?.[0]?.value : '',
            lessonRescheduledTemplate: response?.['field_lesson_rescheduled_templat']?.length > 0 ? response?.['field_lesson_rescheduled_templat']?.[0]?.value : '',
            enableLessonReminder: response?.['field_enable_lesson_reminder']?.length > 0 ? response?.['field_enable_lesson_reminder']?.[0]?.value : false,
            lessonReminderSubject: response?.['field_lesson_reminder_subject']?.length > 0 ? response?.['field_lesson_reminder_subject']?.[0]?.value : this.getDefaultLessonReminderSubject(),
            lessonReminderEmailTemplate: response?.['field_lesson_reminder_template']?.length > 0 ? response?.['field_lesson_reminder_template']?.[0]?.value : this.getDefaultLessonReminderTemplate(),
            enable24HourReminder,
            enable48HourReminder,
            enable72HourReminder,
            enableFirstTimeLessonThankYou: response?.['field_enable_first_time_lesson']?.length > 0 ? response?.['field_enable_first_time_lesson']?.[0]?.value : false,
            firstTimeLessonThankYouSubject: response?.['field_first_time_lesson_subject']?.length > 0 ? response?.['field_first_time_lesson_subject']?.[0]?.value : this.getDefaultFirstTimeLessonThankYouSubject(),
            firstTimeLessonThankYouTemplate: response?.['field_first_time_lesson_template']?.length > 0 ? response?.['field_first_time_lesson_template']?.[0]?.value : this.getDefaultFirstTimeLessonThankYouTemplate()
          });
        },
        (error) => {
          console.error('Error loading email configuration:', error);
        }
      );
  }

  onSubmit() {
    if (this.emailConfigForm.valid) {
      const postData = {
        config_field_name: this.configFieldName,
        config_data: this.prepareConfigData()
      };

      this.showProgressSpinner = true;
      this.successMessage = '';
      this.errorMessage = '';

      this.drupalRESTService
        .httpPOST('/api_rest/v1/saveStudioConfig', postData)
        .subscribe(
          (response) => {
            console.log('Email configuration saved successfully:', response);
            this.successMessage = 'Email configuration saved successfully.';
            this.showProgressSpinner = false;
          },
          (error) => {
            console.error('Error saving email configuration:', error);
            this.errorMessage = 'Error saving email configuration. Please try again.';
            this.showProgressSpinner = false;
          }
        );
    }
  }

  prepareConfigData() {
    const configData = this.emailConfigForm.value;

    const lessonReminderIntervals = [];

    if (configData.enable24HourReminder) {
      lessonReminderIntervals.push({ value: '24_hour' });
    }
    if (configData.enable48HourReminder) {
      lessonReminderIntervals.push({ value: '48_hour' });
    }
    if (configData.enable72HourReminder) {
      lessonReminderIntervals.push({ value: '72_hour' });
    }

    return {
      field_enable_new_enrollment: configData.enableNewEnrollment ? [{ value: true }] : [],
      field_new_enrollment_subject: configData.newEnrollmentSubject ? [{ value: configData.newEnrollmentSubject }] : [],
      field_new_enrollment_template: configData.newEnrollmentTemplate ? [{ value: configData.newEnrollmentTemplate }] : [],
      field_enable_lesson_confirmation: configData.enableLessonConfirmation ? [{ value: true }] : [],
      field_lesson_confirmation_subjec: configData.lessonConfirmationSubject ? [{ value: configData.lessonConfirmationSubject }] : [],
      field_lesson_confirmation_templa: configData.lessonConfirmationTemplate ? [{ value: configData.lessonConfirmationTemplate }] : [],
      field_enable_lesson_rescheduled: configData.enableLessonRescheduled ? [{ value: true }] : [],
      field_lesson_rescheduled_subject: configData.lessonRescheduledSubject ? [{ value: configData.lessonRescheduledSubject }] : [],
      field_lesson_rescheduled_templat: configData.lessonRescheduledTemplate ? [{ value: configData.lessonRescheduledTemplate }] : [],
      field_enable_lesson_reminder: configData.enableLessonReminder ? [{ value: true }] : [],
      field_lesson_reminder_subject: configData.lessonReminderSubject ? [{ value: configData.lessonReminderSubject }] : [],
      field_lesson_reminder_template: configData.lessonReminderEmailTemplate ? [{ value: configData.lessonReminderEmailTemplate }] : [],
      field_lesson_reminder_intervals: lessonReminderIntervals,
      field_enable_first_time_lesson: configData.enableFirstTimeLessonThankYou ? [{ value: true }] : [],
      field_first_time_lesson_subject: configData.firstTimeLessonThankYouSubject ? [{ value: configData.firstTimeLessonThankYouSubject }] : [],
      field_first_time_lesson_template: configData.firstTimeLessonThankYouTemplate ? [{ value: configData.firstTimeLessonThankYouTemplate }] : []
    };
  }

  getDefaultLessonReminderSubject(): string {
    return `Lesson Reminder: __dateTime__`;
  }

  getDefaultNewEnrollmentSubject(): string {
    return `Exciting News! Your Enrollment at __studioName__ is Confirmed`;
  }

  getDefaultLessonReminderTemplate(): string {
    return `Hello __studentFirstName__ __studentLastName__,<br />
    <br />
  Just a friendly reminder that you have a lesson scheduled for __dateTime__ with __instructorName__.<br />
  <br />
  Please make sure to arrive on time and prepared. If you have any questions or need to reschedule, don't hesitate to get in touch.<br />
  <br />
  __AddToCalendar__
  <br />
  <br />
  See you soon!`;
  }

  getDefaultNewEnrollmentTemplate(): string {
    return `Hello __studentFirstName__ __studentLastName__,<br />
    <br />
    Congratulations on your newest enrollment at __studioName__!<br />
  <br />
  - Location: __studioAddress__<br />
  <br />
  ### We're Here for You<br />
  Should you have any questions or need assistance as you prepare for your first class, please don't hesitate to reach out.
  <br />
  We're so excited to have you join us, __studentName__.<br />
  <br />
  See you soon!`;
  }

  getDefaultFirstTimeLessonThankYouSubject(): string {
    return `Thank You for Your First Lesson at __studioName__`;
  }

  getDefaultFirstTimeLessonThankYouTemplate(): string {
    return `Hello __studentFirstName__ __studentLastName__,<br />
    <br />
  Thank you for attending your first lesson at __studioName__! We hope you enjoyed your experience.<br />
  <br />
  If you have any questions or feedback, feel free to reach out. We look forward to seeing you at your next lesson!<br />
  <br />
  __AddToCalendar__
  <br />
  <br />
  Best Regards,<br />
  __studioName__ Team`;
  }


}
