<mat-chip-list #chipList aria-label="Filter keywords" multiple>

    <mat-chip
        *ngFor="let parameter of prettyParameters"
        (removed)="removeKeyword(parameter)"
        [hidden]="hiddenParameters.includes(parameter.parameter)">
      {{ parameter.parameter }}:
      {{ parameter.value }}
    </mat-chip>

</mat-chip-list>
