import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { DrupalRESTService } from 'src/app/services/drupal-rest.service';

@Component({
  selector: 'app-form-twilio-settings',
  templateUrl: './form-twilio-settings.component.html',
  styleUrls: ['./form-twilio-settings.component.css']
})
export class FormTwilioSettingsComponent implements OnInit {
  twilioSettingsForm: FormGroup;
  actionType: 'create' | 'edit' = 'create';
  showProgressSpinner = false;
  successMessage: string;
  errorMessage: string;
  twilioModes = ['Test', 'Live'];

  constructor(
    private fb: FormBuilder,
    private drupalRESTService: DrupalRESTService
  ) {}

  ngOnInit(): void {
    this.twilioSettingsForm = this.fb.group({
      twilioAccountSIDTest: [''],
      twilioAccountSIDLive: [''],
      twilioAuthTokenTest: [''],
      twilioAuthTokenLive: [''],
      twilioPhoneNumberTest: [''],
      twilioPhoneNumberLive: [''],
      twilioMode: ['']
    });

    this.loadTwilioSettings();
  }

  loadTwilioSettings() {
    const configFieldName = 'field_twilio_ref';

    this.drupalRESTService
      .httpGET(`/api_rest/v1/loadStudioConfig?config_field_name=${configFieldName}`)
      .subscribe(
        (response) => {
          const formData = {
            twilioAccountSIDTest: response?.['field_twilio_account_sid_test']?.[0]?.value,
            twilioAccountSIDLive: response?.['field_twilio_account_sid_live']?.[0]?.value,
            twilioAuthTokenTest: response?.['field_twilio_auth_token_test']?.[0]?.value,
            twilioAuthTokenLive: response?.['field_twilio_auth_token_live']?.[0]?.value,
            twilioPhoneNumberTest: response?.['field_twilio_phone_number_test']?.[0]?.value,
            twilioPhoneNumberLive: response?.['field_twilio_phone_number_live']?.[0]?.value,
            twilioMode: response?.['field_twilio_mode']?.[0]?.value
          };

          this.twilioSettingsForm.patchValue(formData);
        },
        (error) => {
          console.error('Error loading Twilio settings:', error);
          this.errorMessage = 'Error loading Twilio settings.';
        }
      );
  }

  saveTwilioSettings() {
    const configFieldName = 'field_twilio_ref';
    const formValues = this.twilioSettingsForm.value;

    const postData = {
      config_field_name: configFieldName,
      config_data: {
        field_twilio_account_sid_test: [{value: formValues.twilioAccountSIDTest}],
        field_twilio_account_sid_live: [{value: formValues.twilioAccountSIDLive}],
        field_twilio_auth_token_test: [{value: formValues.twilioAuthTokenTest}],
        field_twilio_auth_token_live: [{value: formValues.twilioAuthTokenLive}],
        field_twilio_phone_number_test: [{value: formValues.twilioPhoneNumberTest}],
        field_twilio_phone_number_live: [{value: formValues.twilioPhoneNumberLive}],
        field_twilio_mode: [{value: formValues.twilioMode}]
      }
    };

    this.showProgressSpinner = true;
    this.successMessage = '';
    this.errorMessage = '';

    this.drupalRESTService
      .httpPOST('/api_rest/v1/saveStudioConfig', postData)
      .subscribe(
        (response) => {
          console.log('Twilio settings saved successfully:', response);
          this.successMessage = 'Twilio settings saved successfully.';
          this.showProgressSpinner = false;
        },
        (error) => {
          console.error('Error saving Twilio settings:', error);
          this.errorMessage = 'Error saving Twilio settings.';
          this.showProgressSpinner = false;
        }
      );
  }

  onSubmit() {
    this.saveTwilioSettings();
  }

  onUpdate() {
    this.saveTwilioSettings();
  }
}
