<h2>{{this.data?.['name']}}</h2>
<table class="mt-4 mat-table">
	<thead>
		<tr class="mat-header-row">
			<th class="mat-header-cell">
				&nbsp;
			</th>

			<th class="mat-header-cell">
				PRE
			</th>

			<th class="mat-header-cell">
				ORI
			</th>

			<th class="mat-header-cell">
				EXT
			</th>

			<th class="mat-header-cell">
				REN
			</th>

			<th class="mat-header-cell">
				MISC
			</th>

			<th class="mat-header-cell">
				NON UNIT
			</th>

			<!-- <th class="mat-header-cell">
				PORI
			</th> -->

			<th class="mat-header-cell">
				SUNDRY
			</th>

			<th class="mat-header-cell">
				REFUNDS
			</th>

			<th class="mat-header-cell">
				Total
			</th>
		</tr>
	</thead>


	<tr class="mat-row">
		<td class="mat-cell">
			(WEEK)
		</td>
		<td class="mat-cell">
			${{this.data?.['WEEK_PRE']}}
		</td>
		<td class="mat-cell">
			${{this.data?.['WEEK_ORI']}}
		</td>
		<td class="mat-cell">
			${{this.data?.['WEEK_EXT']}}
		</td>
		<td class="mat-cell">
			${{this.data?.['WEEK_REN']}}
		</td>
		<td class="mat-cell">
			${{this.data?.['WEEK_MISC']}}
		</td>
		<td class="mat-cell">
			${{this.data?.['WEEK_NONUNIT']}}
		</td>
		<!-- <td class="mat-cell">
			${{this.data?.['WEEK_PORI']}}
		</td> -->
		<td class="mat-cell">
			${{this.data?.['WEEK_SUNDRY']}}
		</td>
		<td class="mat-cell">
			${{this.data?.['WEEK_REFUND']}}
		</td>
		<td class="mat-cell">
			${{ this.data?.['WEEK_PRE'] + this.data?.['WEEK_ORI'] + this.data?.['WEEK_EXT'] +
			this.data?.['WEEK_MISC'] + this.data?.['WEEK_NONUNIT'] +
			this.data?.['WEEK_SUNDRY'] + this.data?.['WEEK_REN'] + this.data?.['WEEK_REFUND'] }}
		</td>
	</tr>

	<tr class="mat-row">
		<td class="mat-cell">
			(YTD)
		</td>
		<td class="mat-cell">
			${{this.data?.['PRE']}}
		</td>
		<td class="mat-cell">
			${{this.data?.['ORI']}}
		</td>
		<td class="mat-cell">
			${{this.data?.['EXT']}}
		</td>
		<td class="mat-cell">
			${{this.data?.['REN']}}
		</td>
		<td class="mat-cell">
			${{this.data?.['MISC']}}
		</td>
		<td class="mat-cell">
			${{this.data?.['NONUNIT']}}
		</td>
		<!-- <td class="mat-cell">
			${{this.data?.['PORI']}}
		</td> -->
		<td class="mat-cell">
			${{this.data?.['SUNDRY']}}
		</td>
		<td class="mat-cell">
			${{this.data?.['REFUND']}}
		</td>
		<td class="mat-cell">
			${{ this.data?.['PRE'] + this.data?.['ORI'] + this.data?.['EXT'] +
				this.data?.['MISC'] + this.data?.['NONUNIT'] +
				this.data?.['SUNDRY'] + this.data?.['REN'] + this.data?.['REFUND']  }}
		</td>
	</tr>

</table>
