import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-orphaned-lesson-report',
  templateUrl: './orphaned-lesson-report.component.html',
  styleUrls: ['./orphaned-lesson-report.component.css']
})
export class OrphanedLessonReportComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
