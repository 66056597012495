import { Component, Input, OnInit } from '@angular/core';
import { BaseComponent } from '../../forms/base-form/base-form.component';

@Component({
  selector: 'app-warning-message',
  templateUrl: './warning-message.component.html',
  styleUrls: ['./warning-message.component.css']
})
export class WarningMessageComponent extends BaseComponent {
  @Input() message: any;
}

