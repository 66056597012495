<mat-form-field class="full-width-field">
    <mat-label>{{Label}}</mat-label>
    <input
        name="{{Name}}"
        (input)="onSubmit($event)"
        matInput
        ngModel
        [ngModelOptions]="{standalone: true}"
        [matAutocomplete]="auto"
        (blur)="onSubmit($event)">
        <button matSuffix mat-icon-button aria-label="{[MatIcon]}">
            <mat-icon>{{ MatIcon }}</mat-icon>
        </button>
</mat-form-field>
<mat-autocomplete #auto="matAutocomplete"
        (optionSelected)="onSubmit($event)">
    <mat-option 
        *ngFor="let option of autoCompleteOptions"
        [value]=""
        >
        {{ option }}
    </mat-option>
</mat-autocomplete>