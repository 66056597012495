import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { DrupalRESTService } from 'src/app/services/drupal-rest.service';

@Component({
  selector: 'app-form-stripe-settings',
  templateUrl: './form-stripe-settings.component.html',
  styleUrls: ['./form-stripe-settings.component.css']
})
export class FormStripeSettingsComponent implements OnInit {
  stripeSettingsForm: FormGroup;
  actionType: 'create' | 'edit' = 'create';
  showProgressSpinner = false;
  successMessage: string;
  errorMessage: string;
  stripeModes = ['Test', 'Live'];

  constructor(
    private fb: FormBuilder,
    private drupalRESTService: DrupalRESTService
  ) {}

  ngOnInit(): void {
    this.stripeSettingsForm = this.fb.group({
      stripeSecretKeyTest: ['', Validators.required],
      stripeSecretKeyLive: ['', Validators.required],
      stripePublicKeyTest: ['', Validators.required],
      stripePublicKeyLive: ['', Validators.required],
      stripeMode: ['', Validators.required]
    });

    this.loadStripeSettings();
  }

  loadStripeSettings() {
    const configFieldName = 'field_stripe_ref';
    this.drupalRESTService
      .httpGET(`/api_rest/v1/loadStudioConfig?config_field_name=${configFieldName}`)
      .subscribe(
        (response) => {
          const formData = {
            stripeSecretKeyTest: response?.['field_stripe_secret_key_test']?.[0]?.value,
            stripeSecretKeyLive: response?.['field_stripe_secret_key_live']?.[0]?.value,
            stripePublicKeyTest: response?.['field_stripe_public_key_test']?.[0]?.value,
            stripePublicKeyLive: response?.['field_stripe_public_key_live']?.[0]?.value,
            stripeMode: response?.['field_stripe_mode']?.[0]?.value
          };

          this.stripeSettingsForm.patchValue(formData);
        },
        (error) => {
          console.error('Error loading Stripe settings:', error);
          this.errorMessage = 'Error loading Stripe settings.';
        }
      );
  }

  saveStripeSettings() {
    const configFieldName = 'field_stripe_ref';
    const formValues = this.stripeSettingsForm.value;

    const postData = {
      config_field_name: configFieldName,
      config_data: {
        field_stripe_secret_key_test: [{value: formValues.stripeSecretKeyTest}],
        field_stripe_secret_key_live: [{value: formValues.stripeSecretKeyLive}],
        field_stripe_public_key_test: [{value: formValues.stripePublicKeyTest}],
        field_stripe_public_key_live: [{value: formValues.stripePublicKeyLive}],
        field_stripe_mode: [{value: formValues.stripeMode}]
      }
    };

    this.showProgressSpinner = true;
    this.successMessage = '';
    this.errorMessage = '';

    this.drupalRESTService
      .httpPOST('/api_rest/v1/saveStudioConfig', postData)
      .subscribe(
        (response) => {
          console.log('Stripe settings saved successfully:', response);
          this.successMessage = 'Stripe settings saved successfully.';
          this.showProgressSpinner = false;
        },
        (error) => {
          console.error('Error saving Stripe settings:', error);
          this.errorMessage = 'Error saving Stripe settings.';
          this.showProgressSpinner = false;
        }
      );
  }

  onSubmit() {
    this.saveStripeSettings();
  }

  onUpdate() {
    this.saveStripeSettings();
  }
}
