<div class="mt-4">
  <div>
    <div style="display: block">
      <canvas baseChart [data]="barChartData" [options]="barChartOptions"
        [plugins]="barChartPlugins" [type]="barChartType"
        (chartHover)="chartHovered($event)" (chartClick)="chartClicked($event)">
      </canvas>
    </div>
    <!-- <button mat-button mat-raised-button color="primary"
      (click)="update()">Update</button> -->
  </div>
</div>
