import { Component, Input, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-entity-mat-dialog-actions',
  templateUrl: './entity-mat-dialog-actions.component.html',
  styleUrls: ['./entity-mat-dialog-actions.component.css']
})
export class EntityMatDialogActionsComponent implements OnInit {

  @Input() actionType;
  @Input() setFormMode: (mode: "create" | "view" | "edit" | "delete" | number) => void;
  @Input() selectFormIndex: (index) => void;
  @Input() onSubmit: (form: NgForm)  => void;

  constructor() { }

  ngOnInit(): void {
  }

}
