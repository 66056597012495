<!-- form-studio-working-hours.component.html -->
<mat-card class="configuration-card">
  <mat-card-header>
    <mat-card-title>Studio Working Hours Configuration</mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <div>
      <mat-progress-spinner class="matProgressSpinnerCentered" *ngIf="showProgressSpinner" mode="indeterminate"></mat-progress-spinner>
      <form class="col-12" [formGroup]="studioWorkingHoursForm">
        <app-success-message [message]=this.successMessage></app-success-message>
        <app-error-message [message]=this.errorMessage></app-error-message>
        <div formArrayName="field_studio_working_hours">
          <div *ngFor="let workingHour of workingHours.controls; let i = index" class="row">
            <div class="row" [formGroupName]="i">
              <div class="col-2">
                <mat-form-field class="full-width-field" appearance="outline">
                  <mat-label>Day</mat-label>
                  <input matInput [value]="days[workingHour.get('day').value]" readonly>
                </mat-form-field>
              </div>
              <div class="col-2">
                <mat-form-field class="full-width-field" appearance="outline">
                  <mat-label>Start Hours</mat-label>
                  <mat-select [formControlName]="'starthours'">
                    <mat-option *ngFor="let hour of hours" [value]="hour">{{ convertTo12HourFormat(hour) }}</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div class="col-2">
                <mat-form-field class="full-width-field" appearance="outline">
                  <mat-label>End Hours</mat-label>
                  <mat-select [formControlName]="'endhours'">
                    <mat-option *ngFor="let hour of hours" [value]="hour">{{ convertTo12HourFormat(hour) }}</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <!-- <div class="col-1">
                <mat-checkbox formControlName="all_day" (change)="onAllDayChange(i)">All Day</mat-checkbox>
              </div> -->
              <div class="col-4">
                <mat-form-field class="full-width-field" appearance="outline">
                  <mat-label>Comment</mat-label>
                  <input matInput formControlName="comment">
                </mat-form-field>
              </div>
              <div class="col-2 pt-1">
                <button class="me-1" mat-icon-button (click)="resetRow(i)" matTooltip="Reset Row">
                  <mat-icon>refresh</mat-icon>
                </button>
                <button class="" mat-icon-button (click)="copyLastDay(i)" matTooltip="Copy Last Day" [disabled]="i === 0">
                  <mat-icon>content_copy</mat-icon>
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </mat-card-content>
  <mat-card-actions align="end">
    <button mat-raised-button color="accent" [disabled]="!studioWorkingHoursForm.valid" (click)="actionType === 'create' ? saveStudioWorkingHours() : updateStudioWorkingHours()">
      {{ actionType === 'create' ? 'Save' : 'Update' }}
    </button>
  </mat-card-actions>
</mat-card>
