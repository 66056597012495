<div class="container-fluid">
  <h1 class="page-header">Sale/No Sale Report</h1>

  <mat-expansion-panel>
    <mat-expansion-panel-header>
      <mat-panel-title>
        Filter
      </mat-panel-title>
      <mat-panel-description>
        <app-filter-results-panel [parameters]=queryParams></app-filter-results-panel>
      </mat-panel-description>
    </mat-expansion-panel-header>
    <form (ngSubmit)="onSubmit(f)" #f=ngForm class="form">
      <div class="row">

        <!-- Date Range Filter -->
        <div class="col-3">
          <mat-form-field>
            <mat-label>Enter a date range</mat-label>
            <mat-date-range-input [rangePicker]="picker">
              <input name="start_date" (dateChange)="onSubmit(f)" ngModel matStartDate placeholder="Start date">
              <input name="end_date" (dateChange)="onSubmit(f)" ngModel matEndDate placeholder="End date">
            </mat-date-range-input>
            <!-- <mat-hint>MM/DD/YYYY – MM/DD/YYYY</mat-hint> -->
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-date-range-picker #picker></mat-date-range-picker>
          </mat-form-field>
        </div>

        <!-- Enrollment Autocomplete -->
        <!-- <div class="col-3">
          <mat-form-field class="full-width-field">
            <mat-label>Enrollment</mat-label>
            <input name="field_enrollment_name" (input)="onEnrollmentAutocomplete($event)" matInput ngModel
              [matAutocomplete]="autoCompleteEnrollment">
            <button type="button" matSuffix mat-icon-button aria-label="manage_accounts">
              <mat-icon>manage_accounts</mat-icon>
            </button>
          </mat-form-field>
          <mat-autocomplete #autoCompleteEnrollment="matAutocomplete" (optionSelected)="onSubmit(f)">
            <mat-option *ngFor="let option of autoCompleteEnrollmentOptions" [value]="option.label + ' ' + '(' + option.value + ')'">
              {{option.label}} ({{option.value}})
            </mat-option>
          </mat-autocomplete>
        </div> -->

        <!-- Department Filter  -->
        <!-- <div class="col-3">
            <mat-form-field class="full-width-field">
              <mat-label>Select Department...</mat-label>
              <mat-select
                name="field_department_name"
                ngModel
                (selectionChange)="onSubmit(f)">
                <mat-option value="all">All</mat-option>
                <mat-option
                *ngFor="let department of departmentData"
                [value]="department.key">{{ department.value }}
                </mat-option>
              </mat-select>
            </mat-form-field>
        </div> -->

        <!-- Student Filter -->
        <!-- <div class="col-3">
          <mat-form-field class="full-width-field">
              <mat-label>Student Name</mat-label>
              <input
                  name="field_student_id"
                  (input)="onAutocompleteStudentAccount($event)"
                  matInput
                  ngModel
                  [matAutocomplete]="studentautocomplete"
                  >
              <button type="button" matSuffix mat-icon-button
                  aria-label="manage_accounts">
                  <mat-icon>manage_accounts</mat-icon>
              </button>
          </mat-form-field>
          <mat-autocomplete
              #studentautocomplete="matAutocomplete"
              (optionSelected)="onSubmit(f)">
              <mat-option *ngFor="let option of autoCompleteStudentAccountOptions"
                  [value]="option.value">
                  {{option.label}} ({{option.value}})
              </mat-option>
          </mat-autocomplete>
      </div> -->

        <!-- <section class="section">
          <mat-checkbox
              name="Sundry"
              class="margin"
              ngModel
              (change)="onSubmit(f)">Sundry</mat-checkbox>
          <mat-checkbox
              name="Misc"
              class="margin"
              ngModel
              (change)="onSubmit(f)">Misc</mat-checkbox>

          <mat-checkbox
              name="Lesson"
              class="margin"
              ngModel
              (change)="onSubmit(f)">Lesson</mat-checkbox>
        </section> -->

        <section class="section">
          <div class="float-end bring-inline">
            <button (click)="resetForm()" color="primary" mat-flat-button class="me-1" mat-button>Clear
              <!-- <mat-icon (click)="resetForm()" aria-hidden="false" aria-label="Menu dropdown" class=""></mat-icon> -->
            </button>
          </div>

        </section>

      </div>


    </form>

  </mat-expansion-panel>

  <mat-progress-bar *ngIf="ShowProgressBar" mode="indeterminate"></mat-progress-bar>
  <div class="row">
    <div class="col-md-12 mt-3">
      <div class="float-end">
        <!-- <button (click)="onPrint()" color="primary" mat-flat-button class="me-1" mat-button>Print</button> -->
        <!-- <button (click)="onDownloadCSV()" color="primary" mat-flat-button class="me-1 warning" mat-button>CSV</button> -->
      </div>
    </div>
  </div>

  <div class="table-container">

    <table mat-table #displayTable [dataSource]="dataSource" matSort (matSortChange)="sortData($event)" class="mt-4">


      <!-- id Column -->
      <ng-container matColumnDef="receipt">
        <th mat-header-cell *matHeaderCellDef sortActionDescription="Sort by receipt">
          Receipt #
        </th>
        <td mat-cell *matCellDef="let element"> {{ element.service_id }} </td>
      </ng-container>

      <!-- date Column -->
      <ng-container matColumnDef="date">
        <th mat-header-cell *matHeaderCellDef sortActionDescription="Sort by date">
          Date
        </th>
        <td mat-cell *matCellDef="let element"> {{ element.last_service_date | date }} </td>
      </ng-container>

      <!-- type Column -->
      <ng-container matColumnDef="type">
        <th mat-header-cell *matHeaderCellDef sortActionDescription="Sort by type">
          Type
        </th>
        <td mat-cell *matCellDef="let element"> {{ element.field_sps_code_label }} </td>
      </ng-container>

      <!-- teacher Column -->
      <ng-container matColumnDef="teacher">
        <th mat-header-cell *matHeaderCellDef sortActionDescription="Sort by teacher">
          Teacher
        </th>
        <td mat-cell *matCellDef="let element"> {{ element.instructor_first_name }} {{ element.instructor_last_name }} </td>
      </ng-container>

      <!-- student Column -->
      <ng-container matColumnDef="student">
        <th mat-header-cell *matHeaderCellDef sortActionDescription="Sort by student">
          Student
        </th>
        <td mat-cell *matCellDef="let element"> {{ element.student_first_name }} {{ element.student_last_name }} </td>
      </ng-container>

      <!-- field_enrollment_lesson_count Column -->
      <ng-container matColumnDef="units">
        <th mat-header-cell *matHeaderCellDef sortActionDescription="Sort by units">
          Units
        </th>
        <td mat-cell *matCellDef="let element"> {{ element.field_enrollment_lesson_count }} </td>
      </ng-container>

      <!-- cost Column -->
      <ng-container matColumnDef="cost">
        <th mat-header-cell *matHeaderCellDef sortActionDescription="Sort by cost">
          Cost
        </th>
        <td mat-cell *matCellDef="let element"> {{ element.field_enrollment_total_price }} </td>
      </ng-container>

      <!-- paid Column -->
      <ng-container matColumnDef="paid">
        <th mat-header-cell *matHeaderCellDef sortActionDescription="Sort by paid">
          Paid
        </th>
        <td mat-cell *matCellDef="let element"> {{ element.field_total_paid }} </td>
      </ng-container>

      <!-- REPLACE Column -->
      <ng-container matColumnDef="closer">
        <th mat-header-cell *matHeaderCellDef sortActionDescription="Sort by closer">
          Closer
        </th>
        <td mat-cell *matCellDef="let element"> {{ element.executive_first_name }} {{ element.executive_last_name }} </td>
      </ng-container>

      <!-- REPLACE Column -->
      <!-- <ng-container matColumnDef="REPLACE">
        <th mat-header-cell *matHeaderCellDef sortActionDescription="Sort by REPLACE">
          REPLACE
        </th>
        <td mat-cell *matCellDef="let element"> {{ element.REPLACE }} </td>
      </ng-container> -->

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row matRipple *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>

    <mat-paginator (page)="pageChanged($event)" [pageSizeOptions]="onPageSizeOptions()" [length]="30"
      [disabled]="ShowProgressBar"
      [pageSize]="paginationTotalPages" [pageIndex]="paginationIndex" aria-label="Select page of view"></mat-paginator>

  </div>
</div>
