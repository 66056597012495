import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-entity-mat-tab-view',
  templateUrl: './entity-mat-tab-view.component.html',
  styleUrls: ['./entity-mat-tab-view.component.css']
})
export class EntityMatTabViewComponent implements OnInit {

  @Input() entityData = [];

  constructor() { }

  ngOnInit(): void {
  }

}
