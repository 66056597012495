import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NgForm, NgModel } from '@angular/forms';
import { MatTabGroup } from '@angular/material/tabs';
import { BaseDialogComponent } from '../base-dialog/base-dialog.component';
import { BaseComponent } from '../base-form/base-form.component';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-package-entity',
  templateUrl: './package-entity.component.html',
  styleUrls: ['./package-entity.component.css']
})

export class PackageEntityComponent extends BaseDialogComponent implements OnInit {

  override eckType = "packages";
  override bundle = "package";
  override entityId = this._injectedDialogData['data']['EntityID'];

  showLessonTypeAndCount: boolean = true;
  private formSubscription: Subscription;
  isUpdating = false;

  isMiscOrSundry: boolean;
  abbreviationTouched = false;
  unitValue = 1;

  isFormValid(): boolean {
    if (!this.f || !this.f.form) {
      return false;
    }

    const form = this.f.form;
    const requiredFields = ['field_package_name', 'field_sps_code', 'field_lesson_price'];

    // Check if all required fields are filled
    const allRequiredFieldsFilled = requiredFields.every(field => form.get(field)?.value);

    // Check if lesson count is required and filled
    const lessonCountRequired = !this.isMiscOrSundry;
    const lessonCountValid = lessonCountRequired ? form.get('field_lesson_count')?.value : true;

    return allRequiredFieldsFilled && lessonCountValid;
  }

  calcNewTotal() {
    let total;
    let formValues = this.f.form.value;

    console.log('formValues', formValues);

    // If the category is misc (554) or sundry (558), return the lesson price.
    if (formValues.field_sps_code == '554' || formValues.field_sps_code == '558') {
      // Update whatever lesson count is to 0
      this.f.form.patchValue({
        field_lesson_count: 0,
      });

      total = formValues.field_lesson_price;
    } else {
      total = formValues.field_lesson_price * formValues.field_lesson_count;
    }

    // Calculate the discount
    let discount = this.calcDiscount();

    // Apply the discount
    total = total - discount;

    // Add taxes if applicable
    // if (formValues.field_tax_percentage) {
    //   let taxAmount = (total * (formValues.field_tax_percentage / 100));
    //   total = total + taxAmount;
    // }

    this.f.form.patchValue({
      field_total_price: total
    });

    // Determine if enrollment is a misc or sundry item.
    this.isMiscOrSundry = (formValues.field_sps_code == 554 || formValues.field_sps_code == 558) ?? false;
  }

  calcDiscount() {
    let discount = 0;
    let formValues = this.f.form.value;

    // If the discount is a percentage
    if (formValues.field_discount_type == 'percentage') {
      discount = formValues.field_total_price * (formValues.field_discount / 100);
    } else {
      discount = formValues.field_discount;
    }

    return discount;
  }

  onSubmitPackage(f: NgForm) {
    if (this.isFormValid()) {
      this.displayProgressSpinner(true);

      console.log('packageForm', this.f.value);

      // If SPS code is sundry (558), Misc (554), or Non Unit (657) don't send lesson_type.
      if ((this.f.value.field_lesson_type == '') && (this.f.value.field_sps_code == '558' || this.f.value.field_sps_code == '554' || this.f.value.field_sps_code == '657')) {
        // delete (this.f.value.field_lesson_type);
      }

      // Iterate over each field in the form
      for (let field in this.f.controls) {
        // Check if the field is an empty string
        if (this.f.control.get(field).value === '') {
          // If it is, set its value to null
          this.f.control.get(field).patchValue(null);
        }
        if (this.f.control.get(field).value === undefined) {
          // If it is, set its value to null
          this.f.control.get(field).patchValue(null);
        }
      }

      // Set default value for field_retired if nothing is set.
      if (this.f.value?.['field_retired'] == "" || this.f.value?.['field_retired'] == null || this.f.value?.['field_retired'] == undefined) {
        this.f.value.field_retired = false;
      }

      if (this.f.value.field_lesson_type == undefined) {
        this.f.value.field_lesson_type = null;
      }

      this.onSubmit(this.f)
    }
  }

  getAmountLabel(): string {
    const spsCode = this.f?.form?.value?.field_sps_code;
    switch (spsCode) {
      case '554': // MISC
        return 'Event Amount';
      case '558': // SUNDRY
        return 'Item Amount';
      default:
        return 'Lesson Amount';
    }
  }

  onAbbreviationFocus() {
    this.abbreviationTouched = true;
  }

  generateAbbreviation(name: string): string {
    // Split the name into words
    const words = name.split(' ');

    if (words.length >= 3) {
      // If there are 3 or more words, take the first letter of the first three words
      return words.slice(0, 3).map(word => word[0].toUpperCase()).join('');
    } else if (words.length === 2) {
      // If there are 2 words, take the first letter of the first word and the first two letters of the second word
      return (words[0][0] + words[1].slice(0, 2)).toUpperCase();
    } else if (words.length === 1 && words[0].length >= 3) {
      // If there's only one word, take the first three letters
      return words[0].slice(0, 3).toUpperCase();
    } else {
      // If the name is too short, pad with 'X's
      return (words[0] + 'XXX').slice(0, 3).toUpperCase();
    }
  }

  onPackageNameChange(newValue: string, abbreviationInput: NgModel) {
    if (!this.abbreviationTouched && abbreviationInput.untouched) {
      const abbreviation = this.generateAbbreviation(newValue);
      this.f.form.patchValue({
        field_abbreviation: abbreviation
      });
    }
  }

  misc_list = [
    "1401",
    "160",
    "161",
    "162",
    "165",
    "166",
    "167",
    "170",
    "172",
    "173",
    "174",
    "175",
    "1402",
    "177",
    "180",
    "181",
    "182",
    "183",
    "188",
    "190",
    "191",
    "193",
    "636",
    "637",
    "1400",
  ]

  non_unit_list = [
    "173",
    // "1399",
    // "1397",
    // "1398",
    // "1396"
  ]

  non_unit_group_non_list = [
    "1410",
    "1411",
    // "1397",
    // "1398",
    // "1396"
  ]

  sundry_list = [
    "196",
    "197",
    "198",
    "199",
    "200",
    "201",
    "202",
    "203",
    "195",
    "635"
  ]




  override ngOnInit(): void {
    let action = this._injectedDialogData?.['data']?.['action'];

    if (action) {
      this.initActionType = this._injectedDialogData['data']['action'];
      this.actionType = this._injectedDialogData['data']['action'];
    }

    console.log('this._injectedDialogData', this._injectedDialogData);
    this.checkIfEventPassedCurrentTime();

    this.initFormSubscription();
  }

  private initFormSubscription() {
    console.log('Attempting to initialize form subscription');
    if (this.f && this.f.form) {
      console.log('Form found, setting up subscription');
      this.formSubscription = this.f.form.valueChanges.subscribe((formValues) => {
        console.log('Form values changed:', formValues);
        this.updateFieldVisibility();
      });
    } else {
      console.warn('Form not available for subscription');
      setTimeout(() => this.initFormSubscription(), 100);
    }
  }

  ngOnDestroy() {
    console.log('PackageEntityComponent destroyed');
    this.unsubscribeForm();
  }

  private unsubscribeForm() {
    if (this.formSubscription) {
      console.log('Unsubscribing from form changes');
      this.formSubscription.unsubscribe();
      this.formSubscription = null;
    }
  }

  private updateFieldVisibility() {
    if (this.isUpdating) {
      console.log('Update already in progress, skipping');
      return;
    }

    this.isUpdating = true;

    if (!this.f || !this.f.form) {
      console.warn('Form not available for updateFieldVisibility');
      this.isUpdating = false;
      return;
    }

    const spsCode = this.f.form.get('field_sps_code')?.value;
    const previousShowFields = this.isMiscOrSundry;
    this.isMiscOrSundry = (spsCode == '554' || spsCode == '558') ?? false;

    console.log(`SPS Code: ${spsCode}, Show Lesson Type and Count: ${this.isMiscOrSundry}`);

    if (previousShowFields !== this.isMiscOrSundry) {
      console.log(`Field visibility changed from ${previousShowFields} to ${this.isMiscOrSundry}`);

      if (!this.isMiscOrSundry) {
        const previousLessonType = this.f.form.get('field_lesson_type')?.value;
        const previousLessonCount = this.f.form.get('field_lesson_count')?.value;

        if (previousLessonType !== null || previousLessonCount !== null) {
          console.log(`Clearing Lesson Type and Count fields. Previous values: Type=${previousLessonType}, Count=${previousLessonCount}`);
          this.f.form.patchValue({
            field_lesson_type: null,
            field_lesson_count: null
          }, { emitEvent: false });
        }
      }
    }

    this.isUpdating = false;
  }

}
