import { Component, Input, OnInit } from '@angular/core';
import { FormArray } from '@angular/forms';

@Component({
  selector: 'app-simple-import-lessons',
  templateUrl: './simple-import-lessons.component.html',
  styleUrls: ['./simple-import-lessons.component.css']
})
export class SimpleImportLessonsComponent implements OnInit {

  @Input() lessonsArray: FormArray;

  ngOnInit(): void {
  }

}
