<h1 class="page-header">Forgot your password?</h1>

<div [ngClass]="{
        'loading-shade': showProgressSpinner
     }">

        <mat-progress-spinner class="matProgressSpinnerCentered" *ngIf="showProgressSpinner" mode="indeterminate">
        </mat-progress-spinner>

        <div>
            <div>

                <!-- Form -->
                <form (ngSubmit)="onSubmit(f)" #f="ngForm" class="form">
                  <div class="container">
                    <div class="row justify-content-md-center">


                        <mat-card class="col-md-6 col-12">
                            <p class="text-center">Password reset instructions will be sent to your registered email address.</p>
                            <div class="col-12">
                                <mat-form-field class="full-width-field">
                                    <mat-label>Username or email address</mat-label>
                                    <input
                                        type="text"
                                        name="name"
                                        required
                                        matInput
                                        ngModel>
                                </mat-form-field>
                            </div>

                            <p>{{this.errorMessage}}</p>
                            <!-- <app-error-message [message]=this.errorMessage></app-error-message> -->

                            <div class="col-12">
                              <button
                                [disabled]="!f.valid"
                                type="button"
                                (click)="onSubmit(f);"
                                mat-raised-button
                                color="primary">Submit</button>

                            </div>

                        </mat-card>
                    </div>
                  </div>
                </form>
            </div>


</div>
