<div class="container-fluid">
  <h1 class="page-header">Cash Report</h1>

  <mat-expansion-panel>
    <mat-expansion-panel-header>
      <mat-panel-title>
        Filter
      </mat-panel-title>
      <mat-panel-description>
        <app-filter-results-panel [parameters]=queryParams></app-filter-results-panel>
      </mat-panel-description>
    </mat-expansion-panel-header>
    <form (ngSubmit)="onSubmit(f)" #f=ngForm class="form">


      <div class="row">
        <div class="col-3">
          <mat-form-field class="full-width-field">
            <mat-label>Week</mat-label>
            <mat-select matInput [ngModel]=week name="week" (selectionChange)="onSubmit(f)">
              <mat-option>None</mat-option>
              <mat-option *ngFor="let element of WeeksForYearData"
              [value]="element.key">
                {{ element.value }}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div class="col-3">
          <mat-form-field class="full-width-field">
            <mat-label>Year</mat-label>
            <mat-select
            matInput
            [ngModel]=year
            name="year"
            (selectionChange)="onSubmit(f)">
              <mat-option>None</mat-option>
              <mat-option *ngFor="let element of LastFourYearsData"
              [value]="element.key">
                {{ element.value }}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <section class="section">
          <div class="float-end bring-inline">
            <button (click)="resetForm()" color="primary" mat-flat-button class="me-1" mat-button>Clear
              <!-- <mat-icon (click)="resetForm()" aria-hidden="false" aria-label="Menu dropdown" class=""></mat-icon> -->
            </button>
          </div>

        </section>

      </div>

      <section class="section">
      </section>

    </form>

  </mat-expansion-panel>

  <mat-progress-bar *ngIf="ShowProgressBar" mode="indeterminate"></mat-progress-bar>
  <div class="row">
    <div class="col-md-12 mt-3">
      <div class="float-end">
        <button (click)="onPrint()" color="primary" mat-flat-button class="me-1" mat-button>Print</button>
        <!-- <button (click)="onDownloadCSV()" color="primary" mat-flat-button class="me-1 warning" mat-button>CSV</button> -->
      </div>
    </div>
  </div>

  <div class="table-container">

    <h2>{{ environment.name }} - Week # {{ week }}</h2>
    <table class="mt-4 mat-table">
      <thead>
        <tr class="mat-header-row">
          <th class="mat-header-cell">
            &nbsp;
          </th>

          <th class="mat-header-cell">
            PRE
          </th>

          <th class="mat-header-cell">
            ORI
          </th>

          <th class="mat-header-cell">
            EXT
          </th>

          <th class="mat-header-cell">
            REN
          </th>

          <th class="mat-header-cell">
            MISC
          </th>

          <th class="mat-header-cell">
            NON UNIT
          </th>

          <!-- <th class="mat-header-cell">
            PORI
          </th> -->

          <th class="mat-header-cell">
            SUNDRY
          </th>

          <th class="mat-header-cell">
            REFUNDS
          </th>

          <th class="mat-header-cell">
            TOTAL
          </th>
        </tr>
      </thead>


    <tr class="mat-row">
      <td class="mat-cell">
        (WEEK)
      </td>
      <td class="mat-cell">
        ${{ CashReportDataWEEK.PRE }}
      </td>
      <td class="mat-cell">
        ${{ CashReportDataWEEK.ORI }}
      </td>
      <td class="mat-cell">
        ${{ CashReportDataWEEK.EXT }}
      </td>
      <td class="mat-cell">
        ${{ CashReportDataWEEK.REN }}
      </td>
      <td class="mat-cell">
        ${{ CashReportDataWEEK.MISC }}
      </td>
      <td class="mat-cell">
        ${{ CashReportDataWEEK.NONUNIT }}
      </td>
      <!-- <td class="mat-cell">
        ${{ CashReportDataWEEK.PORI }}
      </td> -->
      <td class="mat-cell">
        ${{ CashReportDataWEEK.SUNDRY }}
      </td>
      <td class="mat-cell">
        ${{ CashReportDataWEEK.REFUND }}
      </td>
      <td class="mat-cell">
        ${{ CashReportDataWEEK.PRE + CashReportDataWEEK.ORI + CashReportDataWEEK.EXT +
          CashReportDataWEEK.MISC + CashReportDataWEEK.NONUNIT +
          CashReportDataWEEK.SUNDRY + CashReportDataWEEK.REN + CashReportDataWEEK.REFUND }}
      </td>
    </tr>

        <tr class="mat-row">
          <td class="mat-cell">
            (YTD)
          </td>
          <td class="mat-cell">
            ${{ CashReportData.PRE }}
          </td>
          <td class="mat-cell">
            ${{ CashReportData.ORI }}
          </td>
          <td class="mat-cell">
            ${{ CashReportData.EXT }}
          </td>
          <td class="mat-cell">
            ${{ CashReportData.REN }}
          </td>
          <td class="mat-cell">
            ${{ CashReportData.MISC }}
          </td>
          <td class="mat-cell">
            ${{ CashReportData.NONUNIT }}
          </td>
          <!-- <td class="mat-cell">
            ${{ CashReportData.PORI }}
          </td> -->
          <td class="mat-cell">
            ${{ CashReportData.SUNDRY }}
          </td>
          <td class="mat-cell">
            ${{ CashReportData.REFUND }}
          </td>
          <td class="mat-cell">
            ${{ CashReportData.PRE + CashReportData.ORI + CashReportData.EXT +
              CashReportData.MISC + CashReportData.NONUNIT +
              CashReportData.SUNDRY + CashReportData.REN + CashReportData.REFUND  }}
          </td>
    </tr>

    </table>

    <table mat-table [dataSource]="dataSource" matSort (matSortChange)="sortDataView($event)" class="mt-4 d-none">

      <!-- REPLACE Column -->
      <ng-container matColumnDef="nothing">
        <th *matHeaderCellDef sortActionDescription="Sort by nothing">
          nothing
        </th>
        <td mat-cell *matCellDef="let element"> {{ element.nothing }} </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row matRipple *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>

    <div class="row">
    <div class="col-6" *ngFor="let data of convertToArray(this.storage); index as i;">
      <app-cash-report-individual [data]="data">
      </app-cash-report-individual>
    </div>
    </div>

  </div>
</div>
