import { CommonModule } from "@angular/common";
import { Component, OnInit, AfterViewInit, ViewChild, VERSION } from '@angular/core';
import { LiveAnnouncer } from '@angular/cdk/a11y';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { UtilityService } from 'src/app/services/utility.service';
import { MatPaginator, PageEvent } from "@angular/material/paginator";
import { FormControl, FormGroup, NgForm } from "@angular/forms";
import { DrupalRESTService } from "src/app/services/drupal-rest.service";
import { ActivatedRoute, Event, Params, Router } from "@angular/router";
import { saveAs } from 'file-saver';
import moment from "moment";
import { ViewTableComponent } from "../../base/view-table/view-table.component";

@Component({
  selector: 'app-ami-staff-performance',
  templateUrl: './ami-staff-performance.component.html',
  styleUrls: ['./ami-staff-performance.component.css']
})
export class AmiStaffPerformanceComponent extends ViewTableComponent implements OnInit {

  override displayedColumns: string[] = [
    'name',
    'staff_type',
    'number_of_guest',
    'de/bonus',
    'private_lessons',
    'number_in_class',
    'dor_sanct_competition',
    'showcase_medal_ball',
    'party_time_non_unit',
    'interview_department',
    'renewal_department',
  ];

  currentReport:string = 'staffPerformance';
  week:string;
  year:string;
  staffPerformanceExecutives: any;

  onYearChange(event: any) {
    this.year = event.value;
    console.log('year', this.year)
    this.WeeksForYearData = this._fieldsService.WeeksForYearData(Number.parseInt(this.year));
  }

  override ngAfterContentInit(): void {
    this._activatedRoute.queryParams.subscribe(data => {
      // console.log('subscribed data', data)
      this.ShowProgressBar = false;

      if (data['year']) {
        this.year = data['year']
      } else {
        this.year = moment().format("YYYY");
      }
      if (data['week']) {
        this.week = data['week']
      } else {
        this.week = moment().add(0, 'week').format("w");
      }

      this.getData([
        { parameter: 'week', value: this.week},
        { parameter: 'year', value: this.year},
      ]);
    })
  }

  override getData(params: { parameter: string; value: string; }[]) {
    // Disable buttons if it's the current week.
    let paramWeek = moment().day("Sunday").year(+this.year).week(+this.week).add(1, 'weeks');
    this.isDisabled = paramWeek.isAfter();

    this.clearFormTableData();

    // Show Progress Bar (Loading indicator)
    this.ShowProgressBar = true;
    this.queryParams = params;

    // Add sorting paramaters, if available.
    if (this.sortParams !== undefined) {
      this.queryParams.push(this.sortParams)
    }

    // Load the new student list with the filtering parameters.
    this._utilityService.getStaffPerformanceReport(
      this.queryParams
    )
      .subscribe((data) => {
        let rows: any = data?.['#staff_content_table']?.['staff_data_4_system']?.['#rows'];
        // ShowProgressBar
        this.ShowProgressBar = false;

        if (!rows) { return; }

        // Modify data to work in Angular.
        rows.forEach((element: { name: string; }, index: string | number) => {
          if (data['#staff_content_table']?.['staff_data_4_system']?.['#rows']?.[index]?.['name']) {
            data['#staff_content_table']['staff_data_4_system']['#rows'][index]['name'] = element.name.replace(/<[^>]*>?/gm, '');
          }
        });

        // Extract the refunds from the rows to be displayed seperately.
        this.filterExecutives(rows);

        this.data = data;
        this.dataSource = new MatTableDataSource(this.filterInstructors(rows) ?? []);
        this.pageSize = rows.length;

        // Pagination configuration.
        this.updatePaginationConfig(rows);
      },
        error => this.handleError(error)
      )
  }

  filterExecutives(rows) {
    this.staffPerformanceExecutives = rows.filter(object => {
      let isExecutive = (object.staff_type == "EXECUTIVE");

      return (isExecutive);
    });
  }

  filterInstructors(rows) {
    return rows.filter(object => {
      let isInstructor = (object.staff_type == "INSTRUCTOR");

      return (isInstructor);
    });
  }
}
