import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-personal-information',
  templateUrl: './personal-information.component.html',
  styleUrls: ['./personal-information.component.css',
    '../../../../../app.component.css'
  ]
})
export class PersonalInformationComponent implements OnInit {

  // @Input() statusList: { id: number, value: string }[];

  constructor() { }

  ngOnInit(): void {
  }

  statusList = [
    {id: 64, value: "Pending Status"},
    {id: 63, value: "Cancelled"},
    {id: 61, value: "No Show, Not Charged"},
    {id: 60, value: "No Show, Charged"},
    {id: 62, value: "Rescheduled"},
    {id: 59, value: "Showed"}
  ]

}
