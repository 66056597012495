<!-- email-configuration-settings.component.html -->
<div style="height:64px;" class="page-empty-header"></div>

<div class="container-fluid">
    <div class="row">
        <div style="background:#215DAB; height: calc(100vh - 64px); overflow: scroll;" class="col-2">
            <app-configuration-dashboard-menu></app-configuration-dashboard-menu>
        </div>

        <div class="col-10" style="padding-left: 2.5rem;padding-right: 2.5rem;padding-top:2.5rem;overflow:scroll;max-height: calc(100vh - 64px);">
            <div class="container-fluid">
                <h1>Email Configuration Settings</h1>

                <div *ngIf="showProgressSpinner" class="matProgressSpinnerCentered">
                    <mat-progress-spinner mode="indeterminate"></mat-progress-spinner>
                </div>

                <form [formGroup]="emailConfigForm">
                    <div class="mt-3" *ngIf="successMessage">
                        <div class="alert alert-success">{{ successMessage }}</div>
                    </div>

                    <div class="mt-3" *ngIf="errorMessage">
                        <div class="alert alert-danger">{{ errorMessage }}</div>
                    </div>

                    <!-- Lesson Reminder Emails -->
                    <mat-card class="configuration-card">
                        <mat-card-header>
                            <mat-card-title>Lesson Reminder Emails</mat-card-title>
                        </mat-card-header>
                        <mat-card-content>
                            <div class="row">
                                <div class="col-4">
                                    <mat-slide-toggle formControlName="enableLessonReminder">Enable Lesson Reminder Emails</mat-slide-toggle>
                                </div>
                                <div class="col-7" style="border:.5px thin black; border-radius:7px;" *ngIf="emailConfigForm.get('enableLessonReminder').value">
                                    <div class="row">
                                        <div class="col-4">
                                            <mat-slide-toggle formControlName="enable24HourReminder">Enable 24 Hour Reminder</mat-slide-toggle>
                                        </div>
                                        <div class="col-4">
                                            <mat-slide-toggle formControlName="enable48HourReminder">Enable 48 Hour Reminder</mat-slide-toggle>
                                        </div>
                                        <div class="col-4">
                                            <mat-slide-toggle formControlName="enable72HourReminder">Enable 72 Hour Reminder</mat-slide-toggle>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="mt-3" *ngIf="emailConfigForm.get('enableLessonReminder').value">
                                <div class="row">
                                    <div class="col-12">
                                        <mat-form-field class="full-width-field" appearance="outline">
                                            <mat-label>Lesson Reminder Email Subject</mat-label>
                                            <input matInput formControlName="lessonReminderSubject">
                                        </mat-form-field>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-12">
                                        <editor apiKey="t3tfxkof3dgqkixl39q51eqs8zcqjusuz12sapoaorse2cvf" [init]="tinyMceConfig" formControlName="lessonReminderEmailTemplate"></editor>
                                        <mat-hint>Use placeholders like __studentName__, __studentFirstName__, __studentLastName__, __dateTime__, __instructorName__, __studioName__, __studioAddress__, __studioEmail__, __studioNumber__, and __AddToCalendar__ in the template.</mat-hint>
                                    </div>
                                </div>
                            </div>
                        </mat-card-content>
                        <mat-card-actions align="end">
                            <button (click)="this.onSubmit()" mat-raised-button color="accent" [disabled]="!emailConfigForm.valid">Save</button>
                        </mat-card-actions>
                    </mat-card>

                    <!-- New Enrollment Emails -->
                    <mat-card class="configuration-card d-none">
                        <mat-card-header>
                            <mat-card-title>New Enrollment Emails</mat-card-title>
                        </mat-card-header>
                        <mat-card-content>
                            <div class="row">
                                <div class="col-12">
                                    <mat-slide-toggle formControlName="enableNewEnrollment">Enable New Enrollment Emails</mat-slide-toggle>
                                </div>
                            </div>
                            <div class="mt-3" *ngIf="emailConfigForm.get('enableNewEnrollment').value">
                                <div class="row">
                                    <div class="col-12">
                                        <mat-form-field class="full-width-field" appearance="outline">
                                            <mat-label>New Enrollment Email Subject</mat-label>
                                            <input matInput formControlName="newEnrollmentSubject">
                                        </mat-form-field>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-12">
                                        <editor apiKey="t3tfxkof3dgqkixl39q51eqs8zcqjusuz12sapoaorse2cvf" [init]="tinyMceConfig" formControlName="newEnrollmentTemplate"></editor>
                                        <mat-hint>Use placeholders like __studentName__, __studentFirstName__, __studentLastName__, __dateTime__, __instructorName__, __studioName__, __studioAddress__, and __studioEmail__, __studioNumber__, in the template.</mat-hint>
                                    </div>
                                </div>
                            </div>
                        </mat-card-content>
                        <mat-card-actions align="end">
                            <button (click)="this.onSubmit()" mat-raised-button color="accent" [disabled]="!emailConfigForm.valid">Save</button>
                        </mat-card-actions>
                    </mat-card>

                    <!-- Lesson Confirmation Emails -->
                    <mat-card class="configuration-card">
                        <mat-card-header>
                            <mat-card-title>Lesson Confirmation Emails</mat-card-title>
                        </mat-card-header>
                        <mat-card-content>
                            <div class="row">
                                <div class="col-12">
                                    <mat-slide-toggle formControlName="enableLessonConfirmation">Enable Lesson Confirmation Emails</mat-slide-toggle>
                                </div>
                            </div>
                            <div class="mt-3" *ngIf="emailConfigForm.get('enableLessonConfirmation').value">
                                <div class="row">
                                    <div class="col-12">
                                        <mat-form-field class="full-width-field" appearance="outline">
                                            <mat-label>Lesson Confirmation Email Subject</mat-label>
                                            <input matInput formControlName="lessonConfirmationSubject">
                                        </mat-form-field>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-12">
                                        <editor apiKey="t3tfxkof3dgqkixl39q51eqs8zcqjusuz12sapoaorse2cvf" [init]="tinyMceConfig" formControlName="lessonConfirmationTemplate"></editor>
                                    </div>
                                </div>
                            </div>
                        </mat-card-content>
                        <mat-card-actions align="end">
                            <button (click)="this.onSubmit()" mat-raised-button color="accent" [disabled]="!emailConfigForm.valid">Save</button>
                        </mat-card-actions>
                    </mat-card>

                    <!-- Lesson Rescheduled Emails -->
                    <mat-card class="configuration-card">
                        <mat-card-header>
                            <mat-card-title>Lesson Rescheduled Emails</mat-card-title>
                        </mat-card-header>
                        <mat-card-content>
                            <div class="row">
                                <div class="col-12">
                                    <mat-slide-toggle formControlName="enableLessonRescheduled">Enable Lesson Rescheduled Emails</mat-slide-toggle>
                                </div>
                            </div>
                            <div *ngIf="emailConfigForm.get('enableLessonRescheduled').value">
                                <div class="row">
                                    <div class="col-12">
                                        <mat-form-field class="full-width-field" appearance="outline">
                                            <mat-label>Lesson Rescheduled Email Subject</mat-label>
                                            <input matInput formControlName="lessonRescheduledSubject">
                                        </mat-form-field>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-12">
                                        <editor apiKey="t3tfxkof3dgqkixl39q51eqs8zcqjusuz12sapoaorse2cvf" [init]="tinyMceConfig" formControlName="lessonRescheduledTemplate"></editor>
                                    </div>
                                </div>
                            </div>
                        </mat-card-content>
                        <mat-card-actions align="end">
                            <button (click)="this.onSubmit()" mat-raised-button color="accent" [disabled]="!emailConfigForm.valid">Save</button>
                        </mat-card-actions>
                    </mat-card>

                    <!-- Thank you for first time lessons -->
                    <mat-card class="configuration-card">
                        <mat-card-header>
                            <mat-card-title>Thank You for First Time Lessons</mat-card-title>
                        </mat-card-header>
                        <mat-card-content>
                            <div class="row">
                                <div class="col-12">
                                    <mat-slide-toggle formControlName="enableFirstTimeLessonThankYou">Enable Thank You for First Time Lessons Emails</mat-slide-toggle>
                                </div>
                            </div>
                            <div class="mt-3" *ngIf="emailConfigForm.get('enableFirstTimeLessonThankYou').value">
                                <div class="row">
                                    <div class="col-12">
                                        <mat-form-field class="full-width-field" appearance="outline">
                                            <mat-label>First Time Lesson Thank You Email Subject</mat-label>
                                            <input matInput formControlName="firstTimeLessonThankYouSubject">
                                        </mat-form-field>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-12">
                                        <editor apiKey="t3tfxkof3dgqkixl39q51eqs8zcqjusuz12sapoaorse2cvf" [init]="tinyMceConfig" formControlName="firstTimeLessonThankYouTemplate"></editor>
                                        <mat-hint>Use placeholders like __studentName__, __studentFirstName__, __studentLastName__, __dateTime__, __instructorName__, __studioName__, __studioAddress__, __studioEmail__, __studioNumber__, and __AddToCalendar__ in the template.</mat-hint>
                                    </div>
                                </div>
                            </div>
                        </mat-card-content>
                        <mat-card-actions align="end">
                            <button (click)="this.onSubmit()" mat-raised-button color="accent" [disabled]="!emailConfigForm.valid">Save</button>
                        </mat-card-actions>
                    </mat-card>
                </form>
            </div>
        </div>
    </div>
</div>
