import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { DrupalRESTService } from './drupal-rest.service';

@Injectable({
  providedIn: 'root'
})
export class AuthRESTService {

  constructor(
    private _drupalRESTService: DrupalRESTService,
    private _authService: AuthService,
    ) { }

  test_login() {
    let endpoint = "/api_rest/v1/test_login";

    return this._drupalRESTService.httpGET(endpoint);
  }

  login(username: any, password: any) {
    let endpoint = "/api_rest/v1/user_login";

    let body = {
      name: username,
      pass: password,
    };

    let params = [
      {
        parameter: '_format',
        value: 'json',
      },
    ];

    return this._drupalRESTService.httpPOST(endpoint, body, params);
  }

  logout() {
    // let endpoint = '/user/logout';
    let endpoint = '/api_rest/v1/user_logout';

    let params = [
      {
        parameter: '_format',
        value: 'json',
      },
      {
        parameter: 'token',
        value: this._authService.logout_token
      }
    ]

    return this._drupalRESTService.httpGET(endpoint, params);
  }

  register() {

  }

  forgot_password(name: any) {
    let endpoint = '/user/password';

    let body = {
      name: name,
    };

    let params = [
      {
        parameter: '_format',
        value: 'json',
      },
    ];

    return this._drupalRESTService.httpPOST(endpoint, body, params);
  }

  checkLicenseAgreementStatus(username: string) {
    let endpoint = "/api_rest/v1/check_license_agreement_status";

    let body = {
      name: username
    };

    let params = [
      {
        parameter: '_format',
        value: 'json',
      },
    ];

    return this._drupalRESTService.httpPOST(endpoint, body, params);
  }

}
