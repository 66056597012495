import { Directive, HostListener, ElementRef } from '@angular/core';

@Directive({
  selector: '[appPhoneNumberFormatter]'
})
export class PhoneNumberFormatterDirective {
  constructor(private el: ElementRef) {}

  @HostListener('input', ['$event'])
  onInputChange(event: Event) {
    const input = event.target as HTMLInputElement;
    let value = input.value.replace(/\D/g, '');

    if (value.length > 3 && value.length <= 6) {
      value = value.replace(/^(\d{3})(\d+)/, '($1) $2');
    } else if (value.length > 6) {
      value = value.replace(/^(\d{3})(\d{3})(\d+)/, '($1) $2-$3');
    } else if (value.length > 10) {
      value = value.substring(0, 10); // Ensure no more than 10 digits are entered
      value = value.replace(/^(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
    }

    input.value = value;
  }
}
