<h1 class="page-header">Create Student Account</h1>

<div [ngClass]="{
        'loading-shade': showProgressSpinner
     }">

        <mat-progress-spinner class="matProgressSpinnerCentered" *ngIf="showProgressSpinner" mode="indeterminate">
        </mat-progress-spinner>

        <div>
            <div>

                <!-- Form -->
                <form (ngSubmit)="onSubmit(f)" #f="ngForm" class="form" >
                    <!-- Contacts -->
                        <input
                            class="d-none"
                            type="text"
                            [(ngModel)]="field_contacts"
                            name="field_contacts"
                            value="{{contactList}}">

                        <div class="container">

                            <div class="row">
                                <mat-card>

                                    <!-- Display Contacts  -->
                                    <ng-container *ngIf="field_contacts">
                                        <div
                                        *ngFor="let contact of field_contacts; let i = index;"
                                        class="mb-2 mt-2"
                                        >
                                            <div class="p-3 display-edit-field">
                                                <span>{{ contact.field_first_name }} {{ contact.field_last_name }}</span>

                                                <!-- Remove contact -->
                                                <mat-icon
                                                    class="me-1 float-end"
                                                    (click)="studentRemove(i)"
                                                    aria-hidden="false"
                                                    aria-label="trash">cancel</mat-icon>

                                                <!-- Edit contact -->
                                                <mat-icon
                                                    class="me-1 float-end"
                                                    (click)="studentUpdateLoad(i, contact)"
                                                    aria-hidden="false"
                                                    aria-label="edit">edit</mat-icon>
                                            </div>
                                        </div>
                                    </ng-container>

                                    <!-- field_contacts -->
                                    <div class=""
                                        ngModelGroup="__field_contact_inline_form"
                                        #contact="ngModelGroup"
                                        >

                                        <input class="d-none" ngModel name="type" matInput>
                                        <input class="d-none" ngModel name="bundle" matInput>

                                    <mat-expansion-panel expanded="true">
                                    <mat-expansion-panel-header>
                                        <mat-panel-title>
                                        Contact Information
                                        </mat-panel-title>
                                        <mat-panel-description>
                                        <!-- This is a summary of the content -->
                                        </mat-panel-description>
                                    </mat-expansion-panel-header>
                                        <div class="row">
                                            <div class="col-6">
                                                <mat-form-field class="full-width-field">
                                                    <mat-label>First Name</mat-label>
                                                    <input
                                                        name="field_first_name"
                                                        required
                                                        matInput
                                                        ngModel>
                                                </mat-form-field>
                                            </div>

                                            <div class="col-6">
                                                <mat-form-field class="full-width-field">
                                                    <mat-label>Last Name</mat-label>
                                                    <input
                                                        name="field_last_name"
                                                        required
                                                        matInput
                                                        ngModel>
                                                </mat-form-field>
                                            </div>
                                            <div class="col-3">
                                                    <mat-form-field class="full-width-field">
                                                        <mat-label>Home Phone</mat-label>
                                                        <input ngModel name="field_home_phone" matInput>
                                                    </mat-form-field>
                                                </div>

                                                <div class="col-3">
                                                    <mat-form-field class="full-width-field">
                                                        <mat-label>Cell Phone</mat-label>
                                                        <input ngModel name="field_cell_phone" matInput>
                                                    </mat-form-field>
                                                </div>

                                                <div class="col-6">
                                                    <mat-form-field class="full-width-field">
                                                        <mat-label>Email Address</mat-label>
                                                        <input required ngModel name="field_email" matInput>
                                                    </mat-form-field>
                                                </div>
                                        </div>
                                    </mat-expansion-panel>


                                        <!-- Address  -->
                                        <div
                                        class=""
                                        ngModelGroup="field_address"
                                        #contact="ngModelGroup">

                                            <input
                                                class="d-none"
                                                name="multiple_properties"
                                                matInput
                                                ngModel="true">


                                    <mat-expansion-panel class="mt-1"  expanded="true">
                                    <mat-expansion-panel-header>
                                        <mat-panel-title>
                                        Address
                                        </mat-panel-title>
                                        <mat-panel-description>
                                        <!-- This is a summary of the content -->
                                        </mat-panel-description>
                                    </mat-expansion-panel-header>
                                    <div class="row">
                                            <!-- <div class="col-6">
                                                <mat-form-field class="full-width-field">
                                                    <mat-label>Company</mat-label>
                                                    <input
                                                        name="organization"
                                                        matInput
                                                        ngModel>
                                                </mat-form-field>
                                            </div> -->

                                            <div class="col-12">
                                                <mat-form-field class="full-width-field">
                                                    <mat-label>Street Address</mat-label>
                                                    <input ngModel name="address_line1" matInput>
                                                </mat-form-field>
                                            </div>

                                            <!-- <div class="col-12">
                                                <mat-form-field class="full-width-field">
                                                    <mat-label>Street Address Line 2</mat-label>
                                                    <input ngModel name="address_line2" matInput>
                                                </mat-form-field>
                                            </div> -->

                                            <div class="col-5">
                                                <mat-form-field class="full-width-field">
                                                    <mat-label>City</mat-label>
                                                    <input ngModel name="locality" matInput>
                                                </mat-form-field>
                                            </div>
                                            <!-- <div class="col-5">
                                                <mat-form-field class="full-width-field">
                                                    <mat-label>State</mat-label>
                                                    <input ngModel name="administrative_area" matInput>
                                                </mat-form-field>
                                            </div> -->

                                            <div class="col-5">
                                                <mat-form-field class="full-width-field">
                                                    <mat-label>State</mat-label>
                                                    <mat-select matInput ngModel name="administrative_area">
                                                        <mat-option>None</mat-option>
                                                        <mat-option *ngFor="let element of this._fieldsService.StatesAbbreviations"
                                                            [value]="element.abbreviation">
                                                            {{ element.name }}</mat-option>
                                                    </mat-select>
                                                </mat-form-field>
                                            </div>

                                            <div class="col-2">
                                                <mat-form-field class="full-width-field">
                                                    <mat-label>Zip</mat-label>
                                                    <input ngModel name="postal_code" matInput>
                                                </mat-form-field>
                                            </div>
                                            <div class="col-2 d-none">
                                                <mat-form-field class="full-width-field">
                                                    <mat-label>Country</mat-label>
                                                    <input ngModel="US" name="country_code" matInput>
                                                </mat-form-field>
                                            </div>
</div>
</mat-expansion-panel>


                                        </div>

                                        <div class="row">

                                            <!-- TODO: Add support for this field though the API -->
                                            <!-- <div class="col-12 mt-3">
                                                <mat-label>Lesson Reminders:</mat-label>
                                                <mat-checkbox
                                                    ngModel
                                                    name="field_notification_preference[call]"
                                                    value="call"
                                                    class="">Phone Call</mat-checkbox>
                                                <mat-checkbox
                                                    ngModel
                                                    name="field_notification_preference[SMS]"
                                                    value="SMS"
                                                    class="">Text</mat-checkbox>
                                                <mat-checkbox
                                                    ngModel
                                                    name="field_notification_preference[Email]"
                                                    value="Email"
                                                    class="">Email</mat-checkbox>
                                                <mat-checkbox
                                                    ngModel
                                                    name="field_notification_preference[Both]"
                                                    value="Both"
                                                    class="">Both</mat-checkbox>
                                            </div> -->

                                            <div class="col-12 mt-3">
                                                <div class="row">
                                                    <mat-label>Attending Lessons</mat-label>
                                                </div>
                                                <mat-radio-group name="field_attending_lessons" ngModel aria-label="Attending Lessons">
                                                    <mat-radio-button value="solo">Solo</mat-radio-button>
                                                    <mat-radio-button value="partner">With Partner</mat-radio-button>
                                                </mat-radio-group>
                                            </div>
                                            <div class="col-12 mt-3">
                                                <div class="row">
                                                    <mat-label>Gender</mat-label>
                                                </div>
                                                <mat-radio-group name="field_gender" ngModel aria-label="Gender">
                                                    <mat-radio-button value="1">Male</mat-radio-button>
                                                    <mat-radio-button value="0">Female</mat-radio-button>
                                                </mat-radio-group>
                                            </div>
                                        </div>

                                        <div class="col-12 mt-3">
                                            <button *ngIf="!editMode" type="button"
                                                mat-raised-button
                                                [disabled]="!contact.valid"
                                                class="me-1"
                                                color="primary" (click)="studentIncrement(contact.value)">
                                                Add New Student
                                            </button>
                                            <button *ngIf="editMode" type="button"
                                                mat-raised-button
                                                [disabled]="!contact.valid"
                                                class="me-1"
                                                color="primary" (click)="studentUpdateSubmit(i, contact.value)">
                                                Edit student
                                            </button>
                                        </div>
                                    </div>
                                </mat-card>
                            </div>



                            <div class="row">

                                <div class="col-12">
                                    <h3 class="mt-3">Additional Details</h3>
                                </div>

                                <div class="col-4">
                                    <mat-form-field class="full-width-field">
                                        <mat-label>Inquiry Taker</mat-label>
                                        <mat-select matInput required ngModel name="field_inquiry_taker">
                                            <mat-option>None</mat-option>
                                            <mat-option *ngFor="let element of this._fieldsService.instructorData()"
                                                [value]="element.uid">
                                                {{ element.value }}</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>

                                <div class="col-4">
                                    <mat-form-field class="full-width-field">
                                      <mat-label>Type of Dances</mat-label>
                                      <mat-select multiple
                                        name="field_dances_type"
                                        ngModel
                                        >
                                        <mat-option>None</mat-option>
                                        <mat-option
                                        *ngFor="let element of this._fieldsService.dance_types"
                                        [value]="element.key">{{ element.value }}
                                        </mat-option>
                                      </mat-select>
                                    </mat-form-field>
                                </div>

                                <div class="col-4">
                                    <mat-form-field class="full-width-field">
                                        <mat-label>How did you hear about us?</mat-label>
                                        <mat-select
                                            ngModel
                                            name="field_inquiry_method">
                                            <mat-option>None</mat-option>
                                            <mat-option
                                            *ngFor="let element of this._fieldsService.field_inquiry_method"
                                            [value]="element.key">{{ element.value }}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>

                                <div class="mt-1 mb-3">
                                    <!-- This should be hidden and selected in the Student Inquiry Form  -->
                                    <mat-checkbox
                                        matTooltip="Inquired students are new students to the studio."
                                        [matTooltipPosition]="'right'"
                                        [ngModel]="this?.['inquiry']"
                                        name="field_inquiry_studen"
                                        class="">Inquiry</mat-checkbox>
                                </div>

                                <div class="col-12">
                                    <h3 class="mt-3">Schedule First Lesson</h3>
                                </div>

                                <div class="col-4">
                                    <mat-form-field class="full-width-field">
                                        <mat-label>Teacher</mat-label>
                                        <mat-select matInput ngModel name="field_teacher">
                                            <mat-option>None</mat-option>
                                            <mat-option *ngFor="let element of this._fieldsService.instructorData()"
                                                [value]="element.uid">
                                                {{ element.value }}</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>

                                <div class="col-4">
                                    <mat-form-field class="full-width-field">
                                        <mat-label>Lesson Type</mat-label>
                                        <mat-select ngModel name="lesson_type">
                                            <mat-option>None</mat-option>
                                            <mat-option
                                                *ngFor="let element of this._taxonomyService.lesson_type"
                                                [value]="element.id">{{ element.name }}</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>


                                <div class="col-4">
                                    <mat-form-field>
                                        <input
                                        [ngModel]="field_date_and_time"
                                        (ngModelChange)="uiUpdateDate($event)"
                                        name="requested_date_time"
                                        matInput
                                        [ngxMatDatetimePicker]="picker_field_date_time"
                                        placeholder="Requested Date and Time">
                                    <mat-datepicker-toggle matSuffix [for]="picker_field_date_time">
                                    </mat-datepicker-toggle>
                                    <ngx-mat-datetime-picker #picker_field_date_time
                                        [hideTime]="false"
                                        [enableMeridian]="true"
                                        [showSpinners]="true"
                                        [showSeconds]="false"
                                        [stepHour]="1" [stepMinute]="15"
                                        [touchUi]="false">
                                    </ngx-mat-datetime-picker>
                                    </mat-form-field>
                                </div>

                                <div class="col-12">
                                    <mat-form-field class="full-width-field">
                                        <mat-label>Notes</mat-label>
                                        <textarea ngModel name="field_notes" matInput placeholder=""></textarea>
                                    </mat-form-field>
                                </div>

                                <!-- <div class="col-6">
                                    <mat-form-field class="full-width-field">
                                        <mat-label>Low Lesson Threshold</mat-label>
                                        <input
                                            name="field_low_lesson_threshold"
                                            matInput
                                            type="number"
                                            ngModel>
                                    </mat-form-field>
                                </div> -->

                            </div>

                            <app-error-message [message]=this.errorMessage></app-error-message>

                            <button
                            [disabled]="field_contacts.length == 0"
                            type="button"
                            (click)="onSubmit(f);"
                            mat-raised-button
                            color="accent">Create Student Account</button>
                        </div>
                </form>
            </div>


</div>

<mat-dialog-actions align="end">
    <ng-container *ngIf="this.actionType === 'create'">
    </ng-container>
    <ng-container *ngIf="this.actionType === 'edit'">
        <ng-container *ngIf="this.initActionType == 'edit'">
            <button type="button" (click)="setFormMode('delete')" mat-raised-button color="accent">Delete</button>
            <button type="button" (click)="onSubmit(f);" mat-raised-button color="accent">Save</button>
        </ng-container>
        <ng-container *ngIf="this.initActionType == 'create'">
            <button type="button" (click)="closeDialog()" mat-raised-button color="accent">Cancel</button>
            <button type="button" (click)="onSubmit(f);" mat-raised-button color="accent">Save</button>
        </ng-container>
    </ng-container>
    <ng-container *ngIf="this.actionType === 'delete'">
        <button (click)="setFormMode('edit')" type="submit" mat-raised-button color="accent">Cancel</button>
        <button (click)="onDeleteSubmit(this._injectedDialogData['data']['EntityID'])" type="submit" mat-raised-button
            color="accent">Delete</button>
    </ng-container>
</mat-dialog-actions>
