import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatTabGroup } from '@angular/material/tabs';
import moment from 'moment';
import { BaseDialogComponent } from '../base-dialog/base-dialog.component';
import { BaseComponent } from '../base-form/base-form.component';
import _ from 'lodash';
import { from, concatMap } from 'rxjs';

@Component({
  selector: 'app-event-group-lesson-entity',
  templateUrl: './event-group-lesson-entity.component.html',
  styleUrls: ['./event-group-lesson-entity.component.css']
})
export class EventGroupLessonEntityComponent extends BaseDialogComponent implements OnInit {
  override eckType = "events";
  override bundle = "group_lesson";
  override entityId = this._injectedDialogData['data']['EntityID'];

  entityType = "attendees";
  entityBundle = "attendance";

  override onSubmit(form): void {
    this.errorMessage = "";
    this.displayProgressSpinner(true);

    let values;
    values = _.cloneDeep(form.value);
    this.__alterValues(values);

    // Check for create mode.
    if (this.actionType == "create") {
      this.displayProgressSpinner(true);

      from(values.field_instructor).pipe(
        concatMap(instructorId => {
          const instructorValues = { ...values, field_instructor: instructorId };
          return this._entityRESTService.createEntity(this.eckType, this.bundle, instructorValues);
        })
      ).subscribe({
        next: data => console.log('data', data),
        error: error => {
          this.handleError(error);
          this.displayProgressSpinner(false);
        },
        complete: () => {
          this.closeDialog();
          this.displayProgressSpinner(false);
        }
      });
    }

    if (this.actionType == "edit") {
      this.displayProgressSpinner(true);

      this._entityRESTService.patchEntity(this.eckType, this.bundle, this.entityId, values)
        .subscribe(data => {
          console.log('data', data);
          this.closeDialog();
          this.displayProgressSpinner(false);
        },
          error => this.handleError(error)
        )
    }

  }


  studentIncrement(attendee: any) {
    console.log('attendee');
    console.log(attendee);

    // Append type & bundle to model.
    attendee = {
      ...attendee,
      type: 'attendees',
      bundle: 'attendance',
    }

    // If our data binding fails us (API needs to give an array), set as an empty array.
    if (!Array.isArray(this.field_student)) { this.field_student = [] }

    this.field_student.push(attendee);

    this.resetFieldStudent();
  }

  studentRemove = (index: number): void => {
    this.f.form.controls['__field_students_inline_form'].reset();
    this.editMode = false;
    this.field_student.splice(index, 1);

    this.resetFieldStudent();
  }

  studentUpdateLoad = (index: number): void => {
    console.log('UpdateLoad called...')
    this.resetFieldStudent();

    // Load the student enrollments again.
    this.lookupStudentAccountContact({ option: { value: this.field_student[index].field_student_account } });
    // Load the student contacts again.
    this.onStudentNameSelectThenLoadEnrollmentName({ option: { value: this.field_student[index].field_student_account } })

    // Append type & bundle to model.
    this.field_student[index]['type'] = 'attendees';
    this.field_student[index]['bundle'] = 'attendance';

    this.editIndex = index;
    this.editMode = true;

    // If field_student is a couple.
    this.field_student[index]['field_students'] = this.field_student[index]['field_students'].toString();

    Promise.resolve().then(() => {
      const formValue = { ...this.field_student[index] };
      delete formValue.id;
      this.f.controls['__field_students_inline_form'].patchValue(formValue)
    })
  }

  studentUpdateSubmit(number: any, attendee: any) {
    this.field_student[this.editIndex] = attendee;
    this.f.form.controls['__field_students_inline_form'].reset();
    this.editMode = false;

    this.resetFieldStudent();
  }

  resetFieldStudent() {
    this.enrollmentMessage = "";
    this.autoCompleteStudentContactOptions = null;
    this.enrollmentsForUser = null;
    this.f.form.controls['__field_students_inline_form'].reset();
  }
}

