<div style="height:64px;" class="page-empty-header"></div>

<div class="container-fluid">
  <div class="row">
    <div style="background:#215DAB; height: calc(100vh - 64px); overflow: scroll;" class="col-2">
      <app-configuration-dashboard-menu></app-configuration-dashboard-menu>
    </div>

    <div class="col-10" style="padding-left: 2.5rem;padding-right: 2.5rem;padding-top:2.5rem;overflow:scroll;max-height: calc(100vh - 64px);">

      <div class="container-fluid">
        <h1 class="">Gift Certificates</h1>

        <nav mat-tab-nav-bar #tabPanel>
          <a mat-tab-link
             *ngFor="let link of links"
             [routerLink]="link.path"
             [queryParams]="link.queryParams"
             routerLinkActive #rla="routerLinkActive"
             [active]="rla.isActive"
             (click)="activeLink = link">
            {{link.label}}
          </a>
        </nav>

        <mat-progress-bar *ngIf="ShowProgressBar" mode="indeterminate"></mat-progress-bar>

        <div class="row">
          <div class="col-md-12 mt-3">
            <div class="float-end">
              <button class="" mat-flat-button
                      (click)="openGiftCertificateDialog(null, 'create')">
                Add Gift Certificate
              </button>
            </div>
          </div>
        </div>

				<table multiTemplateDataRows mat-table #displayTable [dataSource]="dataSource" matSort (matSortChange)="sortDataView($event)" class="mt-4">
					<mat-menu #giftCertificateMenu="matMenu">
						<ng-template matMenuContent let-id="id">
							<button mat-menu-item (click)="openGiftCertificateDialog(id, 'delete')">
								Delete Gift Certificate
							</button>
						</ng-template>
					</mat-menu>

					<ng-container matColumnDef="expand">
						<th mat-header-cell *matHeaderCellDef></th>
						<td mat-cell *matCellDef="let element">
							<button style="color:#000; background-color: unset !important;" mat-icon-button (click)="toggleRow(element)" [attr.aria-expanded]="expandedElements[element.id]" aria-label="expand row">
								<mat-icon>{{ expandedElements[element.id] ? 'expand_less' : 'expand_more' }}</mat-icon>
							</button>
						</td>
					</ng-container>

					<ng-container matColumnDef="field_gift_certificate_code">
						<th mat-header-cell *matHeaderCellDef mat-sort-header>Code</th>
						<td mat-cell *matCellDef="let element">{{element.field_gift_certificate_code}}</td>
					</ng-container>

					<ng-container matColumnDef="field_gift_certificate_amount">
						<th mat-header-cell *matHeaderCellDef mat-sort-header>Amount</th>
						<td mat-cell *matCellDef="let element">{{element.field_gift_certificate_amount | currency}}</td>
					</ng-container>

					<ng-container matColumnDef="field_remaining_balance">
						<th mat-header-cell *matHeaderCellDef mat-sort-header>Remaining</th>
						<td mat-cell *matCellDef="let element">{{element.field_remaining_balance | currency}}</td>
					</ng-container>

					<ng-container matColumnDef="field_gift_certificate_status">
						<th mat-header-cell *matHeaderCellDef mat-sort-header>Status</th>
						<td mat-cell *matCellDef="let element">{{element.field_gift_certificate_status}}</td>
					</ng-container>

					<ng-container matColumnDef="field_purchase_date">
						<th mat-header-cell *matHeaderCellDef mat-sort-header>Purchase Date</th>
						<td mat-cell *matCellDef="let element">{{element.field_purchase_date | date: 'MM/dd/yyyy'}}</td>
					</ng-container>

					<ng-container matColumnDef="field_expiration_date">
						<th mat-header-cell *matHeaderCellDef mat-sort-header>Expiration Date</th>
						<td mat-cell *matCellDef="let element">{{element.field_expiration_date | date: 'MM/dd/yyyy'}}</td>
					</ng-container>

					<!-- Use the template reference variable in matMenuTriggerFor -->
					<ng-container matColumnDef="edit">
						<th mat-header-cell *matHeaderCellDef>Actions</th>
						<td mat-cell *matCellDef="let element">
							<button
								mat-icon-button
								[matMenuTriggerFor]="giftCertificateMenu"
								[matMenuTriggerData]="{ id: element.id }"
							>
								<mat-icon>more_vert</mat-icon>
							</button>
						</td>
					</ng-container>

					<ng-container matColumnDef="expandedDetail">
						<td mat-cell *matCellDef="let element" [attr.colspan]="displayedColumns.length">
							<div class="element-detail" [@detailExpand]="expandedElements[element.id] ? 'expanded' : 'collapsed'">
								<div class="row">
									<div class="col-md-6">
										<h5>Recipient Details</h5>
										<p><strong>Name:</strong> {{ element.field_recipient_name }}</p>
										<p><strong>Email:</strong> {{ element.field_recipient_email }}</p>
										<p><strong>Phone:</strong> {{ element.field_recipient_phone }}</p>
									</div>
									<div class="col-md-6">
										<h5>Purchaser Details</h5>
										<p><strong>Name:</strong> {{ element.field_purchaser_name }}</p>
										<p><strong>Email:</strong> {{ element.field_purchaser_email }}</p>
										<p><strong>Phone:</strong> {{ element.field_purchaser_phone }}</p>
									</div>
								</div>
								<div class="row mt-3">
									<div class="col-md-6">
										<p><strong>Location Purchased:</strong> {{ element.studio_purchaser_title }}</p>
										<p><strong>Location Redeemed:</strong> {{ element.field_gift_certificate_location ? element.field_gift_certificate_location : 'Not Redeemed' }}</p>
										<p><strong>Notes:</strong> {{ element.field_gift_certificate_notes }}</p>
									</div>
									<div class="col-md-6">
										<p><strong>Checkout Type:</strong> {{ element.field_checkout_type }}</p>
										<p><strong>Payment:</strong> {{ element.field_gift_certificate_payment }}</p>
									</div>
								</div>
							</div>
						</td>
					</ng-container>

					<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
					<tr mat-row *matRowDef="let element; columns: displayedColumns;"
							class="element-row"
							[class.expanded]="expandedElements[element.id]">
					</tr>
					<tr mat-row *matRowDef="let row; columns: ['expandedDetail']; when: isExpansionDetailRow"
							class="detail-row"
							[class.d-none]="!expandedElements[row.id]">
					</tr>
				</table>

        <mat-paginator
											[disabled]="ShowProgressBar"
											(page)="pageChanged($event)"
                      [pageSizeOptions]="onPageSizeOptions()" [length]="30"
                      [pageSize]="paginationTotalPages" [pageIndex]="paginationIndex"
                      aria-label="Select page of view"></mat-paginator>

      </div>

    </div>

  </div>

</div>
