import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import moment from 'moment';
import { BaseSimpleDialogComponent } from '../base-simple-dialog/base-simple-dialog.component';

@Component({
  selector: 'app-update-service',
  templateUrl: './update-service.component.html',
  styleUrls: ['./update-service.component.css']
})
export class UpdateServiceComponent extends BaseSimpleDialogComponent {

  @ViewChild(NgForm) override f: NgForm;

  displayedColumns: string[] = ['select', 'title', 'instructor', 'time', 'status'];


  override onSubmit(form: NgForm) {

    let type = 'services';
    let dateTime = moment().format('YYYY-MM-DD[T]hh:mm:ss');

    console.log('form form value', form.form.value)
    console.log('selection', this.selection['_selected'])

    // Build request...
    let body:any = {}
    let events = [];

    // Grab selected ID's from selection.
    for (const property in this.selection['_selected']) {
      events[property] = this.selection['_selected'][property].id
      body = {
        ...body,
        events
      }
    }

    // Add status, and other various params the endpoint is expecting.
    body = {
      ...body,
      'status_id': form.form.value.lessons_status_selectbox,
      'type': type,
      'dateTime': dateTime,
    };

    this._drupalRESTService.httpPOST('/api_rest/v1/updateLesson', body, [{ parameter: '_format', value :'json' }])
      .subscribe(data => {
        console.log(data)
        if (data['success']) {
          this.closeDialog();
        }
        if (data['message']) {
          this.errorMessage = data['message'];
        }
      },
      error => {
        this.handleError(error);
      });

  }

  ngOnInit(): void {
    console.log('injectedDialogData-eventData', this._injectedDialogData?.['eventData'])

    this.dataSource = new MatTableDataSource(
      this.filterEventLessonType(this._injectedDialogData['eventData'], 'services')
    );
  }
}
