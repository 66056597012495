<mat-expansion-panel>
  <mat-expansion-panel-header>
    <mat-panel-title>
      Filter
    </mat-panel-title>
    <mat-panel-description>
      Showing only events&nbsp;
      <span *ngIf="filterInstructorCategoryVal"> for {{ getNameFromId(filterInstructorCategoryVal, filterInstructorCategory, 'name') }} Instructors&nbsp;</span>
      <span *ngIf="filterLessonTypeVal"> for {{ getNameFromId(filterLessonTypeVal, filterLessonType) }} Lessons</span>
      <span *ngIf="filterStudent"> for {{ filterStudent }}&nbsp;</span>
      <span *ngIf="filterGroupLesson || filterLesson || filterServices">
        that are
        <span *ngIf="filterGroupLesson"> Group Lessons </span>
        <span *ngIf="filterLesson"> Personal Lessons </span>
        <span *ngIf="filterServices"> Services </span>
        &nbsp;
      </span>
      <span *ngIf="filterDate">&nbsp;on {{ filterDate | date:'shortDate' }}</span>
    </mat-panel-description>
  </mat-expansion-panel-header>

  <form (ngSubmit)="onSubmit(f)" #f=ngForm class="form">
    <div class="row">
    <!-- <p *ngFor="let lesson of filterLessonType" class="{{lesson.id}}">
        {{lesson.value}}
    </p> -->
        <div class="col-3">
            <mat-form-field class="full-width-field">
                <mat-label>Instructor Category</mat-label>
                <mat-select
                    name="filterInstructorCategory"
                    [(ngModel)]="filterInstructorCategoryVal"
                    (selectionChange)="onSubmit(f)"
                    >
                    <mat-option>None</mat-option>
                    <mat-option *ngFor="let value of filterInstructorCategory" [value]="value.id">{{value.name}}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>

        <div class="col-3">
            <!-- <mat-form-field appearance="standard">
                <mat-label>Select Instructor</mat-label>
                <mat-select
                    name="filterInstructor"
                    [(ngModel)]="filterInstructor">
                    <mat-option *ngFor="let value of filterInstructor" [value]="value.name">{{value.name}}</mat-option>
                </mat-select>
            </mat-form-field> -->

            <mat-form-field class="full-width-field">
                <mat-label>Choose Lesson Type..</mat-label>
                <mat-select
                    (selectionChange)="onSubmit(f)"
                    [(ngModel)]="filterLessonTypeVal"
                    name="filterLessonType">
                    <mat-option>None</mat-option>
                    <mat-option
                        *ngFor="let element of this._taxonomyService.lesson_type"
                        [value]="element.id">{{ element.name }}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>


        <div class="col-3">

            <mat-form-field class="full-width-field">
                <mat-label>Student</mat-label>
                <input
                    #studentInput
                    name="filterStudent"
                    (input)="onStudentInput($event)"
                    matInput
                    [(ngModel)]="filterStudent"
                    [matAutocomplete]="auto"
                    >
                    <button type="button" matSuffix mat-icon-button aria-label="manage_accounts">
                        <mat-icon>manage_accounts</mat-icon>
                    </button>
            </mat-form-field>
            <mat-autocomplete #auto="matAutocomplete"
                    (optionSelected)="onSubmit(f)">
                <mat-option *ngFor="let option of autoCompleteOptions"
                    [value]="option.label + ' ' + '(' + option.value + ')'">
                    {{option.label}} ({{option.value}})
                </mat-option>
            </mat-autocomplete>
        </div>


        <div class="col-3">
            <mat-form-field class="full-width-field">
              <mat-label>Choose a date</mat-label>
              <input
                [owlDateTime]="picker"
                matInput
                name="filterDate"
                [(ngModel)]="filterDate"
                (click)="picker.open()"
                (dateTimeChange)="onSubmit(f)">
              <span class="cursor-pointer" matSuffix [owlDateTimeTrigger]="picker">
                <mat-icon>calendar_today</mat-icon>
              </span>
              <owl-date-time #picker [pickerType]="'calendar'" [stepHour]="1" [stepMinute]="15"></owl-date-time>
            </mat-form-field>
          </div>


    </div>

    <section class="section">
        <mat-checkbox
            name="filterGroupLesson"
            class="margin"
            [(ngModel)]="filterGroupLesson"
            (change)="onSubmit(f)">Show Group Lessons</mat-checkbox>
        <mat-checkbox
            name="filterLesson"
            class="margin"
            [(ngModel)]="filterLesson"
            (change)="onSubmit(f)">Show Lessons</mat-checkbox>

        <mat-checkbox
            name="filterServices"
            class="margin"
            [(ngModel)]="filterServices"
            (change)="onSubmit(f)">Show Services</mat-checkbox>

        <mat-checkbox
            name="filterHiddenEvents"
            class="margin"
            [(ngModel)]="filterHiddenEvents"
            (change)="onSubmit(f)">Show Hidden Events</mat-checkbox>
    </section>
<!--
    <mat-divider></mat-divider>
    <button type="submit"
        mat-raised-button
        color="primary">Filter</button> -->

</form>

</mat-expansion-panel>
