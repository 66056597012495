<div style="height:64px;" class="page-empty-header"></div>

<div class="container-fluid">
	<div class="row">
		<div style="background:#215DAB; height: calc(100vh - 64px); overflow:scroll;" class="col-2">
			<app-configuration-dashboard-menu></app-configuration-dashboard-menu>
		</div>

		<div class="col-10" style="padding-left: 2.5rem;padding-right: 2.5rem;padding-top:2.5rem;overflow:scroll;max-height: calc(100vh - 64px);">


		</div>

	</div>

</div>
