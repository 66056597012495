import { Component, OnInit, ViewChild } from '@angular/core';
import { UtilityService } from 'src/app/services/utility.service';
import { ChartConfiguration, ChartData, ChartEvent, ChartType } from 'chart.js';
import { BaseChartDirective } from 'ng2-charts';
import DataLabelsPlugin from 'chartjs-plugin-datalabels';


@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {

  constructor(private _utilityService: UtilityService) { }

  @ViewChild(BaseChartDirective) chart: BaseChartDirective | undefined;
  dashboardData;
  chart_data: Object;

  ngOnInit(): void {
    this.getDashboard();
    this.getCharts();
  }

  getCharts() {
    this._utilityService.loadCharts()
      .subscribe(data => {
        this.chart_data = data;
      })
  }

  getDashboard() {
    this._utilityService.getDashboardPage()
      .subscribe(
        (data) => {
          this.dashboardData = data;
          console.log(this.dashboardData)
        })
  }

}
