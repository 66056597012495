import { Component, Input, OnInit } from '@angular/core';
import _ from 'lodash';

@Component({
  selector: 'app-filter-results-panel',
  templateUrl: './filter-results-panel.component.html',
  styleUrls: ['./filter-results-panel.component.css']
})
export class FilterResultsPanelComponent implements OnInit {

  @Input() set parameters(parameters) {
    this.prettyParameters = this.prettyFieldNames(parameters);
  };

  prettyParameters;

  hiddenParameters = ['_format', 'field_retired_op', 'field_retired_value', 'undefined'];

  // Names to be translated.
  namesObj = {
    "field_first_name_value": "First Name",
    "field_last_name_value": "Last Name",
    "field_email_value": "Email",
    "inquiry_taker": "Inquiry Taker",
    "teacher": "Teacher",
    "service_type": "Service Type",
    "to": "To",
    "from": "From",
    "status": "Status",
    "type": "Role",
    "week": "Week",
    "year": "Year",
    "instructor": "Instructor ID",
    "field_type_target_id": "Lesson Type",
    "field_enrollment_package_name_target_id": "Sales Package",
    "field_student_target_id": "Student ID",
    "student_name": "Student Name",
    "title": "Student",
    "field_date_and_time_value": "Date and Time",
    "enrollment_title": "Enrollment",
    "field_student_department_target_id": "Department",
    "field_standings": "Standings",
    "field_date_and_time_value_1": "End Date",
    "field_package_name_value": "Package Name",
    "stype": "Sort Type",
    "sort": "Sort",
    "sort_order": "Sort Order",
    "sort_by": "Sort By",
    "name": "Name",
    "field_status_value": "Status",
    "title_1": "Student",
    "student_department_id": "Department",
    "field_department_name": "Department",
    "field_enrollment_name": "Enrollment",
    "start_date": "Start Date",
    "end_date": "End Date",
    "field_student": "Student",
    "field_instructor": "Instructor",
    "field_student_id": "Student ID",
    "page": "Page",
    "email": "Email",
    "paid_finished": "Paid Finished",
    "field_payment_date": "Payment Date Range",
    "field_package_code": "Package Code",
    "field_date_and_time_value_start": "Start Date",
    "field_date_and_time_value_end": "End Date",
    "field_date_and_time_value[min]": "Start Date",
    "field_date_and_time_value[max]": "End Date",
    "last_lesson_taken_start": "Last Lesson Start",
    "last_lesson_taken_end": "Last Lesson End",
    "field_category_target_id": "Category ID",
    "field_total_price_value": "Total Price",
    "field_lesson_count_value_1": "Lesson Count",
    "field_lesson_price_value": "Lesson Price",
    "field_lesson_type_target_id_1": "Lesson Type",
    "field_sale_date_value": "Sale Date",
    "field_sps_code_target_id": "SPS Code",
    "field_package_id_value": "Package ID",
  }

  constructor() { }

  ngOnInit(): void {
  }

  public prettyFieldNames(parameters) {
    let parameterValues, oldParameterValue, newParameterValue;
    parameterValues = _.cloneDeep(parameters);

    for (const property in parameterValues) {
      // Don't allow undefined params to display.
      if (parameterValues[property].parameter == undefined || parameterValues[property].value == "") { return }

      let newProperty = this.lookupHumanName(parameterValues[property].parameter);
      parameterValues[property].parameter = newProperty;
    }
    return parameterValues;
  }

  lookupHumanName(field) {
    return this.namesObj[field] ?? field;
  }


}
