import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { BaseDialogComponent } from '../base-dialog/base-dialog.component';

@Component({
  selector: 'app-cancel-future-service',
  templateUrl: './cancel-future-service.component.html',
  styleUrls: ['./cancel-future-service.component.css']
})
export class CancelFutureServiceComponent extends BaseDialogComponent implements OnInit {

  override ngAfterContentInit(): void {
    console.log('injectedDialogData', this._injectedDialogData['data']);
    console.log(this._injectedDialogData['data']);

    this.onSubmit(this.f);
  }

  override onSubmit(form: NgForm) {
    this.displayProgressSpinner(true);

    // Build request...
    let params: any;

    // Add params the endpoint is expecting.
    params = {
      entityID: this._injectedDialogData['data']['entityID'],
    };

    this._drupalRESTService.httpPOST('/api_rest/v1/cancelFutureStandingServices', params)
      .subscribe((data: any) => {
        this.displayProgressSpinner(false);
        console.log(data);
        if (data.success) {
          this._dialog.closeAll();
        }
        if (data.message) {
          this.errorMessage = data.message;
        }
      },
        error => {
          this.displayProgressSpinner(false);
          this.errorMessage = "There was a problem canceling future services."; // Default error message.
          this.handleError(error);
        });
  }

}
