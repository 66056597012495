import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BaseDialogComponent } from '../base-dialog/base-dialog.component';

@Component({
  selector: 'app-enrollment-view-lesson',
  templateUrl: './enrollment-view-lesson.component.html',
  styleUrls: ['./enrollment-view-lesson.component.css']
})
export class EnrollmentViewLessonComponent extends BaseDialogComponent implements OnInit {
  @Input() override entityId;
  @Output() dataCountEvent = new EventEmitter<string>();
}
