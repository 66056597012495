import { Component, Inject, Input, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'inquiry',
  templateUrl: './inquiry.component.html',
  styleUrls: [
    './inquiry.component.css',
    '../../../../app.component.css'
  ]
})

export class InquiryComponent implements OnInit {  

  constructor(@Inject(MAT_DIALOG_DATA) public data: {InstructorList: {}, lesson_types: string[]}) { }

  ngOnInit(): void {
  }

  onSubmit(form: NgForm) {
    console.log(form.value);
  }

}
