import { Component, Input, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { ViewTableComponent } from 'src/app/components/base/view-table/view-table.component';

@Component({
  selector: 'app-student-future-lessons',
  templateUrl: './student-future-lessons.component.html',
  styleUrls: ['./student-future-lessons.component.css']
})
export class StudentFutureLessonsComponent extends ViewTableComponent implements OnInit {

  @Input() drupal_student_id: number;
  studentFutureLessonsData;
  override pageSize: number = 5;

  override displayedColumns: string[] = [
    'date',
    'time',
    'instructorName',
    'lessonCategory',
    'enrollmentCategory',
  ];

  override getData(): void;
  override getData(params: { parameter: string; value: string; }[]): void;
  override getData(params: { parameter?: string; value?: string; }[] = []) {

    // Show Progress Bar (Loading indicator)
    this.ShowProgressBar = true;

    // Initialize queryParams if params are not provided
    this.queryParams = [];

    // Add sorting paramaters, if available.
    if (this.sortParams !== undefined) {
      this.queryParams.push(this.sortParams)
    }

    const endpoint = `/api_rest/v1/studentFutureLessons?contact_id=${this.drupal_student_id}&items_per_page=${this.pageSize}`;

    this.studentFutureLessonsData = this._drupalRESTService.httpGET(endpoint);

    // Load the new data with the filtering parameters.
    this.studentFutureLessonsData.subscribe((data) => {
        // ShowProgressBar
        this.ShowProgressBar = false;

        this.data = data;
        this.dataSource = new MatTableDataSource(data['results']);

        // Pagination
        if (data?.['results'] && this.paginator?.['length']) {
          this.paginator.length = data?.['count'];
          this.paginator.pageSize = this?.['pageSize'];
          this.paginator.pageIndex = this?.['paginationIndex'];
        }
      },
        error => this.handleError(error)
      )
  }



}
