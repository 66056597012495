import { HttpErrorResponse } from '@angular/common/http';
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, AbstractControl, FormArray, Validators, ValidatorFn, ValidationErrors } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import moment from 'moment';
import { Subscription, from, concatMap, tap, catchError, of, retryWhen, toArray, mergeMap, throwError, timer, Observable, forkJoin, switchMap, distinctUntilChanged, filter } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';
import { DrupalRESTService } from 'src/app/services/drupal-rest.service';
import { EntityRESTService } from 'src/app/services/entity-rest.service';
import { FieldsService } from 'src/app/services/fields.service';
import { GenderDetectionService } from 'src/app/services/gender-detection.service';
import { PackagesService } from 'src/app/services/packages.service';
import { TaxonomyService } from 'src/app/services/taxonomy.service';

@Component({
  selector: 'app-simple-import-history',
  templateUrl: './simple-import-history.component.html',
  styleUrls: ['./simple-import-history.component.css']
})
export class SimpleImportHistoryComponent implements OnInit {

  isSubmitting: boolean = false;
  loadingEnrollments: { [key: string]: boolean } = {};
  loadingStudentAccounts: { [key: number]: boolean } = {};
  totalOperations: number;
  completedOperations: number;
  submissionProgress: number;

  constructor(private fb: FormBuilder, private packagesService: PackagesService, private _taxonomyService: TaxonomyService, private _fieldsService: FieldsService, private _entityRESTService: EntityRESTService, private _genderDetectionService: GenderDetectionService, private _authService: AuthService, private snackBar: MatSnackBar, private changeDetectorRef: ChangeDetectorRef, private _drupalRESTService: DrupalRESTService) {
    this.studentAccountsForm = this.fb.group({
      studentAccounts: this.fb.array([])
    });
  }

  studentAccountsForm: FormGroup;
  packages = [];
  firstNameSubscriptions: Subscription[] = [];
  lastNameSubscriptions: Subscription[] = [];

  studentAccountSuccessStates = {};
  studentErrorMessages = {};
  studentSuccessStates = {};
  enrollmentErrorMessages = {};
  enrollmentSuccessStates = {};
  paymentErrorMessages = {};
  paymentSuccessStates = {};
  lessonSuccessStates = {};
  lessonErrorMessages = {};

  ngOnInit() {
    this.addStudentAccount();
    this.setupAutofillListeners();
    this.subscribeToDiscountChanges();
    this.setupGenderDetection();
    this.setupInstructorChangeListeners();
    this.setupTotalLessonsListeners();

    this.packagesService.packages$.subscribe(packages => {
      this.packages = packages;
    });

    this.packagesService.fetchPackages();

    this._taxonomyService.initTaxonomyTerms();

    this.studentAccountsArray.controls.forEach((studentAccount, studentAccountIndex) => {
      const enrollmentsArray = studentAccount.get('enrollments') as FormArray;
      enrollmentsArray.controls.forEach((enrollment, enrollmentIndex) => {
        this.addSaleDateListener(enrollment as FormGroup, studentAccountIndex, enrollmentIndex);
        this.setupLessonAvailabilityListener(enrollment as FormGroup); // Add this line
      });
    });

    this.studentAccountsArray.valueChanges.subscribe(() => {
      this.studentAccountsArray.controls.forEach((studentAccount, studentAccountIndex) => {
        const enrollmentsArray = studentAccount.get('enrollments') as FormArray;
        enrollmentsArray.controls.forEach((enrollment, enrollmentIndex) => {
          this.addSaleDateListener(enrollment as FormGroup, studentAccountIndex, enrollmentIndex);
          this.setupLessonAvailabilityListener(enrollment as FormGroup); // Add this line
        });
      });
    });
  }

  getEnrollmentsFormGroup(studentAccount: AbstractControl): FormArray {
    return studentAccount.get('enrollments') as FormArray;
  }

  getLessons(studentAccountIndex: number, enrollmentIndex: number): FormArray {
    const studentAccountFormGroup = this.studentAccountsArray.at(studentAccountIndex);
    if (!studentAccountFormGroup) {
      console.error('Student account form group not found');
      return; // or handle the error appropriately
    }

    const enrollments = studentAccountFormGroup.get('enrollments') as FormArray;
    if (!enrollments) {
      console.error('Enrollments FormArray not found');
      return; // or handle the error appropriately
    }

    const enrollmentFormGroup = enrollments.at(enrollmentIndex);
    if (!enrollmentFormGroup) {
      console.error('Enrollment form group not found');
      return; // or handle the error appropriately
    }

    return enrollmentFormGroup.get('lessons') as FormArray;
  }

  getPayments(studentAccountIndex: number, enrollmentIndex: number): FormArray {
    // Safely get the student account FormGroup
    const studentAccountFormGroup = this.studentAccountsArray.at(studentAccountIndex) as FormGroup;
    if (!studentAccountFormGroup) {
      console.error('Student account form group not found');
      return; // or handle the error appropriately
    }

    // Safely get the enrollments FormArray
    const enrollments = studentAccountFormGroup.get('enrollments') as FormArray;
    if (!enrollments || enrollments.length <= enrollmentIndex) {
      console.error('Enrollments FormArray not found or index out of bounds');
      return; // or handle the error appropriately
    }

    // Safely get the specific enrollment FormGroup
    const enrollmentFormGroup = enrollments.at(enrollmentIndex) as FormGroup;
    if (!enrollmentFormGroup) {
      console.error('Enrollment form group not found');
      return; // or handle the error appropriately
    }

    // Return the payments FormArray
    return enrollmentFormGroup.get('payments') as FormArray;
  }

  onPackageChange(packageId: string, studentAccountIndex: number, enrollmentIndex: number) {
    const selectedPackage = this.packages.find(pkg => pkg.id === packageId);

    if (selectedPackage) {
      const studentAccountFormGroup = this.studentAccountsArray.at(studentAccountIndex) as FormGroup;
      const enrollmentsArray = studentAccountFormGroup.get('enrollments') as FormArray;
      const enrollmentFormGroup = enrollmentsArray.at(enrollmentIndex) as FormGroup;

      const discount = parseFloat(selectedPackage.field_discount) || 0;
      let originalTotalPrice = parseFloat(selectedPackage.field_total_price);

      if (selectedPackage.field_discount_type === 'flat') {
        originalTotalPrice += discount;
      } else if (selectedPackage.field_discount_type === 'percentage') {
        originalTotalPrice /= (1 - (discount / 100));
      }

      // Keep existing values for __total_lessons_enrolled, __total_enrollment_price, and field_discount if they exist
      const existingTotalLessonsEnrolled = enrollmentFormGroup.get('__total_lessons_enrolled').value || selectedPackage.field_lesson_count;
      const existingTotalEnrollmentPrice = enrollmentFormGroup.get('__total_enrollment_price').value || originalTotalPrice;
      const existingFieldDiscount = enrollmentFormGroup.get('field_discount').value || discount;

      // Get the value from the form's field_sale_date input
      const saleDate = enrollmentFormGroup.get('field_sale_date').value || moment().format('YYYY-MM-DD[T]HH:mm:ss');

      // if field_discount_type is empty, set it to 'flat'
      if (!selectedPackage.field_discount_type) {
        selectedPackage.field_discount_type = 'flat';
      }

      enrollmentFormGroup.patchValue({
        field_enrollment_package_name: selectedPackage.id,
        field_enrollment_lesson_price: parseFloat(selectedPackage.field_lesson_price),
        field_enrollment_lesson_count: existingTotalLessonsEnrolled,
        field_enrollment_total_price: existingTotalEnrollmentPrice,
        field_discount_type: selectedPackage.field_discount_type,
        field_discount: existingFieldDiscount,
        field_category: "1421", // selectedPackage.id, // Set to the ID value
        field_sale_date: saleDate,
        field_expiration_date: moment(saleDate).add(6, 'month').format('YYYY-MM-DD[T]HH:mm:ss')
      });

      this.autofillFields(studentAccountIndex, enrollmentIndex);
    }
  }

  get studentAccountsArray(): FormArray {
    return this.studentAccountsForm.get('studentAccounts') as FormArray;
  }

  addStudentAccount(id = '') {
    const studentAccountFormGroup = this.fb.group({
      id: [id],
      contact1ID: [''], // Optional, no validators
      contact1FirstName: ['', Validators.required],
      contact1LastName: ['', Validators.required],
      contact1Email: ['', [Validators.required, Validators.email]],
      contact1Phone: ['', Validators.required],
      contact1Gender: [''],
      contact2ID: [''], // Optional, no validators
      contact2FirstName: [''], // Optional, no validators
      contact2LastName: [''],  // Optional, no validators
      contact2Email: ['', Validators.email], // Email validation, but not required
      contact2Phone: [''], // Optional, no validators
      contact2Gender: [''], // Optional, no validators
      field_inquired: ['1999-01-01'],
      enrollments: this.fb.array([this.createEnrollmentFormGroup()]),
    });

    this.studentAccountsArray.push(studentAccountFormGroup);
    this.setupAutofillListenersForEnrollment((studentAccountFormGroup.get('enrollments') as FormArray).at(0) as FormGroup, this.studentAccountsArray.length - 1, 0);
    this.subscribeToDiscountChanges();
    this.setupGenderDetection();
    this.setupInstructorChangeListeners();
  }

  addEnrollment(studentAccountIndex: number) {
    const enrollments = this.studentAccountsArray.at(studentAccountIndex).get('enrollments') as FormArray;
    const newEnrollmentFormGroup = this.createEnrollmentFormGroup();
    enrollments.push(newEnrollmentFormGroup);

    this.setupAutofillListenersForEnrollment(newEnrollmentFormGroup, studentAccountIndex, enrollments.length - 1);
    this.subscribeToInstructorChanges(newEnrollmentFormGroup);
    this.subscribeToEnrollmentDateChanges(newEnrollmentFormGroup);

    // Add initial payment form group to the new enrollment
    this.addPaymentToEnrollment(newEnrollmentFormGroup);

    // Set the instructor for each lesson
    const lessonsArray = newEnrollmentFormGroup.get('lessons') as FormArray;
    const instructor = newEnrollmentFormGroup.get('field_instructor').value;
    lessonsArray.controls.forEach((lessonFormGroup: FormGroup) => {
      lessonFormGroup.patchValue({ field_instructor: instructor });
    });
  }

  setupLessonAvailabilityListener(enrollmentFormGroup: FormGroup) {
    const totalLessonsAvailableControl = enrollmentFormGroup.get('__total_lessons_available');

    if (totalLessonsAvailableControl) {
      totalLessonsAvailableControl.valueChanges.subscribe(() => {
        this.updatePayments(enrollmentFormGroup);
      });
    }
  }

  updatePayments(enrollmentFormGroup: FormGroup) {
    const totalLessonsAvailable = enrollmentFormGroup.get('__total_lessons_available').value || 0;
    const lessonPrice = this.calculateDiscountedPricePerLesson(enrollmentFormGroup);
    const grossTuition = this.calculateGrossTuition(enrollmentFormGroup);
    const saleDate = enrollmentFormGroup.get('field_sale_date').value;

    const paymentsArray = enrollmentFormGroup.get('payments') as FormArray;

    if (paymentsArray.length > 0) {
      const existingPaymentFormGroup = paymentsArray.at(0) as FormGroup;
      console.log('Updating payment with new total lessons available:', totalLessonsAvailable);
      existingPaymentFormGroup.patchValue({
        field_date_and_time: saleDate,
        field_gross_tuition: grossTuition,
        field_payment_type: '1414'
      });
    } else {
      console.log('Creating new payment with date:', saleDate);
      const paymentFormGroup = this.createPaymentFormGroup(saleDate, grossTuition);
      paymentsArray.push(paymentFormGroup);
    }
    console.log('Updated Payment Form Array:', paymentsArray.value);
  }

  addPaymentToEnrollment(enrollmentFormGroup: FormGroup) {
    const totalLessonsAvailable = enrollmentFormGroup.get('__total_lessons_available').value || 0;

    // Use the calculateDiscountedPricePerLesson function to get the lesson price
    const lessonPrice = this.calculateDiscountedPricePerLesson(enrollmentFormGroup);

    const grossTuition = totalLessonsAvailable * lessonPrice;
    const saleDate = enrollmentFormGroup.get('field_sale_date').value;

    const paymentsArray = enrollmentFormGroup.get('payments') as FormArray;

    // Check if a payment already exists
    if (paymentsArray.length > 0) {
      const existingPaymentFormGroup = paymentsArray.at(0) as FormGroup;
      console.log('Updating existing payment with date:', saleDate);
      existingPaymentFormGroup.patchValue({
        field_date_and_time: saleDate,
        field_gross_tuition: grossTuition,
        field_payment_type: '1414'
      });
    } else {
      console.log('Creating new payment with date:', saleDate);
      const paymentFormGroup = this.createPaymentFormGroup(saleDate, grossTuition);
      paymentsArray.push(paymentFormGroup);
    }
    console.log('Payment Form Array:', paymentsArray.value);
  }

  autoAddPaymentAndLesson(studentAccountIndex: number, enrollmentIndex: number, enrollmentFormGroup: FormGroup) {
    const totalLessonsTaken = enrollmentFormGroup.get('__total_lessons_taken').value || 0;
    const lessonPrice = parseFloat(enrollmentFormGroup.get('field_enrollment_lesson_price').value);
    const grossTuition = totalLessonsTaken * lessonPrice;
    const saleDate = enrollmentFormGroup.get('field_sale_date').value;
    const instructor = enrollmentFormGroup.get('field_instructor').value;
    const taxAmount = enrollmentFormGroup.get('field_tax_collected').value || 0;

    // Add Payment automatically
    const paymentFormGroup = this.createPaymentFormGroup(saleDate, grossTuition);
    const payments = enrollmentFormGroup.get('payments') as FormArray;
    payments.push(paymentFormGroup);

    // Add Lesson(s) automatically
    const lessons = enrollmentFormGroup.get('lessons') as FormArray;
    for (let i = 0; i < totalLessonsTaken; i++) {
      lessons.push(this.createLessonFormGroup(null, instructor, null, saleDate));
    }

    // Update the form group with the new Payment and Lessons
    enrollmentFormGroup.patchValue({
      payments,
      lessons
    });
  }

  createLessonFormGroup(id = '', instructor = '', attendeeId = '', field_date_and_time = ''): FormGroup {
    return this.fb.group({
      id: [id],
      attendeeId: [attendeeId],
      field_date_and_time: [field_date_and_time || moment().format('YYYY-MM-DD[T]12:00:00')],
      field_instructor: [instructor, Validators.required], // Add Validators.required
    });
  }

  createPaymentFormGroup(fieldDateAndTime: string, grossTuition: number, paymentId: string = ''): FormGroup {
    return this.fb.group({
      id: [paymentId],
      field_date_and_time: [fieldDateAndTime, Validators.required],
      field_gross_tuition: [grossTuition, Validators.required],
      field_tax_collected: [0, Validators.required],
      field_student_name: [''],
      field_enrollment_name: [''],
      field_payment_type: ['1414', Validators.required] // Default to 1414
    });
  }

  addSaleDateListener(enrollmentFormGroup: FormGroup, studentAccountIndex: number, enrollmentIndex: number) {
    const saleDateControl = enrollmentFormGroup.get('field_sale_date');
    if (saleDateControl) {
      saleDateControl.valueChanges.subscribe(newSaleDateValue => {
        const paymentsArray = enrollmentFormGroup.get('payments') as FormArray;
        if (paymentsArray.length > 0) {
          const paymentFormGroup = paymentsArray.at(0) as FormGroup;
          paymentFormGroup.patchValue({ field_date_and_time: newSaleDateValue });
        }
        console.log(`Updated payment date for studentAccount[${studentAccountIndex}].enrollments[${enrollmentIndex}] to ${newSaleDateValue}`);
      });
    }
  }

  deleteEnrollment(studentAccountIndex: number, enrollmentIndex: number) {
    const enrollments = this.studentAccountsArray.at(studentAccountIndex).get('enrollments') as FormArray;
    const enrollment = enrollments.at(enrollmentIndex) as FormGroup;
    const enrollmentId = enrollment.get('id').value;

    if (enrollmentId) {
      const loadingKey = `${studentAccountIndex}_${enrollmentIndex}`;
      this.loadingEnrollments[loadingKey] = true;
      this._entityRESTService.deleteEntity('packages', 'enrollment', enrollmentId).subscribe(() => {
        enrollments.removeAt(enrollmentIndex);
        delete this.loadingEnrollments[loadingKey];
        this.snackBar.open('Enrollment deleted successfully', 'Close', { duration: 3000 });
      }, (error) => {
        console.error('Error deleting enrollment', error);
        delete this.loadingEnrollments[loadingKey];
        this.snackBar.open('Error deleting enrollment', 'Close', { duration: 3000 });
      });
    } else {
      enrollments.removeAt(enrollmentIndex);
    }
  }


  deleteStudentAccount(index: number) {
    const studentAccount = this.studentAccountsArray.at(index) as FormGroup;
    const studentAccountId = studentAccount.get('id').value;

    if (studentAccountId) {
      this.loadingStudentAccounts[index] = true;
      this._entityRESTService.deleteEntity('student_accounts', 'student_account', studentAccountId).subscribe(() => {
        this.studentAccountsArray.removeAt(index);
        delete this.loadingStudentAccounts[index];
        this.snackBar.open('Student account deleted successfully', 'Close', { duration: 3000 });
      }, (error) => {
        console.error('Error deleting student account', error);
        delete this.loadingStudentAccounts[index];
        this.snackBar.open('Error deleting student account', 'Close', { duration: 3000 });
      });
    } else {
      this.studentAccountsArray.removeAt(index);
    }
  }

  onSubmit() {
    this.isSubmitting = true;
    this.studentAccountsForm.markAsPristine();
    this.resetErrorMessagesAndSuccessStates();
    this.resetSubmissionProgress();

    if (this.studentAccountsForm.valid) {
      console.log("Form is valid", this.studentAccountsForm.value);
      const studentAccounts = this.studentAccountsForm.get('studentAccounts').value;

      this.calculateTotalOperations(studentAccounts);

      const submissions = studentAccounts.map((studentAccount, index) => {
        return new Promise((resolve, reject) => {
          const transformedAccount = this.transformStudentAccount(studentAccount);
          const studentAccountId = studentAccount.id;

          const handleResponse = (response) => {
            const studentId = response.id[0].value;
            this.studentSuccessStates[index] = true;
            this.studentAccountsArray.at(index).patchValue({ id: studentId });

            // Set the contact IDs if they exist in the response
            if (response.field_contacts && Array.isArray(response.field_contacts)) {
              console.log('Response field_contacts:', response.field_contacts); // Debug log
              if (response.field_contacts.length > 0 && response.field_contacts[0].target_id) {
                this.studentAccountsArray.at(index).patchValue({ contact1ID: response.field_contacts[0].target_id });
              }

              if (response.field_contacts.length > 1 && response.field_contacts[1].target_id) {
                this.studentAccountsArray.at(index).patchValue({ contact2ID: response.field_contacts[1].target_id });
              }
            } else {
              console.warn('Expected field_contacts not found in the response or has unexpected structure');
            }

            this.incrementCompletedOperations();
            this.createEnrollments(studentAccount.enrollments, studentId, response, index).then(resolve).catch(reject);
          };

          const handleError = (error) => {
            console.error('Error processing student account', error);
            this.studentErrorMessages[index] = 'Error processing student account';
            this.studentSuccessStates[index] = false;
            this.incrementCompletedOperations();
            this.snackBar.open(`Error processing student account for student #${index + 1}`, 'Close', {
              duration: 3000,
            });
            reject(error);
          };

          if (studentAccountId) {
            this._entityRESTService.patchEntity('student_accounts', 'student_account', studentAccountId, transformedAccount)
              .subscribe(handleResponse, handleError);
          } else {
            this._entityRESTService.createEntity('student_accounts', 'student_account', transformedAccount)
              .subscribe(handleResponse, handleError);
          }
        });
      });

      Promise.all(submissions).then(() => {
        this.isSubmitting = false;
      }).catch(() => {
        this.isSubmitting = false;
      });

    } else {
      let errorMessages = this.showFormErrors(this.studentAccountsForm);
      let combinedErrorMessage = errorMessages.join(', ');
      console.log('Form is not valid', this.studentAccountsForm.errors); // Debug log
      console.log('Form value:', this.studentAccountsForm.value); // Debug log
      this.snackBar.open(`Form is not valid: ${combinedErrorMessage}`, 'Close', { duration: 10000 });
      this.isSubmitting = false; // Enable the submit button
    }
  }

  createEnrollments(enrollments, studentId, studentAccountResponse, studentAccountIndex): Promise<void> {
    return new Promise((resolve, reject) => {
      const studentAccountFormGroup = this.studentAccountsArray.at(studentAccountIndex) as FormGroup;
      const enrollmentsArray = studentAccountFormGroup.get('enrollments') as FormArray;

      const enrollmentPromises = enrollments.map((enrollment, enrollmentIndex) => {
        return new Promise<void>((resolveEnrollment, rejectEnrollment) => {
          const transformedEnrollment = this.transformEnrollment(enrollment, studentId);
          const enrollmentId = enrollment?.id;

          const enrollmentObservable = enrollmentId
            ? this._entityRESTService.patchEntity('packages', 'enrollment', enrollmentId, transformedEnrollment)
            : this._entityRESTService.createEntity('packages', 'enrollment', transformedEnrollment);

          enrollmentObservable.subscribe(enrollmentResponse => {
            const enrollmentId = enrollmentResponse.id[0].value;
            this.enrollmentSuccessStates[`${studentAccountIndex}_${enrollmentIndex}`] = true;

            const enrollmentFormGroup = enrollmentsArray.at(enrollmentIndex) as FormGroup;
            enrollmentFormGroup.patchValue({ id: enrollmentId });

            this.addPaymentToEnrollment(enrollmentFormGroup);

            const paymentsArray = enrollmentFormGroup.get('payments') as FormArray;
            const paymentsObservable = from(paymentsArray.controls).pipe(
              concatMap((paymentControl, paymentIndex) => {
                const payment = paymentControl.value;
                const transformedPayment = this.transformPayment(payment, enrollmentId, studentId);
                const paymentId = payment.id;

                const processPayment = paymentId
                  ? this._entityRESTService.patchEntity('payments', 'payment', paymentId, transformedPayment)
                  : this._entityRESTService.createEntity('payments', 'payment', transformedPayment);

                return processPayment.pipe(
                  tap(paymentResponse => {
                    const responsePaymentId = paymentResponse.id[0].value;
                    const key = `${studentAccountIndex}_${enrollmentIndex}_${paymentIndex}`;
                    this.paymentSuccessStates[key] = true;

                    const paymentFormGroup = paymentsArray.at(paymentIndex) as FormGroup;
                    paymentFormGroup.patchValue({ id: responsePaymentId });

                    this.changeDetectorRef.detectChanges();

                    this.incrementCompletedOperations();
                  }),
                  catchError(error => {
                    console.error('Error processing payment', error);
                    const key = `${studentAccountIndex}_${enrollmentIndex}_${paymentIndex}`;
                    this.paymentErrorMessages[key] = 'Error processing payment';
                    this.paymentSuccessStates[key] = false;
                    this.changeDetectorRef.detectChanges();
                    this.incrementCompletedOperations();
                    return of(null);
                  })
                );
              })
            );

            const lessonsArray = enrollmentFormGroup.get('lessons') as FormArray;
            const lessonsObservable = from(lessonsArray.controls).pipe(
              concatMap((lessonControl, lessonIndex) => {
                const lesson = lessonControl.value;
                const transformedLesson = this.transformLesson(lesson, enrollmentId, studentAccountResponse);
                const lessonId = lesson.id;

                const processLesson = lessonId
                  ? this._entityRESTService.patchEntity('events', 'lesson', lessonId, transformedLesson)
                  : this._entityRESTService.createEntity('events', 'lesson', transformedLesson);

                return processLesson.pipe(
                  tap(lessonResponse => {
                    const responseLessonId = lessonResponse.id[0].value;
                    const key = `${studentAccountIndex}_${enrollmentIndex}_${lessonIndex}`;
                    this.lessonSuccessStates[key] = true;

                    const lessonFormGroup = lessonsArray.at(lessonIndex) as FormGroup;
                    lessonFormGroup.patchValue({ id: responseLessonId });

                    this.changeDetectorRef.detectChanges();
                    this.incrementCompletedOperations();
                  }),
                  catchError(error => {
                    console.error('Error processing lesson', error);
                    const key = `${studentAccountIndex}_${enrollmentIndex}_${lessonIndex}`;
                    this.lessonErrorMessages[key] = 'Error processing lesson';
                    this.lessonSuccessStates[key] = false;
                    this.changeDetectorRef.detectChanges();
                    this.incrementCompletedOperations();
                    return of(null);
                  }),
                  retryWhen(this.retryWithDelay(3, 1000)) // Add retryWithDelay here
                );
              })
            );

            paymentsObservable.subscribe({
              complete: () => {
                lessonsObservable.subscribe({
                  complete: () => {
                    console.log('All payments and lessons processed for this enrollment.');
                    resolveEnrollment();
                  },
                  error: rejectEnrollment
                });
              },
              error: rejectEnrollment
            });
          }, error => {
            console.error('Error creating enrollment', error);
            this.enrollmentErrorMessages[enrollmentIndex] = 'Error creating enrollment';
            this.enrollmentSuccessStates[enrollmentIndex] = false;
            rejectEnrollment(error);
          });
        });
      });

      Promise.all(enrollmentPromises).then(() => {
        console.log('All enrollments processed.');
        this.studentAccountSuccessStates[studentAccountIndex] = true; // Set success state here
        resolve();
      }).catch(reject);
    });
  }





  retryStrategy({ maxRetryAttempts = 3, scalingDuration = 1000 }) {
    return (attempts) => attempts.pipe(
      mergeMap((error: HttpErrorResponse, i) => {
        const retryAttempt = i + 1;
        // Assuming you want to retry for specific HTTP status codes
        const retriableStatusCodes = [500, 503, 505];
        if (retryAttempt > maxRetryAttempts || !retriableStatusCodes.includes(error.status)) {
          return throwError(() => new Error(`Retry limit reached. Last error: ${error.message}`));
        }
        console.log(`Attempt ${retryAttempt}: retrying in ${retryAttempt * scalingDuration}ms`);
        return timer(retryAttempt * scalingDuration);
      })
    );
  }

  transformEnrollment(enrollment, studentId) {
    // Calculate the discounted enrollment price
    const originalPrice = enrollment.__total_enrollment_price || 0;
    const discountType = enrollment.field_discount_type;
    const discountValue = enrollment.field_discount || 0;

    let discountedPrice = originalPrice;

    if (discountType === 'flat') {
      discountedPrice -= discountValue;
    } else if (discountType === 'percentage') {
      discountedPrice -= (originalPrice * discountValue / 100);
    }

    // Ensure the discounted price does not go below zero
    discountedPrice = Math.max(discountedPrice, 0);

    // Calculate the price per lesson based on the discounted total price
    const lessonCount = enrollment.__total_lessons_enrolled === 0 ? 1 : enrollment.__total_lessons_enrolled;
    const lessonPrice = discountedPrice / lessonCount;

    // Prepare the scheduled payments and instructor percentages
    const scheduledPayments = [{
      id: null,
      field_amount_paid: null,
      field_enrollment: null,
      field_paid_in_full: true,
      field_down_payment: true,
      field_payment_amount: discountedPrice,
      field_payment_date: moment(enrollment.field_sale_date).format('YYYY-MM-DD'),
      field_scheduled_payments: null,
      field_number_lesson_remaining: null,
      field_studio_reference: this._authService.studios?.[0]?.id,
      bundle: "scheduled_payments",
      type: "scheduled_payments"
    }];

    const instructorPercentages = [{
      id: null,
      field_amount: discountedPrice,
      field_instructor: enrollment.field_instructor,
      field_percentage: 100,
      field_studio_reference: this._authService.studios?.[0]?.id,
      bundle: "instructor_percentages",
      type: "instructor_percentages"
    }];

    // Format the expiration and sale dates
    if (enrollment.field_expiration_date) {
      enrollment.field_expiration_date = moment(enrollment.field_expiration_date).format('YYYY-MM-DD');
    }
    if (enrollment.field_sale_date) {
      enrollment.field_sale_date = moment(enrollment.field_sale_date).format('YYYY-MM-DDTHH:mm:ss');
    }

    const totalLessonsTaken = enrollment.__total_lessons_taken || 0;
    const grossTuition = totalLessonsTaken * lessonPrice;

    return {
      title: "default title",
      field_draft: false,
      field_student: studentId,
      field_sale_date: enrollment.field_sale_date,
      field_expiration_date: enrollment.field_expiration_date,
      field_category: enrollment.field_category,
      field_enrollment_package_name: enrollment.field_enrollment_package_name,
      field_enrollment_lesson_price: lessonPrice,
      field_enrollment_lesson_count: enrollment.__total_lessons_enrolled,
      field_total_lessons_price: enrollment.__total_lessons_price,
      field_enrollment_total_price: discountedPrice,
      field_total_price_with_est_tax: discountedPrice,
      field_taxes: 0,
      field_tax_percentage: enrollment.field_tax_percentage,
      field_payments_structure: "paid_in_full",
      field_payments_structure_type: null,
      field_executive: enrollment.field_executive,
      field_instructor: enrollment.field_instructor,
      field_junior_executive: null,
      field_scheduled_payments: scheduledPayments,
      field_instructor_percentages: instructorPercentages,
      field_discount: enrollment.field_discount,
      field_discount_type: enrollment.field_discount_type,
      field_enrollment_status: "Open",
      field_next_scheduled_payment: null,
      field_visibility: false,
      field_notes: enrollment.field_notes,
      field_studio_reference_target_id: this._authService.studios?.[0]?.id,
      field_studio_reference: this._authService.studios?.[0]?.id,
      payments: [{
        field_date_and_time: enrollment.field_sale_date,
        field_gross_tuition: grossTuition,
        field_tax_collected: 0,
        field_payment_type: '1414'
      }]
    };
  }

  transformLesson(lesson, enrollmentId, studentAccountResponse, attendeeId = '') {
    // Extracting student contact IDs from the student account response
    const studentContactIds = studentAccountResponse.field_contacts.map(contact => contact.target_id);

    // Get the enrollment from the form
    const enrollment = this.findEnrollmentById(enrollmentId);

    if (!enrollment) {
      console.error('Enrollment not found for id:', enrollmentId);
      return null;
    }

    // Get the package ID from the enrollment
    const packageId = enrollment.get('field_enrollment_package_name').value;

    // Find the selected package
    const selectedPackage = this.packages.find(pkg => pkg.id === packageId);

    if (!selectedPackage) {
      console.error('Package not found for id:', packageId);
      return null;
    }

    console.log('Transforming lesson:', lesson);
    console.log('Selected package:', selectedPackage);
    console.log('Package lesson type:', selectedPackage.field_lesson_type);

    // Define the mapping from field_lesson_type to field_type
    const lessonTypeMapping = {
      'Front Department Lesson (FD)': '548',
      'Bonus/Comp Lesson': '555',
      'Dance Evaluation (DE)': '560',
      'Back Department Lesson (BD)': '600',
      'Transfer In': '642',
      'Transfer Out': '643',
      'Pre Original': '1393',
      'Non Unit Group': '1410',
      'Non Unit Private': '1411'
    };

    // Convert lesson type name to ID
    let fieldType = '548'; // Default to Front Department Lesson (FD) if not found
    if (selectedPackage.field_lesson_type && lessonTypeMapping[selectedPackage.field_lesson_type]) {
      fieldType = lessonTypeMapping[selectedPackage.field_lesson_type];
    } else {
      console.warn(`Lesson type "${selectedPackage.field_lesson_type}" not found in mapping. Using default type.`);
    }

    console.log('Converted field_type:', fieldType);

    return {
      id: lesson.id || '',
      title: "Default Title",
      field_type: fieldType,
      field_importer: "1",
      field_instructor: lesson.field_instructor || '',
      field_duration: "00:45",
      field_date_and_time: moment(lesson.field_date_and_time).format('YYYY-MM-DDTHH:mm:ss'),
      field_student: [{
        id: attendeeId,
        type: "attendees",
        bundle: "attendance",
        field_student_account: studentAccountResponse.id[0].value,
        field_students: studentContactIds,
        field_enrollment: enrollmentId.toString(),
        field_description: "",
        field_status: "59",
        field_studio_reference_target_id: this._authService.studios?.[0]?.id,
        field_studio_reference: this._authService.studios?.[0]?.id
      }],
      field_instructor_alternate_list: [],
      field_use_alternating_instructor: false,
      field_repetition_units: "",
      field_repetition_frequency: null,
      field_expiration_date: null,
      field_is_confirmed: false,
      field_status: "59",
      field_24_hour_notification_sent: "1",
      field_exclude_from_display: "1",
      field_studio_reference_target_id: this._authService.studios?.[0]?.id,
      field_studio_reference: this._authService.studios?.[0]?.id
    };
  }

  transformPayment(payment, enrollmentId, studentId) {
    return {
      title: "Default Title",
      field_payment_id: payment.id || '', // Assuming 'id' is a property in your payment object
      field_date_and_time: moment(payment.field_date_and_time).format('YYYY-MM-DDTHH:mm:ss'),
      field_gross_tuition: payment.field_gross_tuition.toString(),
      field_tax_collected: payment.field_tax_collected,
      field_student_name: studentId,
      field_payment_type: payment.field_payment_type || '1414', // Default value as Cash
      field_enrollment_name: [{ target_id: enrollmentId }],
      enrollment_ids: [{ target_id: enrollmentId }],
      idempotency_key: "payment_" + new Date().getTime(),
      field_studio_reference_target_id: this._authService.studios?.[0]?.id,
      field_studio_reference: this._authService.studios?.[0]?.id,
    };
  }

  transformStudentAccount(studentAccount) {
    let attendingLessonsType = studentAccount.contact2FirstName || studentAccount.contact2LastName || studentAccount.contact2Email || studentAccount.contact2Phone ? 'partner' : 'solo';

    let transformedAccount = {
      field_contacts: {
        "0": {
          type: "contacts",
          bundle: "student_record",
          id: studentAccount.contact1ID || '',
          field_first_name: studentAccount.contact1FirstName,
          field_last_name: studentAccount.contact1LastName,
          field_cell_phone: studentAccount.contact1Phone,
          field_email: studentAccount.contact1Email,
          field_attending_lessons: attendingLessonsType,
          field_gender: studentAccount.contact1Gender === 'male' ? "1" : (studentAccount.contact1Gender === 'female' ? "0" : "1"),
          field_address: {
            multiple_properties: null,
            address_line1: null,
            locality: null,
            administrative_area: null,
            postal_code: null,
            country_code: null
          },
          field_studio_reference: this._authService.studios?.[0]?.id,
        }
      },
      field_archive: "0",
      title: "Default Title"
    };

    // Check if Contact 2 data is provided and add it
    if (studentAccount.contact2FirstName || studentAccount.contact2LastName || studentAccount.contact2Email || studentAccount.contact2Phone) {
      transformedAccount.field_contacts["1"] = {
        type: "contacts",
        bundle: "student_record",
        id: studentAccount.contact2ID || '',
        field_first_name: studentAccount.contact2FirstName,
        field_last_name: studentAccount.contact2LastName,
        field_cell_phone: studentAccount.contact2Phone,
        field_email: studentAccount.contact2Email,
        field_attending_lessons: attendingLessonsType,
        field_gender: studentAccount.contact2Gender === 'male' ? "1" : (studentAccount.contact2Gender === 'female' ? "0" : "1"),
        field_address: {
          multiple_properties: null,
          address_line1: null,
          locality: null,
          administrative_area: null,
          postal_code: null,
          country_code: null
        },
        field_studio_reference: this._authService.studios?.[0]?.id,
      };
    }

    // Set field_inquired to 1999.
    transformedAccount['field_inquired'] = '1999-01-01';

    // Add fields from the first enrollment if it exists
    if (studentAccount.enrollments && studentAccount.enrollments.length > 0) {
      const firstEnrollment = studentAccount.enrollments[0];
      transformedAccount['field_executive'] = firstEnrollment.field_executive;
      transformedAccount['field_inquiry_taker'] = firstEnrollment.field_executive; // Set inquiry taker to the same as executive
      transformedAccount['field_teacher'] = firstEnrollment.field_instructor;
    }

    return transformedAccount;
  }

  createEnrollmentFormGroup(): FormGroup {
    return this.fb.group({
      id: [''],
      field_student: [''],
      field_category: ['', Validators.required],
      field_sale_date: ['', Validators.required],
      __field_packages: [''],
      field_enrollment_package_name: [''],
      field_enrollment_lesson_price: ['', Validators.required],
      field_enrollment_lesson_count: [0, Validators.required],
      field_enrollment_total_price: [0, Validators.required],
      field_discount_type: [''],
      field_discount: [0, Validators.required],
      field_tax_percentage: [0],
      field_expiration_date: ['', Validators.required],
      field_executive: ['', Validators.required],
      field_instructor: ['', Validators.required],
      field_notes: [''],
      lessons: this.fb.array([]),
      payments: this.fb.array([]),
      __total_enrollment_price: [0],
      __total_lessons_enrolled: [0],
      __total_lessons_taken: [0],
      __total_lessons_available: [0],
      field_tax_collected: [0]
    }, { validators: this.totalLessonsValidator() });
  }

  subscribeToDiscountChanges() {
    this.studentAccountsArray.controls.forEach((studentAccountFormGroup: FormGroup) => {
      const enrollments = studentAccountFormGroup.get('enrollments') as FormArray;
      enrollments.controls.forEach((enrollmentFormGroup: FormGroup) => {
        this.setupDiscountListeners(enrollmentFormGroup);
      });
    });
  }

  setupDiscountListeners(enrollmentFormGroup: FormGroup) {
    const discountTypeControl = enrollmentFormGroup.get('field_discount_type');
    const discountControl = enrollmentFormGroup.get('field_discount');

    if (discountControl) {
      discountControl.valueChanges.subscribe(() => {
        this.recalculateValues(enrollmentFormGroup);
      });
    }

    if (discountTypeControl) {
      discountTypeControl.valueChanges.subscribe(() => {
        this.recalculateValues(enrollmentFormGroup);
      });
    }
  }

  recalculateValues(enrollmentFormGroup: FormGroup) {
    const discountType = enrollmentFormGroup.get('field_discount_type').value;
    const discountValue = enrollmentFormGroup.get('field_discount').value;
    let lessonPrice = parseFloat(enrollmentFormGroup.get('field_enrollment_lesson_price').value);
    let lessonCount = parseInt(enrollmentFormGroup.get('field_enrollment_lesson_count').value, 10);
    let totalLessonsAvailable = parseInt(enrollmentFormGroup.get('__total_lessons_available').value, 10); // New field

    // Ensure lessonPrice is a number
    lessonPrice = isNaN(lessonPrice) ? 0 : lessonPrice;

    // Treat lessonCount as 1 if it is 0 for calculating
    lessonCount = lessonCount === 0 ? 1 : lessonCount;

    // Calculate total original price
    let totalOriginalPrice = lessonPrice * lessonCount;

    // Adjust totalOriginalPrice based on discount type
    let discountedPrice = totalOriginalPrice;
    if (discountType === 'flat') {
      discountedPrice -= this.parseDiscountValue(discountValue);
    } else if (discountType === 'percentage') {
      const discountPercentage = this.parseDiscountValue(discountValue);
      discountedPrice -= (totalOriginalPrice * discountPercentage / 100);
    }

    // Ensure the discounted price doesn't go below zero
    discountedPrice = Math.max(discountedPrice, 0);

    // Update the form field with the new discounted price
    enrollmentFormGroup.patchValue({ field_enrollment_total_price: discountedPrice });

    // Calculate payment based on total lessons available
    const paymentAmount = lessonPrice * totalLessonsAvailable;
    enrollmentFormGroup.patchValue({ field_enrollment_total_price: paymentAmount });
  }



  parseDiscountValue(discountValue) {
    if (typeof discountValue === 'string' && discountValue.includes('%')) {
      // Remove the '%' sign and parse as integer.
      return parseInt(discountValue.replace('%', ''), 10);
    } else {
      // Parse the value as integer.
      return parseInt(discountValue, 10);
    }
  }

  setupAutofillListeners() {
    this.studentAccountsArray.controls.forEach((studentAccount, studentAccountIndex) => {
      const enrollmentsArray = studentAccount.get('enrollments') as FormArray;
      enrollmentsArray.controls.forEach((enrollment, enrollmentIndex) => {
        this.setupAutofillListenersForEnrollment(enrollment as FormGroup, studentAccountIndex, enrollmentIndex);
      });
    });
  }

  setupAutofillListenersForEnrollment(enrollmentFormGroup: FormGroup, studentAccountIndex: number, enrollmentIndex: number) {
    const totalEnrollmentPriceField = enrollmentFormGroup.get('__total_enrollment_price');
    const totalLessonsEnrolledField = enrollmentFormGroup.get('__total_lessons_enrolled');
    const totalLessonsTakenField = enrollmentFormGroup.get('__total_lessons_taken');
    const totalLessonsAvailableField = enrollmentFormGroup.get('__total_lessons_available');
    const saleDateField = enrollmentFormGroup.get('field_sale_date');

    totalEnrollmentPriceField.valueChanges.subscribe(() => {
      this.autofillFields(studentAccountIndex, enrollmentIndex);
    });
    totalLessonsEnrolledField.valueChanges.subscribe(() => {
      this.autofillFields(studentAccountIndex, enrollmentIndex);
    });
    totalLessonsTakenField.valueChanges.subscribe(() => {
      this.autofillFields(studentAccountIndex, enrollmentIndex);
    });
    totalLessonsAvailableField.valueChanges.subscribe(() => {
      this.autofillFields(studentAccountIndex, enrollmentIndex);
    });
    saleDateField.valueChanges.subscribe(() => {
      this.updateLessonDates(enrollmentFormGroup);
    });

    // Initialize values for the new enrollment
    this.autofillFields(studentAccountIndex, enrollmentIndex);
  }

  autofillFields(studentAccountIndex: number, enrollmentIndex: number) {
    const enrollmentFormGroup = this.getEnrollmentsFormGroup(this.studentAccountsArray.at(studentAccountIndex))?.at(enrollmentIndex) as FormGroup;

    if (!enrollmentFormGroup) {
      console.error('Enrollment form group not found');
      return;
    }

    const enrollmentTotalPrice = enrollmentFormGroup.get('__total_enrollment_price')?.value || 0;
    const totalLessonsEnrolled = enrollmentFormGroup.get('__total_lessons_enrolled')?.value || 0;
    const totalLessonsTaken = enrollmentFormGroup.get('__total_lessons_taken')?.value || 0;
    const totalLessonsAvailable = enrollmentFormGroup.get('__total_lessons_available')?.value || 0;

    const lessonPrice = this.calculateDiscountedPricePerLesson(enrollmentFormGroup);

    enrollmentFormGroup.patchValue({
      field_enrollment_lesson_price: lessonPrice,
      field_enrollment_lesson_count: totalLessonsEnrolled,
      field_enrollment_total_price: this.calculateDiscountedEnrollmentPrice(enrollmentFormGroup),
    });

    this.updateLessons(enrollmentFormGroup, totalLessonsTaken)
      .pipe(
        switchMap(() => {
          this.recalculateValues(enrollmentFormGroup);
          this.updateLessonDates(enrollmentFormGroup);
          return of(null);
        }),
        catchError((error) => {
          console.error('Error in autofillFields:', error);
          return of(null);
        })
      )
      .subscribe(() => {
        enrollmentFormGroup.updateValueAndValidity();
      });
  }

  updateLessons(enrollmentFormGroup: FormGroup, newTotalLessonsTaken: number): Observable<any> {
    const lessonsArray = enrollmentFormGroup.get('lessons') as FormArray;
    if (!lessonsArray) {
      console.error('Lessons array not found in form group');
      return of(null);
    }

    const currentLessonsCount = lessonsArray.length;
    const instructor = enrollmentFormGroup.get('field_instructor')?.value;

    if (currentLessonsCount > newTotalLessonsTaken) {
      // Delete extra lessons
      return this.deleteLessons(enrollmentFormGroup, currentLessonsCount - newTotalLessonsTaken);
    } else if (currentLessonsCount < newTotalLessonsTaken) {
      // Add new lessons
      for (let i = currentLessonsCount; i < newTotalLessonsTaken; i++) {
        lessonsArray.push(this.createLessonFormGroup(null, instructor));
      }
    }

    // Update all lessons' instructors
    lessonsArray.controls.forEach((lessonFormGroup: FormGroup) => {
      lessonFormGroup.patchValue({ field_instructor: instructor });
    });

    return of(null);
  }

  deleteLessons(enrollmentFormGroup: FormGroup, numberToDelete: number): Observable<any> {
    const lessonsArray = enrollmentFormGroup.get('lessons') as FormArray;
    if (!lessonsArray) {
      console.error('Lessons array not found in form group');
      return of(null);
    }

    const deleteObservables: Observable<any>[] = [];

    for (let i = lessonsArray.length - 1; i >= Math.max(0, lessonsArray.length - numberToDelete); i--) {
      const lessonFormGroup = lessonsArray.at(i) as FormGroup;
      if (lessonFormGroup) {
        const lessonId = lessonFormGroup.get('id')?.value;

        if (lessonId) {
          deleteObservables.push(
            this._entityRESTService.deleteEntity('events', 'lesson', lessonId).pipe(
              catchError(error => {
                console.error(`Error deleting lesson with ID ${lessonId}:`, error);
                return of(null);
              })
            )
          );
        }

        lessonsArray.removeAt(i);
      }
    }

    return deleteObservables.length > 0 ? forkJoin(deleteObservables) : of(null);
  }

  updateLessonDates(enrollmentFormGroup: FormGroup) {
    const saleDate = enrollmentFormGroup.get('field_sale_date')?.value;
    const lessonsArray = enrollmentFormGroup.get('lessons') as FormArray;

    if (saleDate && lessonsArray) {
      const enrollmentDateTime = moment(saleDate);
      const lessonDateTime = enrollmentDateTime.clone().add(1, 'minute');

      lessonsArray.controls.forEach((lessonFormGroup: FormGroup) => {
        lessonFormGroup.patchValue({
          field_date_and_time: lessonDateTime.format('YYYY-MM-DD[T]HH:mm:ss')
        });
      });
    }
  }

  calculateDiscountedEnrollmentPrice(enrollment: FormGroup): number {
    const originalPrice = enrollment.get('__total_enrollment_price').value || 0;
    const discountType = enrollment.get('field_discount_type').value;
    const discountValue = enrollment.get('field_discount').value || 0;

    let discountedPrice = originalPrice;

    if (discountType === 'flat') {
      discountedPrice -= discountValue;
    } else if (discountType === 'percentage') {
      discountedPrice -= (originalPrice * discountValue / 100);
    }

    return discountedPrice;
  }



  updateStudentIdInForm(studentAccountIndex: number, studentId: string) {
    const studentAccountFormGroup = this.studentAccountsArray.at(studentAccountIndex) as FormGroup;
    const enrollmentsArray = studentAccountFormGroup.get('enrollments') as FormArray;

    enrollmentsArray.controls.forEach(enrollmentFormGroup => {
      enrollmentFormGroup.patchValue({
        field_student: studentId
      });

      const paymentsArray = enrollmentFormGroup.get('payments') as FormArray;
      paymentsArray.controls.forEach(paymentFormGroup => {
        paymentFormGroup.patchValue({
          field_student_name: studentId
        });
      });
    });
  }

  detectAndSetGender(account: FormGroup, genderControlName: string) {
    let firstName, lastName;

    if (genderControlName === 'contact1Gender') {
      firstName = account.get('contact1FirstName').value;
      lastName = account.get('contact1LastName').value;
    } else if (genderControlName === 'contact2Gender') {
      firstName = account.get('contact2FirstName').value;
      lastName = account.get('contact2LastName').value;
    }

    if (firstName && lastName) {
      this._genderDetectionService.getGender(firstName).subscribe(response => {
        account.get(genderControlName).setValue(response.gender);
      });
    }
  }

  setupAccountGenderDetection(account: FormGroup, index: number) {
    // Existing logic for contact1
    const firstNameControl1 = account.get('contact1FirstName');
    const lastNameControl1 = account.get('contact1LastName');
    this.setupGenderSubscription(firstNameControl1, lastNameControl1, account, 'contact1Gender', index);

    // Add logic for contact2
    const firstNameControl2 = account.get('contact2FirstName');
    const lastNameControl2 = account.get('contact2LastName');
    this.setupGenderSubscription(firstNameControl2, lastNameControl2, account, 'contact2Gender', index);
  }

  setupGenderSubscription(firstNameControl, lastNameControl, account, genderControlName, index) {
    if (firstNameControl && lastNameControl) {
      this.unsubscribeFromGenderDetection(index);

      this.firstNameSubscriptions[index] = firstNameControl.valueChanges.subscribe(() => {
        this.detectAndSetGender(account, genderControlName);
      });

      this.lastNameSubscriptions[index] = lastNameControl.valueChanges.subscribe(() => {
        this.detectAndSetGender(account, genderControlName);
      });
    }
  }


  setupGenderDetection() {
    this.studentAccountsArray.controls.forEach((account, index) => {
      this.setupAccountGenderDetection(account as FormGroup, index);
    });

    // Detect changes in the form array and setup gender detection for new form groups
    this.studentAccountsArray.valueChanges.subscribe(changes => {
      const lastIndex = this.studentAccountsArray.length - 1;
      const lastFormGroup = this.studentAccountsArray.at(lastIndex) as FormGroup;
      this.setupAccountGenderDetection(lastFormGroup, lastIndex);
    });
  }

  private unsubscribeFromGenderDetection(index: number) {
    if (this.firstNameSubscriptions[index]) {
      this.firstNameSubscriptions[index].unsubscribe();
    }
    if (this.lastNameSubscriptions[index]) {
      this.lastNameSubscriptions[index].unsubscribe();
    }
  }

  showFormErrors(formGroup: FormGroup, formPath = ''): string[] {
    let errorMessages = [];

    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      const currentPath = formPath ? `${formPath}.${field}` : field;

      if (control instanceof FormGroup) {
        errorMessages = [...errorMessages, ...this.showFormErrors(control, currentPath)];
      } else if (control instanceof FormArray) {
        control.controls.forEach((group, groupIndex) => {
          if (group instanceof FormGroup) {
            errorMessages = [...errorMessages, ...this.showFormErrors(group, `${currentPath}[${groupIndex}]`)];
          }
        });
      } else if (control.errors) {
        Object.keys(control.errors).forEach(keyError => {
          const errorMessage = `Error in '${currentPath}': ${keyError}`;
          errorMessages.push(errorMessage);
          console.log('Key control: ' + currentPath + ', keyError: ' + keyError + ', err value: ', control.errors[keyError]);
        });
      }
    });

    return errorMessages;
  }

  private mapping = {
    'DE': { optionText: 'DE', valueAttribute: 1395 },
    'EXT': { optionText: 'Extension', valueAttribute: 51 },
    'MISC': { optionText: 'Miscellaneous', valueAttribute: 5 },
    'Non Unit': { optionText: 'Non-Unit', valueAttribute: 66 },
    'ORI': { optionText: 'Original', valueAttribute: 50 },
    'PORI': { optionText: 'Pre-Original', valueAttribute: 49 },
    'REN': { optionText: 'Renewals', valueAttribute: 65 },
    'SUNDRY': { optionText: 'Sundry', valueAttribute: 67 }
  };

  mapPackageValue(input: string): { optionText: string, valueAttribute: number | null } {
    return this.mapping[input] || { optionText: 'Uncategorized', valueAttribute: 70 };
  }

  parsePrice(value: string): number {
    // Remove the $ symbol and parse the number
    const parsedValue = value.replace(/^\$/, '');
    return parseFloat(parsedValue);
  }

  removePayment(studentAccountIndex: number, enrollmentIndex: number, paymentIndex: number) {
    const enrollments = this.studentAccountsArray.at(studentAccountIndex).get('enrollments') as FormArray;
    const enrollment = enrollments.at(enrollmentIndex) as FormGroup;
    const payments = enrollment.get('payments') as FormArray;
    payments.removeAt(paymentIndex);
  }

  removeLesson(studentAccountIndex: number, enrollmentIndex: number, lessonIndex: number) {
    const enrollments = this.studentAccountsArray.at(studentAccountIndex).get('enrollments') as FormArray;
    const enrollment = enrollments.at(enrollmentIndex) as FormGroup;
    const lessons = enrollment.get('lessons') as FormArray;

    // Remove the specified lesson
    lessons.removeAt(lessonIndex);

    // Recalculate the total lessons taken
    const totalLessonsTaken = lessons.length;
    enrollment.patchValue({ __total_lessons_taken: totalLessonsTaken });
  }

  resetErrorMessagesAndSuccessStates() {
    this.studentErrorMessages = {};
    this.studentSuccessStates = {};
    this.enrollmentErrorMessages = {};
    this.enrollmentSuccessStates = {};
    this.paymentErrorMessages = {};
    this.paymentSuccessStates = {};
    this.lessonSuccessStates = {};
    this.lessonErrorMessages = {};
  }

  setupInstructorChangeListeners() {
    const studentAccountsArray = this.studentAccountsArray as FormArray;

    studentAccountsArray.controls.forEach((studentAccountFormGroup: FormGroup) => {
      const enrollments = studentAccountFormGroup.get('enrollments') as FormArray;

      enrollments.controls.forEach((enrollmentFormGroup: FormGroup) => {
        this.subscribeToInstructorChanges(enrollmentFormGroup);
        this.subscribeToEnrollmentDateChanges(enrollmentFormGroup);
      });
    });
  }

  // Method to subscribe to instructor changes for an enrollment
  subscribeToEnrollmentDateChanges(enrollmentFormGroup: FormGroup) {
    const saleDateControl = enrollmentFormGroup.get('field_sale_date');
    if (saleDateControl) {
      saleDateControl.valueChanges.subscribe(newSaleDateValue => {
        this.updateLessonsDates(enrollmentFormGroup, newSaleDateValue);
      });
    }
  }

  // Method to subscribe to instructor changes for an enrollment
  subscribeToInstructorChanges(enrollmentFormGroup: FormGroup) {
    const instructorControl = enrollmentFormGroup.get('field_instructor');

    if (instructorControl) {
      instructorControl.valueChanges.subscribe(newInstructorValue => {
        this.updateLessonsInstructors(enrollmentFormGroup, newInstructorValue);
      });
    }
  }

  // Method to update all lessons' instructors within an enrollment
  updateLessonsDates(enrollmentFormGroup: FormGroup, newSaleDateValue: string) {
    const lessons = enrollmentFormGroup.get('lessons') as FormArray;
    console.log('Updating lesson dates');

    lessons.controls.forEach((lessonFormGroup: FormGroup) => {
      lessonFormGroup.get('field_date_and_time').setValue(moment(newSaleDateValue).format('YYYY-MM-DD[T]12:00:00'), { emitEvent: false });
    });
  }

  // Method to update all lessons' instructors within an enrollment
  updateLessonsInstructors(enrollmentFormGroup: FormGroup, newInstructorValue: string) {
    const lessonsArray = enrollmentFormGroup.get('lessons') as FormArray;

    lessonsArray.controls.forEach((lessonFormGroup: FormGroup) => {
      lessonFormGroup.patchValue({ field_instructor: newInstructorValue }, { emitEvent: false });
    });
  }

  generateKey(i: number, j: number, lessonIndex: number): string {
    return `${i}_${j}_${lessonIndex}`;
  }

  updateLessonSuccessState(enrollmentIndex: number, lessonIndex: number, state: boolean) {
    const key = `${enrollmentIndex}_${lessonIndex}`;
    this.lessonSuccessStates = {
      ...this.lessonSuccessStates,
      [key]: state
    };
    this.changeDetectorRef.detectChanges(); // Trigger change detection
  }

  calculateOriginalEnrollmentPrice(enrollment: FormGroup): number {
    return enrollment.get('__total_enrollment_price').value || 0;
  }

  calculateOriginalPricePerLesson(enrollment: FormGroup): number {
    const originalEnrollmentPrice = this.calculateOriginalEnrollmentPrice(enrollment);
    const lessonCount = enrollment.get('field_enrollment_lesson_count').value;
    return originalEnrollmentPrice / lessonCount;
  }

  autofillLessonDates(enrollmentFormGroup: FormGroup) {
    console.log('Autofilling lesson dates')
    const saleDateControl = enrollmentFormGroup.get('field_sale_date');
    const lessonsArray = enrollmentFormGroup.get('lessons') as FormArray;

    if (saleDateControl && lessonsArray) {
      const saleDate = moment(saleDateControl.value);

      lessonsArray.controls.forEach((lessonFormGroup: FormGroup) => {
        lessonFormGroup.get('field_date_and_time').setValue(saleDate.format('YYYY-MM-DD[T]12:00:00'));
      });
    }
  }

  generatePaymentKey(studentAccountIndex: number, enrollmentIndex: number, paymentIndex: number): string {
    return `${studentAccountIndex}_${enrollmentIndex}_${paymentIndex}`;
  }

  calculateGrossTuition(enrollmentFormGroup: FormGroup): number {
    const totalEnrollmentPrice = enrollmentFormGroup.get('__total_enrollment_price').value || 0;
    const totalLessonsTaken = enrollmentFormGroup.get('__total_lessons_taken').value || 0;

    // Ensure the totalLessonsTaken is at least 1 to avoid division by zero
    const lessonsCount = totalLessonsTaken > 0 ? totalLessonsTaken : 1;

    // Calculate the gross tuition
    return totalEnrollmentPrice / lessonsCount;
  }

  initializeEnrollmentValues(enrollmentFormGroup: FormGroup) {
    const totalEnrollmentPriceField = enrollmentFormGroup.get('__total_enrollment_price');
    const totalLessonsEnrolledField = enrollmentFormGroup.get('__total_lessons_enrolled');
    const totalLessonsTakenField = enrollmentFormGroup.get('__total_lessons_taken');

    totalEnrollmentPriceField.setValue(0);
    totalLessonsEnrolledField.setValue(0);
    totalLessonsTakenField.setValue(1); // Initialize with at least one lesson
  }

  calculateDiscountedPricePerLesson(enrollment: FormGroup): number {
    const discountedEnrollmentPrice = this.calculateDiscountedEnrollmentPrice(enrollment);
    const totalLessonsEnrolled = enrollment.get('__total_lessons_enrolled').value;
    const lessonCount = totalLessonsEnrolled === 0 ? 1 : totalLessonsEnrolled;
    return discountedEnrollmentPrice / lessonCount;
  }

  calculateTotalPaid(enrollment: FormGroup): number {
    const totalLessonsAvailable = enrollment.get('__total_lessons_available').value || 0;
    const lessonPrice = this.calculateDiscountedPricePerLesson(enrollment);
    return totalLessonsAvailable * lessonPrice;
  }

  totalLessonsValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const totalLessonsEnrolled = control.get('__total_lessons_enrolled')?.value;
      const totalLessonsTaken = control.get('__total_lessons_taken')?.value;
      const totalLessonsAvailable = control.get('__total_lessons_available')?.value;

      const errors: ValidationErrors = {};

      if (totalLessonsTaken > totalLessonsEnrolled) {
        errors['totalLessonsTakenInvalid'] = true;
      }

      if (totalLessonsAvailable < totalLessonsTaken || totalLessonsAvailable > totalLessonsEnrolled) {
        errors['totalLessonsAvailableInvalid'] = true;
      }

      return Object.keys(errors).length ? errors : null;
    };
  }

  retryWithDelay(maxRetryAttempts: number, scalingDuration: number) {
    return (attempts: Observable<any>) => attempts.pipe(
      mergeMap((error, i) => {
        const retryAttempt = i + 1;
        if (retryAttempt > maxRetryAttempts || error.status !== 500) {
          return throwError(error);
        }
        console.log(`Attempt ${retryAttempt}: retrying in ${retryAttempt * scalingDuration}ms`);
        return timer(retryAttempt * scalingDuration);
      })
    );
  }

  calculateTotalOperations(studentAccounts) {
    this.totalOperations = studentAccounts.reduce((total, account) => {
      // Count student account
      total += 1;
      // Count enrollments
      total += account.enrollments.length;
      // Count payments and lessons
      account.enrollments.forEach(enrollment => {
        total += enrollment.payments.length;
        total += enrollment.lessons.length;
      });
      return total;
    }, 0);
  }

  incrementCompletedOperations() {
    this.completedOperations++;
    this.submissionProgress = (this.completedOperations / this.totalOperations) * 100;
  }

  resetSubmissionProgress() {
    this.totalOperations = 0;
    this.completedOperations = 0;
    this.submissionProgress = 0;
  }

  findEnrollmentById(enrollmentId: string): FormGroup | null {
    for (let i = 0; i < this.studentAccountsArray.length; i++) {
      const studentAccount = this.studentAccountsArray.at(i) as FormGroup;
      const enrollments = studentAccount.get('enrollments') as FormArray;
      for (let j = 0; j < enrollments.length; j++) {
        const enrollment = enrollments.at(j) as FormGroup;
        if (enrollment.get('id').value === enrollmentId) {
          return enrollment;
        }
      }
    }
    return null;
  }

  clearStudentForm() {
    if (confirm('Are you sure you want to clear the form? All unsaved data will be lost.')) {
      // Reset the form to its initial state
      this.studentAccountsForm.reset();

      // Clear the FormArray
      while (this.studentAccountsArray.length !== 0) {
        this.studentAccountsArray.removeAt(0);
      }

      // Add a new empty student account
      this.addStudentAccount();

      // Reset error messages and success states
      this.resetErrorMessagesAndSuccessStates();

      // Reset submission progress
      this.resetSubmissionProgress();

      // Reset any other component state variables as needed
      this.isSubmitting = false;

      console.log('Form cleared and reset for new student');
    }
  }

  setupTotalLessonsListeners() {
    this.studentAccountsArray.controls.forEach((studentAccount, studentAccountIndex) => {
      const enrollmentsArray = studentAccount.get('enrollments') as FormArray;
      enrollmentsArray.controls.forEach((enrollment, enrollmentIndex) => {
        this.setupEnrollmentLessonsListeners(enrollment as FormGroup, studentAccountIndex, enrollmentIndex);
      });
    });

    // Listen for changes in the studentAccountsArray
    this.studentAccountsArray.valueChanges.subscribe(() => {
      this.studentAccountsArray.controls.forEach((studentAccount, studentAccountIndex) => {
        const enrollmentsArray = studentAccount.get('enrollments') as FormArray;
        enrollmentsArray.controls.forEach((enrollment, enrollmentIndex) => {
          this.setupEnrollmentLessonsListeners(enrollment as FormGroup, studentAccountIndex, enrollmentIndex);
        });
      });
    });
  }

  setupEnrollmentLessonsListeners(enrollmentFormGroup: FormGroup, studentAccountIndex: number, enrollmentIndex: number) {
    const totalLessonsEnrolledControl = enrollmentFormGroup.get('__total_lessons_enrolled');
    const totalLessonsAvailableControl = enrollmentFormGroup.get('__total_lessons_available');

    if (totalLessonsEnrolledControl && totalLessonsAvailableControl) {
      totalLessonsEnrolledControl.valueChanges.pipe(
        distinctUntilChanged(),
        filter(() => !totalLessonsAvailableControl.dirty) // Only proceed if total lessons available hasn't been manually changed
      ).subscribe(newEnrolledValue => {
        totalLessonsAvailableControl.setValue(newEnrolledValue, { emitEvent: false });
      });

      // Mark total lessons available as dirty when it's changed manually
      totalLessonsAvailableControl.valueChanges.pipe(
        distinctUntilChanged()
      ).subscribe(() => {
        totalLessonsAvailableControl.markAsDirty();
      });
    }
  }

}
