import { Component, Input, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import moment from 'moment';
import { BaseComponent } from '../base-form/base-form.component';

@Component({
  selector: 'app-update-status',
  templateUrl: './update-status.component.html',
  styleUrls: ['./update-status.component.css']
})
export class UpdateStatusComponent extends BaseComponent {

  @Input() studentName: any;
  @Input() eventID: number;
  @Input() attendeesID: string;
  @Input() status: string;
  @Input() appointmentType: string;

  override ngAfterContentInit(): void {
    // Set default value(s) for services.
    setTimeout(() => {

      for(var i=0; i < this._taxonomyService.service_status.length; i++) {
        if(this._taxonomyService.service_status[i].name == this.status) {
          if (this.appointmentType == 'services') {
            this.f.form.patchValue({
              field_status: this._taxonomyService.service_status[i].id
            });
          }
          break;
        }
      }

      for(var i=0; i < this._taxonomyService.lesson_status.length; i++) {
        if(this._taxonomyService.lesson_status[i].name == this.status) {
          if (this.appointmentType == 'lesson') {
            this.f.form.patchValue({
              field_status: this._taxonomyService.lesson_status[i].id
            });
          }
          break;
        }
      }
    });
  }

  override onSubmit(form: NgForm) {
    console.log('form.form.value')
    console.log(form.form.value)
    let values = form.form.value;

    // Build request...
    let params: { parameter: any, value: any }[] = [];

    console.log('params');
    console.log(params);

    // Add params the endpoint is expecting.
    if (this.appointmentType == 'lesson') {
      params = [
        { parameter: 'selectedStatus[' + this.attendeesID + ']', value: values.field_status },
        { parameter: 'dateTime', value: moment().format('YYYY-MM-DD[T]HH:mm:ss') },
        { parameter: 'eventId', value: this.eventID },
      ];

      this._drupalRESTService.httpGET('/api_rest/v1/updateStatus', params)
      .subscribe(data => {
        console.log(data)
        if (data['success']) {
          this.successMessage = 'Status updated for the selected event.';

          this._dialogService.dialog.closeAll();
          this.f.form.reset();
        }
        if (data['message']) {
          this.errorMessage = data['message'];
        }
      },
      error => {
        // console.log('error', error);
        this.displayProgressSpinner(false);
        // this.errorMessage = "There was a problem posting this lesson.";
        this.errorMessage = error.error.message;
      });
    }

    if (this.appointmentType == 'services') {
      params = [
        { parameter: 'action', value: 'serviceStatusUpdate' },
        { parameter: 'eventStatus', value: values.field_status },
        { parameter: 'eventId', value: this.eventID },
      ];

      let body = {
        field_status: values.field_status,
      }
      this._entityRESTService.patchEntity('events', 'services', this.eventID, body).subscribe(data => {
        this._dialogService.dialog.closeAll();
        this.f.form.reset();
      })

    }

  }

}
