
<h2 mat-dialog-title>Add Terminal Device</h2>
<mat-dialog-content>
	<form [formGroup]="form">
		<mat-form-field>
			<input matInput placeholder="Device Name" formControlName="name">
		</mat-form-field>
	</form>
</mat-dialog-content>
<mat-dialog-actions>
	<button mat-button (click)="onCancel()">Cancel</button>
	<button mat-button [disabled]="!form.valid" (click)="onSubmit()">Add Device</button>
</mat-dialog-actions>
