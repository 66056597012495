import { Component, OnInit, AfterContentInit, ViewChild, AfterViewInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { BaseSimpleDialogComponent } from '../base-simple-dialog/base-simple-dialog.component';
import { forkJoin } from 'rxjs';

@Component({
  selector: 'app-update-group-lesson',
  templateUrl: './update-group-lesson.component.html',
  styleUrls: ['./update-group-lesson.component.css']
})
export class UpdateGroupLessonComponent extends BaseSimpleDialogComponent implements OnInit, AfterViewInit {
  @ViewChild('f') form!: NgForm;

  lessonStatuses: any[] = [];
  groupLessons: any[] = [];
  isSaving: boolean = false;
  successMessageVisible: boolean = false;
  defaultPendingStatusId: number = 64;

  ngOnInit(): void {
    this.lessonStatuses = this._taxonomyService.lesson_status;
    const allEvents = this._injectedDialogData?.['eventData'] || [];
    this.groupLessons = this.filterEventLessonType(allEvents, 'group_lesson');

    this.groupLessons.forEach(lesson => {
      lesson.studentsDashboardLinks = lesson.eventDetails.studentsDashboardLinks || [];
      lesson.studentsDashboardLinks.forEach(attendee => {
        // Set the initial status from the studentsDashboardStatus
        const statusInfo = lesson.eventDetails.studentsDashboardStatus.find((s: any) => s.attendees_id === attendee.attendees_id);
        if (statusInfo) {
          const statusObj = this.lessonStatuses.find(s => s.name.toLowerCase() === statusInfo.status.toLowerCase());
          attendee.status = statusObj ? statusObj.id : this.defaultPendingStatusId;
        } else {
          attendee.status = this.defaultPendingStatusId;
        }
      });
    });
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      const status = this._injectedDialogData?.['status'];
      const appointmentType = this._injectedDialogData?.['appointmentType'];

      if (status && appointmentType) {
        const statusList = appointmentType === 'services' ? this._taxonomyService.service_status : this._taxonomyService.lesson_status;
        const matchingStatus = statusList.find(s => s.name === status);
        if (matchingStatus) {
          this.form.form.patchValue({ field_status: matchingStatus.id });
        }
      }
    });
  }

  submitLesson(lesson: any): void {
    this.isSaving = true;
    this.errorMessage = '';
    this.successMessage = '';

    const updates = lesson.studentsDashboardLinks.map(attendee => {
      const statusValue = attendee.status;
      if (statusValue) {
        return this._entityRESTService.patchEntity('attendees', 'attendance', +attendee.attendees_id, { field_status: statusValue });
      }
      return null;
    }).filter(update => update !== null);

    forkJoin(updates).subscribe(
      () => {
        this.isSaving = false;
        this.successMessage = 'Status updated for the selected event.';
        this.successMessageVisible = true;
      },
      error => {
        this.isSaving = false;
        this.errorMessage = error?.error?.message || "There was a problem updating the status of this lesson.";
      }
    );
  }

  setAllStatus(statusId: string, lesson: any): void {
    lesson.studentsDashboardLinks.forEach(attendee => {
      attendee.status = statusId;
    });
  }

  isButtonDisabled(lesson: any): boolean {
    return !lesson.studentsDashboardLinks || lesson.studentsDashboardLinks.length === 0;
  }

  getStatusName(statusId: string): string {
    const status = this.lessonStatuses.find(s => s.id === statusId);
    return status ? status.name : 'Pending Status';
  }
}
