<div style="height:64px;" class="page-empty-header"></div>

<div class="container-fluid">
	<div class="row">
		<div style="background:#215DAB; height: calc(100vh - 64px); overflow: scroll;" class="col-2">
			<app-configuration-dashboard-menu></app-configuration-dashboard-menu>
		</div>

		<div class="col-10" style="padding-left: 2.5rem;padding-right: 2.5rem;padding-top:2.5rem;overflow:scroll;max-height: calc(100vh - 64px);">

			<div class="container-fluid">
				<h1 class="">Staff Management</h1>

				<nav mat-tab-nav-bar #tabPanel>
					<a mat-tab-link
						 *ngFor="let link of links"
						 [routerLink]="link.path"
						 [queryParams]="link.queryParams"
						 routerLinkActive #rla="routerLinkActive"
						 [active]="rla.isActive"
						 (click)="activeLink = link">
						{{link.label}}
					</a>
				</nav>

				<mat-expansion-panel *ngIf="false">
					<mat-expansion-panel-header>
						<mat-panel-title>
							Filter
						</mat-panel-title>
						<mat-panel-description>
							<app-filter-results-panel [parameters]=queryParams></app-filter-results-panel>
						</mat-panel-description>
					</mat-expansion-panel-header>
					<form (ngSubmit)="onSubmit(f)" #f=ngForm class="form">
						<div class="row">

							<!-- Student Contact Autocomplete -->
							<div class="col-2">
									<mat-form-field class="full-width-field">
											<mat-label>Student (Contact)</mat-label>
											<input
													#studentInput
													name="id"
													(input)="onAutocompleteStudentContact($event)"
													matInput
													ngModel
													[matAutocomplete]="autoCompleteStudentContact">
											<button
											(click)="redirectUserProfileID(studentInput)"
											type="button"
											matSuffix
											mat-icon-button aria-label="manage_accounts">
												<mat-icon>manage_accounts</mat-icon>
										</button>
									</mat-form-field>
									<mat-autocomplete (optionSelected)="onSubmit(f)" #autoCompleteStudentContact="matAutocomplete">
											<mat-option
													*ngFor="let option of autoCompleteStudentContactOptions"
													[value]="option.value">
													{{option.label}} ({{option.value}})
											</mat-option>
									</mat-autocomplete>
							</div>

							<div class="col-2">
								<mat-form-field class="full-width-field">
									<mat-label>Email</mat-label>
									<input
										matInput
										ngModel
										name="email"
										(blur)="onSubmit(f)"
										(selectionChange)="onSubmit(f)">
								</mat-form-field>
							</div>

							<div class="col-2">
								<mat-form-field class="full-width-field">
									<mat-label>Phone</mat-label>
									<input
										matInput
										ngModel
										(blur)="onSubmit(f)"
										name="phone"
										(selectionChange)="onSubmit(f)">
								</mat-form-field>
							</div>

							<div class="col-2">
								<mat-form-field class="full-width-field">
									<mat-label>Choose a date</mat-label>
									<input
										matInput
										ngModel
										[matDatepicker]="picker"
										name="date"
										(dateChange)="onSubmit(f)">
									<mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
									<mat-datepicker #picker></mat-datepicker>
								</mat-form-field>
							</div>

							<div class="col-2">
									<mat-form-field class="full-width-field">
											<mat-label>Select Instructor...</mat-label>
											<mat-select
											matInput
											ngModel
											name="instructor"
											(selectionChange)="onSubmit(f)">
													<mat-option>None</mat-option>
													<mat-option
															*ngFor="let element of this._fieldsService.instructorData()"
															[value]="element.uid">
															{{ element.value }}</mat-option>
											</mat-select>
									</mat-form-field>
							</div>

							<div class="col-2">
								<mat-form-field class="full-width-field">
										<mat-label>Select Department...</mat-label>
										<mat-select
											name="student_department_id"
											ngModel
											(selectionChange)="onSubmit(f)">
											<mat-option value="All">All</mat-option>
											<mat-option
											*ngFor="let department of departmentData"
											[value]="department.key">{{ department.value }}
											</mat-option>
										</mat-select>
								</mat-form-field>
							</div>

							<section class="section">
								<mat-checkbox
										name="Active"
										class="margin"
										ngModel
										(change)="onSubmit(f)">Active</mat-checkbox>

								<mat-checkbox
										name="Inactive"
										class="margin"
										ngModel
										(change)="onSubmit(f)">Inactive</mat-checkbox>

								<mat-checkbox
										name="NFA"
										class="margin"
										ngModel
										(change)="onSubmit(f)">No Future Appointments</mat-checkbox>

								<mat-checkbox
										name="paid_finished"
										class="margin"
										ngModel
										(change)="onSubmit(f)">Paid Finished</mat-checkbox>

							</section>

							<section class="section">

								<div class="float-end">
									<button (click)="resetForm()" color="primary" mat-flat-button class="me-1" mat-button>Clear
										<!-- <mat-icon (click)="resetForm()" aria-hidden="false" aria-label="Menu dropdown" class=""></mat-icon> -->
									</button>
								</div>

								</section>


							</div>


							<section class="section">
							</section>

						</form>

					</mat-expansion-panel>

					<mat-progress-bar *ngIf="ShowProgressBar" mode="indeterminate"></mat-progress-bar>

					<div class="row">
						<div class="col-md-12 mt-3">
								<div class="float-end">
										<button class="" mat-flat-button
										(click)="openStaffDialog(frmManageStaffComponent, null, 'create')">
												Add staff
										</button>
								</div>
						</div>
				</div>

				<!-- <mat-form-field appearance="standard">
					<mat-label>Filter</mat-label>
					<input matInput (keyup)="applyFilter($event)" placeholder="" #input>
				</mat-form-field> -->

				<table
					cdkDropList (cdkDropListDropped)="drop($event)"
					mat-table #displayTable [dataSource]="dataSource"
					matSort
					(matSortChange)="sortDataView($event)"
					class="mt-4">

					<!-- Student Column -->
					<!-- <ng-container matColumnDef="title">
						<th mat-header-cell *matHeaderCellDef mat-sort-header
							sortActionDescription="Sort by number">
							Student
						</th>
						<td mat-cell *matCellDef="let element">{{element.student}}</td>
					</ng-container> -->

					<!-- field_first_name Column -->
					<ng-container matColumnDef="field_first_name_value">
						<th mat-header-cell *matHeaderCellDef mat-sort-header
							sortActionDescription="Sort by field_first_name_value">
							First Name
						</th>
						<td mat-cell *matCellDef="let element">{{element.field_first_name}}</td>
					</ng-container>

					<!-- field_last_name Column -->
					<ng-container matColumnDef="field_first_name">
						<th mat-header-cell *matHeaderCellDef mat-sort-header
							sortActionDescription="Sort by field_first_name">
							Name
						</th>
						<td mat-cell *matCellDef="let element">
							{{element.field_first_name | htmlDecode}} {{element.field_last_name | htmlDecode}}
						</td>
					</ng-container>

					<!-- Email Column -->
					<ng-container matColumnDef="field_email">
						<th mat-header-cell *matHeaderCellDef mat-sort-header
							sortActionDescription="Sort by name">
							Email
						</th>
						<td mat-cell *matCellDef="let element">{{element.mail}}</td>
					</ng-container>

					<!-- Phone Column -->
					<ng-container matColumnDef="field_cell_number">
						<th mat-header-cell *matHeaderCellDef mat-sort-header
							sortActionDescription="Sort by phone">
							Phone
						</th>
						<td mat-cell *matCellDef="let element">{{element.field_cell_number | phone}}</td>
					</ng-container>

					<!-- field_status Column -->
					<ng-container matColumnDef="field_status">
						<th mat-header-cell *matHeaderCellDef mat-sort-header
							sortActionDescription="Sort by created">
							Status
						</th>
						<td mat-cell *matCellDef="let element">{{element.status }}</td>
					</ng-container>

					<!-- Last Active Column -->
					<ng-container matColumnDef="field_access">
						<th mat-header-cell *matHeaderCellDef mat-sort-header
							sortActionDescription="Sort by field_access">
							Last Active
						</th>
						<td mat-cell *matCellDef="let element">{{element.access}}</td>
					</ng-container>

					<!-- Created Column -->
					<ng-container matColumnDef="created">
						<th mat-header-cell *matHeaderCellDef mat-sort-header
							sortActionDescription="Sort by created">
							Created
						</th>
						<td mat-cell *matCellDef="let element">{{element.created | date: 'MM/dd/yyyy'}}</td>
					</ng-container>

					<!-- roles_target_id -->
					<ng-container matColumnDef="roles_target_id">
						<th mat-header-cell *matHeaderCellDef mat-sort-header
							sortActionDescription="Sort by roles_target_id">
							Role(s)
						</th>
						<td mat-cell *matCellDef="let element">{{ element.roles_target_id }}</td>
					</ng-container>

					<!-- Edit Column -->
					<ng-container matColumnDef="edit">
						<th mat-header-cell *matHeaderCellDef sortActionDescription="Sort by dashboard">
							Actions
						</th>
						<td mat-cell *matCellDef="let element">
									<button mat-icon-button [matMenuTriggerFor]="studentListMenu" [matMenuTriggerData]="{ uid: element.uid }">
										<mat-icon>more_vert</mat-icon>
									</button>
						</td>
					</ng-container>

					<mat-menu #studentListMenu="matMenu">
						<ng-template matMenuContent let-uid="uid">
								<button mat-menu-item
										(click)="openStaffDialog(this.frmManageStaffComponent, uid, 'edit');"
										>
										Edit Staff Member
								</button>
						</ng-template>
					</mat-menu>

					<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
					<tr cdkDrag mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
				</table>

					<mat-paginator (page)="pageChanged($event)"
						[pageSizeOptions]="onPageSizeOptions()" [length]="30"
						[pageSize]="paginationTotalPages" [pageIndex]="paginationIndex"
						aria-label="Select page of view"></mat-paginator>

			</div>


		</div>

	</div>

</div>
