import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatTabGroup } from '@angular/material/tabs';
import { BaseDialogComponent } from '../base-dialog/base-dialog.component';
import { BaseComponent } from '../base-form/base-form.component';

@Component({
  selector: 'app-student-record-entity',
  templateUrl: './student-record-entity.component.html',
  styleUrls: ['./student-record-entity.component.css']
})
export class StudentRecordEntityComponent extends BaseDialogComponent implements OnInit {

  override eckType = "contacts";
  override bundle = "student_record";
  override entityId = this._injectedDialogData['data']['EntityID'];

  contactList = [];
  field_contacts = [];
}
