<!-- update-group-lesson.component.html -->
<app-entity-mat-dialog-title title="Update Group Lesson(s)"></app-entity-mat-dialog-title>
<form #f="ngForm">
  <ng-container *ngIf="groupLessons.length > 0; else noLessonsTemplate">
    <div class="scrollable-list">
    <ng-container *ngFor="let lesson of groupLessons">
      <h3>
        Lesson: {{ lesson.eventDetails.data.date }} - {{ lesson.eventDetails.data.startTime }} to {{ lesson.eventDetails.data.endTime }}
      </h3>
      <p><strong>Instructor:</strong> {{lesson.instructorName}}</p>
      <p><strong>Time:</strong> {{ lesson.eventDetails.data.startTime }} - {{ lesson.eventDetails.data.endTime }}</p>

      <table class="table table-bordered table-striped">
        <thead>
          <tr>
            <th>Attendee</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let attendee of lesson.studentsDashboardLinks">
            <td>{{ attendee.name }}</td>
            <td>
              <mat-form-field>
                <mat-select [(ngModel)]="attendee.status" name="status_{{ attendee.attendees_id }}">
                  <mat-option *ngFor="let status of lessonStatuses" [value]="status.id">
                    {{status.name}} {{ attendee.status === status.id ? '' : '' }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </td>
          </tr>
        </tbody>
      </table>

      <ng-container *ngIf="lesson.studentsDashboardLinks?.length === 0">
        <div>No attendees for this lesson.</div>
      </ng-container>

      <div class="container mb-4">
        <div class="row">
          <div class="col-6">
            <button class="me-1" type="button" [disabled]="isButtonDisabled(lesson) || isSaving" (click)="setAllStatus('59', lesson);" mat-raised-button color="primary">
              Set All Showed
            </button>
            <button class="me-1" type="button" [disabled]="isButtonDisabled(lesson) || isSaving" (click)="setAllStatus('64', lesson);" mat-raised-button color="secondary">
              Set All Pending
            </button>
          </div>
          <div class="col-6">
            <div style="float:right;">
              <button type="button" [disabled]="isButtonDisabled(lesson) || isSaving" (click)="submitLesson(lesson)" mat-raised-button color="accent">
                <ng-container *ngIf="!isSaving">Save</ng-container>
                <ng-container *ngIf="isSaving">
                  <mat-icon class="me-1">hourglass_top</mat-icon> Saving...
                </ng-container>
              </button>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
  </ng-container>

  <ng-template #noLessonsTemplate>
    <div class="alert alert-info mt-2" role="alert">
      No group lessons available.
    </div>
  </ng-template>

  <div *ngIf="successMessageVisible" class="alert alert-success mt-3" role="alert">
    {{ successMessage }}
  </div>
</form>

<app-error-message [message]="this.errorMessage"></app-error-message>
