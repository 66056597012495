import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import moment from 'moment';
import { DrupalRESTService } from 'src/app/services/drupal-rest.service';

@Component({
  selector: 'app-tax-configuration',
  templateUrl: './tax-configuration.component.html',
  styleUrls: ['./tax-configuration.component.css']
})
export class TaxConfigurationComponent implements OnInit {
  taxConfigurationForm: FormGroup;
  actionType: 'create' | 'edit' = 'create';
  showProgressSpinner = false;
  successMessage: string;
  errorMessage: string;

  calculationMethods = ['PERCENTAGE', 'FLAT_RATE', 'TIERED'];
  roundingRules = ['ROUND_UP', 'ROUND_DOWN', 'ROUND_NEAREST', 'NO_ROUNDING'];
  taxTypes = ['VAT', 'GST', 'SALES_TAX', 'EXCISE_TAX', 'SERVICE_TAX'];

  constructor(
    private fb: FormBuilder,
    private drupalRESTService: DrupalRESTService
  ) {}

  ngOnInit(): void {
    this.taxConfigurationForm = this.fb.group({
      taxConfigurations: this.fb.array([])
    });

    this.loadTaxConfigurations();
  }

  get taxConfigurations(): FormArray {
    return this.taxConfigurationForm.get('taxConfigurations') as FormArray;
  }

  createTaxConfigurationGroup(data?: any): FormGroup {
    return this.fb.group({
      id: [data?.id?.[0]?.value || ''],
      title: [data?.title?.[0]?.value || '', Validators.required],
      field_calculation_method: [data?.field_calculation_method?.[0]?.value || null],
      field_rounding_rule: [data?.field_rounding_rule?.[0]?.value || null],
      field_tax_status: [data?.field_tax_status?.[0]?.value || '0'],
      field_tax_type: [data?.field_tax_type?.[0]?.value || null],
      field_tax_value: [data?.field_tax_value?.[0]?.value || null],
      field_effective_tax_start_date: [data?.field_effective_tax_start_date?.[0]?.value || null],
      field_effective_tax_end_date: [data?.field_effective_tax_end_date?.[0]?.value || null],
    });
  }

  loadTaxConfigurations() {
    const configFieldName = 'field_tax_ref';
    this.drupalRESTService
      .httpGET(`/api_rest/v1/loadStudioConfig?config_field_name=${configFieldName}`)
      .subscribe(
        (response) => {
          console.log('Loaded tax configurations:', response);
          if (Array.isArray(response)) {
            response.forEach(taxConfig => {
              this.taxConfigurations.push(this.createTaxConfigurationGroup(taxConfig));
            });
          } else if (response) {
            this.taxConfigurations.push(this.createTaxConfigurationGroup(response));
          }
          this.actionType = 'edit';
          console.log('Form structure:', this.taxConfigurationForm.value);
        },
        (error) => {
          console.error('Error loading tax configurations:', error);
          this.errorMessage = 'Error loading tax configurations.';
        }
      );
  }

  addTaxConfiguration() {
    this.taxConfigurations.push(this.createTaxConfigurationGroup());
  }

  removeTaxConfiguration(index: number) {
    const configId = this.taxConfigurations.at(index).get('id').value;
    if (configId) {
      this.showProgressSpinner = true;
      this.drupalRESTService
        .httpDELETE(`/api_rest/v1/deleteStudioConfig/${configId}`)
        .subscribe(
          (response) => {
            console.log('Tax configuration deleted successfully:', response);
            this.successMessage = 'Tax configuration deleted successfully.';
            this.taxConfigurations.removeAt(index);
            this.showProgressSpinner = false;
          },
          (error) => {
            console.error('Error deleting tax configuration:', error);
            this.errorMessage = 'Error deleting tax configuration.';
            this.showProgressSpinner = false;
          }
        );
    } else {
      this.taxConfigurations.removeAt(index);
    }
  }

  saveTaxConfigurations() {
    const configFieldName = 'field_tax_ref';
    const formattedData = this.taxConfigurationForm.value.taxConfigurations.map(config => {
      const formattedConfig = { ...config };

      // Format the dates using moment.js in the expected format
      if (formattedConfig.field_effective_tax_start_date) {
        formattedConfig.field_effective_tax_start_date = moment(formattedConfig.field_effective_tax_start_date).format('YYYY-MM-DDTHH:mm:ss');
      }
      if (formattedConfig.field_effective_tax_end_date) {
        formattedConfig.field_effective_tax_end_date = moment(formattedConfig.field_effective_tax_end_date).format('YYYY-MM-DDTHH:mm:ss');
      }

      return formattedConfig;
    });

    const postData = {
      config_field_name: configFieldName,
      config_data: formattedData,
    };

    this.showProgressSpinner = true;
    this.successMessage = '';
    this.errorMessage = '';

    this.drupalRESTService
      .httpPOST('/api_rest/v1/saveStudioConfig', postData)
      .subscribe(
        (response) => {
          console.log('Tax configurations saved successfully:', response);
          this.successMessage = 'Tax configurations saved successfully.';
          this.showProgressSpinner = false;
        },
        (error) => {
          console.error('Error saving tax configurations:', error);
          this.handleError(error); // Handle the error more effectively
          this.showProgressSpinner = false;
        }
      );
  }


  handleError(error: any) {
    // Parse and format the error message
    if (error.error && error.error.message) {
      this.errorMessage = `Error: ${error.error.message}`;
    } else if (error.status === 400) {
      this.errorMessage = 'Validation error. Please check your inputs and try again.';
    } else if (error.status === 500) {
      this.errorMessage = 'Server error. Please try again later.';
    } else {
      this.errorMessage = 'An unexpected error occurred. Please try again.';
    }
  }


  onSubmit() {
    this.saveTaxConfigurations();
  }

  onUpdate() {
    this.saveTaxConfigurations();
  }

  onCalculationMethodChange(index: number) {
    const taxConfig = this.taxConfigurations.at(index);
    const method = taxConfig.get('field_calculation_method').value;

    // Adjust the tax value field's placeholder based on the calculation method.
    taxConfig.get('field_tax_value').setValidators(method === 'PERCENTAGE' ? [Validators.required, Validators.min(0), Validators.max(100)] : [Validators.required, Validators.min(0)]);
    taxConfig.get('field_tax_value').updateValueAndValidity();
  }

  getTaxValueLabel(index: number): string {
    const method = this.taxConfigurations.at(index).get('field_calculation_method').value;
    return method === 'PERCENTAGE' ? 'Tax Percentage' : 'Tax Amount';
  }

  getTaxValuePlaceholder(index: number): string {
    const method = this.taxConfigurations.at(index).get('field_calculation_method').value;
    return method === 'PERCENTAGE' ? 'Enter percentage (0-100)' : 'Enter flat amount';
  }

  getHelpfulInfo(index: number): string {
    const taxConfig = this.taxConfigurations.at(index).value;

    const title = taxConfig.title ? `Title: ${taxConfig.title}` : 'Untitled Configuration';
    const type = taxConfig.field_tax_type ? `Type: ${taxConfig.field_tax_type}` : 'No Type';
    const method = taxConfig.field_calculation_method ? `Calculation Method: ${taxConfig.field_calculation_method}` : 'No Calculation Method';
    const status = taxConfig.field_tax_status ? `<span class="status-active">Active</span>` : `<span class="status-inactive">Inactive</span>`;
    const startDate = taxConfig.field_effective_tax_start_date ? `<span class="date-info">Start Date: ${new Date(taxConfig.field_effective_tax_start_date).toLocaleDateString()}</span>` : '';
    const endDate = taxConfig.field_effective_tax_end_date ? `<span class="date-info">End Date: ${new Date(taxConfig.field_effective_tax_end_date).toLocaleDateString()}</span>` : '';
    const taxValue = taxConfig.field_tax_value !== null && taxConfig.field_calculation_method === 'PERCENTAGE' ? `<span class="percentage-info">Tax Percentage: ${taxConfig.field_tax_value}%</span>` : '';

    let summary = `${title}, ${type}, ${method}`;

    if (taxValue) {
      summary += `, ${taxValue}`;
    }
    if (startDate) {
      summary += `, ${startDate}`;
    }
    if (endDate) {
      summary += `, ${endDate}`;
    }

    summary += `, Status: ${status}`;

    return summary;
  }

  calculateTaxCollected(method: string, value: number): number {
    const baseAmount = 1000; // Assuming the base amount is 1000 as per your example
    let taxCollected = 0;

    switch (method) {
      case 'PERCENTAGE':
        taxCollected = (value / 1000) * baseAmount;
        break;
      case 'FLAT_RATE':
        taxCollected = value;
        break;
      case 'TIERED':
        // Implement tiered calculation logic if applicable
        taxCollected = 0; // Placeholder
        break;
      default:
        taxCollected = 0;
    }

    // Ensure taxCollected is rounded to two decimal places
    return Math.round(taxCollected * 100) / 100;
  }
}
