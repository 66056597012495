<!-- cancel-future-appointment.component.html -->
<app-entity-mat-dialog-title title="Delete Future Lessons"></app-entity-mat-dialog-title>

<form (ngSubmit)="onSubmit(f)" #f="ngForm" class="form">
  <div *ngIf="lessonsToDelete.length > 0">
    <h2 *ngIf="_injectedDialogData?.['data']?.['bundle'] === 'lesson'">The following lessons will be deleted:</h2>
    <h2 *ngIf="_injectedDialogData?.['data']?.['bundle'] === 'schedules'">The following schedules will be deleted:</h2>
    <h2 *ngIf="_injectedDialogData?.['data']?.['bundle'] === 'services'">The following services will be deleted:</h2>
    <div class="scrollable-list mb-3">
      <ul>
        <li *ngFor="let lesson of lessonsToDelete">
          <i class="fa fa-calendar" aria-hidden="true"></i> - {{ lesson.date | date:'EEE, MMM d, y - h:mm a' }}
        </li>
      </ul>
    </div>
  </div>

  <div *ngIf="lessonsToDelete.length === 0">
    <h2 *ngIf="_injectedDialogData?.['data']?.['bundle'] === 'lesson'">No future lessons found to delete.</h2>
    <h2 *ngIf="_injectedDialogData?.['data']?.['bundle'] === 'schedules'">No future schedules found to delete.</h2>
    <h2 *ngIf="_injectedDialogData?.['data']?.['bundle'] === 'services'">No future services found to delete.</h2>
  </div>

  <app-error-message [message]=this.errorMessage></app-error-message>

  <div style="min-height:150px" *ngIf="showProgressSpinner">
    <mat-progress-spinner class="matProgressSpinnerCentered"
        *ngIf="showProgressSpinner" mode="indeterminate">
    </mat-progress-spinner>
  </div>

  <div class="dialog-actions">
    <button class="btn btn-success me-1" mat-button type="button" (click)="onCancel()">Exit</button>
    <button class="btn btn-warning" mat-button type="submit" color="accent" [disabled]="lessonsToDelete.length === 0">Confirm</button>
  </div>
</form>
