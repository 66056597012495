import { AfterViewInit, Component, ElementRef, HostListener, OnInit, Renderer2, ViewChild } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-license-agreement-dialog',
  templateUrl: './license-agreement-dialog.component.html',
  styleUrls: ['./license-agreement-dialog.component.css']
})
export class LicenseAgreementDialogComponent implements OnInit {
  @ViewChild('scrollableBox') scrollableBox: ElementRef;
  isChecked: boolean = false;
  canCheck: boolean = false;

  constructor(public dialogRef: MatDialogRef<LicenseAgreementDialogComponent>) { }

  ngOnInit(): void {
  }

  onScroll(): void {
    const scrollableElement = this.scrollableBox.nativeElement;
    const scrollPosition = scrollableElement.scrollTop + scrollableElement.offsetHeight;
    const scrollHeight = scrollableElement.scrollHeight;

    if (scrollPosition >= scrollHeight) {
      this.canCheck = true;
    } else {
      this.canCheck = false;
    }
  }

  onAgree(): void {
    this.dialogRef.close(true);
  }

  onCancel(): void {
    this.dialogRef.close(false);
  }
}
