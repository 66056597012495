import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-repetition-units',
  templateUrl: './repetition-units.component.html',
  styleUrls: ['./repetition-units.component.css',
    '../../../../../app.component.css'
  ]
})
export class RepetitionUnitsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  repetition_units: string[] = [
    "Weekly",
    "EOW",
    "Monthly",
    "Days",
    "Weeks",
    "Months"
  ]

}
