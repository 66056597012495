import { Component, OnInit } from '@angular/core';
import { FieldsService } from 'src/app/services/fields.service';

@Component({
  selector: 'app-department-type-dropdown',
  templateUrl: './department-type-dropdown.component.html',
  styleUrls: ['./department-type-dropdown.component.css']
})
export class DepartmentTypeDropdownComponent implements OnInit {

  departmentData: { key: string; value: string; }[];
  
  constructor(private _fieldsService: FieldsService) { }

  ngOnInit(): void {
    this.departmentData = this._fieldsService.studentDepartmentData();
  }


}
