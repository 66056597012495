import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { DrupalRESTService } from 'src/app/services/drupal-rest.service';
import { FieldsService } from 'src/app/services/fields.service';
import { timeZones } from 'src/app/constants/time-zones';

@Component({
  selector: 'app-form-studio-information',
  templateUrl: './form-studio-information.component.html',
  styleUrls: ['./form-studio-information.component.css']
})
export class FormStudioInformationComponent implements OnInit {
  studioInformationForm: FormGroup;
  actionType: 'create' | 'edit' = 'create';
  showProgressSpinner = false;
  successMessage: string;
  errorMessage: string;

  timeZones = timeZones;

  constructor(
    private fb: FormBuilder,
    private drupalRESTService: DrupalRESTService,
    public _fieldsService: FieldsService
  ) {}

  ngOnInit(): void {
    this.studioInformationForm = this.fb.group({
      studioDisplayName: ['', Validators.required],
      studioEmail: ['', [Validators.required, Validators.email]],
      studioPhoneNumber: ['', Validators.required],
      studioWebsite: this.fb.group({
        uri: ['', Validators.required],
        title: ['', Validators.required]
      }),
      studioAddress: this.fb.group({
        country: ['', Validators.required],
        givenName: ['', Validators.required],
        familyName: ['', Validators.required],
        company: [''],
        streetAddress: ['', Validators.required],
        city: ['', Validators.required],
        state: ['', Validators.required],
        zipCode: ['', Validators.required]
      }),
      studioTimeZone: ['', Validators.required]
    });

    this.loadStudioInformation();
  }

  loadStudioInformation() {
    const configFieldName = 'field_studio_information_ref';
    this.drupalRESTService
      .httpGET(`/api_rest/v1/loadStudioConfig?config_field_name=${configFieldName}`)
      .subscribe(
        (response) => {
          const formData = {
            studioDisplayName: response?.['field_studio_display_name']?.[0]?.value,
            studioEmail: response?.['field_studio_email']?.[0]?.value,
            studioPhoneNumber: response?.['field_studio_phone_number']?.[0]?.value,
            studioWebsite: {
              uri: response?.['field_studio_website']?.[0]?.uri,
              title: response?.['field_studio_website']?.[0]?.title
            },
            studioAddress: {
              country: response?.['field_studio_address']?.[0]?.country_code,
              givenName: response?.['field_studio_address']?.[0]?.given_name,
              familyName: response?.['field_studio_address']?.[0]?.family_name,
              company: response?.['field_studio_address']?.[0]?.organization,
              streetAddress: response?.['field_studio_address']?.[0]?.address_line1,
              city: response?.['field_studio_address']?.[0]?.locality,
              state: response?.['field_studio_address']?.[0]?.administrative_area,
              zipCode: response?.['field_studio_address']?.[0]?.postal_code
            },
            studioTimeZone: response?.['field_studio_timezone']?.[0]?.value
          };

          this.studioInformationForm.patchValue(formData);
        },
        (error) => {
          console.error('Error loading studio information:', error);
          this.errorMessage = 'Error loading studio information.';
        }
      );
  }

  saveStudioInformation() {
    const configFieldName = 'field_studio_information_ref';
    const formValues = this.studioInformationForm.value;

    const postData = {
      config_field_name: configFieldName,
      config_data: {
        field_studio_display_name: [{value: formValues.studioDisplayName}],
        field_studio_email: [{value: formValues.studioEmail}],
        field_studio_phone_number: [{value: formValues.studioPhoneNumber}],
        field_studio_website: [{
          uri: formValues.studioWebsite.uri,
          title: formValues.studioWebsite.title
        }],
        field_studio_address: [{
          country_code: formValues.studioAddress.country,
          given_name: formValues.studioAddress.givenName,
          family_name: formValues.studioAddress.familyName,
          organization: formValues.studioAddress.company,
          address_line1: formValues.studioAddress.streetAddress,
          locality: formValues.studioAddress.city,
          administrative_area: formValues.studioAddress.state,
          postal_code: formValues.studioAddress.zipCode
        }],
        field_studio_timezone: [{value: formValues.studioTimeZone}]
      }
    };

    this.showProgressSpinner = true;
    this.successMessage = '';
    this.errorMessage = '';

    this.drupalRESTService
      .httpPOST('/api_rest/v1/saveStudioConfig', postData)
      .subscribe(
        (response) => {
          console.log('Studio information saved successfully:', response);
          this.successMessage = 'Studio information saved successfully.';
          this.showProgressSpinner = false;
        },
        (error) => {
          console.error('Error saving studio information:', error);
          this.errorMessage = 'Error saving studio information.';
          this.showProgressSpinner = false;
        }
      );
  }

  onSubmit() {
    this.saveStudioInformation();
  }

  onUpdate() {
    this.saveStudioInformation();
  }
}
