<mat-expansion-panel [expanded]="true">
    <mat-expansion-panel-header>
        <mat-panel-title>
            Personal Information
        </mat-panel-title>
    </mat-expansion-panel-header>

    <div class="row">
        <div class="col-12">
            <mat-form-field class="full-width-field">
                <mat-label>Student Account</mat-label>
                <!-- <input
                    name=""
                    (input)="($event)"
                    matInput
                    [(ngModel)]=""
                    [matAutocomplete]="auto"
                    (blur)="OnSubmit(f)"
                    > -->
                    <input
                    matInput
                    >
                <button type="button" matSuffix mat-icon-button
                    aria-label="manage_accounts">
                    <mat-icon>manage_accounts</mat-icon>
                </button>
            </mat-form-field>
        </div>
        <div class="col-12">
            <mat-form-field class="full-width-field">
                <mat-label>Student</mat-label>
                <!-- <input
                    name=""
                    (input)="($event)"
                    matInput
                    [(ngModel)]=""
                    [matAutocomplete]="auto"
                    (blur)="OnSubmit(f)"
                    > -->
                    <input
                    matInput
                    >
                <button type="button" matSuffix mat-icon-button
                    aria-label="manage_accounts">
                    <mat-icon>manage_accounts</mat-icon>
                </button>
            </mat-form-field>
        </div>

        <div class="col-12">
            <mat-form-field class="full-width-field">
                <mat-label>Description</mat-label>
                <textarea ngModel name="field_description" matInput placeholder=""></textarea>
            </mat-form-field>
        </div>

        <div class="col-12">
            <mat-form-field class="full-width-field">
                <mat-label>Status</mat-label>
                <mat-select
                    ngModel
                    name="status">
                    <mat-option
                        *ngFor="let status of statusList"
                        [value]="status.id">
                        {{status.value}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>

        <div class="col-12">
            <button type="submit"
            mat-raised-button
            color="primary">Create attendees</button>
        </div>

    </div>

</mat-expansion-panel>
