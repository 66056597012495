import { Component, Inject, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'lesson',
  templateUrl: './lesson.component.html',
  styleUrls: [
    './lesson.component.css',
    '../../../../app.component.css'
  ]
})
export class LessonComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: {InstructorList: {}, lesson_types: string[]}) { }

  ngOnInit(): void {
  }

  OnSubmit(form: NgForm) {
    console.log(form.value);
  }


}
