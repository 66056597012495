<mat-form-field class="full-width-field">
    <mat-label>Repetition Units</mat-label>
    <mat-select
        ngModel
        name="repetition_units[]">
        <mat-option
            *ngFor="let unit of repetition_units"
            [value]="unit">
            {{unit}}
        </mat-option>
    </mat-select>
</mat-form-field>