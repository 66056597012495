<!-- status-badge-update.component.html -->
<div class="dropdown">
  <button class="btn btn-sm dropdown-toggle status-badge"
          [ngClass]="getStatusClass(currentStatus)"
          type="button" id="statusDropdown"
          data-bs-toggle="dropdown"
          aria-expanded="false">
    <span *ngIf="!isUpdating">{{ getStatusName(currentStatus) }}</span>
    <span *ngIf="isUpdating" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
  </button>
  <ul class="dropdown-menu" aria-labelledby="statusDropdown">
    <li *ngFor="let status of statuses">
      <a class="dropdown-item" (click)="updateStatus(status.id)">{{ status.name }}</a>
    </li>
  </ul>
</div>

<!-- Debug Information -->
<div class="debug-info" *ngIf="showDebugInfo">
  <p><strong>Debug Info:</strong></p>
  <ul>
    <li><strong>Current Status:</strong> {{ currentStatus }}</li>
    <li><strong>Lesson ID:</strong> {{ lessonId }}</li>
    <li><strong>Statuses:</strong> {{ statuses | json }}</li>
    <li><strong>Is Updating:</strong> {{ isUpdating }}</li>
  </ul>
</div>
