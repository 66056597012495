import { Component, OnInit } from '@angular/core';
import { FormArray, NgForm, Validators } from '@angular/forms';
import _ from 'lodash';
import moment from 'moment';
import { BaseDialogComponent } from '../base-dialog/base-dialog.component';

@Component({
  selector: 'app-misc-service-deductions-entity',
  templateUrl: './misc-service-deductions-entity.component.html',
  styleUrls: ['./misc-service-deductions-entity.component.css']
})
export class MiscServiceDeductionsEntityComponent extends BaseDialogComponent implements OnInit {

  override eckType = "deductions";
  override bundle = "miscservicededuction";
  override entityId = this._injectedDialogData['data']['EntityID'];

  weekNumber;
  yearNumber;

  override ngOnInit(): void {
    console.log("this._injectedDialogData['data']", this._injectedDialogData['data'])
    this.initActionType = this._injectedDialogData['data']['action'];
    this.actionType = this._injectedDialogData['data']['action'];

    let formGroup = {
      title: [null, Validators.required],
      field_event_expenses: [null, Validators.required],
      field_transportation_cost: [null, Validators.required],
      field_week_report_number: [this._injectedDialogData['data']?.fieldsData?.week, Validators.required],
      field_year_report_number: [this._injectedDialogData['data']?.fieldsData?.year, Validators.required],
    }

    let formGroupEdit;

    formGroupEdit = {
      ...formGroup,
    }

    this.miscServiceDeductionsFormGroup = this._formBuilder.group(formGroupEdit);
  }

  onSubmitMiscDeduction(f: NgForm, drop = false) {
    this.displayProgressSpinner(true);
    console.log('onSubmitPayment called...');

    console.log('f.value', f.value);

    this.onSubmit(this.miscServiceDeductionsFormGroup);
  }
}
