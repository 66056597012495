<mat-dialog-actions align="end">
    <ng-container *ngIf="this.actionType === 'create'">
        <!-- <button type="button" (click)="setFormMode('delete')" mat-raised-button color="accent">Reset</button> -->
        <button type="button" (click)="onSubmit(f);" mat-raised-button color="accent">Save</button>
    </ng-container>

    <ng-container *ngIf="this.actionType === 'edit'">
        <button type="button" (click)="setFormMode('delete')" mat-raised-button color="accent">Delete</button>
        <button type="button" (click)="onSubmit(f);" mat-raised-button color="accent">Save</button>
    </ng-container>

    <ng-container *ngIf="this.actionType === 'delete'">
        <button (click)="setFormMode('edit')" type="submit" mat-raised-button color="accent">Cancel</button>
        <button (click)="onDeleteSubmit(this._injectedDialogData['data']['EntityID'])" type="submit" mat-raised-button
            color="accent">Delete</button>
    </ng-container>
</mat-dialog-actions>