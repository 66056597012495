<div class="container-fluid">
  <h1 class="page-header">Inquiry Data Report</h1>

  <mat-expansion-panel>
    <mat-expansion-panel-header>
      <mat-panel-title>
        Filter
      </mat-panel-title>
      <mat-panel-description>
        <app-filter-results-panel [parameters]=queryParams></app-filter-results-panel>
      </mat-panel-description>
    </mat-expansion-panel-header>
    <form (ngSubmit)="onSubmit(f)" #f=ngForm class="form">
      <div class="row">

        <!-- Autocomplete First Name -->
        <div class="col-2">
          <mat-form-field class="full-width-field">
            <mat-label>First Name</mat-label>
            <input name="field_first_name_value" (input)="onFirstNameInput($event)" matInput ngModel
              [matAutocomplete]="autoCompleteViewFirstName" (optionSelected)="onSubmit(f)">
            <button type="button" matSuffix mat-icon-button aria-label="manage_accounts">
              <mat-icon>manage_accounts</mat-icon>
            </button>
          </mat-form-field>
          <mat-autocomplete #autoCompleteViewFirstName="matAutocomplete" (optionSelected)="onSubmit(f)">
            <mat-option *ngFor="let option of autoCompleteFirstNameOptions" [value]="option.label">
              {{option.label}}
            </mat-option>
          </mat-autocomplete>
        </div>

        <!-- Autocomplete Last Name -->
        <div class="col-2">
          <mat-form-field class="full-width-field">
            <mat-label>Last Name</mat-label>
            <input name="field_last_name_value" (input)="onLastNameInput($event)" matInput ngModel
              [matAutocomplete]="autoCompleteViewLastName" (optionSelected)="onSubmit(f)">
            <button type="button" matSuffix mat-icon-button aria-label="manage_accounts">
              <mat-icon>manage_accounts</mat-icon>
            </button>
          </mat-form-field>
          <mat-autocomplete #autoCompleteViewLastName="matAutocomplete" (optionSelected)="onSubmit(f)">
            <mat-option *ngFor="let option of autoCompleteLastNameOptions" [value]="option.label">
              {{option.label}}
            </mat-option>
          </mat-autocomplete>
        </div>

        <div class="col-2">
          <mat-form-field class="full-width-field">
            <mat-label>Inquired</mat-label>
            <input matInput ngModel name="field_inquired_value" (blur)="onSubmit(f)" (selectionChange)="onSubmit(f)">
          </mat-form-field>
        </div>

        <div class="col-2">
          <mat-form-field class="full-width-field">
            <mat-label>Email</mat-label>
            <input name="field_email_value" (input)="onViewsAutocompleteEmail($event)" matInput ngModel
              [matAutocomplete]="autoCompleteViewEmail" (optionSelected)="onSubmit(f)">
            <button type="button" matSuffix mat-icon-button aria-label="manage_accounts">
              <mat-icon>manage_accounts</mat-icon>
            </button>
          </mat-form-field>
          <mat-autocomplete #autoCompleteViewEmail="matAutocomplete" (optionSelected)="onSubmit(f)">
            <mat-option *ngFor="let option of autoCompleteOptions" [value]="option.label">
              {{option.value}} ({{option.label}})
            </mat-option>
          </mat-autocomplete>
        </div>

        <!-- Autocomplete First and Last Name -->
        <div class="col-2">
          <mat-form-field class="full-width-field">
            <mat-label>Inquiry Taker</mat-label>
            <input name="inquiry_taker" (input)="onFirstLastNameInput($event)" matInput ngModel
              [matAutocomplete]="autoCompleteViewFirstLastName" (optionSelected)="onSubmit(f)">
            <button type="button" matSuffix mat-icon-button aria-label="manage_accounts">
              <mat-icon>manage_accounts</mat-icon>
            </button>
          </mat-form-field>
          <mat-autocomplete #autoCompleteViewFirstLastName="matAutocomplete" (optionSelected)="onSubmit(f)">
            <mat-option *ngFor="let option of autoCompleteFirstLastNameOptions" [value]="option.label + ' (' + option.value + ')'">
              {{option.label}}
            </mat-option>
          </mat-autocomplete>
        </div>

        <div class="col-2">
          <mat-form-field class="full-width-field">
            <mat-label>Teacher</mat-label>
            <input name="teacher" (input)="onAutocompleteInstructor($event)" matInput ngModel
              [matAutocomplete]="autoCompleteInstructor" (optionSelected)="onSubmit(f)">
            <button type="button" matSuffix mat-icon-button aria-label="manage_accounts">
              <mat-icon>manage_accounts</mat-icon>
            </button>
          </mat-form-field>
          <mat-autocomplete #autoCompleteInstructor="matAutocomplete" (optionSelected)="onSubmit(f)">
            <mat-option *ngFor="let option of autocompleteInstructorOptions"
              [value]="option.label + ' ' + '(' + option.value + ')'">
              {{option.label}} ({{option.value}})
            </mat-option>
          </mat-autocomplete>
        </div>

        <section class="section">

          <div class="float-end bring-inline">
            <button (click)="resetForm()" color="primary" mat-flat-button class="me-1" mat-button>Clear
              <!-- <mat-icon (click)="resetForm()" aria-hidden="false" aria-label="Menu dropdown" class=""></mat-icon> -->
            </button>
          </div>

        </section>


      </div>


      <section class="section">
      </section>

    </form>

  </mat-expansion-panel>

  <mat-progress-bar *ngIf="ShowProgressBar" mode="indeterminate"></mat-progress-bar>
  <div class="row">
    <div class="col-md-12 mt-3">
      <div class="float-end">
        <!-- <button (click)="onPrint()" color="primary" mat-flat-button class="me-1" mat-button>Print</button> -->
        <!-- <button (click)="onDownloadCSV()" color="primary" mat-flat-button class="me-1 warning" mat-button>CSV</button> -->
      </div>
    </div>
  </div>

  <!-- <mat-form-field appearance="standard">
    <mat-label>Filter</mat-label>
    <input matInput (keyup)="applyFilter($event)" placeholder="" #input>
  </mat-form-field> -->

  <!-- <div class="example-container mat-elevation-z8">
  <div class="loading-shade"
    *ngIf="ShowProgressBar">
    <mat-spinner *ngIf="ShowProgressBar"></mat-spinner>
    <div class="example-rate-limit-reached" *ngIf="isRateLimitReached">
      No limit
    </div>
  </div>
  </div> -->

  <div class="table-container">

    <table mat-table #displayTable [dataSource]="dataSource" matSort (matSortChange)="sortData($event)" class="mt-4">

      <!-- field_first_name Column -->
      <ng-container matColumnDef="field_first_name">
        <th mat-header-cell *matHeaderCellDef sortActionDescription="Sort by First Name">
          First Name
        </th>
        <td mat-cell *matCellDef="let element"> {{element.field_first_name}} </td>
      </ng-container>

      <!-- field_last_name Column -->
      <ng-container matColumnDef="field_last_name">
        <th mat-header-cell *matHeaderCellDef sortActionDescription="Sort by Last Name">
          Last Name
        </th>
        <td mat-cell *matCellDef="let element"> {{ element.field_last_name }} </td>
      </ng-container>

      <!-- field_inquired Column -->
      <ng-container matColumnDef="field_inquired">
        <th mat-header-cell *matHeaderCellDef sortActionDescription="Sort by Inquired">
          Inquired
        </th>
        <td mat-cell *matCellDef="let element"> {{ element.field_inquired | date }}
        </td>
      </ng-container>

      <!-- field_booked_on Column -->
      <ng-container matColumnDef="field_booked_on">
        <th mat-header-cell *matHeaderCellDef sortActionDescription="Sort by number">
          Booked On
        </th>
        <td mat-cell *matCellDef="let element"> {{element.field_booked_on  | date}} </td>
      </ng-container>

      <!-- field_showed Column -->
      <ng-container matColumnDef="field_showed">
        <th mat-header-cell *matHeaderCellDef sortActionDescription="Sort by Showed">
          Showed
        </th>
        <td mat-cell *matCellDef="let element"> {{ element.field_showed  | date}} </td>
      </ng-container>

      <!-- inquiry_taker Column -->
      <ng-container matColumnDef="inquiry_taker">
        <th mat-header-cell *matHeaderCellDef sortActionDescription="Sort by Inquiry Taker">
          Inquiry Taker
        </th>
        <td mat-cell *matCellDef="let element"> {{ element.inquiry_taker }} </td>
      </ng-container>

      <!-- teacher Column -->
      <ng-container matColumnDef="teacher">
        <th mat-header-cell *matHeaderCellDef sortActionDescription="Sort by teacher">
          Teacher
        </th>
        <td mat-cell *matCellDef="let element"> {{ element.teacher }} </td>
      </ng-container>

      <!-- field_email Column -->
      <ng-container matColumnDef="field_email">
        <th mat-header-cell *matHeaderCellDef sortActionDescription="Sort by Email">
          Email
        </th>
        <td mat-cell *matCellDef="let element"> {{ element.field_email }} </td>
      </ng-container>

      <!-- field_cell_phone Column -->
      <ng-container matColumnDef="field_cell_phone">
        <th mat-header-cell *matHeaderCellDef sortActionDescription="Sort by Cell Phone">
          Cell Phone
        </th>
        <td mat-cell *matCellDef="let element"> {{element.field_cell_phone}} </td>
      </ng-container>

      <!-- field_inquiry_method Column -->
      <ng-container matColumnDef="field_inquiry_method">
        <th mat-header-cell *matHeaderCellDef sortActionDescription="Sort by Inquiry Method">
          Inquiry Method
        </th>
        <td mat-cell *matCellDef="let element"> {{ element.field_inquiry_method }}
        </td>
      </ng-container>

      <!-- field_notes Column -->
      <ng-container matColumnDef="field_notes">
        <th mat-header-cell *matHeaderCellDef sortActionDescription="Sort by Notes">
          Notes
        </th>
        <td mat-cell *matCellDef="let element"> {{ element.field_notes }} </td>
      </ng-container>

      <!-- actions Column -->
      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef sortActionDescription="Sort by Notes">
          Actions
        </th>
        <td mat-cell *matCellDef="let element">
        <button mat-flat-button color="primary"
          (click)="openEntityComponent(StudentInquiryDetailsComponent, 'student_accounts', 'student_account', 'edit', element.student_account_id)"
          >
          Edit Inquiry Information
          </button>
        </td>
      </ng-container>



      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row matRipple *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>

    <mat-paginator (page)="pageChanged($event)" [pageSizeOptions]="onPageSizeOptions()" [length]="30"
      [disabled]="ShowProgressBar"
      [pageSize]="paginationTotalPages" [pageIndex]="paginationIndex" aria-label="Select page of view"></mat-paginator>

  </div>
</div>
