<div style="height:64px;" class="page-empty-header"></div>

<div class="container-fluid">
	<div class="row">
		<div style="background:#215DAB; overflow: scroll;" class="col-2">
			<app-configuration-dashboard-menu></app-configuration-dashboard-menu>
		</div>

		<div class="col-10" style="padding-left: 2.5rem;padding-right: 2.5rem;padding-top:2.5rem;overflow:scroll;max-height: calc(100vh - 64px);">

			<h1 class="configuration-page-header">Custom Import</h1>

			<ng-container *ngFor="let config of configs">
				<mat-card class="mb-3">
					<mat-card-content>
						<div [formGroup]="form">
							<div class="csv-import-section">
								<h5><i class="bi bi-{{ config.icon }}"></i> {{ config.title }}</h5>
								<ol>
									<li>Download the '{{ config.title }}' CSV template and fill it out.</li>
									<li>Save the file on your computer.</li>
									<li>Select and upload the file using the 'Choose File' button.</li>
								</ol>
								<a href="{{ config.templateUrl }}" download="{{ config.downloadName }}" class="btn btn-primary btn-sm text-white">Download Template</a>
								<input type="file" class="form-control mt-2" (change)="onFileSelected($event, config.field)" accept=".csv" formControlName="{{ config.field }}"/>
								<mat-error *ngIf="form.get(config.field)?.hasError('required')">File is required</mat-error>
							</div>
						</div>
						<button class="mt-3" mat-raised-button color="primary" (click)="onSubmit(config)">Submit</button>
						<div class="mt-3" *ngFor="let result of importResults[config.field]">
							<div [ngClass]="{'alert': true, 'alert-success': result.type === 'success', 'alert-danger': result.type === 'error'}">
								Line {{ result.lineNumber }}: {{ result.message }}
							</div>
						</div>
						<!-- <div *ngIf="successMessages[config.field]" class="alert alert-success mt-3">
							{{ successMessages[config.field] }}
						</div>
						<div *ngIf="errorMessages[config.field]" class="alert alert-danger mt-3">
							{{ errorMessages[config.field] }}
						</div> -->
					</mat-card-content>
				</mat-card>

			</ng-container>

			<div class="mt-3">
				<!-- <button mat-raised-button color="primary" (click)="onSubmit()">Submit</button> -->
				<!-- [disabled]="!allFilesSelected()" -->
			</div>

	</div>
</div>

