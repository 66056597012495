import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormArray, Validators, AbstractControl } from '@angular/forms';
import moment from 'moment';
import { Subscription, catchError, concatMap, delay, from, mergeMap, retryWhen, tap, throwError, timer, toArray } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';
import { EntityRESTService } from 'src/app/services/entity-rest.service';
import { FieldsService } from 'src/app/services/fields.service';
import { GenderDetectionService } from 'src/app/services/gender-detection.service';
import { PackagesService } from 'src/app/services/packages.service';
import { TaxonomyService } from 'src/app/services/taxonomy.service';
import { of } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { HttpErrorResponse } from '@angular/common/http';
import { DrupalRESTService } from 'src/app/services/drupal-rest.service';


@Component({
  selector: 'app-simple-import-student-accounts',
  templateUrl: './simple-import-student-accounts.component.html',
  styleUrls: ['./simple-import-student-accounts.component.css']
})
export class SimpleImportStudentAccountsComponent implements OnInit {

  constructor(private fb: FormBuilder, private packagesService: PackagesService, private _taxonomyService: TaxonomyService, private _fieldsService: FieldsService, private _entityRESTService: EntityRESTService, private _genderDetectionService: GenderDetectionService, private _authService: AuthService, private snackBar: MatSnackBar, private changeDetectorRef: ChangeDetectorRef, private _drupalRESTService: DrupalRESTService) {
    this.studentAccountsForm = this.fb.group({
      studentAccounts: this.fb.array([])
    });
  }

  studentAccountsForm: FormGroup;
  packages = [];
  firstNameSubscriptions: Subscription[] = [];
  lastNameSubscriptions: Subscription[] = [];

  studentErrorMessages = {};
  studentSuccessStates = {};
  enrollmentErrorMessages = {};
  enrollmentSuccessStates = {};
  paymentErrorMessages = {};
  paymentSuccessStates = {};
  lessonSuccessStates = {};
  lessonErrorMessages = {};

  ngOnInit() {
    this.addStudentAccount();
    this.setupAutofillListeners();
    this.subscribeToDiscountChanges();
    this.setupGenderDetection();
    this.setupInstructorChangeListeners();

    this.packagesService.packages$.subscribe(packages => {
      this.packages = packages;
    });

    this._taxonomyService.initTaxonomyTerms();
  }

  getEnrollmentsFormGroup(studentAccount: AbstractControl): FormArray {
    return studentAccount.get('enrollments') as FormArray;
  }

  getLessons(studentAccountIndex: number, enrollmentIndex: number): FormArray {
    const studentAccountFormGroup = this.studentAccountsArray.at(studentAccountIndex);
    if (!studentAccountFormGroup) {
      console.error('Student account form group not found');
      return; // or handle the error appropriately
    }

    const enrollments = studentAccountFormGroup.get('enrollments') as FormArray;
    if (!enrollments) {
      console.error('Enrollments FormArray not found');
      return; // or handle the error appropriately
    }

    const enrollmentFormGroup = enrollments.at(enrollmentIndex);
    if (!enrollmentFormGroup) {
      console.error('Enrollment form group not found');
      return; // or handle the error appropriately
    }

    return enrollmentFormGroup.get('lessons') as FormArray;
  }

  getPayments(studentAccountIndex: number, enrollmentIndex: number): FormArray {
    // Safely get the student account FormGroup
    const studentAccountFormGroup = this.studentAccountsArray.at(studentAccountIndex) as FormGroup;
    if (!studentAccountFormGroup) {
      console.error('Student account form group not found');
      return; // or handle the error appropriately
    }

    // Safely get the enrollments FormArray
    const enrollments = studentAccountFormGroup.get('enrollments') as FormArray;
    if (!enrollments || enrollments.length <= enrollmentIndex) {
      console.error('Enrollments FormArray not found or index out of bounds');
      return; // or handle the error appropriately
    }

    // Safely get the specific enrollment FormGroup
    const enrollmentFormGroup = enrollments.at(enrollmentIndex) as FormGroup;
    if (!enrollmentFormGroup) {
      console.error('Enrollment form group not found');
      return; // or handle the error appropriately
    }

    // Return the payments FormArray
    return enrollmentFormGroup.get('payments') as FormArray;
  }

  onPackageChange(packageId: string, studentAccountIndex: number, enrollmentIndex: number) {
    const selectedPackage = this.packages.find(pkg => pkg.id === packageId);

    if (selectedPackage) {
      const studentAccountFormGroup = this.studentAccountsArray.at(studentAccountIndex) as FormGroup;

      const enrollmentsArray = studentAccountFormGroup.get('enrollments') as FormArray;

      if (!enrollmentsArray || enrollmentsArray.length <= enrollmentIndex) {
        console.error('Enrollment FormArray not found or index out of bounds');
        return;
      }

      const enrollmentFormGroup = enrollmentsArray.at(enrollmentIndex) as FormGroup;

      if (!enrollmentFormGroup) {
        console.error('Enrollment FormGroup not found');
        return;
      }

      const discount = this.parsePrice(selectedPackage.field_discount);
      let originalTotalPrice;

      if (selectedPackage.field_discount_type === 'flat') {
        // If the discount is flat, add it back to the total price
        originalTotalPrice = this.parsePrice(selectedPackage.field_total_price) + discount;
      } else if (selectedPackage.field_discount_type === 'percentage') {
        // If the discount is a percentage, reverse calculate the original price
        originalTotalPrice = this.parsePrice(selectedPackage.field_total_price) / (1 - (discount / 100));
      }

      enrollmentFormGroup.patchValue({
        field_enrollment_package_name: selectedPackage.id,
        field_enrollment_lesson_price: this.parsePrice(selectedPackage.field_lesson_price),
        field_enrollment_lesson_count: selectedPackage.field_lesson_count,
        field_enrollment_total_price: originalTotalPrice, // Adjusted to original price without discount
        field_discount_type: selectedPackage.field_discount_type,
        field_discount: discount,
        field_category: this.mapPackageValue(selectedPackage.field_sps_code).valueAttribute.toString(),
        field_sale_date: moment().format('YYYY-MM-DD[T]12:00:00'),
        field_expiration_date: moment().add(6, 'month').format('YYYY-MM-DD[T]12:00:00'),
        __total_enrollment_price: originalTotalPrice, // Adjusted total enrollment price without discount
        __total_lessons_enrolled: selectedPackage.field_lesson_count,
        __total_lessons_taken: 0 // Assuming initially no lessons are taken
      });

      this.recalculateValues(enrollmentFormGroup);
    }
  }

  get studentAccountsArray(): FormArray {
    return this.studentAccountsForm.get('studentAccounts') as FormArray;
  }

  addStudentAccount(id = '') {
    const studentAccountFormGroup = this.fb.group({
      id: [id],
      contact1FirstName: ['', Validators.required],
      contact1LastName: ['', Validators.required],
      contact1Email: ['', [Validators.required, Validators.email]],
      contact1Phone: ['', Validators.required],
      contact1Gender: [''],
      contact2FirstName: [''], // Optional, no validators
      contact2LastName: [''],  // Optional, no validators
      contact2Email: ['', Validators.email], // Email validation, but not required
      contact2Phone: [''], // Optional, no validators
      contact2Gender: [''], // Optional, no validators
      enrollments: this.fb.array([this.createEnrollmentFormGroup()])
    });
    this.studentAccountsArray.push(studentAccountFormGroup);
    this.subscribeToDiscountChanges();
  }

  addEnrollment(studentAccountIndex: number) {
    const enrollments = this.studentAccountsArray.at(studentAccountIndex).get('enrollments') as FormArray;
    const newEnrollmentFormGroup = this.createEnrollmentFormGroup();
    enrollments.push(newEnrollmentFormGroup);

    this.setupAutofillListenersForEnrollment(newEnrollmentFormGroup, studentAccountIndex, enrollments.length - 1);
    this.subscribeToInstructorChanges(newEnrollmentFormGroup);
    this.subscribeToEnrollmentDateChanges(newEnrollmentFormGroup);
  }

  addPayment(studentAccountIndex: number, enrollmentIndex: number) {
    const enrollments = this.studentAccountsArray.at(studentAccountIndex).get('enrollments') as FormArray;
    const enrollment = enrollments.at(enrollmentIndex) as FormGroup;
    const payments = enrollment.get('payments') as FormArray;
    payments.push(this.createPaymentFormGroup());
  }

  addLesson(studentAccountIndex: number, enrollmentIndex: number) {
    const enrollments = this.studentAccountsArray.at(studentAccountIndex).get('enrollments') as FormArray;
    const enrollment = enrollments.at(enrollmentIndex) as FormGroup;
    const lessons = enrollment.get('lessons') as FormArray;
    const field_sale_date = enrollment.get('field_sale_date').value;

    lessons.push(this.createLessonFormGroup(null, null, null, field_sale_date));

    // Recalculate the total lessons taken
    const totalLessonsTaken = lessons.length;
    enrollment.patchValue({ __total_lessons_taken: totalLessonsTaken });
  }

  createLessonFormGroup(id = '', instructor = '', attendeeId = '', field_date_and_time = ''): FormGroup {
    return this.fb.group({
      id: [id],
      attendeeId: [attendeeId],
      field_date_and_time: [field_date_and_time || moment().format('YYYY-MM-DD[T]12:00:00')],
      field_instructor: [instructor]
    });
  }

  createPaymentFormGroup(): FormGroup {
    return this.fb.group({
      id: [''],
      field_date_and_time: ['', Validators.required],
      field_gross_tuition: [0, Validators.required],
      field_tax_collected: [0, Validators.required],
      field_student_name: [''],
      field_enrollment_name: [''],
      field_payment_type: ['71', Validators.required]
    });
  }

  deleteEnrollment(studentAccountIndex: number, enrollmentIndex: number) {
    const enrollments = this.studentAccountsArray.at(studentAccountIndex).get('enrollments') as FormArray;
    enrollments.removeAt(enrollmentIndex);
  }

  deleteStudentAccount(index: number) {
    this.studentAccountsArray.removeAt(index);
  }

  onSubmit() {
    this.studentAccountsForm.markAsPristine();
    this.resetErrorMessagesAndSuccessStates();

    if (this.studentAccountsForm.valid) {
      const studentAccounts = this.studentAccountsForm.get('studentAccounts').value;

      studentAccounts.forEach((studentAccount, index) => {
        const transformedAccount = this.transformStudentAccount(studentAccount);
        const studentAccountId = studentAccount.id; // Assuming 'id' field is part of the form

        const handleResponse = (response) => {
          const studentId = response.id[0].value;
          this.studentSuccessStates[index] = true;
          this.studentAccountsArray.at(index).patchValue({ id: studentId });
          this.createEnrollments(studentAccount.enrollments, studentId, response, index);
        };

        const handleError = (error) => {
          console.error('Error processing student account', error);
          this.studentErrorMessages[index] = 'Error processing student account';
          this.studentSuccessStates[index] = false;
          this.snackBar.open(`Error processing student account for student #${index + 1}`, 'Close', {
            duration: 3000,
          });
        };

        if (studentAccountId) {
          this._entityRESTService.patchEntity('student_accounts', 'student_account', studentAccountId, transformedAccount)
            .subscribe(handleResponse, handleError);
        } else {
          this._entityRESTService.createEntity('student_accounts', 'student_account', transformedAccount)
            .subscribe(handleResponse, handleError);
        }
      });
    } else {
      let errorMessages = this.showFormErrors(this.studentAccountsForm);
      let combinedErrorMessage = errorMessages.join(', ');
      this.snackBar.open(`Form is not valid: ${combinedErrorMessage}`, 'Close', { duration: 10000 });
    }
  }

  createEnrollments(enrollments, studentId, studentAccountResponse, studentAccountIndex) {
    const studentAccountFormGroup = this.studentAccountsArray.at(studentAccountIndex) as FormGroup;
    const enrollmentsArray = studentAccountFormGroup.get('enrollments') as FormArray;

    enrollments.forEach((enrollment, enrollmentIndex) => {
      const transformedEnrollment = this.transformEnrollment(enrollment, studentId);
      const enrollmentId = enrollment?.id;

      // Create the enrollment first
      const enrollmentObservable = enrollmentId ? this._entityRESTService.patchEntity('packages', 'enrollment', enrollmentId, transformedEnrollment) :
        this._entityRESTService.createEntity('packages', 'enrollment', transformedEnrollment);

      enrollmentObservable.subscribe(enrollmentResponse => {
        const enrollmentId = enrollmentResponse.id[0].value;
        this.enrollmentSuccessStates[`${studentAccountIndex}_${enrollmentIndex}`] = true;

        // Update the enrollment form group with the returned ID
        const enrollmentFormGroup = enrollmentsArray.at(enrollmentIndex) as FormGroup;
        enrollmentFormGroup.patchValue({ id: enrollmentId });

        // Create payments after the enrollment is created
        const paymentsObservable = from(enrollment.payments).pipe(
          concatMap((payment: any, paymentIndex) => {
            const transformedPayment = this.transformPayment(payment, enrollmentId, studentId);
            const paymentId = payment.id;

            const processPayment = paymentId
              ? this._entityRESTService.patchEntity('payments', 'payment', paymentId, transformedPayment)
              : this._entityRESTService.createEntity('payments', 'payment', transformedPayment);

            return processPayment.pipe(
              tap(paymentResponse => {
                const responsePaymentId = paymentResponse.id[0].value;
                const key = `${studentAccountIndex}_${enrollmentIndex}_${paymentIndex}`;
                this.paymentSuccessStates[key] = true;

                // Update the payment form group with the returned ID
                const paymentsArray = (enrollmentsArray.at(enrollmentIndex) as FormGroup).get('payments') as FormArray;
                const paymentFormGroup = paymentsArray.at(paymentIndex) as FormGroup;
                paymentFormGroup.patchValue({ id: responsePaymentId });

                this.changeDetectorRef.detectChanges(); // Trigger change detection
              }),
              catchError(error => {
                console.error('Error processing payment', error);
                const key = `${studentAccountIndex}_${enrollmentIndex}_${paymentIndex}`;
                this.paymentErrorMessages[key] = 'Error processing payment';
                this.paymentSuccessStates[key] = false;
                this.changeDetectorRef.detectChanges(); // Trigger change detection
                return of(null);
              })
            );
          })
        );

        // Create lessons after the payments are created
        const lessonsObservable = from(enrollment.lessons).pipe(
          concatMap((_, lessonIndex) => {
            // Access the specific lesson FormGroup
            const lessonFormGroup = this.getLessons(studentAccountIndex, enrollmentIndex).at(lessonIndex) as FormGroup;
            const attendeeId = lessonFormGroup.get('attendeeId').value;
            const transformedLesson = this.transformLesson(lessonFormGroup.value, enrollmentId, studentAccountResponse, attendeeId);

            const lessonId = lessonFormGroup.get('id').value;

            const lessonObservable = lessonId
              ? this._entityRESTService.patchEntity('events', 'lesson', lessonId, transformedLesson)
              : this._entityRESTService.createEntity('events', 'lesson', transformedLesson);

            return lessonObservable.pipe(
              retryWhen(this.retryStrategy({
                maxRetryAttempts: 10,
                scalingDuration: 2000
              })),
              concatMap(lessonResponse => {
                const newLessonId = lessonResponse?.id?.[0]?.value;
                const newAttendeeId = lessonResponse.field_student?.[0]?.target_id || null;

                // Update the form group with new IDs
                const lessonFormGroup = this.getLessons(studentAccountIndex, enrollmentIndex).at(lessonIndex) as FormGroup;
                lessonFormGroup.patchValue({ id: newLessonId, attendeeId: newAttendeeId });

                // Prepare parameters for posting the lesson
                let params = [
                  { parameter: 'selectedStatus[' + newAttendeeId + ']', value: 59 },
                  { parameter: 'dateTime', value: moment().format('YYYY-MM-DD[T]HH:mm:ss') },
                  { parameter: 'eventId', value: newLessonId },
                ];

                // Return a new observable for posting the lesson
                return this._drupalRESTService.httpGET('/api_rest/v1/updateStatus', params).pipe(
                  retryWhen(this.retryStrategy({
                    maxRetryAttempts: 10,
                    scalingDuration: 2000
                  }))
                );
              }),
              tap(data => {
                console.log('Lesson post response:', data);
                this.lessonSuccessStates[`${enrollmentIndex}_${lessonIndex}`] = true;
              }),
              catchError(error => {
                console.error('Error after retrying lesson update/creation or posting', error);
                this.lessonErrorMessages[`${enrollmentIndex}_${lessonIndex}`] = 'Error updating/creating or posting lesson';
                this.changeDetectorRef.detectChanges();
                return of(null);
              })
            );
          })
        );

        // Execute the payments and lessons observables in sequence
        paymentsObservable.pipe(
          toArray(), // Collect all payment emissions into a single array
          concatMap(() => lessonsObservable) // After all payments, process lessons
        ).subscribe({
          complete: () => console.log('All payments and lessons processed for this enrollment.')
        });
      }, error => {
        console.error('Error creating enrollment', error);
        this.enrollmentErrorMessages[enrollmentIndex] = 'Error creating enrollment';
        this.enrollmentSuccessStates[enrollmentIndex] = false;
      });
    });
  }

  retryStrategy({ maxRetryAttempts = 3, scalingDuration = 1000 }) {
    return (attempts) => attempts.pipe(
      mergeMap((error: HttpErrorResponse, i) => {
        const retryAttempt = i + 1;
        // Assuming you want to retry for specific HTTP status codes
        const retriableStatusCodes = [500, 503, 505];
        if (retryAttempt > maxRetryAttempts || !retriableStatusCodes.includes(error.status)) {
          return throwError(() => new Error(`Retry limit reached. Last error: ${error.message}`));
        }
        console.log(`Attempt ${retryAttempt}: retrying in ${retryAttempt * scalingDuration}ms`);
        return timer(retryAttempt * scalingDuration);
      })
    );
  }


  transformEnrollment(enrollment, studentId) {
    let scheduledPayments = [{
      id: null,
      field_amount_paid: null,
      field_enrollment: null,
      field_paid_in_full: true,
      field_down_payment: true,
      field_payment_amount: enrollment.field_enrollment_total_price,
      field_payment_date: moment(enrollment.field_sale_date).format('YYYY-MM-DD'),
      field_scheduled_payments: null,
      field_number_lesson_remaining: null,
      field_studio_reference: this._authService.studios?.[0]?.id,
      bundle: "scheduled_payments",
      type: "scheduled_payments"
    }];

    let instructorPercentages = [{
      id: null,
      field_amount: enrollment.field_enrollment_total_price,
      field_instructor: enrollment.field_instructor,
      field_percentage: 100,
      field_studio_reference: this._authService.studios?.[0]?.id,
      bundle: "instructor_percentages",
      type: "instructor_percentages"
    }];

    if (enrollment.field_expiration_date) {
      enrollment.field_expiration_date = moment(enrollment.field_expiration_date).format('YYYY-MM-DD');
    }
    if (enrollment.field_sale_date) {
      enrollment.field_sale_date = moment(enrollment.field_sale_date).format('YYYY-MM-DDTHH:mm:ss');
    }

    return {
      title: "default title",
      field_draft: false,
      field_student: studentId,
      field_sale_date: enrollment.field_sale_date,
      field_expiration_date: enrollment.field_expiration_date,
      field_category: enrollment.field_category,
      field_enrollment_package_name: enrollment.field_enrollment_package_name,
      field_enrollment_lesson_price: enrollment.field_enrollment_lesson_price,
      field_enrollment_lesson_count: enrollment.field_enrollment_lesson_count,
      field_total_lessons_price: enrollment.__total_lessons_price, // Assuming this comes from the form
      field_enrollment_total_price: enrollment.field_enrollment_total_price,
      field_taxes: 0, // Set this as needed
      field_tax_percentage: enrollment.field_tax_percentage,
      field_payments_structure: "paid_in_full", // Assuming default value
      field_payments_structure_type: null, // Set as needed
      field_executive: enrollment.field_executive,
      field_instructor: enrollment.field_instructor,
      field_junior_executive: null, // Set as needed
      field_scheduled_payments: scheduledPayments, // Assuming this is part of the form
      field_instructor_percentages: instructorPercentages, // Assuming this is part of the form
      field_discount: enrollment.field_discount,
      field_discount_type: enrollment.field_discount_type,
      field_enrollment_status: "Open", // Assuming default value
      field_next_scheduled_payment: null, // Set as needed
      field_visibility: false, // Assuming default value
      field_notes: enrollment.field_notes,
      field_24_hour_notification_sent: "1",
      field_studio_reference_target_id: this._authService.studios?.[0]?.id,
      field_studio_reference: this._authService.studios?.[0]?.id
    };
  }

  transformLesson(lesson, enrollmentId, studentAccountResponse, attendeeId = '') {
    // Extracting student contact IDs from the student account response
    const studentContactIds = studentAccountResponse.field_contacts.map(contact => contact.target_id);

    return {
      id: lesson.id || '',
      title: "Default Title",
      field_type: "548",
      field_instructor: lesson.field_instructor || '',
      field_duration: "00:45",
      field_date_and_time: moment(lesson.field_date_and_time).format('YYYY-MM-DDTHH:mm:ss'),
      field_student: [{
        id: attendeeId,
        type: "attendees",
        bundle: "attendance",
        field_student_account: studentAccountResponse.id[0].value,
        field_students: studentContactIds,
        field_enrollment: enrollmentId.toString(),
        field_description: "",
        field_status: "64",
        field_studio_reference_target_id: this._authService.studios?.[0]?.id,
        field_studio_reference: this._authService.studios?.[0]?.id
      }],
      field_instructor_alternate_list: [],
      field_use_alternating_instructor: false,
      field_repetition_units: "",
      field_repetition_frequency: null,
      field_expiration_date: null,
      field_is_confirmed: false,
      field_status: "64",
      field_studio_reference_target_id: this._authService.studios?.[0]?.id,
      field_studio_reference: this._authService.studios?.[0]?.id
    };
  }

  transformPayment(payment, enrollmentId, studentId) {
    return {
      title: "Default Title",
      field_payment_id: payment.id || '', // Assuming 'id' is a property in your payment object
      field_date_and_time: moment(payment.field_date_and_time).format('YYYY-MM-DDTHH:mm:ss'),
      field_gross_tuition: payment.field_gross_tuition.toString(),
      field_tax_collected: payment.field_tax_collected,
      field_student_name: studentId,
      // field_scheduled_payment: { target_id: enrollmentId },
      field_payment_type: payment.field_payment_type || '71', // Default value as Cash
      field_enrollment_name: [{ target_id: enrollmentId }],
      enrollment_ids: [{ target_id: enrollmentId }],
      idempotency_key: "payment_" + new Date().getTime(),
      field_studio_reference_target_id: this._authService.studios?.[0]?.id,
      field_studio_reference: this._authService.studios?.[0]?.id,
    };
  }

  transformStudentAccount(studentAccount) {
    let attendingLessonsType = studentAccount.contact2FirstName || studentAccount.contact2LastName || studentAccount.contact2Email || studentAccount.contact2Phone ? 'partner' : 'solo';

    let transformedAccount = {
      field_contacts: {
        "0": {
          type: "contacts",
          bundle: "student_record",
          field_first_name: studentAccount.contact1FirstName,
          field_last_name: studentAccount.contact1LastName,
          field_cell_phone: studentAccount.contact1Phone,
          field_email: studentAccount.contact1Email,
          field_attending_lessons: attendingLessonsType,
          field_gender: studentAccount.contact1Gender === 'male' ? "1" : (studentAccount.contact1Gender === 'female' ? "0" : "1"),
          field_address: {
            multiple_properties: null,
            address_line1: null,
            locality: null,
            administrative_area: null,
            postal_code: null,
            country_code: null
          },
          field_studio_reference: this._authService.studios?.[0]?.id,
        }
      },
      field_archive: "0",
      title: "Default Title"
    };

    // Check if Contact 2 data is provided and add it
    if (studentAccount.contact2FirstName || studentAccount.contact2LastName || studentAccount.contact2Email || studentAccount.contact2Phone) {
      transformedAccount.field_contacts["1"] = {
        type: "contacts",
        bundle: "student_record",
        field_first_name: studentAccount.contact2FirstName,
        field_last_name: studentAccount.contact2LastName,
        field_cell_phone: studentAccount.contact2Phone,
        field_email: studentAccount.contact2Email,
        field_attending_lessons: attendingLessonsType,
        field_gender: studentAccount.contact2Gender === 'male' ? "1" : (studentAccount.contact2Gender === 'female' ? "0" : "1"),
        field_address: {
          multiple_properties: null,
          address_line1: null,
          locality: null,
          administrative_area: null,
          postal_code: null,
          country_code: null
        },
        field_studio_reference: this._authService.studios?.[0]?.id,
      };
    }

    return transformedAccount;
  }

  createEnrollmentFormGroup(): FormGroup {
    const enrollmentFormGroup = this.fb.group({
      id: [''],
      field_student: [''],
      field_category: ['', Validators.required],
      field_sale_date: ['', Validators.required],
      field_enrollment_package_name: [''],
      field_enrollment: [''],
      field_enrollment_lesson_price: ['', Validators.required],
      field_enrollment_lesson_count: [0, Validators.required],
      field_enrollment_total_price: [0, Validators.required],
      field_discount_type: [''],
      field_discount: [0, Validators.required],
      field_tax_percentage: [0],
      field_expiration_date: ['', Validators.required],
      field_executive: ['', Validators.required],
      field_instructor: ['', Validators.required],
      field_notes: [''],
      lessons: this.fb.array([this.createLessonFormGroup()]),
      payments: this.fb.array([this.createPaymentFormGroup()]),
      __total_enrollment_price: [0],
      __total_lessons_enrolled: [0],
      __total_lessons_taken: [1],
      __field_packages: ['']
    });
    this.setupDiscountListeners(enrollmentFormGroup);

    return enrollmentFormGroup;
  }

  subscribeToDiscountChanges() {
    this.studentAccountsArray.controls.forEach((studentAccountFormGroup: FormGroup) => {
      const enrollments = studentAccountFormGroup.get('enrollments') as FormArray;
      enrollments.controls.forEach((enrollmentFormGroup: FormGroup) => {
        this.setupDiscountListeners(enrollmentFormGroup);
      });
    });
  }

  setupDiscountListeners(enrollmentFormGroup: FormGroup) {
    const discountTypeControl = enrollmentFormGroup.get('field_discount_type');
    const discountControl = enrollmentFormGroup.get('field_discount');

    if (discountControl) {
      discountControl.valueChanges.subscribe(newValue => {
        if (typeof newValue === 'string' && newValue.includes('%')) {
          // If the discount value includes '%', set the discount type to 'percentage'
          discountTypeControl.setValue('percentage');
        } else {
          // Otherwise, set the discount type to 'flat'
          discountTypeControl.setValue('flat');
        }
        this.recalculateValues(enrollmentFormGroup);
      });
    }

    if (discountTypeControl) {
      discountTypeControl.valueChanges.subscribe(() => {
        this.recalculateValues(enrollmentFormGroup);
      });
    }
  }


  recalculateValues(enrollmentFormGroup: FormGroup) {
    const discountType = enrollmentFormGroup.get('field_discount_type').value;
    const discountValue = enrollmentFormGroup.get('field_discount').value;
    let lessonPrice = parseFloat(enrollmentFormGroup.get('field_enrollment_lesson_price').value);
    let lessonCount = parseInt(enrollmentFormGroup.get('field_enrollment_lesson_count').value, 10);

    // Ensure lessonPrice is a number
    lessonPrice = isNaN(lessonPrice) ? 0 : lessonPrice;

    // Treat lessonCount as 1 if it is 0 for calculating
    lessonCount = lessonCount === 0 ? 1 : lessonCount;

    // Calculate total original price
    let totalOriginalPrice = lessonPrice * lessonCount;

    let discountedPrice = totalOriginalPrice;

    if (discountType === 'flat') {
      discountedPrice -= this.parseDiscountValue(discountValue);
    } else if (discountType === 'percentage') {
      const discountPercentage = this.parseDiscountValue(discountValue);
      discountedPrice -= (totalOriginalPrice * discountPercentage / 100);
    }

    // Ensure the discounted price doesn't go below zero
    discountedPrice = Math.max(discountedPrice, 0);

    // Update the form field with the new discounted price
    enrollmentFormGroup.patchValue({ field_enrollment_total_price: discountedPrice });
  }

  parseDiscountValue(discountValue) {
    if (typeof discountValue === 'string' && discountValue.includes('%')) {
      // Remove the '%' sign and parse as integer.
      return parseInt(discountValue.replace('%', ''), 10);
    } else {
      // Parse the value as integer.
      return parseInt(discountValue, 10);
    }
  }

  setupAutofillListeners() {
    this.studentAccountsArray.controls.forEach((studentAccount, studentAccountIndex) => {
      const enrollmentsArray = studentAccount.get('enrollments') as FormArray;
      enrollmentsArray.controls.forEach((enrollment, enrollmentIndex) => {
        this.setupAutofillListenersForEnrollment(enrollment as FormGroup, studentAccountIndex, enrollmentIndex);
      });
    });
  }

  setupAutofillListenersForEnrollment(enrollment: FormGroup, studentAccountIndex: number, enrollmentIndex: number) {
    const totalEnrollmentPriceField = enrollment.get('__total_enrollment_price');
    const totalLessonsEnrolledField = enrollment.get('__total_lessons_enrolled');
    const totalLessonsTakenField = enrollment.get('__total_lessons_taken');

    totalEnrollmentPriceField.valueChanges.subscribe(() => {
      this.autofillFields(studentAccountIndex, enrollmentIndex);
    });
    totalLessonsEnrolledField.valueChanges.subscribe(() => {
      this.autofillFields(studentAccountIndex, enrollmentIndex);
    });
    totalLessonsTakenField.valueChanges.subscribe(() => {
      this.autofillFields(studentAccountIndex, enrollmentIndex);
    });
  }

  autofillFields(studentAccountIndex: number, enrollmentIndex: number) {
    const enrollmentFormGroup = this.getEnrollmentsFormGroup(this.studentAccountsArray.at(studentAccountIndex)).at(enrollmentIndex) as FormGroup;

    const enrollmentTotalPrice = enrollmentFormGroup.get('__total_enrollment_price').value || 0;
    const totalLessonsEnrolled = enrollmentFormGroup.get('__total_lessons_enrolled').value || 0;
    const totalLessonsTaken = enrollmentFormGroup.get('__total_lessons_taken').value || 0;

    // Adjust totalLessonsEnrolled for calculating lesson price, but keep original count for other uses
    const adjustedLessonCountForPrice = totalLessonsEnrolled === 0 ? 1 : totalLessonsEnrolled;

    // Calculate lesson price using adjusted count
    const lessonPrice = enrollmentTotalPrice / adjustedLessonCountForPrice;

    // Get the instructor from the enrollment form group.
    const instructor = enrollmentFormGroup.get('field_instructor').value;

    enrollmentFormGroup.patchValue({
      field_enrollment_lesson_price: lessonPrice,
      field_enrollment_lesson_count: totalLessonsEnrolled,
      field_enrollment_total_price: enrollmentTotalPrice
    });

    console.log('Autofilled lesson price:', lessonPrice);
    console.log('Autofilled lesson count:', totalLessonsEnrolled);
    console.log('Autofilled total price:', enrollmentTotalPrice);

    // Adjusting lessons FormArray
    const lessonsArray = enrollmentFormGroup.get('lessons') as FormArray;

    // Get the sale date from the enrollment form group
    const field_sale_date = enrollmentFormGroup.get('field_sale_date').value;

    // Remove or add lesson form groups to match totalLessonsTaken
    while (lessonsArray.length > totalLessonsTaken) {
      lessonsArray.removeAt(lessonsArray.length - 1);
    }
    while (lessonsArray.length < totalLessonsTaken) {
      lessonsArray.push(this.createLessonFormGroup(null, instructor, null, field_sale_date));
    }

    // Recalculate discount and other values
    this.recalculateValues(enrollmentFormGroup);
  }

  updateStudentIdInForm(studentAccountIndex: number, studentId: string) {
    const studentAccountFormGroup = this.studentAccountsArray.at(studentAccountIndex) as FormGroup;
    const enrollmentsArray = studentAccountFormGroup.get('enrollments') as FormArray;

    enrollmentsArray.controls.forEach(enrollmentFormGroup => {
      enrollmentFormGroup.patchValue({
        field_student: studentId
      });

      const paymentsArray = enrollmentFormGroup.get('payments') as FormArray;
      paymentsArray.controls.forEach(paymentFormGroup => {
        paymentFormGroup.patchValue({
          field_student_name: studentId
        });
      });
    });
  }

  detectAndSetGender(account: FormGroup, genderControlName: string) {
    let firstName, lastName;

    if (genderControlName === 'contact1Gender') {
      firstName = account.get('contact1FirstName').value;
      lastName = account.get('contact1LastName').value;
    } else if (genderControlName === 'contact2Gender') {
      firstName = account.get('contact2FirstName').value;
      lastName = account.get('contact2LastName').value;
    }

    if (firstName && lastName) {
      this._genderDetectionService.getGender(firstName).subscribe(response => {
        account.get(genderControlName).setValue(response.gender);
      });
    }
  }

  setupAccountGenderDetection(account: FormGroup, index: number) {
    // Existing logic for contact1
    const firstNameControl1 = account.get('contact1FirstName');
    const lastNameControl1 = account.get('contact1LastName');
    this.setupGenderSubscription(firstNameControl1, lastNameControl1, account, 'contact1Gender', index);

    // Add logic for contact2
    const firstNameControl2 = account.get('contact2FirstName');
    const lastNameControl2 = account.get('contact2LastName');
    this.setupGenderSubscription(firstNameControl2, lastNameControl2, account, 'contact2Gender', index);
  }

  setupGenderSubscription(firstNameControl, lastNameControl, account, genderControlName, index) {
    if (firstNameControl && lastNameControl) {
      this.unsubscribeFromGenderDetection(index);

      this.firstNameSubscriptions[index] = firstNameControl.valueChanges.subscribe(() => {
        this.detectAndSetGender(account, genderControlName);
      });

      this.lastNameSubscriptions[index] = lastNameControl.valueChanges.subscribe(() => {
        this.detectAndSetGender(account, genderControlName);
      });
    }
  }


  setupGenderDetection() {
    this.studentAccountsArray.controls.forEach((account, index) => {
      this.setupAccountGenderDetection(account as FormGroup, index);
    });

    // Detect changes in the form array and setup gender detection for new form groups
    this.studentAccountsArray.valueChanges.subscribe(changes => {
      const lastIndex = this.studentAccountsArray.length - 1;
      const lastFormGroup = this.studentAccountsArray.at(lastIndex) as FormGroup;
      this.setupAccountGenderDetection(lastFormGroup, lastIndex);
    });
  }

  private unsubscribeFromGenderDetection(index: number) {
    if (this.firstNameSubscriptions[index]) {
      this.firstNameSubscriptions[index].unsubscribe();
    }
    if (this.lastNameSubscriptions[index]) {
      this.lastNameSubscriptions[index].unsubscribe();
    }
  }

  showFormErrors(formGroup: FormGroup, formPath = ''): string[] {
    let errorMessages = [];

    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      const currentPath = formPath ? `${formPath}.${field}` : field;

      if (control instanceof FormGroup) {
        errorMessages = [...errorMessages, ...this.showFormErrors(control, currentPath)];
      } else if (control instanceof FormArray) {
        control.controls.forEach((group, groupIndex) => {
          if (group instanceof FormGroup) {
            errorMessages = [...errorMessages, ...this.showFormErrors(group, `${currentPath}[${groupIndex}]`)];
          }
        });
      } else if (control.errors) {
        Object.keys(control.errors).forEach(keyError => {
          const errorMessage = `Error in '${currentPath}': ${keyError}`;
          errorMessages.push(errorMessage);
          console.log('Key control: ' + currentPath + ', keyError: ' + keyError + ', err value: ', control.errors[keyError]);
        });
      }
    });

    return errorMessages;
  }

  private mapping = {
    'DE': { optionText: 'DE', valueAttribute: 1395 },
    'EXT': { optionText: 'Extension', valueAttribute: 51 },
    'MISC': { optionText: 'Miscellaneous', valueAttribute: 5 },
    'Non Unit': { optionText: 'Non-Unit', valueAttribute: 66 },
    'ORI': { optionText: 'Original', valueAttribute: 50 },
    'PORI': { optionText: 'Pre-Original', valueAttribute: 49 },
    'REN': { optionText: 'Renewals', valueAttribute: 65 },
    'SUNDRY': { optionText: 'Sundry', valueAttribute: 67 }
  };

  mapPackageValue(input: string): { optionText: string, valueAttribute: number | null } {
    return this.mapping[input] || { optionText: 'Uncategorized', valueAttribute: 70 };
  }

  parsePrice(value: string): number {
    // Remove the $ symbol and parse the number
    const parsedValue = value.replace(/^\$/, '');
    return parseFloat(parsedValue);
  }

  removePayment(studentAccountIndex: number, enrollmentIndex: number, paymentIndex: number) {
    const enrollments = this.studentAccountsArray.at(studentAccountIndex).get('enrollments') as FormArray;
    const enrollment = enrollments.at(enrollmentIndex) as FormGroup;
    const payments = enrollment.get('payments') as FormArray;
    payments.removeAt(paymentIndex);
  }

  removeLesson(studentAccountIndex: number, enrollmentIndex: number, lessonIndex: number) {
    const enrollments = this.studentAccountsArray.at(studentAccountIndex).get('enrollments') as FormArray;
    const enrollment = enrollments.at(enrollmentIndex) as FormGroup;
    const lessons = enrollment.get('lessons') as FormArray;

    // Remove the specified lesson
    lessons.removeAt(lessonIndex);

    // Recalculate the total lessons taken
    const totalLessonsTaken = lessons.length;
    enrollment.patchValue({ __total_lessons_taken: totalLessonsTaken });
  }

  resetErrorMessagesAndSuccessStates() {
    this.studentErrorMessages = {};
    this.studentSuccessStates = {};
    this.enrollmentErrorMessages = {};
    this.enrollmentSuccessStates = {};
    this.paymentErrorMessages = {};
    this.paymentSuccessStates = {};
    this.lessonSuccessStates = {};
    this.lessonErrorMessages = {};
  }

  setupInstructorChangeListeners() {
    const studentAccountsArray = this.studentAccountsArray as FormArray;

    studentAccountsArray.controls.forEach((studentAccountFormGroup: FormGroup) => {
      const enrollments = studentAccountFormGroup.get('enrollments') as FormArray;

      enrollments.controls.forEach((enrollmentFormGroup: FormGroup) => {
        this.subscribeToInstructorChanges(enrollmentFormGroup);
        this.subscribeToEnrollmentDateChanges(enrollmentFormGroup);
      });
    });
  }

  // Method to subscribe to instructor changes for an enrollment
  subscribeToEnrollmentDateChanges(enrollmentFormGroup: FormGroup) {
    const saleDateControl = enrollmentFormGroup.get('field_sale_date');
    console.log('Subscribing to date changes');
    if (saleDateControl) {
      saleDateControl.valueChanges.subscribe(newSaleDateValue => {
        this.updateLessonsDates(enrollmentFormGroup, newSaleDateValue);
      });
    }
  }

  // Method to subscribe to instructor changes for an enrollment
  subscribeToInstructorChanges(enrollmentFormGroup: FormGroup) {
    const instructorControl = enrollmentFormGroup.get('field_instructor');

    if (instructorControl) {
      instructorControl.valueChanges.subscribe(newInstructorValue => {
        this.updateLessonsInstructors(enrollmentFormGroup, newInstructorValue);
      });
    }
  }

  // Method to update all lessons' instructors within an enrollment
  updateLessonsDates(enrollmentFormGroup: FormGroup, newSaleDateValue: string) {
    const lessons = enrollmentFormGroup.get('lessons') as FormArray;
    console.log('Updating lesson dates');

    lessons.controls.forEach((lessonFormGroup: FormGroup) => {
      lessonFormGroup.get('field_date_and_time').setValue(moment(newSaleDateValue).format('YYYY-MM-DD[T]12:00:00'), { emitEvent: false });
    });
  }

  // Method to update all lessons' instructors within an enrollment
  updateLessonsInstructors(enrollmentFormGroup: FormGroup, newInstructorValue: string) {
    const lessons = enrollmentFormGroup.get('lessons') as FormArray;

    lessons.controls.forEach((lessonFormGroup: FormGroup) => {
      lessonFormGroup.get('field_instructor').setValue(newInstructorValue, { emitEvent: false });
    });
  }

  generateKey(i: number, j: number, lessonIndex: number): string {
    return `${i}_${j}_${lessonIndex}`;
  }

  updateLessonSuccessState(enrollmentIndex: number, lessonIndex: number, state: boolean) {
    const key = `${enrollmentIndex}_${lessonIndex}`;
    this.lessonSuccessStates = {
      ...this.lessonSuccessStates,
      [key]: state
    };
    this.changeDetectorRef.detectChanges(); // Trigger change detection
  }

  calculateOriginalEnrollmentPrice(enrollment: FormGroup): number {
    const discountType = enrollment.get('field_discount_type').value;
    const discountValue = enrollment.get('field_discount').value;
    const discountedPrice = enrollment.get('field_enrollment_total_price').value;

    if (discountType === 'flat') {
      return discountedPrice + this.parseDiscountValue(discountValue);
    } else if (discountType === 'percentage') {
      return discountedPrice / (1 - (this.parseDiscountValue(discountValue) / 100));
    }
    return discountedPrice;
  }

  calculateOriginalPricePerLesson(enrollment: FormGroup): number {
    const originalEnrollmentPrice = this.calculateOriginalEnrollmentPrice(enrollment);
    const lessonCount = enrollment.get('field_enrollment_lesson_count').value;
    return originalEnrollmentPrice / lessonCount;
  }

  autofillLessonDates(enrollmentFormGroup: FormGroup) {
    console.log('Autofilling lesson dates')
    const saleDateControl = enrollmentFormGroup.get('field_sale_date');
    const lessonsArray = enrollmentFormGroup.get('lessons') as FormArray;

    if (saleDateControl && lessonsArray) {
      const saleDate = moment(saleDateControl.value);

      lessonsArray.controls.forEach((lessonFormGroup: FormGroup) => {
        lessonFormGroup.get('field_date_and_time').setValue(saleDate.format('YYYY-MM-DD[T]12:00:00'));
      });
    }
  }

  generatePaymentKey(studentAccountIndex: number, enrollmentIndex: number, paymentIndex: number): string {
    return `${studentAccountIndex}_${enrollmentIndex}_${paymentIndex}`;
  }
}
