<app-entity-mat-dialog-title title="Miscellaneous Service Deduction"></app-entity-mat-dialog-title>

<div [ngClass]="{
        'loading-shade': showProgressSpinner
     }">

    <div mat-dialog-content>

        <mat-progress-spinner class="matProgressSpinnerCentered" *ngIf="showProgressSpinner" mode="indeterminate"></mat-progress-spinner>

        <mat-tab-group class="miscServiceDeductionsFormGroupTabs" [(selectedIndex)]="this.selectedIndex" (selectedTabChange)="setFormMode($event.index)" animationDuration="0ms" #tabs>

            <mat-tab label="View" [disabled]="this.initActionType == 'create'">
                <app-entity-mat-tab-view [entityData]="entityData"></app-entity-mat-tab-view>
            </mat-tab>

            <mat-tab label="{{
                (this.initActionType == 'create' ? 'Create'
                : 'Edit')}}">
                <app-error-message [message]=this.errorMessage></app-error-message>

                <form [formGroup]="miscServiceDeductionsFormGroup" (ngSubmit)="onSubmitMiscDeduction(f)" #f="ngForm" class="form">
                    <div class="container">
                        <div class="row">
                            <!-- Event Name -->
                            <div class="col-12">
                                    <mat-form-field class="full-width-field">
                                            <mat-label>Event</mat-label>
                                            <input type="text" formControlName="title" name="title" matInput>
                                    </mat-form-field>
                            </div>

                            <!-- Event Expenses -->
                            <div class="col-12">
                                <mat-form-field class="full-width-field">
                                    <mat-label>Event Expenses</mat-label>
                                    <input type="number" formControlName="field_event_expenses" name="field_event_expenses" matInput>
                                </mat-form-field>
                            </div>

                            <!-- Transportation Cost -->
                            <div class="col-12">
                                <mat-form-field class="full-width-field">
                                    <mat-label>Transportation Cost</mat-label>
                                    <input type="number" formControlName="field_transportation_cost" name="field_transportation_cost" matInput>
                                </mat-form-field>
                            </div>

                            <p>
                                <li>
                                    Week #: <input type="text" formControlName="field_week_report_number" name="field_week_report_number" matInput>
                                </li>
                                <li>
                                    Year: <input type="text" formControlName="field_year_report_number" name="field_year_report_number" matInput>
                                </li>
                            </p>

                            <!-- Week Report Number -->
                            <div class="col-12 d-none">
                                    <mat-form-field class="full-width-field">
                                            <mat-label>Week Report Number</mat-label>
                                            <input type="text" formControlName="field_week_report_number" name="field_week_report_number" matInput>
                                    </mat-form-field>
                            </div>

                            <!-- Year Report Number -->
                            <div class="col-12 d-none">
                                    <mat-form-field class="full-width-field">
                                            <mat-label>Year Report Number</mat-label>
                                            <input type="text" formControlName="field_year_report_number" name="field_year_report_number" matInput>
                                    </mat-form-field>
                            </div>
                        </div>
                    </div>
                </form>
            </mat-tab>

            <mat-tab label="Delete" [disabled]="this.initActionType == 'create'" *ngIf="this.actionType != 'create'">
                <app-entity-confirm-delete></app-entity-confirm-delete>
            </mat-tab>

        </mat-tab-group>

    </div>

</div>

<mat-dialog-actions align="end">
    <ng-container *ngIf="this.actionType === 'create' && miscServiceDeductionsFormGroup">
        <button [disabled]="!miscServiceDeductionsFormGroup.valid" type="button" (click)="onSubmitMiscDeduction(f);" mat-raised-button color="accent">Save Deduction</button>
    </ng-container>
    <ng-container *ngIf="this.actionType === 'edit' || this.actionType === 'refund'">
        <ng-container *ngIf="this.initActionType == 'edit'">
            <button type="button" (click)="setFormMode('delete')" mat-raised-button color="accent">Delete</button>
            <button type="button" (click)="onSubmit(f);" mat-raised-button color="accent">Save Deduction</button>
        </ng-container>
        <ng-container *ngIf="this.initActionType == 'create'">
            <button type="button" (click)="closeDialog()" mat-raised-button color="accent">Cancel</button>
            <button type="button" (click)="onSubmit(f);" mat-raised-button color="accent">Save Deduction</button>
        </ng-container>
    </ng-container>
    <ng-container *ngIf="this.actionType === 'delete'">
        <button (click)="setFormMode('edit')" type="submit" mat-raised-button color="accent">Cancel</button>
        <button (click)="onDeleteSubmit(this._injectedDialogData['data']['EntityID'])" type="submit" mat-raised-button
            color="accent">Delete</button>
    </ng-container>
</mat-dialog-actions>
