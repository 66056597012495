import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { EntityRESTService } from 'src/app/services/entity-rest.service';
import { UserService } from 'src/app/services/user.service';
import { DrupalRESTService } from 'src/app/services/drupal-rest.service';
import { debounceTime, distinctUntilChanged, filter } from 'rxjs/operators';
import { FieldsService } from 'src/app/services/fields.service';
import { PermissionsService } from 'src/app/services/permissions.service';
import { MatButtonToggleChange } from '@angular/material/button-toggle';

@Component({
  selector: 'app-frm-manage-staff',
  templateUrl: './frm-manage-staff.component.html',
  styleUrls: ['./frm-manage-staff.component.css']
})
export class FrmManageStaffComponent implements OnInit {

  constructor(
    @Inject(MAT_DIALOG_DATA) public _injectedDialogData: any,
    public _entityRESTService: EntityRESTService,
    public _userRESTService: UserService,
    private dialogRef: MatDialogRef<FrmManageStaffComponent>,
    private _drupalRESTService: DrupalRESTService,
    private _fieldsService: FieldsService,
    public _permissionsService: PermissionsService,
  ) { }

  myForm: FormGroup;
  errorMessage: string;
  successMessage: string;
  eckType = "user";
  bundle = "user";
  entityId = this._injectedDialogData?.['data']?.uid;
  showProgressSpinner: boolean = false;
  actionType: string;
  defaultTime: string = '00:00:00';
  formIsPatching: boolean = false; // Initialize the formIsPatching flag
  availableRoles: string[] = ['Executive', 'Instructor'];
  priorEmployment: boolean = false;
  previousSchools: any[] = [];
  selectedSchools: string[] = [];
  hasPassword: boolean = false;

  ngOnInit(): void {
    // this.showProgressSpinner = true;
    console.log(this._injectedDialogData?.['data']);
    this.eckType = "user";
    this.bundle = "user";
    this.entityId = this._injectedDialogData?.['data']?.uid;

    this.myForm = new FormGroup({
      uid: new FormControl(''),
      mail: new FormControl('', [Validators.required, Validators.email]),
      field_last_name: new FormControl('', Validators.required),
      field_first_name: new FormControl('', Validators.required),
      name: new FormControl('', Validators.required),
      pass: new FormControl('', this.actionType === 'create' ? [] : []),
      confirmPassword: new FormControl(''),
      field_ami_id: new FormControl(''),
      field_birthday: new FormControl(''),
      field_hiring_date: new FormControl(''),
      field_cell_number: new FormControl(''),
      field_studios_data: new FormControl(''),
      field_prior_studio_employment: new FormControl({ value: false, disabled: true }),
      roles: new FormControl(['Executive', 'Instructor']),
      status: new FormControl(true),
    }, { validators: this.passwordsMatchingValidator });

    this.initializeDialogData();

    let previousFirstName = '';
    let previousLastName = '';

    // Subscribe to form value changes with conditions
    this.myForm.valueChanges.pipe(
      debounceTime(1000), // Wait for 1 second of inactivity
      distinctUntilChanged(), // Only emit if the value has changed
      filter(() => {
        const firstName = this.myForm.get('field_first_name').value;
        const lastName = this.myForm.get('field_last_name').value;
        const hasChanged = firstName !== previousFirstName || lastName !== previousLastName;
        previousFirstName = firstName;
        previousLastName = lastName;
        return hasChanged && firstName && lastName;
      })
    ).subscribe(() => {
      // this.OR_lookupStaff();
    });

    // Subscribe to priorEmployment changes
    this.myForm.get('field_prior_studio_employment').valueChanges.subscribe(value => {
      const firstName = this.myForm.get('field_first_name').value;
      const lastName = this.myForm.get('field_last_name').value;

      if (value == true && firstName && lastName) {
        this.searchPreviousEmployment();
      } else {
        this.previousSchools = [];
        this.selectedSchools = [];
        this.myForm.get('field_studios_data').setValue('');
        this.myForm.get('field_ami_id').setValue('');
      }
    });

    this.myForm.get('field_first_name').valueChanges.subscribe(() => {
      this.checkNameFieldsAndTogglePriorEmployment();
    });

    this.myForm.get('field_last_name').valueChanges.subscribe(() => {
      this.checkNameFieldsAndTogglePriorEmployment();
    });
  }

  checkNameFieldsAndTogglePriorEmployment() {
    const firstName = this.myForm.get('field_first_name').value;
    const lastName = this.myForm.get('field_last_name').value;

    if (firstName && lastName) {
      this.myForm.get('field_prior_studio_employment').enable();
    } else {
      this.myForm.get('field_prior_studio_employment').disable();
      this.myForm.get('field_prior_studio_employment').setValue(false); // Reset to No if disabled
    }
  }

  initializeDialogData() {
    const dialogData = this._injectedDialogData?.data;
    if (dialogData) {
      if (dialogData.action) {
        this.actionType = dialogData.action;
      }
      if (dialogData.uid && this.actionType === 'edit') {
        this.initializeForEdit(dialogData.uid);
      }
    }
  }

  onSubmit() {
    if (this.myForm.valid) {
      // Convert status to Active or Blocked.
      this.myForm.get('status').setValue(this.myForm.get('status').value ? 'Active' : 'Blocked');

      this._userRESTService.createUser(this.myForm.value).subscribe(
        response => {
          this.myForm.reset();
          this.dialogRef.close();

          // Refresh instructors and executives
          this._fieldsService.fetchAndSetInstructorData().then(data => {
          }).catch(error => {
          });
        },
        error => {
          if (error.error && error.error.message) {
            this.errorMessage = error.error.message;
          } else {
            this.errorMessage = "Failed to create user due to an unknown error.";
          }
        }
      );
    }
  }

  initializeForEdit(userId: number) {
    this._entityRESTService.getEntity(this.eckType, this.bundle, userId).subscribe(
      response => {
        this.hasPassword = !!response['pass'];
        // Remove the password field from the response.
        delete response?.['pass'];
        // Convert 'status' to boolean.
        response['status'] = response['status'] === '1' ? true : false;
        // Ensure roles is an array.
        response['roles'] = Array.isArray(response['roles']) ? response['roles'] : [response['roles']];
        // Capitalize the roles for display in the template
        response['roles'] = response['roles'].map((role: string) => role.charAt(0).toUpperCase() + role.slice(1));

        this.myForm.patchValue(response);
        this.myForm.patchValue({ field_ami_id: response['field_ami_id'].toString() });

      },
      error => {
        this.errorMessage = "Failed to load user: " + error.message; // Set error message
      }
    );
  }

  onUpdate() {
    if (this.myForm.valid) {
      const userId = this.myForm.get('uid').value;
      const updateData = this.myForm.value;

      if (!updateData.pass) {
        delete updateData.pass;
      }

      this._userRESTService.updateUser(userId, updateData).subscribe(
        response => {
          // Handle successful update
          this.successMessage = response.message; // Set success message from response
          // Optionally, you can reset the form or close the dialog here
          // this.myForm.reset();
          // this.dialogRef.close();

          // Refresh instructors and executives
          this._fieldsService.fetchAndSetInstructorData().then(data => {
          }).catch(error => {
          });
        },
        error => {
          this.errorMessage = "Failed to update user: " + error.message; // Set error message
        }
      );
    }
  }

  onDelete(userId: number) {
    this._userRESTService.deleteUser(userId).subscribe(
      response => {
        // Handle successful deletion
      },
      error => {
        this.errorMessage = "Failed to delete user: " + error.message; // Set error message
      }
    );
  }

  OR_lookupStaff() {
    // this.showProgressSpinner = true;
    this.errorMessage = ''; // Clear any previous error message

    // Extract first name and last name from the form
    const firstName = this.myForm.get('field_first_name').value;
    const lastName = this.myForm.get('field_last_name').value;

    // Check if both first name and last name are provided
    if (!firstName || !lastName) {
      // Optionally, set an error message if needed
      this.errorMessage = 'Please enter both first name and last name to search.';
      return;
    }

    // If an existing field_ami_id is present, don't search for staff.
    if (this.myForm.get('field_ami_id').value) {
      console.log('AMI ID already present. Skipping search.');
      return;
    }

    // Prepare parameters for the GET request
    const params = [
      { parameter: 'field_first_name', value: firstName },
      { parameter: 'field_last_name', value: lastName }
    ];

    this._drupalRESTService.httpGET('/api_rest/v1/OR_lookupStaff', params).subscribe(
      (response: any[]) => { // Specify the type as an array of any objects
        this.showProgressSpinner = false;

        // Handle successful response
        if (response && response.length > 0) {
          const staffData = response[0];

          this.formIsPatching = true; // Disable value changes subscription
          // Update the form with the returned data
          const patchValue: any = {
            field_ami_id: staffData.studios?.[0]?.id || '',
          };

          // Only patch field_cell_number if it's not already filled out
          if (!this.myForm.get('field_cell_number').value) {
            // Do not patch phone number.
            // patchValue.field_cell_number = staffData.studios?.[0]?.phone || '';
          }

          // Only patch mail if it's not already filled out
          if (!this.myForm.get('mail').value) {
            patchValue.mail = staffData.email || '';
          }

          this.myForm.patchValue(patchValue);
          this.formIsPatching = false; // Re-enable value changes subscription
        } else {
          // this.errorMessage = "No staff found with the given details.";
          // Clear out AMI ID and cell number if no staff found.
          this.myForm.patchValue({
            field_ami_id: '',
            field_cell_number: '',
          });
        }
      },
      error => {
        this.showProgressSpinner = false;
        this.errorMessage = "Failed to search for staff: " + error.message; // Set error message
      }
    );
  }

  passwordsMatchingValidator(formGroup: FormGroup): { [s: string]: boolean } | null {
    const password = formGroup.get('pass').value;
    const confirmPassword = formGroup.get('confirmPassword').value;
    if (password !== confirmPassword) {
      return { 'passwordMismatch': true };
    }
    return null;
  }

  searchPreviousEmployment() {
    const firstName = this.myForm.get('field_first_name').value;
    const lastName = this.myForm.get('field_last_name').value;
    const field_ami_id = this.myForm.get('field_ami_id').value;

    // Ensure the field_prior_studio_employment is true before proceeding
    const priorEmployment = this.myForm.get('field_prior_studio_employment').value;

    if (!priorEmployment) {
      return; // Exit early if prior employment is not set to true
    }

    if (!firstName || !lastName) {
      this.errorMessage = 'Please enter both first name and last name to search for prior employment.';
      return;
    }

    this.showProgressSpinner = true;
    const params = [
      { parameter: 'field_first_name', value: firstName },
      { parameter: 'field_last_name', value: lastName }
    ];

    this._drupalRESTService.httpGET('/api_rest/v1/OR_lookupStaff', params).subscribe(
      (response: any[]) => {
        this.showProgressSpinner = false;
        if (response && response.length > 0 && response[0].studios) {
          // Store the school details
          this.previousSchools = response[0].studios.map(studio => ({
            name: studio.name,
            city: studio.city,
            state: studio.state,
            phone: studio.phone,
            website: studio.website
          }));

          // Set the field_ami_id on the form.
          this.myForm.get('field_ami_id').setValue(response[0].studios[0].id);

          const schoolNames = response[0].studios.map(studio => studio.name);
          this.previousSchools = schoolNames.join(', ');

          if (this.previousSchools.length === 0) {
            this.errorMessage = "No previous employment found for this staff member.";
          }
        } else {
          this.previousSchools = [];
          this.errorMessage = "No previous employment found for this staff member.";
        }
      },
      error => {
        this.showProgressSpinner = false;
        this.errorMessage = "Failed to search for previous employment: " + error.message;
      }
    );
  }

  toggleSchoolSelection(schoolId: string) {
    const index = this.selectedSchools.indexOf(schoolId);
    if (index > -1) {
      this.selectedSchools.splice(index, 1);
    } else {
      this.selectedSchools.push(schoolId);
    }
    this.updatePreviousEmploymentField();
  }

  updatePreviousEmploymentField() {
    const selectedSchoolNames = this.previousSchools
      .filter(school => this.selectedSchools.includes(school.id))
      .map(school => `${school.name} (${school.city}, ${school.state})`)
      .join(', ');
    this.myForm.get('field_studios_data').setValue(selectedSchoolNames);
  }

}
