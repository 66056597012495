<div class="container-fluid">
  <h1 class="page-header">Payments Due</h1>

  <mat-expansion-panel>
    <mat-expansion-panel-header>
      <mat-panel-title>
        Filter
      </mat-panel-title>
      <mat-panel-description>
        <app-filter-results-panel [parameters]=queryParams></app-filter-results-panel>
      </mat-panel-description>
    </mat-expansion-panel-header>
    <form (ngSubmit)="onSubmit(f)" #f=ngForm class="form">
      <div class="row">

        <!-- Date Range Filter -->
        <div class="col-4">
          <mat-form-field>
            <mat-label>Enter a date range</mat-label>
            <mat-date-range-input [rangePicker]="picker">
              <input name="start_date" (dateChange)="onSubmit(f)" ngModel matStartDate placeholder="Start date">
              <input name="end_date" (dateChange)="onSubmit(f)" ngModel matEndDate placeholder="End date">
            </mat-date-range-input>
            <!-- <mat-hint>MM/DD/YYYY – MM/DD/YYYY</mat-hint> -->
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-date-range-picker #picker></mat-date-range-picker>
          </mat-form-field>
        </div>

        <div class="col-4">
          <mat-form-field class="full-width-field">
              <mat-label>Student Name</mat-label>
              <input
                  name="field_student_target_id"
                  (input)="onAutocompleteStudentAccount($event)"
                  matInput
                  ngModel
                  [matAutocomplete]="studentautocomplete"
                  >
              <button type="button" matSuffix mat-icon-button
                  aria-label="manage_accounts">
                  <mat-icon>manage_accounts</mat-icon>
              </button>
          </mat-form-field>
          <mat-autocomplete
              #studentautocomplete="matAutocomplete"
              (optionSelected)="onSubmit(f)">
              <mat-option *ngFor="let option of autoCompleteStudentAccountOptions"
                  [value]="option.value">
                  {{option.label}} ({{option.value}})
              </mat-option>
          </mat-autocomplete>
      </div>

      <div class="col-4">
        <mat-form-field class="full-width-field">
            <mat-label>Instructor</mat-label>
            <mat-select
            matInput
            ngModel
            name="field_instructor_target_id"
            (selectionChange)="onSubmit(f)">
                <mat-option>None</mat-option>
                <mat-option
                    *ngFor="let element of this._fieldsService.instructorData()"
                    [value]="element.uid">
                    {{ element.value }}</mat-option>
            </mat-select>
        </mat-form-field>
    </div>

        <!-- Enrollment Autocomplete -->
        <!-- <div class="col-4">
          <mat-form-field class="full-width-field">
            <mat-label>Enrollment</mat-label>
            <input name="enrollment" (input)="onEnrollmentAutocomplete($event)" matInput ngModel
              [matAutocomplete]="autoCompleteEnrollment">
            <button type="button" matSuffix mat-icon-button aria-label="manage_accounts">
              <mat-icon>manage_accounts</mat-icon>
            </button>
          </mat-form-field>
          <mat-autocomplete #autoCompleteEnrollment="matAutocomplete" (optionSelected)="onSubmit(f)">
            <mat-option *ngFor="let option of autoCompleteEnrollmentOptions" [value]="option.label + ' ' + '(' + option.value + ')'">
              {{option.label}} ({{option.value}})
            </mat-option>
          </mat-autocomplete>
        </div> -->

        <section class="section">
          <div class="float-end bring-inline">
            <button (click)="resetForm()" color="primary" mat-flat-button class="me-1" mat-button>Clear
              <!-- <mat-icon (click)="resetForm()" aria-hidden="false" aria-label="Menu dropdown" class=""></mat-icon> -->
            </button>
          </div>

        </section>

      </div>

      <section class="section">
      </section>

    </form>

  </mat-expansion-panel>

  <mat-progress-bar *ngIf="ShowProgressBar" mode="indeterminate"></mat-progress-bar>
  <div class="row">
    <div class="col-md-12 mt-3">
      <div class="float-end">
        <button (click)="onPrint()" color="primary" mat-flat-button class="me-1" mat-button>Print</button>
        <button (click)="downloadCSVDrupalViews('/api/export/payments_due', 'payments_due', queryParams)"
                [disabled]="isLoading"
                color="primary"
                mat-flat-button
                class="me-1 warning mat-icon-animate-spin"
                mat-button>
            <mat-icon *ngIf="isLoading">autorenew</mat-icon>
            {{ isLoading ? 'Exporting...' : 'Export' }}
        </button>
      </div>
    </div>
  </div>

  <div class="table-container">

    <table mat-table #displayTable [dataSource]="dataSource" matSort (matSortChange)="sortDataView($event)" class="mt-4">

      <!-- id Column -->
      <!-- <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef sortActionDescription="Sort by id">
          ID
        </th>
        <td mat-cell *matCellDef="let element"> {{element.id}} </td>
      </ng-container> -->

      <!-- field_payment_date_value Column -->
      <ng-container matColumnDef="field_payment_date_value">
        <th mat-header-cell mat-sort-header *matHeaderCellDef sortActionDescription="Sort by field_payment_date_value">
          Due Date
          <!-- Next Scheduled Payment -->
        </th>
        <td mat-cell *matCellDef="let element"> {{ element.field_payment_date | date }} </td>
      </ng-container>

      <!-- field_student_target_id Column -->
      <ng-container matColumnDef="field_student_target_id">
        <th mat-sort-header mat-header-cell *matHeaderCellDef sortActionDescription="Sort by field_student_target_id">
          Student
        </th>
        <td mat-cell *matCellDef="let element"> {{ element.title_2 }} </td>
      </ng-container>

      <!-- title Column -->
      <ng-container matColumnDef="title">
        <th mat-sort-header mat-header-cell *matHeaderCellDef sortActionDescription="Sort by title">
          Enrollment
        </th>
        <td mat-cell *matCellDef="let element"> {{ element.enrollment_title }} </td>
      </ng-container>

      <!-- field_payment_amount Column -->
      <ng-container matColumnDef="field_payment_amount_value">
        <th mat-sort-header mat-header-cell *matHeaderCellDef sortActionDescription="Sort by field_payment_amount_value">
          Amount Due<!-- Amount Due Payment Amount -->
        </th>
        <td mat-cell *matCellDef="let element">
          ${{ element.field_payment_amount }}
        </td>
      </ng-container>

      <!-- field_payment_interval Column -->
      <ng-container matColumnDef="field_payment_interval">
        <th mat-sort-header mat-header-cell *matHeaderCellDef sortActionDescription="Sort by field_payment_interval">
          Payment Interval
        </th>
        <td mat-cell *matCellDef="let element"> {{ element.field_payment_interval }} </td>
      </ng-container>

      <!-- balance_due Column -->
      <ng-container matColumnDef="field_balance_due_value">
        <th mat-sort-header mat-header-cell *matHeaderCellDef sortActionDescription="Sort by field_balance_due_value">
          Remaining Balance
        </th>
        <td mat-cell *matCellDef="let element"> ${{ element.field_balance_due }} </td>
      </ng-container>

      <!-- field_total_paid Column -->
      <ng-container matColumnDef="field_total_paid">
        <th mat-sort-header mat-header-cell *matHeaderCellDef sortActionDescription="Sort by field_total_paid">
          Total Paid
        </th>
        <td mat-cell *matCellDef="let element"> ${{ element.field_amount_paid ? element.field_amount_paid : '0' }} </td>
      </ng-container>

      <!-- field_lesson_available Column -->
      <ng-container matColumnDef="field_lesson_available_value">
        <th mat-sort-header mat-header-cell *matHeaderCellDef sortActionDescription="Sort by field_lesson_available_value">
          Lessons Remaining
        </th>
        <td mat-cell *matCellDef="let element"> {{ element.field_lesson_available }} </td>
      </ng-container>

      <!-- field_enrollment_lesson_count Column -->
      <ng-container matColumnDef="field_enrollment_lesson_count">
        <th mat-sort-header mat-header-cell *matHeaderCellDef sortActionDescription="Sort by field_enrollment_lesson_count">
          Enrollment Lesson Count
        </th>
        <td mat-cell *matCellDef="let element"> {{ element.field_enrollment_lesson_count }} </td>
      </ng-container>

      <!-- field_enrollment_lesson_price Column -->
      <ng-container matColumnDef="field_enrollment_lesson_price">
        <th mat-sort-header mat-header-cell *matHeaderCellDef sortActionDescription="Sort by field_enrollment_lesson_price">
          Enrollment Lesson Price
        </th>
        <td mat-cell *matCellDef="let element"> {{ element.field_enrollment_lesson_price }} </td>
      </ng-container>

      <!-- field_lesson_used Column -->
      <ng-container matColumnDef="field_lesson_used">
        <th mat-sort-header mat-header-cell *matHeaderCellDef sortActionDescription="Sort by field_lesson_used">
          Lesson Used
        </th>
        <td mat-cell *matCellDef="let element"> {{ element.field_lesson_used }} </td>
      </ng-container>

      <!-- field_lessons_paid Column -->
      <ng-container matColumnDef="field_lessons_paid">
        <th mat-sort-header mat-header-cell *matHeaderCellDef sortActionDescription="Sort by field_lessons_paid">
          Lessons Paid
        </th>
        <td mat-cell *matCellDef="let element"> {{ element.field_lessons_paid }} </td>
      </ng-container>

      <!-- field_instructor_label Column -->
      <ng-container matColumnDef="field_instructor_target_id">
        <th mat-sort-header mat-header-cell *matHeaderCellDef sortActionDescription="Sort by field_instructor_target_id">
          Teacher
        </th>
        <td mat-cell *matCellDef="let element"> {{ element.field_first_name }} {{ element.field_last_name }} </td>
      </ng-container>

      <!-- field_lesson_type_label Column -->
      <ng-container matColumnDef="field_lesson_type_label">
        <th mat-sort-header mat-header-cell *matHeaderCellDef sortActionDescription="Sort by field_lesson_type_label">
          Lesson Type
        </th>
        <td mat-cell *matCellDef="let element"> {{ element.field_lesson_type_label }} </td>
      </ng-container>

      <!-- field_sps_code_label Column -->
      <ng-container matColumnDef="field_sps_code_label">
        <th mat-sort-header mat-header-cell *matHeaderCellDef sortActionDescription="Sort by field_sps_code_label">
          SPS Code
        </th>
        <td mat-cell *matCellDef="let element"> {{ element.field_sps_code_label }} </td>
      </ng-container>

      <!-- REPLACE Column -->
      <!-- <ng-container matColumnDef="REPLACE">
        <th mat-header-cell *matHeaderCellDef sortActionDescription="Sort by REPLACE">
          REPLACE
        </th>
        <td mat-cell *matCellDef="let element"> {{ element.REPLACE }} </td>
      </ng-container> -->

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row matRipple *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>

    <mat-paginator (page)="pageChanged($event)" [pageSizeOptions]="onPageSizeOptions()" [length]="30"
      [disabled]="ShowProgressBar"
      [pageSize]="paginationTotalPages" [pageIndex]="paginationIndex" aria-label="Select page of view"></mat-paginator>

  </div>
</div>
