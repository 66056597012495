import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-autocomplete',
  templateUrl: './autocomplete.component.html',
  styleUrls: ['./autocomplete.component.css']
})
export class AutocompleteComponent implements OnInit {

  @Input() Label: string;
  @Input() Name: string = "empty";
  @Input() MatIcon: string;
  @Input() Endpoint: string;
  @Input() autoCompleteOptions: {}[];

  constructor() { }

  ngOnInit(): void {
  }

  onSubmit($event) {

  }

}
