import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs';
import { PermissionsService } from 'src/app/services/permissions.service';

@Component({
  selector: 'app-configuration-dashboard-menu',
  templateUrl: './configuration-dashboard-menu.component.html',
  styleUrls: ['./configuration-dashboard-menu.component.css']
})
export class ConfigurationDashboardMenuComponent implements OnInit {
  showSimpleImport: boolean = false;

  constructor(private router: Router, public permissionsService: PermissionsService) {
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe(() => {
      this.checkSimpleImportRoutes();
    });
  }

  ngOnInit(): void {
    this.checkSimpleImportRoutes();
  }

  toggleSimpleImport() {
    this.showSimpleImport = !this.showSimpleImport;
  }

  checkSimpleImportRoutes() {
    const currentUrl = this.router.url;
    const simpleImportRoutes = [
      '/configuration/simple-import',
      '/configuration/simple-import-instructors',
      '/configuration/simple-import-packages',
      '/configuration/simple-import-student-accounts',
      '/configuration/simple-import-history'
    ];
    this.showSimpleImport = simpleImportRoutes.some(route => currentUrl.includes(route));
  }
}
