import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { CalendarOptions, FullCalendarComponent } from '@fullcalendar/angular'; // useful for typechecking

import { DrupalRESTService } from 'src/app/services/drupal-rest.service';
import { LessonComponent } from 'src/app/components/forms/lesson/lesson.component';
import { GroupLessonComponent } from 'src/app/components/forms/group-lesson/group-lesson.component';
import { SchedulesComponent } from 'src/app/components/forms/schedules/schedules.component';
import { ServicesComponent } from 'src/app/components/forms/services/services.component';
import { StudentAccountComponent } from 'src/app/components/forms/student-account/student-account.component';
import { AppointmentDetailsComponent } from 'src/app/components/forms/appointment-details/appointment-details.component';
import { InquiryComponent } from 'src/app/components/forms/inquiry/inquiry.component';

import $ from "jquery";
import moment from 'moment';
import { ComponentType } from '@angular/cdk/portal';
import { UtilityService } from 'src/app/services/utility.service';
import { Router } from '@angular/router';
import { EventLessonEntityComponent } from '../../forms/event-lesson-entity/event-lesson-entity.component';
import { PaymentsEntityComponent } from '../../forms/payments-entity/payments-entity.component';
import { DialogService } from 'src/app/services/dialog.service';
import { EventSchedulesEntityComponent } from '../../forms/event-schedules-entity/event-schedules-entity.component';
import { StudentInquiryEntityComponent } from '../../forms/student-inquiry-entity/student-inquiry-entity.component';
import { EventGroupLessonEntityComponent } from '../../forms/event-group-lesson-entity/event-group-lesson-entity.component';
import { EventServicesEntityComponent } from '../../forms/event-services-entity/event-services-entity.component';
import { UpdateLessonComponent } from '../../forms/update-lesson/update-lesson.component';
import { UpdateGroupLessonComponent } from '../../forms/update-group-lesson/update-group-lesson.component';
import { UpdateServiceComponent } from '../../forms/update-service/update-service.component';
import { NgForm } from '@angular/forms';
import { FieldsService } from 'src/app/services/fields.service';
import { TaxonomyService } from 'src/app/services/taxonomy.service';

@Component({
  selector: 'app-week-view',
  templateUrl: './week-view.component.html',
  styleUrls: ['./week-view.component.css']
})
export class WeekViewComponent implements OnInit {

  @ViewChild('calendar') calendarComponent: FullCalendarComponent;
  @ViewChild(NgForm) f: NgForm;

  LessonComponent = LessonComponent;
  GroupLessonComponent = GroupLessonComponent;
  SchedulesComponent = SchedulesComponent;
  ServicesComponent = ServicesComponent;
  StudentAccountComponent = StudentAccountComponent;
  InquiryComponent = InquiryComponent;
  EventLessonEntityComponent = EventLessonEntityComponent
  PaymentsEntityComponent = PaymentsEntityComponent
  EventScheduleEntityComponent = EventSchedulesEntityComponent;
  StudentInquiryEntityComponent = StudentInquiryEntityComponent;
  EventGroupLessonEntityComponent = EventGroupLessonEntityComponent;
  EventServicesEntityComponent = EventServicesEntityComponent;
  UpdateLessonComponent = UpdateLessonComponent;
  UpdateGroupLessonComponent = UpdateGroupLessonComponent;
  UpdateServiceComponent = UpdateServiceComponent;

  AMTDayView: any = "";
  AMTConfiguration: any = "";
  InstructorList: {} = "";
  AMTCalendarConfiguration: any = "";
  autoCompleteOptions;
  CalendarWeekViewForYearData = this._fieldsService.CalendarWeekViewForYearData(true);

  Events: any;
  Resources = {};

  // Filter configuration
  filterInstructorCategory: string[];
  filterLessonType: any;
  filterInstructor: string[] = [];
  filterStudent: string = "";
  filterDate: string = "";
  filterGroupLesson: boolean = false;
  filterLesson: boolean = false;
  filterServices: boolean = false;

  field_instructor: any = null;

  // FullCalendar
  currentSelectedInstructorID;
  currentSelectedDuration;
  currentSelectedStartTime;

  summaryCount = {
    privateLessonDaily: 0,
    privateLessonWeekly: 0,
    privateTakenLessonDaily: 0,
    privateTakenLessonWeekly: 0,
  }
  filterDateDisplay: string;

  constructor(
    private drupalRESTService: DrupalRESTService,
    public dialog: MatDialog,
    private _utilityService: UtilityService,
    public _fieldsService: FieldsService,
    private _dialogService: DialogService,
    private _router: Router,
    public _taxonomyService: TaxonomyService
  ) { }

  ngOnInit(): void {
    // this.getAMTDayView();
    this.getAMTCalendarConfiguration();
    this.getAMTFilterConfiguration();

    // Hide custom popover if mouse hover exits.
    $("#custom-popover").hover(null, function () {
      $(this).hide();
    });
  }

  ngOnDestroy(): void {
    // Close all dialogs, otherwise dialogs will stay open when navigating.
    this.dialog.closeAll();
  }

  openEntityComponent(component: ComponentType<unknown>, eckType: any, bundle: any, action: any, EntityID?: any, fieldsData?: {}) {
    console.log('fieldsData')
    console.log(fieldsData)
    this._dialogService.openDialog(component, "defaultWithData", {
      data: {
        EntityID: EntityID,
        eckType: eckType,
        bundle: bundle,
        action: action,
        fieldsData: fieldsData ?? '',
      },
    }).afterClosed().subscribe(data => {
      this.refreshCalendar();
    });
  }

  // Refresh FullCalendar.
  refreshCalendar() {
    this.calendarComponent.getApi().refetchEvents();
    this.calendarComponent.getApi().refetchResources();
  }

  public openForm2($formName: any) {
    let dialogConfig = LessonComponent;
    let configOptions = {};
    let config = {
      'AddLessonComponent': {
        'all': {
          'height': 'auto',
          'width': '600px',
        },
        'edit': 'dialogAddLessonData'
      }
    }

    if (config[$formName] !== 'undefined') {
      let formConfig = config[$formName];

      if (typeof formConfig['all'] !== 'object') {
        configOptions = formConfig['all'];
      }
      else if (typeof formConfig['all'] !== 'string') {
        // this.dialogAddLessonData()
        configOptions = this[formConfig['all']](configOptions);
      }
    }

  }

  public dialogAddLessonData(configOptions) {
    configOptions['data'] = {
      instructors: this.filterInstructorCategory,
      lesson_types: this.filterLessonType
    };
    return configOptions;
  }

  /**
   * Utility to open dialogs with a default configuration.
   *
   * @param formName
   * @param dialogConfig
   */
  public openDialog(formName: ComponentType<unknown>, dialogConfig: { height: string, width: string, closeOnNavigation?: boolean, data?: {} } = { height: "auto", width: "600px", closeOnNavigation: true }) {
    // Specific Configuration
    if (formName == InquiryComponent) {
      dialogConfig = {
        height: 'auto',
        width: '600px',
        data: {
          InstructorList: this.InstructorList,
          lesson_types: this.filterLessonType
        }
      }
    }

    if (formName == LessonComponent || formName == GroupLessonComponent) {
      dialogConfig = {
        height: 'auto',
        width: '600px',
        data: {
          InstructorList: this.InstructorList,
          lesson_types: this.filterLessonType
        }
      }
    }

    let dialogRef = this.dialog.open(formName, dialogConfig).afterClosed().subscribe(data => {
      this.refreshCalendar();
    });;
  }

  /**
   * FullCalendar Configuration.
   */
  calendarOptions: CalendarOptions = {
    schedulerLicenseKey: 'CC-Attribution-NonCommercial-NoDerivatives',
    initialView: 'timeGridWeek',
    height: "auto",
    refetchResourcesOnNavigate: true,

    events: this.getCalendarEvents(),
    resources: this.getCalendarResources(),

    // Set
    eventDidMount: function (info) {
      // Fix for some wierd bug where title is getting appended.
      info.event._def.title = "";
      info.el.innerHTML = info.event._def.extendedProps['dataToShow'];
    },

    headerToolbar: {
      start: 'today prev,next',
      center: 'title',
      end: ''
    },

    // titleFormat: '', // See datesSet

    eventClick: (info) => {
      console.log(info)
      this.openDialog(AppointmentDetailsComponent, {
        height: 'auto',
        width: '650px',
        data: info
      });
    },

    titleFormat: function(date) {
      let newTitleFormat = moment(date.end.marker).format('dddd[,] MMMM DD[, ]YYYY[ - Week #] ');
      let newTitleWeek = moment;
      let title: string;
      newTitleWeek.updateLocale('en', {
        week: {
          dow : 0, // Sunday as the first DOW.
        }
      });

      newTitleFormat += newTitleWeek(date.end.marker).format('w');

      let start = moment(date.start.marker).add(1, 'days').format('MMMM Do');
      let end = moment(date.end.marker).format('MMMM Do[, ]YYYY');
      let weekNumber = moment(date.end.marker).format('w');

      title = start + ' - ' + end + ' - Week # ' + weekNumber;

      return (title).toString();
    },

    selectable: true,

    select: (info) => {
      return false;
      // console.log('resource');
      // console.log(info.resource);

      // // console.log("info.start");
      // // console.log(info.start);
      // // console.log("info.end");
      // // console.log(info.end);
      // // console.log(info);

      // // Scheduling from calendar functionality.
      // var left = info.jsEvent.pageX;
      // var top = info.jsEvent.pageY;
      // var theHeight = $("#custom-popover").height();
      // $("#custom-popover").show();
      // $("#custom-popover").css(
      //   "left",
      //   left - $("#custom-popover").width() / 2 + "px"
      // );
      // $("#custom-popover").css(
      //   "top",
      //   top - theHeight / 2 + 20 + "px"
      // );
      // $("#custom-popover").css("display", "block");

      // // Get the current starting time.
      // this.currentSelectedStartTime = moment(info.start).format('YYYY-MM-DD[T]HH:mm:ss');

      // // Calculate duration of selected area.
      // this.currentSelectedDuration = this.calcDuration(info.start, info.end)

      // // Grab the instructor ID, to be used to pass into the form.
      // this.currentSelectedInstructorID = info.resource.id;
      // console.log("info.resource.id");
      // console.log(info.resource.id);
      // info.start;
      // info.end;

    },

    // When the events over loped it set false the selectable of calendar
    selectOverlap: function (event) {
      console.log('selectOverlap called...');
      console.log(event);
      // console.log(event);
      // if (String(event.className[0]) == "Cancelled") {
      //   return true;
      // }
      // return false;

      return false;
    },

    selectAllow: (date) => {
      // console.log('select allow....')
      // console.log('date')
      // console.log(date)
      // // console.log(date);
      // // Find the time diff for checking the druation.
      // var fromTime = date.start.getTime() / 1000;
      // var toTime = date.end.getTime() / 1000;
      // var timeDiff = (toTime - fromTime) / 3600; // will give difference in hrs


      // // var left = event.pageX;
      // // var top = event.pageY;
      // var theHeight = $("#selected-hours").height();
      // // $("#selected-hours").show();
      // // $("#selected-hours .popover-data").html(timeDiff).css({
      // //   "min-width": "20px",
      // //   "text-align": "center",
      // // });
      // // if (timeDiff > 9) {
      // //   $("#selected-hours .popover-data").css("color", "red");
      // // } else {
      // //   $("#selected-hours .popover-data").css("color", "black");
      // // }
      // // $("#selected-hours").css("left", left + "px");
      // // $("#selected-hours").css("top", top - theHeight / 2 + "px");
      // $("#selected-hours").css({
      //   "z-index": "9999",
      //   position: "absolute",
      //   display: "block",
      // });

      return false;
    },

    dateClick: function (info) {
      console.log('Clicked on: ' + info.dateStr);
      console.log('Coordinates: ' + info.jsEvent.pageX + ',' + info.jsEvent.pageY);
      console.log('Current view: ' + info.view.type);
    },

    resourceLabelContent: function (resourceObj, $th) {
      // $th.html(
      //   $(
      //     '<div style="cursor: pointer;">' +
      //       resourceObj.title +
      //       "</div>"
      //   )
      //   .popover({
      //     content: resourceObj.info,
      //     trigger: calendarConfig.instructor_trigger,
      //     placement: calendarConfig.instructor_placement,
      //     container: calendarConfig.instructor_container,
      //     html: true,
      //   })
      // );
    },

    // Default configuration.
    slotDuration: "00:15:00",
    slotLabelInterval: "00:01:00",
    displayEventTime: false,
    slotLabelFormat: {
      hour: 'numeric',
      minute: '2-digit',
      omitZeroMinute: false,
      meridiem: 'short'
    },
  };

  updateCalendarEvents(params: any) {
    console.log("updateCalendarEvents called...");
    console.log('params')
    console.log(params);

    // FIX: set FullCalendar to go to the correct date.
    // this.calendarComponent.getApi().gotoDate(moment(params.filterDate).toISOString())
    // this.calendarComponent.getApi().gotoDate(moment(this.f.form.value.calendar_date).toISOString())
    // console.log(this.f.form.value.calendar_date)
    this.calendarComponent.getApi().gotoDate(moment(this.f.form.value.calendar_date).toISOString());

    // Update display for calendar week.

    this.calendarOptions.events = this.getCalendarEvents(params);
  }

  getCalendarEvents(params: any = null) {
    console.log("getCalendarEvents called...", params);

    return (fetchInfo, successCallback, failureCallback) => {
      console.log('fetchInfo', fetchInfo);
      // Pro tip: /events is equivalent to /load in the old system.
      let endpoint = "/api/dayview/calendar/events?";

      let newDateStart = moment(fetchInfo.startStr).format("YYYY-MM-DD");
      let newDateEnd = moment(fetchInfo.endStr).format("YYYY-MM-DD");

      let values = this.f.form.value;
      console.log('values', values);

      if (values) {

        if (values.calendar_date && values.calendar_date != "") {
          // endpoint = endpoint + "&calendar_date=" + newDateStart;
          // endpoint = endpoint + "&calendar_date=" + moment(fetchInfo.endStr).add(1, 'weeks').format("YYYY[-W]W");
          endpoint = endpoint + "&calendar_date=" + values.calendar_date;
        }

        if (values.filterStudent && values.filterStudent != "") {
          endpoint = endpoint + "&student=" + values.filterStudent;
        }

        if (values.filterLessonType) {
          endpoint = endpoint + "&lessonType=" + values.filterLessonType;
        }

        // if (values.filterInstructorCategory) {
        //   endpoint = endpoint + "&instructor_category=" + values.filterInstructorCategory;
        // }

        if (values.filterInstructor) {
          endpoint = endpoint + "&instructor=" + values.filterInstructor;
        }

        // Add this new condition to include the field_instructor
        if (values.filterInstructor) {
          console.log('filterInstructor', values.filterInstructor);
          endpoint = endpoint + "&instructor=" + values.filterInstructor;
        }

        if (values.filterGroupLesson) {
          endpoint = endpoint + "&group_lesson=" + values.filterGroupLesson;
        }

        if (values.filterLesson) {
          endpoint = endpoint + "&lesson=" + values.filterLesson;
        }

        if (values.filterServices) {
          endpoint = endpoint + "&services=" + values.filterServices;
        }

      }

      // Send start && end times.
      endpoint = endpoint + "&start=" + newDateStart;
      endpoint = endpoint + "&end=" + newDateEnd;
      // endpoint = endpoint + "&calendar_date=" + moment(fetchInfo.endStr).add(1, 'weeks').format("YYYY[-W]W");

      // Run change on next browser MicroTask.
      Promise.resolve().then(() => {
        this.filterDate = moment(newDateStart).day("Monday").format("YYYY-MM-DD");
        this.filterDateDisplay = moment(newDateStart).format("[Week #] w");
      })

      this.drupalRESTService.httpGET(endpoint).subscribe(
        data => {
          this.Events = data || []; // Ensure data is an array, even if empty
          successCallback(this.Events);
        },
        error => {
          console.error('Error fetching calendar events:', error);
          this.Events = [];
          successCallback([]); // Return empty array on error
        }
      );
    }
  }

  handleEventDidMount(info: any) {
    // console.log(info.event.extendedProps);
    // eventDidMount
  }

  getCalendarResources(thisParams: any = null) {
    return (fetchInfo, successCallback, failureCallback) => {

      let endpoint = "/api/dayview/calendar/resources";
      let params = [
        { parameter: 'start', value: fetchInfo.startStr },
        { parameter: 'end', value: fetchInfo.endStr },
        { parameter: 'calendar_date', value: moment(fetchInfo.endStr).add(1, 'weeks').format("YYYY[-W]W") }
      ];

      // FullCalendar
      // currentSelectedInstructorID;
      // currentSelectedDuration;
      // currentSelectedStartTime;

      // this.filterDate = fetchInfo.startStr;
      this.resetSummary();

      this.drupalRESTService.httpGET(endpoint, params).subscribe(data => {
        this.calcSummary(data)
        successCallback(data);

      })
    };
  }

  calcSummary(data) {
    console.log("calcSummary called...")
    let alreadyCounted: number[] = [];

    data.forEach((element: { id: string; privateLesson: number[]; privatePostedLesson: number[]; }) => {
      let id = parseInt(element.id)

      if (alreadyCounted.includes(id)) { return; };

      this.summaryCount.privateLessonWeekly += element.privateLesson[1];
      this.summaryCount.privateLessonDaily += element.privateLesson[0];
      this.summaryCount.privateTakenLessonWeekly += element.privatePostedLesson[1];
      this.summaryCount.privateTakenLessonDaily += element.privatePostedLesson[0];

      alreadyCounted.push(id)
    });

    console.log('this.summaryCount')
    console.log(this.summaryCount)
  }

  resetSummary() {
    this.summaryCount = {
      privateLessonDaily: 0,
      privateLessonWeekly: 0,
      privateTakenLessonDaily: 0,
      privateTakenLessonWeekly: 0,
    }
  }

  getAMTCalendarConfiguration() {
    let endpoint = "/api/dayview/calendar/settings";

    // TODO: Load calendar from initial configuration and data binding.
    this.drupalRESTService.httpGET(endpoint)
      .subscribe(data => {
        // this.AMTCalendarConfiguration = data;

        console.log('getAMTCalendarConfiguration')
        console.log(data)

        // this.calendarOptions.slotMinTime = data['office_hours']['minTime'];
        // this.calendarOptions.slotMaxTime = data['office_hours']['maxTime'];
        this.calendarOptions.slotDuration = data['duration'];
        this.calendarOptions.nowIndicator = true;

        this.calendarComponent.options.slotMinTime = data['office_hours']['minTime'];
        this.calendarComponent.options.slotMaxTime = data['office_hours']['maxTime'];

        // Set office hours for the week.
        if (data['office_hours']) {
          let minTime = '24:00:00', maxTime = '00:00:00';

          for (const property in data['office_hours']) {
            if (data['office_hours'][property]['start'] && data['office_hours'][property]['end']) {
              // Get min time for the week.
              let save:any = minTime.split(':');
              let saveDate = moment();
              saveDate.set('hour', save[0]);
              saveDate.set('minute', save[1]);
              saveDate.set('second', save[2]);

              // Get max time for the week.
              let saveEnd:any = maxTime.split(':');
              let saveEndDate = moment();
              saveEndDate.set('hour', saveEnd[0]);
              saveEndDate.set('minute', saveEnd[1]);
              saveEndDate.set('second', saveEnd[2]);

              let start:any = data['office_hours'][property]['start'].split(':');
              let workStartDate = moment();
              workStartDate.set('hour', start[0]);
              workStartDate.set('minute', start[1]);
              workStartDate.set('second', start[2]);

              let end:any = data['office_hours'][property]['end'].split(':');
              let workEndDate = moment();
              workEndDate.set('hour', end[0]);
              workEndDate.set('minute', end[1]);
              workEndDate.set('second', end[2]);

              // Do the actual comparisons.
              if (saveDate.format('x') > workStartDate.format('x')) {
                minTime = data['office_hours'][property]['start'];
              }

              // Get max time for the week.
              if (saveEndDate.format('x') < workEndDate.format('x')) {
                maxTime = data['office_hours'][property]['end'];
              }
            }
          }

          this.calendarComponent.options.slotMinTime = minTime;
          this.calendarComponent.options.slotMaxTime = maxTime;
        }


        this.calendarComponent.options.slotDuration = data['duration'];
        this.calendarComponent.options.slotLabelInterval = data['slot_interval'];
        this.calendarComponent.options.displayEventTime = data['event_time'];
      });
  }

  getAMTFilterConfiguration() {
    let endpoint = "/api/dayview/calendar/filterInit";

    this.drupalRESTService.httpGET(endpoint)
      .subscribe(data => {
        this.filterLessonType = data['lessonTypes'];
        this.filterInstructorCategory = data['teacherCategory'];
        this.filterInstructor = data['teacherCategory'];
      });
  }

  getInstructorList() {
    let endpoint = "/api_rest/v1/teacherList";

    this.drupalRESTService.httpGET(endpoint)
      .subscribe(data => {
        this.InstructorList = data;
      })
  }

  // Filter Form Submit
  onSubmit(form: NgForm) {
    // console.log(form, this);

    // Call parent function to update calendar.
    this.updateCalendarEvents(form.value);
  }

  getAMTAutocomplete() {
    let endpoint = "/api/dayview/autocomplete?";

    this.drupalRESTService.httpGET(endpoint)
      .subscribe(data => {
        this.AMTConfiguration = data;
      });
  }

  getAMTDayView() {
    let endpoint = "/api/dayview/resource?instructor_category=78&instructor=&lessonType=&student=&calendar_date=&start=2021-12-01T00%3A00%3A00&end=2021-12-02T00%3A00%3A00&_=1638394993251";

    this.drupalRESTService.httpGET(endpoint)
      .subscribe(data => {
        this.AMTDayView = data;
      });
  }

  calcDuration(dateStart: moment.MomentInput, dateEnd: moment.MomentInput) {
    let startDate = moment(dateStart);
    let endDate = moment(dateEnd);
    let duration = moment.duration(endDate.diff(startDate));

    let MINUTES = duration.asMinutes();
    let m = duration.asMinutes() % 60;
    let h = (MINUTES - m) / 60;
    let HHMM = (h < 10 ? "0" : "") + h.toString() + ":" + (m < 10 ? "0" : "") + m.toString();

    return HHMM;
  }

  // Handle the automplete for student field.
  onStudentInput($event: { target: { value: string; }; }) {

    let term: string = $event.target.value;
    let eckType: string = "student_accounts"; // student_accounts
    let bundle: string = "student_account"; // student_account
    let field: string = "title";

    // Build the endpoint.
    let endpoint: string = "/api/dayview/autocomplete?";
    endpoint = endpoint + "term=" + term;
    endpoint = endpoint + "&eckType=" + eckType;
    endpoint = endpoint + "&bundle=" + bundle;
    endpoint = endpoint + "&field=" + field;

    console.log($event.target.value);
    console.log(endpoint);

    // Do the request.
    this.drupalRESTService.httpGET(endpoint)
      .subscribe(data => {
        console.log(data);
        this.autoCompleteOptions = data;
      })
  }


  // Convert the ID value to the human readable version for output.
  getNameFromId(index, Obj, key = 'value') {
    let item = Obj.find((element) => {
      return element.id == index || element.uid == index;
    });
    return item?.[key];
  }

}
