import { CommonModule } from "@angular/common";
import { Component, OnInit, AfterViewInit, ViewChild, VERSION } from '@angular/core';
import { LiveAnnouncer } from '@angular/cdk/a11y';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { UtilityService } from 'src/app/services/utility.service';
import { MatPaginator, PageEvent } from "@angular/material/paginator";
import { FormControl, FormGroup, NgForm } from "@angular/forms";
import { DrupalRESTService } from "src/app/services/drupal-rest.service";
import { ActivatedRoute, Event, Params, Router } from "@angular/router";
import { saveAs } from 'file-saver';
import moment from "moment";
import { ViewTableComponent } from "../../base/view-table/view-table.component";

@Component({
  selector: 'app-projections-report',
  templateUrl: './projections-report.component.html',
  styleUrls: [
    './projections-report.component.css',
    '../../../app.component.css'
  ]
})
export class ProjectionsReportComponent extends ViewTableComponent implements OnInit {

  projectionsReportTotal;

  override displayedColumns: string[] = [
    'date',
    'students',
    'enrollments',
    'expected_amount',
    'status',
  ];

  override getData(params) {
    // Show Progress Bar (Loading indicator)
    this.ShowProgressBar = true;
    this.queryParams = params;

    // Add sorting paramaters, if available.
    if (this.sortParams !== undefined) {
      this.queryParams.push(this.sortParams)
    }

    // Load the new student list with the filtering parameters.
    this._utilityService.getProjectionsReport(
      this.queryParams
    )
      .subscribe((data) => {
        // ShowProgressBar
        this.ShowProgressBar = false;

        // Modify data to work in Angular.
        data['#projections_table']['projections-report']['#rows'].forEach(element => {
          // Handle special case scenario where they are tallying the total.
          if (element.length === 2) {
            element[2] = { data: "" };
            element[3] = { data: element[1].data };
            element[4] = { data: "" };
            element[1] = { data: "" };

            this.projectionsReportTotal = element;
          }

          // HTML filter. (temporary until we replace the endpoint that generates html table)
          // element[0].data = element[0].data.replace(/<[^>]*>?/gm, '');
          // element[1].data = element[1].data.replace(/<[^>]*>?/gm, '');
          // element[2].data = element[2].data.replace(/<[^>]*>?/gm, '');
          // element[3].data = element[3].data.replace(/<[^>]*>?/gm, '');
          // element[4].data = element[4].data.replace(/<[^>]*>?/gm, '');
          element.forEach((el, index) => {
            if (el.data) {
              el.data = el.data.replace(/<[^>]*>?/gm, '');
            }
          });
        });

        this.data = data;
        this.dataSource = new MatTableDataSource(data['#projections_table']['projections-report']['#rows']);

        // Pagination configuration.
        if (data) {
          console.log(data)

          this.paginator.length = data['#total_rows'];
          // this.paginator.pageSize = (data['results']).length;
          this.paginator.pageSize = this.pageSize;
          this.paginator.pageIndex = this.paginationIndex;

          console.log(this.paginator.pageSize)
          console.log(this.paginator.pageIndex)
          console.log(this.paginator.length)
        }
      },
        error => this.handleError(error)
      )
  }

}
