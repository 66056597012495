<form (ngSubmit)="onSubmit(f)" #f="ngForm" class="form" >
<div mat-dialog-title>
    <h1>Add Group Lesson</h1>
    <button mat-button mat-dialog-close><mat-icon aria-hidden="false" aria-label="Example home icon">close</mat-icon></button>
</div>
<div mat-dialog-content>
        <div class="container">
            <div class="row">

                <div class="col-6">
                    <app-lesson-type-dropdown [lesson_types]=data.lesson_types></app-lesson-type-dropdown>
                </div>

                <div class="col-6">
                    <app-instructor-list-dropdown></app-instructor-list-dropdown>
                </div>

                <div class="col-12">
                    <app-duration-dropdown></app-duration-dropdown>
                </div>

                <div class="col-12">
                    <mat-form-field class="full-width-field">
                        <mat-label>Date and Time</mat-label>
                        <input name="field_date_and_time" matInput [matDatepicker]="field_date_and_time">
                        <mat-datepicker-toggle matSuffix [for]="field_date_and_time"></mat-datepicker-toggle>
                        <mat-datepicker #field_date_and_time></mat-datepicker>
                    </mat-form-field>
                </div>

                <div class="col-12">
                    <mat-form-field class="full-width-field">
                        <mat-label>Notes</mat-label>
                        <textarea ngModel name="field_notes" matInput placeholder=""></textarea>
                    </mat-form-field>
                </div>

                <div class="col-12 mt-1">
                    <mat-expansion-panel [expanded]="false">
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                Standing Lesson
                            </mat-panel-title>
                        </mat-expansion-panel-header>

                        <div class="row">
                            <div class="col-12">
                                <mat-form-field class="full-width-field">
                                    <mat-label>Expiration Date</mat-label>
                                    <input name="field_expiration_date" matInput [matDatepicker]="field_expiration_date">
                                    <mat-datepicker-toggle matSuffix [for]="field_expiration_date"></mat-datepicker-toggle>
                                    <mat-datepicker #field_expiration_date></mat-datepicker>
                                </mat-form-field>
                            </div>
                            <div class="col-6">
                                <mat-form-field class="full-width-field">
                                    <mat-label>Repetition Frequency</mat-label>
                                    <input
                                        name=""
                                        matInput
                                        type="number"
                                        ngModel
                                        >
                                </mat-form-field>
                            </div>
                            <div class="col-6">
                                <app-repetition-units></app-repetition-units>
                            </div>

                            <div class="col-12">
                                <!-- <app-autocomplete ></app-autocomplete> -->
                                <mat-form-field class="full-width-field">
                                    <mat-label>Base Lesson</mat-label>
                                    <input
                                        name=""
                                        matInput
                                        ngModel
                                        >
                                </mat-form-field>
                            </div>

                        </div>

                    </mat-expansion-panel>
                </div>

                <div class="col-12 mt-1">
                    <app-personal-information></app-personal-information>
                </div>

            </div>

        </div>
</div>
<mat-dialog-actions align="end">
    <button onclick="onSubmit(f)" type="submit" mat-raised-button color="accent">Save and Schedule Service</button>
</mat-dialog-actions>
</form>
