<form (ngSubmit)="OnSubmit(f)" #f=ngForm class="form">
<div mat-dialog-title>
    <h1>Add Lesson</h1>
    <button mat-button mat-dialog-close><mat-icon aria-hidden="false"
            aria-label="Example home icon">close</mat-icon></button>
</div>

<div mat-dialog-content>
        <div class="container">
            <div class="row">

                <div class="col-6">
                    <app-lesson-type-dropdown [lesson_types]=data.lesson_types></app-lesson-type-dropdown>
                </div>

                <div class="col-6">
                    <app-instructor-list-dropdown></app-instructor-list-dropdown>
                </div>

                <div class="col-6">
                    <app-duration-dropdown></app-duration-dropdown>
                </div>

                <div class="col-6">
                    <mat-form-field class="full-width-field">
                        <mat-label>Date and Time</mat-label>
                        <input name="date" matInput [matDatepicker]="picker">
                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                        <mat-datepicker #picker></mat-datepicker>
                    </mat-form-field>
                </div>

                <div class="col-12">
                    <app-standing-appointment></app-standing-appointment>
                </div>

                <div class="col-12 mt-1">
                    <app-personal-information ></app-personal-information>
                </div>

                
                <button type="submit" mat-raised-button color="primary">Save and Add Lesson</button>

            </div>

        </div>
        
    </div>
</form>