{{ Columns }}
<p></p>
{{ Rows }}
<!-- <mat-table [dataSource]="dataSource">

    <ng-container *ngFor="let column of Columns" matColumnDef="{{column}}">
        <mat-header-cell *matHeaderCellDef>{{column}}</mat-header-cell>
        <mat-cell *matCellDef="let element">{{ element.value }}</mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="Columns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: Columns"></mat-row>

</mat-table> -->