<mat-expansion-panel [expanded]="true">
    <mat-expansion-panel-header>
        <mat-panel-title>
            {{ Title }}
        </mat-panel-title>
    </mat-expansion-panel-header>

    <div class="row">
        <div class="col-6">
            <mat-form-field class="full-width-field">
                <mat-label>Expiration Date</mat-label>
                <input name="date_expiration" matInput
                    [matDatepicker]="picker">
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>
        </div>

        <div class="col-6">
            <mat-form-field class="full-width-field">
                <mat-label>Repetition Frequency</mat-label>
                <input 
                name="repetition_frequency"
                matInput
                type="number">
            </mat-form-field>
        </div>

        <div class="col-6">
            <app-repetition-units></app-repetition-units>
        </div>
    </div>

</mat-expansion-panel>