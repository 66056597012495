import { ComponentType } from '@angular/cdk/portal';
import { Component, OnInit, ViewChild, AfterViewInit, Inject } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { DialogService } from 'src/app/services/dialog.service';
import { UtilityService } from 'src/app/services/utility.service';

import { InquiryComponent } from 'src/app/components/forms/inquiry/inquiry.component';
import { ServicesComponent } from 'src/app/components/forms/services/services.component';
import { EventServicesEntityComponent } from '../../forms/event-services-entity/event-services-entity.component';
import { EventLessonEntityComponent } from '../../forms/event-lesson-entity/event-lesson-entity.component';
import { StudentInquiryEntityComponent } from '../../forms/student-inquiry-entity/student-inquiry-entity.component';
import { EnrollmentEntityComponent } from '../../forms/enrollment-entity/enrollment-entity.component';
import { StudentRecordEntityComponent } from '../../forms/student-record-entity/student-record-entity.component';
import { SetOpeningBalanceComponent } from '../../forms/set-opening-balance/set-opening-balance.component';
import { StudentDepartmentComponent } from '../../forms/student-department/student-department.component';
import { BaseComponent } from '../../forms/base-form/base-form.component';
import { FormBuilder } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { RouteEventsService } from 'src/app/route-events.service';
import { AuthRESTService } from 'src/app/services/auth-rest.service';
import { AuthService } from 'src/app/services/auth.service';
import { DrupalRESTService } from 'src/app/services/drupal-rest.service';
import { EntityRESTService } from 'src/app/services/entity-rest.service';
import { FieldsService } from 'src/app/services/fields.service';
import { MenuRESTService } from 'src/app/services/menu-rest.service';
import { TaxonomyService } from 'src/app/services/taxonomy.service';
import { StudentInquiryDetailsComponent } from '../../forms/student-inquiry-details/student-inquiry-details.component';
import { PaidLessonAuditComponent } from '../../audit/paid-lesson-audit/paid-lesson-audit.component';

@Component({
  selector: 'app-student-dashboard',
  templateUrl: './student-dashboard.component.html',
  styleUrls: ['./student-dashboard.component.css']
})
export class StudentDashboardComponent extends BaseComponent {

  // Modals to load.
  InquiryComponent = InquiryComponent;
  EventServicesEntityComponent = EventServicesEntityComponent;
  EventLessonEntityComponent = EventLessonEntityComponent;
  StudentInquiryEntityComponent = StudentInquiryEntityComponent;
  EnrollmentEntityComponent = EnrollmentEntityComponent;
  StudentRecordEntityComponent = StudentRecordEntityComponent;
  SetOpeningBalanceComponent = SetOpeningBalanceComponent;
  StudentDepartmentComponent = StudentDepartmentComponent;
  StudentInquiryDetailsComponent = StudentInquiryDetailsComponent;
  PaidLessonAuditComponent = PaidLessonAuditComponent;

  user: { id: number }
  studentDashboardData: any;
  studentServices: any;
  studentServicesColumns: any;
  studentServicesRows: any;
  lessonListColumns: string[];
  lessonListRows: any;
  lessonList: any;
  studentAccountID;
  studentDashboardObservable;

  dataSource: Object;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  paymentReportColumns: string[];

  ngAfterViewInit() {
    // this.lessonListRows.paginator = this.paginator;
    // this.lessonListRows.sort = this.sort;
  }

  ngOnInit(): void {
    this.user = {
      id: this._activatedRoute.snapshot.params['id'],
    }

    this._activatedRoute.params
      .subscribe(
        (params: Params) => {
          this.getStudentDashboard(params['id']);

          // React to route specific parameters.
          this.user.id = params['id'];
        }
      )
  }

  openEntityComponent(component: ComponentType<unknown>, eckType: any, bundle: any, action: any, EntityID?: any, fieldsData?: {}) {
    console.log('fieldsData', fieldsData)
    this._dialogService.openDialog(component, "defaultWithData", {
      data: {
        EntityID: EntityID ?? '',
        eckType: eckType ?? '',
        bundle: bundle ?? '',
        action: action ?? '',
        fieldsData: fieldsData ?? '',
      },
    }).afterClosed().subscribe(data => {
      // Reload the student dashboard.
      this.getStudentDashboard(this._activatedRoute.snapshot.params['id']);
    });
  }

  /**
   * Utility to open dialogs with a default configuration.
   *
   * @param formName
   * @param dialogConfig
   */
  openDialog(component: ComponentType<unknown>, dialogConfig: { height: string, width: string, closeOnNavigation?: boolean, data?: {} } = { height: "auto", width: "600px", closeOnNavigation: true }) {
    let dialogRef = this._dialogService.openDialog(component, "defaultWithData", dialogConfig).afterClosed().subscribe(data => {
      // Reload the student dashboard.
      this.getStudentDashboard(this._activatedRoute.snapshot.params['id']);
    });
  }

  getStudentDashboard(id: number) {
    this.studentDashboardObservable = this._utilityService.getLoadStudentDashboard(id);
    this.studentDashboardObservable.subscribe((data) => {
          console.log("student data", data);
          this.studentAccountID = data['#studentAccountID'][0];

          // Student services DataSource
          if (data['#studentServices']['#rows']) {
            // Convert to iterable array for ngFor directive.
            data['#studentServices']['#rows'] = Object.values(data['#studentServices']['#rows']);
            this.studentServicesColumns = ['date', 'time', 'service', 'length', 'teacher', 'status', 'edit'];
            this.studentServices = data['#studentServices'];
            this.studentServicesRows = data['#studentServices']['#rows'];
          }

          // Lesson List DataSource
          if (data['#student']['#rows']) {
            this.lessonList = data['#student'];
            this.lessonListColumns = ['date', 'time', 'service', 'length', 'teacher', 'status', 'contacts_present', 'taken / rem', 'edit'];
            this.lessonListRows = new MatTableDataSource(data['#student']['#rows'].reverse());
            this.lessonListRows.paginator = this.paginator;
            this.lessonListRows.sort = this.sort;
          }

          if (data['#enrollment']['#rows']) {
            // Convert to iterable array for ngFor directive.
            data['#enrollment']['#rows'] = Object.entries(data['#enrollment']['#rows']);
          }

          this.studentDashboardData = this.dataSource = data;
        }
      )
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.lessonListRows.filter = filterValue.trim().toLowerCase();

    if (this.lessonListRows.paginator) {
      this.lessonListRows.paginator.firstPage();
    }
  }

  getPreviousURL() {
    // If they came from the same page, just redirect them to students-list page.
    if (this._routeEventsService.previousRoutePath.value.includes("/students")) {
      this.previousUrl = ['/students-list'];
    } else {
      this.previousUrl = [this._routeEventsService.previousRoutePath.value];
    }
    return this.previousUrl;
  }


}
