import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-display-attendees',
  templateUrl: './display-attendees.component.html',
  styleUrls: ['./display-attendees.component.css']
})
export class DisplayAttendeesComponent implements OnInit {

  @Input() field_students;
  @Input() studentUpdateLoad: (index: number) => void;
  @Input() studentRemove: (index: number) => void;

  constructor() { }

  ngOnInit(): void {
  }

}
