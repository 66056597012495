import { Injectable, OnInit } from '@angular/core';
import { DrupalRESTService } from './drupal-rest.service';

@Injectable({
  providedIn: 'root'
})
export class TaxonomyService implements OnInit {

  enrollment_type = [];
  group_lesson_types = [];
  lesson_status = [];
  lesson_type = [];
  package_code = [];
  payment_type = [];
  schedule_type = [];
  service_status = [];
  service_type = [];
  sps_code = [];
  student_department = [];
  teacher_category = [];

  constructor(
    private drupalRESTService: DrupalRESTService,
  ) { }

  ngOnInit(): void {
  }

  initTaxonomyTerms() {
    return new Promise((resolve, reject) => {
      this.drupalRESTService.httpGET("/api_rest/v1/taxonomy")
        .subscribe(data => {
          // Clear existing taxonomy terms
          this.clearTaxonomyTerms();

          // Respect "published" and "hide"
          for (const taxonomy_term in data) {
            data[taxonomy_term].forEach((element: any) => {
              if (element.hide == false && element.published) {
                this[taxonomy_term].push(element);
              } else {
                // console.log(element);
              }
            });
          }
          resolve(true);
        });
    })
  }

  private clearTaxonomyTerms() {
    this.enrollment_type = [];
    this.group_lesson_types = [];
    this.lesson_status = [];
    this.lesson_type = [];
    this.package_code = [];
    this.payment_type = [];
    this.schedule_type = [];
    this.service_status = [];
    this.service_type = [];
    this.sps_code = [];
    this.student_department = [];
    this.teacher_category = [];
  }
}

