<div class="container-fluid">
  <h1 class="page-header">Supervisor Report</h1>

  <mat-expansion-panel>
    <mat-expansion-panel-header>
      <mat-panel-title>
        Filter
      </mat-panel-title>
      <mat-panel-description>
        <app-filter-results-panel [parameters]=queryParams></app-filter-results-panel>
      </mat-panel-description>
    </mat-expansion-panel-header>
    <form (ngSubmit)="onSubmit(f)" #f=ngForm class="form">
      <div class="row">

        <!-- Date Range Filter -->
        <div class="col-3">
          <mat-form-field>
            <mat-label>Enter a date range</mat-label>
            <mat-date-range-input [rangePicker]="picker">
              <input name="start_date" (dateChange)="onSubmit(f)" ngModel matStartDate placeholder="Start date">
              <input name="end_date" (dateChange)="onSubmit(f)" ngModel matEndDate placeholder="End date">
            </mat-date-range-input>
            <!-- <mat-hint>MM/DD/YYYY – MM/DD/YYYY</mat-hint> -->
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-date-range-picker #picker></mat-date-range-picker>
          </mat-form-field>
        </div>

        <!-- Enrollment Autocomplete -->
        <!-- <div class="col-4">
          <mat-form-field class="full-width-field">
            <mat-label>Enrollment</mat-label>
            <input name="field_enrollment_name" (input)="onEnrollmentAutocomplete($event)" matInput ngModel
              [matAutocomplete]="autoCompleteEnrollment">
            <button type="button" matSuffix mat-icon-button aria-label="manage_accounts">
              <mat-icon>manage_accounts</mat-icon>
            </button>
          </mat-form-field>
          <mat-autocomplete #autoCompleteEnrollment="matAutocomplete" (optionSelected)="onSubmit(f)">
            <mat-option *ngFor="let option of autoCompleteEnrollmentOptions" [value]="option.label + ' ' + '(' + option.value + ')'">
              {{option.label}} ({{option.value}})
            </mat-option>
          </mat-autocomplete>
        </div> -->


        <!-- <div class="col-4">
            <mat-form-field class="full-width-field">
              <mat-label>Select Department...</mat-label>
              <mat-select
                name="field_department_name"
                ngModel
                (selectionChange)="onSubmit(f)">
                <mat-option value="all">All</mat-option>
                <mat-option
                *ngFor="let department of departmentData"
                [value]="department.key">{{ department.value }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div> -->

        <section class="section">
          <div class="float-end bring-inline">
            <button (click)="resetForm()" color="primary" mat-flat-button class="me-1" mat-button>Clear
              <!-- <mat-icon (click)="resetForm()" aria-hidden="false" aria-label="Menu dropdown" class=""></mat-icon> -->
            </button>
          </div>

        </section>

      </div>

      <section class="section">
      </section>

    </form>

  </mat-expansion-panel>

  <mat-progress-bar *ngIf="ShowProgressBar" mode="indeterminate"></mat-progress-bar>
  <div class="row">
    <div class="col-md-12 mt-3">
      <div class="float-end">
        <!-- <button (click)="onPrint()" color="primary" mat-flat-button class="me-1" mat-button>Print</button> -->
        <!-- <button (click)="onDownloadCSV()" color="primary" mat-flat-button class="me-1 warning" mat-button>CSV</button> -->
      </div>
    </div>
  </div>

  <div class="table-container">

    <table mat-table #displayTable [dataSource]="dataSource" matSort (matSortChange)="sortDataView($event)" class="mt-4">

      <!-- date Column -->
      <ng-container matColumnDef="date">
        <th mat-header-cell *matHeaderCellDef sortActionDescription="Sort by date">
          Date
        </th>
        <td mat-cell *matCellDef="let element"> {{ element.field_sale_date }} </td>
      </ng-container>

      <!-- type Column -->
      <ng-container matColumnDef="type">
        <th mat-header-cell *matHeaderCellDef sortActionDescription="Sort by type">
          Enrollment Type
        </th>
        <td mat-cell *matCellDef="let element"> {{ element.enrollment_type_id }} </td>
      </ng-container>

      <!-- teacher Column -->
      <ng-container matColumnDef="teacher">
        <th mat-header-cell *matHeaderCellDef sortActionDescription="Sort by teacher">
          Teacher
        </th>
        <td mat-cell *matCellDef="let element"> {{ element.field_first_name_executive }} {{ element.field_last_name_executive }} </td>
      </ng-container>

      <!-- student Column -->
      <ng-container matColumnDef="student">
        <th mat-header-cell *matHeaderCellDef sortActionDescription="Sort by student">
          Student
        </th>
        <td mat-cell *matCellDef="let element"> {{ element.field_first_name_value }} {{ element.field_last_name_value }} </td>
      </ng-container>

      <!-- units Column -->
      <ng-container matColumnDef="units">
        <th mat-header-cell *matHeaderCellDef sortActionDescription="Sort by units">
          Units
        </th>
        <td mat-cell *matCellDef="let element"> {{ element.lesson_count }} </td>
      </ng-container>

      <!-- lesson_tracking Column -->
      <ng-container matColumnDef="lesson_tracking">
        <th mat-header-cell *matHeaderCellDef sortActionDescription="Sort by lesson_tracking">
          Lesson Tracking
        </th>
        <td mat-cell *matCellDef="let element">  Remaining: {{ element.lessons_remaining }} / Total {{ element.total_lessons_paid }} </td>
      </ng-container>

      <!-- outcome Column -->
      <ng-container matColumnDef="outcome">
        <th mat-header-cell *matHeaderCellDef sortActionDescription="Sort by outcome">
          Outcome
        </th>
        <td mat-cell *matCellDef="let element"> {{ element.field_enrollment_status }} </td>
      </ng-container>

      <!-- payment_due Column -->
      <ng-container matColumnDef="payment_due">
        <th mat-header-cell *matHeaderCellDef sortActionDescription="Sort by payment_due">
          Payment Due
        </th>
        <td mat-cell *matCellDef="let element"> {{ element.field_payment_date_value }} </td>
      </ng-container>

      <!-- email Column -->
      <ng-container matColumnDef="nfa">
        <th mat-header-cell *matHeaderCellDef sortActionDescription="Sort by nfa">
          NFA
          <!-- No Future Appointments -->
        </th>
        <td mat-cell *matCellDef="let element"> {{ element.field_upcoming_lesson == null ? '✓' : '' }} </td>
      </ng-container>

      <!-- REPLACE Column -->
      <!-- <ng-container matColumnDef="REPLACE">
        <th mat-header-cell *matHeaderCellDef sortActionDescription="Sort by REPLACE">
          REPLACE
        </th>
        <td mat-cell *matCellDef="let element"> {{ element.REPLACE }} </td>
      </ng-container> -->

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row matRipple *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>

    <mat-paginator (page)="pageChanged($event)" [pageSizeOptions]="onPageSizeOptions()" [length]="30"
      [disabled]="ShowProgressBar"
      [pageSize]="paginationTotalPages" [pageIndex]="paginationIndex" aria-label="Select page of view"></mat-paginator>

  </div>
</div>
