import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import moment from 'moment';
import { ViewTableComponent } from '../../base/view-table/view-table.component';

@Component({
  selector: 'app-us-sales-tax-report',
  templateUrl: './us-sales-tax-report.component.html',
  styleUrls: ['./us-sales-tax-report.component.css']
})
export class UsSalesTaxReportComponent extends ViewTableComponent implements OnInit {

  currentPage: number;

  activeLink = 'All Reports';
  links = [
    { linkTitle: 'All Reports', linkRoute: '/us-sales-tax-report' },
    { linkTitle: 'Recent Reports', linkRoute: '/us-sales-tax-report-recent' }
  ]

  override displayedColumns: string[] = [
    'title',
    'field_date_and_time',
    'field_gross_tuition',
    'field_payment_id',
    'field_payment_type',
    'field_status',
    'field_student_name',
    'field_tax_collected',
    'field_total_payment_amount',
    'actions'
  ];

  override getData(params: { parameter: string; value: string; }[]) {
    this.ShowProgressBar = true;
    this.queryParams = params;

    let filters = '?sort_order=ASC&sort_by=field_date_and_time';
    if (params) {
      for (const param of params) {
        if (param.parameter === 'Recent') {
          filters += `&field_date_and_time=${moment().subtract(30, 'days').format('YYYY-MM-DD')}--`;
        }
      }
    }

    const endpoint = `/api_rest/v1/us-sales-tax-report${filters}`;

    this._drupalRESTService.httpGET(endpoint, params).subscribe(
      (data) => {
        this.ShowProgressBar = false;
        this.dataSource = new MatTableDataSource(data['results']);
        // this.dataSource.paginator = this.paginator;
        // this.dataSource.sort = this.sort;

        if (data['results']) {
          // this.setupPagination(data, params);
        }
      },
      error => this.handleError(error)
    );
  }

  setReportStatus(payment_id: number, value: string) {
    let body = {
      field_status: value,
    };
    let setReportStatus = this._entityRESTService.patchEntity('us_sales_tax_report', 'us_sales_tax_report', payment_id, body);
    setReportStatus.subscribe(data => {
      console.log('data', data);
      this.getData(this.queryParams);
    })
  }

}
