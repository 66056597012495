import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-lesson-type-dropdown',
  templateUrl: './lesson-type-dropdown.component.html',
  styleUrls: [
    './lesson-type-dropdown.component.css',
    '../../../../../app.component.css'
  ]
})
export class LessonTypeDropdownComponent implements OnInit {

  @Input() lesson_types;

  constructor() { }

  ngOnInit(): void {
  }

}
