import { Component, OnInit } from '@angular/core';
import { BaseComponent } from 'src/app/components/forms/base-form/base-form.component';

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.css']
})
export class LogoutComponent extends BaseComponent implements OnInit {
  ngOnInit(): void {

    localStorage.removeItem('csrf_token');
    this._authService.set_user_authenticated(false);
    this._router.navigate(['/auth/login'], {queryParams: {logged_out: true}})
    this._authService.current_user = null;

    this._authRESTService.logout()
    .subscribe(response => {
      console.log(response);
      // this._router.navigate(['auth/logout'])
    });
  }
}
