import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import moment from 'moment';
import { ViewTableComponent } from '../../base/view-table/view-table.component';

@Component({
  selector: 'app-payments-made-encompassing',
  templateUrl: './payments-made-encompassing.component.html',
  styleUrls: ['./payments-made-encompassing.component.css']
})
export class PaymentsMadeEncompassingComponent extends ViewTableComponent implements OnInit {

  currentPage: number;

  override displayedColumns: string[] = [
    'id',
    'field_date_and_time',
    'field_student_name',
    'field_student_department',
    'field_enrollment_package_name_label',
    'field_gross_tuition',
    'field_payment_type_label',
    'field_instructor_label',
    'closer',
  ];

  override getData(params: { parameter: string; value: string; }[]) {
    this.ShowProgressBar = true;
    this.queryParams = params;

    // Add sorting paramaters, if available.
    if (this.sortParams !== undefined) {
      this.queryParams.push(this.sortParams)
    }

    params = [
      ...params,
    ];

    // Alter start_date and end_date params for Drupal.
    let start_date;
    let end_date;
    let field_next_scheduled_payment;

    if (params) {
      for (const property in params) {
        if (params[property]?.['parameter'] == 'start_date') {
          start_date = moment(params[property]?.['value']).format('YYYY-MM-DD').toString();
        }
        if (params[property]?.['parameter'] == 'end_date') {
          end_date = moment(params[property]?.['value']).format('YYYY-MM-DD').toString();
        }
      }

      field_next_scheduled_payment = {
        parameter: 'field_date_and_time',
        value: start_date + "--" + end_date,
      }
    }

    if (field_next_scheduled_payment && field_next_scheduled_payment?.['value'] != 'undefined--undefined')  {
      params = [
        ...params,
        field_next_scheduled_payment
      ];
    }


    // Handle different checkbox based filters.
    let field_sps_code;
    let sps_codes = '';
    if (params) {
      for (const property in params) {
        // console.log(params[property])
        if (params[property]?.['parameter'] == 'Sundry') {
          sps_codes = sps_codes + "558\+";
        }
        if (params[property]?.['parameter'] == 'Misc') {
          sps_codes = sps_codes + "554\+";
        }
        if (params[property]?.['parameter'] == 'Lesson') {
          sps_codes = sps_codes + "1394\+550\+549\+547\+552";
        }
      }

      field_sps_code = {
        parameter: 'field_sps_code',
        value: sps_codes,
      }
    }

    if (field_sps_code && field_sps_code?.['value'] != '')  {
      params = [
        ...params,
        field_sps_code
      ];
    }

    // Load the new data with the filtering parameters.
    let endpoint: string = "/api/v1/reports/cash";

    this._drupalRESTService.httpGET(endpoint, params)
      .subscribe((data) => {
        // ShowProgressBar
        this.ShowProgressBar = false;

        this.dataSource = new MatTableDataSource(data['results']);

        // // Pagination
        if (data['results']) {
          this.paginator.length = data['count'];
          this.paginator.pageSize = this.pageSize;
          this.paginator.pageIndex = this.paginationIndex;
        }
      },
        error => this.handleError(error)
      )
  }
}
