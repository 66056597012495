import { Component, Input, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { Params } from '@angular/router';
import { ViewTableComponent } from 'src/app/components/base/view-table/view-table.component';
import { ViewPaymentsFromEnrollmentComponent } from 'src/app/components/forms/view-payments-from-enrollment/view-payments-from-enrollment.component';

@Component({
  selector: 'app-lesson-student-report',
  templateUrl: './lesson-student-report.component.html',
  styleUrls: ['./lesson-student-report.component.css']
})
export class LessonStudentReportComponent extends ViewTableComponent implements OnInit {

  @Input() drupal_studentAccount_id: number;
  @Input() enrollmentsToLoad: string;
  @Input() drupal_student_name: string;

  sendNonUnitPrivateParam = false;
  sendNonUnitGroupParam = false;

  ViewPaymentsFromEnrollmentComponent = ViewPaymentsFromEnrollmentComponent;

  override displayedColumns: string[] = [
    'enrollmentID',
    // 'field_contacts',
    'field_sale_date',
    'field_instructor',
    'field_category',
    'field_package_name',
    'field_enrollment_lesson_count',
    'field_enrollment_total_price',
    'field_total_paid',
    'field_balance_due',
    // 'field_additional_notes',
    'field_lesson_available',
    'isDropped',
    // 'nothing',
    'nothing_1',
  ];

  override getData(params: { parameter: string; value: string; }[]) {
    // Show Progress Bar (Loading indicator)
    this.ShowProgressBar = true;
    this.queryParams = params;

    let override_params = [
      { parameter: 'field_category_target_id', value: this.enrollmentsToLoad },
      { parameter: 'id', value: this.drupal_studentAccount_id+'' },
      { parameter: 'items_per_page', value: 'All' },
    ]

    // Conditional Parameter Addition
    if (this.sendNonUnitPrivateParam) {
      override_params.push({ parameter: 'field_lesson_type_target_id', value: '1411'});
      // override_params.push({ parameter: 'field_package_code_target_id', value: '173'});
    }
    // Conditional Parameter Addition
    if (this.sendNonUnitGroupParam) {
      override_params.push({ parameter: 'field_lesson_type_target_id', value: '1410'});
      // override_params.push({ parameter: 'field_package_code_target_id', value: '173'});
    }

    // Add sorting paramaters, if available.
    if (this.sortParams !== undefined) {
      this.queryParams.push(this.sortParams)
    }

    // Load the new data with the filtering parameters.
    this._utilityService.getEnrollmentyView(
      this.queryParams.concat(override_params)
    )
      .subscribe((data) => {
        // ShowProgressBar
        this.ShowProgressBar = false;

        this.data = data;
        this.dataSource = new MatTableDataSource(data['results']);

        // Pagination
        if (data['results']) {
          // this.paginator.length = data['count'];
          // this.paginator.pageSize = this.pageSize;
          // this.paginator.pageIndex = this.paginationIndex;
          this.paginator.length = data['count'];
          this.paginator.pageSize = data['count'];
          this.paginator.pageIndex = 0;
        }
      },
        error => this.handleError(error)
      )
  }

  updateGetData() {
    // Assuming other parts of the component rely on getData with updated state
    this.getData(this.queryParams);
  }

}
