import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { ViewTableComponent } from '../../base/view-table/view-table.component';
import { EnrollmentEntityComponent } from '../../forms/enrollment-entity/enrollment-entity.component';
import { StudentDashboardWrapperComponent } from '../../student/student-dashboard-wrapper/student-dashboard-wrapper.component';
import { ViewPaymentsFromEnrollmentComponent } from '../../forms/view-payments-from-enrollment/view-payments-from-enrollment.component';

@Component({
  selector: 'app-enrollments-view',
  templateUrl: './enrollments-view.component.html',
  styleUrls: ['./enrollments-view.component.css']
})
export class EnrollmentsViewComponent extends ViewTableComponent implements OnInit {

  override displayedColumns: string[] = [
    'enrollmentID',
    'field_contacts',
    'field_sale_date',
    'field_instructor',
    'field_category',
    'field_package_name',
    'field_enrollment_lesson_count',
    'field_enrollment_total_price',
    'field_total_paid',
    'field_balance_due',
    'field_additional_notes',
    'field_lesson_available',
    'isDropped',
    // 'nothing',
    'nothing_1',
  ];

  StudentDashboardWrapperComponent = StudentDashboardWrapperComponent;
  ViewPaymentsFromEnrollmentComponent = ViewPaymentsFromEnrollmentComponent;

  override getData(params: { parameter: string; value: string; }[]) {
    this.ShowProgressBar = true;
    this.queryParams = params;

    this._drupalRESTService.httpGET("/api/v1/enrollment_list", params)
      .subscribe((data) => {
        this.ShowProgressBar = false;
        this.data = data;
        this.dataSource = new MatTableDataSource(data['results']);

        // Pagination
        if (data['results']) {
          this.setupPagination(data, params);
        }

      }, error => this.handleError(error))
  }

  isInteger(value: string): boolean {
    if (value && !isNaN(+value)) {
      return Number(value) % 1 === 0;
    }
    return false;
  }

}
