import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-simple-import-history-wrapper',
  templateUrl: './simple-import-history-wrapper.component.html',
  styleUrls: ['./simple-import-history-wrapper.component.css']
})
export class SimpleImportHistoryWrapperComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
