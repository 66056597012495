<div class="stripe-devices-section">
  <h3>Connected Square Devices</h3>
  <div *ngIf="stripeDevices.length === 0 && !showProgressSpinner" class="no-devices">
    No devices connected.
  </div>
  <div class="row">
    <div
      *ngFor="let device of stripeDevices"
      class="col-md-4 col-sm-6 mb-4"
      [ngClass]="{'selected': selectedDeviceId === device.id}"
      (click)="selectDevice(device.id)">
      <div class="device-tile">
        <span class="tile-label">{{ device.attributes.name }}</span>
        <span class="tile-subtext">{{ device.attributes.model }} - {{ device.attributes.version }}</span>
        <div class="device-details">
          <p>Battery: {{ device.battery }}%</p>
          <p *ngIf="device.wifi">{{ device.wifi.ssid }} ({{ device.wifi.signal_strength.value }}/5)</p>
          <p *ngIf="!device.wifi">Ethernet Connected</p>
        </div>
        <span class="device-status">{{ device.status.category }}</span>
      </div>
    </div>
  </div>
  <div *ngIf="errorMessage" class="error-message">
    {{ errorMessage }}
  </div>
</div>
