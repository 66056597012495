<mat-card class="configuration-card">
  <mat-card-header>
    <mat-card-title>AMI Reporting Settings</mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <div>
      <mat-progress-spinner class="matProgressSpinnerCentered" *ngIf="showProgressSpinner" mode="indeterminate"></mat-progress-spinner>
      <form class="col-12" [formGroup]="amiReportingForm">
        <app-success-message [message]=this.successMessage></app-success-message>
        <app-error-message [message]=this.errorMessage></app-error-message>
        <div class="col-12">
          <mat-form-field class="full-width-field" appearance="outline">
            <mat-label>AMI Address</mat-label>
            <input matInput formControlName="amiAddress" [readonly]="!hasAdminAccess()">
            <mat-icon matSuffix *ngIf="!hasAdminAccess()">lock</mat-icon>
          </mat-form-field>
        </div>
        <div class="col-12">
          <mat-form-field class="full-width-field" appearance="outline">
            <mat-label>Client ID</mat-label>
            <input matInput formControlName="clientId" [readonly]="!hasAdminAccess()">
            <mat-icon matSuffix *ngIf="!hasAdminAccess()">lock</mat-icon>
          </mat-form-field>
        </div>
        <div class="col-12">
          <mat-form-field class="full-width-field" appearance="outline">
            <mat-label>Grant Type</mat-label>
            <input matInput formControlName="grantType" [readonly]="!hasAdminAccess()">
            <mat-icon matSuffix *ngIf="!hasAdminAccess()">lock</mat-icon>
          </mat-form-field>
        </div>
        <div class="col-12">
          <mat-form-field class="full-width-field" appearance="outline">
            <mat-label>Client Secret</mat-label>
            <input matInput formControlName="clientSecret" [readonly]="!hasAdminAccess()">
            <mat-icon matSuffix *ngIf="!hasAdminAccess()">lock</mat-icon>
          </mat-form-field>
        </div>
        <div class="col-12">
          <mat-form-field class="full-width-field" appearance="outline">
            <mat-label>Username</mat-label>
            <input matInput formControlName="username" [readonly]="!hasAdminAccess()">
            <mat-icon matSuffix *ngIf="!hasAdminAccess()">lock</mat-icon>
          </mat-form-field>
        </div>
        <div class="col-12">
          <mat-form-field class="full-width-field" appearance="outline">
            <mat-label>Password</mat-label>
            <input matInput formControlName="password" [readonly]="!hasAdminAccess()">
            <mat-icon matSuffix *ngIf="!hasAdminAccess()">lock</mat-icon>
          </mat-form-field>
        </div>
      </form>
    </div>
  </mat-card-content>
  <mat-card-actions align="end">
    <button type="button" [disabled]="!amiReportingForm.valid || !hasAdminAccess()" (click)="actionType === 'create' ? onSubmit() : onUpdate()" mat-raised-button color="accent">
      {{ actionType === 'create' ? 'Save' : 'Update' }}
    </button>
  </mat-card-actions>
</mat-card>
