import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-idance-import',
  templateUrl: './idance-import.component.html',
  styleUrls: ['./idance-import.component.css']
})
export class IdanceImportComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
