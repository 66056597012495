<app-entity-mat-dialog-title title="Cancel Future Services"></app-entity-mat-dialog-title>

<form>

<h2></h2>

<app-error-message [message]=this.errorMessage></app-error-message>

<div style="min-height:150px" *ngIf="showProgressSpinner">
    <mat-progress-spinner class="matProgressSpinnerCentered"
        *ngIf="showProgressSpinner" mode="indeterminate">
    </mat-progress-spinner>
</div>

<form (ngSubmit)="onSubmit(f)" #f="ngForm" class="form"></form>
