<!-- update-status-group-lesson.component.html -->
<h2>Update Group Lesson status:</h2><!-- SINGLE GROUP LESSON -->

<app-success-message [message]=this.successMessage></app-success-message>
<app-error-message [message]=this.errorMessage></app-error-message>

<form (ngSubmit)="onSubmit(f)" #f="ngForm" class="form">
  <div class="container">
    <div class="row">
			<div class="col-6">
        <button class="me-1" type="button" [disabled]="!f.valid || objLength(eventData?.studentsDashboardLinks) == 0" (click)="setAllStatus(59);" mat-raised-button
          color="primary">Set All Showed</button>
					<button class="me-1" type="button" [disabled]="!f.valid || objLength(eventData?.studentsDashboardLinks) == 0" (click)="setAllStatus(64);" mat-raised-button
						color="secondary">Set All Pending</button>
			</div>
		</div>

		<div class="row">

			<div class="alert alert-primary" role="alert" *ngIf="objLength(eventData?.studentsDashboardLinks) == 0">
				There are no attendees to this event.
			</div>

			<table class="p-0 mt-3">
					<!-- {{this._taxonomyService.lesson_status | json}} -->

				<!-- <thead>
					<tr class="mat-header-row">
							<th class="mat-header-cell">
								Student
						</th>
						<th class="mat-header-cell">
								Status
						</th>
					</tr>
				</thead> -->

				<tr class="mat-row" *ngFor="let attendee of eventData?.studentsDashboardLinks">

						<td class="mat-cell">
							{{ attendee.name }}
						</td>

						<td class="mat-cell">
							<mat-form-field class="full-width-field">
								<mat-label>Status</mat-label>
								<mat-select
                  required
                  [ngModel]="lookupIDByStatus(attendee.status)"
                  name="{{ attendee.attendees_id }}">
                  <!-- name="{{'selectedStatus[' + attendee.id + ']'}}"> -->
									<mat-option>None</mat-option>
									<mat-option
										*ngFor="let element of this._taxonomyService.lesson_status"
										[disabled]="element.name != 'Pending Status' && element.name != 'Showed'"
										[value]="element.id">
											{{element.name}}
									</mat-option>
								</mat-select>
							</mat-form-field>
						</td>

				</tr>

        <!-- {{ f.value | json }} -->

      <div class="col-5 mt-2">
        <button type="button" [disabled]="!f.valid || objLength(eventData?.studentsDashboardLinks) == 0" (click)="onSubmit(f);" mat-raised-button
          color="accent">Save</button>
      </div>

			</table>
    </div>
  </div>
</form>
