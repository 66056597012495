<div class="row">
	<ng-container *ngIf="instructor_list">
			<div
			*ngFor="let instructor of instructor_list; let i = index;"
			class="mb-2 mt-2"
			style="border: 1px solid #ccc; padding: 0 12px; margin-bottom: 12px;"
			>
					{{ instructor.field_student_account }}

					<!-- <button type="button"
							class="me-1 float-end"
							color="warning" (click)="studentRemove(i)">
					</button> -->
					<!-- Remove attendee -->
					<mat-icon
							class="me-1 float-end"
							(click)="instructorRemove(i)"
							aria-hidden="false"
							aria-label="trash">cancel</mat-icon>

					<!-- <button type="button"
							mat-raised-button
							class="me-1 float-end"
							color="warning" (click)="studentUpdateLoad(i, attendee)">
					</button> -->
					<!-- Edit attendee -->
					<mat-icon
							class="me-1 float-end"
							(click)="instructorUpdateLoad(i, instructor)"
							aria-hidden="false"
							aria-label="edit">edit</mat-icon>
			</div>
	</ng-container>
</div>
