import { HttpClient, HttpEventType, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { EntityRESTService } from 'src/app/services/entity-rest.service';

interface ImportResult {
  lineNumber: number;
  message: string;
  type: 'success' | 'error';
}

@Component({
  selector: 'app-custom-import',
  templateUrl: './custom-import.component.html',
  styleUrls: ['./custom-import.component.css']
})
export class CustomImportComponent implements OnInit {

  selectedFile: File | null = null;
  selectedFiles: { [key: string]: File } = {};

  errorMessages: { [key: string]: string } = {};
  successMessages: { [key: string]: string } = {};

  studentAccounts: File | null = null;
  packages: File | null = null;
  event: File | null = null;
  payment: File | null = null;
  importResults: { [key: string]: ImportResult[] } = {};
  isLoading: boolean = false;

  form: any;

  configs = [
    {
      icon: 'person',
      title: 'Student Accounts',
      field: 'studentAccounts',
      templateUrl: '/assets/importTemplateCSV/student_accounts.csv',
      downloadName: 'Student_Accounts_Template.csv',
      eckType: 'student_account',
      bundle: 'student_accounts',
    },
    {
      icon: 'box',
      title: 'Packages',
      field: 'packages',
      templateUrl: '/assets/importTemplateCSV/packages.csv',
      downloadName: 'Packages_Template.csv',
      eckType: 'packages',
      bundle: 'package',
    },
    {
      icon: 'calendar-event',
      title: 'Events',
      field: 'events',
      templateUrl: '/assets/importTemplateCSV/events.csv',
      downloadName: 'Events_Template.csv',
      eckType: 'event',
      bundle: 'lesson',
    },
    {
      icon: 'credit-card',
      title: 'Enrollment Agreements',
      field: 'enrollments',
      templateUrl: '/assets/importTemplateCSV/enrollments.csv',
      downloadName: 'Enrollment_Template.csv',
      eckType: 'packages',
      bundle: 'enrollment'
    },
    {
      icon: 'credit-card',
      title: 'Payments',
      field: 'payments',
      templateUrl: '/assets/importTemplateCSV/payments.csv',
      downloadName: 'Payments_Template.csv',
      eckType: 'payments',
      bundle: 'payment',
    }
  ];

  constructor(private fb: FormBuilder, private http: HttpClient, private _entityRESTService: EntityRESTService) {
    const formControls = this.configs.reduce((controls, config) => {
      controls[config.field] = [null, Validators.required];
      return controls;
    }, {} as { [key: string]: any });

    this.form = this.fb.group(formControls);
  }

  onFileSelected(event: any, fileType: string) {
    const file = event.target.files[0];
    if (file) {
      this.selectedFiles[fileType] = file;
      this.form.get(fileType).setValue(file.name, { emitModelToViewChange: false });
    }

    // Clear the input element
    event.target.value = null;
  }

  allFilesSelected(): boolean {
    return Object.keys(this.selectedFiles).length === this.configs.length;
  }

  onSubmit(config: any): void {
    console.log('Config:', config);
    const file = this.selectedFiles[config.field];
    if (file) {
      this.uploadFile(file, config);
    } else {
      console.error('No file selected for', config.field);
    }
  }

  uploadFile(file: File, config: any) {
    const reader = new FileReader();
    this.importResults[config.field] = []; // Initialize results array for this field
    this.isLoading = true;

    reader.onload = (e: any) => {
      const text = e.target.result;
      const lines = text.split('\n').map(line => line.trim()).filter(line => line);
      const headers = lines[0].split(',').map(header => header.trim());
      const rows = lines.slice(1).map(line => line.split(','));

      rows.forEach((row, index) => {
        const lineNumber = index + 2; // +2 because array is 0-indexed and we skipped the header
        const values = headers.reduce((obj, header, index) => {
          obj[header] = row[index].trim();
          return obj;
        }, {} as any);

        if (!values.title) {
          values.title = 'default title';
        }

        console.log('Creating entity:', values)
        this._entityRESTService.createEntity(config.eckType, config.bundle, values)
          .subscribe(response => {
            this.isLoading = false;
            console.log('Entity created:', response);
            this.importResults[config.field].push({
              lineNumber,
              message: 'Entity created successfully!',
              type: 'success'
            });
          }, error => {
            this.isLoading = false;
            console.error('Failed to create entity:', error);
            this.importResults[config.field].push({
              lineNumber,
              message: error.error.message || 'An unexpected error occurred',
              type: 'error'
            });
          });
      });
    };

    reader.onerror = (error) => {
      console.error('Error reading file:', error);
      this.importResults[config.field].push({
        lineNumber: 0,
        message: 'Error reading file',
        type: 'error'
      });
    };

    reader.readAsText(file);
  }
  ngOnInit(): void {
  }
}
