<div style="height:64px;" class="page-empty-header"></div>

<div class="container-fluid">
	<div class="row">
		<div style="background:#215DAB; height: calc(100vh - 64px); overflow: scroll;" class="col-2">
			<app-configuration-dashboard-menu></app-configuration-dashboard-menu>
		</div>

		<div class="col-10" style="padding-left: 2.5rem;padding-right: 2.5rem;padding-top:2.5rem;overflow:scroll;max-height: calc(100vh - 64px);">

			<div class="container-fluid">
				<h1 class="">Square Integration</h1>

				<div class="row">
					<div class="col-8">
						<app-form-square-settings></app-form-square-settings>
						<app-square-connected-devices></app-square-connected-devices>
					</div>

					<div class="col-4">
						<!-- OAuth Guide Section -->
						<div class="oauth-guide-section">
							<h2>Connecting Your Square Account</h2>
							<p>To use Square with Agenda Master, you need to authorize access to your Square account. Follow these steps to get started:</p>
							<ol>
								<li><strong>Authorize Square:</strong>
									<p>Click on the <strong>"Authorize Square"</strong> tile below. This will redirect you to the Square website, where you will be asked to log in and allow access to your Square account.</p>
								</li>
								<li><strong>Log in to Square:</strong>
									<p>If you already have a Square account, enter your login credentials on the Square page. If you don't have an account, you can create one during this process.</p>
								</li>
								<li><strong>Grant Permission:</strong>
									<p>After logging in, Square will ask if you want to allow Agenda Master to access your account. Review the permissions and click <strong>"Allow"</strong> to proceed.</p>
								</li>
								<li><strong>Confirmation:</strong>
									<p>Once authorized, you will be redirected back to Agenda Master, where you’ll see a confirmation message indicating that you are now connected to Square.</p>
								</li>
							</ol>
							<h3>Disconnecting Your Square Account</h3>
							<p>If you ever need to disconnect your Square account, follow these steps:</p>
							<ol>
								<li><strong>Deauthorize Square:</strong>
									<p>Click on the <strong>"Deauthorize Square"</strong> tile. This will revoke the application's access to your Square account.</p>
								</li>
								<li><strong>Confirmation:</strong>
									<p>You will see a message confirming that your Square account has been successfully disconnected.</p>
								</li>
							</ol>
							<p>If you encounter any issues, please refer to our <a href="/contact-support" target="_blank">support page</a> or visit the <a href="https://developer.squareup.com/docs/oauth-api" target="_blank">Square OAuth Documentation</a> for more details.</p>
						</div>

						<!-- Support Links Section -->
						<!-- <div class="support-links-section">
							<h2>Support Links</h2>
							<ul class="support-links-list">
								<li><a href="https://squareup.com/help/us/en/article/5434-set-up-hardware" target="_blank">How to Pair Devices with Square</a></li>
								<li><a href="https://developer.squareup.com/docs" target="_blank">Square Payment Processing Documentation</a></li>
								<li><a href="/contact-support" target="_blank">Contact Support</a></li>
							</ul>
						</div> -->
					</div>
					<div class="col-8">
					</div>
					<div class="col-4">
					</div>
			</div>

		</div>

	</div>
</div>
