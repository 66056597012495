<form (ngSubmit)="onSubmit(f)" #f="ngForm" class="form" >
<div mat-dialog-title>
    <h1>Add Schedules</h1>
    <button mat-button mat-dialog-close><mat-icon aria-hidden="false" aria-label="Example home icon">close</mat-icon></button>
</div>
<div mat-dialog-content>
        <div class="container">
            <div class="row">

                <div class="col-6">
                    <mat-form-field class="full-width-field">
                        <mat-label>Schedule Type</mat-label>
                        <mat-select
                            ngModel
                            name="field_schedule_type">
                            <mat-option>None</mat-option>
                            <mat-option *ngFor="let type of schedule_type" [value]="type.id">{{ type.value }}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>

                <div class="col-6">
                    <app-instructor-list-dropdown></app-instructor-list-dropdown>
                </div>

                <div class="col-12">
                    <mat-form-field class="full-width-field">
                        <mat-label>Date and Time</mat-label>
                        <input
                            ngModel
                            matInput
                            [matDatepicker]="field_date_and_time"
                            name="field_date_and_time">
                        <mat-datepicker-toggle matSuffix [for]="field_date_and_time"></mat-datepicker-toggle>
                        <mat-datepicker #field_date_and_time></mat-datepicker>
                    </mat-form-field>
                </div>

                <div class="col-12">
                    <app-duration-dropdown></app-duration-dropdown>
                </div>

                <div class="col-12">
                    <app-standing-appointment [Title]=standingAppointmentTitle></app-standing-appointment>
                </div>

                <div class="col-12">
                    <mat-form-field class="full-width-field">
                        <mat-label>Notes</mat-label>
                        <textarea ngModel name="field_notes" matInput placeholder=""></textarea>
                    </mat-form-field>
                </div>

            </div>

        </div>
    </div>
    <mat-dialog-actions align="end">
        <button onclick="onSubmit(f)" type="submit" mat-raised-button color="accent">Save and Schedule Service</button>
    </mat-dialog-actions>
</form>