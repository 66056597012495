import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { ViewTableComponent } from '../../base/view-table/view-table.component';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import moment from 'moment';

@Component({
  selector: 'app-chat-success-rate',
  templateUrl: './chat-success-rate.component.html',
  styleUrls: ['./chat-success-rate.component.css']
})
export class ChatSuccessRateComponent extends ViewTableComponent implements OnInit {

  override displayedColumns: string[] = [
    'name',
    'role',
    'service_type',
    'success_rate',
  ];

  override getData(params: { parameter: string; value: string; }[]) {
    // Show Progress Bar (Loading indicator)
    this.ShowProgressBar = true;
    this.queryParams = params;

    // Add sorting paramaters, if available.
    if (this.sortParams !== undefined) {
      this.queryParams.push(this.sortParams)
    }

    // Load the new data with the filtering parameters.
    this._utilityService.getChatSuccessRateReport(
      this.queryParams
    )
      .subscribe((data) => {
        // ShowProgressBar
        this.ShowProgressBar = false;

        this.data = data['#rate_table']['success-rate']['#rows'];
        this.dataSource = new MatTableDataSource(data['#rate_table']['success-rate']['#rows']);

        console.log("data['#rate_table']['success-rate']['#rows']")
        console.log(data['#rate_table']['success-rate']['#rows'])

        // Pagination
        if (data['results']) {
          this.paginator.length = data['count'];
          this.paginator.pageSize = this.pageSize;
          this.paginator.pageIndex = this.paginationIndex;
        }
      },
        error => this.handleError(error)
      )
  }

}
