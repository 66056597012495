<mat-dialog-content style="padding-top: 16px;
display: flex;
flex-wrap: wrap;
flex-direction: row;
align-items: flex-end;
justify-content: center;
align-content: center;
height: 100%;">
	<h3 class="text-center">
		Please swipe the credit card.
	</h3>
	<mat-progress-bar mode="indeterminate"></mat-progress-bar>

	<form>
		<input (input)="ccInput($event)" #inputCreditCardScan class="cdk-visually-hidden" id="inputCreditCardScan" style="width:100%;">
	</form>

</mat-dialog-content>
<mat-dialog-actions align="center">
  <button style="background-color:white !important; color:black;" mat-button mat-dialog-close>Cancel</button>
</mat-dialog-actions>
