<mat-card class="configuration-card">
  <mat-card-header>
    <mat-card-title>Studio Information</mat-card-title>
  </mat-card-header>

  <mat-card-content>
    <div>
      <mat-progress-spinner class="matProgressSpinnerCentered" *ngIf="showProgressSpinner" mode="indeterminate"></mat-progress-spinner>

      <form class="col-12" [formGroup]="studioInformationForm">
        <app-success-message [message]="this.successMessage"></app-success-message>
        <app-error-message [message]="this.errorMessage"></app-error-message>

        <div class="row">
          <div class="col-12">
            <mat-form-field class="full-width-field" appearance="outline">
              <mat-label>Studio Display Name</mat-label>
              <input matInput formControlName="studioDisplayName">
            </mat-form-field>
          </div>
        </div>

        <div class="row">
          <div class="col-12">
            <mat-form-field class="full-width-field" appearance="outline">
              <mat-label>Studio Email</mat-label>
              <input matInput formControlName="studioEmail">
              <mat-error *ngIf="studioInformationForm.get('studioEmail').invalid">
                Please enter a valid email address.
              </mat-error>
            </mat-form-field>
          </div>
        </div>

        <div class="row">
          <div class="col-12">
            <mat-form-field class="full-width-field" appearance="outline">
              <mat-label>Studio Phone Number</mat-label>
              <input appPhoneNumberFormatter matInput formControlName="studioPhoneNumber">
            </mat-form-field>
          </div>
        </div>

        <div formGroupName="studioWebsite">
          <div class="row">
            <div class="col-12">
              <mat-form-field class="full-width-field" appearance="outline">
                <mat-label>Website URI</mat-label>
                <input matInput formControlName="uri">
              </mat-form-field>
            </div>
          </div>

          <div class="row">
            <div class="col-12">
              <mat-form-field class="full-width-field" appearance="outline">
                <mat-label>Website Title</mat-label>
                <input matInput formControlName="title">
              </mat-form-field>
            </div>
          </div>
        </div>

        <div formGroupName="studioAddress">
          <div class="row">
            <div class="col-12">
              <mat-form-field class="full-width-field" appearance="outline">
                <mat-label>Country</mat-label>
                <mat-select formControlName="country">
                  <mat-option *ngFor="let country of this._fieldsService.countries" [value]="country.abbreviation">{{ country.name }}</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>

          <div class="row">
            <div class="col-6">
              <mat-form-field class="full-width-field" appearance="outline">
                <mat-label>First Name</mat-label>
                <input matInput formControlName="givenName">
              </mat-form-field>
            </div>

            <div class="col-6">
              <mat-form-field class="full-width-field" appearance="outline">
                <mat-label>Last Name</mat-label>
                <input matInput formControlName="familyName">
              </mat-form-field>
            </div>
          </div>

          <div class="row">
            <div class="col-12">
              <mat-form-field class="full-width-field" appearance="outline">
                <mat-label>Company</mat-label>
                <input matInput formControlName="company">
              </mat-form-field>
            </div>
          </div>

          <div class="row">
            <div class="col-12">
              <mat-form-field class="full-width-field" appearance="outline">
                <mat-label>Street Address</mat-label>
                <input matInput formControlName="streetAddress">
              </mat-form-field>
            </div>
          </div>

          <div class="row">
            <div class="col-6">
              <mat-form-field class="full-width-field" appearance="outline">
                <mat-label>City</mat-label>
                <input matInput formControlName="city">
              </mat-form-field>
            </div>

            <div class="col-6">
              <mat-form-field class="full-width-field" appearance="outline">
                <mat-label>State</mat-label>
                <mat-select formControlName="state">
                  <mat-option *ngFor="let state of this._fieldsService.StatesAbbreviations" [value]="state.abbreviation">{{ state.name }}</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>

          <div class="row">
            <div class="col-12">
              <mat-form-field class="full-width-field" appearance="outline">
                <mat-label>Zip Code</mat-label>
                <input matInput formControlName="zipCode">
              </mat-form-field>
            </div>
          </div>

          </div>

          <div class="row">
            <div class="col-12">
              <mat-form-field class="full-width-field" appearance="outline">
                <mat-label>Time Zone</mat-label>
                <mat-select formControlName="studioTimeZone">
                  <mat-option *ngFor="let timeZone of timeZones" [value]="timeZone">{{ timeZone }}</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>

      </form>
    </div>
  </mat-card-content>

  <mat-card-actions align="end">
    <button type="button"
            [disabled]="!studioInformationForm.valid"
            (click)="actionType === 'create' ? onSubmit() : onUpdate()"
            mat-raised-button
            color="accent">
      {{ actionType === 'create' ? 'Save' : 'Update' }}
    </button>
  </mat-card-actions>
</mat-card>
