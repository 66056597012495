<!-- cancel-reschedule.component.html -->
<app-entity-mat-dialog-title title="Cancel/Reschedule"></app-entity-mat-dialog-title>

<h2>What date would you like to reschedule to?</h2>

<app-error-message [message]=this.errorMessage></app-error-message>

<form (ngSubmit)="onSubmit(f)" #f="ngForm" class="form">
  <div class="container">
    <div class="row">

      <div class="col-12">
          <mat-form-field>
          <input ngModel
          (ngModelChange)="uiUpdateDate($event)"
          name="field_date_and_time" matInput [ngxMatDatetimePicker]="picker_field_date_time"
                          placeholder="Choose a date">
          <mat-datepicker-toggle matSuffix [for]="picker_field_date_time">
          </mat-datepicker-toggle>
          <ngx-mat-datetime-picker #picker_field_date_time
              [defaultTime]="field_date_and_time_default_time"
              [hideTime]="false"
              [enableMeridian]="true"
              [showSpinners]="true"
              [showSeconds]="false"
              [stepHour]="1" [stepMinute]="15"
              [touchUi]="false">
          </ngx-mat-datetime-picker>
          </mat-form-field>
      </div>

      <div class="col-12">
        <mat-form-field>
          <textarea ngModel name="field_reason_for_canceling" matInput placeholder="Cancellation/Reschedule Reason"></textarea>
        </mat-form-field>
      </div>

      <div class="col-12">
        <p>Leave the date time field empty if you want to cancel.</p>
      </div>

      <div class="col-5">
        <button type="button" (click)="onSubmit(f);" mat-raised-button
          color="accent">Save</button>
      </div>
    </div>
  </div>
</form>
