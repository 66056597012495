<!-- form-stripe-settings.component.html -->
<mat-card class="configuration-card">
  <mat-card-header>
    <mat-card-title>Stripe Settings</mat-card-title>
  </mat-card-header>

  <mat-card-content>
    <div>
      <mat-progress-spinner class="matProgressSpinnerCentered" *ngIf="showProgressSpinner" mode="indeterminate"></mat-progress-spinner>

      <form class="col-12" [formGroup]="stripeSettingsForm">
        <app-success-message [message]=this.successMessage></app-success-message>
        <app-error-message [message]=this.errorMessage></app-error-message>

        <div class="col-12">
          <mat-form-field class="full-width-field" appearance="outline">
            <mat-label>Stripe Secret Key (Test)</mat-label>
            <input matInput formControlName="stripeSecretKeyTest">
          </mat-form-field>
        </div>

        <div class="col-12">
          <mat-form-field class="full-width-field" appearance="outline">
            <mat-label>Stripe Secret Key (Live)</mat-label>
            <input matInput formControlName="stripeSecretKeyLive">
          </mat-form-field>
        </div>

        <div class="col-12">
          <mat-form-field class="full-width-field" appearance="outline">
            <mat-label>Stripe Public Key (Test)</mat-label>
            <input matInput formControlName="stripePublicKeyTest">
          </mat-form-field>
        </div>

        <div class="col-12">
          <mat-form-field class="full-width-field" appearance="outline">
            <mat-label>Stripe Public Key (Live)</mat-label>
            <input matInput formControlName="stripePublicKeyLive">
          </mat-form-field>
        </div>

        <div class="col-12">
          <mat-form-field class="full-width-field" appearance="outline">
            <mat-label>Stripe Mode</mat-label>
            <mat-select formControlName="stripeMode">
              <mat-option *ngFor="let mode of stripeModes" [value]="mode">
                {{mode}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </form>
    </div>
  </mat-card-content>

  <mat-card-actions align="end">
    <button type="button"
            [disabled]="!stripeSettingsForm.valid"
            (click)="actionType === 'create' ? onSubmit() : onUpdate()"
            mat-raised-button
            color="accent">
      {{ actionType === 'create' ? 'Save' : 'Update' }}
    </button>
  </mat-card-actions>
</mat-card>
