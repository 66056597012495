<app-entity-mat-dialog-title title="Drop Enrollment"></app-entity-mat-dialog-title>

<!-- <app-success-message [message]=this.successMessage></app-error-message> -->
<app-error-message [message]=this.errorMessage></app-error-message>

<div style="min-height:150px" *ngIf="showProgressSpinner">
    <mat-progress-spinner class="matProgressSpinnerCentered"
        *ngIf="showProgressSpinner" mode="indeterminate">
    </mat-progress-spinner>
</div>

<ng-container *ngIf="droppedData?.allLessonsTakenAndPaid">
	<app-warning-message [message]="'This enrollment can\'t be dropped because the enrollment has been fulfilled.'"></app-warning-message>
</ng-container>

<ng-container *ngIf="this.enrollmentEntityData?.field_dropped_date != ''">
	<app-warning-message [message]="'This enrollment has already been dropped.'"></app-warning-message>
</ng-container>

<form (ngSubmit)="onSubmit(f)" #f="ngForm" class="form">
  <div class="container">
      <div class="row">
					<div class="col-12">
						<mat-form-field>
							<!-- [ngModel]="now" -->
						<input
						ngModel
						required
						name="drop_date" matInput [ngxMatDatetimePicker]="field_dropped_date" placeholder="Drop Date">
						<mat-datepicker-toggle matSuffix [for]="field_dropped_date">
						</mat-datepicker-toggle>
						<ngx-mat-datetime-picker #field_dropped_date
								[hideTime]="true"
								[enableMeridian]="true"
								[showSpinners]="true"
								[showSeconds]="false"
								[stepHour]="1" [stepMinute]="15"
								[touchUi]="false">
						</ngx-mat-datetime-picker>
						</mat-form-field>
				</div>

				<div class="col-12">
					<mat-form-field class="full-width-field">
							<mat-label>Total Price</mat-label>
							<input
								required
								readonly
								name="field_drop"
								matInput
								type="number"
								ngModel="{{droppedData?.['total_price']}}">
					</mat-form-field>
			</div>

			<div class="col-12">
				<mat-form-field *ngIf="droppedData?.['total_dropped']" class="full-width-field">
						<mat-label>Total Dropped</mat-label>
						<input
							required
							readonly
							name="field_total_dropped"
							matInput
							type="number"
							ngModel="{{ droppedData?.['total_dropped'] }}">
				</mat-form-field>
			</div>

    </div>

  </div>
</form>

<!-- Refund calculation -->
<ng-container *ngIf="this.enrollmentEntityData">
	<mat-card>
		<mat-card-title>
				Drop & Refund Calculations
		</mat-card-title>
		<p>Lessons Price: ${{ this.enrollmentEntityData?.field_enrollment_lesson_price }}</p>
		<p>Lessons Paid and Taken: {{ this.enrollmentEntityData?.field_lesson_used }}</p>
		<p>Lessons Available: {{ this.enrollmentEntityData.field_enrollment_lesson_count - this.enrollmentEntityData.field_lesson_used }}</p>
		<p>Taxes: {{ this.enrollmentEntityData.field_taxes }}</p>
		<!-- this.enrollmentEntityData.field_enrollment_lesson_count -->
		<p>Estimated Refund: ${{ this.estimatedRefund }}</p>
	</mat-card>
</ng-container>

<mat-dialog-actions align="end">
        <button [disabled]="!this.f.valid || droppedData?.allLessonsTakenAndPaid || (this.enrollmentEntityData?.field_dropped_date != '')" type="button" (click)="onSubmit(f);" mat-raised-button
            color="accent">Drop</button>
				<button [disabled]="!this.f.valid || this.estimatedRefund >= 0 || droppedData?.allLessonsTakenAndPaid || (this.enrollmentEntityData?.field_dropped_date != '')" type="button" (click)="onSubmit(f, true);" mat-raised-button
            color="accent">Drop & Refund</button>
      <button mat-dialog-close type="submit" mat-raised-button
            color="accent">Cancel</button>
</mat-dialog-actions>
