import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { ViewTableComponent } from '../../base/view-table/view-table.component';

@Component({
  selector: 'app-canceled-events',
  templateUrl: './canceled-events.component.html',
  styleUrls: [
    './canceled-events.component.css',
    '../../../app.component.css'
  ]
})
export class CanceledEventsComponent extends ViewTableComponent implements OnInit {

  override displayedColumns: string[] = [
    'title',
    'field_type',
    'field_date_and_time',
    'type',
    'edit_events',
  ];
  override getData(params: { parameter: string; value: string; }[]) {
    // Show Progress Bar (Loading indicator)
    this.ShowProgressBar = true;
    this.queryParams = params;

    // Add sorting paramaters, if available.
    if (this.sortParams !== undefined) {
      this.queryParams.push(this.sortParams)
    }

    // Load the new data with the filtering parameters.
    this._utilityService.getCanceledEventsView(
      this.queryParams
    )
      .subscribe((data) => {
        // ShowProgressBar
        this.ShowProgressBar = false;

        this.data = data;
        this.dataSource = new MatTableDataSource(data['results']);

        // Pagination
        if (data['results']) {
          this.paginator.length = data['count'];
          this.paginator.pageSize = this.pageSize;
          this.paginator.pageIndex = this.paginationIndex;
        }
      },
        error => this.handleError(error)
      )
  }

}
