import { Injectable } from '@angular/core';
import { DrupalRESTService } from './drupal-rest.service';
import { Observable } from 'rxjs';
import { EntityRESTService } from './entity-rest.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  private userEndpoint = '/api_user/v1/access';

  constructor(
    private _drupalRESTService: DrupalRESTService,
    private _entityRESTService: EntityRESTService,
    private _snackBar: MatSnackBar,
    ) {}

  // Method to get a user or users
  getUsers(params: any): Observable<any> {
    return this._drupalRESTService.httpGET(this.userEndpoint, params);
  }

  // Method to create a new user
  createUser(userData: any): Observable<any> {
    return this._drupalRESTService.httpPOST(this.userEndpoint, userData);
  }

  // Method to update a user
  updateUser(userId: string, userData: any): Observable<any> {
    const updateEndpoint = `${this.userEndpoint}`;
    return this._drupalRESTService.httpPATCH(updateEndpoint, userData);
  }

  // Method to delete a user
  deleteUser(id: number) {
    return new Observable((observer) => {
      this._entityRESTService.deleteEntity('user', '', id).subscribe(
        () => {
          this.getUsers({});
          observer.next();
          observer.complete();
        },
        error => {
          console.error('Error deleting user:', error);
          this._snackBar.open('Error deleting user. ' + error?.statusText, 'Close', { duration: 3000 });
          observer.error(error);
        }
      );
    });
  }
}
