<mat-card class="configuration-card">
  <mat-card-header>
    <mat-card-title>Twilio Settings</mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <div>
      <mat-progress-spinner class="matProgressSpinnerCentered" *ngIf="showProgressSpinner" mode="indeterminate"></mat-progress-spinner>
      <form class="col-12" [formGroup]="twilioSettingsForm">
        <app-success-message [message]=this.successMessage></app-success-message>
        <app-error-message [message]=this.errorMessage></app-error-message>

        <div class="col-12">
          <mat-form-field class="full-width-field" appearance="outline">
            <mat-label>Twilio Account SID (Test)</mat-label>
            <input matInput formControlName="twilioAccountSIDTest">
          </mat-form-field>
        </div>

        <div class="col-12">
          <mat-form-field class="full-width-field" appearance="outline">
            <mat-label>Twilio Account SID (Live)</mat-label>
            <input matInput formControlName="twilioAccountSIDLive">
          </mat-form-field>
        </div>

        <div class="col-12">
          <mat-form-field class="full-width-field" appearance="outline">
            <mat-label>Twilio Auth Token (Test)</mat-label>
            <input matInput formControlName="twilioAuthTokenTest">
          </mat-form-field>
        </div>

        <div class="col-12">
          <mat-form-field class="full-width-field" appearance="outline">
            <mat-label>Twilio Auth Token (Live)</mat-label>
            <input matInput formControlName="twilioAuthTokenLive">
          </mat-form-field>
        </div>

        <div class="col-12">
          <mat-form-field class="full-width-field" appearance="outline">
            <mat-label>Twilio Phone Number (Test)</mat-label>
            <input matInput formControlName="twilioPhoneNumberTest">
          </mat-form-field>
        </div>

        <div class="col-12">
          <mat-form-field class="full-width-field" appearance="outline">
            <mat-label>Twilio Phone Number (Live)</mat-label>
            <input matInput formControlName="twilioPhoneNumberLive">
          </mat-form-field>
        </div>

        <div class="col-12">
          <mat-form-field class="full-width-field" appearance="outline">
            <mat-label>Twilio Mode</mat-label>
            <mat-select formControlName="twilioMode">
              <mat-option *ngFor="let mode of twilioModes" [value]="mode">
                {{mode}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </form>
    </div>
  </mat-card-content>
  <mat-card-actions align="end">
    <button type="button" [disabled]="!twilioSettingsForm.valid" (click)="actionType === 'create' ? onSubmit() : onUpdate()" mat-raised-button color="accent">
      {{ actionType === 'create' ? 'Save' : 'Update' }}
    </button>
  </mat-card-actions>
</mat-card>
