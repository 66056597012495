export const environment = {
  production: true,
  name: "production",
  drupalUsername: "",
	location: "TBD",
  drupalPassword: "",
  drupalUrl: 'https://app.letsdance.tech',
  hostUrl: 'https://app.letsdance.tech',
  subFolder: '/app/',
  logRocketKey: 'i7mbrj/agendamaster-production'
};
