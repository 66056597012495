import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-integration-stripe',
  templateUrl: './integration-stripe.component.html',
  styleUrls: ['./integration-stripe.component.css']
})
export class IntegrationStripeComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
