import { Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { BaseDialogComponent } from '../base-dialog/base-dialog.component';

@Component({
  selector: 'app-credit-card-read-input',
  templateUrl: './credit-card-read-input.component.html',
  styleUrls: ['./credit-card-read-input.component.css'],
  // host: {
    // '(document:keydown)': 'ccBuildInput($event)'
  // }
})
export class CreditCardReadInputComponent extends BaseDialogComponent implements OnInit {

  @Output() cc_number = new EventEmitter<string>();
  @Output() cc_expiration_month = new EventEmitter<string>();
  @Output() cc_expiration_year = new EventEmitter<string>();
  @Output() cc_cvv = new EventEmitter<string>();

  @ViewChild('inputCreditCardScan') inputCreditCardScan!: ElementRef;

  firstName: any;
  lastName: any;
  decryptedCardNumber: string;
  cardNumber: string;
  expiration_month: any;
  expiration_year: any;
  cardType: string;
  ccReadInput: string = "";

  ngAfterViewChecked() {
    let onElement = this._renderer2.selectRootElement('#inputCreditCardScan');
    onElement.focus();
  }

  // ngAfterViewInit() {
  //   //using selectRootElement instead of deprecated invokeElementMethod
  //   this._renderer.selectRootElement(this.input["nativeElement"]).focus();
  //  }

  ccBuildInput(e: KeyboardEvent) {
    // console.log('e', e)
    // // store input in hidden input field.
    // this.inputCreditCardScan.nativeElement.value += e.key;
    // return true;

    // if (e.key == 'Shift' || e.key == 'Enter' || e.key == 'Meta') {
    //   console.log('e key is ?')
    //   return;
    // } else {
    //   console.log('e', e.key);
    //   this.ccReadInput += e.key;

    //   console.log('ccReadInput', this.ccReadInput);

    //   if (e.key == "?") {
    //     console.log('e key is ?')
    //     this.ccInput(this.ccReadInput);
    //   }
    // }
  }

  ccInput(input) {
    // console.log('INPUT', input)

    if (input.data == '?') {
      let ccNum = input.target.value;

      let isCaretPresent = false;
      let isEqualPresent = false;

      if (ccNum.indexOf("^") != -1) {
        isCaretPresent = true
      } else {
        isCaretPresent = false;
      }

      if (ccNum.indexOf("=") != -1) {
        isEqualPresent = true
      } else {
        isEqualPresent = false;
      }

      //handle parsing differently depending on card format
      let cardData;
      if (isCaretPresent) {
        cardData = ccNum.split('^');
      } else if (isEqualPresent) {
        cardData = ccNum.split('=');
      }

      this.firstName = this.formatFirstName(cardData[1]);
      this.lastName = this.formatLastName(cardData[1]);

      this.decryptedCardNumber = this.formatCardNumber(cardData[0]);
      this.cardNumber = this.decryptedCardNumber;
      this.cardType = this.getCardType(this.decryptedCardNumber);

      this.expiration_month = cardData[2].substring(2, 4);
      this.expiration_year = cardData[2].substring(0, 2);

      console.log(this.cardNumber);
      console.log(this.cardType);
      console.log(this.expiration_month);
      console.log(this.expiration_year);

      this.cc_number.emit(this.cardNumber);
      this.cc_expiration_month.emit(this.expiration_month);
      this.cc_expiration_year.emit(this.expiration_year);
      // this.cc_cvv = ;

      this.closeDialog({data: "test"});
      this._dialogRef.close({
          cc_number: this.cardNumber,
          cc_expiration_month: this.expiration_month,
          cc_expiration_year: this.expiration_year,
          cc_card_type: this.cardType,
          cc_expiration: this.expiration_month + '/' + this.expiration_year
        })
    }
  }


	formatCardNumber(cardNum) {

		var result = "";

		result = cardNum.replace(/[^0-9]*/, "");

		return result;
	}

	formatFirstName(name) {

		if (name.indexOf("/") != -1) {

			let nameSplit = name.split('/');

			return nameSplit[1];

		} else {
			return "";
		}
	}

	formatLastName(name) {

		if (name.indexOf("/") != -1) {

			let nameSplit = name.split('/');

			return nameSplit[0];

		} else {
			return "";
		}
	}

	getCardType(number) {

		let re = new RegExp("^4");
		if (number.match(re) != null)
			return "Visa";

		re = new RegExp("^(34|37)");
		if (number.match(re) != null)
			return "American Express";

		re = new RegExp("^5[1-5]");
		if (number.match(re) != null)
			return "MasterCard";

		re = new RegExp("^6011");
		if (number.match(re) != null)
			return "Discover";

		return "";
	}

}
