import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-cash-report-individual',
  templateUrl: './cash-report-individual.component.html',
  styleUrls: ['./cash-report-individual.component.css']
})
export class CashReportIndividualComponent implements OnInit {

  @Input() data = '';

  constructor() { }

  ngOnInit(): void {
  }

}
