import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-add-terminal-device-dialog',
  templateUrl: './add-terminal-device-dialog.component.html',
  styleUrls: ['./add-terminal-device-dialog.component.css']
})
export class AddTerminalDeviceDialogComponent {
  form: FormGroup;

  constructor(
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<AddTerminalDeviceDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { locationId: string }
  ) {
    this.form = this.fb.group({
      name: ['', Validators.required],
    });
  }

  onCancel(): void {
    this.dialogRef.close();
  }

  onSubmit(): void {
    if (this.form.valid) {
      this.dialogRef.close({
        name: this.form.get('name')?.value,
        location_id: this.data.locationId
      });
    }
  }
}
