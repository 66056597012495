<!-- form-square-settings.component.html -->
<mat-card class="configuration-card">
  <mat-card-header>
    <mat-card-title>
      Square Settings
      <span class="environment-badge" [ngClass]="currentEnvironment">{{ currentEnvironment }}</span>
      <mat-icon *ngIf="isAuthorized" class="status-icon connected">check_circle</mat-icon>
      <mat-icon *ngIf="!isAuthorized" class="status-icon not-connected">error</mat-icon>
    </mat-card-title>
  </mat-card-header>

  <mat-card-content>
    <div>
      <mat-progress-spinner
        class="matProgressSpinnerCentered"
        *ngIf="showProgressSpinner"
        mode="indeterminate">
      </mat-progress-spinner>

      <div class="col-12" *ngIf="!showProgressSpinner">
        <p *ngIf="isAuthorized" class="connection-status connected">
          You are connected to Square.
        </p>
        <p *ngIf="!isAuthorized" class="connection-status not-connected">
          You are not connected to Square. Please authorize below.
        </p>
      </div>

      <div class="tile-container">
        <div class="tile authorize-tile" (click)="authorizeSquare()" [class.disabled]="isAuthorized">
          <mat-icon class="tile-icon">vpn_key</mat-icon>
          <span class="tile-label">Authorize Square</span>
        </div>
        <div class="tile deauthorize-tile" (click)="deauthorizeSquare()" [class.disabled]="!isAuthorized">
          <mat-icon class="tile-icon">lock_open</mat-icon>
          <span class="tile-label">Deauthorize Square</span>
        </div>
        <div class="tile save-tile" (click)="onSubmit()" [class.disabled]="!isAuthorized">
          <mat-icon class="tile-icon">save</mat-icon>
          <span class="tile-label">Save Settings</span>
        </div>
      </div>

      <app-success-message [message]="successMessage"></app-success-message>
      <app-error-message [message]="errorMessage"></app-error-message>
    </div>
  </mat-card-content>
</mat-card>
