import { Injectable } from '@angular/core';
import { DrupalRESTService } from './drupal-rest.service';

@Injectable({
  providedIn: 'root'
})
export class MenuRESTService {

  constructor(
    private _drupalRESTService: DrupalRESTService
  ) { }

  loadedMenuItems: any = [];

  getMenu() {
    let endpoint = '/api/menu_items/main'
    return this._drupalRESTService.httpGET(endpoint)
      .subscribe(data => {
        this.loadedMenuItems = data;
      });

  }
}
