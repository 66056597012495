<div formArrayName="enrollments">
  <div *ngFor="let enrollment of enrollments.controls; let i = index" [formGroupName]="i">

    <div class="row">
      <h3>Enrollment {{ i + 1 }}</h3>

      <div class="col-2">
        <mat-form-field class="full-width-field" appearance="outline">
          <mat-label>Course Name</mat-label>
          <input matInput formControlName="courseName">
        </mat-form-field>
      </div>

      <div class="col-2">
        <mat-form-field class="full-width-field" appearance="outline">
          <mat-label>Start Date</mat-label>
          <input matInput formControlName="startDate" type="date">
        </mat-form-field>
      </div>

      <div class="col-2">
        <mat-form-field class="full-width-field" appearance="outline">
          <mat-label>End Date</mat-label>
          <input matInput formControlName="endDate" type="date">
        </mat-form-field>
      </div>

      <!-- Add additional fields as necessary -->

      <div class="col-1">
        <button type="button" (click)="deleteEnrollment(i)" class="btn btn-danger">
          <mat-icon>delete</mat-icon>
        </button>
      </div>

      <!-- Placeholder for error and success icons, adjust as needed based on component's functionality -->
      <div class="col-1 error-icon">
        <mat-icon matTooltip="Error message here" aria-label="Error icon">error_outline</mat-icon>
      </div>
      <div class="col-1 success-icon">
        <mat-icon>check_circle</mat-icon>
      </div>
    </div>
  </div>
</div>

<button type="button" mat-stroked-button (click)="addEnrollment()">Add Enrollment</button>

<!-- <app-simple-import-payments [paymentsArray]="paymentsArray"></app-simple-import-payments> -->
<!-- <app-simple-import-lessons [lessonsArray]="lessonsArray"></app-simple-import-lessons> -->
