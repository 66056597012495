import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { UtilityService } from 'src/app/services/utility.service';

@Component({
  selector: 'app-lesson-enrollments',
  templateUrl: './lesson-enrollments.component.html',
  styleUrls: ['./lesson-enrollments.component.css']
})

export class LessonEnrollmentsComponent implements OnInit {

  @Input() id: number;
  dataSource: any;
  columns: string[];

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(private utilityService: UtilityService) {}

  ngOnInit(): void {
        // Handle payments table.
        // this._utilityService.getPaymentReports(this.id)
        // .subscribe(
        //   (data) => {
        //     console.log('data')
        //     console.log(data)

        //       if (data) {
        //         console.log(Object.values(data));
        //         this.dataSource = new MatTableDataSource(Object.values(data));

        //         this.dataSource.paginator = this.paginator;
        //         this.dataSource.sort = this.sort;

        //         this.columns = [
        //           'payment_id',
        //           'customer_name',
        //           'date',
        //           'gross_tuition',
        //           'enrollment_name',
        //           'payment_type',
        //           'status',
        //           'assign',
        //           'teacher'
        //         ];
        //         // this.lessonListRows = new MatTableDataSource(data);

        //       }
        //   }
        // )
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

}
