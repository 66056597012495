import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'truncate'
})
export class TruncatePipe implements PipeTransform {

  transform(value: string | null | undefined, limit: number = 40, completeWords: boolean = false, ellipsis: string = '...'): string {
    // Check if value is null or undefined, return an empty string or some default value
    if (!value) {
      return '';
    }

    let actualLimit = limit;

    // If completeWords is true, try to limit the string without cutting off words
    if (completeWords && value.length > limit) {
      const lastSpaceIndex = value.substr(0, limit).lastIndexOf(' ');
      if (lastSpaceIndex > 0) { // Make sure there is a space to cut off at
        actualLimit = lastSpaceIndex;
      }
    }

    return value.length > limit ? `${value.substr(0, actualLimit)}${ellipsis}` : value;
  }
}
