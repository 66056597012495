import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-generic-table',
  templateUrl: './generic-table.component.html',
  styleUrls: ['./generic-table.component.css']
})
export class GenericTableComponent implements OnInit {

  // @Input() DataSource: [] = [];
  @Input() Columns;
  @Input() Rows: [] = [];

  dataSource = this.Rows;

  constructor() { }

  ngOnInit(): void {
    console.log("this.Columns")
    console.log(this.Columns)
    console.log("this.Rows")
    console.log(this.Rows)
  }

}
