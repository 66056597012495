
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatTabGroup } from '@angular/material/tabs';
import moment from 'moment';
import { BaseDialogComponent } from '../base-dialog/base-dialog.component';
import { BaseComponent } from '../base-form/base-form.component';
import _ from 'lodash';
import { concatMap, delay, forkJoin, from, of } from 'rxjs';

@Component({
  selector: 'app-event-schedules-entity',
  templateUrl: './event-schedules-entity.component.html',
  styleUrls: ['./event-schedules-entity.component.css']
})
export class EventSchedulesEntityComponent extends BaseDialogComponent implements OnInit {
  override eckType = "events";
  override bundle = "schedules";
  override entityId = this._injectedDialogData['data']['EntityID'];

  override onSubmit(form): void {
    this.errorMessage = "";
    this.displayProgressSpinner(true);

    let values;
    values = _.cloneDeep(form.value);
    this.__alterValues(values);

    // Check for create mode.
    if (this.actionType == "create") {
      this.displayProgressSpinner(true);

      from(values.field_schedule_instructor).pipe(
        concatMap(instructorId => {
          const instructorValues = { ...values, field_schedule_instructor: instructorId };
          return this._entityRESTService.createEntity(this.eckType, this.bundle, instructorValues);
        })
      ).subscribe({
        next: data => console.log('data', data),
        error: error => {
          this.handleError(error);
          this.displayProgressSpinner(false);
        },
        complete: () => {
          this.closeDialog();
          this.displayProgressSpinner(false);
        }
      });
    }

    if (this.actionType == "edit") {
      this.displayProgressSpinner(true);

      this._entityRESTService.patchEntity(this.eckType, this.bundle, this.entityId, values)
        .subscribe(data => {
          console.log('data', data);
          this.closeDialog();
          this.displayProgressSpinner(false);
        },
          error => this.handleError(error)
        )
    }

  }
}
