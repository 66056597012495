import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { ViewTableComponent } from '../../base/view-table/view-table.component';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import moment from 'moment';
import { MatPaginator } from '@angular/material/paginator';

@Component({
  selector: 'app-students-inventory',
  templateUrl: './students-inventory.component.html',
  styleUrls: ['./students-inventory.component.css']
})
export class StudentsInventoryComponent extends ViewTableComponent implements OnInit {
  isActiveChecked = false;
  isInactiveChecked = false;
  lastLessonTakenStart: Date;
  lastLessonTakenEnd: Date;

  override displayedColumns: string[] = [
    'name',
    'field_student_department',
    'address',
    'totalEnrolled',
    'lessonUsed',
    'remaining',
    'totalDueLessons',
    'miscService',
    'total_due_misc_field',
    'lastLesson',
    'futureLesson'
  ];

  override getData(params: { parameter: string; value: string; }[]) {
    this.ShowProgressBar = true;
    this.queryParams = params;

    // Handle different checkbox based filters.
    let start, end;
    let filters = '?field_archive=1&';
    let today = moment().format('YYYY-MM-DD');
    if (params) {
      for (const param of params) {
        if (param?.parameter === 'Active') {
          filters += "&field_last_lesson_taken_value=" + moment().subtract(30, 'days').format('YYYY-MM-DD') + "--";
        }
        if (param?.parameter === 'Inactive') {
          filters += "&field_last_lesson_taken_value=" + "--" + moment().subtract(30, 'days').format('YYYY-MM-DD');
        }
        if (param?.parameter === 'NFA') {
          filters += "&field_nfa=1";
          filters += "&field_total_lessons_remaining_value[value]=0";
          filters += "&field_total_lessons_remaining_value[min]=";
          filters += "&field_total_lessons_remaining_value[max]=";
          filters += "&field_total_lessons_remaining_value_op=>";
        }
        if (param?.parameter === 'paid_finished') {
          filters += "&field_paid_finished=true";
          filters += "&field_total_lessons_paid_ahead_value=0";
          filters += "&field_total_lessons_remaining_value[value]=0";
          filters += "&field_total_lessons_remaining_value[min]=";
          filters += "&field_total_lessons_remaining_value[max]=";
          filters += "&field_total_lessons_remaining_value_op==";
        }

        if (param?.parameter === 'last_lesson_taken_start') {
          start = param.value;
        }
        if (param?.parameter === 'last_lesson_taken_end') {
          end = param.value;
        }
      }

      if (start && end) {
        filters += "&field_last_lesson_taken_value=" + start + "--" + end;
      }
    }

    this.filters = filters;

    // Load the new data with the filtering parameters.
    let endpoint: string = "/api/v2/students_inventory" + filters;

    this._drupalRESTService.httpGET(endpoint, params)
      .subscribe((data) => {
        this.ShowProgressBar = false;

        this.dataSource = new MatTableDataSource(data['results']);

        if (data['results']) {
          this.setupPagination(data, params);
        }
      },
        error => this.handleError(error)
      );
  }

  onActiveChange() {
    if (this.isActiveChecked) {
      this.isInactiveChecked = false;
    }
    this.submitFilters();
  }

  onInactiveChange() {
    if (this.isInactiveChecked) {
      this.isActiveChecked = false;
    }
    this.submitFilters();
  }

  submitFilters() {
    const params = [];
    if (this.isActiveChecked) {
      params.push({ parameter: 'Active', value: 'true' });
    }
    if (this.isInactiveChecked) {
      params.push({ parameter: 'Inactive', value: 'true' });
    }
    if (this.lastLessonTakenStart) {
      params.push({ parameter: 'last_lesson_taken_start', value: this.lastLessonTakenStart.toISOString() });
    }
    if (this.lastLessonTakenEnd) {
      params.push({ parameter: 'last_lesson_taken_end', value: this.lastLessonTakenEnd.toISOString() });
    }
    this.getData(params);
  }

  updatePickerMax(event: any) {
    this.lastLessonTakenStart = event.value;
    this.submitFilters();
  }

  override resetForm() {
    this.isActiveChecked = false;
    this.isInactiveChecked = false;
    this.lastLessonTakenStart = null;
    this.lastLessonTakenEnd = null;
    this.submitFilters();
  }
}
