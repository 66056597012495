import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NgForm, NgModelGroup } from '@angular/forms';
import { MatTabGroup } from '@angular/material/tabs';
import _, { unset } from 'lodash';
import moment from 'moment';
import { BaseDialogComponent } from '../base-dialog/base-dialog.component';
import { BaseComponent } from '../base-form/base-form.component';
import { EventServicesEntityComponent } from '../event-services-entity/event-services-entity.component';
import { ReplaySubject, Subject, concatMap, debounceTime, distinctUntilChanged, filter, of, switchMap, tap } from 'rxjs';
import { ScheduleConflictsComponent } from '../../utility/schedule-conflicts/schedule-conflicts.component';

@Component({
  selector: 'app-student-inquiry-entity',
  templateUrl: './student-inquiry-entity.component.html',
  styleUrls: ['./student-inquiry-entity.component.css']
})
export class StudentInquiryEntityComponent extends BaseDialogComponent implements OnInit {

  @ViewChild(ScheduleConflictsComponent) scheduleConflicts: ScheduleConflictsComponent;
  @ViewChild('firstContact') firstContact: NgModelGroup;
  @ViewChild('partnerContact') partnerContact: NgModelGroup;

  override eckType = "student_accounts";
  override bundle = "student_account";
  override entityId = this._injectedDialogData['data']['EntityID'];

  // Declare the subjects
  private DEPackageSubject = new ReplaySubject<any>(1);
  private PreOriginalPackageSubject = new ReplaySubject<any>(1);

  // Public observables to expose the subjects
  public DEPackage$ = this.DEPackageSubject.asObservable();
  public PreOriginalPackage$ = this.PreOriginalPackageSubject.asObservable();
  studentAccountReady: boolean = false;

  contactList = [];
  field_contacts = [];
  checked = true;
  scheduleFirstLessonEnabled: boolean = true;
  conflictingEvent: any = null;
  alternativeTimes: any[] = [];
  field_attending_lessons: string = 'solo';

  disableSubmitButton = false;
  studentAccountCreated: boolean = false;
  studentAccountData;
  override field_date_and_time = this._injectedDialogData['data']['fieldsData']['dayview_field_date_and_time'];
  field_inquired = this._injectedDialogData['data']['fieldsData']['dayview_field_date_and_time'];
  _field_expiration_date = this._injectedDialogData['data']['fieldsData']['dayview_field_date_and_time'];
  _field_sale_date = this._injectedDialogData['data']['fieldsData']['dayview_field_date_and_time'];
  _inquiry = this._injectedDialogData['data']['fieldsData']['inquiry'];

  field_communication_opt_in: boolean = false;
  field_communication_opt_in_partner: boolean = false;

  duplicateWarning: string = '';
  duplicateWarningPartner: string = '';

  onNotificationPreferenceChange(contactIndex: number, value: string) {
    console.log('Selected Communication Preference:', value);
    this.field_contacts[contactIndex].field_notification_preference = value;
  }

  onNotificationPreferenceChangePartner(value: string) {
    console.log('Selected Communication Preference for Partner:', value);
    this.partnerContact.value.field_notification_preference = value;
  }

  onCommunicationOptInChange(value: boolean) {
    console.log('onCommunicationOptInChange called', value)
    this.field_communication_opt_in = value;
  }

  onCommunicationOptInPartnerChange(value: boolean) {
    console.log('onCommunicationOptInPartnerChange called', value)
    this.field_communication_opt_in_partner = value;
  }

  override async onSubmit(f: NgForm, promptScheduleService = false) {
    console.log('onSubmit called');
    console.log('firstContact:', this.firstContact);
    this.displayProgressSpinner(true);
    this.disableSubmitButton = true;
    // Copy the object to not modify binded data.
    let values = _.cloneDeep(f.form.value);
    let requested_date_and_time = moment(values.requested_date_time).format('YYYY-MM-DDTHH:mm:ss');

    this.__alterValues(values);

    // Fix for "field_teacher" being a required field when it should not.
    if (values.field_teacher == "") { delete values.field_teacher; }

    // Use the requested date and time (for the enrollment).
    values.field_inquired = this.field_inquired; // contact date
    this._field_expiration_date = values.requested_date_time;
    this._field_sale_date = values.requested_date_time;

    let mainContact = {
      type: 'contacts',
      bundle: 'student_record',
      field_attending_lessons: this.field_attending_lessons,
      field_home_phone: this.filterPhoneNumber(f.value.__field_contact_inline_form?.field_home_phone),
      field_cell_phone: this.filterPhoneNumber(f.value.__field_contact_inline_form?.field_cell_phone),
      field_first_name: f.value.__field_contact_inline_form?.field_first_name,
      field_last_name: f.value.__field_contact_inline_form?.field_last_name,
      field_email: f.value.__field_contact_inline_form?.field_email,
      field_gender: f.value.__field_contact_inline_form?.field_gender_main,
      field_communication_opt_in: this.field_communication_opt_in ? "1" : "0",
      field_studio_reference: [this._authService.studios?.[0]?.id],
      field_address: {
        address_line1: f.value.__field_contact_inline_form?.field_address?.address_line1,
        locality: f.value.__field_contact_inline_form?.field_address?.locality,
        administrative_area: f.value.__field_contact_inline_form?.field_address?.administrative_area,
        postal_code: f.value.__field_contact_inline_form?.field_address?.postal_code,
        country_code: f.value.__field_contact_inline_form?.field_address?.country_code || 'US',
      },
      // field_notification_preference: f.value.__field_contact_inline_form?.field_notification_preference,
    };

    let partnerContact = null;
    if (this.field_attending_lessons === 'partner') {
      partnerContact = {
        type: 'contacts',
        bundle: 'student_record',
        field_attending_lessons: this.field_attending_lessons,
        field_home_phone: this.filterPhoneNumber(this.partnerContact.value.field_home_phone),
        field_cell_phone: this.filterPhoneNumber(this.partnerContact.value.field_cell_phone),
        field_first_name: this.partnerContact.value.field_first_name,
        field_last_name: this.partnerContact.value.field_last_name,
        field_email: this.partnerContact.value.field_email,
        field_gender: this.partnerContact.value.field_gender_partner,
        field_communication_opt_in: this.field_communication_opt_in_partner ? "1" : "0",
        field_studio_reference: [this._authService.studios?.[0]?.id],
        field_address: {
          address_line1: this.partnerContact.value.field_address?.address_line1,
          locality: this.partnerContact.value.field_address?.locality,
          administrative_area: this.partnerContact.value.field_address?.administrative_area,
          postal_code: this.partnerContact.value.field_address?.postal_code,
          country_code: this.partnerContact.value.field_address?.country_code || 'US',
        },
        // field_notification_preference: this.partnerContact.value.field_notification_preference,
      };
    }

    // Prep the data to be sent.
    let studentBody = {
      ...values,
      field_contacts: [mainContact, partnerContact].filter(contact => contact !== null),
      field_inquired: moment(values.field_inquired).format('YYYY-MM-DD'),
      field_executive: values?.schedule_first_lesson_field_executive,
      field_archive: "0",
      title: "Default Title",
      field_notes: values?.field_notes,
    };

    let schedule_first_lesson = this.scheduleFirstLessonEnabled;
    let create_de_enrollment = Boolean(schedule_first_lesson && studentBody?.['lesson_type'] == 560);
    let create_preoriginal_enrollment = Boolean(schedule_first_lesson && studentBody?.['lesson_type'] == 1393);

    console.log('studentBody:', studentBody);
    console.log('schedule_first_lesson:', schedule_first_lesson);
    console.log('create_de_enrollment:', create_de_enrollment);
    console.log('create_preoriginal_enrollment:', create_preoriginal_enrollment);

    await this.pullSystemPackages();

    try {
      const data = await this._entityRESTService.createEntity('student_accounts', 'student_account', studentBody, [{ 'parameter': '_format', 'value': 'json' }]).toPromise();
      this.studentAccountData = data;
      console.log('Student account created:', data);

      if (promptScheduleService) {
        console.log('Calling promptScheduleService');
        await this.promptScheduleService(data, requested_date_and_time, studentBody);
      }

      if (create_de_enrollment) {
        console.log('Creating DE enrollment');
        await new Promise<void>((resolve, reject) => {
          this.DEPackage$.subscribe(
            async (DE_package) => {
              console.log('DE package:', DE_package);
              if (DE_package) {
                try {
                  await this.createDEEnrollment(true, schedule_first_lesson, studentBody, requested_date_and_time, DE_package);
                  console.log('DE enrollment created');
                  // Close the dialog and refresh the calendar
                  this.displayProgressSpinner(false);
                  this.closeDialog();
                  await this.refreshCalendar2();

                  resolve();
                } catch (error) {
                  console.error('Error creating DE enrollment:', error);
                  reject(error);
                }
              } else {
                console.log('DE package not found');
                resolve();
              }
            },
            (error) => {
              console.error('Error retrieving DE package:', error);
              reject(error);
            }
          );
        });
      }

      if (create_preoriginal_enrollment) {
        console.log('Creating pre-original enrollment');
        await new Promise<void>((resolve, reject) => {
          this.PreOriginalPackage$.subscribe(
            async (PreOriginal_package) => {
              console.log('Pre-original package:', PreOriginal_package);
              if (PreOriginal_package) {
                try {
                  await this.createPreOriginalEnrollment(true, schedule_first_lesson, studentBody, requested_date_and_time, PreOriginal_package);
                  console.log('Pre-original enrollment created');
                  resolve();
                } catch (error) {
                  console.error('Error creating pre-original enrollment:', error);
                  reject(error);
                }
              } else {
                console.log('Pre-original package not found');
                resolve();
              }
            },
            (error) => {
              console.error('Error retrieving pre-original package:', error);
              reject(error);
            }
          );
        });
      }

      console.log('Refreshing calendar');
      await this.refreshCalendar2();

      this.displayProgressSpinner(false);
      this.closeDialog();
      this.disableSubmitButton = false;
    } catch (error) {
      console.log('Error:', error);
      this.disableSubmitButton = false;
      this.handleError(error);
    }
  }

  studentUpdateLoad = (index: number): void => {
    this.editIndex = index;
    this.editMode = true;
    this.f.controls['__field_contact_inline_form'].patchValue(this.field_contacts[index])
  }

  studentRemove = (index: number): void => {
    // Reset the field contacts group control.
    this.f.form.controls['__field_contact_inline_form'].reset();
    this.editMode = false;
    this.field_contacts.splice(index, 1);
  }

  studentUpdateSubmit(number, contact: any) {
    this.field_contacts[this.editIndex] = contact;
    this.f.form.controls['__field_contact_inline_form'].reset();
    this.editMode = false;
  }

  studentIncrement(contact: any) {
    // Append type & bundle to model.
    contact = {
      ...contact,
      type: "contacts",
      bundle: "student_record",
      'field_studio_reference': [this._authService.studios?.[0]?.id],
      field_communication_opt_in: contact.field_communication_opt_in ? "1" : "0"
    }

    // If our data binding fails us (API needs to give an array), set as an empty array.
    if (!Array.isArray(this.field_contacts)) { this.field_contacts = [] }

    this.field_contacts.push(contact);

    // Reset the field contact group control.
    this.f.form.controls['__field_contact_inline_form'].reset();
  }


  pullSystemPackages(): Promise<void> {
    // Pull the system packages from the /packages endpoint.
    let endpoint: string = "/api/v1/packages";
    let params = [
      { parameter: "field_retired_op", value: "=" },
      { parameter: "field_retired_value", value: "0" },
      { parameter: "field_is_system_package_op", value: "=" },
      { parameter: "field_is_system_package_value", value: "1" },
    ];

    return new Promise<void>((resolve, reject) => {
      this._drupalRESTService.httpGET(endpoint, params).subscribe(
        (data) => {
          let DE_package = null;
          let PreOriginal_package = null;

          data?.['results'].forEach(data => {
            if (data.field_sps_code === 'DE') {
              DE_package = data;
            }
            if (data.field_sps_code === 'PORI') {
              PreOriginal_package = data;
            }
          });

          console.log('DE package in pullSystemPackages:', DE_package);
          console.log('PreOriginal package in pullSystemPackages:', PreOriginal_package);

          // Emit the packages through the subjects
          this.DEPackageSubject.next(DE_package);
          this.PreOriginalPackageSubject.next(PreOriginal_package);

          resolve();
        },
        (error) => {
          console.error('Error pulling system packages:', error);
          reject(error);
        }
      );
    });
  }


  scheduleFirstLesson(schedule_first_lesson: boolean, create_de_enrollment: boolean, create_preoriginal_enrollment: boolean, studentBody: any, requested_date_and_time: string) {
    // Convenience functionality to schedule first lesson.
    if (schedule_first_lesson && !create_de_enrollment && !create_preoriginal_enrollment) {
      let eventBody: any = {
        "field_student": [
          {
            "type": "attendees",
            "bundle": "attendance",
            "field_student_account": this.studentAccountData['id'][0].value,
            "field_students": this.studentAccountData?.['field_contacts']?.[0]?.target_id,
            "field_status": "64",
          }
        ],
        "field_type": studentBody['lesson_type'],
        "field_instructor": { target_id: studentBody['field_teacher'] },
        "field_date_and_time": requested_date_and_time,
      };

      // Get default duration times.
      let _duration = this.updateLessonDefaultTimes(studentBody['lesson_type']);
      eventBody = {
        ...eventBody,
        field_duration: _duration
      }

      // Create's the first event for the student.
      this._entityRESTService.createEntity('events', 'lesson', eventBody).subscribe(data => {
        this.refreshCalendar();
      }, error => {
        // TODO: Handle the error.
      });
    }
  }

  async createDEEnrollment(create_de_enrollment: boolean, schedule_first_lesson: boolean, studentBody: any, requested_date_and_time: string, DE_package) {
    console.log('createDEEnrollment called with:', {
      create_de_enrollment,
      schedule_first_lesson,
      studentBody,
      requested_date_and_time,
      DE_package
    });

    // Convenience functionality to create a DE enrollment.
    if (create_de_enrollment && schedule_first_lesson) {
      const dePackage = await this._entityRESTService.getEntity('packages', 'package', DE_package?.id).toPromise();
      let dePackagePrice = parseFloat(dePackage?.['field_lesson_price']) || 0;

      console.log('DE Package:', dePackage);
      console.log('DE Package Price:', dePackagePrice);

      let enrollmentBody = {
        "field_draft": false,
        "field_student": this.studentAccountData['id'][0].value,
        "field_sale_date": moment(this._field_sale_date).format('YYYY-MM-DDTHH:mm:ss'),
        "field_expiration_date": moment(this._field_expiration_date).add(4, 'weeks').format('YYYY-MM-DD'),
        "field_category": "1395",
        "field_enrollment_package_name": dePackage?.['id'],
        "field_enrollment_lesson_price": 0,
        "field_enrollment_lesson_count": 1,
        "field_total_lessons_price": dePackagePrice,
        "field_enrollment_total_price": dePackagePrice,
        "field_taxes": 0,
        "field_tax_percentage": "0",
        "field_total_paid": null,
        "field_lesson_used": null,
        "field_lessons_paid": null,
        "field_lesson_available": null,
        "field_payments_structure": "paid_in_full",
        // "field_executive": studentBody['field_teacher'],
        "field_junior_executive": null,
        "field_scheduled_payments": [
          {
            "field_amount_paid": null,
            "field_enrollment": null,
            "field_paid_in_full": true,
            "field_down_payment": true,
            "field_payment_amount": dePackagePrice,
            "field_payment_date": moment(this._field_sale_date).format("YYYY-MM-DD"),
            "field_studio_reference": this._authService.studios?.[0]?.id,
            "bundle": "scheduled_payments",
            "type": "scheduled_payments"
          }
        ],
        "field_instructor_percentages": [
          {
            "field_amount": 0,
            "field_instructor": studentBody['field_teacher'],
            "field_percentage": 100,
            "field_studio_reference": this._authService.studios?.[0]?.id,
            "bundle": "instructor_percentages",
            "type": "instructor_percentages"
          }
        ],
        "field_interval_unit": "",
        "field_discount": 0,
        "field_discount_type": "flat",
        "field_enrollment_status": "Open",
        "field_next_scheduled_payment": "",
        "field_visibility": false,
        "field_notes": ""
      };

      console.log('Enrollment Body:', enrollmentBody);

      // Create the enrollment for the student.
      const enrollmentData = await this._entityRESTService.createEntity('packages', 'enrollment', enrollmentBody).toPromise();
      console.log('Enrollment Data:', enrollmentData);

      // After the enrollment is created, create the first lesson.
      //  - Convenience functionality to schedule first lesson.
      if (!schedule_first_lesson) return;

      let eventBody = {
        "field_student": [
          {
            "type": "attendees",
            "bundle": "attendance",
            "field_status": "64",
            "field_student_account": this.studentAccountData['id'][0].value,
            "field_students": (this.studentAccountData?.['field_contacts']?.[1]?.target_id) ? [this.studentAccountData['field_contacts'][0].target_id, this.studentAccountData['field_contacts'][1].target_id] : this.studentAccountData['field_contacts'][0].target_id, // field_contacts, default to the couple value.
            "field_enrollment": { target_id: enrollmentData['id'][0]['value'] } // Add enrollment here.
          }
        ],
        "field_type": studentBody['lesson_type'],
        "field_instructor": { target_id: studentBody['field_teacher'] }, // data['field_teacher'][0].target_id
        "field_duration": "00:45",
        "field_date_and_time": requested_date_and_time,
      };

      // Get default duration times.
      let _duration = this.updateLessonDefaultTimes(studentBody['lesson_type']);
      eventBody = {
        ...eventBody,
        field_duration: _duration
      }

      console.log('Event Body:', eventBody);

      // Create's the first event for the student.
      const eventData = await this._entityRESTService.createEntity('events', 'lesson', eventBody).toPromise();
      console.log('Event Data:', eventData);

      // Create the DE Pre Chat for the student.
      let DEServiceBody = {
        'field_type': 1404,
        'field_status': 106,
        // 'field_executive': studentBody?.field_teacher,
        'field_executive': studentBody?.schedule_first_lesson_field_executive,
        'field_duration': '00:15',
        'field_date_and_time': moment(requested_date_and_time).subtract(15, 'minutes').format('YYYY-MM-DDTHH:mm:ss'),
        'field_student': [
          {
            type: 'attendees',
            bundle: 'attendance',
            field_student_account: this.studentAccountData['id'][0].value,
            field_students: (this.studentAccountData?.['field_contacts']?.[1]?.target_id) ? [this.studentAccountData['field_contacts'][0].target_id, this.studentAccountData['field_contacts'][1].target_id] : this.studentAccountData['field_contacts'][0].target_id, // field_contacts, default to the couple value.
          },
        ],
      }

      console.log('DE Service Body:', DEServiceBody);

      const DEServiceData = await this._entityRESTService.createEntity('events', 'services', DEServiceBody).toPromise();
      console.log('DE Service Data:', DEServiceData);

      // Create the DE Pre Chat for the student.
      let OriginalChatServiceBody = {
        'field_type': 567,
        'field_status': 113,
        // 'field_executive': studentBody?.field_teacher,
        'field_executive': studentBody?.schedule_first_lesson_field_executive,
        'field_duration': '00:15',
        'field_date_and_time': moment(requested_date_and_time).add(30, 'minutes').format('YYYY-MM-DDTHH:mm:ss'),
        'field_student': [
          {
            type: 'attendees',
            bundle: 'attendance',
            field_student_account: this.studentAccountData['id'][0].value,
            field_students: (this.studentAccountData?.['field_contacts']?.[1]?.target_id) ? [this.studentAccountData['field_contacts'][0].target_id, this.studentAccountData['field_contacts'][1].target_id] : this.studentAccountData['field_contacts'][0].target_id, // field_contacts, default to the couple value.
          },
        ],
      }

      console.log('Original Chat Service Body:', OriginalChatServiceBody);

      const OriginalChatServiceData = await this._entityRESTService.createEntity('events', 'services', OriginalChatServiceBody).toPromise();
      console.log('Original Chat Service Data:', OriginalChatServiceData);

      await this.refreshCalendar2();
    }

    return Promise.resolve();
  }

  async createPreOriginalEnrollment(create_preoriginal_enrollment: boolean, schedule_first_lesson: boolean, studentBody: any, requested_date_and_time: string, PreOriginal_package) {
    if (create_preoriginal_enrollment && schedule_first_lesson) {
      // First, load the pre original package because we need price data.
      const preOriginalPackage = await this._entityRESTService.getEntity('packages', 'package', PreOriginal_package?.id).toPromise();
      let preOriginalPackagePrice = parseFloat(preOriginalPackage?.['field_lesson_price']) || 25;

      let enrollmentBody =
      {
        "field_draft": false,
        "field_student": this.studentAccountData['id'][0].value,
        "field_sale_date": moment(this._field_sale_date).format("YYYY-MM-DDTHH:mm:ss"),
        "field_expiration_date": moment(this._field_expiration_date).add(4, 'weeks').format('YYYY-MM-DD'),
        "field_category": "49",
        "field_enrollment_package_name": preOriginalPackage?.['id'],
        "field_enrollment_lesson_price": preOriginalPackagePrice,
        "field_enrollment_lesson_count": 1,
        "field_total_lessons_price": preOriginalPackagePrice,
        "field_enrollment_total_price": preOriginalPackagePrice,
        "field_taxes": 0,
        "field_tax_percentage": "0",
        "field_total_paid": null,
        "field_lesson_used": null,
        "field_lessons_paid": null,
        "field_lesson_available": null,
        "field_payments_structure": "paid_in_full",
        // "field_executive": studentBody['field_teacher'],
        "field_junior_executive": null,
        "field_scheduled_payments": [
          {
            "field_amount_paid": null,
            "field_enrollment": null,
            "field_paid_in_full": true,
            "field_down_payment": true,
            "field_payment_amount": preOriginalPackagePrice,
            "field_payment_date": moment(this._field_sale_date).format("YYYY-MM-DD"),
            "field_studio_reference": this._authService.studios?.[0]?.id,
            "bundle": "scheduled_payments",
            "type": "scheduled_payments"
          }
        ],
        "field_instructor_percentages": [
          {
            "field_amount": preOriginalPackagePrice,
            "field_instructor": studentBody['field_teacher'],
            "field_percentage": 100,
            "field_studio_reference": this._authService.studios?.[0]?.id,
            "bundle": "instructor_percentages",
            "type": "instructor_percentages"
          }
        ],
        "field_interval_unit": "",
        "field_discount": 0,
        "field_discount_type": "flat",
        "field_enrollment_status": "Open",
        "field_next_scheduled_payment": "",
        "field_visibility": false,
        "field_notes": ""
      };

      // Create the enrollment for the student.
      const enrollmentData = await this._entityRESTService.createEntity('packages', 'enrollment', enrollmentBody).toPromise();

      // After the enrollment is created, create the first lesson.
      //  - Convenience functionality to schedule first lesson.
      if (!schedule_first_lesson) return;
      let eventBody = {
        "field_student": [
          {
            "type": "attendees",
            "bundle": "attendance",
            "field_student_account": this.studentAccountData['id'][0].value,
            "field_students": (this.studentAccountData?.['field_contacts']?.[1]?.target_id) ? [this.studentAccountData['field_contacts'][0].target_id, this.studentAccountData['field_contacts'][1].target_id] : this.studentAccountData['field_contacts'][0].target_id, // field_contacts, default to the couple value.
            "field_status": "64",
            "field_enrollment": { target_id: enrollmentData['id'][0]['value'] } // Add enrollment here.
          }
        ],
        "field_type": studentBody['lesson_type'],
        "field_instructor": { target_id: studentBody['field_teacher'] }, // data['field_teacher'][0].target_id
        "field_duration": "00:45",
        "field_date_and_time": requested_date_and_time,
      };

      // Get default duration times.
      let _duration = this.updateLessonDefaultTimes(studentBody['lesson_type']);
      eventBody = {
        ...eventBody,
        field_duration: _duration
      }

      // Create's the first event for the student.
      await this._entityRESTService.createEntity('events', 'lesson', eventBody).toPromise();

      // Create the Pre Chat for the student.
      let PREServiceBody = {
        'field_type': 656,
        'field_status': 106,
        // 'field_executive': studentBody?.field_teacher,
        'field_executive': studentBody?.schedule_first_lesson_field_executive,
        'field_duration': '00:15',
        'field_date_and_time': moment(requested_date_and_time).subtract(15, 'minutes').format('YYYY-MM-DDTHH:mm:ss'),
        'field_student': [
          {
            type: 'attendees',
            bundle: 'attendance',
            field_student_account: this.studentAccountData['id'][0].value,
            field_students: (this.studentAccountData?.['field_contacts']?.[1]?.target_id) ? [this.studentAccountData['field_contacts'][0].target_id, this.studentAccountData['field_contacts'][1].target_id] : this.studentAccountData['field_contacts'][0].target_id, // field_contacts, default to the couple value.
          },
        ],
      }

      await this._entityRESTService.createEntity('events', 'services', PREServiceBody).toPromise();
      // Create the DE Pre Chat for the student.
      let OriginalChatServiceBody = {
        'field_type': 567,
        'field_status': 113,
        // 'field_executive': studentBody?.field_teacher,
        'field_executive': studentBody?.schedule_first_lesson_field_executive,
        'field_duration': '00:15',
        'field_date_and_time': moment(requested_date_and_time).add(30, 'minutes').format('YYYY-MM-DDTHH:mm:ss'),
        'field_student': [
          {
            type: 'attendees',
            bundle: 'attendance',
            field_student_account: this.studentAccountData['id'][0].value,
            field_students: (this.studentAccountData?.['field_contacts']?.[1]?.target_id) ? [this.studentAccountData['field_contacts'][0].target_id, this.studentAccountData['field_contacts'][1].target_id] : this.studentAccountData['field_contacts'][0].target_id, // field_contacts, default to the couple value.
          },
        ],
      }

      await this._entityRESTService.createEntity('events', 'services', OriginalChatServiceBody).toPromise();
    }

    return Promise.resolve();
  }

  promptScheduleService(data, requested_date_and_time, studentBody) {
    // Potentially have more than one student in the service.
    let field_students = [];
    data['field_contacts'].forEach(contact => {
      field_students.push(contact.target_id.toString())
    });

    // Date and time should be 15 minutes before the scheduled lesson.
    let field_date_and_time = moment(requested_date_and_time).subtract(15, 'minutes');;

    let fieldsData = {
      'field_inquiry_taker': studentBody?.field_inquiry_taker,
      'field_duration': '00:15',
      'field_date_and_time': field_date_and_time,
      '___field_student': {
        type: 'attendees',
        bundle: 'attendance',
        field_student_account: data['title'][0].value.toString() + ' (' + data['id'][0].value.toString() + ') ',
        field_students: field_students.toString(),
      },
    }

    this._dialogService.openDialog(EventServicesEntityComponent, "defaultWithData", {
      data: {
        EntityID: null,
        eckType: 'events',
        bundle: 'services',
        action: 'create',
        fieldsData: fieldsData ?? '',
      },
    }).afterClosed().subscribe(data => {
    });

    return Promise.resolve();
  }

  getFilteredLessonTypes() {
    if (this.f?.form?.value?.field_inquiry_studen) {
      return this._taxonomyService.lesson_type.filter(lt => lt.id === '1393' || lt.id === '560');
    }
    return this._taxonomyService.lesson_type;
  }

  toggleInquiry() {
    this.f.form.patchValue({ 'lesson_type': null });
  }

  async checkScheduleConflicts() {
    if (this.scheduleConflicts) {
      await this.scheduleConflicts.checkScheduleConflicts();
    }
  }

  scrollToConflicts() {
    setTimeout(() => {
      this.scheduleConflicts.conflictsContainer.nativeElement.scrollIntoView({ behavior: 'smooth' });
    }, 0);
  }

  isFormValid(): boolean {
    if (this.f && this.f.form) {
      const mainStudentRequiredFields = [
        '__field_contact_inline_form.field_first_name',
        '__field_contact_inline_form.field_last_name',
        '__field_contact_inline_form.field_email',
        '__field_contact_inline_form.field_gender_main'
      ];

      const scheduleRequiredFields = [
        'schedule_first_lesson_field_executive',
        'field_teacher',
        'lesson_type',
        'requested_date_time'
      ];

      const invalidMainStudentFields = mainStudentRequiredFields.filter(field => !this.f.form.get(field)?.valid);
      const invalidScheduleFields = this.scheduleFirstLessonEnabled ? scheduleRequiredFields.filter(field => !this.f.form.get(field)?.valid) : [];

      // Check if field_inquiry_taker is filled out
      const isInquiryTakerFilled = this.f.form.get('field_inquiry_taker')?.value;

      // Partner fields validation logic modification starts here
      let invalidPartnerFields = [];
      if (this.field_attending_lessons === 'partner' && this.partnerContact) {
        // Directly check the validity of the partnerContact form group
        invalidPartnerFields = this.partnerContact.valid ? [] : ['Invalid Partner Contact Group'];
      }

      // Final validity check includes the partnerContact validity and field_inquiry_taker check
      const isValid = invalidMainStudentFields.length === 0 && invalidPartnerFields.length === 0 && invalidScheduleFields.length === 0 && isInquiryTakerFilled;
      return isValid;
    }

    return false; // Return false to indicate form is not valid if not initialized
  }

  onAttendingLessonsChange(event: any) {
    this.field_attending_lessons = event.value;
  }

  copyAddress() {
    if (this.firstContact && this.partnerContact) {
      const firstContactAddress = this.firstContact.control.get('field_address').value;
      this.partnerContact.control.get('field_address').setValue({
        multiple_properties: 'true',
        address_line1: firstContactAddress?.address_line1,
        locality: firstContactAddress?.locality,
        administrative_area: firstContactAddress?.administrative_area,
        postal_code: firstContactAddress?.postal_code,
        country_code: firstContactAddress?.country_code || 'US',
      });
    }
  }

  checkDuplicateStudent(contactType: 'main' | 'partner') {
    const formGroup = contactType === 'main' ? this.firstContact : this.partnerContact;
    const firstName = formGroup.control.get('field_first_name')?.value;
    const lastName = formGroup.control.get('field_last_name')?.value;

    if (!firstName || !lastName) {
      return;
    }

    const params = [
        { parameter: 'field_first_name', value: firstName },
        { parameter: 'field_last_name', value: lastName }
    ];

    this._drupalRESTService.httpGET('/api_rest/v1/checkDuplicateStudent', params).subscribe(
      (response: any) => {
        if (response.warning) {
          if (contactType === 'main') {
            this.duplicateWarning = response.warning;
          } else {
            this.duplicateWarningPartner = response.warning;
          }
        } else {
          if (contactType === 'main') {
            this.duplicateWarning = '';
          } else {
            this.duplicateWarningPartner = '';
          }
        }
      },
      error => {
        console.error('Error checking for duplicate student:', error);
      }
    );
  }

}
