<div class="row">
    <ng-container *ngIf="field_students">
        <div
        *ngFor="let student of field_students; let i = index;"
        class="mb-2 mt-2"
        style="border: 1px solid #ccc; padding: 0 12px; margin-bottom: 12px;"
        >
            {{ student.field_student_account }}

            <!-- Remove attendee -->
            <mat-icon
                class="me-1 float-end"
                (click)="studentRemove(i)"
                aria-hidden="false"
                aria-label="trash">cancel</mat-icon>

            <!-- Edit attendee -->
            <mat-icon
                class="me-1 float-end"
                (click)="studentUpdateLoad(i, attendee)"
                aria-hidden="false"
                aria-label="edit">edit</mat-icon>
        </div>
    </ng-container>
</div>
