<div class="container">
  <h2>Simple Import Spreadsheet</h2>

  <mat-card>
    <mat-card-header>
      <mat-card-title>Step 1: Download Spreadsheet Template</mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <button mat-raised-button color="primary" (click)="downloadSpreadsheet()">
        Download Template
      </button>
    </mat-card-content>
  </mat-card>

  <mat-card>
    <mat-card-header>
      <mat-card-title>Step 2: Import Completed Spreadsheet</mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <input type="file" (change)="onFileSelected($event)" accept=".xlsx" style="display: none" #fileInput>
      <button mat-stroked-button (click)="fileInput.click()" [disabled]="isUploading">
        <mat-icon>attach_file</mat-icon>
        Select File
      </button>
      <span *ngIf="selectedFile">{{ selectedFile.name }}</span>
      <button class="ms-2" mat-raised-button color="primary" (click)="uploadSpreadsheet()" [disabled]="!selectedFile || isUploading">
        <mat-icon class="spinner" *ngIf="isUploading">refresh</mat-icon>
        <span>{{ isUploading ? 'Processing...' : 'Upload Spreadsheet' }}</span>
      </button>
    </mat-card-content>
    <mat-card-actions>
    </mat-card-actions>
  </mat-card>

  <mat-card *ngIf="lastImportResult">
    <mat-card-header>
      <mat-card-title>Import Results</mat-card-title>
      <mat-card-subtitle>{{ lastImportResult.timestamp }}</mat-card-subtitle>
    </mat-card-header>
    <mat-card-content>
      <p>{{ lastImportResult.message }}</p>
      <p>Total records processed: {{ lastImportResult.result.processed }}</p>
      <h3>Created:</h3>
      <ul>
        <li>Student Accounts: {{ lastImportResult.result.created.student_accounts }}</li>
        <li>Enrollments: {{ lastImportResult.result.created.enrollments }}</li>
        <li>Payments: {{ lastImportResult.result.created.payments }}</li>
        <li>Lessons: {{ lastImportResult.result.created.lessons }}</li>
      </ul>
      <h3>Updated:</h3>
      <ul>
        <li>Student Accounts: {{ lastImportResult.result.updated.student_accounts }}</li>
        <li>Enrollments: {{ lastImportResult.result.updated.enrollments }}</li>
        <li>Payments: {{ lastImportResult.result.updated.payments }}</li>
        <li>Lessons: {{ lastImportResult.result.updated.lessons }}</li>
      </ul>
      <h3>Deleted:</h3>
      <ul>
        <li>Student Accounts: {{ lastImportResult.result.deleted.student_accounts }}</li>
      </ul>
      <h3 *ngIf="lastImportResult.result.errors.length > 0">Errors:</h3>
      <ul *ngIf="lastImportResult.result.errors.length > 0">
        <li *ngFor="let error of lastImportResult.result.errors">{{ error }}</li>
      </ul>
    </mat-card-content>
  </mat-card>
</div>
